import { Radio, RadioChangeEvent } from 'antd';
import { getMessages, RoutingHelper } from 'common';
import { useCurrentView } from 'pages/Authentication/hooks';
import Styles from "./Styles.module.scss";

export default function LoginRegisterSwitch() {
    const currentView = useCurrentView();

    function onChange(e: RadioChangeEvent) {
        RoutingHelper.Authentication({ activeView: e.target.value }).navigate();
    }

    return (
        <>
            <Radio.Group
                className={Styles.LoginRegisterSwitchContainer}
                value={currentView}
                onChange={onChange}
            >
                <Radio.Button value="login" className='button-bold'>
                    {getMessages("000121")}
                </Radio.Button>
                <Radio.Button value="register" className='button-bold'>
                    {getMessages("000122")}
                </Radio.Button>
            </Radio.Group>
        </>
    );
}
