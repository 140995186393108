import { IWindowLevel, ProblemTypeKeys, SortingKey } from 'common';
import { IAnnotationProjectStatus, TAnnotationStatus } from './IAnnotationTracking';

export interface ICommonState {
    projectFilters: IProjectFilters;
    projectSorting: SortingKey;
    isAnnotationEditDrawerVisible?: boolean;
    isAnnotationLeftNavbarCollapsed?: boolean;
    isTaskStatusModalOpen?: boolean;
    isReviewStatusModalOpen?: boolean;
    isLeftBarCollapsed?: boolean;
    isRightBarCollapsed?: boolean;
    isBrushToolBoxVisible?: boolean;
    isSegmentConfigBoxVisible?: boolean;
    isInputFocused?: boolean;
    isBrushConfigBoxFocused?: boolean;
    windowLevel?: IWindowLevel;
}

export function CommonStateFactory(data?: Partial<ICommonState>): ICommonState {
    return {
        projectFilters: ProjectFiltersFactory(data?.projectFilters),
        projectSorting: data?.projectSorting ?? 'lastActivity',
        isAnnotationEditDrawerVisible: data?.isAnnotationEditDrawerVisible ?? false,
        isAnnotationLeftNavbarCollapsed: data?.isAnnotationLeftNavbarCollapsed ?? false,
        isTaskStatusModalOpen: data?.isTaskStatusModalOpen ?? false,
        isReviewStatusModalOpen: data?.isReviewStatusModalOpen ?? false,
        isLeftBarCollapsed: data?.isLeftBarCollapsed ?? false,
        isRightBarCollapsed: data?.isRightBarCollapsed ?? false,
        isBrushToolBoxVisible: data?.isBrushToolBoxVisible ?? false,
        isInputFocused: data?.isInputFocused ?? false,
        isBrushConfigBoxFocused: data?.isBrushConfigBoxFocused ?? false,
        windowLevel: data?.windowLevel ?? null,
    };
}

export interface IProjectFilters {
    problemType: ProblemTypeKeys;
    status: TAnnotationStatus;
    projectStatus: IAnnotationProjectStatus;
    search: string;
}

export function ProjectFiltersFactory(data?: Partial<IProjectFilters>): IProjectFilters {
    return {
        problemType: data?.problemType ?? null,
        status: data?.status ?? null,
        projectStatus: data?.projectStatus ?? null,
        search: data?.search ?? null,
    };
}

export interface IClassMappingSelection {
    predictionClassKey: number;
    enabled: boolean;
    annotationClassKey: number;
}
