import { Dictionary } from "common/entities";
import _assign from "lodash/assign";
import _isObject from "lodash/isObject";
import _mapKeys from "lodash/mapKeys";
import _transform from "lodash/transform";
import _reduce from "lodash/reduce";
import _set from "lodash/set";
import { _keys } from "./_keys";

export function _flatten(obj: Object, takeFirstItems = false, preserveEmpty = false): Dictionary<any> {
    return _transform(obj, function (result: any, value: any, key) {
        if (_isObject(value)) {
            if (Array.isArray(value) && takeFirstItems) value = value.slice(0, 1);
            let flatMap = _mapKeys(_flatten(value, preserveEmpty), function (_mvalue, mkey) {
                if (Array.isArray(value)) {
                    let index = mkey.indexOf('.');
                    if (-1 !== index) {
                        return `${key}[${mkey.slice(0, index)}]${mkey.slice(index)}`;
                    }
                    return `${key}[${mkey}]`;
                }
                return `${key}.${mkey}`;
            });

            _assign(result, flatMap);

            // Preverve Empty arrays and objects
            if (preserveEmpty && _keys(flatMap).length === 0) {
                result[key] = value;
            }
        } else {
            result[key] = value;
        }

        return result;
    }, {});
}

export function _inflate(params: Dictionary<any>) {
    return _reduce(params, function (result, value, key) { return _set(result, key, value); }, {});
}