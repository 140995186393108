import { IGModalProps } from 'components/GModal';
import { useState } from 'react';
import { useWindowSize } from 'react-use';
import Styles from "./Styles.module.scss";

export function useDimensionWarning() {
    const { width } = useWindowSize();
    const [supress, setSupress] = useState(false);

    const modalProps: IGModalProps = {
        open: !supress && width < 768,
        className: Styles.MobileWarningModal,
        footer: null,
        closable: false,
        okType: "primary",
        maskStyle: { backdropFilter: "blur(20px)" },
    };


    return { setSupress, modalProps };
}
