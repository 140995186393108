import { AuditCategory, AuditEventType } from 'common';





export interface IAuditLog {
    result: IAuditResult[];
    hits: number;
}

export interface IAuditResult {
    _id: string;
    audit_reason: string;
    message: string;
    user_log_level: string;
    host: string;
    event: {
        original: string;
    };
    "x-real-ip": string;
    "x-session-id": string;
    is_api_request: boolean;
    category: Partial<AuditCategory>;
    "user-agent": string;
    "x-user-id": string;
    "accept-language": string;
    details: string;
    "@version": string;
    timestamp: string;
    "@timestamp": string;
    loggerName: string;
    "x-request-id": string;
    "x-project-id": string;
    log: {
        file: {
            path: string;
        };
    };
    referer: string;
    'event-type': Partial<AuditEventType>;
    level: Partial<AuditLevel>;
    lineno: number;
}


export interface AuditLogsResponse {
    result: IAuditLog[];
    total: number;
}

export interface AuditLogsRequestParams {
    skip?: number;
    limit?: number;
    level?: AuditLevel;
    date?: [number, number];
    user_id?: string;
    event_type?: string;
    session_id?: string;
}

export enum AuditLevel {
    Critical = 'critical',
    Error = 'error',
    Warning = 'warning',
    Info = 'info',
    Debug = 'debug',
    Trace = 'trace',
}

export interface AuditLogRequestParams {
    session_id: string;
}

export type AuditLogParamsResponse = Dictionary<string[]>;
