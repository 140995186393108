import { ProblemTypeKeys, TClassMapping } from 'common';
import { AnnotationShapeType, AnnotationTool } from './annotation.interface';

export interface ILabelingConfiguration {
    id: string;
    name: string;
    problemType: ProblemTypeKeys;
    shapeType: AnnotationShapeType;
    classMapping: TClassMapping;
    defaultTool?: AnnotationTool;
    allowMultipleClass?: boolean;
    classificationMappingList?: string;
    selectionComponent?: SelectionComponent;
    allowMultipleInstance?: boolean;
}

export interface ILabelingConfigurationResponse {
    id: string;
    name: string;
    problem_type: ProblemTypeKeys;
    shape_type: AnnotationShapeType;
    class_mapping: TClassMapping;
    default_tool?: AnnotationTool;
    allow_multiple_class?: boolean;
    classification_mapping_list?: string;
    selection_component?: SelectionComponent;
    allow_multiple_instance?: boolean;
}

export type ILabelingConfigurationFormItem = Omit<ILabelingConfigurationResponse,  'class_mapping'> & {
    class_mapping: string[];
};

type SelectionComponent = 'dropdown' | 'radio';

export function LabelingConfigurationFactory(data?: Partial<ILabelingConfigurationResponse>): ILabelingConfiguration {
    return {
        id: data?.id ?? '',
        name: data?.name ?? '',
        problemType: data?.problem_type,
        shapeType: data?.shape_type ?? null,
        classMapping: data?.class_mapping ?? {},
        defaultTool: data?.default_tool ?? null,
        allowMultipleClass: data?.allow_multiple_class ?? false,
        classificationMappingList: data?.classification_mapping_list ?? null,
        selectionComponent: data?.selection_component ?? 'radio',
        allowMultipleInstance: data?.allow_multiple_instance ?? false,
    };
}

export function transformLabelingConfigurationForRequest(data: ILabelingConfiguration): ILabelingConfigurationResponse {
    return {
        id: data.id,
        name: data.name,
        problem_type: data.problemType,
        shape_type: data.shapeType,
        class_mapping: data.classMapping,
        default_tool: data.defaultTool,
        allow_multiple_class: data?.allowMultipleClass,
        classification_mapping_list: data?.classificationMappingList,
        selection_component: data?.selectionComponent,
    };
}

export function isPolygonExists(config: Array<ILabelingConfiguration>) {
    return config?.some(item => item.shapeType === 'polygon');
}
