import { getMessages } from "common";
import { GSelect } from "components";
import { useProjectSelection } from "./useProjectSelection";
import Styles from "./Styles.module.scss";

export default function ProjectSelection() {
    const { menuItemList, onChange, activeProjectId, renderProjectOption, renderProjectDropdown } = useProjectSelection();

    return (
        <div className={Styles.SelectWrapper}>
            <GSelect
                onChange={onChange}
                menuItemList={menuItemList}
                menuItemRender={renderProjectOption}
                dropdownRender={renderProjectDropdown}
                placeholder={getMessages("001252")}
                value={activeProjectId}
                id="project-select"
                allowClear
                placement="bottomRight"
                popupClassName={Styles.ProjectSelectBox}
            />
        </div>
    );
}