import { BackTop } from 'antd';
import Styles from "./Styles.module.scss";
import { ArrowUp } from 'components/assets/icons';
import { getMessages } from 'common/helpers';

export default function GScrollToTop() {
    return (
        <>
            <BackTop>
                <div className={Styles.ScrollToTopContainer}>
                    <ArrowUp />
                    {getMessages("000808")}
                </div>
            </BackTop>
        </>);
}