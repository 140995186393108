import { PlatformService, getMessages } from 'common';
import { IModelFiles, ModelFilesFactory } from 'pages/Models/views/ModelUpload/entities';
import { IModelArtifactProps, ModelArtifactPropsFactory } from 'pages/Models/views/ModelUpload/entities/IModelArtifactProps';
import { put, select } from 'redux-saga/effects';
import { RootState } from 'redux/store';
import { updateModelArtifactProps, updateModelFiles, updateModelTrainingState } from '..';
import { selectConfigList, selectModelFormParams } from '../selectors';
import { Notification } from 'components';
import { extendedApiSlice } from '../ModelService';

export function* submitTrainingModelFormSaga(sagaAction: any): any {
    try {
        const { selectedProblemType, selectedFramework }: IModelArtifactProps = yield select(
            (state: RootState) => state.models.modelUpladState.modelArtifactProps
        );
        const { model_id, model_path }: IModelFiles = yield select((state: RootState) => state.models.modelUpladState.modelFiles);
        const modelFormParams = yield select(selectModelFormParams);
        const modelConfigList = yield select(selectConfigList);

        let { form } = sagaAction.payload;
        const configList = modelConfigList.map((item: any) => {
            return {
                default: item.default,
                explanation: item.explanation,
                name: item.name,
                codename: item.name.toLowerCase(),
                type: item.type,
                dtype: item.dtype,
                values: {
                    selection: item.selection,
                    type: item?.type === 'number_field' ? 'between' : 'individual',
                    values: item?.type === 'number_field' ? [item?.min, item?.max] : item?.values,
                },
            };
        });
        let payload = {
            model_id: model_id,
            model_architecture: 'resnet',
            framework_version: '1.1.0',
            model_version: '1.0.1',
            channels: modelFormParams?.channels,
            problem_type: selectedProblemType,
            model_framework: selectedFramework,
            model_configs: configList,
            model_name: modelFormParams?.model_name,
            model_detail: modelFormParams?.model_detail,
            model_summary: modelFormParams?.model_summary,
            tags: modelFormParams?.tags,
            model_codename: modelFormParams?.model_name,
            reqs: `${model_path}/reqs.txt`,
        };

        const params = {
            model_path: model_path,
            dockerhub: false,
            src: 'local',
            is_training: true,
        };

        const response = yield PlatformService.Manage.CreateModel.call(payload, { params });

        if (response.data) {
            form?.resetFields?.();

            yield put(updateModelFiles(ModelFilesFactory()));
            yield put(updateModelArtifactProps(ModelArtifactPropsFactory()));

            Notification.success({ content: getMessages('001612') });
            yield put(updateModelTrainingState({ isCustomTrainingModalOpen: false }));
            yield put(extendedApiSlice.util.invalidateTags(['TrainModelList']));
        }
    } catch (error: any) {
        console.log(error);
    }
}
