import { createSlice } from '@reduxjs/toolkit';
import _merge from 'lodash/merge';
import { IBaseAction } from 'redux/entities';
import { IAnnotationUploadState, IFileUploadState } from '../../AnnotationCS/entities/IAnnotationUploadState';
import {
    AnnotationShapeType,
    AnnotationStateFactory,
    AssignmentRole,
    IAnnotationCreateState,
    ICurrentProjectState,
    IImageEvaluationState,
    IViewportState,
    IVisualComparisonState,
    UserState,
} from '../entities';
import { ICommonState, IProjectFilters } from '../entities/ICommonState';
import { PreAnnotationConfig, UserUpdateModalState } from '../views/AnnotationDetails/views/PreAnnotation/entities';
import { IToolState, LabelSelection, PreAnnotationState } from '../views/ToolView/entities/interfaces';

const AnnotationSlice = createSlice({
    name: 'AnnotationCS',
    initialState: AnnotationStateFactory(),
    reducers: {
        updateCurrentProjectState(state, { payload }: IBaseAction<Partial<ICurrentProjectState>>) {
            Object.assign(state.currentProjectState, payload);
        },
        updateToolState(state, { payload }: IBaseAction<Partial<IToolState>>) {
            Object.assign(state.toolState, payload);
        },
        updateProjectFilters(state, { payload }: IBaseAction<Partial<IProjectFilters>>) {
            Object.assign(state.commonState.projectFilters, payload);
        },
        updateCommonState(state, { payload }: IBaseAction<Partial<ICommonState>>) {
            Object.assign(state.commonState, payload);
        },
        updateUploadState(state, { payload }: IBaseAction<Partial<IAnnotationUploadState>>) {
            Object.assign(state.uploadState, payload);
        },
        updateStudySessionId(state, { payload }: IBaseAction<string>) {
            state.toolState.studySessionId = payload;
        },
        updateStudySessionStartTime(state, { payload }: IBaseAction<number>) {
            state.toolState.studySessionStartTime = payload;
        },
        updateAnnotationFileUploadState(state, { payload }: IBaseAction<Partial<IFileUploadState>>) {
            Object.assign(state.uploadState.fileUpload, payload);
        },
        updateCurrentRole(state, { payload }: IBaseAction<AssignmentRole>) {
            state.toolState.currentRole = payload;
        },
        updateCurrentReader(state, { payload }: IBaseAction<string>) {
            state.toolState.currentReader = payload;
        },
        updateIsAnnotationChanged(state, { payload }: IBaseAction<boolean>) {
            state.toolState.isAnnotationChanged = payload;
        },
        updateActiveShapeType(state, { payload }: IBaseAction<AnnotationShapeType>) {
            state.toolState.activeShapeType = payload;
        },
        updatePreAnnotationActivated(state, { payload }: IBaseAction<boolean>) {
            state.toolState.preAnnotationState.active = payload;
        },
        updateIsPreAnnotationLabelSelectionIsOpen(state, { payload }: IBaseAction<boolean>) {
            state.toolState.preAnnotationState.modalOpen = payload;
        },
        updatePreAnnotationLabelSelection(state, { payload }: IBaseAction<LabelSelection>) {
            state.toolState.preAnnotationState.labelSelection = payload;
        },
        updatePreAnnotationModelId(state, { payload }: IBaseAction<string>) {
            state.toolState.preAnnotationState.modelId = payload;
        },
        updatePreAnnotationEditMode(state, { payload }: IBaseAction<boolean>) {
            state.toolState.preAnnotationState.editMode = payload;
        },
        updatePreAnnotationState(state, { payload }: IBaseAction<Partial<PreAnnotationState>>) {
            Object.assign(state.toolState.preAnnotationState, payload);
        },
        updateVisualComparisonState(state, { payload }: IBaseAction<Partial<IVisualComparisonState>>) {
            Object.assign(state.visualComparisonState, payload);
        },
        updateVisualComparisonStudyId(state, { payload }: IBaseAction<string>) {
            state.visualComparisonState.study_id = payload;
        },
        updateVisualComparisonViewportState(state, { payload }: IBaseAction<Array<IViewportState>>) {
            state.visualComparisonState.viewportState = payload;
        },
        updateVisualComparisonViewportStateItem(state, { payload }: IBaseAction<{ key: number; value: IViewportState }>) {
            state.visualComparisonState.viewportState[payload.key] = payload.value;
        },
        updatePreAnnotationConfig(state, { payload }: IBaseAction<PreAnnotationConfig>) {
            Object.assign(state.preAnnotationConfig, payload);
        },
        updateLabelingConfig(state, { payload }: IBaseAction<string>) {
            state.preAnnotationConfig.labelingConfig = payload;
        },
        updatePreAnnotationUserUpdateModalState(state, { payload }: IBaseAction<UserUpdateModalState>) {
            state.preAnnotationConfig.userUpdateModalState = payload;
        },
        updateAnnotationCreateState(state, { payload }: IBaseAction<Partial<IAnnotationCreateState>>) {
            Object.assign(state.annotationCreateState, payload);
        },
        updateAnnotationCreateDatasetFilters(state, { payload }: IBaseAction<Partial<IProjectFilters>>) {
            Object.assign(state.annotationCreateState.datasetFilters, payload);
        },
        updateImageEvaluationState(state, { payload }: IBaseAction<Partial<IImageEvaluationState>>) {
            Object.assign(state.annotationCreateState.imageEvaluationState, payload);
        },
        updateObjectComparisonLabel(state, { payload }: IBaseAction<string>) {
            state.objectComparisonState.label = payload;
        },
        updateObjectComparisonSeriesId(state, { payload }: IBaseAction<string>) {
            state.objectComparisonState.seriesId = payload;
        },
        updateObjectComparisonImageIdIndex(state, { payload }: IBaseAction<number>) {
            state.objectComparisonState.imageIdIndex = payload;
        },
        updateObjectComparisonStudyId(state, { payload }: IBaseAction<string>) {
            state.objectComparisonState.studyId = payload;
        },
        updateObjectComparisonLabelModalOpen(state, { payload }: IBaseAction<boolean>) {
            state.objectComparisonState.labelModalOpen = payload;
        },
        updateObjectComparisonUserStateMap(state, { payload }: IBaseAction<Record<string, Partial<UserState>>>) {
            _merge(state.objectComparisonState.userStateMap, payload);
        },
        setObjectComparisonUserStateMap(state, { payload }: IBaseAction<Record<string, UserState>>) {
            state.objectComparisonState.userStateMap = payload;
        },
        updateObjectComparisonState(state, { payload }: IBaseAction<Partial<IVisualComparisonState>>) {
            Object.assign(state.objectComparisonState, payload);
        },
    },
});

export const {
    updateCurrentProjectState,
    updateToolState,
    updateProjectFilters,
    updateCommonState,
    updateUploadState,
    updateStudySessionId,
    updateAnnotationFileUploadState,
    updateCurrentRole,
    updateCurrentReader,
    updateIsAnnotationChanged,
    updateActiveShapeType,
    updateVisualComparisonState,
    updateVisualComparisonStudyId,
    updateVisualComparisonViewportState,
    updateVisualComparisonViewportStateItem,
    updatePreAnnotationConfig,
    updateLabelingConfig,
    updatePreAnnotationActivated,
    updateAnnotationCreateState,
    updateAnnotationCreateDatasetFilters,
    updateImageEvaluationState,
    updatePreAnnotationLabelSelection,
    updateIsPreAnnotationLabelSelectionIsOpen,
    updatePreAnnotationModelId,
    updateStudySessionStartTime,
    updatePreAnnotationState,
    updatePreAnnotationEditMode,
    updatePreAnnotationUserUpdateModalState,
    updateObjectComparisonImageIdIndex,
    updateObjectComparisonLabel,
    updateObjectComparisonSeriesId,
    updateObjectComparisonStudyId,
    updateObjectComparisonLabelModalOpen,
    updateObjectComparisonUserStateMap,
    setObjectComparisonUserStateMap,
    updateObjectComparisonState,
} = AnnotationSlice.actions;

export default AnnotationSlice.reducer;
