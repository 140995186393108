import { StorageHelper } from '.';
import { EmbeddingStatus } from '../SAMPredictionHelper/entities';

export enum StorageType {
    'EmbeddingStatus' = 'EmbeddingStatus',
    'EmbeddingDownloadProgress' = 'EmbeddingDownloadProgress',
}

export const EmbeddingStatusStorage = new StorageHelper<StorageTypeMap[StorageType.EmbeddingStatus]>(StorageType.EmbeddingStatus);
export const EmbeddingDownloadProgressStorage = new StorageHelper<StorageTypeMap[StorageType.EmbeddingDownloadProgress]>(
    StorageType.EmbeddingDownloadProgress
);

export const StorageList = {
    [StorageType.EmbeddingStatus]: EmbeddingStatusStorage,
    [StorageType.EmbeddingDownloadProgress]: EmbeddingDownloadProgressStorage,
};

export interface StorageTypeMap {
    [StorageType.EmbeddingStatus]: EmbeddingStatus;
    [StorageType.EmbeddingDownloadProgress]: number;
}
