import { Collapse } from 'antd';
import { UserNotificationsView, _entries, getMessages } from 'common';
import { MarkAll } from 'components/assets/icons';
import useNotificationOverlay from '../hooks/useNotificationOverlay';
import NotificationItem from './NotificationItem';
import RecentNotifications from './RecentNotifications';
import Styles from './Styles.module.scss';
const { Panel } = Collapse;

export default function NotificationList({ type }: IProps) {
    const { onMarkAllAsRead, titleGrouped } = useNotificationOverlay(type as any);

    return (
        <div className={Styles.NotificationListContainer}>
            <div className={Styles.NotificationItemHeader}>
                <MarkAll style={{ color: '#00ffa7' }} />
                <span
                    onClick={onMarkAllAsRead}
                    className="pBody-bold primary"
                >
                    {getMessages('000221')}
                </span>
            </div>

            <div className={Styles.NotificationList}></div>

            {type === 'recentMessages' ? (
                <RecentNotifications />
            ) : (
                <Collapse className={Styles.CollapseWrapper}>
                    {_entries(titleGrouped).map(([key, value]) => (
                        <Panel
                            className={Styles.CollapsePanel}
                            header={renderCollapseHeader(key as string, value.filter((item: any) => !item.isRead).length)}
                            key={key}
                        >
                            {value.map((item: any) => (
                                <NotificationItem
                                    item={item}
                                    key={item._id}
                                />
                            ))}
                        </Panel>
                    ))}
                </Collapse>
            )}
        </div>
    );
}

function renderCollapseHeader(item: string, unReadCount: number) {
    return (
        <div className={Styles.CollapsePanelContainer}>
            <span>{item}</span>
            {unReadCount !== 0 && (
                <div className={Styles.CollapsePanelBadge}>
                    <span>{unReadCount > 9 ? '+9' : unReadCount}</span>
                </div>
            )}
        </div>
    );
}

interface IProps {
    type: UserNotificationsView | 'recentMessages';
}
