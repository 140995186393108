import { Dictionary } from 'common';
import { IPredictionStats } from '../views/ModelDetails/components/DetailsContentSection/views/PredictionExplorer/components/PredictionTable';

export interface IPredictionExplorerState {
    selectedPredictionList: Array<string>;
    selectedFilters: Dictionary<any>;
    selectedDates: Array<string>;
    isRawModalVisible: boolean;
    selectedPrediction: string;
    currentDataSource: Array<IPredictionStats>;
    allSelected: boolean;
}

export function PredictionExplorerStateFactory(data?: Partial<IPredictionExplorerState>): IPredictionExplorerState {
    return {
        selectedPredictionList: data?.selectedPredictionList ?? [],
        selectedFilters: data?.selectedFilters ?? {},
        selectedDates: data?.selectedDates ?? [],
        isRawModalVisible: data?.isRawModalVisible ?? false,
        selectedPrediction: data?.selectedPrediction ?? null,
        currentDataSource: data?.currentDataSource ?? [],
        allSelected: data?.allSelected ?? false,
    };
}
