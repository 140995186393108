export function IsIncludeOrEqual(reference: any, value: string | Array<string> | boolean, intersection?: boolean): boolean {
    if (!reference || !value) return false;
    if (intersection) {
        if (Array.isArray(reference)) {
            if (Array.isArray(value)) {
                return reference.some(r => value.includes(r));
            }
            return reference?.includes(value);
        }
        if (Array.isArray(value)) {
            return value.includes(reference);
        }
    }
    if (Array.isArray(reference)) {
        return reference?.includes(value);
    }

    return reference === value;
}