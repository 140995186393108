import { INotification } from 'app/entities';
import { RootState } from 'redux/store';
import { createSelector } from 'reselect';

export const selectSessionReminderVisible = (state: RootState) => state.app.common.sessionReminderVisible;
export const selectNotifications = (state: RootState) => state.app.notifications;
export const selectRoutingSource = (state: RootState) => state.app.routing.source;
export const selectEnvConfiguration = (state: RootState) => state.app.common.envConfiguration;
export const selectOrganizationMembers = (state: RootState) => state.app.common.organizationMembers;
export const selectConfigFailModalVisible = (state: RootState) => state.app.common.configFailModalVisible;
export const selectedNotificationVisible = (state: RootState) => state.app.common.selectedNotificationVisible;
export const selectedNotification = (state: RootState) => state.app.common.selectedNotification;
export const notificationReportStep = (state: RootState) => state.app.common.notificationReportStep;
export const selectErrorLogs = (state: RootState) => state.app.errorLogs;
export const selectIsAppVersionModalOpen = (state: RootState) => state.app.common.isAppVersionModalOpen;

export const selectUnreadNotifications = createSelector<any, Array<INotification>>(
    selectNotifications,
    (notifications: Array<INotification>) => notifications?.filter(item => !item.isRead && !item.reported)
);

export const selectAllNotifications = createSelector<any, Array<INotification>>(
    selectNotifications,
    (notifications: Array<INotification>) => notifications?.filter(item => !item.reported)
);

export const selectActivityNotifications = createSelector<any, Array<INotification>>(
    selectNotifications,
    (notifications: Array<INotification>) => notifications?.filter(item => item.type !== 'error' && item.type !== 'warning')
);

export const selectSystemNotifications = createSelector<any, Array<INotification>>(
    selectNotifications,
    (notifications: Array<INotification>) => notifications?.filter(item => item.type === 'error' && !item.reported)
);

export const selectReportedNotifications = createSelector<any, Array<INotification>>(
    selectNotifications,
    (notifications: Array<INotification>) => notifications?.filter(item => item.reported === true)
);

export const selectRecentNotifications = createSelector<any, Array<INotification>>(
    selectNotifications,
    (notifications: Array<INotification>) => notifications?.filter(item => !item.reported).splice(0, 5)
);

export const selectUnreadNotificationCount = createSelector<any, number>(
    selectUnreadNotifications,
    (notifications: Array<INotification>) => notifications?.length
);
