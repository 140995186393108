import CloseCircleOutlined from "@ant-design/icons/CloseCircleOutlined";
import { Progress } from "antd";
import { Delete, ZipFile } from "components/assets/icons";
import Styles from "./Styles.module.scss";
import { TUploadStatus } from '.';

export default function UploadProgress(props: IUploadProgressProps) {

    function onCancelUpload() {
        props.onCancelUpload?.();
    }

    function onDeleteFile() {
        props.onDeleteFile?.();
    }

        return (
            <>
                <div className={[Styles.FileListContainer, props.className].join(" ")}>
                    <ZipFile />
                    <div className={Styles.FileItemContainer} >
                        <div className={Styles.ProgressContainer} >
                            {props?.fileName}
                            <Progress strokeColor={props.uploadStatus !== "exception" ? "#00ffa7" : undefined} status={props.uploadStatus as any} percent={props.uploadProgress} />
                        </div>
                        {props.uploadStatus === "active"
                            ? <CloseCircleOutlined onClick={onCancelUpload} className="cancel-icon" />
                            : <div onClick={onDeleteFile} className="delete-icon"><Delete /></div>}
                    </div>
                </div>
            </>
        )
}

interface IUploadProgressProps {
    className?: string;
    fileName?: string;
    uploadProgress?: number;
    uploadStatus?: TUploadStatus;
    onCancelUpload?: () => void;
    onDeleteFile?: () => void;
}