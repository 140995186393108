export interface IProject {
    id: string;
    isArchived?: boolean;
    projectId: string;
    name: string;
    description?: string;
    datasetList: Array<string>;
    modelList: Array<string>;
    validationList: any;
    includeAllValidation: { "enabled": boolean; };
    accessOptions: IAccessOptions;
    createdAt: number;
    updated: number;
    access?:string;
    annotationList: Array<string>;
    lastActivity?: number;
}

export interface IProjectResponse {
    annotation_list: any;
    project_id: string;
    name: string;
    description?: string;
    dataset_list: Array<string>;
    model_list: Array<string>;
    validation_list: any;
    includeAllValidation: { "enabled": boolean; };
    access_options: IAccessOptions;
    createdAt: number;
    updated: number;
    isArchived?: boolean;
    last_activity?: number;

}

export interface IProjectRequest extends Partial<IProject> {
    access:string,
    permission_list_changes: Array<string>,
    remove_permission_list: Array<string>,
}

export function getProjectData(data?: IProjectResponse): IProject {
    return {
        projectId: data?.project_id,
        name: data?.name,
        createdAt: data?.createdAt,
        description: data?.description,
        datasetList: data?.dataset_list,
        modelList: data?.model_list,
        validationList: data?.validation_list,
        includeAllValidation: data?.includeAllValidation,
        accessOptions: data?.access_options,
        updated: data?.updated,
        isArchived: data?.isArchived ?? false,
        id: data?.project_id,
        annotationList: data?.annotation_list,
        lastActivity: data?.last_activity,
    };
}
export function transformDataForRequest(data: Partial<IProjectRequest>) {
    return {
        project_id: data?.projectId,
        name: data?.name,
        createdAt: data?.createdAt,
        description: data?.description,
        dataset_list: data?.datasetList,
        model_list: data?.modelList,
        validation_list: data?.validationList,
        annotation_list: data?.annotationList,
        includeAllValidation: data?.includeAllValidation,
        access:data?.access,
        permission_list_changes: data?.permission_list_changes,
        remove_permission_list: data?.remove_permission_list,
        updated: data?.updated,
        
    };
}

export interface IAccessOptions {
    access: "public" | "private";
    owner: string;
    permission_list: Array<IPermissionList>;
}

export interface IPermissionList {
    user: string;
    timestamp: number;
    type: string;
}