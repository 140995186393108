import { Dictionary } from 'common';
import { NotificationCenterItem } from 'react-toastify/addons/use-notification-center';

export interface INotificationsProps {
    notifications: NotificationCenterItem[];
    unReadCount: number;
}
export interface IUserSettings {
    notificationPreferences: Dictionary<boolean>;
    isHideReadNotifications: boolean;
    isNotificationDropdownOpen: boolean;
    isUserDropdownOpen: boolean;
    isClipboardOpen: boolean;
    isMFAModalOpen: boolean;
}

export function UserSettingsFactory(data?: Partial<IUserSettings>): IUserSettings {
    return {
        notificationPreferences: data.notificationPreferences ?? {},
        isHideReadNotifications: data.isHideReadNotifications ?? false,
        isNotificationDropdownOpen: data.isNotificationDropdownOpen ?? false,
        isUserDropdownOpen: data.isUserDropdownOpen ?? false,
        isClipboardOpen: data.isClipboardOpen ?? false,
        isMFAModalOpen: data.isMFAModalOpen ?? false,
    };
}

export interface MFASecret {
    /**
     * ASCII representation of the secret
     */
    ascii: string;
    /**
     * Hex representation of the secret
     */
    hex: string;
    /**
     * Base32 representation of the secret
     */
    base32: string;
    /**
     * URL for the QR code for the ASCII secret.
     *
     * @deprecated use a separate QR code library
     */
    qr_code_ascii?: string | undefined;
    /**
     * URL for the QR code for the hex secret.
     *
     * @deprecated use a separate QR code library
     */
    qr_code_hex?: string | undefined;
    /**
     * URL for the QR code for the base32 secret.
     *
     * @deprecated use a separate QR code library
     */
    qr_code_base32?: string | undefined;
    /**
     * URL for the Google Authenticator otpauth
     * URL's QR code.
     *
     * @deprecated use a separate QR code library
     */
    google_auth_qr: string;
    /**
     * Google Authenticator-compatible otpauth URL.
     */
    otpauth_url?: string | undefined;
}
