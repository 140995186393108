import { TUploadStatus } from "components/GUpload";
import { IUploadParams, UploadParamsFactory } from "./IUploadParams";

export interface IUploadDatasetState {
    uploadLoading: boolean;
    uploadParams: IUploadParams;
    thumbnailList: Array<string>;
    uploadStatus: TUploadStatus;
    uploadProgress: number;
}

export function UploadDatasetStateFactory(data?: IUploadDatasetState): IUploadDatasetState {
    return {
        uploadLoading: data?.uploadLoading || false,
        uploadParams: UploadParamsFactory(data?.uploadParams),
        thumbnailList: data?.thumbnailList || [],
        uploadStatus: data?.uploadStatus || null,
        uploadProgress: data?.uploadProgress || undefined,
    };
}

export type { IUploadParams };

