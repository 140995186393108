import { PayloadAction } from "@reduxjs/toolkit";
import { IDatasetFilters, _entries } from "common";
import { WritableDraft } from "immer/dist/internal";
import { IDatasetState } from "pages/Datasets/entities";
import { IDatasetSelection } from "../entities";
import { IDatasetCreation } from "../entities/IDatasetCreation";
import { IImageFiltering } from "../entities/IImageFiltering";

export const datasetCurationReducer = {
    setSelectedDatasets(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Array<IDatasetSelection>>) {
        state.datasetCurationState.selectedDatasets = payload;
    },

    updateSelectedDatasets(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<IDatasetSelection>) {
        state.datasetCurationState.selectedDatasets = state.datasetCurationState.selectedDatasets.map(item => item.datasetId === payload.datasetId ? payload : item);
    },

    updateMetadataFilterFields(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<any>) {

        let index = state.datasetCurationState.imageFiltering.selectedMetadataFilters?.findIndex((item: { nameList: any[]; }) => item?.nameList?.some((p: any) => payload?.nameList?.includes(p)));
        if (index >= 0) {
            state.datasetCurationState.imageFiltering.selectedMetadataFilters[index] = payload;
        } else {
            state.datasetCurationState.imageFiltering.selectedMetadataFilters?.push(payload);
        }
    },

    updateImageFilteringState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IImageFiltering>>) {
        _entries(payload).forEach(([key, value]) => {
            (state.datasetCurationState.imageFiltering as any)[key] = value;
        });
    },

    updateDatasetCreationState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IDatasetCreation>>) {
        _entries(payload).forEach(([key, value]) => {
            (state.datasetCurationState.datasetCreation as any)[key] = value;
        });
    },

    updateCurationDatasetFiltering(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IDatasetFilters>>) {
        _entries(payload).forEach(([key, value]: [keyof IDatasetFilters, never]) => {
            state.datasetCurationState.datasetFilters[key] = value;
        }
        );
    },
};

