import React from 'react';
import AppProvider from './AppProvider';
import './assets/Style.scss';

export default function App() {
    return (
        <React.Fragment>
            <AppProvider />
        </React.Fragment>
    );
}
