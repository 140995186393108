import { updateCommonState } from 'app/store';
import cs from 'classnames';
import { useDispatch } from 'react-redux';
import Styles from './Styles.module.scss';

export default function AppVersion() {
    const dispatch = useDispatch();

    return (
        <div
            onClick={() => dispatch(updateCommonState({ isAppVersionModalOpen: true }))}
            className={cs('pBody-medium white', Styles.Version)}
        >
            {process.env.REACT_APP_VERSION}
        </div>
    );
}
