import { ExportedMetricsStateFactory, IExportedMetricsState } from '../views/exportedMetrics/entities';
import {
    CommonStateFactory,
    DefaultTablesFactory,
    ExternalModelValidationFactory,
    IExternalModelValidationState,
} from '../views/externalValidation/entities';
import { IStartValidationPageState, StartValidationPageStateFactory } from '../views/startValidation/entities';
import { ICommonState, ISummaryPageData, SummaryPageDataFactory } from '../views/summary/entities';
import {
    BlindSpotAnalysisStateFactory,
    IBlindSpotAnalysisState,
} from '../views/validationAnalysis/components/blindSpotAnalysis/entities';
import {
    IValidationComparisonState,
    ValidationComparisonStateFactory,
} from '../views/validationAnalysis/components/comparison/entities';
import { IMetricsState, MetricsStateFactory } from '../views/validationAnalysis/components/metrics/entities';
import {
    IValidationSummaryState,
    IValidationSummaryStateFactory,
} from '../views/validationAnalysis/components/newSummary/entities';

interface IValidationState {
    summary: ISummaryPageData;
    metrics: IMetricsState;
    comparison: IValidationComparisonState;
    startValidation: IStartValidationPageState;
    blindSpotAnalysis: IBlindSpotAnalysisState;
    validationSummary: IValidationSummaryState;
    externalModelValidation: IExternalModelValidationState;
    commonState: ICommonState;
    defaultTables?: Dictionary<string>;
    exportedMetrics?: IExportedMetricsState;
}

export const ValidationStateFactory = (): IValidationState => ({
    summary: SummaryPageDataFactory(),
    metrics: MetricsStateFactory(),
    comparison: ValidationComparisonStateFactory(),
    startValidation: StartValidationPageStateFactory(),
    blindSpotAnalysis: BlindSpotAnalysisStateFactory(),
    validationSummary: IValidationSummaryStateFactory(),
    externalModelValidation: ExternalModelValidationFactory(),
    commonState: CommonStateFactory(),
    defaultTables: DefaultTablesFactory(),
    exportedMetrics: ExportedMetricsStateFactory(),
});

export type { IValidationState };

export * from './ValidationResult';
