import { createAction } from '@reduxjs/toolkit';
import { IUserInfo } from '../entities';
import { ISetupConfigurationPayload } from '../entities/interfaces';
import { ILoginRequestPayload, INewPasswordRequestPayload } from '../entities/payload';

export const APP_START_TRANSACTION = 'APP.APP_START_TRANSACTION';
export const USER_LOGIN_TRANSACTION = 'APP.USER_LOGIN_TRANSACTION';
export const USER_NEW_PASSWORD_TRANSACTION = 'APP.USER_NEW_PASSWORD_TRANSACTION';
export const REGISTER_NEW_USER_TRANSACTION = 'APP.USER_REGISTER_NEW_USER_TRANSACTION';
export const FORGOT_PASSWORD_TRANSACTION = 'FORGOT_PASSWORD_TRANSACTION';
export const ACTIVATION_TOKEN_TRANSACTION = 'ACTIVATION_TOKEN_TRANSACTION';
export const UPDATE_SESSION = 'UPDATE_SESSION';
export const UPDATE_USER_ACCOUNT_INFO = 'UPDATE_USER_ACCOUNT_INFO';
export const SETUP_CONFIGURATION = 'SETUP_CONFIGURATION';

export const appStartTransaction = createAction(APP_START_TRANSACTION);
export const userLoginTransaction = createAction<ILoginRequestPayload>(USER_LOGIN_TRANSACTION);
export const userNewPasswordTransaction = createAction<INewPasswordRequestPayload>(USER_NEW_PASSWORD_TRANSACTION);
export const registerNewUser = createAction<{ email: string }>(REGISTER_NEW_USER_TRANSACTION);
export const forgotPasswordTransaction = createAction<{ email: string }>(FORGOT_PASSWORD_TRANSACTION);
export const ActivationTokenTransaction = createAction<any>(ACTIVATION_TOKEN_TRANSACTION);
export const updateSession = createAction(UPDATE_SESSION);
export const updateUserAccountInformation = createAction<{ userInfo: IUserInfo }>(UPDATE_USER_ACCOUNT_INFO);
export const setupConfiguration = createAction<ISetupConfigurationPayload>(SETUP_CONFIGURATION);
