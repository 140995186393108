import { PlatformService, getMessages } from 'common';
import { Notification } from 'components';

export function* startModelTrainingSaga(sagaAction: any): any {
    try {
        const { data } = yield PlatformService.MLUtility.TrainModel.call(sagaAction?.payload?.config, {
            params: sagaAction?.payload?.params,
            headers: { 'user-id': sagaAction?.payload.user?.userId },
        });

        if (data?.status_code === '202') {
            Notification.success({ content: getMessages('001488') });
        }
    } catch (e) {
        console.log(e);
    }
}
