import { Dictionary, _mapValues, _toNumber } from "common";
import { IValidationPlotResponse } from "..";


export interface IClassPerformanceResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: string;
        data: IClassPerformanceResponseData;
    };
}

export interface IClassPerformanceResponseData {
    Validation: IClassPerformanceItem;
    Training: IClassPerformanceItem;
}


export interface IClassPerformanceItem {
    Accuracy: Dictionary<number>,
    Specificity: Dictionary<number>,
    Sensitivity: Dictionary<number>,
    Precision: Dictionary<number>,
}


export interface IClassPerformanceData {
    Validation: IClassPerformanceItem;
    Training?: IClassPerformanceItem;
}

const getClassPerformanceItem = (item: IClassPerformanceItem): IClassPerformanceItem => _mapValues(item, formatValue);

const formatValue = (classItem: Dictionary<number>) => _mapValues(classItem, value => _toNumber(value?.toFixed?.(3)) ?? undefined);

export function getClassPerformanceData(payload: IClassPerformanceResponseData): IClassPerformanceData {

    const result: any = {};

    if (payload?.Validation) {
        result.Validation = getClassPerformanceItem(payload.Validation);
    }

    if (payload?.Training) {
        result.Training = getClassPerformanceItem(payload.Training);
    }

    return result;

}