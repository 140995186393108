import { getMessages } from 'common/helpers';
import Notification from 'components/GNotification';
import React, { useState } from 'react';

export function useTagEditor({ onChange, tagList }: IArgs) {
    const [inputVisible, setInputVisible] = useState(false);
    const [inputValue, setInputValue] = useState("");
    const [editInputIndex, setEditInputIndex] = useState(-1);
    const [editInputValue, setEditInputValue] = useState("");


    function handleDelete(removedTag: string) {
        onChange?.(tagList?.filter(tag => tag !== removedTag) || []);
    }

    function showInput() {
        setInputVisible(true);
    }

    function handleInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setInputValue(e.target.value);
    }

    function handleEditInputChange(e: React.ChangeEvent<HTMLInputElement>) {
        setEditInputValue(e.target.value);
    }

    function handleInputConfirm() {
        const isUnique = !tagList?.includes(inputValue);
        if (inputValue && isUnique) {
            onChange?.(tagList ? [...tagList, inputValue] : [inputValue]);

            setInputVisible(false);
            setInputValue("");
        } else if (inputValue) {
            Notification.warning({ content: getMessages("000364") });
        } else {
            setInputVisible(false);
            setInputValue("");
        }
    }

    function handleCancelEdit(key: string) {
        if (key === "Escape") {
            setInputVisible(false);
            setInputValue("");
            setEditInputIndex(-1);
            setEditInputValue("");
        }
    }

    function handleEditInputConfirm() {
        const isUnique = tagList?.every((tag, i) => i !== editInputIndex ? tag !== editInputValue : true);
        if (editInputValue && isUnique) {

            const tags = [...tagList];

            tags[editInputIndex] = editInputValue;

            onChange(tags);

            setEditInputIndex(-1);
            setEditInputValue("");
        } else if (editInputValue) {
            Notification.warning({ content: getMessages("000364") });
        } else {
            setEditInputIndex(-1);
            setEditInputValue("");
        }
    }

    return { inputVisible, inputValue, editInputIndex, editInputValue, handleDelete, showInput, handleInputChange, handleEditInputChange, handleInputConfirm, handleCancelEdit, handleEditInputConfirm, setEditInputIndex, setEditInputValue };
}

export interface IArgs {
    tagList?: Array<string>;
    onChange?: (key: Array<string>) => void;
}