import { AxiosResponse } from "axios";
import { APIService } from "common/services/serviceList";
import { IImageResponse, ImageFactory } from "common/entities";

export async function getImageDetails({ imageId, datasetId }: IGetImageDetailsArgs) {
    try {
        if (!imageId || !datasetId) return;

        const response: AxiosResponse<IImageResponse> = await APIService.Datasets.GetImageDetails.get({
            params: { image_id: imageId, dataset_id: datasetId },
        });
        const data = datasetId === "playground" ? (response?.data as any)?.[0] : response?.data;
        return ImageFactory(data);

    } catch (error) {
        console.error(error);
    }
}

interface IGetImageDetailsArgs {
    imageId: string;
    datasetId: string;
}