import { INotification } from 'app/entities';
import { MarkAsReadService } from 'app/hooks/MarkAsReadService';
import { updateCommonState } from 'app/store';
import cx from 'classnames';
import dayjs from 'dayjs';
import { useDispatch } from 'react-redux';
import Styles from './Styles.module.scss';

export default function NotificationItem({ item }: IProps) {
    const dispatch = useDispatch();

    return (
        <div
            className={Styles.NotificationItem}
            onClick={() => {
                dispatch(
                    updateCommonState({
                        selectedNotificationVisible: true,
                        notificationReportStep: 0,
                        selectedNotification: item,
                    })
                );

                !item.isRead && MarkAsReadService([item.id]);
            }}
        >
            <div className={Styles.Content}>
                <span className={cx(Styles.ReadMark, { [Styles.NotRead]: !item.isRead })} />

                <div className={Styles.MessageWrapper}>
                    <div className={cx('pBody-bold', 'white', Styles.Message)}>{item.message}</div>
                    <div className={cx('caption2-normal grey', Styles.time)}>{dayjs.unix(item.createdAt).format('LLLL')}</div>
                </div>
            </div>
        </div>
    );
}

interface IProps {
    item: INotification;
}
