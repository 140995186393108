import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'common/entities';
import { UserSettingsFactory } from '../entities';

export const UserSettingsSlice = createSlice({
    name: 'User',
    initialState: UserSettingsFactory({}),
    reducers: {
        updateNotificationPreferences(state, { payload }: PayloadAction<Dictionary<boolean>>) {
            Object.assign(state.notificationPreferences ?? {}, payload);
        },
        isHideReadNotifications(state, { payload }: PayloadAction<boolean>) {
            state.isHideReadNotifications = payload;
        },
        setDropdownOpen(state, { payload }: PayloadAction<boolean>) {
            state.isNotificationDropdownOpen = payload;
        },
        setUserDropdownOpen(state, { payload }: PayloadAction<boolean>) {
            state.isUserDropdownOpen = payload;
        },
        setIsClipboardOpen(state, { payload }: PayloadAction<boolean>) {
            state.isClipboardOpen = payload;
        },
        setIsMFAModalOpen(state, { payload }: PayloadAction<boolean>) {
            state.isMFAModalOpen = payload;
        },
    },
});

export const {
    updateNotificationPreferences,
    isHideReadNotifications,
    setDropdownOpen,
    setUserDropdownOpen,
    setIsClipboardOpen,
    setIsMFAModalOpen,
} = UserSettingsSlice.actions;

export default UserSettingsSlice.reducer;
