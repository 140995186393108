import DeleteOutlined from '@ant-design/icons/DeleteOutlined';
import { Image, Upload, UploadProps } from 'antd';
import ImgCrop from 'antd-img-crop';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import React, { useState } from 'react';
import Styles from './Styles.module.scss';

interface GProfileUploadProps extends UploadProps {
    onImageChanged?: (file?: File) => void;
    defaultImageSrc?: string;
    showUploadImage?:boolean;
}

export default function GImageUpload(props: GProfileUploadProps) {
    const [fileList, setFileList] = useState<any>([]);
    const [croppedImage, setCroppedImage] = useState<File>();

    function onChange(e: UploadChangeParam<unknown>) {
        setFileList(e.fileList);
    }

    function onBeforeUpload(file: File) {
        props.onImageChanged?.(file);
        setCroppedImage(file);
        return false;
    }

    function onDeleteFile(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.preventDefault();
        e.stopPropagation();

        setFileList([]);
        setCroppedImage(undefined);
        props.onImageChanged?.();
    }

    return (
        <div className={Styles.UploadImageContainer}>
            <ImgCrop
                aspect={2}
                showGrid
            >
                <Upload
                    listType="picture-card"
                    fileList={fileList}
                    onChange={onChange}
                    maxCount={1}
                    beforeUpload={onBeforeUpload}
                    showUploadList={false}
                    id={props.id}
                >
                    {
                        <div className={Styles.PreviewImage}>
                            <Image
                                preview={false}
                                src={croppedImage && props.showUploadImage ? URL.createObjectURL(croppedImage) : props.defaultImageSrc}
                            />
                            <div
                                className={Styles.DeleteIcon}
                                onClick={onDeleteFile}
                            >
                                <DeleteOutlined />
                            </div>
                        </div>
                    }
                </Upload>
            </ImgCrop>
        </div>
    );
}
