import cs from 'classnames';
import GLoading from 'components/GLoading';
import CornerstoneViewport from 'react-cornerstone-viewport';
import Styles from './Styles.module.scss';
import { IViewportProps } from './entities/interfaces';
import { useViewport } from './hooks/useViewport';

export default function Viewport({ index, toolList, viewportClassName, viewportStyle }: IViewportProps) {
    const {
        activeViewport,
        cinePlay,
        frameRate,
        imageUrlList,
        loading,
        onActiveViewportChanged,
        onElementEnabled,
        prefetch,
        imageIndex,
    } = useViewport(index);

    return (
        <div
            style={viewportStyle}
            className={cs(Styles.Viewport, viewportClassName, { [Styles.Active]: activeViewport === index })}
            role="application"
        >
            <GLoading
                loading={loading}
                className={Styles.ViewportLoadingWrapper}
            >
                {Boolean(imageUrlList?.length) && (
                    <CornerstoneViewport
                        tools={toolList}
                        imageIds={imageUrlList}
                        imageIdIndex={imageIndex}
                        isPlaying={index === activeViewport ? cinePlay : false}
                        frameRate={frameRate}
                        className={Styles.CornerstoneViewport}
                        onElementEnabled={onElementEnabled}
                        setViewportActive={onActiveViewportChanged}
                        isStackPrefetchEnabled={prefetch}
                    />
                )}
            </GLoading>
        </div>
    );
}
