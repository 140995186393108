import { createAction } from "@reduxjs/toolkit";
import { Dictionary } from "common";
import { IModel } from "../entities";
import { IPreEmptiveOptions } from "../entities/IDeploymentTracking";
import { UploadModelActionPayload } from "../views/ModelUpload/entities";

export const GET_MODEL_LIST_TRANSACTION = 'GET_MODEL_LIST_TRANACTION';
export const GET_VENDOR_LIST_TRANSACTION = 'GET_VENDOR_LIST_TRANSACTION';
export const DOWNLOAD_ARTIFACTS_ACTION = 'DOWNLOAD_ARTIFACTS_ACTION';
export const UPLOAD_MODEL_FILES_TRANSACTION = 'UPLOAD_MODEL_FILES_TRANSACTION';
export const SUBMIT_MODEL_FORM_TRANSACTION = 'SUBMIT_MODEL_FORM_TRANSACTION';
export const EDIT_MODEL_FORM_TRANSACTION = 'EDIT_MODEL_FORM_TRANSACTION';
export const SUBMIT_MODEL_TRAINING_FORM_TRANSACTION = 'SUBMIT_MODEL_TRAINING_FORM_TRANSACTION';

export const DEPLOY_MODEL_TRANSACTION = 'DEPLOY_MODEL_TRANSACTION';
export const STOP_MODEL_TRANSACTION = 'STOP_MODEL_TRANSACTION';
export const ARCHIVE_MODEL_TRANSACTION = 'ARCHIVE_MODEL_TRANSACTION';
export const UNARCHIVE_MODEL_TRANSACTION = 'UNARCHIVE_MODEL_TRANSACTION';
export const DELETE_MODEL_TRANSACTION = 'DELETE_MODEL_TRANSACTION';
export const UPDATE_PREEMPTIVE_MODEL_TRANSACTION = 'UPDATE_PREEMPTIVE_MODEL_TRANSACTION';

export const START_MODEL_TRAINING_ACTION = 'START_MODEL_TRAINING_ACTION';
export const SET_MODEL_MODE_ACTION = 'SET_MODEL_MODE_ACTION';

export const getModelListTransaction = createAction(GET_MODEL_LIST_TRANSACTION);
export const getVendorListTransaction = createAction(GET_VENDOR_LIST_TRANSACTION);
export const downloadArtifactsAction = createAction(DOWNLOAD_ARTIFACTS_ACTION);
export const uploadModelFilesTransaction = createAction<UploadModelActionPayload>(UPLOAD_MODEL_FILES_TRANSACTION);
export const submitModelFormTransaction = createAction<Dictionary<any>>(SUBMIT_MODEL_FORM_TRANSACTION);
export const editModelFormTransaction = createAction<Dictionary<any>>(EDIT_MODEL_FORM_TRANSACTION);
export const updatePreEmptiveModelTransaction = createAction<Dictionary<any>>(UPDATE_PREEMPTIVE_MODEL_TRANSACTION);
export const submitModelTrainingFormTransaction = createAction<Dictionary<any>>(SUBMIT_MODEL_TRAINING_FORM_TRANSACTION);


export const deployModelTransaction = createAction<{ model_id: string, deployment_id: string, pre_emptive_options: IPreEmptiveOptions, target:string }>(DEPLOY_MODEL_TRANSACTION);
export const stopModelTransaction = createAction<Partial<IModel>>(STOP_MODEL_TRANSACTION);
export const archiveModelTransaction = createAction<{ model_id: string }>(ARCHIVE_MODEL_TRANSACTION);
export const unArchiveModelTransaction = createAction<{ model_id: string }>(UNARCHIVE_MODEL_TRANSACTION);
export const deleteModelTransaction = createAction<{ model_id: string }>(DELETE_MODEL_TRANSACTION);

export const startModelTrainingAction = createAction<any>(START_MODEL_TRAINING_ACTION);
export const setModelModeAction = createAction<any>(SET_MODEL_MODE_ACTION);