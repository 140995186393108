import { CornerstoneToolEvents, IRectangleHandles, MeasurementEvent } from 'common/helpers/DicomViewerHelper/interface';
import { Image } from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { EventData, samPredictionHelper } from '../../SAMPredictionHelper';
import { RectMeasurementFactory } from './entities';

const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');
const rectangleRoiCursor = cornerstoneTools.importInternal('tools/cursors').rectangleRoiCursor;
const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext');
const draw = cornerstoneTools.importInternal('drawing/draw');
const setShadow = cornerstoneTools.importInternal('drawing/setShadow');
const drawRect = cornerstoneTools.importInternal('drawing/drawRect');
const { getToolState, getModule, toolColors } = cornerstoneTools;

export class SAMRectPredictionTool extends BaseAnnotationTool {
    image: Image;
    constructor(props = {}) {
        const defaultProps = {
            name: 'SAMRectPrediction',
            supportedInteractionTypes: ['Mouse'],
            svgCursor: rectangleRoiCursor,
            configuration: {
                renderDashed: false,
            },
        };
        super(props, defaultProps);
        this.onMeasurementCompleted = this.onMeasurementCompleted.bind(this);
    }

    activeCallback(element: HTMLElement) {
        element.addEventListener(CornerstoneToolEvents.MEASUREMENT_COMPLETED, this.onMeasurementCompleted);
    }
    disabledCallback(element: HTMLElement) {
        samPredictionHelper.rect = null;
        element.removeEventListener(CornerstoneToolEvents.MEASUREMENT_COMPLETED, this.onMeasurementCompleted);
    }
    onMeasurementCompleted(event: CustomEvent<MeasurementEvent<IRectangleHandles>>) {
        if (event.detail.toolType !== this.name) return;
        const handles = event.detail.measurementData.handles;

        samPredictionHelper.getPrediction({
            image: this.image,
            element: event.detail.element,
            rect: [handles.start, handles.end],
        });
    }

    updateCachedStats() {}
    createNewMeasurement(eventData: EventData): any {
        if (!eventData?.currentPoints?.image) return;
        this.image = eventData.image;

        return RectMeasurementFactory(
            [eventData.currentPoints.image, eventData.currentPoints.image],
            eventData.viewport.rotation
        );
    }

    pointNearTool() {
        return false;
    }

    renderToolData(evt: CustomEvent<any>) {
        const toolData = getToolState(evt.currentTarget, this.name);

        if (!toolData) return;
        const eventData = evt.detail;
        const { element } = eventData;
        const lineDash = getModule('globalConfiguration').configuration.lineDash;
        const { renderDashed } = this.configuration;
        const context: CanvasRenderingContext2D = getNewContext(eventData.canvasContext.canvas);

        // Meta

        draw(context, (context: CanvasRenderingContext2D) => {
            // If we have tool data for this element - iterate over each set and draw it
            // const data = toolData.data[toolData.data.length - 1];

            const data = toolData.data?.[toolData.data.length - 1];
            if (!data?.visible) return;

            // Configure
            const color = toolColors.getColorIfActive(data);

            setShadow(context, this.configuration);

            const rectOptions: any = { color };

            if (renderDashed) {
                rectOptions.lineDash = lineDash;
            }

            // Draw
            drawRect(context, element, data.handles.start, data.handles.end, rectOptions, 'pixel', data.handles.initialRotation);
        });
    }
}
