import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { IValidationState } from "pages/Validation/entities";
import { IBaseAction } from "redux/entities";
import { IBlandAtmanPlatformResponse, IExternalValidationTrackingResponse, IResultModalState } from "../entities";

export const ExternalModelValidationReducer = {
    updateResultModalState(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IResultModalState>>) {
        Object.assign(state.externalModelValidation.resultModal, payload);
    },
    setSelectedValidationMetric(state: WritableDraft<IValidationState>, { payload }: IBaseAction<IExternalValidationTrackingResponse>) {
        state.externalModelValidation.selectedValidation = payload;
    },
    setBlandAtmanResponse(state: WritableDraft<IValidationState>, { payload }: IBaseAction<Partial<IBlandAtmanPlatformResponse>>) {
        state.externalModelValidation.blandAtmanResponse = payload;
    }
};