import { useEffect } from 'react';
import { unstable_BlockerFunction, unstable_useBlocker } from 'react-router-dom';

export const useBlocker = unstable_useBlocker;

export function usePrompt({ message, shouldBlock }: IArgs) {
    let blocker = useBlocker(shouldBlock);

    useEffect(() => {
        if (blocker.state === 'blocked') {
            let proceed = window.confirm(message);
            if (proceed) {
                // This timeout is needed to avoid a weird "race" on POP navigations
                // between the `window.history` revert navigation and the result of
                // `window.confirm`
                setTimeout(blocker.proceed, 0);
            } else {
                blocker.reset();
            }
        }
    }, [blocker, message]);

    useEffect(() => {
        if (blocker.state === 'blocked' && !shouldBlock) {
            blocker.reset();
        }
    }, [blocker, shouldBlock]);
}

interface IArgs {
    message: string;
    shouldBlock: boolean | unstable_BlockerFunction;
}
