import { useGetImageDetailsQuery } from 'app/store/APISlice';
import { selectActiveViewportState } from 'components/GDicomViewer/store';
import { useSelector } from 'react-redux';
import { useDataset } from '.';

export function useActiveImageDetails() {
    const dataset = useDataset();
    const activeViewportState = useSelector(selectActiveViewportState);
    const { currentData: activeImageDetails } = useGetImageDetailsQuery(
        { datasetId: dataset?.datasetId, imageId: activeViewportState?.imageId },
        { skip: !activeViewportState?.imageId || !dataset?.datasetId }
    );

    return activeImageDetails;
}
