/* eslint-disable eqeqeq */
import { PredicateType } from 'common/entities';
import { _concat } from './_concat';
import { _get } from './_get';

export function _updateList<T>(arr: Array<T>, type: ActionType, value: T, predicate?: PredicateType<T>): Array<T> {
    if (!arr || !type || !value) return [];

    if (!predicate) {
        switch (type) {
            case 'ADD':
                return _concat(arr, value as any);
            case 'REMOVE':
                return arr.filter(o => o != value);
            case 'UPDATE':
                return arr.map(o => (o == value ? value : o));
            case 'UPSERT':
                return arr.some(o => o == value) ? arr.map(o => (o == value ? value : o)) : _concat(arr, value as any);
            default:
                return arr;
        }
    }

    const cb = typeof predicate === 'function' ? predicate : (o: T) => _get(o, predicate as any);

    const index = arr.findIndex((o, i, a) => cb(o, i, a) == cb(value, i, a));

    switch (type) {
        case 'ADD':
            return _concat(arr, value as any);
        case 'REMOVE':
            return index == -1 ? arr : arr.filter((o, i) => i != index);
        case 'UPDATE':
            return index == -1 ? arr : arr.map((o, i) => (i == index ? value : o));
        case 'UPSERT':
            return index == -1 ? _concat(arr, value as any) : arr.map((o, i) => (i == index ? value : o));
        default:
            return arr;
    }
}

type ActionType = 'ADD' | 'REMOVE' | 'UPDATE' | 'UPSERT';
