import { isNullOrUndefined } from "common";

export interface IValidationMetricsDataTransactionPaylaod {
    dataType?: MetricsDataType;
    validationId?: string;
}

export enum MetricsDataType {
    confusionMatrix = "pcm",
    plotTopLosses = "ptl",
    thresholdCurve = "pcrtms",
    performanceByConfidence = "pcpbt",
    classPerformance = "pcp",
    datasetClassDistribution = "pcd",
    populationDistribution = "pmd",
    highlightedMetrics = "phom",
    basicModelStats = "psct",
    trainingVsValidationPerformance = "ptvcct",
    mostConfusedClasses = "pmc",
    mostConfusedImages = "pmcci",
    confidenceDistribution = "pchsd",
    rocCurve = "prms",
    predictionDistribution = "ppd",
    explorePredictions = "pep",
    precisionRecallCurve = "pprms",
    softmaxHistogram = "pspd",
    confidenceDistributionGTless = "pgchsd",
    plotTopMisses = "ptm",
    performanceByIoUThreshold = "ppbit",
    objectCounts = "poc",
    predictionGTDistribution = "ppvsdd",
    mismatchedPredictions = "mmp",
    liftChart = "plc",
    detailedMetrics = "pdmbmd",
    intersectionDistribution = "pid",
    violinChart = "pvg",
}

export const formatValue = (value: number) => {
    const result = !isNullOrUndefined(value) && !isNaN(Number(value)) ? Number(Number(value)?.toFixed?.(4)) : "N/A";

    return isNaN(result as any) ? "N/A" as any : result;
};
