import cs from 'classnames';
import { _range } from 'common/Utils/LangUtils';
import { getMessages } from 'common/helpers';
import GSearchInput from 'components/GInput/GSearchInput';
import GList from 'components/GList';
import { ExplorerBreadcrumb } from './ExplorerBreadcrumb';
import { ListItem } from './ListItem';
import Styles from './Styles.module.scss';
import { IDatasetImageExplorerProps } from './entities';
import { useDatasetImageExplorer } from './hooks/useDatasetImageExplorer';

export function DatasetImageExplorer({
    datasetId,
    className,
    selection,
    allowNavigation = true,
    onClickImage,
    excludeItems,
    ...props
}: IDatasetImageExplorerProps) {
    const {
        pageSize,
        loading,
        dataSource,
        onClickItem,
        dataset,
        level,
        selectedSeries,
        selectedStudy,
        studyList,
        seriesList,
        onClickStudyList,
        setSearch,
        setStudySearch,
        paginationConfig
    } = useDatasetImageExplorer({
        datasetId,
        allowNavigation,
        onClickImage,
        excludeItems,
    });


    return (
        <div className={Styles.DatasetImageExplorerContainer}>
            <div className={Styles.HeaderContainer}>
                <ExplorerBreadcrumb
                    dataset={dataset}
                    level={level}
                    selectedStudy={selectedStudy}
                    selectedSeries={selectedSeries}
                    studyList={studyList}
                    seriesList={seriesList}
                    onClickStudyList={onClickStudyList}
                    onClickItem={onClickItem}
                />

                <div className={Styles.SearchContainer}>
                    {level === 'study' && (
                        <GSearchInput
                            onSearch={setStudySearch}
                            className={Styles.Search}
                            placeholder={`${getMessages('000304')} ${level}`}
                            allowClear
                            id="dataset-details-study-search-input"
                        />
                    )}

                    {level === 'image' && (
                        <GSearchInput
                            onSearch={setSearch}
                            className={Styles.Search}
                            placeholder={`${getMessages('000304')} ${level}`}
                            allowClear
                            id="dataset-details-image-search-input"
                        />
                    )}
                </div>
            </div>

            <div>
                <GList
                    dataSource={loading ? _range(pageSize).map(id => ({ id })) : dataSource?.data}
                    rowKey="id"
                    loading={loading}
                    renderItem={item => (
                        <ListItem
                            item={item}
                            datasetId={datasetId}
                            loading={loading}
                            onClick={onClickItem}
                            key={item.id}
                            selection={selection}
                            level={level}
                        />
                    )}
                    grid={{ gutter: 10, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 6 }}
                    size="large"
                    className={cs(Styles.DatasetExplorer, className)}
                    pagination={
                        dataSource?.total > pageSize
                            ? paginationConfig
                            : false
                    }
                    {...props}
                />
            </div>
        </div>
    );
}
