import { URLSearchValues } from 'common/enum';
import { useSearchParams } from 'react-router-dom';

export function useGetSearchParamValue<T extends string = string>(paramName: string, defaultValue?: T) {
    const [searchParams] = useSearchParams();

    return (searchParams.get(paramName) as T) || defaultValue;
}

export function getValueFromUrl(paramName: URLSearchValues) {
    return new URLSearchParams(window.location.search).get(paramName);
}
