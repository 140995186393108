import { IImage, IImageResponse } from 'common/entities';

export function transformImages(imageList: Array<IImageResponse>, datasetId: string): Array<IImage> {
    const result = [];
    if (imageList?.length) {
        let i = 0;
        while (i < imageList?.length) {
            // For performance reasons
            const item = imageList[i];
            if (item) {
                result.push(transformImageResponse(item, datasetId));
            }
            ++i;
        }
    }
    return result;
}

export function transformImageResponse(item: IImageResponse, datasetId: string): IImage {
    if (!item) return null;
    return {
        imageId: item.image_id,
        sliceCount: item.n_slices,
        format: item.format,
        imageName: item.image_name,
        medicalMetadata: item.medical_metadata,
        datasetId,
        annotation: item.annotation,
        similarImagesList: item.similar_images_list,
        metadata: item.metadata,
        instanceId: item.instance_id,
        seriesId: item.series_id,
        studyId: item.study_id,
        entities: item.entities,
        imageStatistics: item.image_statistics,
    };
}
