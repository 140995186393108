import { AuthService, _isEmpty } from "common";
import { updateValidationMetricsViewState } from "pages/Validation/store";
import { put } from "redux-saga/effects";
import { defaultViewList, IViewItem } from "../../entities/IViews";

export function* getValidationMetricsViewSaga(): any {
    try {
        const response: { data: Array<IViewItem>; } = yield AuthService.GetValidationMetricsView.call();

        if (_isEmpty(response?.data)) yield put(updateValidationMetricsViewState({ viewList: defaultViewList }));
        else yield put(updateValidationMetricsViewState({ viewList: response.data }));

    } catch (err) {
        console.log(err);
    }
}