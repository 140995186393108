import { createAction } from "@reduxjs/toolkit";
import { MetricsDataType } from "../../metrics/entities";

export const GET_COMPARISION_STATIC_DATA_TRANSACTION = 'GET_COMPARISION_STATIC_DATA_TRANSACTION';

export const GET_COMPARISION_CHART_DATA_TRANSACTION = 'GET_COMPARISION_CHART_DATA_TRANSACTION'




export const getComparisonStaticDataTransaction = createAction<Array<string>>(GET_COMPARISION_STATIC_DATA_TRANSACTION);

export const getComparisonChartDataTransaction = createAction<IGetChartDataPayload>('GET_COMPARISION_CHART_DATA_TRANSACTION');

export interface IGetChartDataPayload {
    chartType: MetricsDataType;
    validationList: [string, string];
}