import cx from 'classnames';
import GList from 'components/GList';
import ListItem from './ListItem';
import Styles from './Styles.module.scss';
import { IDatasetExplorerItem, IDatasetExplorerProps } from './entities';

export function DatasetExplorer({
    className,
    datasetId,
    loading,
    selection,
    selectedImages,
    onClickImage,
    CustomListItem,
    ...props
}: IDatasetExplorerProps) {
    return (
        <>
            <GList<IDatasetExplorerItem>
                rowKey="id"
                loading={loading}
                renderItem={item =>
                    CustomListItem ? (
                        <CustomListItem
                            {...item}
                            datasetId={datasetId}
                            loading={loading as boolean}
                            onClick={onClickImage}
                            key={item.id}
                            selection={selection}
                            selectedImages={selectedImages}
                        />
                    ) : (
                        <ListItem
                            {...item}
                            datasetId={datasetId}
                            loading={loading as boolean}
                            onClick={onClickImage}
                            key={item.id}
                            selection={selection}
                            selectedImages={selectedImages}
                        />
                    )
                }
                grid={{ gutter: 10, xs: 1, sm: 2, md: 2, lg: 3, xl: 4, xxl: 6 }}
                size="large"
                className={cx(Styles.DatasetExplorer, className)}
                {...props}
            />
        </>
    );
}

export type { IDatasetExplorerItem, IDatasetExplorerProps } from './entities';
export { getExplorerDatasource } from './getExplorerDatasource';

