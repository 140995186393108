import { AxiosResponse } from "axios";
import { APIService, getMessages } from "common";
import { Notification } from "components";
import { IModelCardResponsePayload } from "pages/Models/entities";
import { call, } from "redux-saga/effects";
import { getModelListTransactionSaga } from "./modelListSaga";

export function* ModelArchiveSaga(sagaAction: any): any {
    try {

        const model_id = sagaAction.payload.model_id;

        const response: AxiosResponse<{ model: IModelCardResponsePayload; }> = yield APIService.Models.ArchiveModel.call({ model_id });

        if (response.data) {

            yield call(getModelListTransactionSaga);

            Notification.success({ content: getMessages("000414") });
        }

    } catch (error) {
        console.log(error);
    }

}

export function* ModelUnArchiveSaga(sagaAction: any): any {
    try {

        const model_id = sagaAction.payload.model_id;

        const response: AxiosResponse<{ model: IModelCardResponsePayload; }> = yield APIService.Models.UnArchiveModel.call({ model_id });

        if (response.data) {

            yield call(getModelListTransactionSaga);

            Notification.success({ content: getMessages("000418") });
        }

    } catch (error) {
        console.log(error);
    }

}