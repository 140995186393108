import { DeploymentStatus } from 'common/enum';
import { EventBus, RoutingHelper } from 'common/helpers';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { findModel, selectModelList } from 'redux/selectors';

export function useOpenPlayground() {
    const modelList = useSelector(selectModelList);
    const location = useLocation();

    function openPlayground({ model, ...params }: IOpenPlaygroundArgs) {
        const selectedModel = findModel(modelList, model);

        if (selectedModel?.deploymentStatus === DeploymentStatus.UP) {
            (params as any).model = model;
        }

        RoutingHelper.Playground({ params }).navigate();

        if (location?.pathname?.includes('playground')) {
            EventBus.emit('change-dataset', params);
        }
    }

    return useCallback(openPlayground, [location?.pathname, modelList]);
}

interface IOpenPlaygroundArgs {
    model?: string;
    dataset?: string;
    image?: string;
    study?: string;
}
