import { IMetricsExtra } from "../../metrics/entities";
import { ComparisonControlStateFactory, IComparisonControl } from "./ComparisonControl";
import { ComparisonMetricsFactory, IComparisonMetrics } from "./IComparisonMetrics";

export interface IValidationComparisonState {
    control: IComparisonControl;
    comparisonMetrics: IComparisonMetrics;
    comparisonExtra: IMetricsExtra;
}

export function ValidationComparisonStateFactory(data?: IValidationComparisonState): IValidationComparisonState {
    return {
        control: ComparisonControlStateFactory(data?.control),
        comparisonMetrics: ComparisonMetricsFactory(data?.comparisonMetrics),
        comparisonExtra: data?.comparisonExtra
    };
}

export * from "./ComparisonControl";
export * from "./IComparisonMetrics";
