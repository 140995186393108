export default function getBoundingBoxReferencePoints(start, end, width, height) {
    const BoundingBoxPoints = [];

    for (let i = 0; i < 360; i++) {
        let x = Math.min(x, width);
        x = Math.max(x, 0);

        let y = Math.min(y, height);
        y = Math.max(y, 0);

        BoundingBoxPoints.push({
            x,
            y,
        });
    }

    return BoundingBoxPoints;
}
