import { PlatformService } from 'common';

export function* setModelModeSaga(sagaAction: any): any {
    try {
        const params = sagaAction?.payload;
        const { data } = yield PlatformService.MLUtility.SetModelMode.call(null, {
            params,
        });

        return data;
    } catch (e) {
        console.log(e);
    }
}
