import { all, takeEvery } from "@redux-saga/core/effects";
import { getMessages, PlatformService } from "common";
import { Notification } from "components";
import * as actions from "./actions";

export default function* rootSaga() {
    yield all([
        takeEvery(actions.START_STUDY_VALIDATION_RUN_ACTION, startStudyValidationRunSaga),
    ]);
}

function* startStudyValidationRunSaga(sagaAction: any): any {
    try {
        const { data } = yield PlatformService.Prediction.StartStudyBatch.call(sagaAction.payload);
        if (data?.status_code === "202") {
            Notification.success({ content: getMessages("000310") });
        }
    } catch (e) {
        console.log(e);
    }
}