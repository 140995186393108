import { IBaseData } from "./interfaces";
import { TVarType } from "./types";

export function getVarType(value: any): TVarType {
    if (value === null) return 'null';
    if (value === undefined) return 'undefined';
    if (Array.isArray(value)) return 'array';
    return typeof value;
}

export function BaseDataFactory<T>(payload: Partial<IBaseData<T>>): IBaseData<T> {
    return {
        data: payload?.data,
        loading: payload?.loading ?? false,
        error: payload?.error ?? null,
        isFetched: payload?.isFetched ?? false,
    };
}