import { URLSearchValues } from 'common';
import { _isEmpty } from 'common/Utils';
import { useUpdateSearchParams } from 'common/hooks/useUpdateSearchParams';
import { DatasetService, getStudySeriesParams } from 'pages/Datasets/store/DatasetService';
import { useAppDispatch } from 'redux/reduxHooks';
import { useDicomViewerInstance } from '../DicomViewerState';
import { useDataset } from '../ImageState';
import { useChangeSeriesState } from './useChangeSeriesState';

export function useChangeStudy() {
    const dataset = useDataset();
    const dispatch = useAppDispatch();
    const changeSeriesState = useChangeSeriesState();
    const updateSearchParams = useUpdateSearchParams();
    const dicomViewerHelper = useDicomViewerInstance();

    return async function changeStudy(nextStudy: string, seriesId?: string) {
        try {
            dicomViewerHelper?.clearToolsState();

            updateSearchParams({ [URLSearchValues.STUDY]: nextStudy });

            const StudySeriesSubscription = dispatch(
                DatasetService.endpoints.getStudySeries.initiate(getStudySeriesParams(dataset?.datasetId, nextStudy))
            );
            const { data: studySeries } = await StudySeriesSubscription;
            StudySeriesSubscription.unsubscribe();

            let _nextSerie = seriesId;

            if (_isEmpty(seriesId) || !studySeries?.some(s => s.series_id === seriesId)) {
                _nextSerie = studySeries?.[0]?.series_id;
            }

            if (!_nextSerie && !nextStudy) return;
            changeSeriesState({ seriesId: _nextSerie, changeAllViewports: true });
        } catch (error) {
            console.error(error);
        }
    };
}
