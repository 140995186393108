import { Input } from 'antd';
import { SearchProps } from 'antd/lib/input';
import cx from 'classnames';
import { getMessages } from 'common/helpers';
import Styles from '../assets/Styles.module.scss';

export default function GSearchInput({ className, placeholder = getMessages('000304'), ...props }: GSearchInputProps) {
    return (
        <div className={cx(Styles.GInputContainer, Styles.GSearchBox, className)}>
            <Input.Search
                placeholder={placeholder}
                {...props}
            />
        </div>
    );
}

export interface GSearchInputProps extends SearchProps {}
