import { getDocsURL, getMessages } from 'common';
import {
    Annotation,
    Application,
    Deployment,
    Documentation,
    FolderChartLine,
    Home,
    ModelLine20,
    Playground,
    Validation,
} from 'components/assets/icons';
import { Link } from 'react-router-dom';

export const linkMenuList = [
    {
        key: 'main',
        label: getMessages('001809'),
        icon: <Home />,
    },
    {
        key: 'sub1',
        label: getMessages('000208'),
        icon: <Playground />,
        children: [
            {
                key: 'playground',
                label: <Link to={'/playground'}>Playground</Link>,
            },
            {
                key: 'playground-3d',
                label: <Link to={'/viewer'}>Playground 3D</Link>,
            },
        ],
    },
    {
        key: 'project',
        label: getMessages('001236'),
        icon: <FolderChartLine />,
    },
    {
        key: 'validation',
        label: getMessages('000002'),
        icon: <Validation />,
    },
    {
        key: 'models',
        label: getMessages('000614'),
        icon: <ModelLine20 />,
    },
    {
        key: 'data',
        label: getMessages('000003'),
        icon: <Deployment />,
    },
    {
        key: 'annotation',
        label: getMessages('000813'),
        icon: <Annotation />,
    },
    // {
    //     key: 'viewer/playground',
    //     label: getMessages('002435'),
    //     icon: <Annotation />,
    // },
    {
        key: 'platform',
        label: getMessages('000004'),
        icon: <Application />,
    },
    {
        key: 'documentation',
        label: getMessages('000488'),
        icon: <Documentation />,
        link: getDocsURL(),
    },
].map(item => ({
    ...item,
    label: renderLabel(item),
}));

function renderLabel(item: any) {
    // const dropdownItemList = [
    //     {
    //         key: 'playground',
    //         render: <Link to={"/playground"}>Playground</Link>,
    //     },
    //     {
    //         key: '3d-playground',
    //         render: <Link to={"/viewer/playground"}>Playground</Link>,
    //     },
    // ];
    // if (item.key === 'playground') return <GDropdown menuItemList={dropdownItemList}>Playground</GDropdown>;

    if(item?.children) return <div>{item.label}</div>

    if (item?.link)
        return (
            <a
                href={item.link}
                target="_blank"
                rel="next"
            >
                {item.label}{' '}
            </a>
        );
    else return <Link to={`/${item.key}`}>{item.label}</Link>;
}
