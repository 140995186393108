import { PayloadAction } from "@reduxjs/toolkit";
import { Dictionary, _entries } from "common";
import { WritableDraft } from "immer/dist/internal";
import { IValidationState } from "pages/Validation/entities";
import { IMetricsExtra } from "../../metrics/entities";
import { ILayoutState } from "../../metrics/entities/Charts/IChartState";
import { ComparisonChartKey, IComparisonMetrics } from "../entities";
import { IComparisonControl } from "../entities/ComparisonControl";

function setComparisonData(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IComparisonMetrics>>) {
    _entries(payload).forEach(([key, value]: [ComparisonChartKey, any]) => {
        if (state.comparison.comparisonMetrics[key]) {
            state.comparison.comparisonMetrics[key].data = value;
        }
    });
}

function setComparisonLayoutState(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<Record<ComparisonChartKey, ILayoutState>>>) {
    _entries(payload).forEach(([key, value]: [ComparisonChartKey, ILayoutState]) => {
        if (state.comparison.comparisonMetrics[key]) {
            state.comparison.comparisonMetrics[key].layoutState = value;
        }
    });
}

function setComparisonExtraData(state: WritableDraft<IValidationState>, { payload }: PayloadAction<IMetricsExtra>) {
    state.comparison.comparisonExtra = payload;
}

function setComparisonGraphParams(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<Record<ComparisonChartKey, Dictionary>>>) {
    _entries(payload).forEach(([key, value]: [ComparisonChartKey, Dictionary]) => {
        if (state.comparison.comparisonMetrics[key]) {
            state.comparison.comparisonMetrics[key].graphParams = value;
        }
    });
}

function setComparisonDefaultParams(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<Record<ComparisonChartKey, Dictionary>>>) {
    _entries(payload).forEach(([key, value]: [ComparisonChartKey, Dictionary]) => {
        if (state.comparison.comparisonMetrics[key]) {
            state.comparison.comparisonMetrics[key].defaultParams = value;
        }
    });
}

function updateComparisonControlState(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IComparisonControl>>) {
    Object.assign(state.comparison.control, payload);
}

function setSelectionModalVisible(state: WritableDraft<IValidationState>, { payload }: PayloadAction<boolean>) {
    state.comparison.control.selectionModalVisible = payload;
}

export const comparisonReducer = {
    setComparisonData,
    setComparisonExtraData,
    setComparisonDefaultParams,
    setComparisonGraphParams,
    setComparisonLayoutState,
    updateComparisonControlState,
    setSelectionModalVisible,
};

export * from "./actions";
export * from "./selectors";
