import { getMessages } from 'common/helpers';
import { useDatasetData } from 'redux/selectors';
import Styles from './Styles.module.scss';

export default function StudyCount({ datasetId }: { datasetId: string }) {
    const dataset = useDatasetData(datasetId);
    return (
        <div>
            {dataset?.studyCount ? (
                <div className={Styles.StudyCount}>
                    <span className="pBody-normal"> {` ${dataset?.studyCount}`}</span>
                    <span className="pBody-normal">
                        {' '}
                        {dataset?.studyCount === 1 ? ` ${getMessages('001867')}` : ` ${getMessages('001025')}`}
                    </span>
                </div>
            ) : null}
        </div>
    );
}
