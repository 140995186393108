import { _entries } from "./_entries";
import { _isEmpty } from "./_isEmpty";

export function _compact<T extends Array<any>>(arr: T): T {
    if (!arr) return [] as T;
    return arr?.filter(i => !_isEmpty(i)) as T;
}

export function _compactDict<T>(dict: T): Partial<T> {
    return _entries(dict).reduce((acc: Partial<T>, [key, value]) => {
        if (!_isEmpty(value)) {
            acc[key] = value;
        }
        return acc;
    }, {});
}