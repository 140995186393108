import { Layout, SiderProps } from 'antd';
import cs from 'classnames';
import { _uuid } from 'common';
import { useRef } from 'react';
import { useEvent } from 'react-use';
import { useImmerReducer } from 'use-immer';
import Styles from './Styles.module.scss';

export function GResizeableSider({
    onSizeChange,
    className,
    width,
    minWidth,
    maxWidth,
    style,
    id,
    ...props
}: IGResizeableSiderProps) {
    const isMouseDown = useRef(false);
    const _id = useRef(id || _uuid());
    const element = document.getElementById(_id.current);
    const [state, dispatch] = useImmerReducer(
        (_state: IState, action: MouseEvent) => {
            if (props.collapsed) return _state;
            const xPos = GetRelativeXPosition(element, action.pageX);
            const width = GetWidth(xPos, minWidth, maxWidth);

            _state.resizable = Math.abs(xPos - _state.width) < 24;

            switch (action.type) {
                case 'mousedown':
                    if (!_state.resizable) return;
                    isMouseDown.current = true;
                    break;
                case 'mouseup':
                    if (!isMouseDown.current) return;
                    isMouseDown.current = false;
                    _state.width = width;
                    break;

                case 'mousemove':
                    if (!isMouseDown.current) return;

                    _state.width = width;
                    _state.resizable = true;
                    break;

                default:
                    break;
            }

            onSizeChange?.(_state.width);
        },
        { resizable: false, width: GetWidth(width, minWidth, maxWidth) } as IState
    );

    useEvent('mouseup', dispatch);
    useEvent('mousemove', dispatch);

    return (
        <Layout.Sider
            {...props}
            id={_id.current}
            style={{
                ...style,
                cursor: state.resizable ? 'ew-resize' : undefined,
            }}
            className={cs(Styles.GResizeableSider, className)}
            width={state.width}
            onMouseDown={e => {
                dispatch(e.nativeEvent);
                props.onMouseDown?.(e);
            }}
        />
    );
}

function GetRelativeXPosition(element: HTMLElement, xpos: number) {
    // find relative position of mouse to element
    if (!element) return xpos;
    xpos = xpos - element.offsetLeft;

    return xpos;
}

function GetWidth(xpos: number, minWidth?: number, maxWidth?: number) {
    // find relative position of mouse to element
    if (xpos < 0) xpos = 0;

    if (typeof minWidth === 'number' && xpos < minWidth) {
        return minWidth;
    } else if (typeof maxWidth === 'number' && xpos > maxWidth) {
        return maxWidth;
    } else {
        return xpos;
    }
}

export interface IGResizeableSiderProps extends SiderProps {
    onSizeChange?: (width: number) => void;
    width?: number;
    minWidth?: number;
    maxWidth?: number;
}

interface IState {
    resizable: boolean;
    width: number;
}
