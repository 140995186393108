import { getMessages } from 'common';
import { GButton, GModal, GTooltip } from 'components';
import GSelectionTable from 'components/GDataGrid/GSelectionTable';
import { CloseX, Reset20 } from 'components/assets/icons';
import { selectIsClipboardOpen } from 'pages/User/store/selectors';
import { useSelector } from 'react-redux';
import Styles from './Styles.module.scss';
import useGetClipboardData, { columns } from './useGetClipboardData';

export default function ClipboardModalContainer() {
    const open = useSelector(selectIsClipboardOpen);
    if (!open) return <></>;

    return <ClipboardModal />;
}

function ClipboardModal() {
    const open = useSelector(selectIsClipboardOpen);
    const { data, resetClipboard, onRemoveSelected, handleModalClose, selectedItems, toggle } = useGetClipboardData();

    function getRenderTitle() {
        return (
            <div className={Styles.TitleContainer}>
                <div className="subtitle2-bold">{getMessages('001568')}</div>
                <div className={Styles.BtnContainer}>
                    {Boolean(selectedItems?.size) && (
                        <GTooltip title={getMessages('001575')}>
                            <GButton
                                onClick={onRemoveSelected}
                                id="remove-selected-clipboard"
                            >
                                {getMessages('001574')}
                            </GButton>
                        </GTooltip>
                    )}
                    <GTooltip title={getMessages('001569')}>
                        <GButton
                            onClick={resetClipboard}
                            id="reset-clipboard"
                        >
                            <Reset20 />
                        </GButton>
                    </GTooltip>
                </div>
            </div>
        );
    }

    const onChangeSelection = (id: string, checked: boolean) => {
        toggle(id);
    };

    return (
        <>
            <GModal
                open={open}
                setVisible={handleModalClose}
                footer={null}
                title={getRenderTitle()}
                closeIcon={<CloseX />}
                width="1000px"
                closable
                destroyOnClose
                className={Styles.ModalContainer}
            >
                <GSelectionTable
                    columns={columns}
                    dataSource={data}
                    idIndex="id"
                    onChangeSelection={onChangeSelection}
                    selectionList={Array.from(selectedItems)}
                />
            </GModal>
        </>
    );
}
