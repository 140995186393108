import { PayloadAction } from "@reduxjs/toolkit";
import { getObjectKeyByValue } from "common";
import { setComparisonData } from "pages/Validation/store";
import { MetricsDataType } from "pages/Validation/views/validationAnalysis/components/metrics/entities";
import { getChartData } from "pages/Validation/views/validationAnalysis/components/metrics/store/saga/metricsChartSaga";
import { all, call, fork, put, select } from "redux-saga/effects";
import { ComparisonChartKey } from "../../entities";
import { IGetChartDataPayload } from "../actions";
import { selectComparisonGraphParams } from "../selectors";
import { combineChartData, setLayoutStateSaga } from "./ComparisonStaticDataSaga";
import { extraComparisonMetricsMap } from "./ExtraComparisonMetricsSaga";

export function* getComparisonChartDataSaga({ payload }: PayloadAction<IGetChartDataPayload>): any {
    try {
        const { chartType } = payload;

        const chartKey = getObjectKeyByValue(MetricsDataType, chartType) as ComparisonChartKey;

        yield fork(setLayoutStateSaga, chartKey, { isLoading: true });

        if (extraComparisonMetricsMap[chartKey]) yield fork(GetExtraMetricsSaga, payload);

        else yield fork(GetDataSaga, payload);

    } catch (error) {
        console.error(error);
    }
}

export function* GetExtraMetricsSaga({ chartType, validationList }: IGetChartDataPayload): any {
    try {
        const state = yield select();
        const chartKey = getObjectKeyByValue(MetricsDataType, chartType) as ComparisonChartKey;

        const graphParams = selectComparisonGraphParams(chartKey)(state);

        const dataPayload = yield call(extraComparisonMetricsMap[chartKey].dataFunc, validationList[0], validationList[1], graphParams);

        yield put(setComparisonData({ [chartKey]: dataPayload }));

        yield fork(setLayoutStateSaga, chartKey, { isLoading: false });
    } catch (error) {
        console.error(error);
    }
}

export function* GetDataSaga({ chartType, validationList }: IGetChartDataPayload): any {
    try {
        const state = yield select();
        const chartKey = getObjectKeyByValue(MetricsDataType, chartType) as ComparisonChartKey;

        const graphParams = selectComparisonGraphParams(chartKey)(state);


        const [result1, result2] = yield all(
            validationList?.map(val => call(getChartData, {
                graph_id: chartType,
                graph_params: graphParams ?? {},
                validation_id: val,
            }))
        );

        if (result1 || result2) {
            const dataPayload = combineChartData(validationList, chartKey)(result1, result2);

            yield put(setComparisonData({ [chartKey]: dataPayload }));

            yield fork(setLayoutStateSaga, chartKey, { isLoading: false });

        } else {
            yield fork(setLayoutStateSaga, chartKey, { isEmpty: true });
        }
    } catch (error) {
        console.error(error);
    }
}