import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { IDatasetState } from 'pages/Datasets/entities';
import { IPacsExplorerState, PacsStudy } from '../entities';

export const PacsExplorerReducer = {
    updatePacsExplorerState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IPacsExplorerState>>) {
        Object.assign(state.pacsExplorerState, payload);
    },

    removeStudy(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<string>) {
        const index = state.pacsExplorerState.selectedStudies.findIndex(study => study.StudyInstanceUID === payload);
        if (index !== -1) {
            state.pacsExplorerState.selectedStudies.splice(index, 1);
        }
    },

    toggleStudy(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<PacsStudy>) {
        const index = state.pacsExplorerState.selectedStudies.findIndex(
            study => study.StudyInstanceUID === payload.StudyInstanceUID
        );
        if (index !== -1) {
            state.pacsExplorerState.selectedStudies.splice(index, 1);
        } else {
            state.pacsExplorerState.selectedStudies.push(payload);
        }
    },

    updateSelectedStudies(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<PacsStudy[]>) {
        state.pacsExplorerState.selectedStudies = payload;
    },



    updatePacsExplorerPage(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<number>) {
        state.pacsExplorerState.page = payload;
    },

    updatePacsExplorerPageSize(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<number>) {
        state.pacsExplorerState.pageSize = payload;
    },

    updatePacsExplorerFilters(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Dictionary>) {
        Object.assign(state.pacsExplorerState.filters, payload);
    },

    updatePacsExplorerTableFilters(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Dictionary>) {
        Object.assign(state.pacsExplorerState.tableFilters, payload);
    },

    updatePacsExplorerView(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<'explorer' | 'create'>) {
        state.pacsExplorerState.view = payload;
    },

    updatePacsExplorerSelectionsModalOpen(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<boolean>) {
        state.pacsExplorerState.selectionsModalOpen = payload;
    },

    updatePacsExplorerTagModalOpen(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<boolean>) {
        state.pacsExplorerState.tagModalOpen = payload;
    },
};
