import { DatasetsFiltersFactory, IDatasetFilters } from 'common/hooks/useFilteredDatasetList';

export interface IAnnotationCreateState {
    currentStep: number;
    datasetFilters: IDatasetFilters;
    imageEvaluationState: IImageEvaluationState;
}

export function AnnotationCreateStateFactory(): IAnnotationCreateState {
    return {
        currentStep: 0,
        datasetFilters: DatasetsFiltersFactory(),
        imageEvaluationState: {
            pairModalOpen: false,
            pairSelectedRowIndex: undefined,
        },
    };
}

export interface IImageEvaluationState {
    pairModalOpen: boolean;
    pairSelectedRowIndex?: number;
}

export const AnnotationCreateSteps = (
    ['Dataset Selection', 'Project Details', 'Team', 'Labeling Configuration'] as const
).map(item => ({
    title: item,
    content: '',
}));
