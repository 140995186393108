export function formatStringtoURLPath(str:string) {
    if (!str) return '';
    // Convert the string to lowercase
    let formattedStr = str?.toLowerCase();

    // Replace spaces with dashes
    formattedStr = formattedStr?.replace(/\s+/g, '-');

    // Remove special characters except dashes and alphanumeric characters
    formattedStr = formattedStr?.replace(/[^\w\s-]/g, '');

    // Replace multiple dashes with a single dash
    formattedStr = formattedStr?.replace(/--+/g, '-');

    // Trim dashes from the beginning and end of the string
    formattedStr = formattedStr?.replace(/^-+|-+$/g, '');

    return formattedStr;
}

// Example usage:
//"SD Pilot Project" => Output: "sd-pilot-project"
//"Lumbar Spine  & Example" => Output: "lumbar-spine-example"
