import Fuse from 'fuse.js';
import FuseHighlight from './FuseHighlight';

export function useHandleSearch<T = any>(searchOptions: T[] = [], options: Fuse.IFuseOptions<T>) {
    function handleSearch(value = '') {
        const fuse = new Fuse(searchOptions, options);
        return fuse?.search(value);
    }

    return {
        handleSearch,
    };
}

export function getSearchOptionsWithHighlight(list: Fuse.FuseResult<any>[]) {
    return list?.map((i, index) => {
        return {
            label: (
                <div key={`${i.refIndex}-${index}`}>
                    {!i.matches?.some(e => e.key === 'title') ? (
                        <>
                            <span>{i.item.title}</span>
                            <br />
                        </>
                    ) : null}
                    {i.matches?.map((item, index) => (
                        <div key={`${item.value}-${index}`}>
                            {item.key !== 'title' && <span>↳ {item.key} : </span>}
                            <FuseHighlight
                                hit={i}
                                attribute={item.key}
                            />
                        </div>
                    ))}
                </div>
            ),
            value: i.item?.value,
            title: i.item?.title,
        };
    });
}
