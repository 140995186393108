import { _entries } from "common";
import { IValidationPlotResponse } from "..";
import { formatValue } from "./payload";
export interface IIDetailedMetricsResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: string;
        data: IDetailedMetricsData;
    };
}

export type graph_1Keys = "TruePositive" | "TrueNegative" | "FalsePositive" | "FalseNegative";
export type graph_2Keys = "SimpleHausdorffDistance" | "AverageHausdorffDistance" | "CrossEntropy";
export type graph_3Keys = "Dice Score" | "mean Sensitivity" | "mean Specificity" | "Precision" | "BalancedAccuracy" | "AdjustedRandIndex" | "AUC_trapezoid" | "mean AUC" | "mean Kappa" | "VolumetricSimilarity" | "Matthew's Classwise C. C." | "meanIoU" | "Pixel Accuracy" | "mean fwIoU";


export interface IDetailedMetricsData {
    graph_1: IDetailedMetricsGraph1Data;
    graph_2: IDetailedMetricsGraph2Data;
    graph_3: IDetailedMetricsGraph3Data;
}

export type IDetailedMetricsGraph1Data = Record<graph_1Keys, number>;
export type IDetailedMetricsGraph2Data = Record<graph_2Keys, number>;
export type IDetailedMetricsGraph3Data = Record<graph_3Keys, number>;

export function getDetailedMetricsData(data: IDetailedMetricsData): IDetailedMetricsData {
    if (data) {
        const { graph_1, graph_2, graph_3 } = data;
        return {
            graph_1: _entries(graph_1).reduce((acc, [key, value]) => ({ ...acc, [key]: formatValue(value) }), {}) as any,
            graph_2: _entries(graph_2).reduce((acc, [key, value]) => ({ ...acc, [key]: formatValue(value) }), {}) as any,
            graph_3: _entries(graph_3).reduce((acc, [key, value]) => ({ ...acc, [key]: formatValue(value) }), {}) as any,
        };
    }
}