import { IBaseProps } from 'common/entities';
import React from 'react';
import cx from "classnames";
import Styles from "./assets/Styles.module.scss";

export function GInputGroup({ className, ...props }: GInputGroupProps) {
    return (
        <div className={cx(Styles.GInputGroup)} {...props} />
    );
}

export interface GInputGroupProps extends IBaseProps {

}