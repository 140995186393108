import { AuthService, ServiceEnum } from "common";
import { getUri } from "common/services/Client/axiosConfig";

export async function getMicrosoftURL() {
    try {
        const redirectUri = getUri(ServiceEnum.Auth) + "/oauth/microsoft";

        const response = await AuthService.GetMicrosoftOAauthURL.get({ params: { redirectUri } });
        return response?.data;
    } catch (error) {
        console.log(error);
    }
}
