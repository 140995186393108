import { RootState } from 'redux/store';

export const selectLoginLoading = (state: RootState) => state.auth.loading;
export const selectAuthToken = (state: RootState) => state.auth.token;
export const selectIsActiveMFA = (state: RootState) => state.auth.isActiveMFA;
export const selectMfaTokenStatus = (state: RootState) => state.auth.mfaTokenStatus;
export const selectIsAppInit = (state: RootState) => state.auth.isAppInit;
export const selectUser = (state: RootState) => state.auth.user;
export const selectActivationToken = (state: RootState) => state.auth.activation_token;
export const selectActivationStatus = (state: RootState) => state.auth.activationExpired;
export const selectSetupModal = (state: RootState) => state.auth.setupModal;
