import { _entries } from "common";
import { IValidationPlotResponse } from "..";
import { formatValue } from "./payload";

export interface IPerformanceByConfidenceResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: string;
        data: IPerformanceByConfidenceData;
    };
}

export type graph1Keys = "F1" | "FNR" | "FPR" | "Matthew's Classwise C. C." | "Precision" | "Sensitivity" | "Specificity";
export type graph2Keys = "TP" | "TN" | "FP" | "FN";

export interface IPerformanceByConfidenceData {
    graph_1: IPerformanceByConfidenceGraph1Data;
    graph_2: IPerformanceByConfidenceGraph2Data;
}

export type IPerformanceByConfidenceGraph1Data = Record<graph1Keys, number>;
export type IPerformanceByConfidenceGraph2Data = Record<graph2Keys, number>;


export interface IPerformanceByConfidenceGraphParams {
    predicted_class: number,
    threshold: number,
}

export function getPerformanceByConfidenceData(data: IPerformanceByConfidenceData): IPerformanceByConfidenceData {
    if (data) {
        const { graph_1, graph_2 } = data;
        return {
            graph_1: _entries(graph_1).reduce((acc, [key, value]) => ({ ...acc, [key]: formatValue(value) }), {}) as any,
            graph_2: _entries(graph_2).reduce((acc, [key, value]) => ({ ...acc, [key]: formatValue(value) }), {}) as any,
        };
    }
}
