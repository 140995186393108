import { useNotifications } from 'app/hooks/useNotifications';
import { UserNotificationsView } from 'common';
import _groupBy from 'lodash/groupBy';

export default function useNotificationOverlay(type: UserNotificationsView) {
    const { markAllAsRead, filteredNotifications } = useNotifications(type);

    function onMarkAllAsRead(e: React.MouseEvent) {
        markAllAsRead();
        e.stopPropagation();
    }

    const titleGrouped = _groupBy(filteredNotifications, 'title');

    return { onMarkAllAsRead, titleGrouped };
}
