import { AxiosResponse } from 'axios';
import { Image } from 'cornerstone-core';
import { OnnxValue } from 'onnxruntime-web';
import { CornerstoneMouseEvent, IPoint, IRectangleHandles } from '../DicomViewerHelper';

export type EmbeddingResponseType = AxiosResponse<{
    embeddings: string;
}>;

export interface EventData extends CornerstoneMouseEvent {
    handles: IRectangleHandles;
}

export interface OperationData {
    pixelData?: Float32Array;
    points?: Array<{ x: number; y: number }>;
    segmentIndex?: number;
    segmentMixinType?: string;
}

export enum EmbeddingStatus {
    Loading = 'Loading',
    Pending = 'Pending',
    Loaded = 'Loaded',
    Failed = 'Failed',
}

export interface ModelReturnType {
    iou_predictions: OnnxValue;
    masks: OnnxValue;
    low_res_masks: OnnxValue;
}

export interface PredictionState {
    clicks: Array<IPoint>;
    rect: [IPoint, IPoint];
    result: ModelReturnType;
}

export const StateFactory = (): PredictionState => ({
    clicks: [],
    rect: null,
    result: null,
});

export interface NewPredictionArgs {
    image: Image;
    element: HTMLElement;
    point?: IPoint;
    rect?: [IPoint, IPoint];
}
