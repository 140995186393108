import { APIService } from "common";
import { put } from "redux-saga/effects";
import { setSourceList } from "..";

export function* GetSourceListSaga(): any {
    try {
        const response = yield APIService.Datasets.GetSources.call()
        if (response?.status === 200 && response?.data) {
            yield put(setSourceList(response.data));
        }

    } catch (error) {
        console.log(error)
    }
}