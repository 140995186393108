import { BaseDataFactory } from 'common/entities';
import dayjs from 'dayjs';
import { AppState, IAppCommonState, IEnvConfiguration, INetworkSpeedMeasures, INotification, IRouting } from './interfaces';

export function AppStateFactory(data?: AppState): AppState {
    return {
        routing: RoutingFactory(data?.routing),
        common: AppCommonStateFactory(data?.common),
        notifications: data?.notifications ?? [],
        errorLogs: data?.errorLogs ?? [],
    };
}

export function AppCommonStateFactory(data?: Partial<IAppCommonState>): IAppCommonState {
    return {
        networkSpeed: NetworkSpeedMeasuresFactory(data?.networkSpeed),
        serverNetworkStatus: data?.serverNetworkStatus ?? 'online',
        sessionReminderVisible: data?.sessionReminderVisible ?? false,
        envConfiguration: EnvConfigurationFactory(data?.envConfiguration),
        organizationMembers: BaseDataFactory({ data: data?.organizationMembers?.data ?? [] }),
        configFailModalVisible: data?.configFailModalVisible ?? false,
    };
}

export function NetworkSpeedMeasuresFactory(data?: INetworkSpeedMeasures): INetworkSpeedMeasures {
    return {
        bps: data?.bps,
        kbps: data?.kbps,
        mbps: data?.mbps,
        status: data?.status ?? 'online',
    };
}

export function EnvConfigurationFactory(data?: Partial<IEnvConfiguration>): IEnvConfiguration {
    return {
        oauth: data?.oauth ?? { google: false, microsoft: false },
        activation_token: data?.activation_token ?? '',
        activation_token_expires: data?.activation_token_expires ?? dayjs().unix(),
    };
}

export function NotificationFactory(notification: Partial<INotification> = {}): INotification {
    return {
        id: notification.id || null,
        isRead: notification.isRead || false,
        createdAt: notification.createdAt || dayjs().unix(),
        type: notification.type || 'default',
        isLoading: notification.isLoading || false,
        readAt: null,
        userId: notification.userId,
        message: notification.message,
        title: notification.title,
    };
}

export function RoutingFactory(data?: IRouting): IRouting {
    return {
        urlParams: data?.urlParams ?? {},
        urlQuery: data?.urlQuery ?? {},
        source: data?.source ?? '',
    };
}
