export interface IComparisonControl {
    isDataFetched: boolean;
    isDataLoading: boolean;
    selectionModalVisible: boolean;
    showCompatibleValidations: boolean;
}

export function ComparisonControlStateFactory(data?: IComparisonControl): IComparisonControl {
    return {
        isDataFetched: data?.isDataFetched ?? false,
        isDataLoading: data?.isDataLoading ?? false,
        selectionModalVisible: data?.selectionModalVisible ?? false,
        showCompatibleValidations: data?.showCompatibleValidations ?? true
    };
}