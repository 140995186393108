import { AxiosResponse } from "axios";
import { APIService, getMessages } from "common";
import { Notification } from "components";
import { call } from "redux-saga/effects";
import { getDatasetListTransactionSaga } from "./datasetListSaga";

export function* DatasetArchiveSaga(sagaAction: any): any {
    try {
        const dataset_id = sagaAction.payload.dataset_id;
        const response: AxiosResponse<{ dataset: any }> = yield APIService.Datasets.ArchiveDataset.call({ dataset_id });

        if (response.data) {
            yield call(getDatasetListTransactionSaga)
            Notification.success({ content: getMessages("000498") });
        }
    } catch (error) {
        console.log(error)
    }
}
export function* DatasetUnArchiveSaga(sagaAction: any): any {
    try {
        const dataset_id = sagaAction.payload.dataset_id;
        const response: AxiosResponse<{ dataset: any }> = yield APIService.Datasets.UnArchiveDataset.call({ dataset_id });

        if (response.data) {
            yield call(getDatasetListTransactionSaga)
            Notification.success({ content: getMessages("000499") });
        }
    } catch (error) {
        console.log(error)
    }
}

