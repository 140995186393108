import { useRef } from 'react';
import useEventCallback from 'use-event-callback';
import { _uuid } from './LangUtils';

export function useUuid(): ReturnType {
    const idRef = useRef(_uuid());

    const reset = useEventCallback(() => (idRef.current = _uuid()));

    return [idRef.current, { reset }];
}

type ReturnType = [string, { reset: () => void }];
