import { List } from 'antd';
import cs from 'classnames';
import { isWindowLevelExist } from 'common/enum';
import GTooltip from 'components/GTooltip';
import { useGetDatasetQuery } from 'pages/Datasets/store/DatasetService';
import SelectionComponent from './SelectionComponent';
import Styles from './Styles.module.scss';
import Thumbnail from './Thumbnail';
import { ExplorerLevel, IDatasetExplorerItem, ISelection } from './entities';

export function ListItem({ item, datasetId, loading, onClick, selection, level }: IProps) {
    const { id, name = id, thumbnail } = item;
    const { currentData: dataset } = useGetDatasetQuery(datasetId);

    return (
        <List.Item>
            <div
                className={cs(Styles.ImageContainer, { [Styles.StackedThumbnail]: thumbnail?.length > 1 })}
                onClick={() => onClick?.(item)}
            >
                {thumbnail?.map((item, i) => (
                    <Thumbnail
                        dataset={dataset}
                        datasetId={item.datasetId}
                        windowLevel={isWindowLevelExist(dataset?.defaultWindowLevel) ? dataset?.defaultWindowLevel : undefined}
                        style={{ top: (2 - i) * 7 }}
                        loading={loading}
                        {...item}
                    />
                ))}
                {loading || !selection?.type ? null : (
                    <SelectionComponent
                        selection={selection}
                        checked={selection?.selectedItems?.has(id)}
                    />
                )}
            </div>

            {loading ? null : (
                <GTooltip title={name}>
                    <div className="pBody-medium white truncate">{name}</div>
                </GTooltip>
            )}
        </List.Item>
    );
}

interface IProps {
    item: IDatasetExplorerItem;
    datasetId: string;
    loading?: boolean;
    onClick?: (item: IDatasetExplorerItem) => void;
    selection?: ISelection;
    level?: ExplorerLevel;
}
