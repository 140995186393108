import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { IValidationState } from 'pages/Validation/entities';
import { ICommonState, IModalStatus, IOverlayStatus, ISummaryPageData, IValidationRun } from '../entities';

function setPlatformSummaryData(state: WritableDraft<IValidationState>, { payload }: PayloadAction<ISummaryPageData>) {
    Object.assign(state.summary, payload);
}

function setValidationRunData(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Array<IValidationRun>>) {
    state.summary.validationRunList = payload;
}

function setSummaryOverlayStatus(state: WritableDraft<IValidationState>, { payload }: PayloadAction<IOverlayStatus>) {
    state.summary.overlayStatus = payload;
}

function setIsEditModalOpen(state: WritableDraft<IValidationState>, { payload }: PayloadAction<IModalStatus>) {
    state.summary.isEditModalOpen = payload;
}

function setSelectedValidations(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Array<string>>) {
    state.summary.selectedValidations = payload;
}

function updateValidationItem(state: WritableDraft<IValidationState>, { payload }: PayloadAction<IValidationRun>) {
    Object.assign(state.summary, {
        validationRunList: state.summary.validationRunList.map(item =>
            item.validationId === payload.validationId ? payload : item
        ),
    });
}

function updateCommonState(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<ICommonState>>) {
    Object.assign(state.commonState, payload);
}

export const ValidationSummaryReducer = {
    setPlatformSummaryData,
    setValidationRunData,
    setSummaryOverlayStatus,
    setIsEditModalOpen,
    updateValidationItem,
    updateCommonState,
    setSelectedValidations,
};
