import { TreeSelect, TreeSelectProps } from "antd";
import cx from "classnames";
import { Dictionary } from 'common';
import React from 'react';
import Styles from "./Styles.module.scss";
import { ArrowDown } from 'components/assets/icons';

export default function GTreeSelect({ className, popupClassName, id, ...props }: IGSelectProps) {
    return (
        <div
            className={cx(Styles.SelectContainer, className)}
            id={id}
        >
            <TreeSelect
                popupClassName={cx(Styles.SelectMenu, popupClassName)}
                getPopupContainer={trigger => trigger.parentElement}
                suffixIcon={<ArrowDown />}
                {...props}
            />
        </div>
    );
}

export interface IGSelectProps extends TreeSelectProps {

}

export interface ISelectItem extends Dictionary {
  value: any;
  title: React.ReactNode,
  disabled?: boolean;
}