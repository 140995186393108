import { getMessages } from "common";
import { ICommonState } from "../../summary/entities";
import { IExternalModelValidationState, IResultModalState } from "./interfaces";

export function ExternalModelValidationFactory(data?: Partial<IExternalModelValidationState>): IExternalModelValidationState {
    return {
        resultModal: ResultModalFactory(data?.resultModal),
        selectedValidation: data?.selectedValidation ?? null,
        blandAtmanResponse: data?.blandAtmanResponse ?? null,
    };
}

export function ResultModalFactory(data?: Partial<IResultModalState>): IResultModalState {
    return {
        open: data?.open ?? false,
        validationId: data?.validationId ?? null,
        studyId: data?.studyId ?? null,
    };
}

export const CommonStateFactory = (data?: ICommonState): ICommonState => ({
    isValRestartLoading: data?.isValRestartLoading ?? false,
    restartedValidationId: data?.restartedValidationId ?? "",
    isEditBSARoundNameModalOpen: data?.isEditBSARoundNameModalOpen ?? false,
    EditedBSARoundId: data?.EditedBSARoundId ?? null,
    
});
export const DefaultTablesFactory = (data?: any): any => ({
    ClassComparison: getMessages("000991"),
    ClassComparisonOverall: getMessages("001378"),
    CohortSizeAnalysisLargest: getMessages("001301"),
    CohortSizeAnalysisSmallest: getMessages("001308"),
    MetadataBestComparison: getMessages("001390"),
    MetadataWorstComparison: getMessages("001391"),
    MetadataMultipleBestComparison: getMessages("001388"),
    MetadataMultipleWorstComparison: getMessages("001389"),
    NumberOfSubcohortGroups: getMessages("001372"),

    
});