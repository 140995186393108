import { DeploymentStatus } from 'common';

export interface IPlatformHealth {
    autodeploy?: DeploymentStatus;
    validation?: DeploymentStatus;
    mongodb?: DeploymentStatus;
    rabbitmq?: DeploymentStatus;
    'nodejs-tool'?: DeploymentStatus;
    "db-manager"?: DeploymentStatus;
    'deployment-manager'?: DeploymentStatus;
}

export function PlatformHealthFactory(data?: Partial<IPlatformHealth>): IPlatformHealth {
    return {
        autodeploy: data?.autodeploy || DeploymentStatus.DOWN,
        validation: data?.validation || DeploymentStatus.DOWN,
        mongodb: data?.mongodb || DeploymentStatus.DOWN,
        rabbitmq: data?.rabbitmq || DeploymentStatus.DOWN,
        'nodejs-tool': data?.['nodejs-tool'] || DeploymentStatus.DOWN,
        "db-manager": data?.["db-manager"] || DeploymentStatus.DOWN,
        'deployment-manager': data?.['deployment-manager'] || DeploymentStatus.DOWN,
    };
}

export enum PlatformServices {
    autodeploy = 'AUTODEPLOY',
    validation = 'VALIDATION',
    mongodb = 'MONGODB',
    rabbitmq = 'RABBITMQ',
    'nodejs-tool' = 'NODEJS_TOOL',
    "db-manager" = 'DB_MANAGER',
    'deployment-manager' = 'DEPLOYMENT_MANAGER',
}

export interface IPlatformAlert{
    deployment_id:Array<string>,
    text:string,
    type:string
}