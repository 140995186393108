import { PayloadAction } from "@reduxjs/toolkit";
import { Dictionary, IImage } from "common";
import { WritableDraft } from "immer/dist/internal";
import { IAugmentationProperties, IImageControls } from "pages/Playground/entities";
import { IValidationState } from "pages/Validation/entities";
import { IValidationParams } from "../entities/IValidationParams";

function setImageMap(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Dictionary<IImage>>) {
    state.startValidation.robustValidationState.imageMap = payload;
}

function setTotalSize(state: WritableDraft<IValidationState>, { payload }: PayloadAction<number>) {
    state.startValidation.robustValidationState.totalDataSize = payload;
}

function setAugmentationProperties(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IAugmentationProperties>>) {
    Object.entries(payload ?? {}).forEach(([key, value]) => {
        (state.startValidation.robustValidationState.augmentationProperties as any)[key] = value;
    });
}

function setImageControls(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IImageControls>>) {
    Object.entries(payload ?? {}).forEach(([key, value]) => {
        (state.startValidation.robustValidationState.imageControls as any)[key] = value;
    });
}

function updateValidationParams(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IValidationParams>>) {
    Object.entries(payload ?? {}).forEach(([key, value]) => {
        (state.startValidation.validationParams as any)[key] = value;
    });
}

function updateProbability(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Dictionary<number>>) {
    Object.entries(payload ?? {}).forEach(([key, value]) => {
        (state.startValidation.robustValidationState.probabilityMap)[key] = value;
    });
}

function setProbability(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Dictionary<number>>) {
    state.startValidation.robustValidationState.probabilityMap = payload;
}

function setSelectedImage(state: WritableDraft<IValidationState>, { payload }: PayloadAction<string>) {
    state.startValidation.robustValidationState.selectedImage = payload;
}

export const startValidationReducer = {
    setImageMap,
    setTotalSize,
    setAugmentationProperties,
    setImageControls,
    setSelectedImage,
    setProbability,
    updateProbability,
    updateValidationParams,
};