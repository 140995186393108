import { Modal, ModalProps } from 'antd';
import { default as classnames, default as cs } from 'classnames';
import GErrorBoundry from 'components/GErrorBoundry';
import { CloseIcon } from 'components/assets/icons';
import Styles from './Styles.module.scss';

export default function GModal({
    setVisible,
    children,
    className,
    closable = true,
    onCancel,
    title,
    closeIconPosition = 'right',
    centered = true,
    ...props
}: IGModalProps) {
    function onModalCancel(e: React.MouseEvent<HTMLElement, MouseEvent>) {
        onCancel?.(e);
        setVisible?.(false);
    }

    function getModalHeader() {
        if (!closable) return <div className="subtitle2-bold title">{title}</div>;

        return (
            <div className={cs(Styles.ModalHeader, closeIconPosition === 'left' ? Styles.LeftIcon : null)}>
                <span className="subtitle2-bold title">{title}</span>
                <CloseIcon
                    onClick={onModalCancel as any}
                    className="close-icon"
                    id="Modal-Close-icon"
                />
            </div>
        );
    }

    return (
        <>
            <Modal
                className={classnames(Styles.ModalContainer, className)}
                title={title && getModalHeader()}
                closable={closable}
                onCancel={onModalCancel}
                {...props}
                centered={centered}
            >
                <GErrorBoundry>{children}</GErrorBoundry>
            </Modal>
        </>
    );
}

export interface IGModalProps extends ModalProps {
    setVisible?: (open: boolean) => void;
    closeIconPosition?: 'right' | 'left';
}
