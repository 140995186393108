import { IImage } from "common";
import { MetadataFieldType } from "pages/Datasets/entities";

export interface IImageFiltering {
    metadataFilterFields: Array<IMetadataFilterField>;
    selectedMetadataFilters: Array<IMetadataFilter>;
    selectedQueries: Array<Array<string>>;
    metadataFiltersLoading: boolean;
    imageFiltersCalculated: boolean;
    queryApplied: boolean;
    selectedMetadataFiltersLoading: boolean;
}

export function ImageFilteringFactory(data?: Partial<IImageFiltering>): IImageFiltering {
    return {
        metadataFilterFields: data?.metadataFilterFields || [],
        selectedMetadataFilters: data?.selectedMetadataFilters || [],
        selectedQueries: data?.selectedQueries || [],
        metadataFiltersLoading: data?.metadataFiltersLoading ?? false,
        imageFiltersCalculated: data?.imageFiltersCalculated ?? false,
        queryApplied: data?.queryApplied ?? false,
        selectedMetadataFiltersLoading: data?.selectedMetadataFiltersLoading ?? false,
    };
}

export interface IMetadataFilterField {
    meta_name: string,
    type: MetadataFieldType,
    min?: number,
    max?: number,
    valueList: Array<any>;
}

export interface IMetadataFilter {
    nameList: Array<string>,
    value: Array<number | string>,
}

export interface IFilteredImageList {
    imageList: Array<IImage>,
    totalCount: number,
    selectedImageSize: number,
}

export interface IFilterItem {
    meta_name: string,
    type?: string;
    min?: number,
    max?: number,
    valueList?: Array<string>,
    selectedValue?: string | number,
}

export function FilteredImageListFactory(data?: Partial<IFilteredImageList>): IFilteredImageList {
    return {
        imageList: data?.imageList ?? [],
        totalCount: data?.totalCount ?? 0,
        selectedImageSize: data?.selectedImageSize ?? 0,
    };
}