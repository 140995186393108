import { Dictionary, IImage } from 'common';
import { IAnnotationEl } from 'pages/AnnotationCS/entities';

export interface IAnnotationManagementState {
    viewMode: AnnotationViewMode;
    previewModal: IAnnotationModalState;
    imageListState: IImageListState;
    selectedAnnotationId: string;
    selectedLabelingConfiguration: string;
}

export interface IAnnotationModalState {
    visibility: boolean;
    selectedImage?: IImage;
    showGroundTruth?: boolean;
}

export interface IImageListState {
    imageList: Array<IImage>;
    skip: number;
    totalCount: number;
    loading: boolean;
    pageSize: number;
    imageSearch?: string;
}

export type AnnotationViewMode = 'list' | 'grid';

export type AnnotationEditState = Dictionary<IAnnotationEl>;

export function AnnotationManagementStateFactory(data?: Partial<IAnnotationManagementState>): IAnnotationManagementState {
    return {
        selectedAnnotationId: data?.selectedAnnotationId ?? null,
        selectedLabelingConfiguration: data?.selectedLabelingConfiguration ?? null,
        viewMode: data?.viewMode ?? 'grid',
        previewModal: ModalStateFactory(data?.previewModal),
        imageListState: ImageListStateFactory(data?.imageListState),
    };
}

export function ImageListStateFactory(data?: Partial<IImageListState>): IImageListState {
    return {
        imageList: data?.imageList ?? [],
        skip: data?.skip ?? 0,
        totalCount: data?.totalCount ?? 0,
        loading: data?.loading ?? false,
        pageSize: data?.pageSize ?? 12,
        imageSearch: data?.imageSearch ?? '',
    };
}

export function ModalStateFactory(data?: Partial<IAnnotationModalState>): IAnnotationModalState {
    return {
        visibility: data?.visibility ?? false,
        selectedImage: data?.selectedImage ?? null,
    };
}
