import { DicomViewerHelper, isStudyDataset } from 'common';
import { _compactDict, _pipe } from 'common/Utils';
import { samPredictionHelper } from 'common/helpers/SAMPredictionHelper';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useEvent } from 'react-use';
import useEventCallback from 'use-event-callback';
import { CornerstoneImageRenderedEvent, CornerstoneStackScrollEvent } from '../entities/interfaces';
import { updateActiveViewport, updateWindowLevel } from '../store';
import {
    getViewportStateSelector,
    selectActiveViewport,
    selectCinePlay,
    selectFrameRate,
    selectPrefetch,
    selectSmartSegmentationEnabled,
    selectWindowLevel,
} from '../store/selectors';
import { useDicomViewerInstance, useViewportElementMap } from './DicomViewerState';
import { useImageUrlList } from './ImageState/useImageUrlList';
import { useChangeImage } from './Transactions';

export function useViewport(index: number) {
    const dispatch = useDispatch();
    const dicomViewerInstance = useDicomViewerInstance();
    const activeViewport = useSelector(selectActiveViewport);
    const cinePlay = useSelector(selectCinePlay);
    const frameRate = useSelector(selectFrameRate);
    const windowLevel = useSelector(selectWindowLevel);
    const prefetch = useSelector(selectPrefetch);
    const [viewportElementMap, setViewportElement] = useViewportElementMap();
    const smartSegmentationEnabled = useSelector(selectSmartSegmentationEnabled);
    const changeImage = useChangeImage();

    const viewportState = useSelector(useMemo(() => getViewportStateSelector(index), [index]));
    const viewportElement = viewportElementMap[index];

    const { imageUrlList, seriesImagesLoading, imageList } = useImageUrlList(index);

    let imageIndex = imageList?.findIndex(image => image?.imageId === viewportState?.imageId);
    imageIndex = imageIndex === -1 ? 0 : imageIndex;

    const loading = viewportState?.loading || seriesImagesLoading;

    const onActiveViewportChanged = useEventCallback((e: CustomEvent) => {
        if (activeViewport !== index) {
            dispatch(updateActiveViewport(index));
            if (!dicomViewerInstance) return;
            dicomViewerInstance.activeElementIndex = index;

            updateSearchParams({ image: viewportState?.imageId, series: viewportState?.seriesId });
        }
        const wl = dicomViewerInstance.getWindowLevel(index);
        if (_pipe(windowLevel, _compactDict, isEmpty)) return;
        dispatch(updateWindowLevel(wl));
    });

    const onElementEnabled = useEventCallback((e: CustomEvent) => {
        const element = e.detail.element as HTMLDivElement;
        dicomViewerInstance?.onElementEnabled(element);
        if (element === viewportElement) return;

        const seriesId = isStudyDataset(dicomViewerInstance.dataset) ? viewportState?.seriesId : viewportState?.imageId;

        element.dataset.seriesId = seriesId;

        setViewportElement(index, element);

        dicomViewerInstance?.elementMap.set(index, element);
        dicomViewerInstance?.populateLabelMapIndexMap();

        window.activeElement = element;
    });

    const onImageRendered = useEventCallback((e: CornerstoneImageRenderedEvent) => {
        if (smartSegmentationEnabled)
            samPredictionHelper.debouncedGetEmbedding(samPredictionHelper.getParams(e.detail.image.imageId));

        const seriesId = isStudyDataset(dicomViewerInstance.dataset)
            ? viewportState?.seriesId
            : DicomViewerHelper.getImageIdFromUrl(e.detail.image.imageId);

        if (viewportElement.dataset.seriesId !== seriesId) {
            viewportElement.dataset.seriesId = seriesId;
        }

        dicomViewerInstance?.onImageRendered(e.detail.image, index);
    });

    const onStackChanged = useEventCallback((e: CornerstoneStackScrollEvent) => {
        if (imageIndex === e.detail.newImageIdIndex) return;
        changeImage({
            imageIndex: e.detail.newImageIdIndex,
            viewportIndex: index,
        });
    });

    useEvent('cornerstoneimagerendered', onImageRendered, viewportElement);
    useEvent('cornerstonetoolsstackscroll', onStackChanged, viewportElement);
    useEvent('cornerstoneelementenabled', onElementEnabled, viewportElement);

    return {
        activeViewport,
        cinePlay,
        frameRate,
        imageUrlList,
        viewportState,
        onActiveViewportChanged,
        onElementEnabled,
        prefetch,
        imageIndex,
        loading,
    };
}

function updateSearchParams(params: Dictionary) {
    const searchParams = new URLSearchParams(window.location.search);
    for (const [key, value] of Object.entries(params)) {
        searchParams.set(key, value);
    }
    const newurl =
        window.location.protocol + '//' + window.location.host + window.location.pathname + '?' + searchParams.toString();
    window.history.replaceState({ path: newurl }, '', newurl);
}
