import { selectActiveProjectId, setActiveProjectId, useGetProjectListQuery } from 'pages/Project/store';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/selectors';

export function useCheckProject() {
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const { data, isSuccess } = useGetProjectListQuery(undefined, { skip: !user?.userId });
    const activeProject = useSelector(selectActiveProjectId);

    useEffect(() => {
        if (!isSuccess || !activeProject || !user?.email) return;

        if (data?.some(p => p?.projectId === activeProject)) return;
        dispatch(setActiveProjectId(null));
    }, [activeProject, data, dispatch, isSuccess, user?.email]);
}
