import { Image } from 'antd';
import { getMessages } from 'common/helpers';
import { useCoverImage } from 'common/hooks/useCoverImage';
import GTooltip from 'components/GTooltip';
import { HasAnnotation } from 'components/assets/icons';
import ModelCardImage from 'components/assets/images/ModelCardImage.png';
import StatsTags, { IStats } from '../TagList/StatsTags';
import Styles from './Styles.module.scss';
import StudyCount from './StudyCount';

export default function CardImage({
    imageSrc,
    datasetId,
    statsList,
    defaultAnnotationId,
    showStudyCount = false, 
}: IImageSectionProps) {
    const { image } = useCoverImage({ src: imageSrc, datasetId: datasetId });

    return (
        <div className={Styles.CardImage}>
            {showStudyCount && <StudyCount datasetId={datasetId} />}

            <Image
                preview={false}
                src={image}
                alt="image"
                fallback={ModelCardImage}
                placeholder={ModelCardImage}
            />

            {defaultAnnotationId && (
                <GTooltip title={getMessages('000482')}>
                    <div className={Styles.AnnotationIcon}>
                        <HasAnnotation />
                    </div>
                </GTooltip>
            )}

            <StatsTags statsList={statsList} />
        </div>
    );
}

export const getValueFromField = (field: string | number | Array<string>) => (Array.isArray(field) ? field.join(' ') : field);
export interface IImageSectionProps<T = any> {
    imageSrc: string;
    datasetId?: string;
    statsList?: Array<IStats>;
    item?: T;
    defaultAnnotationId?: string;
    infoTagList?: Dictionary<string | Array<string>>;
    extraTagField?: React.ReactNode;
    tagList?: Array<string | Array<string>>;
    showStudyCount?: boolean;
}
