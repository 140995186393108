import React from 'react';
import { Spin } from 'antd';
import Styles from './Styles.module.scss';
import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import cs from 'classnames';

export default function GFallback({ className, allScreen }: IProps) {
    return (
        <div className={cs(Styles.FallbackContainer, { [Styles.AllScreen]: allScreen }, className)}>
            <Spin
                size="large"
                spinning={true}
                tip="Loading..."
                indicator={
                    <LoadingOutlined
                        style={{ fontSize: 64, color: '#fff' }}
                        spin
                    />
                }
            />
        </div>
    );
}

interface IProps {
    className?: string;
    allScreen?: boolean;
}
