import { AxiosResponse } from "axios";

export function downloadFileFromAxios(response: AxiosResponse<Blob>, fileName: string = 'download') {
    if (!response?.data) return;

    const link = document.createElement('a');

    link.setAttribute('download', fileName);
    link.setAttribute('style', 'display: none');
    link.href = window.URL.createObjectURL(response.data);
    document.body.appendChild(link);

    link.click();

    window.URL.revokeObjectURL(link.href);
    document.body.removeChild(link);
}