import { Form } from 'antd';
import { getMessages } from 'common';
import { GInput } from 'components';
import GForm, { IGFormProps } from 'components/GForm';
import GPasswordInput from 'components/GInput/GPasswordInput';
import GModal, { IGModalProps } from 'components/GModal';
import { ISetupConfigurationPayload } from 'pages/Authentication/entities/interfaces';
import { selectSetupModal } from 'pages/Authentication/store/Selectors';
import { setupConfiguration } from 'pages/Authentication/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './Styles.module.scss';

export default function SetupModal() {
    const dispatch = useDispatch();
    const setupModalState = useSelector(selectSetupModal);

    const [form] = Form.useForm();

    function onFinish(values: ISetupConfigurationPayload) {
        dispatch(setupConfiguration(values));
    }

    const modalProps: IGModalProps = {
        title: getMessages('000195'),
        footer: null,
        open: setupModalState?.visible,
        width: '400px',
        closable: false,
        destroyOnClose: true,
        className: Styles.ActivationModalContainer,
    };

    const formProps: IGFormProps = {
        name: 'setup-modal-form',
        layout: 'vertical',
        onFinish,
        form,
        requiredMark: false,
        hideRequiredMark: true,
        submitButton: true,
        className: Styles.FormContainer,
        id: 'setup-modal',
        submitLoading: setupModalState?.loading,
    };

    return (
        <GModal {...modalProps}>
            <GForm {...formProps}>
                <Form.Item
                    label={getMessages('001175')}
                    name={['configurations', 'organization']}
                    rules={[{ required: true }]}
                >
                    <GInput placeholder={getMessages('001175')} />
                </Form.Item>

                <Form.Item
                    label={getMessages('000124')}
                    name={['adminPassword', 'password']}
                    rules={[
                        {
                            required: true,
                            message: getMessages('001339'),
                        },
                        {
                            pattern: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&.,])[A-Za-z\d@$!%*?&.,]{13,}$/,
                            message: getMessages('000530'),
                        },
                    ]}
                    hasFeedback
                >
                    <GPasswordInput
                        type="password"
                        placeholder={getMessages('000124')}
                    />
                </Form.Item>
                <Form.Item
                    name={['adminPassword', 'confirm']}
                    label={getMessages('000125')}
                    dependencies={[['adminPassword', 'password']]}
                    hasFeedback
                    rules={[
                        {
                            required: true,
                            message: getMessages('000133'),
                        },
                        ({ getFieldValue }) => ({
                            validator(_, value) {
                                if (!value || getFieldValue(['adminPassword', 'password']) === value) {
                                    return Promise.resolve();
                                }
                                return Promise.reject(new Error(getMessages('000134')));
                            },
                        }),
                    ]}
                >
                    <GPasswordInput
                        type="password"
                        placeholder={getMessages('000125')}
                    />
                </Form.Item>
            </GForm>
        </GModal>
    );
}
