import { AxiosResponse } from "axios";
import { APIService, Dictionary, _keys, _values } from "common";
import { all, call } from "redux-saga/effects";
import { MismatchedPredictionsData } from "../../../metrics/entities";
import { IMismatchedPredictionsParams } from "../../../metrics/entities/Charts/IMismatchedPredictions";
import { IComparisonMetrics } from "../../entities";

export function* getExtraComparisonMetricsSaga(val1: string, val2: string, params?: Dictionary<any>): any {
    try {
        const response = yield all(_values(extraComparisonMetricsMap).map(({ dataFunc }) => call(dataFunc, val1, val2, params)));

        return _keys(extraComparisonMetricsMap).reduce((acc: any, key, index) => ({ ...acc, [key]: response[index] }), {});

    } catch (error) {
        console.log(error);
    }
}

export const extraComparisonMetricsMap: IExtraComparisonMetricsMap = {
    mismatchedPredictions: {
        dataFunc: getMismatchedPredictions,
        defaultParams: {
            limit: 300,
        }
    }
};

export async function getMismatchedPredictions(val1: string, val2: string, params: IMismatchedPredictionsParams = {}) {
    const response: AxiosResponse<MismatchedPredictionsData> = await APIService.Validation.GetMismatchedPredictions.post({ val1, val2, ...params });

    return response?.data;
}

export type IExtraComparisonMetricsMap = Partial<Record<keyof IComparisonMetrics, {
    defaultParams: Dictionary<any>;
    dataFunc: (val1: string, val2: string, params?: Dictionary<any>) => Promise<any>;
}>>;