import { LineSvgProps, ResponsiveLine } from '@nivo/line';
import _ from 'lodash';
import { IBaseChartProps } from '../entities';
import { ISerie, useSerieLegend } from '../hooks/useSerieLegend';
import { nivoTheme } from '../nivoTheme';

export default function BurndownChart({ chartID, className, data, chartOptions = {}, height, width, style }: ILineChartProps) {
    const { renderSerieLegends, selectedSeries } = useSerieLegend({ data });

    const ChartProps: LineSvgProps = {
        data: data?.filter(item => selectedSeries?.includes(item.id)),
        ..._.defaultsDeep(chartOptions, defaultOptions),
    };

    return (
        <div
            id={chartID}
            className={className}
            style={{ height, width, ...style }}
        >
            {renderSerieLegends()}
            <ResponsiveLine {...ChartProps} />
        </div>
    );
}

interface ILineChartProps extends IBaseChartProps {
    data?: Array<ISerie>;
    chartOptions?: Omit<LineSvgProps, 'data'>;
}

const defaultOptions: Omit<LineSvgProps, 'data'> = {
    margin: { top: 20, right: 20, bottom: 50, left: 60 },
    theme: nivoTheme,
    lineWidth: 2,

    xScale: {
        type: 'time',
        format: '%Y-%m-%d',
        useUTC: false,
        precision: 'day',
    },
    xFormat: 'time:%Y-%m-%d',
    yScale: {
        type: 'linear',
    },
    axisLeft: {
        legendOffset: -40,
        legendPosition: 'middle',
    },
    axisBottom: {
        format: '%b %d',
        tickValues: 'every 2 days',
        legendOffset: 36,
        legendPosition: 'middle',
    },
    pointSize: 8,
    pointBorderWidth: 1,
    pointBorderColor: {
        from: 'color',
        modifiers: [['darker', 0.3]],
    },
    useMesh: true,
    enableSlices: false,
    curve: 'linear',
    axisTop: null,
    colors: d => d.color,
};
