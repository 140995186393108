export function drawBrushPixels(
    pointerArray: Array<[number, number]>,
    pixelData: Float32Array,
    segmentIndex: number,
    columns: number,
    shouldErase = false,
    lockedSegments: Set<number> = new Set()
) {
    pointerArray.forEach(([x, y]) => {
        const spIndex = y * columns + x;

        if (shouldErase) {
            if (pixelData[spIndex] === segmentIndex) {
                pixelData[spIndex] = 0;
            }
        } else {
            if (lockedSegments.has(pixelData[spIndex])) return;

            pixelData[spIndex] = segmentIndex;
        }
    });
}
