import { AxiosResponse } from "axios";
import { APIService } from "common/services";
import { useEffect, useState } from "react";

export function useFetchSimilarDatasets(datasetId: string) {
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);
    
    async function getSimilarDatasets() {
        try {
            if (!datasetId) return;
            setLoading(true)
            const response: AxiosResponse<any> = await APIService.Datasets.GetDatasetSimilarities.get({ params: { base: datasetId } });
            if (response?.status === 200) {
                let similarResponse = response.data.filter((similar: any) => similar.base !== similar.compare)  /* filter itself out */
                if (similarResponse) {
                    setList(similarResponse)
                } else {
                    setList([])
                }
            }
            setLoading(false)
        } catch (error) {
            console.error(error);
        }
    }

    useEffect(() => {
        getSimilarDatasets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasetId]);

    return {list, loading};
}