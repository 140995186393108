import { PayloadAction } from "@reduxjs/toolkit";
import { _isEmpty, _mapValues } from "common";
import { setComparisonData, setComparisonDefaultParams, setComparisonExtraData, setComparisonGraphParams, setComparisonLayoutState, updateComparisonControlState } from "pages/Validation/store";
import { IBasicModelStatsData, IClassPerformanceData, IConfusionMatrixData, IDatasetClassDistributionData, IPopulationDistributionData, MetricsDataType } from "pages/Validation/views/validationAnalysis/components/metrics/entities";
import { ILayoutState } from "pages/Validation/views/validationAnalysis/components/metrics/entities/Charts/IChartState";
import { getStaticPayloadSaga } from "pages/Validation/views/validationAnalysis/components/metrics/store/saga/getStaticPayloadSaga";
import { all, call, fork, put } from "redux-saga/effects";
import { ComparisonChartKey } from "../../entities";
import { extraComparisonMetricsMap, getExtraComparisonMetricsSaga } from "./ExtraComparisonMetricsSaga";

export function* ComparisonStaticDataSaga({ payload }: PayloadAction<Array<string>>): any {
    try {
        yield fork(setLayoutStateSaga, "setAll", { isLoading: true });
        yield put(updateComparisonControlState({ isDataLoading: true }));

        const [result1, result2, extraComparisonMetrics] = yield all([
            call(getStaticPayloadSaga, payload[0]),
            call(getStaticPayloadSaga, payload[1]),
            call(getExtraComparisonMetricsSaga, payload[0], payload[1])
        ]);

        if (result1 || result2) {
            const { metricsExtraPayload, paramPayload } = result1;

            Object.assign(paramPayload, _mapValues(extraComparisonMetricsMap, value => value.defaultParams));

            if (!_isEmpty(paramPayload)) {
                yield put(setComparisonGraphParams(paramPayload));
                yield put(setComparisonDefaultParams(paramPayload));
            }

            let dataPayload = yield call(getCombinedPayloads, result1?.dataPayload, result2?.dataPayload, payload);

            Object.assign(dataPayload, extraComparisonMetrics);

            yield put(setComparisonExtraData(metricsExtraPayload));
            yield put(setComparisonData(dataPayload));

            yield fork(setLayoutStateSaga, "setAll", { isLoading: false });
            yield put(updateComparisonControlState({ isDataFetched: true, isDataLoading: false }));

        } else {
            yield fork(setLayoutStateSaga, "setAll", { isEmpty: true });
            yield put(updateComparisonControlState({ isDataLoading: false }));
        }
    } catch (error) {
        console.log(error);
        yield fork(setLayoutStateSaga, "setAll", { isEmpty: true });
    }
}

export function getCombinedPayloads(dataPayload1: Record<ComparisonChartKey, any>, dataPayload2: Record<ComparisonChartKey, any>, selectedValidations: Array<string>): any {
    try {
        const val1 = selectedValidations[0];
        const val2 = selectedValidations[1];

        return _mapValues(MetricsDataType, ((_, key: ComparisonChartKey) => combineChartData([val1, val2], key)(dataPayload1?.[key], dataPayload2?.[key])));
    } catch (error) {
        console.error(error);
    }
}

export function combineChartData([val1, val2]: [string, string], chartKey: ComparisonChartKey) {
    const CombinePayloads: Partial<Record<ComparisonChartKey, any>> = {
        basicModelStats: (data1: IBasicModelStatsData, data2: IBasicModelStatsData) => ({
            [val1]: data1?.Validation, [val2]: data2?.Validation
        }),
        classPerformance: (data1: IClassPerformanceData, data2: IClassPerformanceData) => ({
            [val1]: data1?.Validation, [val2]: data2?.Validation
        }),
        confusionMatrix: (data1: IConfusionMatrixData, data2: IConfusionMatrixData) => ({
            [val1]: data1?.Validation, [val2]: data2?.Validation, class_order: data1?.class_order
        }),
        datasetClassDistribution: (data1: IDatasetClassDistributionData, data2: IDatasetClassDistributionData) => ({
            [val1]: data1?.Validation, [val2]: data2?.Validation,
        }),
        populationDistribution: (data1: IPopulationDistributionData, data2: IPopulationDistributionData) => ({
            [val1]: data1?.Validation, [val2]: data2?.Validation,
        }),
    };

    return CombinePayloads[chartKey] ?? ((data1: any, data2: any) => ({ [val1]: data1, [val2]: data2 }));
}

export function* setLayoutStateSaga(key: ComparisonChartKey | "setAll", layout: Partial<ILayoutState>): any {
    try {
        if (key === "setAll") {
            const payload = _mapValues(MetricsDataType, (() => layout));

            yield put(setComparisonLayoutState(payload));

        } else {
            yield put(setComparisonLayoutState({ [key]: layout }));
        }

    } catch (error) {
        console.log(error);
    }
}
