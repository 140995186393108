import { getMessages } from 'common/helpers/MLHelper';
import { NoData } from 'components/assets/icons';
import React from 'react';
import Styles from "./Styles.module.scss";

interface GOverlayProps {
    children?: React.ReactNode;
    showNoDataOverlay?: boolean;
    showErrorOverlay?: boolean;
}

export default function GOverlay(props: GOverlayProps) {
    return (
        <>
            {props.children}
            {
                props.showNoDataOverlay
                    ?
                    <div className={Styles.NoDataIconContainer}>
                        <NoData />
                        <div>{getMessages("000307")}</div>
                    </div>
                    : props.showErrorOverlay
                        ? <div>{getMessages("000308")}</div>
                        : null
            }
        </>
    );
}
