import { AnnotationToolEnum, Dictionary, IWindowLevel } from 'common';
import { IClassControl } from './ISinglePrediction';

export interface IPredictionControls {
    imageControls: IImageControls;
    showResultPicture: boolean;
    classControls: Dictionary<IClassControl>;
    dicomImageDetails: DicomImageDetails;
    windowLevel: IWindowLevel;
    activeDicomTool: keyof typeof AnnotationToolEnum;
    showGroundTruth: boolean;
    interactiveViewerState: IInteractiveViewerState;
    isExplainParams: boolean; //explain switch value
    isBrushToolBoxVisible?: boolean;
}

export function PredictionControlsFactory(data?: Partial<IPredictionControls>): IPredictionControls {
    return {
        imageControls: ImageControlsFactory(data?.imageControls),
        showResultPicture: data?.showResultPicture ?? false,
        classControls: data?.classControls ?? {},
        dicomImageDetails: data?.dicomImageDetails ?? {},
        windowLevel: data?.windowLevel ?? { ww: undefined, wc: undefined },
        activeDicomTool: data?.activeDicomTool ?? null,
        showGroundTruth: data?.showGroundTruth ?? false,
        interactiveViewerState: data?.interactiveViewerState ?? {},
        isExplainParams: data?.isExplainParams ?? false,
    };
}

export interface IInteractiveViewerState {
    isActive?: boolean;
    downloadProgress?: number;
    zoom?: number;
}

export interface DicomImageDetails {
    loading?: boolean;
    metadata?: Dictionary;
    metadata_raw?: Dictionary;
}

export interface IImageControls {
    useRawImage: boolean;
    HFlip: boolean;
    VFlip: boolean;
    angle: number;
    cropOptions: Dictionary<any>;
}

export function ImageControlsFactory(data?: Partial<IImageControls>): IImageControls {
    return {
        useRawImage: data?.useRawImage ?? true,
        HFlip: data?.HFlip ?? false,
        VFlip: data?.VFlip ?? false,
        angle: data?.angle ?? 0,
        cropOptions: data?.cropOptions ?? null,
    };
}

export function ImageControlIsTouched(data?: IImageControls): boolean {
    if (!data) return false;

    const defaultItem = ImageControlsFactory();

    Object.entries(data).some(([key, item]: [keyof IImageControls, any]) => item !== defaultItem[key]);
}
