import { IValidationPlotResponse } from "..";
import { formatValue } from "./payload";

export interface IPlotTopMissesResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: "image";
        data: Array<IPlotTopMissesResponseItem>;
    };
}

export interface IPlotTopMissesResponseItem {
    image_id: string;
    image_name: string;
    rank: number;
    img_url: string;
    Loss: number;
    mIoU: number;
    FalsePositive: number;
    TruePositive: number;
    iou: number;
    fwiou: number;
    pAccs: number;
    TrueNegative: number;
    FalseNegative: number;
    DSC_Enhanced: number;
    DSC_Sets: number;
    DSC_CM: number;
    DSC: number;
    IoU_Sets: number;
    IoU_CM: number;
    IoU: number;
    Sensitivity_Sets: number;
    Sensitivity_CM: number;
    Sensitivity: number;
    Specificity_Sets: number;
    Specificity_CM: number;
    Specificity: number;
    Precision_Sets: number;
    Precision_CM: number;
    Precision: number;
    Accuracy_Sets: number;
    Accuracy_CM: number;
    BalancedAccuracy: number;
    AdjustedRandIndex: number;
    Accuracy: number;
    AUC_trapezoid: number;
    AUC: number;
    Kapp: number;
    SimpleHausdorffDistance: number;
    AverageHausdorffDistance: number;
    VolumetricSimilarity: number;
    MCC: number;
    MCC_Normalized: number;
    MCC_Absolute: number;
}

export type PlotTopMissesData = Array<IPlotTopMissesItem>;

export interface IPlotTopMissesItem {
    imageId: string;
    imageName: string;
    rank: number;
    imgURL: string;
    loss: number;
    mIoU: number;
    FalsePositive: number;
    TruePositive: number;
    iou: number;
    fwiou: number;
    pAccs: number;
    TrueNegative: number;
    FalseNegative: number;
    DSC_Enhanced: number;
    DSC_Sets: number;
    DSC_CM: number;
    DSC: number;
    IoU_Sets: number;
    IoU_CM: number;
    IoU: number;
    Sensitivity_Sets: number;
    Sensitivity_CM: number;
    Sensitivity: number;
    Specificity_Sets: number;
    Specificity_CM: number;
    Specificity: number;
    Precision_Sets: number;
    Precision_CM: number;
    Precision: number;
    Accuracy_Sets: number;
    Accuracy_CM: number;
    BalancedAccuracy: number;
    AdjustedRandIndex: number;
    Accuracy: number;
    AUC_trapezoid: number;
    AUC: number;
    Kapp: number;
    SimpleHausdorffDistance: number;
    AverageHausdorffDistance: number;
    VolumetricSimilarity: number;
    MCC: number;
    MCC_Normalized: number;
    MCC_Absolute: number;
}

export function setPlotTopMissesItem(data: Array<IPlotTopMissesResponseItem>): PlotTopMissesData {
    return data
        ?.map(item => ({
            imageId: item.image_id,
            imageName: item.image_name,
            rank: item.rank,
            imgURL: item.img_url,
            loss: formatValue(item.Loss),
            mIoU: formatValue(item.mIoU),
            FalsePositive: formatValue(item.FalsePositive),
            TruePositive: formatValue(item.TruePositive),
            iou: formatValue(item.iou),
            fwiou: formatValue(item.fwiou),
            pAccs: formatValue(item.pAccs),
            TrueNegative: formatValue(item.TrueNegative),
            FalseNegative: formatValue(item.FalseNegative),
            DSC_Enhanced: formatValue(item.DSC_Enhanced),
            DSC_Sets: formatValue(item.DSC_Sets),
            DSC_CM: formatValue(item.DSC_CM),
            DSC: formatValue(item.DSC),
            IoU_Sets: formatValue(item.IoU_Sets),
            IoU_CM: formatValue(item.IoU_CM),
            IoU: formatValue(item.IoU),
            Sensitivity_Sets: formatValue(item.Sensitivity_Sets),
            Sensitivity_CM: formatValue(item.Sensitivity_CM),
            Sensitivity: formatValue(item.Sensitivity),
            Specificity_Sets: formatValue(item.Specificity_Sets),
            Specificity_CM: formatValue(item.Specificity_CM),
            Specificity: formatValue(item.Specificity),
            Precision_Sets: formatValue(item.Precision_Sets),
            Precision_CM: formatValue(item.Precision_CM),
            Precision: formatValue(item.Precision),
            Accuracy_Sets: formatValue(item.Accuracy_Sets),
            Accuracy_CM: formatValue(item.Accuracy_CM),
            BalancedAccuracy: formatValue(item.BalancedAccuracy),
            AdjustedRandIndex: formatValue(item.AdjustedRandIndex),
            Accuracy: formatValue(item.Accuracy),
            AUC_trapezoid: formatValue(item.AUC_trapezoid),
            AUC: formatValue(item.AUC),
            Kapp: formatValue(item.Kapp),
            SimpleHausdorffDistance: formatValue(item.SimpleHausdorffDistance),
            AverageHausdorffDistance: formatValue(item.AverageHausdorffDistance),
            VolumetricSimilarity: formatValue(item.VolumetricSimilarity),
            MCC: formatValue(item.MCC),
            MCC_Normalized: formatValue(item.MCC_Normalized),
            MCC_Absolute: formatValue(item.MCC_Absolute),
        }))
        .sort((a, b) => a.rank - b.rank);
}