import { Input, } from 'antd';
import { PasswordProps } from 'antd/lib/input';
import React from 'react';
import Styles from "../assets/Styles.module.scss";
import cx from "classnames";

export default function GPasswordInput({ className, ...props }: GPasswordInputProps) {
    return (
        <div className={cx(Styles.GInputContainer, Styles.GPasswordInput, className)}>
            <Input.Password   {...props} />
        </div>
    );
}

interface GPasswordInputProps extends PasswordProps {
}