import { AxiosResponse } from 'axios';
import { ServiceEnum } from 'common/enum';
import { APIService, GetMethod, getUploadImageURL } from 'common/services';
import ModelCardImage from "components/assets/images/ModelCardImage.png";
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNetworkSpeed } from 'redux/selectors';

const defaultImages = ["/assets/model_image/ModelCardImage.png", "/assets/dataset_image/default_images.png"];

export function useCoverImage({ src, datasetId }: IUseCoverImagelArgs) {
    const [imageBlobUrl, setImageBlobUrl] = useState<string>(ModelCardImage);
    const [loading, setLoading] = useState(false);
    const { status } = useSelector(selectNetworkSpeed);
    const abortController = useRef(new AbortController());

    useEffect(() => {
        setLoading(true);

        getImage()
            .catch(() => getDefaultCoverImage())
            .finally(() => setLoading(false));

        return () => {
            if (imageBlobUrl) window.URL.revokeObjectURL(imageBlobUrl);
            if (loading) {
                abortController.current.abort();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [src]);

    async function getImage(url = src) {
        if (!url || status === "low" || defaultImages.includes(url)) return;
        abortController.current = new AbortController();
        const imageSrc = getUploadImageURL({ src: url });

        if (!imageSrc) return;

        const response = await (new GetMethod(imageSrc, undefined, ServiceEnum.None)).get({
            signal: abortController.current.signal,
            responseType: 'blob'
        });

        if (!response?.data) return;

        setImageBlobUrl(window.URL.createObjectURL(response.data));
    }

    async function getDefaultCoverImage() {
        try {
            if (!datasetId) return;
            setLoading(true);

            const url: AxiosResponse<string> = await APIService.Datasets.GetDatasetCoverImage.get({ params: { dataset_id: datasetId } });
            getImage(url.data);
        } catch (error) {
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return { image: imageBlobUrl, loading };
}

interface IUseCoverImagelArgs {
    src: string;
    datasetId?: string;
}