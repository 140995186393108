
export function downloadImage({ src, name }: IDownloadImageArgs) {
    const link = document.createElement('a');

    link.setAttribute('download', `${name}.png`);
    link.setAttribute("style", "display: none");
    link.href = src;
    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}

export interface IDownloadImageArgs {
    src: string;
    name: string;
}