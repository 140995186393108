import { Dictionary, IWindowLevel, TClassMapping, _entries, _get, _values, isNullOrUndefined } from 'common';
import { colorPalette } from 'components/assets/theme';
import { IModel } from 'pages/Models/entities';
import { IUserTransformationParams } from './IAugmentation';
import {
    IClassificationResultResponse,
    IConfidenceScore,
    IMask,
    IObjectDetectionResultResponse,
    IPredictionResponse,
    IPredictionResult,
    ISegmentationResultResponse,
    defaultResultFields,
    fieldList,
    pathMap,
} from './IPredictionResult';

export interface ISinglePredictionRequestPayload {
    image_id: string;
    dataset_id: string;
    image_type: string;
    user_transformations: IUserTransformationParams;
    threshold: number;
    slice: number;
    time_frame: number;
    windowing?: IWindowLevel;
    explain_params: Dictionary;
}

export interface IClassControl {
    class: string;
    selected: boolean;
    color: string;
    threshold?: number;
}

export function getClassControls(classMappings: TClassMapping, classPrefix = '', defaultThreshold = 0.25) {
    return _entries(classMappings).reduce((result: Dictionary<IClassControl>, [key, value]) => {
        const _key = classPrefix ? key + `__${classPrefix}` : key;
        result[_key] = {
            class: classPrefix ? value + `__${classPrefix}` : value,
            color: colorPalette[Math.floor(Math.random() * colorPalette.length)],
            selected: true,
            threshold: defaultThreshold,
        };
        return result;
    }, {});
}

export function getSegmentationResultFromResponse(
    payload: IPredictionResponse<ISegmentationResultResponse>,
    model: IModel
): IPredictionResult {
    const fieldMap = fieldList.segmentation.reduce((result, field) => {
        result[field] = _get(
            model?.outputMapping,
            pathMap.segmentation[field],
            _get(defaultResultFields.segmentation, pathMap.segmentation[field])
        );
        return result;
    }, {} as Record<(typeof fieldList.segmentation)[number], string>);

    return {
        isLoading: false,
        isEmpty: false,
        maskList: payload.response?.[fieldMap?.masks]?.map((_mask: any) => ({
            mask: _mask?.[fieldMap?.mask],
            rle: _mask?.[fieldMap?.rle],
            prediction_class: _mask?.[fieldMap?.prediction_class],
            confidence: getFormattedValue(_mask?.[fieldMap?.confidence]),
        })),
        timeTaken: payload.time_taken,
    };
}

export function getObjectDetectionResultFromResponse(
    payload: IPredictionResponse<IObjectDetectionResultResponse>,
    model: IModel
): IPredictionResult {
    const fieldMap = fieldList.object_detection.reduce((result, field) => {
        result[field] = _get(
            model?.outputMapping,
            pathMap.object_detection[field],
            _get(defaultResultFields.object_detection, pathMap.object_detection[field])
        );
        return result;
    }, {} as Record<(typeof fieldList.object_detection)[number], string>);

    const maskList: Array<IMask> = payload.response[fieldMap.objects]?.map((item: any) => {
        const { x1, y1, x2, y2 } = item[fieldMap.box] ?? {};
        return {
            mask: [
                { x: x1, y: y1 },
                { x: x1, y: y2 },
                { x: x2, y: y2 },
                { x: x2, y: y1 },
            ],
            prediction_class: item[fieldMap.prediction_class],
            confidence: item[fieldMap.confidence],
        };
    });

    return {
        isLoading: false,
        isEmpty: false,
        maskList,
        timeTaken: payload.time_taken,
    };
}

export function getClassificationResultFromResponse(
    payload: IPredictionResponse<IClassificationResultResponse>,
    model: IModel
): IPredictionResult {
    const { classMappings } = model;
    const data = payload?.response;

    const fieldMap = fieldList.classification.reduce((result, field) => {
        result[field] = _get(
            model?.outputMapping,
            pathMap.classification[field],
            _get(defaultResultFields.classification, pathMap.classification[field])
        );
        return result;
    }, {} as Record<(typeof fieldList.classification)[number], string>);

    let confidenceScore: Array<IConfidenceScore> = _values(classMappings).map((name, index) => ({
        name,
        value: getFormattedValue(data[fieldMap.logits]?.[index]),
    }));

    const prediction_class = data[fieldMap.prediction_class];
    const confidence = data[fieldMap.confidence];

    return {
        isLoading: false,
        isEmpty: false,
        classification: {
            confidenceScore,
            result: {
                name: classMappings[prediction_class],
                value: getFormattedValue(confidence),
            },
        },
        timeTaken: payload.time_taken,
    };
}

const getFormattedValue = (value: number) =>
    !isNullOrUndefined(value) && !isNaN(Number(value)) ? Number((Math.floor(value * 100) / 100).toFixed(2)) : undefined;
