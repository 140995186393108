export interface IExportedReports {
    _id: any;
    id?: string;
    userId: string;
    type: string;
    validationId?: string;
    datasetId?: string;
    modelId?: string;
    exporterId: string;
    exportedAt: number;
    created_timestamp: number;
    filters: Dictionary<any>;
}

export interface IExportedMetricsState {
    isPreviewModalVisible: boolean;
    selectedId: string;
    type: string;
    selectedPath: string;
}

export function ExportedMetricsStateFactory(data?: Partial<IExportedMetricsState>): IExportedMetricsState {
    return {
        isPreviewModalVisible: data?.isPreviewModalVisible ?? false,
        selectedId: data?.selectedId ?? null,
        selectedPath: data?.selectedPath ?? null,
        type: data?.type ?? null,
    };
}
