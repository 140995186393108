import { DeploymentStatus } from "common";
import dayjs from "dayjs";
import { IModel } from "pages/Models/entities";
import { FilterValue } from "pages/Validation/components/DateFilterButton";
import { findModel, selectModelList, selectValidationList } from "redux/selectors";
import { RootState } from "redux/store";
import { createSelector } from "reselect";
import { IValidationRun } from "../../summary/entities";
import { ValidationStatus } from "../../summary/entities/IValidation";

export const selectImageMap = (state: RootState) => state.validation.startValidation.robustValidationState.imageMap;
export const selectTotalDataSize = (state: RootState) => state.validation.startValidation.robustValidationState.totalDataSize;
export const selectImageControls = (state: RootState) => state.validation.startValidation.robustValidationState.imageControls;
export const selectAugmentationProperties = (state: RootState) => state.validation.startValidation.robustValidationState.augmentationProperties;
export const selectSelectedImage = (state: RootState) => state.validation.startValidation.robustValidationState.selectedImage;
export const selectProbabilityMap = (state: RootState) => state.validation.startValidation.robustValidationState.probabilityMap;

export const selectImageCount = (state: RootState) => state.validation.startValidation.validationParams.imageCount;
export const selectNumberOfWorker = (state: RootState) => state.validation.startValidation.validationParams.numberOfWorker;
export const selectBatchSize = (state: RootState) => state.validation.startValidation.validationParams.batchSize;
export const selectWindowLevel = (state: RootState) => state.validation.startValidation.validationParams.windowLevel;
export const selectGenerateMetrics = (state: RootState) => state.validation.startValidation.validationParams.generateMetrics;
export const selectRowCount = (state: RootState) => state.validation.startValidation.validationParams.rowCount;

export const selectDeployedModelList = createSelector<any, Array<IModel>>(
    selectModelList,
    (models: Array<IModel>) => models?.filter(model => model.deploymentStatus === DeploymentStatus.UP && model.dockerhub !== true)
);

export const selectDeployedModelsCount = createSelector<any,number>(
    selectModelList,
    (models: Array<IModel>) => models?.filter(model => model.deploymentStatus === DeploymentStatus.UP && model.dockerhub !== true)?.length
);

export const selectRunningWorksCount = createSelector<any, number>(
    selectValidationList,
    (validationRuns: Array<IValidationRun>) => validationRuns?.filter(item => item.validationStatus === ValidationStatus.batch_inprogress)?.length
);


export function selectJobsSubmittedDatasource(filterValue: FilterValue) {
    return createSelector<any, Array<IValidationRun>>(
        selectValidationList,
        selectModelList,
        (validationlist: Array<IValidationRun>, modelList: Array<IModel>) => getDataSource(validationlist, modelList, filterValue)
    );
}

function getDataSource(validationRunList: IValidationRun[], modelList: Array<IModel>, filterValue: FilterValue): IValidationRun[] {
    if (!validationRunList) return [];

    return getFilteredList(validationRunList, filterValue)
        .map(item => ({
            ...item,
            modelStatus: findModel(modelList, item.modelId)?.deploymentStatus
        }))
        .filter(v => v.archived !== true);
}

function getFilteredList(validationRunList: IValidationRun[], filterValue: FilterValue) {
    switch (filterValue) {
        case "month":
            return validationRunList.filter(item => dayjs().diff(dayjs.unix(item.createdTimestamp), "month") === 0);
        case "week":
            return validationRunList.filter(item => dayjs().diff(dayjs.unix(item.createdTimestamp), "week") === 0);

        default:
            return validationRunList;
    }
}

