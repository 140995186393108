import SyncOutlined from '@ant-design/icons/SyncOutlined';
import { ValidationStatus } from "pages/Validation/views/summary/entities/IValidation";
import Styles from "./Styles.module.scss";
import cx from "classnames";

export default function CompletionTag({ status, progress }: TCompletionTagProps) {
  const { text, className } = getTagContent(status, progress);

  return (
    <div className={Styles.TagContainer}>
      {
        status === ValidationStatus.batch_inprogress
          ? <SyncOutlined className={className} spin />
          : <div className={cx(Styles.StatusTag, className)} />
      }
      <div className={Styles.TagText}>{text}</div>
    </div>
  );
}

function getTagContent(status: ValidationStatus, progress: number) {
  switch (status) {
    case ValidationStatus.batch_inprogress:
      const text = progress ? `Running | ${progress}%` : "Running";
      return { text, className: Styles.Running };

    case ValidationStatus.done:
      return { text: `Completed`, className: Styles.Done };

    case ValidationStatus.batch_done:
      return { text: `Batch Comp.`, className: Styles.BatchDone };
    case ValidationStatus.batch_partial:
      return { text: `Batch Part.`, className: Styles.BatchDone };
    case ValidationStatus.halt:
      return { text: `Stopped`, className: Styles.Stopped };

    case ValidationStatus.validation_failed:
    case ValidationStatus.batch_failed:
      return { text: `Failed`, className: Styles.Failed };
    default:
      return { text:"Pending...", className: Styles.Pending}

  }
}

interface TCompletionTagProps {
  status: ValidationStatus;
  progress?: number,
};