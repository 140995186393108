import { Menu, MenuProps } from 'antd';
import cs from 'classnames';
import Styles from './GMenu.module.scss';

export default function GMenu({ className, ...props }: IProps) {
    return (
        <>
            <Menu
                className={cs(Styles.MenuContainer, className)}
                {...props}
            />
        </>
    );
}

export const GMenuClassName = Styles.MenuContainer;

export interface IProps extends MenuProps {}
