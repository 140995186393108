import { cornerstoneTools } from 'common/helpers/CornerstoneInitHelper/CornerstoneHelper';
import { CornerstoneToolEvents } from 'common/helpers/DicomViewerHelper/interface/Events';

const triggerEvent = cornerstoneTools.importInternal('util/triggerEvent');

export function triggerLabelmapModifiedEvent(
    element: HTMLElement,
    { labelmapIndex, ...args }: LabelMapModifiedEventPayload = {}
) {
    const segmentationModule = cornerstoneTools?.getModule('segmentation');

    labelmapIndex = labelmapIndex ?? segmentationModule.getters.activeLabelmapIndex(element);

    triggerEvent(element, CornerstoneToolEvents.LABELMAP_MODIFIED, {
        labelmapIndex,
        ...args,
    });
}

export interface LabelMapModifiedEventPayload {
    labelmapIndex?: number;
    segmentIndexList?: number[];
    imageIdIndex?: number;
}
