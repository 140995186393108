import { selectNotifications } from 'app/store/selectors';
import { getMessages, RoutingHelper } from 'common';
import { Setting } from 'components/assets/icons';
import GTabs from 'components/GTabs';
import { setDropdownOpen } from 'pages/User/store';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import NotificationListContainer from './NotificationList';
import Styles from './Styles.module.scss';

export default function NotificationMenuOverlay() {
    const dispatch = useDispatch();
    const notifications = useSelector(selectNotifications);

    function handleCloseDropdown() {
        dispatch(setDropdownOpen(false));
    }

    return (
        <div className={Styles.NotificationOverlayContainer}>
            <div
                onClick={e => e.stopPropagation()}
                className={Styles.Header}
            >
                <div className="pBody-bold white">{getMessages('000226')}</div>
                <Link
                    onClick={handleCloseDropdown}
                    to={RoutingHelper.User({ activeView: 'notifications', location: 'settings' }).uri}
                    className={Styles.Setting}
                >
                    <Setting
                        style={{ color: '#C5D0DE' }}
                        className="pointer"
                    />
                </Link>
            </div>

            <GTabs
                tabList={tabList}
                className={Styles.TabContainer}
            />

            {notifications.length ? (
                <Link
                    onClick={handleCloseDropdown}
                    to={RoutingHelper.User({ location: 'notifications', activeView: 'all' }).uri}
                    className={Styles.ViewNotifications}
                >
                    {getMessages('000224')}
                </Link>
            ) : null}
        </div>
    );
}

const tabList = [
    {
        key: 'last5Notifications',
        title: 'Recent Messages',
        content: <NotificationListContainer type="recentMessages" />,
    },
    {
        key: 'activities',
        title: getMessages('000227'),
        content: <NotificationListContainer type="activities" />,
    },
    {
        key: 'system',
        title: getMessages('000211'),
        content: <NotificationListContainer type="systemMessages" />,
    },
];
