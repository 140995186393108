/* eslint-disable eqeqeq */
import cx from "classnames";
import { getMessages, toggleFullScreen } from 'common';
import { FullScreen } from 'components/assets/icons';
import GTooltip from 'components/GTooltip';
import { useCallback, useEffect, useState } from 'react';
import IconBackground from "../IconBackground";
import Styles from "./Styles.module.scss";

export default function FullScreenView() {
    const [fullScreen, setFullScreen] = useState(false);
    const [f11Pressed, setF11Pressed] = useState(false);

    const message = fullScreen ? getMessages("000936") : getMessages("000935");

    function onFullScreen() {
        if (f11Pressed) return;

        toggleFullScreen(!fullScreen);
        setFullScreen(!fullScreen);
    }

    const exitHandler = useCallback(() => {
        const doc = document as DocumentWithFullscreen;
        if (!doc.fullscreenElement && !doc.webkitIsFullScreen && !doc.mozFullScreen && !doc.msFullscreenElement && fullScreen) {
            setFullScreen(false);
        }
    }, [fullScreen]);

    useEffect(() => {
        if (document.addEventListener) {
            document.addEventListener('webkitfullscreenchange', exitHandler, false);
            document.addEventListener('mozfullscreenchange', exitHandler, false);
            document.addEventListener('fullscreenchange', exitHandler, false);
            document.addEventListener('MSFullscreenChange', exitHandler, false);
        }

        document.onkeydown = function (e) {
            if (e.code === "F11") {
                setF11Pressed(!f11Pressed);
            }
        };

        window.onresize = function () {
            const maxHeight = window.screen.height,
                maxWidth = window.screen.width,
                curHeight = window.innerHeight,
                curWidth = window.innerWidth;

            const isFullScreen = maxWidth == curWidth && maxHeight == curHeight;

            if (isFullScreen !== fullScreen) setFullScreen(isFullScreen);
            if (!isFullScreen && f11Pressed) setF11Pressed(false);

        };
    }, [exitHandler, f11Pressed, fullScreen]);

    return (
        <GTooltip title={message}>
            <div>
                <IconBackground
                    icon={<FullScreen
                        aria-disabled
                        onClick={onFullScreen}
                        className={cx(Styles.FullScreen, { [Styles.FullscreenDisabled]: f11Pressed })} />}
                />
            </div>
        </GTooltip>
    );
}

interface DocumentWithFullscreen extends Document {
    webkitIsFullScreen?: Element;
    mozFullScreen?: Element;
    msFullscreenElement?: Element;
}
