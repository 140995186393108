import { Notification } from 'components';
import { SessionReminder } from 'pages/Authentication/components';
import PlatformAlertModal from 'pages/Platform/views/PlatformMonitoring/components/PlatformAlertModal';
import ModalContainer from 'react-modal-promise';
import { ToastContainer } from 'react-toastify';

export default function CombineProviders() {
    return (
        <>
            <ModalContainer />
            <ToastContainer {...Notification.deafultOptions} />
            <SessionReminder />
            <PlatformAlertModal />
        </>
    );
}
