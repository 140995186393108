
import { put } from "redux-saga/effects";
import { updateUserAccountInfo } from "..";

export function* updateUserAccountInformationSaga(sagaAction: any): any {
  try {
    yield put(updateUserAccountInfo(sagaAction.payload));
  } catch (e) {
    console.log(e);
  } finally {
  }
}
