export interface IObjectComparisonState {
    label: string;
    studyId: string;
    seriesId: string;
    imageIdIndex: number;
    labelModalOpen: boolean;
    userStateMap: Record<string, UserState>;
}

export function ObjectComparisonFactory(data?: Partial<IObjectComparisonState>): IObjectComparisonState {
    return {
        label: data?.label ?? '',
        studyId: data?.studyId ?? '',
        seriesId: data?.seriesId ?? '',
        imageIdIndex: data?.imageIdIndex ?? 0,
        labelModalOpen: data?.labelModalOpen ?? false,
        userStateMap: data?.userStateMap ?? {},
    };
}

export interface UserState {
    type: 'user' | 'project';
    color: string;
    visible: boolean;
    enabled: boolean;
}

export function UserStateFactory(data?: Partial<UserState>): UserState {
    return {
        type: data?.type ?? 'user',
        color: data?.color ?? '',
        visible: data?.visible ?? true,
        enabled: data?.enabled ?? true,
    };
}
