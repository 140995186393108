import { _compact, _entries, _isEmpty, _uuid, _values, isNullOrUndefined } from 'common/Utils';
import { getRandomSegmentationColor } from 'components/assets/theme';
import { Image } from 'cornerstone-core';
import dcmjs from 'dcmjs';
import _debounce from 'lodash/debounce';
import { cornerstone, cornerstoneTools } from '../CornerstoneInitHelper/CornerstoneHelper';
import { hexToRgb } from '../SegmentationHelper/GenericUtils';
import { triggerLabelmapModifiedEvent } from '../SegmentationHelper/lib';
import { DicomViewerCore } from './DicomViewerCore';
import {
    Color,
    DiceComparisonArgs,
    ICreateSegmentListArgs,
    IHistoryOperation,
    LabelMap2DReturnType,
    LabelMap3D,
    LabelMaps3DReturnType,
    ReplaceMode,
    Segmentation,
    SegmentationMetadataFactory,
    SegmentationModule,
} from './interface';

const segmentationUtils = cornerstoneTools.importInternal('util/segmentationUtils');

export abstract class SegmentationUtils extends DicomViewerCore {
    protected currentSegmentLabel: string;

    getSegmentLabelMap(viewport: number | HTMLElement = this.activeElementIndex, labelmapIndex?: number) {
        const segmentLabelMap = new Map<string, Segmentation>();

        const element = typeof viewport === 'number' ? this.getViewportElement(viewport) : viewport;
        if (!this.isElementEnabled(element)) return segmentLabelMap;

        const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
        if (!labelmaps3D) return segmentLabelMap;

        labelmaps3D.forEach((labelmap3D, i) => {
            if (!labelmap3D || (!_isEmpty(labelmapIndex) && i !== labelmapIndex)) return;
            const metadata = labelmap3D?.metadata ?? null;
            const colorLutTable = this.segmentationState.colorLutTables[labelmap3D.colorLUTIndex];

            _compact(metadata).forEach(meta => {
                const color = colorLutTable[meta.SegmentNumber];

                segmentLabelMap.set(meta.SegmentLabel, {
                    id: i + '+' + meta.SegmentNumber,
                    labelmapIndex: i,
                    segmentIndex: meta.SegmentNumber,
                    color,
                    meta,
                    name: meta.SegmentLabel,
                    description: meta.SegmentDescription,
                });
            });
        });

        return segmentLabelMap;
    }
    getSegmentLabelNameList(viewport: number | HTMLElement = this.activeElementIndex, labelmapIndex?: number) {
        return Array.from(this.getSegmentLabelMap(viewport, labelmapIndex).keys());
    }

    public labelMapIndexMap = new Map<string, number>();
    getLabelMapIndex(labelMapKey: string, viewportIndex = this.activeElementIndex): number {
        if (this.labelMapIndexMap.has(labelMapKey)) return this.labelMapIndexMap.get(labelMapKey);
        let _newIndex = this.labelMapIndexMap.size;

        const element = this.getViewportElement(viewportIndex);
        if (this.isElementEnabled(element)) {
            const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
            if (labelmaps3D) _newIndex = labelmaps3D.length;
        }

        this.labelMapIndexMap.set(labelMapKey, _newIndex);
        return _newIndex;
    }

    getLabelmapKeyFromIndex(index: number): string {
        let result;
        this.labelMapIndexMap.forEach((value, key) => {
            if (value === index) result = key;
        });
        return result;
    }

    populateLabelMapIndexMap() {
        _values(this.segmentationState?.series).forEach(item => {
            item.labelmaps3D?.forEach((labelmap3D, i) => {
                if (!labelmap3D?.key) return;
                if (!this.getLabelmapKeyFromIndex(i)) this.labelMapIndexMap.set(labelmap3D.key, i);
            });
        });
    }

    elementWaitedMethods = new Map<string, { name: string; args: any[] }>();
    applyElementWaitedMethods() {
        this.elementWaitedMethods.forEach(({ name, args = [] }) => {
            (this as any)[name](...args);
        });
    }

    createSegmentList(args: ICreateSegmentListArgs, functionId = _uuid()) {
        try {
            let { colorMap, labelList, labelMapKey, onColorList, seriesId, viewportIndex = this.activeElementIndex } = args;
            if (!labelList?.length) return;
            const element = this.getViewportElement(viewportIndex);
            const image = this.getEnabledElement(element)?.image;

            const labelMapIndex = this.getLabelMapIndex(labelMapKey, viewportIndex);

            if (!element || !image || (seriesId && this.getSeriesIdFromMetadata(image) !== seriesId)) {
                this.elementWaitedMethods.set(functionId, {
                    name: 'createSegmentList',
                    args: [args, functionId],
                });
                return labelMapIndex;
            }

            this.setters.activeLabelmapIndex(element, labelMapIndex);

            const colorList = labelList.map((label, i) => {
                this.setters.activeSegmentIndex(element, i);

                return {
                    color: colorMap?.[label]
                        ? hexToRgb(colorMap[label])
                        : hexToRgb(getRandomSegmentationColor(_values(colorMap))),
                    label,
                };
            });

            this.setters.colorLUT(
                labelMapIndex,
                colorList.map(({ color }) => [..._values(color), 120])
            );

            labelList.forEach((label, i) => {
                const index = i + 1;
                const metadata = SegmentationMetadataFactory({
                    SegmentNumber: index,
                    SegmentLabel: label,
                    SegmentDescription: label,
                });

                this.setters.metadata(element, labelMapIndex, index, metadata);

                this.setters.activeSegmentIndex(element, index);
            });
            const { labelmap3D } = this.getLabelMap2D(element);

            labelmap3D.colorLUTIndex = labelMapIndex;
            labelmap3D.key = labelMapKey;
            labelmap3D.index = labelMapIndex;

            onColorList?.(colorList);

            if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);

            return labelMapIndex;
        } catch (error) {
            console.error(error);
        }
    }

    clearSegmentations(viewportIndex?: number): void {
        if (_isEmpty(viewportIndex)) return this.elementMap.forEach((_, index) => this.clearSegmentations(index));

        const element = this.getViewportElement(viewportIndex);
        this.getters.labelmaps3D(element)?.labelmaps3D?.forEach((labelmap3D: LabelMap3D) => {
            labelmap3D?.labelmaps2D.forEach(labelmap2D => {
                if (!labelmap2D) return;
                labelmap2D.segmentsOnLabelmap = [0];
                labelmap2D.pixelData = new Float32Array(labelmap2D.pixelData.length);
            });
        });
    }

    removeLabelMapSegmentations(labelMapIndex: number, viewportIndex?: number, functionId = _uuid()): any {
        if (_isEmpty(viewportIndex))
            return this.elementMap.forEach((_, index) => this.removeLabelMapSegmentations(labelMapIndex, index));
        const element = this.getViewportElement(viewportIndex);
        if (!this.isElementEnabled(element))
            return this.elementWaitedMethods.set(functionId, {
                name: 'removeLabelMapSegmentations',
                args: [labelMapIndex, viewportIndex, functionId],
            });

        const { labelmaps3D } = this.getters.labelmaps3D(element);
        if (!labelmaps3D) return;
        labelmaps3D[labelMapIndex]?.labelmaps2D.forEach(labelmap2D => {
            if (!labelmap2D) return;
            labelmap2D.segmentsOnLabelmap = [0];
            labelmap2D.pixelData = new Float32Array(labelmap2D.pixelData.length);
        });
        if (this.elementWaitedMethods.has(functionId)) this.elementWaitedMethods.delete(functionId);
    }

    resetElementToolState(viewportIndex: number = this.activeElementIndex) {
        const element = this.getViewportElement(viewportIndex);
        if (!this.isElementEnabled(element)) return;
        const enabledElement = this.getEnabledElement(element) as any;

        enabledElement.toolStateManager = cornerstoneTools.newImageIdSpecificToolStateManager();
    }

    calculateSegmentArea(pixelData: Float32Array | Array<number>, label: number, spacing: Array<number>) {
        const pixelCount = pixelData.filter(pixel => pixel === label).length;
        const areaInMm2 = pixelCount * (spacing[0] * spacing[1]);
        return areaInMm2;
    }

    filterLabelPixels(pixelData: Float32Array, segmentIndex: number): Float32Array {
        return pixelData.map(pixel => (pixel === segmentIndex ? pixel : 0));
    }

    clearSegmentationFromLabelmap(element: HTMLDivElement, labelmapindex: number, segmentIndex: number) {
        if (_isEmpty(element, labelmapindex, segmentIndex)) return;

        try {
            const labelmap3D: LabelMap3D = this.getters.labelmap3D(element, labelmapindex);
            if (!labelmap3D) return;

            labelmap3D?.labelmaps2D?.forEach((labelmap2D, i) => {
                if (!labelmap2D?.segmentsOnLabelmap?.includes(segmentIndex)) return;

                const previousPixelData = structuredClone(labelmap2D.pixelData);

                for (let i = 0; i < labelmap2D.pixelData.length; i++) {
                    if (labelmap2D.pixelData[i] === segmentIndex) {
                        labelmap2D.pixelData[i] = 0;
                    }
                }

                const operation = {
                    imageIdIndex: i,
                    diff: segmentationUtils.getDiffBetweenPixelData(previousPixelData, labelmap2D.pixelData),
                };

                this.segmentationModule.setters.pushState(element, [operation]);

                this.segmentationModule.setters.updateSegmentsOnLabelmap2D(labelmap2D);
            });
        } catch (error) {
            console.error(error);
        }
    }

    changeBrushRadius(_radius: number) {
        this.configuration.radius = _radius;
    }

    changeInactiveSegmentOpacity(opacity: number) {
        this.changeAllSegmentOpacity(1);
        this.inActiveSegmentOpacity = opacity;
        this.brushState.forEach(labelmap3D => {
            labelmap3D.metadata.forEach(meta => {
                if (meta?.SegmentLabel === this.currentSegmentLabel) return;
                const colorLut = this.segmentationState.colorLutTables[labelmap3D.colorLUTIndex];
                colorLut[meta?.SegmentNumber][3] = opacity;
            });
        });
    }

    updateLabelLockMap(map: Dictionary<boolean>) {
        if (!this.configuration.lockedSegments) this.configuration.lockedSegments = new Set();

        _entries(map).forEach(([label, value]) => {
            const segmentIndex = this.getSegmentLabelMap().get(label)?.segmentIndex;
            if (value) {
                this.configuration.lockedSegments.add(segmentIndex);
            } else {
                this.configuration.lockedSegments.delete(segmentIndex);
            }
        });
    }

    changeActiveSegmentOpacity(opacity: number) {
        this.changeAllSegmentOpacity(1);
        this.brushState.forEach(labelmap3D => {
            const segmentIndex = labelmap3D.metadata.find(meta => meta?.SegmentLabel === this.currentSegmentLabel)?.SegmentNumber;
            const colorLut = this.segmentationState.colorLutTables[labelmap3D.colorLUTIndex];

            if (_isEmpty(segmentIndex)) return;

            colorLut[segmentIndex][3] = opacity;
        });
    }

    changeAllSegmentOpacity(opacity: number) {
        this.segmentationModule.configuration.fillAlpha = opacity;
    }

    changeAllSegmentBorderWidth(width: number) {
        this.segmentationModule.configuration.outlineWidth = width;
    }

    changeAllSegmentBorderVisibility(visible: boolean, opacity: number) {
        this.segmentationModule.configuration.renderOutline = visible ? true : false;
        if (opacity) this.changeAllSegmentOpacity(opacity);
    }

    //mask encoding as a string
    maskToRle(image: Float32Array): string {
        let result = '';

        let i = 0;
        while (i < image.length) {
            if (image[i] === 0) {
                i++;
                continue;
            }
            let j = i + 1;
            let count = 1;
            while (image[i] === image[j]) {
                count++;
                j++;
            }
            result = result.concat(`${i} ${count} `);
            i = j;
        }
        return result.trimEnd();
    }

    labelmap2DToRle(labelmap2D: Float32Array) {
        const maskList: Array<{ mask: string; segmentIndex: number; count: number }> = [];
        let i = 0;
        while (i < labelmap2D.length) {
            if (labelmap2D[i] === 0) {
                i++;
                continue;
            }
            let j = i + 1;
            let count = 1;
            while (labelmap2D[i] === labelmap2D[j]) {
                count++;
                j++;
            }

            const item = maskList.find(item => item.segmentIndex === labelmap2D[i]);
            if (item) {
                item.mask = item.mask.concat(`${i} ${count} `);
                item.count += count;
            } else {
                maskList.push({ mask: `${i} ${count} `, segmentIndex: labelmap2D[i], count });
            }
            i = j;
        }
        return maskList;
    }

    //rle decoding
    rleToMask(mask_rle: string, length: number, label: number = 0): Float32Array {
        const mask = new Float32Array(length);

        if (_isEmpty(mask_rle)) return mask;

        const rleList = mask_rle.split(' ');
        rleList.forEach((item, i) => {
            if (i % 2 !== 0) return;
            const start = Number(item);
            const length = Number(rleList[i + 1]);

            for (let j = start; j < start + length; j++) {
                mask[j] = label;

                if (j >= mask.length) break;
            }
        });

        return mask;
    }

    mergePixelDataLabels(pixelDataList: Array<Float32Array>, length: number) {
        const mergedPixelData = new Float32Array(length);

        for (let i = 0; i < length; i++) {
            mergedPixelData[i] = 0;

            pixelDataList.forEach(pixelData => {
                if (pixelData[i] !== 0) {
                    mergedPixelData[i] = pixelData[i];
                }
            });
        }
        return mergedPixelData;
    }

    exportAsSegModalityDcm(element: HTMLDivElement) {
        const stacktoolState = cornerstoneTools?.getToolState(element, 'stack');
        const imageIds: Array<string> = stacktoolState.data[0].imageIds;

        let imagePromises = imageIds?.map(image => cornerstone.loadImage(image));

        const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);

        if (!labelmaps3D) return console.log('No labelmaps3D found for element');

        labelmaps3D.forEach(labelmap3D => {
            const labelmaps2D = labelmap3D.labelmaps2D;

            labelmaps2D.forEach(labelmap2D => {
                labelmap2D?.segmentsOnLabelmap?.forEach((segmentIndex: any) => {
                    if (segmentIndex !== 0 && !labelmap3D.metadata[segmentIndex]) {
                        labelmap3D.metadata[segmentIndex] = this.generateMockMetadata(segmentIndex);
                    }
                });
            });
        });

        Promise.all(imagePromises)
            .then(images => {
                //const segBlob = this.dcmjs.adapters.Cornerstone.Segmentation.generateSegmentation(images, labelmaps3D);
                //Create a URL for the binary.
                //var objectUrl = URL.createObjectURL(segBlob);
                //              window.open(objectUrl);
            })
            .catch(err => console.log(err));
    }

    generateMockMetadata(segmentIndex: any) {
        return {
            SegmentedPropertyCategoryCodeSequence: {
                CodeValue: 'T-D0050',
                CodingSchemeDesignator: 'SRT',
                CodeMeaning: 'Tissue',
            },
            SegmentNumber: (segmentIndex + 1).toString(),
            SegmentLabel: 'Tissue ' + (segmentIndex + 1).toString(),
            SegmentAlgorithmType: 'SEMIAUTOMATIC',
            SegmentAlgorithmName: 'Gesund AI',
            SegmentedPropertyTypeCodeSequence: {
                CodeValue: 'T-D0050',
                CodingSchemeDesignator: 'SRT',
                CodeMeaning: 'Tissue',
            },
        };
    }

    hideAllSegments() {
        this.elementMap.forEach((element, index) => {
            const { labelmap3D } = this.getLabelMap2D(element);

            if (!labelmap3D?.metadata) return;

            labelmap3D.metadata
                .map(meta => meta.SegmentNumber)
                .forEach(index => {
                    labelmap3D.segmentsHidden[index] = !labelmap3D.segmentsHidden[index];
                });

            this.forceRender(index);
        });
    }

    hideOrShowActiveSegment(segmentIndex: number) {
        this.elementMap.forEach(element => {
            const labelmaps3D = this.getLabelMap2D(element);
            const labelmap3D = labelmaps3D.labelmap3D;
            const segmentsHidden = labelmap3D.segmentsHidden;

            if (!labelmap3D) return;
            if (!labelmap3D.metadata) return;

            const SegmentIndex = labelmap3D.metadata.find(item => item.SegmentNumber === segmentIndex);
            if (!SegmentIndex) return;
            segmentsHidden[segmentIndex] = !segmentsHidden[segmentIndex];
        });

        this.forceRender();
    }

    hideOrShowActiveSegmentById(label: string, visible?: boolean) {
        try {
            this.elementMap.forEach(element => {
                if (!this.isElementEnabled(element)) return;
                const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);

                labelmaps3D?.forEach(labelmap3D => {
                    const segmentsHidden = labelmap3D.segmentsHidden;

                    const Segment = labelmap3D?.metadata?.find(item => item?.SegmentLabel === label);
                    if (!Segment) return;
                    let number = Segment.SegmentNumber;
                    segmentsHidden[number] = !(visible ?? segmentsHidden[number]);
                });
            });

            this.forceRender();
        } catch (error) {
            console.log(error);
        }
    }

    undoStateSegmentation() {
        const labelmap3D = this.getters.labelmap3D(this.activeElement);
        const { undo, redo } = labelmap3D;

        if (!undo.length || this.activeTool === 'SAMPointPrediction' || this.activeTool === 'SAMRectPrediction') return;

        // Pop last set of operations from undo.
        const operations = undo.pop();

        // Undo operations.
        this.applyState(labelmap3D, operations, ReplaceMode.undo);

        // Push set of operations to redo.
        redo.push(operations);

        const segmentIndexList = Array.from(new Set(operations[0]?.diff.flatMap(value => value.slice(1, 3)))).filter(
            value => value > 0
        );

        triggerLabelmapModifiedEvent(this.activeElement, { imageIdIndex: operations[0]?.imageIdIndex, segmentIndexList });
        this.forceRender();
    }

    redoStateSegmentation() {
        const labelmap3D = this.getters.labelmap3D(this.activeElement);
        const { undo, redo } = labelmap3D;

        if (!redo.length || this.activeTool === 'SAMPointPrediction' || this.activeTool === 'SAMRectPrediction') return;

        // Pop last set of operations from undo.
        const operations = redo.pop();

        // Undo operations.
        this.applyState(labelmap3D, operations, ReplaceMode.redo);

        // Push set of operations to redo.
        undo.push(operations);

        const segmentIndexList = Array.from(new Set(operations[0]?.diff.flatMap(value => value.slice(1, 3)))).filter(
            value => value > 0
        );

        triggerLabelmapModifiedEvent(this.activeElement, { imageIdIndex: operations[0]?.imageIdIndex, segmentIndexList });
        this.forceRender();
    }

    applyState(labelmap3D: LabelMap3D, operations: Array<IHistoryOperation>, replaceIndex: ReplaceMode) {
        const { labelmaps2D } = labelmap3D;

        operations.forEach(({ diff, imageIdIndex }) => {
            const labelmap2D = labelmaps2D[imageIdIndex];

            for (const diffI of diff) {
                labelmap2D.pixelData[diffI[0]] = diffI[replaceIndex];
            }

            this.setters.updateSegmentsOnLabelmap2D(labelmap2D);
        });
    }

    changeSegmentColor(segmentName: string, color: Color, labelMapIndex?: number, viewportIndex = this.activeElementIndex) {
        const element = this.getViewportElement(viewportIndex);
        if (!this.isElementEnabled(element)) return;
        const { labelmaps3D, activeLabelmapIndex } = this.getters.labelmaps3D(element);

        const _labelMapIndex = labelMapIndex ?? activeLabelmapIndex;

        const labelmap3D = labelmaps3D[_labelMapIndex];
        const SegmentIndex = labelmap3D?.metadata
            .filter((i: any) => !isNullOrUndefined(i))
            ?.find(item => item.SegmentLabel === segmentName);

        if (!SegmentIndex) return;
        let changeIndex = SegmentIndex.SegmentNumber;
        const colorLut = this.segmentationState.colorLutTables[_labelMapIndex];
        colorLut[changeIndex][0] = color.r;
        colorLut[changeIndex][1] = color.g;
        colorLut[changeIndex][2] = color.b;

        this.forceRender();
    }

    debouncedChangeSegmentColor = _debounce(this.changeSegmentColor, 500);

    async createSegFile(): Promise<Blob> {
        const globalToolStateManager = cornerstoneTools.globalImageIdSpecificToolStateManager;
        globalToolStateManager.saveToolState();

        const stackToolState = cornerstoneTools.getToolState(this.activeElement, 'stack');
        const imageIds = stackToolState.data[0].imageIds;

        const { labelmaps3D } = this.getters.labelmaps3D(this.activeElement);

        if (!labelmaps3D) return;

        const imageList: Image[] = await Promise.all(imageIds.map((image: string) => cornerstone.loadImage(image)));

        const segBlob = dcmjs.adapters.Cornerstone.Segmentation.generateSegmentation(imageList, labelmaps3D);

        // const seriesId = this.getSeriesIdFromMetadata(imageList[0]);
        // downloadFileFromAxios({ data: segBlob } as AxiosResponse, (seriesId || 'segmentation') + '.dcm');

        return segBlob;
    }

    parseSegFile(arrayBuffer: ArrayBuffer) {
        const element = document.getElementsByClassName('viewport-element')[0];

        const stackToolState = cornerstoneTools.getToolState(element, 'stack');
        const imageIds = stackToolState.data[0].imageIds;

        const t0 = performance.now();

        const { labelmapBufferArray, segMetadata, segmentsOnFrame } = dcmjs.adapters.Cornerstone.Segmentation.generateToolState(
            imageIds,
            arrayBuffer,
            cornerstone.metaData
        );

        const t1 = performance.now();

        this.setters.labelmap3DByFirstImageId(
            imageIds[0],
            labelmapBufferArray[0],
            0,
            segMetadata,
            imageIds.length,
            segmentsOnFrame
        );

        console.log('Parsing Seg File took ' + (t1 - t0) + ' milliseconds.');
    }

    changeSegmentLabelByName(nextLabel: string, oldLabel: string) {
        const labelmaps3D = this.getLabelMap2D(this.activeElement);

        const SegmentIndex = labelmaps3D.labelmap3D.metadata.find(function (item: any) {
            return item.SegmentLabel === oldLabel;
        });

        if (!SegmentIndex) return;

        SegmentIndex.SegmentLabel = nextLabel;

        this.forceRender();
    }

    setActiveSegmentVisible(segmentOpacity: number) {
        const element = this.activeElement;
        const labelmap3D = this.getLabelMap2D(element)?.labelmap3D;
        const activeLabelmapIndex: number = this.getters.activeLabelmapIndex(element);
        const activeIndex: number = this.getters.activeSegmentIndex(element);
        const colorLut = this.segmentationState.colorLutTables[activeLabelmapIndex];

        const Segments = labelmap3D.metadata.map((item: any) => {
            return item.SegmentNumber;
        });

        Segments.forEach((index: any) => {
            if (index !== activeIndex) {
                // if(!this.configuration.renderOutline) colorLut[index][3] = 80;
                colorLut[index][3] = this.inActiveSegmentOpacity;
            } else {
                colorLut[activeIndex][3] = segmentOpacity;
            }
        });
    }

    addLabelmap2D(element: HTMLElement, labelmapIndex: number, imageIdIndex: number, length: number) {
        const elementOffset = length * imageIdIndex;

        const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
        if (!labelmaps3D?.[labelmapIndex]) return null;

        const pixelData = new Float32Array(
            labelmaps3D[labelmapIndex].buffer,
            elementOffset * 4, // 4 bytes/voxel
            length
        );

        labelmaps3D[labelmapIndex].labelmaps2D[imageIdIndex] = {
            pixelData,
            segmentsOnLabelmap: [],
        };

        return labelmaps3D[labelmapIndex].labelmaps2D[imageIdIndex];
    }

    resetBrushToolState() {
        _values(this.segmentationState.series).forEach(seriesState => {
            seriesState.labelmaps3D.forEach(labelmap3D => {
                labelmap3D.labelmaps2D.forEach(labelmap2D => {
                    if (!labelmap2D) return;
                    labelmap2D.pixelData = new Float32Array(labelmap2D.pixelData.length);
                    labelmap2D.segmentsOnLabelmap = [0];
                });
            });
        });
    }

    resetBrushToolStateByImageId(imageId: string) {
        const imageIndex = this.getImageIndexFromStack(imageId);

        _values(this.segmentationState.series).forEach(seriesState => {
            seriesState.labelmaps3D.forEach(labelmap3D => {
                const labelmap2D = labelmap3D.labelmaps2D[imageIndex];
                if (!labelmap2D) return;
                labelmap2D.pixelData = new Float32Array(labelmap2D.pixelData.length);
                labelmap2D.segmentsOnLabelmap = [0];
            });
        });
    }

    zoomToLabel(labelList: { segmentLabel: string; labelMapIndex?: number; imageIndex?: number }[], viewportIndex?: number): any {
        if (_isEmpty(viewportIndex)) return this.elementMap.forEach((_, key) => this.zoomToLabel(labelList, key));
        const element = this.getViewportElement(viewportIndex);

        if (!this.isElementEnabled(element)) return;
        cornerstone.fitToWindow(element);

        const { labelmaps3D, activeLabelmapIndex }: LabelMaps3DReturnType = this.getters.labelmaps3D(element);
        const segmentIndexList = labelList
            ?.map(({ labelMapIndex, segmentLabel, imageIndex }) => {
                const labelMap3D = labelmaps3D[labelMapIndex ?? activeLabelmapIndex];
                const index = imageIndex ?? this.getCurrentImageIdIndex(element);

                return {
                    pixelData: labelMap3D?.labelmaps2D[index]?.pixelData,
                    segmentIndex: labelMap3D?.metadata.find(meta => meta?.SegmentLabel === segmentLabel)?.SegmentNumber,
                };
            })
            ?.filter(({ segmentIndex }) => segmentIndex);

        if (_isEmpty(segmentIndexList)) return;

        const { image } = this.getEnabledElement(element);
        const { rows, columns } = image;

        let { top, bottom, left, right } = { top: rows, bottom: 0, left: columns, right: 0 };

        segmentIndexList.forEach(({ pixelData, segmentIndex }) => {
            const {
                top: _top,
                bottom: _bottom,
                left: _left,
                right: _right,
            } = this.getBoundingBoxOfSegment(segmentIndex, pixelData, rows, columns);
            top = Math.min(top, _top);
            bottom = Math.max(bottom, _bottom);
            left = Math.min(left, _left);
            right = Math.max(right, _right);
        });

        this.zoomToBoundingBox(element, { top, bottom, left, right });
    }

    zoomToBoundingBox(element: HTMLElement, boundingBox: { top: number; bottom: number; left: number; right: number }) {
        // Retrieve the current viewport for the element
        const viewport = cornerstone.getViewport(element);
        const { image, canvas } = cornerstone.getEnabledElement(element);

        if (
            boundingBox.top === 0 &&
            boundingBox.bottom === image.height &&
            boundingBox.left === 0 &&
            boundingBox.right === image.width
        )
            return;

        const rowPixelSpacing = image.rowPixelSpacing || 1;
        const columnPixelSpacing = image.columnPixelSpacing || 1;
        let verticalRatio = 1;
        let horizontalRatio = 1;

        if (rowPixelSpacing < columnPixelSpacing) {
            horizontalRatio = columnPixelSpacing / rowPixelSpacing;
        } else {
            // even if they are equal we want to calculate this ratio (the ration might be 0.5)
            verticalRatio = rowPixelSpacing / columnPixelSpacing;
        }

        // Get viewport dimensions
        const viewportWidth = canvas.width;
        const viewportHeight = canvas.height;

        const margin = 50;

        // Calculate bounding box dimensions
        const boxWidth = boundingBox.right - boundingBox.left + margin;
        const boxHeight = boundingBox.bottom - boundingBox.top + margin;

        // Calculate the scale needed to fit the bounding box within the viewport
        const scaleX = viewportWidth / boxWidth / horizontalRatio;
        const scaleY = viewportHeight / boxHeight / verticalRatio;
        const calculatedScale = Math.min(scaleX, scaleY); // Choose the smaller scale to fit the entire box

        // Apply the calculated scale within allowable limits
        viewport.scale = calculatedScale;

        // Calculate the bounding box center
        const boxCenterX = (boundingBox.left + boundingBox.right) / 2;
        const boxCenterY = (boundingBox.top + boundingBox.bottom) / 2;

        // Calculate the translation needed to center the bounding box
        const desiredTranslation = {
            x: image.width / 2 - boxCenterX,
            y: image.height / 2 - boxCenterY,
        };

        // Apply translation to center the bounding box
        viewport.translation.x = desiredTranslation.x;
        viewport.translation.y = desiredTranslation.y;

        // Update the viewport with the calculated scale and translation
        cornerstone.setViewport(element, viewport);
    }

    focusToLabel(segmentLabel: string[], viewportIndex?: number): any {
        if (_isEmpty(viewportIndex)) return this.elementMap.forEach((_, key) => this.focusToLabel(segmentLabel, key));
        const element = this.getViewportElement(viewportIndex);

        if (!this.isElementEnabled(element)) return;
        cornerstone.fitToWindow(element);

        const { labelmap2D } = this.getLabelMap2D(element);
        const segmentIndexList = _compact(segmentLabel.map(label => this.getSegmentLabelMap(element).get(label)?.segmentIndex));

        if (_isEmpty(segmentIndexList)) return;

        const pixelData = labelmap2D.pixelData;

        const { image } = this.getEnabledElement(element);
        const { rows, columns } = image;

        let { top, bottom, left, right } = { top: rows, bottom: 0, left: columns, right: 0 };

        segmentIndexList.forEach(segmentIndex => {
            const {
                top: _top,
                bottom: _bottom,
                left: _left,
                right: _right,
            } = this.getBoundingBoxOfSegment(segmentIndex, pixelData, rows, columns);
            top = Math.min(top, _top);
            bottom = Math.max(bottom, _bottom);
            left = Math.min(left, _left);
            right = Math.max(right, _right);
        });

        const viewport = cornerstone.getViewport(element) as any;
        const margin = 10;

        viewport.displayedArea = {
            // Top Left Hand Corner
            tlhc: {
                x: left - margin,
                y: top - margin,
            },
            // Bottom Right Hand Corner
            brhc: {
                x: right + margin,
                y: bottom + margin,
            },
            rowPixelSpacing: 1,
            columnPixelSpacing: 1,
            // presentationSizeMode: 'SCALE TO FIT',
            presentationSizeMode: 'MAGNIFY',
        };

        cornerstone.setViewport(element, viewport);
    }

    calculateDiceSimilarity(compare1: DiceComparisonArgs, compare2: DiceComparisonArgs) {
        if (!compare1 || !compare2 || !this.isElementEnabled(this.activeElement)) return 0;
        let intersection = 0;
        let sum1 = 0;
        let sum2 = 0;

        const { labelmaps3D }: LabelMaps3DReturnType = this.getters.labelmaps3D(this.activeElement);
        if (!labelmaps3D) return 0;

        const labelmap3_1 = labelmaps3D[compare1.labelmapIndex];
        const labelmap3_2 = labelmaps3D[compare2.labelmapIndex];

        if (!labelmap3_1 || !labelmap3_2) return 0;

        const labelmap2D_1 = labelmap3_1.labelmaps2D[compare1.imageIdIndex];
        const labelmap2D_2 = labelmap3_2.labelmaps2D[compare2.imageIdIndex];

        if (!labelmap2D_1 || !labelmap2D_2) return 0;

        const segmentIndex1 = labelmap3_1.metadata.find(item => item?.SegmentLabel === compare1.segmentLabel)?.SegmentNumber;
        const segmentIndex2 = labelmap3_2.metadata.find(item => item?.SegmentLabel === compare2.segmentLabel)?.SegmentNumber;

        if (
            !labelmap2D_1?.segmentsOnLabelmap?.includes?.(segmentIndex1) ||
            !labelmap2D_2?.segmentsOnLabelmap?.includes?.(segmentIndex2)
        )
            return 0;

        const pixelData1 = labelmap2D_1.pixelData;
        const pixelData2 = labelmap2D_2.pixelData;

        for (let i = 0; i < pixelData1.length; i++) {
            if (pixelData1[i] === segmentIndex1 && pixelData2[i] === segmentIndex2) intersection++;
            if (pixelData1[i] === segmentIndex1) sum1++;
            if (pixelData2[i] === segmentIndex2) sum2++;
        }

        return (2 * intersection) / (sum1 + sum2);
    }

    resetFocus(viewportIndex?: number): any {
        if (_isEmpty(viewportIndex)) return this.elementMap.forEach((_, key) => this.resetFocus(key));
        const element = this.getViewportElement(viewportIndex);

        if (!this.isElementEnabled(element)) return;

        const viewport = cornerstone.getViewport(element) as any;

        viewport.displayedArea = undefined;
        cornerstone.setViewport(element, viewport);
    }

    getBoundingBoxOfSegment(segmentIndex: number, pixelData: Float32Array, rows: number, columns: number) {
        const boundingBox = {
            top: rows,
            left: columns,
            bottom: 0,
            right: 0,
        };

        for (let i = 0; i < pixelData.length; i++) {
            if (pixelData[i] === segmentIndex) {
                const x = i % columns;
                const y = Math.floor(i / columns);

                boundingBox.left = Math.min(boundingBox.left, x);
                boundingBox.right = Math.max(boundingBox.right, x);
                boundingBox.top = Math.min(boundingBox.top, y);
                boundingBox.bottom = Math.max(boundingBox.bottom, y);
            }
        }

        return boundingBox;
    }

    getSeriesModule(image: cornerstone.Image): Dictionary {
        return cornerstone.metaData?.get('generalSeriesModule', image?.imageId);
    }

    getImageModule(image: cornerstone.Image): Dictionary {
        return cornerstone.metaData?.get('generalImageModule', image?.imageId);
    }

    getSeriesIdFromMetadata(image: cornerstone.Image): string {
        return this.getSeriesModule(image)?.seriesInstanceUID;
    }

    getInstanceIdFromMetadata(image: cornerstone.Image): string {
        return this.getImageModule(image)?.sopInstanceUid;
    }

    get segmentationModule(): SegmentationModule {
        return cornerstoneTools?.getModule('segmentation');
    }

    get getters() {
        return this.segmentationModule?.getters;
    }

    get setters() {
        return this.segmentationModule?.setters;
    }

    get segmentationState() {
        return this.segmentationModule?.state;
    }

    get configuration() {
        return this.segmentationModule?.configuration;
    }

    get brushState(): Array<LabelMap3D> {
        return _values(this.segmentationState?.series).reduce((acc, curr: LabelMaps3DReturnType) => {
            curr.labelmaps3D?.forEach((labelmap, i) => {
                if (!labelmap) return;
                acc[i] = labelmap;
            });
            return acc;
        }, []);
    }

    getLabelMaps3DList() {
        return Promise.all(
            _entries(this.segmentationState?.series).map(async ([key, value]) => {
                const image = await cornerstone.loadImage(key);

                const seriesId = this.getSeriesIdFromMetadata(image);
            })
        );
    }

    getLabelMap2D(element: HTMLElement): LabelMap2DReturnType {
        return this.getters.labelmap2D(element);
    }
}
