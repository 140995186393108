import { PayloadAction } from "@reduxjs/toolkit";
import { setUploadLoading } from "pages/Datasets/store";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { ISubmitDatasetFormArgs } from "../../entities/ISubmitDatasetFormArgs";
import * as actions from "../actions";
import { WriteDatasetToDb } from "./WriteDatasetToDB";

export default function* rootSaga() {
    yield all([
        takeEvery(actions.SUBMIT_DATASET_FORM_TRANSACTION, submitDatasetForm),
    ]);
}
export function* submitDatasetForm(sagaAction: PayloadAction<ISubmitDatasetFormArgs>): any {
    try {
        yield put(setUploadLoading(true));
        yield call(WriteDatasetToDb, sagaAction);
    } catch (error) {
        console.error(error);
    } finally {
        yield put(setUploadLoading(false));
    }
}
