export interface IDatasetSelection {
    datasetId: string,
    count: number,
    selectedCount: number,
    imageFilterSelection: boolean,
}

export function IDatasetSelectionFactory(data?: Partial<IDatasetSelection>): IDatasetSelection {
    return {
        datasetId: data?.datasetId ?? "",
        count: data?.count ?? null,
        selectedCount: data?.selectedCount ?? null,
        imageFilterSelection: data?.imageFilterSelection ?? false,
    };
}