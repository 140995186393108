import Styles from './Styles.module.scss';
import cx from 'classnames';

export default function StatsTags({ statsList, className, layout }: IProps) {
    return (
        <div className={cx(Styles.StatsListContainer, className, Styles[layout])}>
            {statsList?.map(({ icon, name, value }) => (
                <div
                    className={Styles.StatsItem}
                    key={name}
                >
                    <div className={Styles.icon}>{icon}</div>
                    <div className="pBody-medium text-nowrap">{name}:</div>
                    <div
                        className="pBody-medium white text-nowrap"
                        style={{ marginLeft: '5px' }}
                    >
                        {value || '-'}
                    </div>
                </div>
            ))}
        </div>
    );
}

interface IProps {
    statsList?: Array<IStats>;
    layout?: 'horizontal' | 'vertical';
    className?: string;
}

export interface IStats {
    name: string;
    value: string | number;
    icon: React.ReactNode;
}
