import { Radio, RadioProps } from 'antd';
import cx from 'classnames';
import { Dictionary } from 'common';
import React from 'react';
import Styles from './Styles.module.scss';

export function GRadioGroup({ optionClassName, menuItemList, className, value, onChange }: IRadioGroupProps) {
    function getOptions() {
        return menuItemList?.map(({ disabled, value, title }) => (
            <GRadio
                disabled={disabled}
                value={value}
                className={optionClassName}
                key={value}
            >
                {title}
            </GRadio>
        ));
    }

    return (
        <>
            <Radio.Group
                className={cx(Styles.RadioGroupContainer, className)}
                onChange={e => onChange?.(e.target.value)}
                value={value}
            >
                {getOptions()}
            </Radio.Group>
        </>
    );
}

export function GRadio({ className, ...props }: IRadioProps) {
    return (
        <Radio
            className={cx(Styles.RadioItem, className)}
            {...props}
        />
    );
}

interface IRadioGroupProps {
    className?: string;
    menuItemList?: IRadioItem[];
    onChange?: (value: any) => void;
    value?: string;
    optionClassName?: string;
    id?: string;
}

interface IRadioProps extends RadioProps {}

export interface IRadioItem extends Dictionary<any> {
    value: string;
    title: React.ReactNode;
    disabled?: boolean;
}
