import { setMetricsDataToInitial, updateMetricsCommonState } from "pages/Validation/store";
import { all, call, put, takeEvery, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import { changePreviewImageSaga } from "./changePreviewImageSaga";
import { setStaticPayloadSaga } from "./getStaticPayloadSaga";
import { getValidationMetricsViewSaga } from "./getValidationMetricsViewSaga";
import { updateValidationMetricsViewSaga } from "./updateValidationMetricsViewSaga";
import { getValidationMetricsDataSaga } from "./metricsChartSaga";

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_VALIDATION_METRICS_PAGE_DATA_TRANSACTION, getValidationMetricsDataSaga),
        takeEvery(actions.UPDATE_VALIDATION_METRICS_VIEW_TRANSACTION, updateValidationMetricsViewSaga),
        takeEvery(actions.GET_VALIDATION_METRICS_VIEW_TRANSACTION, getValidationMetricsViewSaga),
        takeLatest(actions.SET_SELECTED_VALIDATION_TRANSACTION, setSelectedValidationSaga),
        takeEvery(actions.CHANGE_PREVIEW_IMAGE_TRANSACTION, changePreviewImageSaga),
    ]);
}

function* setSelectedValidationSaga(sagaAction: any): any {
    try {
        const validationId = sagaAction.payload;

        yield put(updateMetricsCommonState({ pageLoading: true }));

        yield put(setMetricsDataToInitial());

        yield call(setStaticPayloadSaga, validationId);

    } catch (error) {
        console.log(error);
    }

}
