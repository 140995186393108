import { all, takeEvery } from 'redux-saga/effects';
import * as actions from './../actions';
import { ActivationTokenSaga } from './ActivationTokenSaga';
import { appStartTransactionSaga } from './AppStartSaga';
import { ForgotPasswordSaga } from './ForgotPasswordSaga';
import { userLoginTransactionSaga } from './LoginActionSaga';
import { userNewPasswordTransactionSaga } from './NewPassword';
import { setupConfigurationSaga } from './SetupConfigurationSaga';
import { updateSessionSaga } from './UpdateSession';
import { registerNewUserTransactionSaga } from './UserRegisterSaga';
import { updateUserAccountInformationSaga } from './updateUserAccountInformationSaga';

export default function* rootSaga() {
    yield all([
        takeEvery(actions.USER_LOGIN_TRANSACTION, userLoginTransactionSaga),
        takeEvery(actions.APP_START_TRANSACTION, appStartTransactionSaga),
        takeEvery(actions.USER_NEW_PASSWORD_TRANSACTION, userNewPasswordTransactionSaga),
        takeEvery(actions.REGISTER_NEW_USER_TRANSACTION, registerNewUserTransactionSaga),
        takeEvery(actions.FORGOT_PASSWORD_TRANSACTION, ForgotPasswordSaga),
        takeEvery(actions.ACTIVATION_TOKEN_TRANSACTION, ActivationTokenSaga),
        takeEvery(actions.UPDATE_SESSION, updateSessionSaga),
        takeEvery(actions.UPDATE_USER_ACCOUNT_INFO, updateUserAccountInformationSaga),
        takeEvery(actions.SETUP_CONFIGURATION, setupConfigurationSaga),
    ]);
}
