export const enMessages: Record<string, string> = {
    $00001: 'Not all fields have been entered.',
    $00002: 'An account with this email already exists.',
    $00003: 'You are not authorized for registration.',
    $00004: 'Verification failed, authorization denied',
    $00005: 'User Not Found',
    $00006: 'You already set your password',
    $00007: 'Invalid credentials.',
    $00008: 'Enter the same password twice for verification.',
    $00009: 'The password needs to be at least 13 characters long.',
    $00010: 'No account with this email has been registered.',
    $00011: 'Your session is expired. Please sign in.',
    $00012: 'A credentials with this name already exists.',
    $00013: 'Access denied. Check your activation token.',
    $00014: 'Activation expired.',
    $00015: 'Invalid activation token.',
    $00016: 'You do not have access to this dataset.',
    $00017: 'Invalid token. Please try again.',
    $00018: 'You have exceeded the maximum number of login attempts. Please try again later.',
    $00019: 'Maximum user limit reached. Please contact your administrator.',
    $00020: 'You have already logged in from another device. Please log out from that device and try again.',
    $00021: 'You do not have access to this model.',
    $00022: 'You do not have access to this validation.',

    // Platform Messages
    $01001: 'Could not upload module due to listed reason: Model file is missing, allowed extensions are pth/h5/pickle/bin/hdf5/pt/onnx',
    $01002: 'Could not upload module due to listed reason: Requirements file is missing.',
    $01003: 'Incompatible image or model pair! Please try again with another image or model.',
};
