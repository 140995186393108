import { Dropdown } from 'antd';
import { selectUnreadNotificationCount } from 'app/store/selectors';
import { getMessages } from 'common';
import { GTooltip } from 'components';
import GBadge from 'components/GBadge';
import { Notification } from 'components/assets/icons';
import { setDropdownOpen } from 'pages/User/store';
import { selectIsNotificationDropdownOpen } from 'pages/User/store/selectors';
import { useDispatch, useSelector } from 'react-redux';
import IconBackground from '../IconBackground';
import Styles from './Styles.module.scss';
import NotificationMenuOverlay from './components/NotificationMenuOverlay';

export default function NotificationPanel() {
    const unreadCount = useSelector(selectUnreadNotificationCount);
    const open = useSelector(selectIsNotificationDropdownOpen);

    const dispatch = useDispatch();

    function handleOpenDropdown() {
        dispatch(setDropdownOpen(!open));
    }

    return (
        <Dropdown
            trigger={['click']}
            open={open}
            onOpenChange={handleOpenDropdown}
            overlay={<NotificationMenuOverlay />}
            className={Styles.NotificationDropDown}
            overlayClassName={Styles.DropdownMenu}
            placement="bottomLeft"
        >
            <GTooltip title={getMessages('000214')}>
                <div>
                    <IconBackground
                        icon={
                            <GBadge count={unreadCount}>
                                <Notification />
                            </GBadge>
                        }
                    />
                </div>
            </GTooltip>
        </Dropdown>
    );
}
