import { APISlice } from 'app/store/APISlice';
import { blobToBase64 } from 'common/Utils';
import { IDataset, IDatasetResponsePayload, IImageResponse, TransformDataset } from 'common/entities';
import { IImage } from 'common/entities/Dataset';
import { ISeries, IStudy } from 'common/entities/Dataset/Study';
import { ServiceEnum } from 'common/enum';
import { transformImages } from 'common/services/Tools/transformImages';
import { IAnnotationEl, IAnnotationTrackingResponse } from 'pages/AnnotationCS/entities';
import { IFile, IFileStreamQueryParams } from '../entities/IFileStream';
import { IMetadataEl, IMetadataField } from '../entities/IMetadata';

export const DatasetService = APISlice.injectEndpoints({
    endpoints: builder => ({
        getDatasetList: builder.query<Array<IDataset>, string | undefined>({
            query: projectId => ({
                url: `${ServiceEnum.Api}/Datasets/GetDatasetList`,
                params: { projectId },
            }),
            transformResponse(baseQueryReturnValue: Array<IDatasetResponsePayload>, meta, arg) {
                return baseQueryReturnValue?.map(TransformDataset) || [];
            },
            providesTags: (result, error, projectId) =>
                projectId ? [{ type: 'DatasetList', id: projectId }, 'DatasetList'] : ['DatasetList'],
        }),
        getDataset: builder.query<IDataset, string | undefined>({
            query: dataset_id => ({
                url: `${ServiceEnum.Api}/Datasets/GetDataset`,
                params: { dataset_id },
            }),
            transformResponse(baseQueryReturnValue: IDatasetResponsePayload, meta, dataset_id) {
                return TransformDataset(baseQueryReturnValue);
            },
            providesTags: (result, error, dataset_id) =>
                dataset_id ? [{ type: 'Dataset', id: dataset_id }, 'Dataset'] : ['Dataset'],
        }),

        getDatasetFiles: builder.query<Array<IFile>, string>({
            query: dataset_id => ({
                url: `${ServiceEnum.Api}/Datasets/GetDatasetFiles`,
                params: { dataset_id },
            }),
        }),

        getFileStream: builder.query<Blob | ArrayBuffer, IFileStreamQueryParams>({
            query: ({ file_name, file_type, file_url, response_type }) => ({
                url: `${ServiceEnum.Management}/dataset/get_file_stream`,
                params: { file_name, file_type, file_url },
                responseHandler: response => (response_type === 'arraybuffer' ? response?.arrayBuffer() : response.blob()),
            }),
        }),

        removeAnnotation: builder.mutation<void, { dataset_id: string; annotation_id: string }>({
            query: body => ({
                url: `${ServiceEnum.Api}/Annotation/RemoveAnnotation`,
                body,
                method: 'POST',
                responseHandler: response => response.text(),
            }),
            invalidatesTags: (result, error, args) => [
                'AnnotationList',
                'AnnotationProjectList',
                'DatasetList',
                { type: 'Dataset', id: args?.dataset_id },
            ],
        }),

        createAnnotation: builder.mutation<IAnnotationTrackingResponse, ICreateAnnotationProjectArgs>({
            query: args => ({
                url: `${ServiceEnum.Api}/Annotation/CreateAnnotation`,
                method: 'POST',
                body: args,
            }),
            invalidatesTags: response => ['AnnotationList', { type: 'Dataset', id: response?.dataset_id }],
        }),
        setDefaultAnnotation: builder.mutation<void, { dataset_id: string; annotation_id: string }>({
            query: params => ({
                url: `${ServiceEnum.Api}/Annotation/SetDefaultAnnotation`,
                body: params,
                method: 'POST',
            }),
            invalidatesTags: (result, error, args) => [
                'AnnotationList',
                'Dataset',
                { type: 'Dataset', id: args.dataset_id },
                'DatasetList',
            ],
        }),

        getStudyList: builder.query<{ data: Array<IStudy>; total: number }, GetStudyListArgs>({
            query: params => ({
                url: `${ServiceEnum.Api}/Datasets/GetStudyList`,
                params,
            }),
        }),

        getStudySeries: builder.query<Array<ISeries>, string>({
            query: params => ({
                url: `${ServiceEnum.Api}/Datasets/GetStudySeries?` + params,
            }),
        }),
        deleteCuratedDataset: builder.mutation<void, { dataset_id: string }>({
            query: body => ({
                url: `${ServiceEnum.Api}/Datasets/DeleteCuratedDataset`,
                body,
                method: 'POST',
            }),
            transformResponse: (response: any) => response.text(),
        }),
        deleteDataset: builder.mutation<void, FormData>({
            query: formData => ({
                url: `${ServiceEnum.Management}/dataset/delete`,
                method: 'POST',
                body: formData,
            }),
        }),
        getImageList: builder.query<{ data: Array<IImage>; total: number }, IGetImageListArgs>({
            query: params => ({
                url: `${ServiceEnum.Api}/Datasets/GetDatasetImages`,
                method: 'POST',
                params,
            }),
            transformResponse(baseQueryReturnValue: { data: Array<IImageResponse>; total: number }, meta, { dataset_id }) {
                return { data: transformImages(baseQueryReturnValue?.data, dataset_id), total: baseQueryReturnValue?.total };
            },
            keepUnusedDataFor: 60 * 60,
        }),
        generateSequence: builder.query<any, any>({
            query: params => ({
                url: `${ServiceEnum.Genomics}/sequencing/generate`,
                method: 'POST',
                params,
            }),
        }),
        getPlatformRawImage: builder.query<string, IUsePlatformThumbnailArgs>({
            query: params => ({
                url: `${ServiceEnum.Management}/image/get_image_stream`,
                params,
                responseHandler: response => response.blob().then(blob => blobToBase64(blob)),
            }),
        }),
        updateDataset: builder.mutation<void, IUpdateDatasetArgs>({
            query: body => ({
                url: `${ServiceEnum.Api}/Datasets/UpdateDataset`,
                method: 'POST',
                body,
            }),
        }),
        getTabularData: builder.query<any, string>({
            query: dataset_id => ({
                url: `${ServiceEnum.Api}/Datasets/GetTabularData`,
                params: { dataset_id },
            }),
        }),
        getTabularMetadata: builder.query<any, string>({
            query: metadata_id => ({
                url: `${ServiceEnum.Api}/Metadata/GetTabularMetaData`,
                params: { metadata_id },
            }),
        }),
    }),
});

export const {
    useGetDatasetListQuery,
    useGetDatasetQuery,
    useGetDatasetFilesQuery,
    useGetFileStreamQuery,
    useSetDefaultAnnotationMutation,
    useCreateAnnotationMutation,
    useRemoveAnnotationMutation,
    useGetStudyListQuery,
    useGetStudySeriesQuery,
    useDeleteCuratedDatasetMutation,
    useDeleteDatasetMutation,
    useGetImageListQuery,
    useGetPlatformRawImageQuery,
    useUpdateDatasetMutation,
    useGetTabularDataQuery,
    useGenerateSequenceQuery,
    useGetTabularMetadataQuery,
} = DatasetService;

export * from './MetadataService';

export interface IGetImageListArgs {
    dataset_id: string;
    skip?: number;
    limit?: number;
    search?: string;
    seriesId?: string;
    studyId?: string;
    excludeImages?: string[];
}

export interface ICreateMetadataArgs {
    dataset_id: string;
    metadata_name: string;
    metadata_data: Array<IMetadataEl>;
    metadata_fields: Array<IMetadataField>;
}

export interface ICreateAnnotationProjectArgs extends Partial<IAnnotationTrackingResponse> {
    annotation_data?: Array<IAnnotationEl>;
    isProject?: boolean;
}

export interface IGetImageAnnotationArgs {
    annotation_id: string;
    image_list?: string[];
}

export interface GetStudyListArgs {
    dataset_id: string;
    skip?: number;
    limit?: number;
    slim?: boolean;
    search?: string | number;
    include_list?: string[];
    qc?: boolean;
    annotation_id?: string;
}

export interface GetStudySeriesArgs {
    dataset_id: string;
    study_id: string;
}

export function getStudySeriesParams(dataset_id: string, study_id: string) {
    return new URLSearchParams({ dataset_id, study_id }).toString();
}

export interface IUsePlatformThumbnailArgs {
    image_id: string;
    dataset_id: string;
    width?: number;
    height?: number;
    image_slice?: number;
    ww?: number;
    wc?: number;
    raw_image?: boolean;
}

export interface IUpdateDatasetArgs {
    datasetId: string;
    selectedRemoveImages: Array<string>;
    addedImagesMap: Dictionary<Array<string>>;
}
