export function s3UrlParser(url?: string): S3Properties {
    if (!url) return null;

    const _decodedUrl = decodeURIComponent(url);

    let _result = null;

    // http://s3.amazonaws.com/bucket/key1/key2
    var _match = _decodedUrl.match(/^https?:\/\/s3.amazonaws.com\/([^/]+)\/?(.*?)$/);
    if (_match) {
        _result = {
            bucket: _match[1],
            path: _match[2] || '/',
            region: '',
        };
    }

    // http://s3-aws-region.amazonaws.com/bucket/key1/key2
    _match = _decodedUrl.match(/^https?:\/\/s3-([^.]+).amazonaws.com\/([^/]+)\/?(.*?)$/);
    if (_match) {
        _result = {
            bucket: _match[2],
            path: _match[3] || '/',
            region: _match[1],
        };
    }

    // http://bucket.s3.amazonaws.com/key1/key2
    _match = _decodedUrl.match(/^https?:\/\/([^.]+).s3.amazonaws.com\/?(.*?)$/);
    if (_match) {
        _result = {
            bucket: _match[1],
            path: _match[2] || '/',
            region: '',
        };
    }

    // http://bucket.s3-aws-region.amazonaws.com/key1/key2 or,
    // http://bucket.s3.aws-region.amazonaws.com/key1/key2
    _match = _decodedUrl.match(/^https?:\/\/([^.]+).(?:s3-|s3\.)([^.]+).amazonaws.com\/?(.*?)$/);
    if (_match) {
        _result = {
            bucket: _match[1],
            path: _match[3] || '/',
            region: _match[2],
        };
    }

    return _result;
}

export interface S3Properties {
    bucket: string;
    path: string;
    region: string;
}
