import cx from "classnames";
import GSwitch from "components/GSwitch";
import { IBooleanFilterItem } from '../../entities';
import Styles from './Styles.module.scss';

export default function BooleanFilterItem({ name, onChangeFilter, title, value, layout = "horizontal", className }: IProps) {
    return (
        <label htmlFor={`dataset-filter-${name}-switch`} className={cx(Styles.CheckBoxItem, className)}>
            <GSwitch
                checked={value}
                onChange={checked => onChangeFilter(name, checked)}
                id={`dataset-filter-${name}-switch`}
            />
            <div className="pBody-medium white">{title}</div>
        </label>
    );
}

interface IProps extends IBooleanFilterItem {
    onChangeFilter: (key: string, value: any) => void;
    layout?: "horizontal" | "vertical";
    className?: string;
}