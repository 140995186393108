import { PayloadAction } from '@reduxjs/toolkit';
import { FormInstance } from 'antd';
import { APIService, getMessages, getUnixTime, IDatasetResponsePayload, RoutingHelper } from 'common';
import { Notification } from 'components';
import { selectSelectedDatasets, selectSelectedFilters } from 'pages/Datasets/views/DatasetCuration/store/selectors';
import { call, put, select } from 'redux-saga/effects';
import { RootState } from 'redux/store';
import { updateDatasetCreationState } from '..';
import { getDatasetListTransactionSaga } from './datasetListSaga';

export function* CreateCuratedDatasetSaga(
    sagaAction: PayloadAction<{ payload: IDatasetResponsePayload; form: FormInstance }>
): any {
    try {
        let { payload, form } = sagaAction.payload;
        yield put(updateDatasetCreationState({ curateDatasetLoading: true }));
        const state: RootState = yield select();

        const selectedDatasets = selectSelectedDatasets(state);
        const filterList = selectSelectedFilters(state);

        const response = yield APIService.Datasets.CreateCuratedDataset.call({
            filterList,
            datasetForm: getFormPayload(payload),
            limitMap: selectedDatasets
                ?.filter(d => d.selectedCount && d.imageFilterSelection)
                .map(d => ({ datasetId: d.datasetId, count: d.selectedCount })),
        });
        if (!response.data) return;

        form?.resetFields?.();
        Notification.success({ content: getMessages('000927') });
        RoutingHelper.Dataset({ activeView: 'DatasetDashboard' }).navigate();
        yield call(getDatasetListTransactionSaga);
    } catch (error) {
        console.log(error);
    } finally {
        yield put(updateDatasetCreationState({ curateDatasetLoading: false }));
    }
}

function getFormPayload(data: IDatasetResponsePayload): IDatasetResponsePayload {
    let currentUser: any = sessionStorage.getItem('user');

    currentUser = currentUser ? JSON.parse(currentUser) : {};

    let permission_list = (data as any)?.dataset_access_options?.permission_list || [];

    permission_list.push(currentUser.email);

    permission_list = permission_list.map((user: string) => ({ user, timestamp: getUnixTime(), type: 'user' }));

    return {
        ...data,
        dataset_access_options: {
            access: data?.dataset_access_options?.access || 'public',
            owner_email: currentUser.email,
            permission_list,
        },
    };
}
