import { DeploymentStatus } from 'common/enum';
import { AnnotationShapeType } from './annotation.interface';

export const aiAssistanceToolList: Array<AiAssistanceTool> = [
    {
        label: 'Background Removal',
        name: 'background_removal',
        inputType: 'polygon',
        returnType: 'polygon',
        responseType: 'instant',
    },
    // {
    //     label: 'Nu Click',
    //     name: 'nu_click',
    //     inputType: 'point',
    //     returnType: 'polygon',
    //     responseType: 'instant',
    // },
];

export const aiAssistanceMenuItemList = aiAssistanceToolList.map(tool => ({
    ...tool,
    title: tool.label,
    value: tool.name,
    deploymentStatus: DeploymentStatus.UP,
}));

export interface AiAssistanceTool {
    name: AiAssistanceToolName;
    label: string;
    inputType: AnnotationShapeType;
    returnType: AnnotationShapeType;
    responseType: ResponseType;
}

export type AiAssistanceToolName = 'background_removal' | 'nu_click';
export type InputType = 'point' | 'polygon' | 'rectangle';
export type ResponseType = 'instant' | 'delayed';
