import { IsIncludeOrEqual, _entries, _isEmpty, _uniqBy, isNullOrUndefined } from 'common/Utils';
import {
    getSearchOptionsWithHighlight,
    useHandleSearch,
} from 'common/components/DashboardView/components/FilterPanel/useHandleSearch';
import { Dictionary, FilterInput, IDataset, SortingKey, isDicom } from 'common/entities';
import dayjs from 'dayjs';
import Fuse from 'fuse.js';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { selectArchivedDatasetList, selectDatasetList } from 'redux/selectors';

let fuseResult: Fuse.FuseResult<any>[];
let fuseResultIds: Array<string>;

const options = {
    includeScore: true,
    minMatchCharLength: 3,
    includeMatches: true,
    keys: ['title', 'description', 'tags', 'modality'],
};

export function useFilteredDatasetList<T extends IDatasetFilters>({
    filters,
    archived,
    sortBy,
    additionalFiltering,
    ...args
}: IUseFilteredDatasetListArgs<T>) {
    const datasetList = useSelector(selectDatasetList);
    const archivedDatasetList = useSelector(selectArchivedDatasetList);
    const { activeView } = useParams();

    const searchOptions = (activeView === 'ArchivedDatasets' ? archivedDatasetList : datasetList)?.map(item => ({
        value: item.datasetId,
        title: item.datasetName,
        label: <div key={item.datasetId}>{item.datasetName}</div>,
        description: item.datasetSummary,
        tags: item.tags.join(', ') || '',
        modality: item?.modality?.join?.(', ') || '',
    }));
    const { handleSearch } = useHandleSearch(searchOptions, options);

    let result: Array<IDataset> =
        args?.datasetList || (archived ? structuredClone(archivedDatasetList) : structuredClone(datasetList));
    switch (sortBy) {
        case 'lastActivity':
            result.sort((a, b) => {
                const aLastActivity = a.lastActivity || 0;
                const bLastActivity = b.lastActivity || 0;
                return bLastActivity - aLastActivity;
            });
            break;
        case 'popularityDesc':
            result.sort((a, b) => b.viewCount - a.viewCount);
            break;

        case 'popularityAsc':
            result.sort((a, b) => a.viewCount - b.viewCount);
            break;

        case 'dateLatest':
            result = sortingFunction(result, -1);
            break;

        case 'dateOldest':
        default:
            result = sortingFunction(result, 1);
            break;
    }

    _entries(filters).forEach(([key, value]) => {
        if (_isEmpty(value)) return;
        switch (key) {
            case 'search':
                fuseResult = handleSearch(value);
                fuseResultIds = fuseResult?.map(i => {
                    return i.item?.value;
                });

                if (value !== '')
                    result = result
                        ?.filter(d => fuseResultIds?.includes(d.datasetId))
                        .sort(function (a: IDataset, b: IDataset) {
                            return fuseResultIds.indexOf(a.datasetId) - fuseResultIds.indexOf(b.datasetId);
                        });
                else return result;
                break;

            case 'hidePreviousVersions':
                if (!value) return;

                result = _uniqBy(result, 'datasetName')
                    .map(item => item.datasetName)
                    .map(
                        item =>
                            result
                                .filter(d => d.datasetName === item)
                                .sort((a, b) => dayjs(b.createdDate).unix() - dayjs(a.createdDate).unix())[0]
                    );
                break;

            case 'hidePublicDatasets':
                if (!value) return;

                result = result.filter(d => d.datasetAccessOptions.access === 'private');
                break;

            case 'showDatasetWithAnnotations':
                if (!value) return;

                result = result.filter(d => d.defaultAnnotationId);
                break;

            case 'showDicoms':
                if (!value) return;
                result = result.filter(d => IsIncludeOrEqual(d.encoding, 'dicom'));
                break;

            case 'showCompatiblesForProject':
                if (!value) return;
                result = result.filter(dataset =>
                    value?.map((item: any) => dataset?.compatibleModelIds?.includes(item)).includes(true)
                );

                break;
            case 'showCompatibles':
            case 'hideNoDefaultMetadata':
                if (!value) return;
                result = result.filter(
                    dataset =>
                        !isNullOrUndefined(dataset.defaultMetadataId) &&
                        dataset.defaultMetadataId !== 'N/A' &&
                        dataset.defaultMetadataId !== ''
                );
                break;

            case 'isDicom':
                if (!value) return;
                result = result.filter(dataset => isDicom(dataset));
                break;

            default:
                result = result.filter(d => IsIncludeOrEqual(d[key as keyof IDataset], value as any, true));
        }

        result = additionalFiltering?.(result, key, value) ?? result;
    });

    // this sort func ensures that blank dates moved to bottom
    function sortingFunction(result: Array<IDataset>, type: number) {
        return [...result].sort((a, b) => {
            const dateA = dayjs(a.createdDate).unix(),
                dateB = dayjs(b.createdDate).unix();
            if (!dayjs(a.createdDate).isValid()) return 1;
            if (!dayjs(b.createdDate).isValid()) return -1;
            return type * (+dateA - +dateB);
        });
    }

    const datasetIds = result.map(item => item.datasetId);

    const filteredFuseResult = fuseResult?.filter(d => datasetIds?.includes(d.item.value));

    const searchOptionsWithHighlight = getSearchOptionsWithHighlight(filteredFuseResult);

    return {
        filteredDatasetList: result,
        searchOptionsWithHighlight,
    };
}

export interface IUseFilteredDatasetListArgs<T> {
    datasetList?: Array<IDataset>;
    filters: Partial<T> | Dictionary;
    sortBy?: SortingKey;
    archived?: boolean;
    additionalFiltering?: (datasetList: Array<IDataset>, key: keyof T, value: any) => Array<IDataset>;
}

export interface IDatasetFilters {
    search: string;
    modality: FilterInput;
    anatomy: FilterInput;
    problemType: FilterInput;
    sourceId: FilterInput;
    encoding: FilterInput;
    category: FilterInput;
    hidePreviousVersions: boolean;
    hidePublicDatasets: boolean;
    hideNoDefaultMetadata?: boolean;
    isDicom?: boolean;
    showCompatibles?: boolean;
    showDatasetWithAnnotations?: boolean;
}

export type CategoricalDatasetFilters = 'modality' | 'anatomy' | 'sourceId' | 'encoding' | 'category';
export type BooleanDatasetFilters =
    | 'hidePreviousVersions'
    | 'hidePublicDatasets'
    | 'hideNoDefaultMetadata'
    | 'isDicom'
    | 'showDatasetWithAnnotations';

export function DatasetsFiltersFactory(data?: Partial<IDatasetFilters>): IDatasetFilters {
    return {
        search: data?.search || null,
        modality: data?.modality || null,
        anatomy: data?.anatomy || null,
        problemType: data?.problemType || null,
        sourceId: data?.sourceId || null,
        encoding: data?.encoding || null,
        category: data?.category || null,
        hidePreviousVersions: data?.hidePreviousVersions || false,
        hidePublicDatasets: data?.hidePublicDatasets || false,
        hideNoDefaultMetadata: data?.hideNoDefaultMetadata || false,
        isDicom: data?.isDicom || false,
        showCompatibles: data?.showCompatibles || false,
        showDatasetWithAnnotations: data?.showDatasetWithAnnotations || false,
    };
}
