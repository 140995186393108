import { PayloadAction } from '@reduxjs/toolkit';
import { Dictionary } from 'common';
import { WritableDraft } from 'immer/dist/internal';
import { IValidationState } from 'pages/Validation/entities';
import { IBlindSpotAnalysisState, IBlindSpotTracking } from '../entities';

function setMetadataFiltersModal(state: WritableDraft<IValidationState>, { payload }: PayloadAction<boolean>) {
    state.blindSpotAnalysis.metadataFilters.open = payload;
}

function setMetadataFilters(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Dictionary>) {
    state.blindSpotAnalysis.metadataFilters.selectedMetadataFields = payload;
}

function updateValCreatedCohortList(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Array<string>>) {
    state.blindSpotAnalysis.analysisTracking.valCreatedCohortList = payload;
}
function updateBlindSpotAnalysisState(
    state: WritableDraft<IValidationState>,
    { payload }: PayloadAction<Partial<IBlindSpotAnalysisState>>
) {
    Object.assign(state.blindSpotAnalysis, payload);
}

function removeBlindSpotAnalysis(state: WritableDraft<IValidationState>, { payload }: PayloadAction<string>) {
    state.blindSpotAnalysis.blindSpotAnalysisTrackingList = state.blindSpotAnalysis.blindSpotAnalysisTrackingList.filter(
        item => item.bstId !== payload
    );
}

function updateBlindSpotTrackingList(state: WritableDraft<IValidationState>, { payload }: PayloadAction<IBlindSpotTracking>) {
    const index = state.blindSpotAnalysis.blindSpotAnalysisTrackingList.findIndex(item => item.bstId === payload.bstId);
    if (index > -1) state.blindSpotAnalysis.blindSpotAnalysisTrackingList[index] = payload;
    else state.blindSpotAnalysis.blindSpotAnalysisTrackingList.push(payload);
}

export const BlindSpotAnalysisReducer = {
    setMetadataFiltersModal,
    setMetadataFilters,
    updateValCreatedCohortList,
    updateBlindSpotAnalysisState,
    removeBlindSpotAnalysis,
    updateBlindSpotTrackingList,
};
