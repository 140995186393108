import { cornerstoneTools } from '../CornerstoneInitHelper/CornerstoneHelper';
import GAngleTool from './GAngleTool';
import GBrushTool from './GBrushTool';
import GCobbAngleTool from './GCobbAngleTool';
import GCorrectionScissorsTool from './GCorrectionScissorsTool';
import GFreehandScissorsTool from './GFreehandScissorsTool';
import GLengthTool from './GLengthTool';
import GRectangleRoiTool from './GRectangleRoiTool';
import SphericalGrowCutSegmentationTool from './SmartGrowCircleTool';
import { SAMPointPredictionTool } from './SmartPredictionTools/SAMPointPredictionTool';
import { SAMRectPredictionTool } from './SmartPredictionTools/SAMRectPredictionTool';
import ThresholdsBrushTool from './ThresholdsBrushTool';

export * from './ThresholdsBrushTool';

export const ToolMap = {
    Length: GLengthTool,
    Brush: GBrushTool,
    BrushEraser: GBrushTool,
    RectangleRoi: GRectangleRoiTool,
    CobbAngle: GCobbAngleTool,
    Angle: GAngleTool,
    SAMRectPrediction: SAMRectPredictionTool,
    SAMPointPrediction: SAMPointPredictionTool,
    SphericalGrowCutSegmentation: SphericalGrowCutSegmentationTool,
    FreehandScissors: GFreehandScissorsTool,
    CorrectionScissors: GCorrectionScissorsTool,
    ThresholdsBrush: ThresholdsBrushTool,
    ThresholdsBrushEraser: ThresholdsBrushTool,
    DragProbe: cornerstoneTools.DragProbeTool,
};
