import { Image } from 'antd';
import { IImage } from 'common/entities';
import { IWindowLevel } from 'common/enum';
import { usePlatformThumbnail } from 'common/hooks/usePlatformThumbnail';
import placeholder from 'components/assets/placeholder/placeholder.png';
import Styles from './Styles.module.scss';

export default function Thumbnail({ imageName, imageId, datasetId, windowLevel, sliceCount, style }: IProps) {

    const { image } = usePlatformThumbnail({ imageId, datasetId, windowLevel, slice: sliceCount ? 0 : -1 });

    return (
        <>
            <Image
                src={image}
                alt={imageName}
                className={Styles.Image}
                fallback={placeholder}
                placeholder={placeholder}
                preview={false}
                style={style}
            />
        </>
    );
}

interface IProps extends IImage {
    windowLevel?: IWindowLevel;
    style?: React.CSSProperties;
    qcMode?: boolean;
    status?: string;
}
