import { createAction } from "@reduxjs/toolkit";

export const SUBSCRIBE_PLATFORM_HEALTH_TRANSACTION = 'SUBSCRIBE_PLATFORM_HEALTH_TRANSACTION';
export const UNSUBSCRIBE_PLATFORM_HEALTH_TRANSACTION = 'UNSUBSCRIBE_PLATFORM_HEALTH_TRANSACTION';
export const GET_CREDENTIALS_LIST_TRANSACTION = 'GET_CREDENTIALS_LIST_TRANSACTION';
export const GET_TRANSACTIONLIST_TRANSACTION = 'GET_TRANSACTIONLIST_TRANSACTION';


export const subscribePlatformHealthService = createAction(SUBSCRIBE_PLATFORM_HEALTH_TRANSACTION);
export const unsubscribePlatformHealthService = createAction(UNSUBSCRIBE_PLATFORM_HEALTH_TRANSACTION);
export const getTransactionListTransaction = createAction(GET_TRANSACTIONLIST_TRANSACTION);
export const getCredentialsListTransaction = createAction(GET_CREDENTIALS_LIST_TRANSACTION);
