export const StorageOptions: Record<StorageOptionType, LocalForageOptions> = {
    EmbeddingDB: {
        name: 'SAMEmbedding',
        version: 1.0,
        description: 'Store embedding for SAM',
        storeName: 'embedding',
    },

    AnnotationDB: {
        name: 'Annotation',
        version: 1.0,
        description: 'User annotations',
        storeName: 'annotation',
    },
};

export type StorageOptionType = 'EmbeddingDB' | 'AnnotationDB';
