import GMenu from 'components/GMenu';
import { MenuLine } from 'components/assets/icons';
import { useLocation } from 'react-router-dom';
import { linkMenuList } from './LinkItem';
import Styles from './Styles.module.scss';

export default function LinkPanel() {
    const location = useLocation();
    const page = location.pathname.split('/')[1];

    return (
        <>
            <GMenu
                overflowedIndicator={<MenuLine />}
                mode="horizontal"
                items={linkMenuList}
                className={Styles.NavMenu}
                id="navigation-menu"
                selectedKeys={[page || 'main']}
            />
        </>
    );
}
