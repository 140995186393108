import { getMessages } from 'common';
import { Notification, showMessageModal } from 'components';
import { getDatasetListTransaction } from 'pages/Datasets/store/actions';
import { getModelListTransaction } from 'pages/Models/store/actions';
import { getValidationListTransaction } from 'pages/Validation/views/summary/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { setActiveProjectId } from '../store';
import { selectActiveProjectId } from '../store/selectors';

export function useActivateProject({ projectId }: { projectId: string }) {
    const dispatch = useDispatch();
    const activeProjectId = useSelector(selectActiveProjectId);
    const isActiveProject = activeProjectId === projectId;

    function changeProject(value: string) {
        isActiveProject ? deactivateProject() : activateProject();

        function deactivateProject() {
            localStorage.removeItem('activeProject');
            dispatch(setActiveProjectId(null));
            Notification.success({ content: getMessages('001245') });
        }

        function activateProject() {
            localStorage.setItem('activeProject', value);
            dispatch(setActiveProjectId(value));
            Notification.success({ content: getMessages('001244') });
        }

        dispatch(getModelListTransaction());
        dispatch(getDatasetListTransaction());
        dispatch(getValidationListTransaction());
    }

    return function onStartValidation() {
        showMessageModal({
            type: 'info',
            content: isActiveProject ? getMessages('001260') : getMessages('001259'),
            cancelButton: {
                enabled: true,
            },
            okButton: {
                text: getMessages('000397'),
                color: 'primary',
            },
        }).then(() => changeProject(projectId));
    };
}
