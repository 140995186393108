import constate from "constate";
import { useEffect, useState } from 'react';
import { View } from "./interface";

export function useGalleryState({ defaultView }: IProps) {

    const viewState = useState<View>(defaultView);

    useEffect(() => {
        viewState[1](defaultView);
    }, [defaultView]);


    return { viewState };
}


export const [GalleryStateProvider, useViewState] =
    constate(useGalleryState,
        value => value.viewState,
    );

interface IProps {
    defaultView?: View;
}