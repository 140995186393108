import { enMessages } from "./en";
import { trMessages } from "./tr";

export const messages = {
    "tr-TR": trMessages,
    "en-EN": enMessages
};

export type MessageKeys = keyof typeof messages["en-EN"];

export { enMessages, trMessages };