import { subscribeDB } from 'app/store/actions';
import { AxiosResponse } from 'axios';
import { AuthService } from 'common';
import { put } from 'redux-saga/effects';
import { setAppInit, updateToken } from '..';

export function* updateSessionSaga(): any {
    try {
        const response: AxiosResponse<{ token: string }> = yield AuthService.UpdateSession.call();

        const token = response?.data?.token;

        localStorage.setItem('accessToken', token);
        sessionStorage.setItem('accessToken', token);

        yield put(updateToken(token));
        yield put(subscribeDB(false));
    } catch (e) {
        console.log(e);
    } finally {
        yield put(setAppInit(true));
    }
}
