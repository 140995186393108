import { Dictionary } from "common/entities";
import { _entries } from "common/Utils";

export class URLHelper extends URL {
    constructor (url: string, queryParams?: Dictionary<string>, base?: string | URL) {
        super(url, base);

        if (queryParams) {
            this.setQueryParams(queryParams);
        }
    }

    public getQueryParams(): Dictionary<string> {
        const params = this.searchParams;
        const result: Record<string, string> = {};
        for (const [key, value] of params) {
            result[key] = value;
        }
        return result;
    }

    public setQueryParams(params: Dictionary<string>): void {
        for (const [key, value] of _entries(params)) {
            this.searchParams.set(key, value);
        }
    }
}
