import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { APIService, getMessages, IDataset } from 'common';
import { Notification } from 'components';
import produce from 'immer';
import { IMetadataFilterField } from 'pages/Datasets/views/DatasetCuration/entities';
import { put, select } from 'redux-saga/effects';
import { selectDatasetList } from 'redux/selectors';
import { setDatasetList, updateImageFilteringState } from '..';

export function* setDefaultMetadataSaga({ payload }: PayloadAction<{ dataset_id: string; metadata_id: string }>): any {
    try {
        const response = yield APIService.Metadata.SetDefaultMetadata.call(payload);

        if (response?.data) {
            Notification.success({ content: getMessages('000810') });

            const datasetList: Array<IDataset> = yield select(selectDatasetList);

            const newList = produce(datasetList, draft => {
                const selectedDataset = draft.find(dataset => dataset.datasetId === payload.dataset_id);
                if (!selectedDataset) return;
                selectedDataset.defaultMetadataId = payload.metadata_id;
            });

            yield put(setDatasetList(newList));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* GetMetadataFilterFieldsSaga({ payload }: PayloadAction<any>): any {
    try {
        yield put(updateImageFilteringState({ metadataFiltersLoading: true }));
        const response: AxiosResponse<Array<IMetadataFilterField>> = yield APIService.Metadata.GetMetadataFilterFields.call({
            datasetIdList: payload,
        });
        if (response?.data) {
            yield put(
                updateImageFilteringState({
                    metadataFiltersLoading: false,
                    metadataFilterFields: response.data,
                })
            );
        }
    } catch (error) {
        console.log(error);
        yield put(updateImageFilteringState({ metadataFiltersLoading: false }));
    }
}
