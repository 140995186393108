import { Form } from 'antd';
import { selectConfigFailModalVisible } from 'app/store/selectors';
import { getMessages } from 'common';
import { GForm, GInput, GModal } from 'components';
import { IGFormProps } from 'components/GForm';
import { IGModalProps } from 'components/GModal';
import { ActivationTokenTransaction } from 'pages/Authentication/store/actions';
import { selectActivationStatus, selectActivationToken, selectIsAppInit } from 'pages/Authentication/store/Selectors';
import { useDispatch, useSelector } from 'react-redux';
import Styles from "./Styles.module.scss";

export default function ActivationModal() {
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const isAppInit = useSelector(selectIsAppInit)
    const activationToken = useSelector(selectActivationToken)
    const activationStatus = useSelector(selectActivationStatus)
    const visible = useSelector(selectConfigFailModalVisible);

    function onFinish(activationToken: string) {
        dispatch(ActivationTokenTransaction(activationToken));
    }

    const modalProps: IGModalProps = {
        title: activationStatus === "expired" ? "Token Expired" : getMessages("001320"),
        footer: null,
        open: !activationToken && isAppInit && !visible,
        width: "400px",
        closable: false,
        destroyOnClose: true,
        className: activationStatus === "expired" ? Styles.ActivationExpiredModalContainer : Styles.ActivationModalContainer,
    };

    const formProps: IGFormProps = {
        name: "activation_modal_form",
        layout: 'vertical',
        onFinish, form,
        requiredMark: false,
        hideRequiredMark: true,
        submitButton: true,
        submitButtonText: getMessages("001324"),
        className: Styles.FormContainer,
        id: "auth-activation-modal",
        submitButtonId: "annotation-create-new-project-submit",
    };

    return (
        <GModal {...modalProps}>
            <GForm
                {...formProps}
            >
                <Form.Item
                    label={getMessages("001318")}
                    name="activationToken"
                    rules={[{
                        required: true,
                        message: getMessages("001319"),
                    }]}
                >
                    <GInput type="text" placeholder={getMessages("001318")} id="activation-token-input" />
                </Form.Item>
            </GForm>
            {activationStatus === "expired" && <div className={"pBody-bold red"}>
                {getMessages("001321")}</div>}
        </GModal>
    );
}