import * as am5 from '@amcharts/amcharts5';
import { ColumnSeries } from '@amcharts/amcharts5/xy';
import { ChartHelper } from 'common';
import { SeriesType } from 'common/helpers/ChartHelper/entities/enums';

export function makeSeries(
    chartHelper: ChartHelper.XYChart,
    category: string,
    chartID: string,
    locationX: number,
    lightTheme?: boolean,
    borderRadius?: { cornerRadiusTL: number; cornerRadiusTR: number },
    barWidth?: number,
    maskBullets?: boolean,
    bulletTextColor?: am5.Color,
    showBulletText?: boolean
) {
    chartHelper.createSeries(
        {
            type: SeriesType.ColumnSeries,
            seriesSettings: {
                valueYField: category,
                categoryXField: 'name',
                name: category,
                // sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(chartHelper.root, {
                    labelText: maskBullets === false ? category + ',{valueY}' : '{valueY}',
                }),
                locationX,
                id: chartID + category,
                maskBullets,
            },
        },
        (series: ColumnSeries) => {
            series.columns.template.setAll({
                tooltipText: '{name}, {categoryX}:{valueY}',
                // width: 40,
                width: am5.percent(barWidth ?? 90),
                tooltipY: 0,
                cornerRadiusTL: borderRadius?.cornerRadiusTL ?? 5,
                cornerRadiusTR: borderRadius?.cornerRadiusTR ?? 5,
                strokeOpacity: 0,
                maxWidth: 90,
            });

            if (maskBullets === false) {
                series.bullets.push(function () {
                    return am5.Bullet.new(chartHelper.root, {
                        locationY: 0,
                        sprite: am5.Label.new(chartHelper.root, {
                            text: category,
                            fill: lightTheme ? am5.color('#000') : am5.color('#fff'),
                            centerY: am5.p50,
                            centerX: am5.p50,
                            populateText: true,
                            rotation: -75,
                            oversizedBehavior: 'truncate',
                            width: 150,
                            maxWidth: 70,
                            paddingRight: 5,
                            textAlign: 'right',
                            fontSize: 11,
                        }),
                    });
                });
            } else {
                series.columns.template.onPrivate('height', function (height, target) {
                    am5.array.each(target.dataItem.bullets, function (bullet) {
                        if (height > 50) {
                            bullet.set('locationY', 1);
                            bullet.get('sprite').set('centerY', am5.p0);
                        } else {
                            bullet.set('locationY', 1);
                            bullet.get('sprite').set('centerY', am5.p100);
                        }
                        if (height < 10) {
                            bullet.get('sprite').set('visible', false);
                        }
                    });
                });

                if (!showBulletText) {
                    series.columns.template.onPrivate('width', function (width, target) {
                        am5.array.each(target.dataItem.bullets, function (bullet) {
                            if (width < 50) {
                                bullet.get('sprite').set('visible', false);
                            }
                        });
                    });
                }

                series.bullets.push(function () {
                    return am5.Bullet.new(chartHelper.root, {
                        locationY: 1,
                        sprite: am5.Label.new(chartHelper.root, {
                            text: `{${category}}`,
                            fill: lightTheme ? am5.color('#000') : bulletTextColor ?? am5.color('#000'),
                            centerX: am5.p50,
                            centerY: am5.p0,
                            // dy: 30,
                            populateText: true,
                        }),
                    });
                });
            }
        }
    );
}
