export interface IDatasetCreation {
    curateDatasetLoading: boolean;
    datasetPicture: string;
}

export function DatasetCreationFactory(data?: Partial<IDatasetCreation>): IDatasetCreation {
    return {
        curateDatasetLoading: data?.curateDatasetLoading ?? false,
        datasetPicture: data?.datasetPicture ?? "",
    };
}