import { IPoint } from '../../DicomViewerHelper/interface';

export function PointMeasurementFactory(point: IPoint, strategy: ClickTypeStrategy) {
    return {
        visible: true,
        active: true,
        color: strategyColorMap[strategy],
        invalidated: true,
        handles: {
            end: {
                x: point.x,
                y: point.y,
                highlight: true,
                active: true,
            },
        },
    };
}

export function RectMeasurementFactory([start, end]: [IPoint, IPoint], initialRotation: number) {
    return {
        visible: true,
        active: true,
        color: 'green',
        invalidated: true,
        handles: {
            start: {
                x: start.x,
                y: start.y,
                highlight: true,
                active: false,
            },
            end: {
                x: end.x,
                y: end.y,
                highlight: true,
                active: true,
            },
            initialRotation,
        },
    };
}

export const strategyClickTypeMap: Record<ClickTypeStrategy, number> = {
    add: 1,
    remove: 0,
};

export const strategyColorMap: Record<ClickTypeStrategy, string> = {
    add: 'green',
    remove: 'red',
};

export interface SAMPointPredictionToolConfiguration {
    renderDashed?: boolean;
    handleRadius?: number;
    strategy?: ClickTypeStrategy;
}
export type ClickTypeStrategy = 'add' | 'remove';
