import { Drawer, DrawerProps } from 'antd';
import Styles from './Styles.module.scss';
import cx from "classnames"

export default function GDrawer({ bodyStyle, className, ...props }: IGDrawer) {
    return (
        <Drawer
            className={cx(Styles.GDrawer, className)}
            bodyStyle={Object.assign(bodyStyle, { paddingBottom: 10 })}
            {...props}
        />
    )
}

export interface IGDrawer extends DrawerProps {

}