import { INotification } from 'app/entities';
import { _uuid } from 'common/Utils/LangUtils';
import { EventBus } from 'common/helpers/EventBus';
import dayjs from 'dayjs';
import { ToastContainerProps, ToastContent, ToastOptions, toast } from 'react-toastify';

export default class Notification {
    static deafultOptions: ToastContainerProps = {
        theme: 'dark',
    };

    private static applyExtraOptions({ content, options }: INotificationArgs) {
        if (options?.showTime) {
            return (
                <div>
                    <div>{content}</div>
                    <div>{dayjs().format('YYYY-MM-DD HH:mm:ss')}</div>
                </div>
            );
        }
        return content;
    }

    private static show({ content, options }: INotificationArgs) {
        options.toastId = options?.toastId || _uuid();
        // Notification.addNotificationToStore(NotificationFactory({ id: options.toastId as any, content, type: options.type }));

        toast(content, options);
    }

    static success({ content, options = {} }: INotificationArgs) {
        options.toastId = options?.toastId || _uuid();

        content = Notification.applyExtraOptions({ content, options });
        options.type = 'success';

        Notification.show({ content, options });
    }

    static info({ content, options = {} }: INotificationArgs) {
        options.toastId = options?.toastId || _uuid();

        content = Notification.applyExtraOptions({ content, options });
        options.type = 'info';

        Notification.show({ content, options });
    }

    static warning({ content, options = {} }: INotificationArgs) {
        options.toastId = options?.toastId || _uuid();

        content = Notification.applyExtraOptions({ content, options });
        options.type = 'warning';

        Notification.show({ content, options });
    }

    static error({ content, options = {} }: INotificationArgs) {
        options.toastId = options?.toastId || _uuid();

        content = Notification.applyExtraOptions({ content, options });
        options.type = 'error';

        Notification.show({ content, options });
    }

    private static addNotificationToStore(notification: INotification) {
        EventBus.emit('dispatch', {
            type: 'App/updateNotifications',
            payload: [notification],
        });
    }
}

export interface INotificationArgs {
    content: ToastContent;
    options?: IExtraOptions & ToastOptions<{}>;
}

interface IExtraOptions {
    showTime?: boolean;
}
