import { ChartHelper } from 'common/helpers';
import { AxesType, SeriesType } from 'common/helpers/ChartHelper/entities/enums';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

export function createChart(chartID: string, chartOptions?: IChartOptions) {
    const chartHelper = new ChartHelper.XYChart();

    chartHelper.createRoot({ chartID });

    chartHelper
        .createChart({ legendEnabled: chartOptions?.legendEnabled, })
        .createAxes({
            xAxis: {
                type: AxesType.value,
                rendererSetting: {
                    minGridDistance: 40,
                },
                valueAxisOptions: {
                    maxDeviation: 0.3,
                    min: 0,
                },
            },
            yAxis: {
                type: AxesType.category,
                rendererSetting: {
                    minGridDistance: 20,
                },
                categoryAxisOptions: {
                    maxDeviation: 0.3,
                    categoryField: chartOptions?.xAxis?.fieldName || "name",
                }
            },
        }, (xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>, yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>) => {

            xAxis.get("renderer").grid.template.setAll({
                fillOpacity: 0,
                strokeOpacity: 0,
            });

            xAxis.get("renderer").labels.template.setAll({
                oversizedBehavior: "truncate",
                maxWidth: 50
            });

            if (chartOptions?.yAxis?.wrap) {
                yAxis.get("renderer").labels.template.setAll({
                    oversizedBehavior: "wrap",
                    maxWidth: chartOptions?.yAxis?.maxWidth,
                });
            }
        });

    chartHelper.createSeries({
        type: SeriesType.ColumnSeries,
        seriesSettings: {
            name: chartOptions?.series?.name || chartID,
            valueXField: chartOptions?.yAxis?.fieldName || "value",
            categoryYField: chartOptions?.xAxis?.fieldName || "name",
            valueField: chartOptions?.yAxis?.fieldName || "value",
            fill: am5.color(chartOptions?.series?.fill || "#193498"),
            sequencedInterpolation: true,
            tooltip: am5.Tooltip.new(chartHelper.root, {
                labelText: "{valueY}"
            }),
            calculateAggregates: true,
        },
        enableTooltip: chartOptions?.tooltipEnabled,
    }, (series: am5xy.ColumnSeries) => {
        series.columns.template.setAll({ cornerRadiusBR: 5, cornerRadiusTR: 5, strokeOpacity: 0 });

        if (chartOptions?.valueBulletEnabled) {

            if (chartOptions?.dynamicBulletPosition) {
                series.columns.template.onPrivate("width", function (width, target) {
                    am5.array.each(target.dataItem.bullets, function (bullet) {
                        if (width > 50) {
                            bullet.set("locationX", 0.5);
                            bullet.get("sprite").set("centerX", am5.p50);
                        }
                        else {
                            bullet.set("locationX", 1);
                            bullet.get("sprite").set("dx", 50);
                        }
                    });
                });
            }

            series.bullets.push(function () {
                return am5.Bullet.new(chartHelper.root, {
                    locationX: 1,
                    sprite: am5.Label.new(chartHelper.root, {
                        text: "{value}",
                        fill: am5.color("#fff"),
                        centerX: am5.p100,
                        centerY: am5.p50,
                        populateText: true,
                        fontSize: 12,
                    })
                });
            });

        }

        if (chartOptions?.heatFillEnabled) {

            series.set("heatRules", [{
                target: series.columns.template,
                min: am5.color("#F2F2F2"),
                max: am5.color("#4829A3"),
                dataField: "value",
                key: "fill"
            }]);
        }
    });

    return chartHelper;
}

export interface IChartOptions {
    valueBulletEnabled?: boolean;
    legendEnabled?: boolean;
    tooltipEnabled?: boolean;
    heatFillEnabled?: boolean;
    dynamicBulletPosition?: boolean;
    xAxis?: {
        labelText?: string;
        fieldName?: string;
    };
    yAxis?: {
        fieldName?: string;
        wrap?: boolean;
        maxWidth?: number;
    };
    series?: {
        fill?: string;
        stroke?: string;
        name?: string;
    };
}