import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { ChartHelper } from 'common/helpers';
import { AxesType, SeriesType } from 'common/helpers/ChartHelper/entities/enums';

export function createChart(chartID: string, chartOptions: IChartOptions = {}) {
    const { lightTheme } = chartOptions;
    const chartHelper = new ChartHelper.XYChart<am5xy.ColumnSeries>();
    let xAxisTitle;

    chartHelper.createRoot({ chartID, lightTheme });

    chartHelper.createChart({ legendEnabled: chartOptions?.legendEnabled }).createAxes(
        {
            xAxis: {
                type: AxesType.category,
                rendererSetting: {
                    minGridDistance: 20,
                },
                categoryAxisOptions: {
                    maxDeviation: 0.3,
                    categoryField: chartOptions?.xAxis?.fieldName || 'name',
                    tooltip: am5.Tooltip.new(chartHelper.root, {
                        labelText: '{name}',
                    }),
                },
            },
            yAxis: {
                type: AxesType.value,
                rendererSetting: {},
                valueAxisOptions: {
                    maxDeviation: 0.3,
                    min: chartOptions?.yAxis?.min,
                    max: chartOptions?.yAxis?.max,
                },
            },
        },
        (xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>, yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>) => {
            if (chartOptions?.xAxis?.labelText) {
                xAxis.get('renderer').labels.template.setAll({
                    text: chartOptions.xAxis?.labelText,
                    fill: am5.color('#fff'),
                    tooltipText: '{name}',
                });
            }
            if (chartOptions?.xAxis?.rotate) {
                xAxis.get('renderer').labels.template.setAll({
                    rotation: -90,
                    // rotation: -75,
                    centerY: am5.p50,
                    centerX: am5.p100,
                    paddingRight: 5,
                    textAlign: 'right',
                });
            }

            if (chartOptions?.xAxis?.title) {
                xAxisTitle = am5.Label.new(chartHelper.root, {
                    text: chartOptions?.xAxis?.title,
                    x: am5.p50,
                    centerX: am5.p50,
                    fill: am5.color('#fff'),
                });
                xAxis.children.push(xAxisTitle);
            }

            xAxis.get('renderer').grid.template.setAll({
                fillOpacity: 0,
                strokeOpacity: 0,
            });

            if (chartOptions?.xAxis?.truncate) {
                xAxis.get('renderer').labels.template.setAll({
                    oversizedBehavior: 'truncate',
                    maxWidth: 50,
                    tooltip: am5.Tooltip.new(chartHelper.root, {
                        labelText: '{name}',
                    }),
                });
            }
        }
    );

    chartHelper.createSeries(
        {
            type: SeriesType.ColumnSeries,
            seriesSettings: {
                name: chartOptions?.series?.name || chartID,
                valueYField: chartOptions?.yAxis?.fieldName || 'value',
                categoryXField: chartOptions?.xAxis?.fieldName || 'name',
                valueField: chartOptions?.yAxis?.fieldName || 'value',
                // fill: am5.color(chartOptions?.series?.fill || '#19B6A4'),
                sequencedInterpolation: true,
                tooltip: am5.Tooltip.new(chartHelper.root, {
                    labelText: chartOptions?.tooltipLabelText || '{name}-{valueY}',
                }),
                calculateAggregates: true,
            },
            enableTooltip: chartOptions?.tooltipEnabled,
        },
        (series: am5xy.ColumnSeries) => {
            series.columns.template.setAll({ cornerRadiusTL: 5, cornerRadiusTR: 5, strokeOpacity: 0, maxWidth: 90 });

            if (chartOptions?.valueBulletEnabled) {
                if (chartOptions?.dynamicBulletPosition) {
                    series.columns.template.onPrivate('height', function (height, target) {
                        am5.array.each(target.dataItem.bullets, function (bullet) {
                            if (height > 50) {
                                bullet.set('locationY', 1);
                                bullet.get('sprite').set('centerY', am5.p0);
                            } else {
                                bullet.set('locationY', 1);
                                bullet.get('sprite').set('centerY', am5.p100);
                            }
                        });
                    });
                }

                series.bullets.push(function () {
                    return am5.Bullet.new(chartHelper.root, {
                        locationY: 1,
                        sprite: am5.Label.new(chartHelper.root, {
                            text: chartOptions?.bulletLabelText || '{value}',
                            fill: lightTheme ? am5.color('#000') : am5.color('#fff'),
                            fontWeight: 'bold',
                            centerX: am5.p50,
                            centerY: am5.p0,
                            // dy: 30,
                            populateText: true,
                        }),
                    });
                });
            }

            if (chartOptions?.heatFillEnabled) {
                series.set('heatRules', [
                    {
                        target: series.columns.template,
                        min: am5.color('#F2F2F2'),
                        max: am5.color('#4829A3'),
                        dataField: 'value',
                        key: 'fill',
                    },
                ]);
            }
        }
    );

    return { chartHelper, xAxisTitle };
}

export interface IChartOptions {
    valueBulletEnabled?: boolean;
    legendEnabled?: boolean;
    tooltipEnabled?: boolean;
    heatFillEnabled?: boolean;
    dynamicBulletPosition?: boolean;
    tooltipLabelText?: string;
    bulletLabelText?: string;
    xAxis?: {
        labelText?: string;
        fieldName?: string;
        title?: string;
        rotate?: boolean;
        truncate?: boolean;
        maxWidth?: number;
    };
    yAxis?: {
        fieldName?: string;
        min?: number;
        max?: number;
    };
    series?: {
        fill?: string;
        stroke?: string;
        name?: string;
    };
    lightTheme?: boolean;
    customColor?: string[];
}
