import { updateCommonState } from 'app/store';
import { APIService, getNetworkSpeed } from 'common';
import { selectAuthToken } from 'pages/Authentication/store/Selectors';
import { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

export function useNetworkStatus() {
    const dispatch = useDispatch();
    const interval = useRef<NodeJS.Timeout>();
    const token = useSelector(selectAuthToken);

    useEffect(() => {

        handleInterval();

        window.ononline = () => {
            handleInterval();
        };

        window.onoffline = () => {
            clearInterval(interval.current);
            interval.current = null;
            dispatch(
                updateCommonState({
                    networkStatus: {
                        bps: null,
                        kbps: null,
                        mbps: null,
                        status: "offline"
                    }
                }));
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {

        checkServerNetwork();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [token]);


    function handleInterval() {
        if (interval.current) return;
        handleNetworkSpeed();
        interval.current = setInterval(handleNetworkSpeed, 600000);
    }

    async function handleNetworkSpeed() {
        try {
            const speed = await getNetworkSpeed();

            dispatch(updateCommonState({ networkSpeed: speed }));
        } catch (error) {
            console.error(error);
        }
    }

    async function checkServerNetwork() {
        try {
            if (!token) return;
            const response = await APIService.Platform.CheckServerInternetConnection.get();
            dispatch(updateCommonState({ serverNetworkStatus: response?.data ? "online" : "offline" }));
        } catch (error) {
            console.error(error);
        }
    }
}
