import cx from "classnames";
import { getMessages } from 'common/helpers';
import GSelect from 'components/GSelect';
import { ICategoricalFilterItem } from '../../entities';
import Styles from './Styles.module.scss';

export default function CategoricalFilterItem({ menuList, name, value, title, onChangeFilter, className }: IProps) {
    return (
        <div className={cx(Styles.CategoricalFilter, className)}>
            <div className={cx('pBody-medium', 'white', 'mb-4')}>{title}</div>
            <GSelect
                menuItemList={menuList}
                placeholder={getMessages('000209')}
                onChange={value => {
                    onChangeFilter(name, value);
                }}
                value={value}
                allowClear
                id={`dataset-filter-${name}-select`}
                size="small"
            />
        </div>
    );
}

interface IProps extends ICategoricalFilterItem {
    onChangeFilter: (key: string, value: any) => void;
    className?: string;
}