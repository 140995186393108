import GCheckbox from 'components/GCheckbox';
import { GRadio } from 'components/GRadio';
import Styles from './Styles.module.scss';
import { ISelection } from './entities';

export default function SelectionComponent({ selection, checked }: IProps) {
    switch (selection?.type) {
        case 'checkbox':
            return (
                <GCheckbox
                    checked={checked}
                    className={Styles.ImageSelection}
                />
            );

        case 'radio':
            return (
                <GRadio
                    checked={checked}
                    className={Styles.ImageSelection}
                />
            );

        default:
            return null;
    }
}

interface IProps {
    selection?: ISelection;
    checked?: boolean;
}
