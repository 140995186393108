import { URLSearchValues } from 'common';
import { _entries, _isEmpty } from 'common/Utils';
import { isStudyDataset } from 'common/entities';
import { getValueFromUrl } from 'common/hooks/useGetSearchParamValue';
import { ViewportStateFactory } from 'components/GDicomViewer/entities';
import {
    selectGridView,
    selectViewportStateMap,
    updateActiveViewport,
    updateDicomViewerState,
    updateGridView,
} from 'components/GDicomViewer/store';
import { useGetStudySeries } from 'pages/Datasets/store/hooks';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDataset, useSimpleImageList } from '../ImageState';
import { useChangeImage } from './useChangeImageState';

export function useTransactions({ studyId }: { studyId: string }) {
    const dataset = useDataset();
    const dispatch = useDispatch();
    const simpleImageList = useSimpleImageList();
    const viewportStateMap = useSelector(selectViewportStateMap);
    const { currentData: studySeries } = useGetStudySeries(dataset?.datasetId, studyId, !isStudyDataset(dataset));
    const changeImageState = useChangeImage();
    const gridView = useSelector(selectGridView);

    async function autoImageSelection() {
        try {
            if (!dataset) return;
            const seriesId = getValueFromUrl(URLSearchValues.SERIES) || studySeries?.[0]?.series_id;
            let imageId = getValueFromUrl(URLSearchValues.IMAGE);
            if (isStudyDataset(dataset)) {
                if (!studyId) return;
                _entries(viewportStateMap).forEach(([viewportIndex, viewportState]) => {
                    if (viewportState.seriesId || viewportState?.loading || !seriesId) return;
                    changeImageState({ seriesId, viewportIndex, imageId });
                });
            } else {
                if (!simpleImageList?.length) return;
                imageId = imageId ?? simpleImageList[0]?.imageId;

                _entries(viewportStateMap).forEach(([viewportIndex, viewportState]) => {
                    if (!_isEmpty(viewportState.imageId)) return;
                    changeImageState({ imageId, viewportIndex: Number(viewportIndex) });
                });
            }
        } catch (error) {
            console.log(error);
        }
    }

    const gridCount = gridView.columns * gridView.rows;
    useEffect(() => {
        autoImageSelection();
    }, [dataset, studyId, gridCount, simpleImageList, studySeries]);

    useEffect(() => {
        dispatch(updateGridView({ columns: 1, rows: 1 }));
        dispatch(updateActiveViewport(0));
        dispatch(updateDicomViewerState({ viewportStateMap: { 0: ViewportStateFactory() } }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataset]);
}

export * from './useChangeImageState';
export * from './useChangeSeriesState';
export * from './useChangeStudyState';

