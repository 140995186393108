import { ChartHelper } from 'common/helpers';
import { AxesType, SeriesType } from 'common/helpers/ChartHelper/entities/enums';
import * as am5 from "@amcharts/amcharts5";
import { LineSeries } from '@amcharts/amcharts5/xy';
import * as am5xy from "@amcharts/amcharts5/xy";

export function createChart(chartID: string) {
    const chartHelper = new ChartHelper.XYChart();

    chartHelper.createRoot({ chartID });

    chartHelper
        .createChart({})
        .createAxes({
            xAxis: {
                type: AxesType.gaplessDate,
                categoryField: "time",
                dateAxisOptions: {
                    // maxDeviation: 0.5,
                    groupData: false,
                    extraMax: 0.1, // this adds some space in front
                    extraMin: -0.1,  // this removes some space form th beginning so that the line would not be cut off
                    baseInterval: {
                        timeUnit: "minute",
                        count: 3,
                    },
                },
                rendererSetting: {
                    minGridDistance: 50,
                }
            },
            yAxis: {
                type: AxesType.value, rendererSetting: {},
                valueAxisOptions: {
                    min: 0, max: 100, numberFormat: "#'%'"
                },
            }
        }, (xAxis: am5xy.DateAxis<am5xy.AxisRenderer>, yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>) => {
            yAxis.get("renderer").grid.template.setAll({
                stroke: am5.color("#666C75"),
                strokeDasharray: 0,
                strokeWidth: 1
            });
            xAxis.get("renderer").grid.template.setAll({
                stroke: am5.color("#666C75"),
                strokeDasharray: 0,
                strokeWidth: 1
            });
        });

    chartHelper.createSeries({
        type: SeriesType.LineSeries,
        seriesSettings: {
            name: chartID,
            valueYField: "value",
            valueXField: "date",
            fill: am5.color("#34C5F3"),
            stroke: am5.color("#34C5F3"),
        },
    }, (series: LineSeries) => {
        series.strokes.template.setAll({
            strokeWidth: 1,
        });
        series.fills.template.setAll({
            fillOpacity: 0.5,
            visible: true,
            fillGradient: am5.LinearGradient.new(chartHelper.root, {
                stops: [{ opacity: 0.3 }, { opacity: 0 }],
            })
        });
    });

    return chartHelper;
}