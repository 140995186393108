/* eslint-disable eqeqeq */
import { updateCommonState } from 'app/store';
import { unsubscribeDB } from 'app/store/actions';
import { selectSessionReminderVisible } from 'app/store/selectors';
import { IGModalProps } from 'components/GModal';
import { clearSession } from 'pages/Authentication/hooks';
import { updateSession } from 'pages/Authentication/store/actions';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import useEventCallback from 'use-event-callback';
import Styles from './Styles.module.scss';

export function useSessionReminder() {
    const dispatch = useDispatch();
    const [counter, setCounter] = useState(10);
    const intervalRef = useRef<NodeJS.Timeout>();
    const visible = useSelector(selectSessionReminderVisible);

    useEffect(() => {
        if (visible) {
            startInterval();
            dispatch(unsubscribeDB());
        }
    }, [dispatch, visible]);

    useEffect(() => {
        if (counter == 0) {
            logout();
        }
    }, [counter]);

    const onClose = useEventCallback(() => dispatch(updateCommonState({ sessionReminderVisible: false })));

    const modalProps: IGModalProps = {
        open: visible, setVisible: onClose,
        className: Styles.SessionReminderContainer,
        footer: null,
        width: 450,
        closable: false,
        destroyOnClose: true,
    };

    function logout() {
        clearSession();
        window.location.reload();
    }

    function startInterval() {
        intervalRef.current = setInterval(() => {
            setCounter(oldValue => oldValue - 1);
        }, 1000);
    }

    function stopInterval() {
        clearInterval(intervalRef.current);
        intervalRef.current = null;
        setCounter(60);
    }

    function onClickCancel() {
        logout();
    }

    function onClickContinue() {
        stopInterval();
        dispatch(updateSession());
        onClose();
    }

    return { modalProps, counter, onClickContinue, onClickCancel };

}
