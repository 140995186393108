import React from 'react';
import Styles from './Styles.module.scss';

export default function GKeyboardButton({ content }: IProps) {
    if (Array.isArray(content))
        return (
            <>
                {content.map((i, index) => {
                    const key = `${i}-${index}`;
                    return (
                        <>
                            <GKeyboardButton
                                key={key}
                                content={i}
                            />
                            <Divider show={index !== content.length - 1} />
                        </>
                    );
                })}
            </>
        );

    return (
        <div className={Styles.KeyboardFrame}>
            <span>{content}</span>
        </div>
    );
}

export function Divider({ show }: { show?: boolean }) {
    if (!show) return null;
    return <span className={Styles.Divider}>+</span>;
}

interface IProps {
    content: React.ReactNode;
}
