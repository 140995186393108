import { DicomViewerHelper } from 'common/helpers';
import { useMemo } from 'react';
import { useDataset } from '.';
import { useViewerImageList, useViewerImageListLoading } from '../useViewerImageList';

export function useImageUrlList(index?: number) {
    const dataset = useDataset();
    const imageList = useViewerImageList(index, true);
    const seriesImagesLoading = useViewerImageListLoading(index);
    const imageUrlList = useMemo(() => imageList?.map(i => DicomViewerHelper.getImageUrl(dataset, i)), [dataset, imageList]);

    return { imageUrlList, seriesImagesLoading, imageList };
}
