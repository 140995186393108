import { all, takeEvery } from 'redux-saga/effects';
import DatasetUploadSaga from '../../views/DatasetsUpload/store/saga';
import * as actions from '../actions';
import { CreateCuratedDatasetSaga } from './createCuratedDatasetSaga';
import { DatasetArchiveSaga, DatasetUnArchiveSaga } from './datasetArchiveSaga';
import { getDatasetListTransactionSaga } from './datasetListSaga';
import { editDatasetForm } from './editDatasetForm';
import { GetMetadataFilterFieldsSaga } from './metadataSaga';
import { GetSourceListSaga } from './sourceListSaga';

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_DATASET_LIST_TRANSACTION, getDatasetListTransactionSaga),
        takeEvery(actions.GET_SOURCE_LIST_TRANSACTION, GetSourceListSaga),
        takeEvery(actions.EDIT_DATASET_TRANSACTION, editDatasetForm),
        takeEvery(actions.ARCHIVE_DATASET_TRANSACTION, DatasetArchiveSaga),
        takeEvery(actions.UNARCHIVE_DATASET_TRANSACTION, DatasetUnArchiveSaga),
        takeEvery(actions.GET_METADATA_FILTER_FIELDS_TRANSACTION, GetMetadataFilterFieldsSaga),
        takeEvery(actions.CREATE_CURATED_DATASET_TRANSACTION, CreateCuratedDatasetSaga),
        DatasetUploadSaga(),
    ]);
}
