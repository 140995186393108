import { Dictionary, IImage } from "common";

export interface IDatasetAnalysisState {
    selectedSimilarImageId: string;
    imageAnalysisModalVisible: boolean;
    baseImageDetails: IImage;
    selectedNoisyImage: any;
    noisyImageModalVisible: boolean;
    similarBaseImageDetails: IImage;
    selectedAnnotation: string;
    annotationStats: Array<Dictionary>;
    metadataStats: Array<Dictionary>;
    selectedMetadataList: Array<string>;
    metadataFields: Array<any>;
    metadataStatsLoading: boolean;
    selectedLabelingConfiguration: string;

}

export function DatasetAnalysisStateFactory(data?: IDatasetAnalysisState): IDatasetAnalysisState {
    return {
        selectedSimilarImageId: data?.selectedSimilarImageId || null,
        imageAnalysisModalVisible: data?.imageAnalysisModalVisible || false,
        baseImageDetails: data?.baseImageDetails || null,
        selectedNoisyImage: data?.selectedNoisyImage || null,
        noisyImageModalVisible: data?.noisyImageModalVisible || false,
        similarBaseImageDetails: data?.similarBaseImageDetails || null,
        selectedAnnotation: data?.selectedAnnotation || null,
        annotationStats: data?.annotationStats || null,
        metadataStats: data?.metadataStats || null,
        selectedMetadataList: data?.selectedMetadataList || [],
        metadataFields: data?.metadataFields || null,
        metadataStatsLoading: data?.metadataStatsLoading || false,
        selectedLabelingConfiguration: data?.selectedLabelingConfiguration || null,
    };
}