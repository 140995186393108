import { INotification } from 'app/entities';
import { IPlatformMonitoring, PlatformMonitoringFactory } from '../views/PlatformMonitoring/entities';
import { IUsageStatistics, UsageStatisticsFactory } from '../views/UsageStatistics/entities';
import { ICredentials } from './Credentials';
import { IUserManagement, UserManagementFactory } from './UserManagement';

export interface IPlatformState {
    platformMonitoring: IPlatformMonitoring;
    usageStatistics: IUsageStatistics;
    credentialList: ICredentials[];
    userManagement: IUserManagement;
    addCredentialVisible?: boolean;
    common: IPlatformCommonState;
    uploadState: IFile;
}

interface IFile {
    file: string;
    fileName: string;
}

interface IPreviewModalState {
    isModalVisible: boolean;
    logDetails: INotification;
}
export interface IPlatformCommonState {
    previewModalState: IPreviewModalState;
    isUpdateTokenModalVisible: boolean;
}

function CommonStateFactory(data: IPlatformCommonState) {
    return {
        previewModalState: data?.previewModalState ?? { isModalVisible: false, logDetails: null },
        isUpdateTokenModalVisible: data?.isUpdateTokenModalVisible ?? false,
    };
}

export function PlatformStateFactory(data?: Partial<IPlatformState>): IPlatformState {
    return {
        platformMonitoring: PlatformMonitoringFactory(data?.platformMonitoring),
        usageStatistics: UsageStatisticsFactory(data?.usageStatistics),
        credentialList: data?.credentialList || [],
        userManagement: UserManagementFactory(data?.userManagement),
        addCredentialVisible: data?.addCredentialVisible || false,
        common: CommonStateFactory(data?.common),
        uploadState: data?.uploadState || null,
    };
}

export * from './AuditLogs';

