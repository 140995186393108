import { Link } from 'react-router-dom';
import CardImage, { IImageSectionProps } from './CardImage';
import CardInfo, { IInfoSectionProps } from './CardInfo';
import Styles from "./Styles.module.scss";

export default function DashboardCardItem<T = any>({ link, id, imageSectionProps, infoSectionProps, navCb }: IProps<T>) {

    return (
        <Link to={link} id={id} className={Styles.DashboardCardContainer} onClick={() => navCb?.(id)} >
            <CardInfo {...infoSectionProps}{...imageSectionProps} />
            <CardImage {...imageSectionProps} {...infoSectionProps} />
        </Link>
    );
}


interface IProps<T = any> {
    link: string;
    navCb?: (id: string) => void;
    id?: string;
    imageSectionProps: IImageSectionProps<T>;
    infoSectionProps: IInfoSectionProps;
}