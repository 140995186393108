/* eslint-disable react-hooks/exhaustive-deps */
import { Dictionary, IImage } from 'common/entities';
import { useEffect } from 'react';
import { useMap } from 'react-use';
import { getImageDetails } from '../services/Tools';

export function useImageDetails({ imageId, datasetId, fetch = true }: IUseImageDetailsArgs): Partial<IPreloadItem> {
    const [detailList, { set }] = useMap<Dictionary<IPreloadItem>>({});

    useEffect(() => {
        setSelectedImageDetails();
    }, [imageId, datasetId]);

    function setState(id: string, newState: Partial<IPreloadItem> = {}) {
        const item = detailList[id] ?? initialPreloadItem;

        set(imageId, { ...item, ...newState });
    }

    async function setSelectedImageDetails() {
        try {
            if (!imageId || !datasetId || detailList[imageId] || !fetch) return;

            setState(imageId, { loading: true });

            const imageDetails = await getImageDetails({ imageId, datasetId });
            setState(imageId, { imageDetails, loading: false });
        } catch (error) {
            console.error(error);
            setState(imageId, { loading: false });
        }
    }

    return detailList[imageId] ?? {};
}

interface IUseImageDetailsArgs {
    imageId: string;
    datasetId: string;
    fetch?: boolean;
}

interface IPreloadItem {
    imageDetails: IImage;
    loading: boolean;
}

const initialPreloadItem: IPreloadItem = {
    imageDetails: null,
    loading: false,
};
