import { ChartStateFactory, IChartState } from "./Charts";
import { CommonStateFactory, ICommon } from "./ICommon";
import { IImagePreviewControls, IIMagePreviewControlsFactory } from "./IImagePreviewControls";
import { IMetricsExtra, MetricsExtraDataFactory } from "./IMetricsExtra";
import { IViewState, ViewStateFactory } from "./IViews";

export interface IMetricsState {
    imagePreviewControls: IImagePreviewControls;
    metricsExtra: IMetricsExtra;
    validationMetrics: IChartState;
    common: ICommon;
    views: IViewState;
}

export const MetricsStateFactory = (data?: IMetricsState): IMetricsState => ({
    imagePreviewControls: IIMagePreviewControlsFactory(data?.imagePreviewControls),
    metricsExtra: MetricsExtraDataFactory(data?.metricsExtra),
    validationMetrics: ChartStateFactory(data?.validationMetrics),
    common: CommonStateFactory(data?.common),
    views: ViewStateFactory(data?.views),
});

export * from "./Charts";
export * from "./ICommon";
export * from "./IImagePreviewControls";
export * from "./IMetricsExtra";
export * from "./IViews";

