import cornerstoneTools from 'cornerstone-tools';
import growCutFromRect from '../SegmentationHelper/SegmentFromRect';


const BaseTool = cornerstoneTools.importInternal('base/BaseTool');


export class SmartBoundingBoxTool extends BaseTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'SmartBoundingBox',
            configuration: {
                shouldCleanSegment: false,
            },
            strategies: {
                GROW_CUT: growCutFromRect
            },
            defaultStrategy: 'GROW_CUT',
            supportedInteractionTypes: ['Mouse'],
            mixins: ['rectangleSegmentationMixin'],
        };
        super(props, defaultProps);
    }

  }