import { Slider, SliderSingleProps } from 'antd';
import { SliderRangeProps } from 'antd/lib/slider';
import Styles from './Styles.module.scss';
import cx from 'classnames';
import { RefObject } from 'react';

export default function GSlider({ className, sliderRef, ...props }: GSliderProps) {
    return (
        <div
            id={props.id}
            className={cx(Styles.GSliderContainer, className)}
        >
            <Slider
                ref={sliderRef}
                id={props.id}
                tooltip={{ placement: 'top' }}
                className={cx(Styles.GSlider)}
                {...props}
            />
        </div>
    );
}

export type GSliderProps = GSliderSingleProps | GSliderRangeProps;

interface GSliderSingleProps extends SliderSingleProps {
    id?: string;
    sliderRef?: RefObject<HTMLInputElement>;
}

interface GSliderRangeProps extends SliderRangeProps {
    range: true;
    id?: string;
    sliderRef?: RefObject<HTMLInputElement>;
}
