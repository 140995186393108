import { Input, InputProps } from 'antd';
import cx from "classnames";
import Styles from "./Styles.module.scss";

export default function GColorPicker({ className, ...props }: GColorPickerProps) {
    return (
        <>
            <Input
                type="color"
                className={cx(Styles.GColorPicker, className)}
                {...props}
            />
        </>
    );
}

export interface GColorPickerProps extends InputProps {

}
