import cornerstoneTools from 'cornerstone-tools';
import { Point } from '../DicomViewerHelper';

const BaseTool = cornerstoneTools.importInternal('base/BaseTool');
const cursors = cornerstoneTools.importInternal('tools/cursors');
const segmentationUtils = cornerstoneTools.importInternal('util/segmentationUtils');
const segmentationModule = cornerstoneTools.getModule('segmentation');

export default class GFreehandScissorsTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'FreehandScissors',
            strategies: {
                FILL_INSIDE: fillInsideFreehand,
                FILL_OUTSIDE: fillOutsideFreehand,
                ERASE_OUTSIDE: eraseOutsideFreehand,
                ERASE_INSIDE: eraseInsideFreehand,
            },
            cursors: {
                FILL_INSIDE: cursors.freehandFillInsideCursor,
                FILL_OUTSIDE: cursors.freehandFillOutsideCursor,
                ERASE_OUTSIDE: cursors.freehandEraseOutsideCursor,
                ERASE_INSIDE: cursors.freehandEraseInsideCursor,
            },
            defaultStrategy: 'FILL_INSIDE',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            svgCursor: cursors.freehandFillInsideCursor,
            mixins: ['freehandSegmentationMixin'],
        };

        super(props, defaultProps);
    }
}

function fillFreehand(evt: any, operationData: any, inside = true) {
    const { points, segmentationMixinType } = operationData;

    if (segmentationMixinType !== `freehandSegmentationMixin`) {
        console.error(
            `eraseInsideFreehand operation requires freehandSegmentationMixin operationData, recieved ${segmentationMixinType}`
        );

        return;
    }

    // Obtain the bounding box of the entire drawing so that
    // we can subset our search. Outside of the bounding box,
    // everything is outside of the polygon.
    const { image } = evt.detail;
    const vertices = points.map((a: Point) => [a.x, a.y]);
    const [topLeft, bottomRight] = segmentationUtils.getBoundingBoxAroundPolygon(vertices, image);

    inside
        ? fillInsideShape(
              evt,
              operationData,
              (point: Point) => isPointInPolygon([point.x, point.y], vertices),
              topLeft,
              bottomRight
          )
        : fillOutsideShape(
              evt,
              operationData,
              (point: Point) => isPointInPolygon([point.x, point.y], vertices),
              topLeft,
              bottomRight
          );
}

export function fillInsideFreehand(evt: any, operationData: any) {
    fillFreehand(evt, operationData, true);
}

export function fillOutsideFreehand(evt: any, operationData: any) {
    fillFreehand(evt, operationData, false);
}

function fillShape(evt: any, operationData: any, pointInShape: any, topLeft: any, bottomRight: any, insideOrOutside = 'inside') {
    const { pixelData, segmentIndex } = operationData;
    const lockedSegments = segmentationModule?.configuration?.lockedSegments;
    if (pixelData === undefined || segmentIndex === undefined) {
        console.error(`fillInsideShape requires operationData to contain pixelData and segmentIndex`);

        return;
    }

    const { width } = evt.detail.image;
    const [xMin, yMin] = topLeft;
    const [xMax, yMax] = bottomRight;

    if (insideOrOutside === 'outside') {
        segmentationUtils.fillOutsideBoundingBox(evt, operationData, topLeft, bottomRight);
    }

    for (let x = xMin; x < xMax; x++) {
        for (let y = yMin; y < yMax; y++) {
            const pixelIndex = y * width + x;
            const currentIndex = pixelData[pixelIndex];

            // If the pixel is the same segmentIndex and is inside the
            // Region defined by the array of points, set their value to segmentIndex.
            if (
                pointInShape({
                    x,
                    y,
                }) &&
                (currentIndex === 0 || !lockedSegments?.has(currentIndex))
            ) {
                pixelData[pixelIndex] = segmentIndex;
            }
        }
    }
}

export function fillInsideShape(evt: any, operationData: any, pointInShape: any, topLeft: any, bottomRight: any) {
    fillShape(evt, operationData, pointInShape, topLeft, bottomRight, 'inside');
}

export function fillOutsideShape(evt: any, operationData: any, pointInShape: any, topLeft: any, bottomRight: any) {
    fillShape(evt, operationData, (point: Point) => !pointInShape(point), topLeft, bottomRight, 'outside');
}

function eraseFreehand(evt: any, operationData: any, inside = true) {
    const { points, segmentationMixinType } = operationData;

    if (segmentationMixinType !== `freehandSegmentationMixin`) {
        console.error(
            `eraseInsideFreehand operation requires freehandSegmentationMixin operationData, recieved ${segmentationMixinType}`
        );

        return;
    }

    const { image } = evt.detail;
    const vertices = points.map((a: Point) => [a.x, a.y]);
    const [topLeft, bottomRight] = segmentationUtils.getBoundingBoxAroundPolygon(vertices, image);

    inside
        ? segmentationUtils.eraseInsideShape(
              evt,
              operationData,
              (point: Point) => isPointInPolygon([point.x, point.y], vertices),
              topLeft,
              bottomRight
          )
        : segmentationUtils.eraseOutsideShape(
              evt,
              operationData,
              (point: Point) => isPointInPolygon([point.x, point.y], vertices),
              topLeft,
              bottomRight
          );
}

export function eraseInsideFreehand(evt: any, operationData: any) {
    eraseFreehand(evt, operationData, true);
}

export function eraseOutsideFreehand(evt: any, operationData: any) {
    eraseFreehand(evt, operationData, false);
}

export function isPointInPolygon(point: [number, number], vs: any) {
    const x = point[0];
    const y = point[1];
    let inside = false;

    for (let i = 0, j = vs.length - 1; i < vs.length; j = i++) {
        const xi = vs[i][0];
        const yi = vs[i][1];

        const xj = vs[j][0];
        const yj = vs[j][1];

        const intersect = yi > y !== yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;

        if (intersect) {
            inside = !inside;
        }
    }

    return inside;
}

export function eraseIfSegmentIndex(pixelIndex: number, pixelData: any, segmentIndex: number) {
    if (pixelData[pixelIndex] === segmentIndex) {
        pixelData[pixelIndex] = 0;
    }
}

export function isSameSegment(pixelIndex: number, pixelData: any, segmentIndex: number) {
    return pixelData[pixelIndex] === segmentIndex;
}
