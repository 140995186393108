/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useRef } from "react";
import { isNullOrUndefined } from "common/Utils";
import { ChartHelper } from "common/helpers";
import { Dictionary } from "common/entities";
import { createChart, IChartOptions } from "./createChart";
import { IBaseChartProps } from "../entities";

export default function SemiCirclePieChart(props: IGaugeChartProps) {
    const CHART_ID = props.chartID;
    let data = props.data;
    const chartHelperRef = useRef<ChartHelper.PieChart>();

    function setData() {
        if (isNullOrUndefined(data)) {
            data = [];
        }

        if (chartHelperRef.current) {
            chartHelperRef.current.setData(data);

            if (props.chartOptions?.centerLabel?.enabled) {
                chartHelperRef?.current?.centerLabel?.set("text", `[font-size:16px]${props.chartOptions?.centerLabel?.text} 
                \n [font-size:22px bold]${data?.reduce((accu, item) => accu + item?.value, 0)}[/]`);
            }
        }
    }

    useEffect(() => {
        setData();
    }, [data]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(CHART_ID, props.chartOptions);
            setData();
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
    }, []);

    return (
        <>
            <div style={Object.assign({ height: props.height || "100%", width: props.width || "90%" }, props.style)} id={CHART_ID} />
        </>
    );
}



export interface IGaugeChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    height?: number;
    width?: number;
    chartOptions?: IChartOptions;
}