import { APIService, AuthService } from 'common';
import { ISetupConfigurationPayload } from 'pages/Authentication/entities/interfaces';
import { put, select } from 'redux-saga/effects';
import { IBaseAction } from 'redux/entities';
import { updateSetupModal } from '..';
import { selectUser } from '../Selectors';

export function* setupConfigurationSaga({ payload }: IBaseAction<ISetupConfigurationPayload>): any {
    try {
        yield put(updateSetupModal({ visible: true, loading: true }));
        const user = yield select(selectUser);
        yield AuthService.ChangeAdminPassword.call({
            ...payload.adminPassword,
            email: user?.email,
            organization: payload.configurations?.organization,
        });

        if (payload.configurations?.organization) {
            yield APIService.Configurations.UpdateConfigurations.call({
                updates: [{ name: 'organization', value: payload.configurations?.organization }],
            });
        }

        yield put(updateSetupModal({ visible: false, loading: false }));
    } catch (e) {
        console.log(e);
        yield put(updateSetupModal({ visible: true, loading: false }));
    }
}
