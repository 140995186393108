import { Dictionary } from "common";
import { IValidationPlotResponse } from "..";
import { formatValue } from "./payload";

export interface IMostConfusedImagesResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: "image";
        data: Array<IMostConfusedImagesResponseItem>;
    };
}

export interface IMostConfusedImagesResponseItem {
    image_id: string;
    image_name: string;
    img_url: string;
    Loss: number;
    rank: number,
    "Ground Truth": IResultResponse,
    "Prediction": IResultResponse,
    "Meta Data": Dictionary<string>;
}

interface IResultResponse {
    "Class Name": string,
    "Confidence": number;
}

export type MostConfusedImagesData = Array<IMostConfusedImagesItem>;

export interface IMostConfusedImagesItem {
    imageId: string;
    imageName: string;
    imgURL: string;
    loss: number;
    rank: number,
    groundTruth: IResult,
    prediction: IResult,
    metaData: Dictionary<string>;
}

interface IResult {
    className: string,
    confidence: number;
}

export function setMostConfusedImagesData(data: Array<IMostConfusedImagesResponseItem>): MostConfusedImagesData {
    return data?.map(item => ({
        imageId: item.image_id,
        imageName: item.image_name,
        rank: item.rank,
        imgURL: item.img_url,
        loss: formatValue(item.Loss),
        metaData: item["Meta Data"],
        groundTruth: getResultData(item["Ground Truth"]),
        prediction: getResultData(item.Prediction)
    })).sort((a, b) => b.rank - a.rank);
}

function getResultData(data?: IResultResponse): IResult {
    return {
        className: data["Class Name"],
        confidence: formatValue(data.Confidence),
    };
}
export interface IMostConfusedImagesGraphParams {
    predicted_class: number;
    true_class: number;
}
