
import { PropertyName } from "common/entities";
import { _get } from "./_get";

export function _uniq<T extends Array<unknown>>(arr: T): T {
    return [...new Set(arr ?? [])] as T;
}

export function _uniqBy<T>(arr: Array<T>, predicate: ((o: T, k: Map<any, any>, arr: Array<T>) => any) | string | keyof T | Array<PropertyName>): Array<T> {
    if (!arr || !predicate) return arr ?? [];
    const cb = typeof predicate === 'function' ? predicate : (o: T) => _get(o, predicate as any);

    return [...arr.reduce((map, item) => {
        const key = (item === null || item === undefined) ?
            item : cb(item, map, arr);

        map.has(key) || map.set(key, item);

        return map;
    }, new Map()).values()];
}