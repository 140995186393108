import { List } from 'antd';
import cs from 'classnames';
import { GImageSkeletonItem } from 'components/GSkeleton/GImageSkeleton';
import GTooltip from 'components/GTooltip';
import { useDatasetData } from 'redux/selectors';
import SelectionComponent from './SelectionComponent';
import Styles from './Styles.module.scss';
import Thumbnail from './Thumbnail';
import { IDatasetExplorerItem } from './entities';

export default function ListItem({
    id,
    name = id,
    thumbnail,
    datasetId,
    loading,
    onClick,
    selection,
    selectedImages,
}: IListItemProps) {
    const dataset = useDatasetData(datasetId);

    if (loading) {
        return <GImageSkeletonItem active={loading} />;
    }

    return (
        <List.Item>
            <div
                className={cs(Styles.ImageContainer, { [Styles.StackedThumbnail]: thumbnail?.length > 1 })}
                onClick={() => onClick?.(id)}
            >
                {thumbnail?.map((item, i) => (
                    <Thumbnail
                        key={i}
                        datasetId={item.datasetId}
                        windowLevel={dataset?.defaultWindowLevel}
                        style={{ top: (2 - i) * 7 }}
                        {...item}
                    />
                ))}
                <SelectionComponent
                    selection={selection}
                    checked={selectedImages?.includes(id)}
                />
            </div>

            <GTooltip title={name}>
                <div className="pBody-medium white truncate">{name}</div>
            </GTooltip>
        </List.Item>
    );
}

export interface IListItemProps extends IDatasetExplorerItem {
    datasetId: string;
    loading?: boolean;
    onClick?: (id: string) => void;
    selection?: boolean | 'checkbox' | 'radio';
    selectedImages?: Array<string>;
}

