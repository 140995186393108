import { Button } from 'antd';
import cx from 'classnames';
import { getMessages } from 'common/helpers';
import GDropdown from 'components/GDropdown';
import { FilterNewDesign } from 'components/assets/icons';
import { useState } from 'react';
import { IBooleanFilterItem, ICategoricalFilterItem } from '../../entities';
import BooleanFilterItem from './BooleanFilterItem';
import CategoricalFilterItem from './CategoricalFilterItem';
import SearchItem, { ISearchOption } from './SearchItem';
import Styles from './Styles.module.scss';

export default function FilterPanel({
    onChangeFilter,
    searchValue,
    searchOptions,
    booleanFilterList,
    categoricalFilterList,
    title,
    searchOptionsWithHighlight,
}: IFilterPanelProps) {
    const [open, setOpen] = useState(false);
    let booleanMenu: Array<IOption> = booleanFilterList?.map(item => {
        return {
            key: item.name,
            render: (
                <BooleanFilterItem
                    {...item}
                    onChangeFilter={onChangeFilter}
                />
            ),
            disabled: false,
        };
    });

    let categoricalMenu: Array<IOption> = categoricalFilterList?.map(item => {
        return {
            key: item.name,
            render: (
                <CategoricalFilterItem
                    {...item}
                    onChangeFilter={onChangeFilter}
                />
            ),
            disabled: false,
        };
    });

    const menu = categoricalMenu.concat(booleanMenu);

    return (
        <div className={Styles.FilterSectionContainer}>
            <div className={cx(Styles.Title, 'h3-bold')}>{title}</div>
            <div className={cx(Styles.Desc, 'subtitle2-normal')}>{getMessages('000620')} </div>
            <div className={Styles.Container}>
                <SearchItem
                    onChangeFilter={onChangeFilter}
                    searchValue={searchValue}
                    searchOptions={searchOptions}
                    searchOptionsWithHighlight={searchOptionsWithHighlight}
                />
                {menu?.length === 0 ? null : (
                    <GDropdown
                        menuItemList={menu}
                        overlayClassName={Styles.DetailsMenuOverlay}
                        className={Styles.DetailsMenuDropdown}
                        id="hide-public-dropdown"
                        placement="bottomRight"
                        open={open}
                        onOpenChange={setOpen}
                        trigger={['click']}
                    >
                        <Button
                            className={Styles.DetailsMenuButton}
                            icon={<FilterNewDesign />}
                        />
                    </GDropdown>
                )}
            </div>
        </div>
    );
}

export interface IFilterPanelProps {
    title: string;
    onChangeFilter: (key: string, value: any) => void;
    searchValue: string;
    searchOptions: Array<ISearchOption>;
    categoricalFilterList?: Array<ICategoricalFilterItem>;
    booleanFilterList?: Array<IBooleanFilterItem>;
    searchOptionsWithHighlight?: Array<ISearchOption> | undefined;
}

export { BooleanFilterItem, CategoricalFilterItem, SearchItem };

interface IOption {
    key: string;
    render: React.ReactNode;
    disabled?: boolean;
}
