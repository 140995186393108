import * as am5 from "@amcharts/amcharts5";
import * as am5percent from "@amcharts/amcharts5/percent";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark';

import { Dictionary } from 'common';
import { IChartHelperArgs, ICreateChartArgs, ICreateSeriesArgs } from './entities/entities';

export class PieChart {
    root: am5.Root;
    chart: am5percent.PieChart;
    data: Array<any> = [];
    defaultTheme: am5.Theme;
    series: am5percent.PieSeries;
    legend?: am5.Legend;
    centerLabel?: am5.Label;

    createRoot(args: IChartHelperArgs) {
        this.root = am5.Root.new(args.chartID);

        this.defaultTheme = am5.Theme.new(this.root);
        this.defaultTheme.rule('ColorSet').set(
            'colors',
            this.colorPalette.map(color => am5.color(color))
        );

        if (args?.lightTheme) {
            this.root.setThemes([am5themes_Animated.new(this.root), this.defaultTheme]);
        } else {
            this.root.setThemes([am5themes_Animated.new(this.root), am5themes_Dark.new(this.root), this.defaultTheme]);
        }
    }

    createChart({ settings = {} }: ICreateChartArgs<am5percent.IPieChartSettings>, CB?: (chart?: am5percent.PieChart) => void) {
        this.chart = this.root.container.children.push(
            am5percent.PieChart.new(this.root, {
                layout: this.root.verticalLayout,
                ...settings,
            })
        );

        CB?.(this.chart);

        return this;
    }

    createSeries(
        { type, seriesSettings, enableTooltip, legend }: ICreateSeriesArgs<am5percent.IPieSeriesSettings>,
        CB?: (series: am5percent.PieSeries) => void
    ) {
        this.series = this.chart.series.push(
            am5percent.PieSeries.new(this.root, {
                ...seriesSettings,
            })
        );

        if (legend?.enabled) {
            this.legend = this.chart.children.push(
                am5.Legend.new(this.root, {
                    marginBottom: 15,
                    height: am5.percent(70),
                    width: am5.percent(75),
                    verticalScrollbar: am5.Scrollbar.new(this.root, {
                        orientation: 'vertical',
                    }),
                    ...legend.legendSettings,
                })
            );

            this.legend.markers.template.setAll({
                width: 12,
                height: 12,
            });

            this.legend.markerRectangles.template.setAll({
                cornerRadiusTL: 10,
                cornerRadiusTR: 10,
                cornerRadiusBL: 10,
                cornerRadiusBR: 10,
            });

            this.legend.itemContainers.template.setAll({
                visible: true,
                marginTop: 0,
            });
        }

        this.series.slices.template.set('tooltipText', '{category}: {value}');

        this.series.data.setAll(this.data);

        this.legend?.data?.setAll(this.chart.series.values);

        CB?.(this.series);
        return this;
    }

    setData(data: Array<Dictionary<any>>) {
        this.data = data;

        this.series?.appear();
        this.series?.data?.setAll(this.data);
        this.legend?.data?.setAll(this.series.dataItems);
    }

    colorPalette = [
        '#00FFA7',
        '#24D9D9',
        '#A4BF00',
        '#FFB800',
        '#FF8A00',
        '#EE5843',
        '#F9748C',
        '#FFACBB',
        '#DB78D1',
        '#E7A5FF',
        '#8819CC',
        '#6819CC',
        '#5A00FF',
        '#4968D4',
        '#0085FF',
        '#6597C6',
    ];
}