import LoadingOutlined from '@ant-design/icons/LoadingOutlined';
import { Spin, SpinProps } from 'antd';
import cx from 'classnames';
import React from 'react';
import Styles from './Styles.module.scss';
interface GLoadingProps extends SpinProps {
    children?: React.ReactNode;
    loading?: boolean;
    className?: string;
}

const loadingIcon = (
    <LoadingOutlined
        style={{ fontSize: 24 }}
        spin
    />
);

export default function GLoading({ children, loading = false, style, className }: GLoadingProps) {
    return (
        <Spin
            style={style}
            size="large"
            spinning={loading}
            tip="Loading..."
            indicator={loadingIcon}
            wrapperClassName={cx(Styles.Spin, 'g-loading-wrapper', className, { loading })}
        >
            {children}
        </Spin>
    );
}
