import { ChartHelper } from 'common/helpers';
import { AxesType, SeriesType } from 'common/helpers/ChartHelper/entities/enums';
import * as am5 from "@amcharts/amcharts5";
import { LineSeries } from '@amcharts/amcharts5/xy';
import * as am5xy from "@amcharts/amcharts5/xy";

export function createChart(chartID: string, chartOptions?: IChartOptions) {
    const chartHelper = new ChartHelper.XYChart();

    chartHelper.createRoot({ chartID });

    chartHelper
        .createChart({})
        .createAxes({
            xAxis: {
                type: AxesType.category,
                categoryAxisOptions: {
                    categoryField: chartOptions?.xAxis?.fieldName ?? "name",
                },
                rendererSetting: {
                    minGridDistance: 50,
                }
            },
            yAxis: {
                type: AxesType.value, rendererSetting: {},
                valueAxisOptions: {
                    min: chartOptions?.yAxis?.min,
                    max: chartOptions?.yAxis?.max,
                },
            }
        }, (xAxis: am5xy.DateAxis<am5xy.AxisRenderer>, yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>) => {
            yAxis.get("renderer").grid.template.setAll({
                stroke: am5.color("#666C75"),
                strokeDasharray: 0,
                strokeWidth: 1
            });
            xAxis.get("renderer").grid.template.setAll({
                stroke: am5.color("#666C75"),
                strokeDasharray: 0,
                strokeWidth: 1
            });
        });

    chartHelper.createSeries({
        type: SeriesType.LineSeries,
        seriesSettings: {
            name: chartID,
            valueYField: chartOptions?.yAxis?.fieldName ?? "value",
            categoryXField: chartOptions?.xAxis?.fieldName ?? "name",
            valueField: chartOptions?.yAxis?.fieldName || "value",
            fill: am5.color("#34C5F3"),
            stroke: am5.color("#34C5F3"),
        },
    }, (series: LineSeries) => {
        series.strokes.template.setAll({
            strokeWidth: 1,
        });
        series.fills.template.setAll({
            fillOpacity: 0.5,
            visible: true,
            fillGradient: am5.LinearGradient.new(chartHelper.root, {
                stops: [{ opacity: 0.3 }, { opacity: 0 }],
            })
        });
    });

    return chartHelper;
}

export interface IChartOptions {
    tooltipEnabled?: boolean;
    xAxis?: {
        fieldName?: string;
    };
    yAxis?: {
        fieldName?: string;
        min: number;
        max: number;
    };
    series?: {
        fill?: string;
        stroke?: string;
        name?: string;
    };
}