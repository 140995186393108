import { getMessages } from 'common/helpers';
import GAutoComplete from 'components/GAutoComplete';
import Styles from './Styles.module.scss';

export default function SearchItem({ onChangeFilter, searchValue, searchOptions, searchOptionsWithHighlight }: IProps) {
    return (
        <>
            <GAutoComplete
                placeholder={getMessages('000304')}
                allowClear
                style={{ width: 240 }}
                className={Styles.Search}
                value={searchValue}
                options={
                    searchOptionsWithHighlight && searchOptionsWithHighlight?.length !== 0
                        ? searchOptionsWithHighlight
                        : searchOptions
                }
                id="filter-panel-searchbox"
                onChange={(value: string) => {
                    onChangeFilter('search', value ?? '');
                }}
                onSelect={(value: string, option: ISearchOption) => {
                    onChangeFilter('search', option?.title);
                }}
            />
        </>
    );
}

export interface ISearchOption {
    value: string;
    label: any;
    title?: string;
    description?: string;
    tags?: string;
    modality?: string;
    problemType?: string;
}

interface IProps {
    onChangeFilter: (key: string, value: any) => void;
    searchValue: string;
    searchOptions: Array<ISearchOption>;
    searchOptionsWithHighlight?: Array<ISearchOption> | undefined;
}
