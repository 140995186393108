import { updateCommonState } from 'app/store';
import { unsubscribeDB } from 'app/store/actions';
import { useEventBus, useTrackSession } from 'common';
import { appStartTransaction } from 'pages/Authentication/store/actions';
import { useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import 'react-toastify/dist/ReactToastify.css';
import { useCheckProject } from './useCheckProject';
import { useHighlight } from './useHighlight';
import { useNetworkStatus } from './useNetworkStatus';

export function useSubscribeEvents() {
    const dispatch = useDispatch();

    useNetworkStatus();
    useCheckProject();
    useHighlight();
    useTrackSession();

    const onLogout = useCallback(() => {
        if (!sessionStorage.getItem('accessToken')) return;

        dispatch(updateCommonState({ sessionReminderVisible: true }));
    }, [dispatch]);

    useEventBus('forceLogout', onLogout);
    useEventBus('dispatch', dispatch);

    useEffect(() => {
        dispatch(appStartTransaction());

        return () => {
            dispatch(unsubscribeDB());
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}
