import { Dictionary, getMessages, ProblemType } from 'common';

export interface IValidationSummaryState {
    excludedTables: Array<string>
    excludedMetadatas: Array<any>;
    exportModalVisible: boolean;
    pdfLoading: boolean;
    selectedMultipleMetadataRound: string;
    landscapeViewEnabled: boolean;
    columnFilterModalOpen: boolean;
    selectedColumns: Dictionary;
    selectedTable: string;
    settingsParams: Dictionary;
    defaultTables: Dictionary;
}

export function IValidationSummaryStateFactory(data?: Partial<IValidationSummaryState>): IValidationSummaryState {
    return {
        excludedTables: data?.excludedTables || [],
        excludedMetadatas: data?.excludedMetadatas || [],
        exportModalVisible: data?.exportModalVisible ?? false,
        pdfLoading: data?.pdfLoading ?? false,
        selectedMultipleMetadataRound: data?.selectedMultipleMetadataRound || null,
        landscapeViewEnabled: data?.landscapeViewEnabled || false,
        columnFilterModalOpen: data?.columnFilterModalOpen || false,
        selectedColumns: data?.selectedColumns || defaultHidedColumns,
        selectedTable: data?.selectedTable || "",
        settingsParams: data?.settingsParams || defaultSettingsParams,
        defaultTables: data?.defaultTables || defaultTables,
    };
}

export const defaultTables = {
    ClassComparison: getMessages("000991"),
    ClassComparisonOverall: getMessages("001378"),
    CohortSizeAnalysisLargest: getMessages("001301"),
    CohortSizeAnalysisSmallest: getMessages("001308"),
    MetadataBestComparison: getMessages("001390"),
    MetadataWorstComparison: getMessages("001391"),
    MetadataMultipleBestComparison: getMessages("001388"),
    MetadataMultipleWorstComparison: getMessages("001389"),
    NumberOfSubcohortGroups: getMessages("001372"),
    // PatientDemographicsComparison: getMessages("000992"),
    // ImagingComparison: getMessages("000993"),
    // PatientCharacteristics: getMessages("000989"),
    // ImagingCharacteristics: getMessages("000990"),
};



export interface ISummaryTablePropsProps {
    noStyle?: boolean
}

export const defaultHidedColumns: Dictionary = {
    [ProblemType.classification]: {
        PatientCharacteristics: [],
        ImagingCharacteristics: [],
        ClassComparison: [],
        ClassComparisonOverall: ["Accuracy", "Diversity Index", "Macro AUC", "Macro Sensitivity", "Micro Sensitivity", "Macro Specificity", "Micro Specificity", "Sample Size", "Matthews C. C"],
        PatientDemographicsComparison: ["Sample Size", "Micro Specificity", "Micro Sensitivity", "Macro Specificity", "Macro Sensitivity", "Micro Precision", "Macro Precision", "Micro F1", "Macro F1", "Matthews C. C", "Diversity Index"],
        ImagingComparison: ["Sample Size", "Micro Specificity", "Micro Sensitivity", "Macro Specificity", "Macro Sensitivity", "Micro Precision", "Macro Precision", "Micro F1", "Macro F1", "Matthews C. C", "Diversity Index"],
        CohortSizeAnalysisLargest: [],
        CohortSizeAnalysisSmallest: [],
        MetadataBestComparison: ["Micro Specificity", "Micro Sensitivity", "Macro Specificity", "Macro Sensitivity", "Micro Precision", "Macro Precision", "Micro F1", "Macro F1", "Matthews C. C", "Diversity Index"],
        MetadataWorstComparison: ["Micro Specificity", "Micro Sensitivity", "Macro Specificity", "Macro Sensitivity", "Micro Precision", "Macro Precision", "Micro F1", "Macro F1", "Matthews C. C", "Diversity Index"],
        MetadataMultipleBestComparison: ["Micro Specificity", "Micro Sensitivity", "Macro Specificity", "Macro Sensitivity", "Micro Precision", "Macro Precision", "Micro F1", "Macro F1", "Matthews C. C", "Diversity Index"],
        MetadataMultipleWorstComparison: ["Micro Specificity", "Micro Sensitivity", "Macro Specificity", "Macro Sensitivity", "Micro Precision", "Macro Precision", "Micro F1", "Macro F1", "Matthews C. C", "Diversity Index"],
        NumberOfSubcohortGroups: [],
    },
    [ProblemType.segmentation]: {
        PatientCharacteristics: [],
        ImagingCharacteristics: [],
        ClassComparison: [],
        ClassComparisonOverall: [],
        PatientDemographicsComparison: [],
        ImagingComparison: [],
        CohortSizeAnalysisLargest: [],
        CohortSizeAnalysisSmallest: [],
        MetadataBestComparison: [],
        MetadataWorstComparison: [],
        MetadataMultipleBestComparison: [],
        MetadataMultipleWorstComparison: [],
        NumberOfSubcohortGroups: [],
    },
    [ProblemType.object_detection]: {
        PatientCharacteristics: [],
        ImagingCharacteristics: [],
        ClassComparison: [],
        ClassComparisonOverall: [],
        PatientDemographicsComparison: [],
        ImagingComparison: [],
        CohortSizeAnalysisLargest: [],
        CohortSizeAnalysisSmallest: [],
        MetadataBestComparison: [],
        MetadataWorstComparison: [],
        MetadataMultipleBestComparison: [],
        MetadataMultipleWorstComparison: [],
        NumberOfSubcohortGroups: [],
    },
    [ProblemType.instance_segmentation]: {
        PatientCharacteristics: [],
        ImagingCharacteristics: [],
        ClassComparison: [],
        ClassComparisonOverall: [],
        PatientDemographicsComparison: [],
        ImagingComparison: [],
        CohortSizeAnalysisLargest: [],
        CohortSizeAnalysisSmallest: [],
        MetadataBestComparison: [],
        MetadataWorstComparison: [],
        MetadataMultipleBestComparison: [],
        MetadataMultipleWorstComparison: [],
        NumberOfSubcohortGroups: [],
    },
};

export const defaultSettingsParams = {
    diversityIndexThreshold: 0.5,
    enableDiversityIndexThreshold: false,
    hideEmptyTables: false,
}