import { APIService } from "common";
import { updateBlindSpotAnalysisState } from "pages/Validation/store";
import { getBlindSpotTrackingData } from "pages/Validation/views/validationAnalysis/components/blindSpotAnalysis/entities";
import { put } from "redux-saga/effects";

export function* getBlindSpotTrackingListSaga(): any {
    try {
        const response = yield APIService.Validation.GetBlindSpotAnalysisTrackingList.call();

        const payload = response.data.map(getBlindSpotTrackingData) ?? [];

        yield put(updateBlindSpotAnalysisState({ blindSpotAnalysisTrackingList: payload }));

        return payload;
    } catch (error) {
        console.log(error);
    }
}