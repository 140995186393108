import { DeploymentStatus, ProblemType } from 'common';
import { IStudyJob } from '../../externalValidation/entities';
import { IBlindSpotAnalysisResult } from '../../validationAnalysis/components/blindSpotAnalysis/entities';
import { IBlindSpotTracking } from '../../validationAnalysis/components/blindSpotAnalysis/entities/IBlindSpotTracking';

interface IValidationRun {
    validationId: string;
    problemType: ProblemType;
    anatomy: string;
    modality: string;
    tags: Array<string>;
    createdTimestamp: number;
    finishedTimestamp: number;
    datasetName: string;
    sampleSize: number;
    hospitalName: string;
    modelName: string;
    modelSummary: string;
    modelStatus: DeploymentStatus;
    validationStatus: ValidationStatus;
    progress: number;
    metrics: any;
    name: string;
    modelId: string;
    datasetId: string;
    generateMetrics: boolean;
    sampleCounts: ISampleCount;
    archived: boolean;
    deploymentId: string;
    userId: string;
    userEmail: string;
    accessOptions?: any;
    parentBatchJobId?: string;
    subCohortProperties?: IBlindSpotAnalysisResult;
    blindSpotAnalysis?: IBlindSpotTracking;
    metadataId?: string;
    annotationId?: string;
    studyJobs?: Array<IStudyJob>;
    favorited?: boolean;
    robustValidation?: boolean;
}

interface IMetrics {
    name: string;
    value: number;
}

export interface ISampleCount {
    total: number;
    done: number;
    failed: number;
}

export enum ValidationStatus {
    done = 'DONE',
    batch_done = 'BATCH_DONE',
    batch_failed = 'BATCH_FAILED',
    batch_partial = 'BATCH_DONE(PARTIAL)',
    batch_inprogress = 'BATCH_INPROGRESS',
    halt = 'HALTED',
    validation_done = 'DONE',
    validation_failed = 'VAL_FAILED',
}

export type { IMetrics, IValidationRun };
