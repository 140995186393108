import { PayloadAction } from "@reduxjs/toolkit";
import { Dictionary, isNullOrUndefined, _entries } from "common";
import { WritableDraft } from "immer/dist/internal";
import { IValidationState } from "pages/Validation/entities";
import { IChartState, IImagePreviewControls, IMetricsExtra } from "../entities";
import { ChartKey, ChartStateFactory, ILayoutState } from "../entities/Charts/IChartState";
import { ICommon } from "../entities/ICommon";

function setMetricsDataToInitial(state: WritableDraft<IValidationState>,) {
    const initialState = ChartStateFactory();

    _entries(initialState).forEach(([key, value]: [ChartKey, any]) => {
        state.metrics.validationMetrics[key] = value;
    });
}

function setMetricsChartData(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IChartState>>) {
    _entries(payload).forEach(([key, value]: [ChartKey, any]) => {

        if (state.metrics.validationMetrics[key]) {
            state.metrics.validationMetrics[key].data = value;
        }
    });
}

function setMetricsChartDefaultData(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IChartState>>) {
    _entries(payload).forEach(([key, value]: [ChartKey, any]) => {

        if (state.metrics.validationMetrics[key]) {
            state.metrics.validationMetrics[key].defaultData = value;
        }
    });
}

function setMetricsChartGraphParams(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<Record<ChartKey, Dictionary<any>>>>) {
    _entries(payload).forEach(([key, value]: [ChartKey, Dictionary<any>]) => {
        if (state.metrics.validationMetrics[key]) {
            state.metrics.validationMetrics[key].graphParams = value;
        }
    });
}

function setMetricsChartDefaultParams(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<Record<ChartKey, Dictionary<any>>>>) {
    _entries(payload).forEach(([key, value]: [ChartKey, Dictionary<any>]) => {
        if (state.metrics.validationMetrics[key]) {
            state.metrics.validationMetrics[key].defaultParams = value;
        }
    });
}

function setMetricsImagePreviewControls(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<IImagePreviewControls>>) {
    if (!isNullOrUndefined(payload.previewVisible)) {
        state.metrics.imagePreviewControls.previewVisible = payload.previewVisible;
    }

    if (!isNullOrUndefined(payload.navigationEnabled)) {
        state.metrics.imagePreviewControls.navigationEnabled = payload.navigationEnabled;
    }

    if (!isNullOrUndefined(payload.selectedImageId)) {
        state.metrics.imagePreviewControls.selectedImageId = payload.selectedImageId;
    }

    if (!isNullOrUndefined(payload.activeSection)) {
        state.metrics.imagePreviewControls.activeSection = payload.activeSection;
    }
}

function setMetricsLayoutState(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<Record<ChartKey, ILayoutState>>>) {
    _entries(payload).forEach(([key, value]: [ChartKey, ILayoutState]) => {
        if (state.metrics.validationMetrics[key]) {
            state.metrics.validationMetrics[key].layoutState = value;
        }
    });
}

function setMetricsExtraData(state: WritableDraft<IValidationState>, { payload }: PayloadAction<IMetricsExtra>) {
    state.metrics.metricsExtra = payload;
}

function updateMetricsCommonState(state: WritableDraft<IValidationState>, { payload }: PayloadAction<Partial<ICommon>>) {
    Object.assign(state.metrics.common, payload);
}

function updateHidedChartList(state: WritableDraft<IValidationState>, { payload }: PayloadAction<any>) {
    state.metrics.views.hidedChartList = payload;
}

function updateValidationMetricsViewState(state: WritableDraft<IValidationState>, { payload }: PayloadAction<any>) {
    Object.assign(state.metrics.views, payload);
}

export const MetricsReducer = {
    setMetricsDataToInitial,
    setMetricsChartData,
    setMetricsChartGraphParams,
    setMetricsChartDefaultParams,
    setMetricsImagePreviewControls,
    setMetricsLayoutState,
    setMetricsExtraData,
    updateMetricsCommonState,
    updateHidedChartList,
    updateValidationMetricsViewState,
    setMetricsChartDefaultData
};