import PlusOutlined from '@ant-design/icons/PlusOutlined';
import { Input, InputProps, InputRef, Tag } from 'antd';
import cx from 'classnames';
import { getMessages } from 'common/helpers';
import GTooltip from 'components/GTooltip';
import React, { useRef } from 'react';
import Styles from './Styles.module.scss';
import { useTagEditor } from './useTagEditor';

export default function GTagEditor({
    inputProps = {},
    isMapTags,
    tagPrefix,
    tagList,
    className,
    id,
    onChange,
    placeHolder,
    editingEnabled = true,
}: GTagEditorProps) {
    const {
        editInputIndex,
        editInputValue,
        handleCancelEdit,
        handleDelete,
        handleEditInputChange,
        handleEditInputConfirm,
        handleInputChange,
        handleInputConfirm,
        inputValue,
        inputVisible,
        showInput,
        setEditInputIndex,
        setEditInputValue,
    } = useTagEditor({ onChange, tagList });
    const inputRef = useRef<InputRef>();
    const editInputRef = useRef<InputRef>();

    function renderTagElement(tag: string, index: number) {
        if (editInputIndex === index) {
            return (
                <Input
                    ref={editInputRef}
                    key={tag}
                    size="small"
                    className="tag-input"
                    value={editInputValue}
                    onChange={handleEditInputChange}
                    onBlur={handleEditInputConfirm}
                    onPressEnter={handleEditInputConfirm}
                    onKeyDown={e => handleCancelEdit(e.key)}
                    autoFocus
                    {...inputProps}
                />
            );
        }
        const isLongTag = tag?.length > 20;
        let tagText = isLongTag ? `${tag.slice(0, 20)}...` : tag;
        if (isMapTags) {
            tagText = `${index} - ${tagText}`;
        }
        const tagElem = (
            <Tag
                key={tag}
                closable = {editingEnabled}
                onClose={() => handleDelete(tag)}
                className="caption2-normal"
                tabIndex={index}
            >
                {tagPrefix?.(tag, index)}
                <span
                    onDoubleClick={e => {
                        setEditInputIndex(index);
                        setEditInputValue(tag);
                        e.preventDefault();
                    }}
                >
                    {tagText}
                </span>
            </Tag>
        );

        return isLongTag ? (
            <GTooltip
                title={tag}
                key={tag}
            >
                {tagElem}
            </GTooltip>
        ) : (
            tagElem
        );
    }

    function renderAddNewTag() {
        return inputVisible ? (
            <Input
                autoFocus
                ref={inputRef}
                type="text"
                size="small"
                className="tag-input"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputConfirm}
                onPressEnter={handleInputConfirm}
                onKeyDown={e => handleCancelEdit(e.key)}
                {...inputProps}
            />
        ) : (
            <Tag
                className="caption2-normal new-tag"
                onClick={showInput}
                tabIndex={tagList?.length || 0}
                onKeyDown={e => e.key === 'Enter' && showInput()}
            >
                <PlusOutlined /> {placeHolder || getMessages('000362')}
            </Tag>
        );
    }

    return (
        <div
            className={cx(Styles.TagsContainer, className)}
            id={id}
        >
            {tagList?.map(renderTagElement)}
            {editingEnabled && renderAddNewTag()}
        </div>
    );
}

interface GTagEditorProps {
    className?: string;
    tagList?: Array<string>;
    onChange?: (key: Array<string>) => void;
    editingEnabled?: boolean;
    placeHolder?: string;
    isMapTags?: boolean;
    inputProps?: InputProps;
    id?: string;
    tagPrefix?: (tag: string, index: number) => React.ReactNode;
}
