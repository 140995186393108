export function toggleFullScreen(show: boolean) {
    if (show) {
        const el: any = document.documentElement;
        if (!el) return;
        el.requestFullscreen?.();
        el.mozRequestFullScreen?.();
        el.webkitRequestFullscreen?.();
        el.msRequestFullscreen?.();
        el.webkitEnterFullscreen?.();
    } else {
        document.exitFullscreen?.();
        (document as any).webkitExitFullscreen?.();
        (document as any).mozCancelFullScreen?.();
        (document as any).msExitFullscreen?.();
    }
}