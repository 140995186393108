import { Dictionary } from "common";
import { IValidationPlotResponse } from "..";
import { formatValue } from "./payload";

export interface IExplorePredictionsResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: "image";
        data: Array<IExplorePredictionsResponseItem>;
    };
}

export interface IExplorePredictionsResponseItem {
    image_id: string;
    image_name: string;
    img_url: string;
    rank: number,
    Prediction: string,
    Confidence: number,
    "Meta Data": Dictionary<string>;
}

export type ExplorePredictionsData = Array<IExplorePredictionItem>;

export interface IExplorePredictionItem {
    imageId: string;
    imageName: string;
    imgURL: string;
    rank: number,
    prediction: string,
    confidence: number;
    metaData: Dictionary<string>;
}

export function setExplorePredictionsData(data: Array<IExplorePredictionsResponseItem>): ExplorePredictionsData {
    return data?.map(item => ({
        imageId: item.image_id,
        imageName: item.image_name,
        rank: item.rank,
        imgURL: item.img_url,
        metaData: item["Meta Data"],
        prediction: item.Prediction,
        confidence: formatValue(item.Confidence)
    })).sort((a, b) => a.rank - b.rank);
}

export interface IExplorePredictionGraphParams {
    predicted_class: number;
    true_class: number;
}
