import { Breadcrumb, BreadcrumbProps } from 'antd';
import cx from 'classnames';
import Styles from './Styles.module.scss';

interface IBreadCrumbItem {
    title: string;
    href?: string;
}

interface GBreadcrumbProps extends BreadcrumbProps {
    items: Array<IBreadCrumbItem>;
}

export default function GBreadcrumb({ items, className, ...props }: GBreadcrumbProps) {
    return (
        <Breadcrumb
            separator=">"
            className={cx(Styles.Breadcrumb, className)}
            {...props}
        >
            {items.map((item, index) => (
                <Breadcrumb.Item
                    href={item?.href}
                    key={index}
                >
                    {item.title}
                </Breadcrumb.Item>
            ))}
        </Breadcrumb>
    );
}
