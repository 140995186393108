export enum ChartType {
    XYChart = "XYChart",
    PieChart = "PieChart",
}

export enum AxesType {
    value = "value",
    category = "category",
    date = "date",
    gaplessDate = "gaplessDate",
}

export enum SeriesType {
    ColumnSeries = "ColumnSeries",
    LineSeries = "LineSeries",
    PieSeries = "PieSeries",
    HieararchySeries = "HieararchySeries",
    CandlestickSeries = "CandlestickSeries",
    StepLineSeries = "StepLineSeries",
}