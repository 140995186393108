import { isNullOrUndefined } from './isNullOrUndefined';

export function _isEmpty(...args: Array<any>): boolean {
    if (args.length === 0) return true;
    return args?.some(arg => isEmptySingle(arg));
}

function isEmptySingle(value: any) {
    if (isNullOrUndefined(value) || value === '') return true;

    if (Array.isArray(value)) return value.filter(item => !_isEmpty(item)).length === 0;

    if (typeof value === 'object') return Object.keys(value).length === 0;

    return false;
}
