import { IValidationPlotResponse } from "..";
import { formatValue } from "./payload";

export interface IBasicModelStatsResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: string;
        data: IBasicModelStatsResponseData;
    };
}

export interface IBasicModelStatsResponseData {
    Validation: {
        [type: string]: IBasicModelStatsResponseItem,
    },
    Training: {
        [type: string]: IBasicModelStatsResponseItem,
    };
}

export interface IBasicModelStatsResponseItem {
    Accuracy: number,
    "False Positive": number;
    "True Positive": number;
    "False Negative": number;
    "True Negative": number;
    "Sensitivity (TPR)": number;
    "Specificity (TNR)": number;
    Precision: number;
    "Negative Predictive Value": number;
    "False Positive Rate": number;
    "False Negative Rate": number;
    F1: number;
    IoU: number;
    "Dice Loss": number;
    "11-points interpolation": number;
    "mAP@.50": number;
    "mAP@.75": number;
    "mAP@[.50,.95]": number;
    "AP@10": number,
    "AP@50": number,
    "AP@75": number,
    "AP@[.50,.95]": number;
    AUC: number;
    "AR@max=100": number;
    "AR@max=10": number;
    "AR@max=1": number;
    "mean IoU": number;
    pAccs: number;
    fwIoU: number;
    Dice: number;
    "mean Sensitivity": number;
    "mean Specificity": number;
    "mean AUC": number;
    "mean Kappa": number;
}

export interface IBasicModelStatsItem {
    dataType: string,
    accuracy: string,
    falsePositive: number;
    truePositive: number;
    falseNegative: number;
    trueNegative: number;
    sensitivityTPR: number;
    specificityTNR: number;
    precision: number;
    negativePredictiveValue: number;
    falsePositiveRate: number;
    falseNegativeRate: number;
    f1: number;
    iou: number;
    diceLoss: number;
    interpolation: number;
    mAP50: number;
    mAP75: number;
    mAP95: number;
    "AP@10": number,
    "AP@50": number,
    "AP@75": number,
    "AP@[.50,.95]": number;
    AUC: number;
    "AR@max=100": number;
    "AR@max=10": number;
    "AR@max=1": number;
    meanIoU: number;
    pAccs: number;
    fwIoU: number;
    dice: number;
    meanSensitivity: number;
    meanSpecificity: number;
    meanAUC: number;
    meanKappa: number;
}

export type BasicModelStatsData = Array<IBasicModelStatsItem>;

export interface IBasicModelStatsData {
    Validation: BasicModelStatsData;
    Training?: BasicModelStatsData;
}

const getBasicModelStatsItem = (key: string, item: IBasicModelStatsResponseItem): IBasicModelStatsItem => ({
    dataType: key,
    accuracy: `${(item.Accuracy * 100)?.toFixed?.(0)}%`,
    falsePositive: formatValue(item["False Positive"]),
    truePositive: formatValue(item["True Positive"]),
    falseNegative: formatValue(item["False Negative"]),
    trueNegative: formatValue(item["True Negative"]),
    sensitivityTPR: formatValue(item["Sensitivity (TPR)"]),
    specificityTNR: formatValue(item["Specificity (TNR)"]),
    precision: formatValue(item.Precision),
    negativePredictiveValue: formatValue(item["Negative Predictive Value"]),
    falsePositiveRate: formatValue(item["False Positive Rate"]),
    falseNegativeRate: formatValue(item["False Negative Rate"]),
    f1: formatValue(item.F1),
    iou: formatValue(item.IoU),
    diceLoss: formatValue(item["Dice Loss"]),
    interpolation: formatValue(item["11-points interpolation"]),
    mAP50: formatValue(item["mAP@.50"]),
    mAP75: formatValue(item["mAP@.75"]),
    mAP95: formatValue(item["mAP@[.50,.95]"]),
    "AP@10": formatValue(item["AP@10"]),
    "AP@50": formatValue(item["AP@50"]),
    "AP@75": formatValue(item["AP@75"]),
    "AP@[.50,.95]": formatValue(item["AP@[.50,.95]"]),
    AUC: formatValue(item.AUC),
    "AR@max=100": formatValue(item["AR@max=100"]),
    "AR@max=10": formatValue(item["AR@max=10"]),
    "AR@max=1": formatValue(item["AR@max=1"]),
    meanIoU: formatValue(item["mean IoU"]),
    pAccs: formatValue(item.pAccs),
    fwIoU: formatValue(item.fwIoU),
    dice: formatValue(item.Dice),
    meanSensitivity: formatValue(item["mean Sensitivity"]),
    meanSpecificity: formatValue(item["mean Specificity"]),
    meanAUC: formatValue(item["mean AUC"]),
    meanKappa: formatValue(item["mean Kappa"]),
});

export function getBasicModelStats(payload: IBasicModelStatsResponseData): IBasicModelStatsData {

    const result: any = {};

    if (payload?.Validation) {
        result.Validation = Object.entries(payload.Validation).reduce((acc, [key, value]) => [...acc, getBasicModelStatsItem(key, value)], []);
    }

    if (payload?.Training) {
        result.Training = Object.entries(payload.Training).reduce((acc, [key, value]) => [...acc, getBasicModelStatsItem(key, value)], []);
    }

    return result;

}