import React, { useCallback } from 'react';
import { useEvent } from 'react-use';

export function useLocalStorageState<T = any>(key: string, initialValue?: T) {
    const [state, setState] = React.useState<T>(initialValue ?? JSON.parse(localStorage.getItem(key) ?? 'null'));

    React.useEffect(() => {
        const value = localStorage.getItem(key);
        if (value) {
            setState(JSON.parse(value));
        }
    }, [key]);

    useEvent('storage', (e: StorageEvent) => {
        if (e.key !== key || JSON.stringify(state ?? null) === e.newValue) return;
        setState(JSON.parse(e.newValue ?? 'null'));
    });

    const updateState = useCallback(
        (payload: React.SetStateAction<T>) => {
            if (typeof payload === 'function') {
                return setState(prevState => {
                    const newValue = (payload as any)(prevState);

                    return newValue;
                });
            } else {
                localStorage.setItem(key, JSON.stringify(payload ?? null));

                return setState(payload);
            }
        },
        [key]
    );

    return [state, updateState] as const;
}

export class LocalStorageHelper<T = any> {
    constructor(public key: string, public state: T = null) {
        if (state !== undefined) {
            localStorage.setItem(key, JSON.stringify(state ?? null));
        }
    }

    updateState(payload: React.SetStateAction<T>) {
        this.state = typeof payload === 'function' ? (payload as any)(this.state) : payload;

        localStorage.setItem(this.key, JSON.stringify(this.state));
    }

    subscribe(callback: (state: T) => void) {
        const handler = (e: StorageEvent) => {
            if (e.key !== this.key || JSON.stringify(this.state ?? null) === e.newValue) return;
            callback(JSON.parse(e.newValue ?? 'null'));
        };

        window.addEventListener('storage', handler);

        return () => window.removeEventListener('storage', handler);
    }
}
