export const colorPalette = [
    '#00FFA7',
    '#24D9D9',
    '#A4BF00',
    '#FFB800',
    '#FF8A00',
    '#EE5843',
    '#F9748C',
    '#FFACBB',
    '#DB78D1',
    '#E7A5FF',
    '#8819CC',
    '#6819CC',
    '#5A00FF',
    '#4968D4',
    '#0085FF',
    '#6597C6',
];

export function getRandomColor(exclude: string[] = []): string {
    const availableColors = colorPalette.filter(color => !exclude.includes(color));
    return availableColors[Math.floor(Math.random() * availableColors.length)].toLocaleLowerCase();
}

export const segmentationColors = [
    '#00FFA7',
    '#24D9D9',
    '#A4BF00',
    '#FFB800',
    '#FF8A00',
    '#EE5843',
    '#F9748C',
    '#FFACBB',
    '#DB78D1',
    '#E7A5FF',
    '#8819CC',
    '#6819CC',
    '#5A00FF',
    '#4968D4',
    '#0085FF',
    '#6597C6',
];

export function getRandomSegmentationColor(exclude: string[] = []): string {
    const availableColors = segmentationColors.filter(color => !exclude.includes(color));
    return availableColors[Math.floor(Math.random() * availableColors.length)].toLocaleLowerCase();
}
