import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { APIService, getMessages } from 'common';
import { Notification } from 'components';
import { setSummaryOverlayStatus, setValidationRunData } from 'pages/Validation/store';
import { IBlindSpotTracking } from 'pages/Validation/views/validationAnalysis/components/blindSpotAnalysis/entities/IBlindSpotTracking';
import { call, put, select } from 'redux-saga/effects';
import { selectBlindSpotAnalysisTrackingList, selectValidationList } from 'redux/selectors';
import { getBlindSpotTrackingListSaga } from '../../../validationAnalysis/components/blindSpotAnalysis/store/saga';
import { IValidationRun, ValidationStatus } from '../../entities/IValidation';

export function* getValidationListDataSaga(): any {
    try {
        yield put(setSummaryOverlayStatus({ isLoading: true, showNoDataOverlay: false }));
        let projectId = localStorage.getItem('activeProject');
        const blindSpotAnalysisTrackingList: Array<IBlindSpotTracking> = yield call(getBlindSpotTrackingListSaga);
        const response: AxiosResponse<Array<IValidationRun>> = yield APIService.Validation.GetValidationList.call({
            params: { projectId },
        });

        if (!response?.data) return yield put(setSummaryOverlayStatus({ isLoading: false, showNoDataOverlay: true }));

        response.data.forEach(item => {
            item.blindSpotAnalysis = blindSpotAnalysisTrackingList.find(tracking => tracking.validationId === item.validationId);
        });

        response.data.sort((a, b) => b.createdTimestamp - a.createdTimestamp);

        yield put(setValidationRunData(response.data));
        yield put(setSummaryOverlayStatus({ isLoading: false, showNoDataOverlay: false }));
    } catch (error) {
        console.log(error);
        yield put(setSummaryOverlayStatus({ isLoading: false, showNoDataOverlay: true }));
    }
}

export function* updateValidationListTransactionSaga(action: PayloadAction<IValidationRun>) {
    try {
        yield call(updateValidationListSaga, action.payload);
    } catch (error) {
        console.log(error);
    }
}

export function* updateValidationListSaga(data: IValidationRun | Array<IValidationRun>): any {
    try {
        if (!data) return;
        let validationRunlist: IValidationRun[] = yield select(selectValidationList);
        validationRunlist = structuredClone(validationRunlist);

        if (Array.isArray(data)) {
            for (let item of data) {
                validationRunlist = yield updateValidationItem(validationRunlist, item);
            }
        } else {
            validationRunlist = yield updateValidationItem(validationRunlist, data);
        }

        yield put(setValidationRunData(validationRunlist));
    } catch (error) {
        console.log(error);
    }
}

function* updateValidationItem(validationRunlist: IValidationRun[], data: IValidationRun): any {
    const blindSpotAnalysisTrackingList: Array<IBlindSpotTracking> = yield select(selectBlindSpotAnalysisTrackingList);

    try {
        const blindSpotAnalysisTracking = blindSpotAnalysisTrackingList?.find(item => item.validationId === data.validationId);
        data.blindSpotAnalysis = blindSpotAnalysisTracking;
    } catch (error) {}

    const updatedValidation = validationRunlist?.find(validation => validation?.validationId === data?.validationId);

    if (!updatedValidation) {
        let text = getMessages('000313');
        if (data?.parentBatchJobId) text = getMessages('000859');

        Notification.success({ content: text.replace('$', data.name), options: { toastId: data.validationId } });
        validationRunlist.unshift(data);
    } else if (data.archived) {
        validationRunlist = validationRunlist.filter(validation => validation.validationId !== updatedValidation.validationId);
    } else {
        validationRunlist = validationRunlist.map(item => (item.validationId === data.validationId ? data : item));
    }

    if (updatedValidation?.validationStatus !== ValidationStatus.done && data.validationStatus === ValidationStatus.done) {
        Notification.success({
            content: getMessages('000312').replace('$', data.name),
            options: { toastId: data.validationId },
        });
    }

    return validationRunlist;
}
