export class MigrationFile {
    name: string;
    cloudKey: string;
    cloudPath: string;
    extension: string;
    path?: string;
}

export class PacsMigrationFile {
    process_id: string;
    file: MigrationFile;
    status: MigrationFileStatus;
    message?: string;
    etag: string;
    size?: number;
    last_modified?: Date;
    sop_instance_uid?: string;
    series_instance_uid?: string;
    study_instance_uid?: string;
    parent_patient?: string;
    parent_study?: string;
    parent_series?: string;
    instance_id?: string;
    _id?: string;
    createdAt?: Date;
}

export enum MigrationFileStatus {
    PENDING = 'pending',
    DOWNLOADED = 'downloaded',
    COMPLETED = 'completed',
    FAILED = 'failed',
    SKIPPED = 'skipped',
}

export interface MigrationFileRequestPayload extends MigrationFileListFilter {
    processId: string;
    pacsId: string;
    limit?: number;
    offset?: number;
}

export interface MigrationFileListFilter {
    name?: string;
    status?: MigrationFileStatus[];
    study_instance_uid?: string[];
}
