import { PayloadAction } from '@reduxjs/toolkit';
import { FormInstance } from 'antd';
import { APIService, getMessages, IDatasetResponsePayload, WindowLevel, WindowLevelKey } from 'common';
import { showMessageModal } from 'components';
import { UploadParamsFactory } from 'pages/Datasets/views/DatasetsUpload/entities/IUploadParams';
import { put } from 'redux-saga/effects';
import { setDatasetEditDrawer, setUploadLoading, updateDatasetUploadParams } from '..';
import { getDatasetListTransaction } from '../actions';
import { DatasetService } from '../DatasetService';
import Styles from './Styles.module.scss';

export function* editDatasetForm(sagaAction: PayloadAction<{ payload: IDatasetResponsePayload; form: FormInstance }>): any {
    try {
        let { payload, form } = sagaAction.payload;

        payload.default_window_level = WindowLevel[payload.default_window_level as unknown as WindowLevelKey];
        yield put(setUploadLoading(true));
        const response = yield APIService.Datasets.EditDateset.post(payload);

        if (response.data) {
            form?.resetFields?.();
            yield put(getDatasetListTransaction());
            yield put(setDatasetEditDrawer(false));
            yield put(updateDatasetUploadParams(UploadParamsFactory()));

            yield put(DatasetService.util.invalidateTags(['Dataset', { type: 'Dataset', id: payload.dataset_id }]));

            return showMessageModal({
                className: Styles.MessageModal,
                type: 'info',
                content: getMessages('000660'),
                cancelButton: {
                    enabled: false,
                },
                okButton: {
                    text: getMessages('000661'),
                },
            });
        }
    } catch (error) {
        console.log(error);
    } finally {
        yield put(setUploadLoading(false));
    }
}
