import { Image } from 'antd';
import cs from 'classnames';
import { IDataset, ISeries } from 'common/entities';
import { usePlatformThumbnail } from 'common/hooks/usePlatformThumbnail';
import { GImageSkeletonItem } from 'components/GSkeleton/GImageSkeleton';
import GTooltip from 'components/GTooltip';
import { StackInnerEmpty } from 'components/assets/icons';
import placeholder from 'components/assets/placeholder/placeholder.png';
import Styles from './Styles.module.scss';

export default function SerieItem({ item, onChangeSerie, dataset, activeSerie }: ISerieItemProps) {
    const onSerieSelected = () => {
        onChangeSerie(item.series_id);
    };

    return (
        <div
            className={cs(Styles.ThumbnailContainer, { [Styles.ActiveImageLabel]: item?.series_id === activeSerie?.series_id })}
            onClick={onSerieSelected}
            id="playground-series-items"
        >
            <div
                className={cs(Styles.ThumbnailWrapper, {
                    [Styles.ActiveSerieBorder]: item?.series_id === activeSerie?.series_id,
                })}
            >
                {item.exampleImages?.slice(0, 3)?.map((imageId, i) => (
                    <div
                        key={i}
                        className={cs(Styles.Thumbnail)}
                    >
                        <SerieThumbnail
                            key={imageId}
                            imageId={imageId}
                            dataset={dataset}
                            i={i}
                        />{' '}
                    </div>
                ))}
            </div>
            <div className={Styles.itemDescVertical}>
                <GTooltip title={item.description}>
                    <span
                        className="pBody-medium"
                        style={{ width: 120 }}
                    >
                        {[item.series_number, item.description || 'No Description'].filter(Boolean).join(' / ')}
                    </span>
                </GTooltip>
                <span>
                    <StackInnerEmpty />
                    <span className="caption2-normal">{item?.count}</span>
                </span>
            </div>
        </div>
    );
}

function SerieThumbnail({ dataset, i, imageId }: ISerieThumbnailProps) {
    const { image, loading } = usePlatformThumbnail({
        imageId: imageId,
        datasetId: dataset?.datasetId,
        windowLevel: dataset?.defaultWindowLevel,
    });

    if (loading)
        return (
            <GImageSkeletonItem
                active
                className={cs(Styles.GallerySkeletonItem, Styles.StackedSkeletonItem)}
                showTitle={false}
                style={{ top: (2 - i) * 7, width: `${100 - 2 + i}%` }}
            />
        );

    return (
        <Image
            style={{
                top: i === 2 ? 0 : i === 1 ? 3.82 : 4.59,
                left: i === 2 ? 2.6 : i === 1 ? 6 : 3.7,
                transform: `${i === 2 ? '' : i === 1 ? 'rotate(1.978deg)' : 'rotate(-5.258deg)'}`,
            }}
            src={image}
            placeholder={placeholder}
            fallback={placeholder}
            alt=""
            preview={false}
            className={Styles.Image}
            id="playground-StackItem"
        />
    );
}

interface ISerieThumbnailProps {
    imageId: string;
    dataset: IDataset;
    i: number;
}

interface ISerieItemProps {
    onChangeSerie: (seriesId: string) => void;
    dataset: IDataset;
    item: ISeries;
    activeSerie?: ISeries;
}
