import { Dictionary } from "common";

export interface ICommon {
    exportPdfVisible: boolean;
    pdfLoading: boolean;
    pageLoading: boolean;
    staticPayloadFetched: string;
    exportTablesVisible: boolean;
    pdfTablesLoading: boolean;
    exportFilterEnabled: boolean;
    lightThemeDict: Dictionary<boolean>;
}

export function CommonStateFactory(data?: Partial<ICommon>): ICommon {
    return {
        exportPdfVisible: data?.exportPdfVisible ?? false,
        pdfLoading: data?.pdfLoading ?? false,
        pageLoading: data?.pageLoading ?? false,
        staticPayloadFetched: data?.staticPayloadFetched ?? null,
        exportTablesVisible: data?.exportTablesVisible ?? false,
        pdfTablesLoading: data?.pdfTablesLoading ?? false,
        exportFilterEnabled: data?.exportFilterEnabled ?? false,
        lightThemeDict: data?.lightThemeDict ?? lightThemeDict
    };
}

export const lightThemeDict: Dictionary<boolean> = {
    "highlightedMetrics": false,
    "basicModelStats": false,
    "plotTopLosses": false,
    "confusionMatrix": false,
    "predictionGTDistribution": false,
    "rocCurve": false,
    "precisionRecallCurve": false,
    "performanceByConfidence": false,
    "confidenceDistribution": false,
    "classPerformance": false,
    "trainingVsValidationPerformance": false,
    "populationDistribution": false,
    "mostConfusedClasses": false,
    "confidenceDistributionGTless": false,
    "liftChart": false,
    "explorePredictions": false,
    "predictionDistribution": false,
    "plotTopMisses": false,
    "detailedMetrics": false,
    "intersectionDistribution": false,
    "performanceByIoUThreshold": false,
    "objectCounts": false,
    "datasetClassDistribution": false,
    "violinChart": false,
}

export type ValidationType = "validationResult" | "batchPrediction";