import cornerstoneTools, { getModule, getToolState, toolColors } from 'cornerstone-tools';

const external = cornerstoneTools.external;
const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');
const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext');
const draw = cornerstoneTools.importInternal('drawing/draw');
const drawRect = cornerstoneTools.importInternal('drawing/drawRect');
const setShadow = cornerstoneTools.importInternal('drawing/setShadow');
const throttle = cornerstoneTools.importInternal('util/throttle');
/**
 * @public
 * @class AssistedSliceSelectionTool
 * @memberof Tools.Annotation
 * @classdesc Tool for drawing rectangular for assisted slice selection.
 * the statistics of the enclosed pixels.
 * @extends Tools.Base.BaseAnnotationTool
 */

export default class AssistedSliceSelectionRectTool extends BaseAnnotationTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'AssistedSliceSelectionRect',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            configuration: {
                drawHandles: false,
                drawHandlesOnHover: false,
                hideHandlesIfMoving: false,
                renderDashed: true,
            },
        };
        super(props, defaultProps);
        this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
    }

    createNewMeasurement(eventData: any) {
        const goodEventData = eventData && eventData.currentPoints && eventData.currentPoints.image;

        if (!goodEventData) {
            return;
        }

        return {
            visible: true,
            active: true,
            color: '#FF0000',
            invalidated: true,
            handles: {
                start: {
                    x: eventData.currentPoints.image.x,
                    y: eventData.currentPoints.image.y,
                    highlight: true,
                    active: false,
                },
                end: {
                    x: eventData.currentPoints.image.x,
                    y: eventData.currentPoints.image.y,
                    highlight: true,
                    active: false,
                },
                initialRotation: eventData.viewport.rotation,
            },
        };
    }

    pointNearTool(element: any, data: any, coords: any, interactionType: any) {
        const hasStartAndEndHandles = data && data.handles && data.handles.start && data.handles.end;
        const validParameters = hasStartAndEndHandles;

        if (!validParameters || data.visible === false) {
            return false;
        }

        const distance = interactionType === 'mouse' ? 15 : 25;
        const startCanvas = external.cornerstone.pixelToCanvas(element, data.handles.start);
        const endCanvas = external.cornerstone.pixelToCanvas(element, data.handles.end);

        const rect = {
            left: Math.min(startCanvas.x, endCanvas.x),
            top: Math.min(startCanvas.y, endCanvas.y),
            width: Math.abs(startCanvas.x - endCanvas.x),
            height: Math.abs(startCanvas.y - endCanvas.y),
        };

        const distanceToPoint = external.cornerstoneMath.rect.distanceToPoint(rect, coords);

        return distanceToPoint < distance;
    }

    renderToolData(evt: any) {
        const toolData = getToolState(evt.currentTarget, this.name);

        if (!toolData) {
            return;
        }

        const eventData = evt.detail;
        const { image, element } = eventData;
        const lineWidth = 3;
        const lineDash = getModule('globalConfiguration').configuration.lineDash;
        const { renderDashed } = this.configuration;
        const context = getNewContext(eventData.canvasContext.canvas);
        //const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

        // Meta
        //const seriesModule = external.cornerstone.metaData.get('generalSeriesModule', image.imageId) || {};

        // Pixel Spacing
        //const modality = seriesModule.modality;
        //const hasPixelSpacing = rowPixelSpacing && colPixelSpacing;

        draw(context, (context: any) => {
            // If we have tool data for this element - iterate over each set and draw it
            for (let i = 0; i < toolData.data.length; i++) {
                const data = toolData.data[i];

                if (data.visible === false) {
                    continue;
                }

                // Configure
                const color: any = toolColors.getColorIfActive(data);

                setShadow(context, this.configuration);

                const rectOptions: any = { color };

                if (renderDashed) {
                    rectOptions.lineDash = lineDash;
                    rectOptions.lineWidth = lineWidth;
                }

                // Draw
                drawRect(
                    context,
                    element,
                    data.handles.start,
                    data.handles.end,
                    rectOptions,
                    'pixel',
                    data.handles.initialRotation
                );

                // Update textbox stats
                if (data.invalidated === true) {
                    if (data.cachedStats) {
                        this.throttledUpdateCachedStats(image, element, data);
                    } else {
                        this.updateCachedStats(image, element, data);
                    }
                }
            }
        });
    }
}

