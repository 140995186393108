import { Matomo } from 'app/Matomo';
import { useGetAnalyticsConfigQuery } from 'app/store/APISlice';
import constate from 'constate';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/selectors';

function useMatomoInstance() {
    const { data } = useGetAnalyticsConfigQuery(undefined);
    const [matomo, setMatomo] = useState<Matomo>();
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        if (!data?.siteId) return;
        setMatomo(new Matomo(data?.url, data?.siteId));
    }, [data]);

    useEffect(() => {
        if (!user?.email) return;
        matomo?.setUser(user?.email);
    }, [user, matomo]);

    return { matomo };
}

export const [MatomoProvider, useMatomo] = constate(useMatomoInstance, value => value?.matomo);
