import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { IValidationState } from "pages/Validation/entities";


function updateValidationSummaryCommonState(state: WritableDraft<IValidationState>, { payload }: PayloadAction<any>) {
    Object.assign(state.validationSummary, payload);
}

function updateSelectedColumns(state: WritableDraft<IValidationState>, { payload }: PayloadAction<any>) {
    state.validationSummary.selectedColumns[payload.problemType][payload.selectedTable] = payload.selectedFilters;
}

export const ExportValidationSummaryReducer = {
    updateValidationSummaryCommonState,
    updateSelectedColumns
};