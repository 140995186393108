import { DatePicker, DatePickerProps } from 'antd';
import 'antd/lib/date-picker/style/index';
import cx from 'classnames';
import Styles from './Styles.module.scss';
import 'antd/lib/date-picker/style/index';
import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';

dayjs.extend(weekday);
dayjs.extend(localeData);

export default function GDatePicker({ className, ...props }: GDatePickerProps) {
    return (
        <>
            <DatePicker
                className={cx(Styles.GColorPicker, className)}
                {...props}
            />
        </>
    );
}

export type GDatePickerProps = DatePickerProps;
