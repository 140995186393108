import { DeploymentStatus, Dictionary, ProblemType } from 'common';
import {
    IModelTraining,
    IModelTrainingConfigParams,
    IModelTrainingParams,
    ModelTrainingParamsFactory,
} from './IModelTrainingParams';

export interface IModelTrainingPageState {
    modelTrainingParams: IModelTrainingParams;
    selectedModelTrainingItem: string;
    isEditModalOpen: IEditTrainJobModalStatus;
    isCustomTrainingModalOpen: boolean;
    activeView: string;
    selectedConfigList: Array<Dictionary<any>>;
    activeTabKey: string;
    modelFormParams: Dictionary<any>;
}

export function ModelTrainingPageStateFactory(data?: Partial<IModelTrainingPageState>): IModelTrainingPageState {
    return {
        modelTrainingParams: ModelTrainingParamsFactory(data?.modelTrainingParams),
        selectedModelTrainingItem: data?.selectedModelTrainingItem ?? null,
        isEditModalOpen: data?.isEditModalOpen || {
            isOpen: false,
            trainJobId: '',
        },
        isCustomTrainingModalOpen: data?.isCustomTrainingModalOpen || false,
        activeView: data?.activeView || 'modelUpload',
        selectedConfigList: data?.selectedConfigList || defaultConfigList,
        activeTabKey: data?.activeTabKey || '0',
        modelFormParams: data?.modelFormParams || null,
    };
}

export interface IIModelTrainingRequestPayload {
    config: IModelTrainingConfigParams;
}

export interface IIModelTrainingRequestParams {
    dataset_id: string;
    annotation_id: string;
    config_id: string;
}

export interface IModelTrainingConfig {
    epochs: number;
    loss: string;
    image_size: Array<number>;
    batch_size: number;
    validation_split: number;
    num_classes: number;
    pre_dense_layer: number;
    backbone: string;
    metrics: Array<string>;
    created_timestamp?: number;
    finished_timestamp?: number;
    user?: string;
    validation_size?: number;
    train_size?: number;
    config_id?: string;
    problem_type?: ProblemType;
    user_id: string;
    optimizer?: string;
    annotation_id?: string;
    problemType: string;
    checkpoint_save_mode?: string;
    model?: string;
}

export enum ModelTrainingStatus {
    done = 'DONE',
    inprogress = 'INPROGRESS',
    failed = 'FAILED',
}

export interface IProgressCellArgs {
    value: any;
    record: IModelTraining;
}

export type modelTrainingDataIndex = keyof IModelTrainingConfig | keyof IModelTraining;

export interface ICaptionSummaryCellArgs {
    status?: DeploymentStatus;
    caption: React.ReactNode;
    summary?: React.ReactNode;
    record: IModelTraining;
    navLink?: string;
}

export interface IEditTrainJobModalStatus {
    isOpen: boolean;
    trainJobId: string;
}

const defaultConfigList = [
    {
        codename: 'batch_size',
        default: 4,
        dtype: 'integer',
        explanation: 'Defines the number of samples that will be propagated through the network.',
        name: 'Batch Size',
        type: 'dropdown',
        selection: 'single',
        values: [1, 2, 4, 8, 12, 16, 24, 32, 48, 64, 128, 256],
    },
    {
        name: 'Epochs',
        codename: 'epochs',
        explanation: 'Complete iteration through a dataset during the training.',
        type: 'number_field',
        dtype: 'integer',
        selection: 'single',
        min: 0,
        max: 400,
        default: 10,
    },
];
