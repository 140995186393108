import { AuditHelper } from 'common';
import Cookies from 'js-cookie';

export function clearSession() {
    AuditHelper.trackSession(true);

    localStorage.clear();
    sessionStorage.clear();
    Cookies.remove('accessToken');
}
