import { PayloadAction } from "@reduxjs/toolkit";
import { FormInstance } from "antd";
import { AxiosResponse } from "axios";
import { APIService, getMessages } from "common";
import { showMessageModal } from "components";
import { updateValidationItem } from "pages/Validation/store";
import { put } from "redux-saga/effects";
import { IValidationRun } from "../../entities/IValidation";

export function* editValidationItemSaga(sagaAction: PayloadAction<{ payload: IValidationRun, form: FormInstance; }>): any {
    try {
        let { payload, form } = sagaAction.payload;
        const response: AxiosResponse<IValidationRun> = yield APIService.Validation.UpdateNameAndAccessOptions.call({ payload });

        if (response?.data) {
            form?.resetFields?.();

            yield put(updateValidationItem(response?.data));

            showMessageModal({
                type: "success",
                content: getMessages("000941"),
                cancelButton: {
                    enabled: false,
                },
                okButton: {
                    text: getMessages("000942"),
                },
            });
        }
    } catch (error) {
        console.log(error);
    }
}

