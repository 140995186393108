import { _updateList } from 'common';
import { updateBlindSpotAnalysisState } from 'pages/Validation/store';
import { put, select } from 'redux-saga/effects';
import { selectBlindSpotAnalysisTrackingList } from 'redux/selectors';
import { IBlindSpotTracking, IBlindSpotTrackingResponse, getBlindSpotTrackingData } from '../../entities/IBlindSpotTracking';

export function* UpdateBlindSpotTrackingSaga(updateData: IBlindSpotTrackingResponse | Array<IBlindSpotTrackingResponse>): any {
    try {
        if (!updateData) return;
        if (!Array.isArray(updateData)) updateData = [updateData];
        let blindSpotAnalysisTrackingList: Array<IBlindSpotTracking> = yield select(selectBlindSpotAnalysisTrackingList);

        for (const updateItem of updateData) {
            const payload = getBlindSpotTrackingData(updateItem);

            blindSpotAnalysisTrackingList = _updateList(blindSpotAnalysisTrackingList, 'UPSERT', payload, 'bstId');
        }

        yield put(updateBlindSpotAnalysisState({ blindSpotAnalysisTrackingList }));
    } catch (error) {
        console.log(error);
    }
}
