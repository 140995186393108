import { LineSvgProps, ResponsiveLine, SliceTooltip } from '@nivo/line';
import { IBaseChartProps } from '../entities';
import { useSerieLegend } from '../hooks';
import { ISerie } from '../hooks/useSerieLegend';
import { nivoTheme, nivoThemeLight } from '../nivoTheme';

export default function LineChart({ data, chartOptions, ...props }: ILineChartProps) {
    const { lightTheme } = chartOptions;
    const { renderSerieLegends, selectedSeries } = useSerieLegend({ data, lightTheme });

    const ChartProps: LineSvgProps = {
        data: data?.filter(item => selectedSeries?.includes(item.id)),
        margin: { top: 20, right: 20, bottom: 50, left: 60 },
        xScale: {
            ...(chartOptions?.xAxis || {}),
            type: (chartOptions?.xAxis?.type as any) || 'linear',
        },
        yScale: {
            type: (chartOptions?.yAxis?.type as any) || 'linear',
            min: chartOptions?.yAxis?.min,
            max: chartOptions?.yAxis?.max,
        },
        xFormat: chartOptions?.xFormat,
        yFormat: ' >-.1f',
        curve: 'linear',
        colors: d => d.color,
        enablePoints: false,
        useMesh: true,
        theme: lightTheme ? nivoThemeLight : nivoTheme,
        lineWidth: 2,
        axisTop: null,
        axisBottom: {
            // format: '.1f',
            format: chartOptions?.axisBottomFormat,
            legend: chartOptions?.xAxis?.title,
            legendOffset: 36,
            legendPosition: 'middle',
            tickValues: chartOptions?.axisBottomTickValues,
        },
        axisLeft: {
            // format: '.1f',
            legend: chartOptions?.yAxis?.title,
            legendOffset: -40,
            legendPosition: 'middle',
        },
        sliceTooltip: chartOptions?.sliceTooltip,
        enableSlices: chartOptions?.sliceTooltip ? 'x' : undefined,
    };

    if (chartOptions?.enableArea) {
        ChartProps.enableArea = true;
        ChartProps.areaBlendMode = 'difference';
        ChartProps.areaOpacity = 0.15;
    }

    return (
        <>
            <div
                id={props.chartID}
                className={props.className}
            >
                {renderSerieLegends()}
                <ResponsiveLine {...ChartProps} />
            </div>
        </>
    );
}

interface ILineChartProps extends IBaseChartProps {
    data?: Array<ISerie>;
    chartOptions?: IChartOptions;
}

export interface IChartOptions {
    xAxis?: {
        type?: 'linear' | 'point' | 'band' | 'time' | 'log';
        title?: string;
        min?: number;
        max?: number;
        format?: string;
        precision?: 'millisecond' | 'second' | 'minute' | 'hour' | 'day' | 'month' | 'year';
        useUTC?: boolean;
    };
    yAxis?: {
        type?: 'linear' | 'point' | 'band' | 'time' | 'log';
        title?: string;
        min?: number;
        max?: number;
    };
    xFormat?: string;
    axisBottomFormat?: string;
    axisBottomTickValues?: string;
    enableArea?: boolean;
    sliceTooltip?: SliceTooltip;
    lightTheme?: boolean;
}
