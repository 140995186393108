import { Dictionary } from 'common';
import { IAnnotation } from 'pages/AnnotationCS/entities';

export interface IImage {
    imageId: string;
    sliceCount?: number;
    format?: string;
    imageName?: string;
    metadata?: Dictionary;
    annotation?: Array<IAnnotation>;
    medicalMetadata?: IMedicalMetadata;
    entities?: Array<IEntityItem>;
    datasetId?: string;
    similarImagesList?: Array<ISimilarImage>;
    imageStatistics?: IImageStatistics;
    studyId?: string;
    instanceId?: string;
    seriesId?: string;
}

export interface IImageResponse {
    image_id: string;
    dataset_id?: string;
    image_url?: string;
    created_timestamp?: number;
    image_name?: string;
    metadata?: Dictionary;
    annotation?: Array<IAnnotation>;
    format?: string;
    n_slices?: number;
    medical_metadata?: IMedicalMetadata;
    entities?: Array<IEntityItem>;
    similar_images_list?: Array<ISimilarImage>;
    image_statistics?: IImageStatistics;
    study_id?: string;
    instance_id?: string;
    series_id?: string;
}

export function ImageFactory(data?: Partial<IImageResponse>): IImage {
    return {
        imageId: data?.image_id ?? '',
        datasetId: data?.dataset_id ?? '',
        imageName: data?.image_name ?? '',
        metadata: data?.metadata ?? {},
        annotation: data?.annotation ?? [],
        format: data?.format ?? '',
        sliceCount: data?.n_slices ?? 0,
        medicalMetadata: data?.medical_metadata,
        entities: data?.entities ?? [],
        similarImagesList: data?.similar_images_list ?? [],
        imageStatistics: data?.image_statistics,
        studyId: data?.study_id,
        instanceId: data?.instance_id,
        seriesId: data?.series_id,
    };
}

export interface ISimilarImage {
    confused: boolean;
    distance: number;
    image_id: string;
    label: number;
    base_image_id?: string;
    base_image_label?: number;
}

export interface IEntityItem {
    name: string;
    entity_list: Dictionary<Array<string>>;
}

export interface IMedicalMetadata {
    instance_number: number;
    slice_location: number;
    series_number: number;
    series_description: string;
}
export interface IImageStatistics {
    blur: number;
    mean: number;
    min: number;
    max: number;
    stdv: number;
}
