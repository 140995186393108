import { IDataset } from 'common';
import { IRobustValidationState, RobustValidationStateFactory } from './IRobustValidationState';
import { IValidationParams, ValidationParamsFactory } from './IValidationParams';
import { IModel } from 'pages/Models/entities';

export interface IStartValidationPageState {
    robustValidationState: IRobustValidationState;
    validationParams: IValidationParams;
}

export function StartValidationPageStateFactory(data?: Partial<IStartValidationPageState>): IStartValidationPageState {
    return {
        robustValidationState: RobustValidationStateFactory(data?.robustValidationState),
        validationParams: ValidationParamsFactory(data?.validationParams),
    };
}

export interface IValidationRunRequestPayload {
    // dataset_id: string;
    // user_transformations: any;
    // model_id?: string;
    // deploy_id: string;
    // n_images: number;
    // batch_size: number;
    // n_workers: number;
    // generate_metrics: boolean;
    // windowing: any;
    // project_id?: string;

    dataset: IDataset;
    model: IModel;
}
