import { isStudyDataset } from 'common/entities/Dataset';
import { useGetDatasetQuery } from 'pages/Datasets/store/DatasetService';
import { useState } from 'react';
import { IDatasetExplorerItem, ISelection } from '../entities';
import { useSimpleDataSource } from './useSimpleDataSource';
import { useStudyDataSource } from './useStudyDataSource';
import { useMap } from 'react-use';
import usePagination from './usePagination';

export function useDatasetImageExplorer({ allowNavigation, datasetId, onClickImage, selection, excludeItems }: IProps) {
    const { currentData: dataset } = useGetDatasetQuery(datasetId);
    const [pageSize, setPageSize] = useState<number>(12);
    const [pageMap, { set, reset }] = useMap<Dictionary<number>>({
        page: 1,
        seriesPage: 1,
        studyPage: 1,
    });

    const [search, setSearch] = useState<string>();

    const { simpleImageSource, simpleImageSourceLoading } = useSimpleDataSource({
        dataset,
        pageSize,
        search,
        page: pageMap?.page,
    });

    const {
        studyDataSource,
        studySourceLoading,
        level,
        onClickStudyItem,
        selectedSeries,
        selectedStudy,
        studyList,
        seriesList,
        onClickStudyList,
        setStudySearch,
        studySearch,
    } = useStudyDataSource({
        dataset,
        pageSize,
        search,
        excludeItems,
        setSearch,
        pageMap,
        reset,
    });
    
    const loading = studySourceLoading || simpleImageSourceLoading;
    const dataSource = isStudyDataset(dataset) ? studyDataSource : simpleImageSource;
    const paginationConfig = usePagination(setPageSize, pageMap, pageSize, set, dataSource?.total, level)

    function onClickItem(item: IDatasetExplorerItem) {
        if (selection && (!isStudyDataset(dataset) || selection.selectionLevel === level)) {
            selection.onSelectionChange(item);
            return;
        }
        if (isStudyDataset(dataset) && item.type !== 'image' && allowNavigation) {
            onClickStudyItem(item);
        } else {
            onClickImage?.(item);
        }
    }

    return {
        pageSize,
        loading,
        dataSource,
        onClickItem,
        dataset,
        level,
        selectedSeries,
        selectedStudy,
        studyList,
        seriesList,
        onClickStudyList,
        search,
        setSearch,
        setStudySearch,
        studySearch,
        pageMap,
        paginationConfig
    };
}

interface IProps {
    datasetId: string;
    allowNavigation: boolean;
    onClickImage: (image: IDatasetExplorerItem) => void;
    selection?: ISelection;
    excludeItems?: string[];
}
