import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';
import appReducer from 'app/store';
import { APISlice } from 'app/store/APISlice';
import DicomViewerReducer from 'components/GDicomViewer/store';
import produce from 'immer';
import annotationReducer from 'pages/AnnotationCS/store';
import authReducer from 'pages/Authentication/store';
import datasetReducer from 'pages/Datasets/store';
import dashboardReducer from 'pages/Main/store';
import modelReducer from 'pages/Models/store';
import platformReducer from 'pages/Platform/store';
import playgroundReducer from 'pages/Playground/store';
import projectReducer from 'pages/Project/store';
import userSettingsReducer from 'pages/User/store';
import validationReducer from 'pages/Validation/store';
import createSagaMiddleware from 'redux-saga';
import saga from './saga';

let sagaMiddleware = createSagaMiddleware();

const store = configureStore({
    reducer: {
        [APISlice.reducerPath]: APISlice.reducer,
        app: appReducer,
        validation: validationReducer,
        auth: authReducer,
        models: modelReducer,
        playground: playgroundReducer,
        datasets: datasetReducer,
        platform: platformReducer,
        annotation: annotationReducer,
        userSettings: userSettingsReducer,
        project: projectReducer,
        dashboard: dashboardReducer,
        dicomViewer: DicomViewerReducer,
    },
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware({
            serializableCheck: false,
        }).concat(sagaMiddleware, APISlice.middleware),
    devTools: {
        actionSanitizer: action => {
            switch (action.type) {
                case 'playground/setWindowLevel':
                    return { ...action, payload: '<<LONG_BLOB>>' };
                case 'Model/updateModelOutputExample':
                    return { ...action, payload: '<<LONG_BLOB>>' };

                default:
                    return action;
            }
        },
        stateSanitizer: state =>
            produce(state, (draft: any) => {
                draft.playground.present.common.imageMap = '<<LONG_BLOB>>';
                // draft.models.common.modelOutputExampleSchema = '<<LONG_BLOB>>';
            }),
    },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

sagaMiddleware.run(saga);
setupListeners(store.dispatch);

export default store;
