import { IDataset } from 'common/entities';
import { _findBy, _uniq, _uniqBy, isNullOrUndefined } from 'common/Utils';
import { IAnnotationTracking } from 'pages/AnnotationCS/entities';
import { useGetAnnotationTrackingListCSQuery } from 'pages/AnnotationCS/store/AnnotationService';
import { IModel } from 'pages/Models/entities';
import { useSelector } from 'react-redux';
import { selectAllDatasets, selectAllModels, selectDataSourceList, selectVendorList } from 'redux/selectors';

export function useDatasetFilterMenuItems() {
    const datasetList = useSelector(selectAllDatasets);
    const datasourceList = useSelector(selectDataSourceList);

    return function getFilterMenuItems(key: keyof IDataset) {
        const fields = datasetList.flatMap(d => d[key]);
        let result = _uniq(fields);

        switch (key) {
            case 'sourceId':
                result = result.map(i => {
                    const source = _findBy(datasourceList, 'source_id', i);

                    return {
                        value: source?.source_id,
                        title: source?.source_name,
                    };
                });
                break;

            default:
                result = result.map(i => ({ value: i, title: i }));
                break;
        }

        return result.filter(item => !isNullOrUndefined(item.value) && item.value !== 'N/A');
    };
}

export function useModelFilterMenuItems() {
    const modelList = useSelector(selectAllModels);
    const vendorList = useSelector(selectVendorList);
    return function getFilterMenuItems(key: keyof IModel): Array<any> {
        return _uniqBy(
            modelList
                ?.flatMap(d => d[key])
                .filter(item => !isNullOrUndefined(item) && item !== 'N/A')
                .map(item => ({ value: item, title: (
                    key === 'vendorId' ? _findBy(vendorList, 'vendor_id', item)?.vendor_name : item
                ) })),
            'value'
        );
    };
}
   
export function useAnnotationFilterMenuItems() {
    let projectId = localStorage.getItem("activeProject");
    const { data: projectList } = useGetAnnotationTrackingListCSQuery({dataset_id: undefined, project_id: projectId ?? undefined});

    return function getFilterMenuItems(key: keyof IAnnotationTracking) {
        
        const fields = projectList?.flatMap((d: any) => d[key]);
        let result = _uniq(fields);
        
                result.filter(i => i && i)
                result = result?.map(i => {

                    return {
                        value: i,
                        title: i,
                    };
                });
          

        return result.filter((item: { value: string; }) => !isNullOrUndefined(item.value) && item.value !== 'N/A');
    };
}