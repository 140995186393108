import { IClassMappingSelection } from 'pages/AnnotationCS/entities';

export interface PreAnnotationConfig {
    classMappingConfig?: Array<IClassMappingSelection>;
    modelId?: string;
    preAnnotationMode?: PreAnnotationMode;
    modalOpen?: boolean;
    validation?: string;
    labelingConfig?: string;
    readers?: string[];
    userUpdateModalState?: UserUpdateModalState;
}

export type PreAnnotationMode = 'assisted-slice-selection' | 'prediction';

export function PreAnnotationConfigFactory(): PreAnnotationConfig {
    return {
        classMappingConfig: [],
        modelId: null,
        preAnnotationMode: 'prediction',
        modalOpen: false,
        validation: null,
        labelingConfig: null,
        readers: [],
        userUpdateModalState: UserUpdateModalStateFactory(),
    };
}

export interface UserUpdateModalState {
    open: boolean;
    validationId: string;
}

export function UserUpdateModalStateFactory(): UserUpdateModalState {
    return {
        open: false,
        validationId: null,
    };
}
