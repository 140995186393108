import { Dictionary } from "common";
import { ChartHelper } from "common/helpers";
import _uniqueId from "lodash/uniqueId";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { IBaseChartProps } from "../entities";
import { createChart, IChartOptions } from "./createChart";

export default function HorizontalBarChart(props: IBarChartProps) {
    let data = props.data;
    const [chartID, setChartID] = useState(_uniqueId(props.chartID));
    const chartHelperRef = useRef<ChartHelper.XYChart>();

    useEffect(() => {
        setChartID(_uniqueId(props.chartID));
    }, [props.chartID]);

    useEffect(() => {
        if (props.data && chartHelperRef.current) {
            chartHelperRef.current.setData(props.data);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useLayoutEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(chartID, props.chartOptions);

            if (props.data) {
                chartHelperRef.current.setData(props.data);
            }
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
    }, []);

    return (
        <>
            <div className={props.className} style={Object.assign({ height: props.height, width: props.width }, props.style)} id={chartID} />
        </>
    );
}



export interface IBarChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    chartOptions?: IChartOptions;
    getChartHelperRef?: (chartHelperRef: ChartHelper.XYChart) => void;
}

