import { ChartHelper } from 'common/helpers';
import { SeriesType } from 'common/helpers/ChartHelper/entities/enums';
import * as am5 from "@amcharts/amcharts5";

export function createChart(chartID: string, chartOptions?: IChartOptions) {
    const chartHelper = new ChartHelper.PieChart();

    chartHelper.createRoot({ chartID });

    chartHelper
        .createChart({
            settings: {
                startAngle: 180,
                endAngle: 360,
                layout: chartHelper.root.verticalLayout,
                innerRadius: am5.percent(75),
            },
        })
        .createSeries(
            {
                type: SeriesType.PieSeries,
                seriesSettings: {
                    startAngle: 180,
                    endAngle: 360,
                    valueField: 'value',
                    categoryField: 'name',
                    alignLabels: false,
                    legendValueText: '{value}',
                },
                legend: {
                    enabled: chartOptions?.legend?.enabled ?? true,
                    legendSettings: {
                        centerY: am5.percent(50),
                        y: am5.percent(80),
                        centerX: am5.percent(50),
                        x: am5.percent(50),
                        layout: chartHelper.root.gridLayout,
                    },
                },
            },
            series => {
                series.states.create('hidden', {
                    startAngle: 180,
                    endAngle: 180,
                });

                series.ticks.template.setAll({
                    forceHidden: true,
                });

                series.labels.template.setAll({
                    visible: false,
                    text: '',
                });

                if (chartOptions?.centerLabel?.enabled) {
                    chartHelper.centerLabel = chartHelper.chart.seriesContainer.children.push(
                        am5.Label.new(chartHelper.root, {
                            fill: am5.color('#ffffff'),
                            textAlign: 'center',
                            centerY: am5.p100,
                            centerX: am5.p50,
                            text: ``,
                        })
                    );
                }
            }
        );

    return chartHelper;
}

export interface IChartOptions {
    centerLabel?: {
        enabled: boolean;
        text?: string;
    };

    legend?: {
        enabled?: boolean;
    };
}