/* eslint-disable eqeqeq */
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { _entries } from 'common/Utils';
import { _keys } from 'common/Utils/LangUtils/_keys';
import { getErrorMessage } from 'common/Utils/getErrorMessage';
import { endpoints, endpointsWithId } from 'common/entities/NotificationTitles';
import { ServiceEnum } from 'common/enum/ServiceEnum';
import { EventBus } from 'common/helpers/EventBus';
import { getServerMessages } from 'common/helpers/MLHelper';
import Notification from 'components/GNotification/index';
import { getDefaultHeaders } from '../Tools/getDefaultHeaders';
import { getBaseConfig, injectHeaders } from './axiosConfig';

export default class ApiClient {
    baseUrl: ServiceEnum = ServiceEnum.Api;

    private instance: AxiosInstance | null = null;

    private get http(): AxiosInstance {
        return this.instance ?? this.initHttp();
    }
    _url: string;
    _config?: AxiosRequestConfig;
    constructor(url: string, config?: AxiosRequestConfig, baseURL?: ServiceEnum) {
        this._url = url;
        this._config = config;
        this.baseUrl = baseURL ?? this.baseUrl;

        this.get = this.get.bind(this);
        this.post = this.post.bind(this);
        this.put = this.put.bind(this);
        this.delete = this.delete.bind(this);
    }

    initHttp() {
        const http = axios.create(getBaseConfig(this.baseUrl));

        http.interceptors.request.use(injectHeaders as any, error => Promise.reject(error));

        http.interceptors.response.use(
            response => response,
            error => {
                const { request, response } = error;
                return this.handleError(request, response);
            }
        );

        this.instance = http;
        return http;
    }

    request<T = any, R = AxiosResponse<T>>(config: AxiosRequestConfig): Promise<R> {
        return this.http.request(config);
    }

    get<T = any, R = AxiosResponse<T>>(config?: AxiosRequestConfig): Promise<R> {
        return this.http.get<T, R>(this._url, config);
    }

    post<T = any, R = AxiosResponse<T>>(payload?: T, config?: AxiosRequestConfig): Promise<R> {
        return this.http.post<T, R>(this._url, payload, config);
    }

    put<T = any, R = AxiosResponse<T>>(data?: T, config?: AxiosRequestConfig): Promise<R> {
        return this.http.put<T, R>(this._url, data, config);
    }

    delete<T = any, R = AxiosResponse<T>>(config?: AxiosRequestConfig): Promise<R> {
        return this.http.delete<T, R>(this._url, config);
    }

    private async handleError(request: any, response: AxiosResponse<AxiosError>) {
        let message = getServerMessages(getErrorMessage(response?.data));
        let location = window.location.href;
        let requestUrl = request?.responseURL;
        let pathName = request?.responseURL
            ? new URL(request?.responseURL)?.pathname?.replace(`${new URL(request?.responseURL)?.searchParams?.toString()}`, '')
            : '';
        let title: string;

        let path = pathName?.endsWith('/') ? pathName?.slice(0, -1) : pathName;

        if (_keys(endpoints).some(i => i === path)) title = (endpoints as any)[path];
        else title = _entries(endpointsWithId).find(([key]) => path.includes(key))?.[1];

        if (message) {
            console.error({ error: response.data });

            if (!request?.responseURL?.includes('GetUser') && response.status == 401) {
                EventBus.emit('forceLogout');
            }

            if (message.includes('<!DOCTYPE html>')) message = 'Server error';

            if (response.status == 502) message = 'Service unavailable';

            Notification.error({ content: message, options: { toastId: message } });

            if (![400, 401, 402, 403, 502].includes(response.status))
                this.saveNotification(message, title, location, requestUrl, pathName);
        }

        return Promise.reject(response);
    }

    private saveNotification(message: string, title: string, location: string, requestUrl: string, pathName: string) {
        try {
            axios.post(
                '/api/Notifications/AddNewNotification',
                {
                    message: message,
                    type: 'error',
                    title: title ?? pathName?.substring(1).replaceAll('/', '-'),
                    location: location ?? '',
                    requestUrl: requestUrl ?? '',
                },
                {
                    headers: {
                        ...getDefaultHeaders(),
                        Accept: 'application/json',
                        'Content-Type': 'application/json; charset=utf-8',
                        'Access-Control-Allow-Credentials': '*',
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                }
            );
        } catch (error) {
            console.error(error);
        }
    }
}
