export interface IModelFiles {
    model_id: string;
    model_path: string;
    file_content: any;
    dockerhub: boolean;
}

export function ModelFilesFactory(data?: Partial<IModelFiles>): IModelFiles {
    return {
        model_id: data?.model_id || null,
        model_path: data?.model_path || null,
        file_content: data?.file_content || null,
        dockerhub: data?.dockerhub || false,
    };
}