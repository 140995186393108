import { Dictionary, TClassMapping } from "common";
import { IBlindSpotTrackingResponse } from "./IBlindSpotTracking";



export function GetAnalysisResultData(data?: IBSAResponseItem): IBlindSpotAnalysisResult {
    return {
        subcohortId: data?.sub_cohort_id,
        combination: data?.combination,
        metrics: data?.metrics,
        size: data?.size,
        isViable: data?.is_viable,
    };
}

export interface IAnalysisResultResponse {
    analysisResult: Array<IBSAResponseItem>;
    tracking: IBlindSpotTrackingResponse;
    classMappings: TClassMapping;
}

export interface IBSAResponseItem {
    _id: string;
    sub_cohort_id: string;
    combination: Dictionary<MetadataValue>;
    metrics: MetricType;
    size: number;
    is_viable: boolean;
}

export interface IBlindSpotAnalysisResult {
    subcohortId: string;
    combination: Dictionary<MetadataValue>;
    metrics: MetricType;
    size: number;
    isViable: boolean;
}

export interface IAnalysisMetrics extends Dictionary<number> {
    "Accuracy"?: number;
    "Macro F1"?: number;
    "Micro F1"?: number;
    "Macro Precision"?: number;
    "Micro Precision"?: number;
    "Macro Recall"?: number;
    "Micro Recall"?: number;
}

export type MetadataValue = string | [number, number];

export type MetricType = Record<number | "Average", IAnalysisMetrics>;
