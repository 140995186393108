/* eslint-disable react-hooks/exhaustive-deps */
import { Tag } from 'antd';
import { useEffect, useState } from 'react';
import Styles from "./Styles.module.scss";
import GTagEditor from './GTagEditor';


export default function GTags(props: GTagsProps) {
    const [tagList, setTagList] = useState(props.tagList);

    useEffect(() => {
        setTagList(props.tagList);
    }, [props.tagList]);

    useEffect(() => {
        props.onListChange?.(tagList);
    }, [tagList]);

    function renderTags(tag: string, index: number) {
        return (
            <Tag
                closable={props.deleteEnabled}
                onClose={() => setTagList(oldList => oldList.filter(t => t !== tag))}
                key={tag}
            >
                {props.tagPrefix?.(tag, index, props?.configIndex)}
                {tag}
            </Tag>
        );
    }
    return (
        <div className={[Styles.TagsContainer, props.className].join(" ")}>
            {props.editingEnabled
                ? <GTagEditor {...props} />
                : tagList?.map(renderTags)}
        </div>
    );
}

interface GTagsProps {
    className?: string;
    tagList: Array<string>;
    onListChange?: (tagList: Array<string>) => void;
    editingEnabled?: boolean;
    deleteEnabled?: boolean;
    tagPrefix?: (tag: string, index: number, configIndex?:number) => React.ReactNode;
    problemType?:string;
    configIndex?:number;
}
