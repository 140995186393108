import { Dictionary } from "common";
import { IValidationPlotResponse } from "..";
import { formatValue } from "./payload";

export interface IHighlightedMetricsResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: string;
        data: IHighlightedMetricsResponseData;
    };
}

export type IHighlightedMetricsResponseData = Dictionary<MetricsValue>;

export interface MetricsValue {
    Validation: number;
    Training: number;
    Confidence_Interval: Array<number>;
}

function MetricsValueFactory(data?: MetricsValue): MetricsValue {
    return {
        Validation: formatValue(data?.Validation),
        Training: formatValue(data?.Training),
        Confidence_Interval: data?.Confidence_Interval
    };
}


export type THighlightedMetrics = Dictionary<MetricsValue>

export function getHighlightedMetricsData(payloadDict: IHighlightedMetricsResponseData): THighlightedMetrics {
    if (!payloadDict) return {};
    return Object.entries(payloadDict).filter(([key, _]) => key !== "mAP11@10").reduce((acc, [key, value]) => ({ ...acc, [key]: MetricsValueFactory(value) }), {});
}