import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IDatasetFilters, IModelFilters } from 'common';
import { IAnnotationFilters } from 'common/hooks/useFilteredAnnotationList';
import { IProjectFilters } from 'pages/AnnotationCS/entities/ICommonState';
import { ICommonState, ProjectStateFactory } from '../entities';

const ProjectSlice = createSlice({
    name: "Project",
    initialState: ProjectStateFactory(),
    reducers: {
        setActiveProjectId(state, { payload }: PayloadAction<string>) {
            state.activeProjectId = payload;
        },
        updateDatasetFilters(state, { payload }: PayloadAction<Partial<IDatasetFilters>>) {
            Object.assign(state.newProjectState.datasetFilters, payload);
        },
        updateAnnotationFilters(state, { payload }: PayloadAction<Partial<IAnnotationFilters>>) {
            Object.assign(state.newProjectState.annotationFilters, payload);
        },
        updateModelFilters(state, { payload }: PayloadAction<Partial<IModelFilters>>) {
            Object.assign(state.newProjectState.modelFilters, payload);
        },
        updateCommonState(state, { payload }: PayloadAction<Partial<ICommonState>>) {
            Object.assign(state.commonState, payload);
        },
        updateProjectFilters(state, { payload }: PayloadAction<Partial<IProjectFilters>>) {
            Object.assign(state.commonState.projectFilters, payload);
        },
    }
});

export const {
    updateDatasetFilters, updateAnnotationFilters, updateModelFilters, updateCommonState, updateProjectFilters, setActiveProjectId
} = ProjectSlice.actions;

export default ProjectSlice.reducer;

export * from "./ProjectService";
export * from "./selectors";

