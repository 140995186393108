/* eslint-disable react-hooks/exhaustive-deps */
import { ChartHelper, } from 'common/helpers';
import React, { useEffect, useLayoutEffect, useRef } from 'react';
import { IBaseChartProps } from '../entities';
import { createChart } from './createChart';

export default function TimeLineChart(props: ITimeLineChartProps) {
    const chartHelperRef = useRef<ChartHelper.XYChart>();

    useLayoutEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(props.chartID);
            props.getChartHelperRef?.(chartHelperRef.current);
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
    }, []);

    useEffect(() => {

        if (props.data && chartHelperRef.current) {
            chartHelperRef.current.setData(props.data);
        }

    }, [props.data]);

    return (
        <>
            <div id={props.chartID} className={props.className} />
        </>
    );
}


interface ITimeLineChartProps extends IBaseChartProps {
    data?: Array<{ date: number, value: number; }>;
    getChartHelperRef?: (chartHelperRef: ChartHelper.XYChart) => void;
}
