import { TableColumnsType } from 'antd';
import { IDataset } from 'common/entities';
import { getMessages } from 'common/helpers';
import { _findBy } from 'common/Utils';
import { useSelector } from 'react-redux';
import { selectDataSourceList } from 'redux/selectors';

export function useDatasetTable(): { columns: TableColumnsType<IDataset> } {
    const sourceList = useSelector(selectDataSourceList);

    const columns: TableColumnsType<IDataset> = [
        {
            dataIndex: 'datasetName',
            title: getMessages('000039'),
        },
        {
            dataIndex: 'modality',
            title: getMessages('000021'),
            render: renderBaseField,
        },
        {
            dataIndex: 'anatomy',
            title: getMessages('000023'),
            render: renderBaseField,
        },
        {
            dataIndex: 'encoding',
            title: getMessages('000292'),
            render: renderBaseField,
        },
        {
            dataIndex: 'category',
            title: getMessages('000635'),
            render: renderBaseField,
        },
        {
            dataIndex: 'sampleSize',
            title: getMessages('000043'),
        },
        {
            dataIndex: 'sourceId',
            title: getMessages('000564'),
            render: (value: any) => _findBy(sourceList, 'source_id', value)?.source_name,
        },
        {
            dataIndex: 'createdDate',
            title: getMessages('000769'),
        },
    ].map(item => ({ ...item, key: item.dataIndex }));

    return { columns };
}

export const renderBaseField = (value: any) => (Array.isArray(value) ? value.join(', ') : value);

export interface IUseDatasetTableArgs {}
