import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { ChartHelper } from 'common/helpers';
import { AxesType, SeriesType } from 'common/helpers/ChartHelper/entities/enums';

export function createChart(chartID: string, chartOptions: IChartOptions = {}) {
    const { lightTheme } = chartOptions;
    const chartHelper = new ChartHelper.XYChart<am5xy.ColumnSeries>();
    let xAxisTitle;

    chartHelper.createRoot({ chartID, lightTheme });

    chartHelper.createChart({ legendEnabled: chartOptions?.legendEnabled }).createAxes(
        {
            xAxis: {
                type: AxesType.category,
                rendererSetting: {
                    minGridDistance: 20,
                },
                categoryAxisOptions: {
                    maxDeviation: 0.3,
                    categoryField: chartOptions?.xAxis?.fieldName || 'user',
                    tooltip: am5.Tooltip.new(chartHelper.root, {
                        labelText: '{user}',
                    }),
                },
            },
            yAxis: {
                type: AxesType.value,
                rendererSetting: {},
                valueAxisOptions: {
                    maxDeviation: 0.3,
                    min: chartOptions?.yAxis?.min,
                    max: chartOptions?.yAxis?.max,
                },
            },
        },
        (xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>, yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>) => {
            if (chartOptions?.xAxis?.labelText) {
                xAxis.get('renderer').labels.template.setAll({
                    text: chartOptions.xAxis?.labelText,
                    fill: am5.color('#fff'),
                    tooltipText: '{name}',
                });
            }
            if (chartOptions?.xAxis?.rotate) {
                xAxis.get('renderer').labels.template.setAll({
                    rotation: -90,
                    centerY: am5.p50,
                    centerX: am5.p100,
                    paddingRight: 5,
                    textAlign: 'right',
                });
            }
        }
    );

    chartHelper.createSeries(
        {
            type: SeriesType.CandlestickSeries,
            seriesSettings: {
                name: chartID,
                openValueYField: 'q1',
                highValueYField: 'max',
                lowValueYField: 'min',
                valueYField: 'q3',
                categoryXField: 'user',
                tooltip: am5.Tooltip.new(chartHelper.root, {
                    labelText: "q1: {openValueY}\nmin: {lowValueY}\nmax: {highValueY}\nq3: {valueY},\nmedian: {median}",
                    pointerOrientation: 'horizontal',
                }),
                fill: am5.color('#00ffa7'),
                stroke: am5.color('#ffffff'),
            },
        },
        (series: am5xy.CandlestickSeries) => {
            series.columns.template.setAll({
                cornerRadiusTL: 5,
                cornerRadiusTR: 5,
                cornerRadiusBL: 5,
                cornerRadiusBR: 5,
                maxWidth: 90,
            });
        }
    );

    chartHelper.createSeries(
        {
            type: SeriesType.StepLineSeries,
            seriesSettings: {
                valueYField: 'median',
                categoryXField: 'user',
                noRisers: true,
                stepWidth: am5.percent(50),
                stroke: am5.color('#bfbfbf'),
            },
            enableTooltip: true,
        },
        series => {}
    );
    const cursor = chartHelper.chart.set(
        'cursor',
        am5xy.XYCursor.new(chartHelper.root, {
            xAxis: chartHelper.xAxis,
        })
    );
    cursor.lineY.set('visible', false);

    return { chartHelper, xAxisTitle };
}

export interface IChartOptions {
    valueBulletEnabled?: boolean;
    legendEnabled?: boolean;
    tooltipEnabled?: boolean;
    heatFillEnabled?: boolean;
    dynamicBulletPosition?: boolean;
    tooltipLabelText?: string;
    bulletLabelText?: string;
    xAxis?: {
        labelText?: string;
        fieldName?: string;
        title?: string;
        rotate?: boolean;
        truncate?: boolean;
        maxWidth?: number;
    };
    yAxis?: {
        fieldName?: string;
        min?: number;
        max?: number;
        q1FieldName?: string;
        q3FieldName?: string;
        minFieldName?: string;
        maxFieldName?: string;
        medianaFieldName?: string;
    };
    series?: {
        fill?: string;
        stroke?: string;
        name?: string;
        medianaName?: string;
    };
    lightTheme?: boolean;
    customColor?: string[];
}
