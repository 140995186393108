import { createAction } from "@reduxjs/toolkit";
import { IChartState, IValidationMetricsDataTransactionPaylaod } from "../entities";

export const GET_VALIDATION_METRICS_PAGE_DATA_TRANSACTION = 'GET_VALIDATION_METRICS_PAGE_DATA_TRANSACTION';
export const SET_VALIDATION_METRICS_PAGE_DATA_ACTION = 'SET_VALIDATION_METRICS_PAGE_DATA_ACTION';
export const SET_SELECTED_VALIDATION_TRANSACTION = 'SET_SELECTED_VALIDATION_TRANSACTION';
export const CHANGE_PREVIEW_IMAGE_TRANSACTION = 'CHANGE_PREVIEW_IMAGE_TRANSACTION';
export const UPDATE_VALIDATION_METRICS_VIEW_TRANSACTION = 'UPDATE_VALIDATION_METRICS_VIEW_TRANSACTION';
export const GET_VALIDATION_METRICS_VIEW_TRANSACTION = 'GET_VALIDATION_METRICS_VIEW_TRANSACTION';

export const getValidationMetricsPageDataTransaction = createAction<IValidationMetricsDataTransactionPaylaod>(GET_VALIDATION_METRICS_PAGE_DATA_TRANSACTION);
export const setValidationMetricsPageDataAction = createAction<Partial<IChartState>>(SET_VALIDATION_METRICS_PAGE_DATA_ACTION);
export const setSelectedValidationTransAction = createAction<string>(SET_SELECTED_VALIDATION_TRANSACTION);
export const changePreviewImageTransaction = createAction<number>(CHANGE_PREVIEW_IMAGE_TRANSACTION);
export const getValidationMetricsViewTransaction = createAction(GET_VALIDATION_METRICS_VIEW_TRANSACTION);
export const updateValidationMetricsViewTransaction = createAction<any>(UPDATE_VALIDATION_METRICS_VIEW_TRANSACTION);