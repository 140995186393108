import { getMessages, PlatformService } from 'common';
import { useEffect, useState } from 'react';

export default function useSystemUtils() {

    const [systemUtils, setSystemUtils] = useState<ISystemUtils>();

    useEffect(() => {
        handleGetSystemUtils();

        const interval = setInterval(handleGetSystemUtils, 60000);
        return () => {
            clearInterval(interval);
        };
    }, []);

    async function handleGetSystemUtils() {
        try {
            if (!document.hasFocus()) return;
            const response: { data: ISystemUtils; } = await PlatformService.Deployment.GetSystemUtilization.get({ headers: { activity: "system" } });
            if (response?.data) {
                const utils: ISystemUtils = {
                    memory: Number(response.data.memory?.toFixed(2)),
                    cpu_percent: Number(response.data.cpu_percent?.toFixed(2)),
                };
                setSystemUtils(utils);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const progressMenuList = [
        {
            label: getMessages("000164"),
            value: systemUtils?.memory,
        },
        {
            label: getMessages("000165"),
            value: systemUtils?.cpu_percent,
        },
    ];

    return { progressMenuList, };
}

interface ISystemUtils {
    cpu_percent: number;
    memory: number;
}

