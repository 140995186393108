import { Image } from 'antd';
import cs from 'classnames';
import { IDataset, IImage } from 'common/entities';
import { usePlatformThumbnail } from 'common/hooks/usePlatformThumbnail';
import { GImageSkeletonItem } from 'components/GSkeleton/GImageSkeleton';
import GTooltip from 'components/GTooltip';
import { Stack } from 'components/assets/icons';
import placeholder from 'components/assets/placeholder/placeholder.png';
import Styles from './Styles.module.scss';

export default function GalleryItem({ image, onChangeImage, dataset, activeImageId }: IGalleryItemProps) {
    const imageName = image?.imageName || image.imageId;
    const isSliceExist = Boolean(image?.sliceCount);

    const { image: imageSrc, loading } = usePlatformThumbnail({
        imageId: image?.imageId,
        datasetId: image?.datasetId,
        windowLevel: dataset?.defaultWindowLevel,
        slice: image?.sliceCount ? 0 : -1,
    });

    if (loading)
        return (
            <GImageSkeletonItem
                active
                className={Styles.GallerySkeletonItem}
            />
        );

    return (
        <div
            className={cs(Styles.ThumbnailContainer)}
            id="playground-series-items"
        >
            <div className={cs(Styles.ThumbnailImageWrapper)}>
                <div
                    className={cs(Styles.Thumbnail, { [Styles.ActiveImageLabel]: activeImageId === image.imageId })}
                    onClick={() => onChangeImage(image?.imageId)}
                >
                    <Image
                        width={'100%'}
                        src={imageSrc}
                        placeholder={placeholder}
                        fallback={placeholder}
                        alt=""
                        className={cs(Styles.Image, { [Styles.ActiveImageBorder]: activeImageId === image.imageId })}
                        preview={false}
                        id="gallery-item"
                    />
                </div>
            </div>
            <div className={Styles.itemDesc}>
                <GTooltip title={imageName}>
                    <span
                        className="pBody-medium"
                        style={{ width: isSliceExist ? 100 : 200 }}
                    >
                        {imageName}
                    </span>
                </GTooltip>
                <span>
                    {isSliceExist ? (
                        <>
                            <Stack />
                            <span className="caption2-normal">{image?.sliceCount}</span>
                        </>
                    ) : null}
                </span>
            </div>
        </div>
    );
}

interface IGalleryItemProps {
    image: IImage;
    activeImageId?: string;
    dataset?: IDataset;
    onChangeImage: (imageId: string) => void;
}
