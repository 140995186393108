import { all, takeLatest } from "redux-saga/effects";
import * as actions from "../actions";
import { ComparisonStaticDataSaga } from "./ComparisonStaticDataSaga";
import { getComparisonChartDataSaga } from "./GetComparisonChartDataSaga";

export default function* rootSaga() {
    yield all([
        takeLatest(actions.getComparisonStaticDataTransaction, ComparisonStaticDataSaga),
        takeLatest(actions.getComparisonChartDataTransaction, getComparisonChartDataSaga),
    ]);
}