import cs from 'classnames';
import { getMessages, RoutingHelper } from 'common';
import { LogoutBoxLine16, ReportIssue, SettingLine16, UserMenuCopy } from 'components/assets/icons';
import { clearSession } from 'pages/Authentication/hooks';
import { setIsClipboardOpen, setUserDropdownOpen } from 'pages/User/store';
import { selectIsUserDropdownOpen } from 'pages/User/store/selectors';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { selectCurrentUser } from 'redux/selectors';
import Styles from './Styles.module.scss';
import { GTooltip } from 'components';

export default function useUserMenu() {
    const currentUser = useSelector(selectCurrentUser);
    const dispatch = useDispatch();
    const open = useSelector(selectIsUserDropdownOpen);

    function handleOpenDropdown() {
        dispatch(setUserDropdownOpen(!open));
    }

    const handleLogout = useCallback(() => {
        clearSession();
        dispatch(setUserDropdownOpen(false));
        window.location.reload();
    }, []);

    function handleCloseDropdown() {
        dispatch(setUserDropdownOpen(false));
    }

    function handleShowClipboard() {
        dispatch(setIsClipboardOpen(true));
        handleCloseDropdown();
    }

    const userMenuList = [
        {
            key: 'settings',
            render: (
                <Link
                    onClick={handleCloseDropdown}
                    to={RoutingHelper.User({ location: 'settings', activeView: 'account' }).uri}
                >
                    <div className={Styles.menuListItem}>{getMessages('000228')}</div>
                </Link>
            ),
            icon: <SettingLine16 />,
        },
        {
            key: 'showClipboard',
            render: (
                <div onClick={handleShowClipboard}>
                    <div className={Styles.menuListItem}>{getMessages('001568')}</div>
                </div>
            ),
            icon: <UserMenuCopy />,
        },
        // we will add a report issue function
        // {
        //     key: 'reportIssue',
        //     render: <div className={Styles.menuListItem}>{getMessages('001576')}</div>,
        //     icon: <ReportIssue />,
        // },
        {
            key: 'logout',
            render: (
                <div
                    className={Styles.menuListItem}
                    onClick={handleLogout}
                >
                    {getMessages('000151')}
                </div>
            ),
            icon: <LogoutBoxLine16 />,
        },
    ];

    const renderUserDropdown = (menu: any) => (
        <div>
            <div className={Styles.UserInfo}>
                <div className={Styles.UserName}>
                    {currentUser?.displayName !== currentUser?.email &&
                    currentUser?.displayName !== null &&
                    currentUser?.displayName !== '' ? (
                            <GTooltip
                                title={currentUser?.displayName.length > 15 ? currentUser?.displayName : ''}
                                placement="bottom"
                                >
                                <div className={cs(Styles.DisplayName, 'pBody-medium white')}>{currentUser?.displayName}</div>
                            </GTooltip>
                    ) : null}
                    <div className={cs(Styles.Email, 'pBody-medium white')}>{currentUser?.email}</div>
                </div>
            </div>
            {menu}
        </div>
    );
    return { userMenuList, renderUserDropdown, open, handleOpenDropdown };
}
