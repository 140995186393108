import { camelCaseToTitleCase } from 'common';
import React from 'react';
import { useLocation } from 'react-router-dom';

export function useDocumentTitle() {
    const location = useLocation();

    React.useEffect(() => {
        let title = camelCaseToTitleCase(location?.pathname?.split?.('/')?.[1]);

        document.title = title ? `${title} | Gesund.ai Platform` : 'Gesund.ai Platform';
    }, [location]);
}
