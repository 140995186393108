import { AxiosResponse } from 'axios';
import { APIService, IDataset, IDatasetResponsePayload, TransformDataset } from 'common';
import { put } from 'redux-saga/effects';
import { setArchivedDatasets, setDatasetList, setDatasetListLoading, updateArchiveDataset, updateDataset } from '..';
import { DatasetService } from '../DatasetService';

export function* getDatasetListTransactionSaga(): any {
    try {
        let projectId = localStorage.getItem('activeProject');

        yield put(setDatasetListLoading(true));

        const response: AxiosResponse<Array<IDatasetResponsePayload>> = yield APIService.Datasets.GetDatasetList.call({
            params: { projectId },
        });

        if (response?.data) {
            let payload = response?.data.map(TransformDataset);

            const datasetList: Array<IDataset> = [];
            const archivedDatasetList: Array<IDataset> = [];
            payload.forEach(item => {
                if (!item) return;
                if (item?.archived) {
                    archivedDatasetList.push(item as any);
                } else {
                    datasetList.push(item as any);
                }
            });
            yield put(setDatasetList(datasetList));
            yield put(setArchivedDatasets(archivedDatasetList));
            return payload;
        }
    } catch (e) {
        console.log(e);
    } finally {
        yield put(setDatasetListLoading(false));
    }
}

export function* updateDatasetSaga(payload: IDatasetResponsePayload | Array<IDatasetResponsePayload>) {
    try {
        if (!payload) return;
        if (!Array.isArray(payload)) payload = [payload];

        for (const payloadItem of payload) {
            const dataset = TransformDataset(payloadItem);
            if (dataset.archived) {
                yield put(updateArchiveDataset(dataset));
            } else {
                yield put(updateDataset(dataset));
                yield put(DatasetService.util.invalidateTags(['Dataset']));
            }
        }
    } catch (e) {
        console.log(e);
    }
}
