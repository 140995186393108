import { IDatasetExplorerItem } from 'common/components/DatasetExplorer';
import { isStudyDataset } from 'common/entities';
import { useGetImageListQuery } from 'pages/Datasets/store/DatasetService';
import { IDataSourceArgs } from '../entities';

export function useSimpleDataSource({ dataset, page, pageSize, search, excludeItems }: IDataSourceArgs) {
    const skipImage = search ? 0 : (page - 1) * pageSize;

    const { simpleImageSource, simpleImageSourceLoading, refetch } = useGetImageListQuery(
        {
            dataset_id: dataset?.datasetId,
            skip: skipImage,
            limit: pageSize,
            search,
            excludeImages: excludeItems,
        },
        {
            skip: isStudyDataset(dataset),
            selectFromResult: ({ currentData, isFetching }) => ({
                simpleImageSource: {
                    data: currentData?.data?.map(
                        (image): IDatasetExplorerItem => ({
                            ...image,
                            id: image.imageId,
                            name: image.imageName,
                            type: 'image',
                            thumbnail: [image],
                        })
                    ),
                    total: currentData?.total,
                },
                simpleImageSourceLoading: isFetching,
            }),
        }
    );

    return { simpleImageSource, simpleImageSourceLoading, refetchSimpleImageSource: refetch };
}
