import produce from 'immer';
import { useCallback, useState } from 'react';
import { useEvent } from 'react-use';
import { _tryParseJSON } from '../../Utils';

export class ImageClipboard {
    static get data(): Array<IClipboardItem> {
        return _tryParseJSON(localStorage.getItem(ImageClipboardName)) || [];
    }

    static set data(data: Array<IClipboardItem>) {
        localStorage.setItem(ImageClipboardName, JSON.stringify(data));
    }

    static get dataLength() {
        return ImageClipboard.data?.length || 0;
    }

    static getItem(id: string) {
        return ImageClipboard.data?.find(item => item.id === id);
    }

    static hasItem(id: string) {
        return ImageClipboard.data?.some(item => item.id === id);
    }
}

export interface IClipboardItem {
    id: string;
    name: string;
    datasetId: string;
    datasetName?: string;
    type: 'study' | 'image';
}

export const ImageClipboardName = 'ImageClipboard';

export function useImageClipboardData() {
    const [data, setData] = useState<Array<IClipboardItem>>(ImageClipboard.data);

    const refresh = useCallback(() => {
        setData(ImageClipboard.data);
    }, []);

    useEvent('storage', refresh);

    function updateData(update: Array<IClipboardItem> | ((data: Array<IClipboardItem>) => Array<IClipboardItem>)) {
        if (typeof update === 'function') {
            return setData(_old => {
                ImageClipboard.data = produce(_old, update);
                return ImageClipboard.data;
            });
        }
        setData(update);
        ImageClipboard.data = update;
    }

    function push(item: IClipboardItem) {
        updateData(_old => [..._old, item]);
    }

    function remove(id: string | Array<string>) {
        if (Array.isArray(id)) {
            updateData(_old => _old.filter(item => !id.includes(item.id)));
            return;
        }
        updateData(_old => _old.filter(item => item.id !== id));
    }

    return { data, refresh, updateData, push, remove, getItem: ImageClipboard.getItem, hasItem: ImageClipboard.hasItem };
}
