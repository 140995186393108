import { Dictionary, IImage } from 'common/entities';
import { getDatasetImages } from 'common/services';
import { _toDict, _values } from 'common/Utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useMap, useUpdateEffect } from 'react-use';

export function useInfiniteScrollingImageMap({ datasetId, batchSize = 50, fetch = true, skipSignal, seriesId, studyId }: IArgs) {
    const [imageMap, { reset: resetMap, setAll, set }] = useMap<Dictionary<IImage>>({});
    const [totalCount, setTotalCount] = useState(0);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState<string>();
    const abortController = useRef(new AbortController());
    const [fetchedDataset, setFetchedDataset] = useState<string>();

    if (skipSignal)
        skipSignal.onabort = () => {
            setSkip(skipSignal?.reason ?? 0);
        };

    useEffect(() => {
        reset();

        return () => {
            abortController.current.abort();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [datasetId, studyId, seriesId]);

    const getImageList = useCallback(
        async (_skip = skip, reset = false, limit = batchSize) => {
            try {
                if (loading) abortController.current.abort();
                if (!fetch || !datasetId) return;
                setLoading(true);
                abortController.current = new AbortController();
                const { data = [], total } = await getDatasetImages({
                    datasetId,
                    skip: _skip,
                    limit,
                    search: searchText?.toString(),
                    signal: abortController.current.signal,
                    studyId,
                    seriesId,
                });

                const newMap = reset ? _toDict(data, 'imageId') : Object.assign({}, imageMap, _toDict(data, 'imageId'));

                setAll(newMap);
                setTotalCount(total);
                setFetchedDataset(datasetId);
                return newMap;
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        },
        [batchSize, datasetId, fetch, imageMap, loading, searchText, seriesId, setAll, skip, studyId]
    );

    function reset() {
        resetMap();
        setTotalCount(0);
        setSkip(0);
        getImageList(0, true);
        setSearchText(undefined);
    }

    useUpdateEffect(() => {
        getImageList();
    }, [skip]);

    function onSearch(_search: string) {
        setSkip(0);
    }

    const loadMore = useCallback(
        (index: number) => {
            let _nextSkip = skip + batchSize;
            let limit = batchSize;

            if (loading || totalCount <= _nextSkip || index < _values(imageMap).length - 5) return imageMap;
            if (index > _nextSkip) {
                limit = index - skip;
            }

            setSkip(_nextSkip);

            return getImageList(_nextSkip, false, limit);
        },
        [batchSize, getImageList, imageMap, loading, skip, totalCount]
    );

    return { imageMap, totalCount, skip, loading, onSearch, loadMore, reset, set, fetchedDataset };
}

interface IArgs {
    datasetId: string;
    batchSize?: number;
    fetch?: boolean;
    skipSignal?: AbortSignal;
    studyId?: string;
    seriesId?: string;
}
