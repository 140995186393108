import { NetworkStatus } from 'app/entities';
import { eachOfLimit } from 'async';
import { Dictionary } from 'common/entities';
import { IWindowLevel, isWindowLevelExist } from 'common/enum';
import { PlatformService } from 'common/services';
import produce from 'immer';
import _isEqual from 'lodash/isEqual';
import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { selectNetworkSpeed } from 'redux/selectors';
import { _compactDict, _keys, _mapValues, _values, blobToBase64, isNullOrUndefined } from '../Utils';
import { useDidUpdate } from './useDidUpdate';

export function usePlatformRawImage({
    imageId,
    datasetId,
    windowLevel,
    fetch,
    image_slice = -1,
    return_handler = false,
    preLoadList = [],
    returnType = 'blob',
    rawImage,
    onImagePreloadMapChange,
}: IUsePlatformRawImageArgs): Partial<IPreloadItem> {
    const [imageMap, setImageMap] = useState<Dictionary<IPreloadItem>>({});
    const [currentImage, setCurrentImage] = useState<Partial<IPreloadItem>>({});
    const abortSignallerMap = useRef<Dictionary<AbortController>>({});
    const { status } = useSelector(selectNetworkSpeed);

    useEffect(() => {
        return () => {
            // eslint-disable-next-line react-hooks/exhaustive-deps
            _values(abortSignallerMap.current).forEach(abortController => abortController.abort());

            _values(imageMap).forEach(item => {
                if (item?.image) {
                    window.URL.revokeObjectURL(item.image);
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        const _newItem = imageMap[imageId];
        if (
            _newItem?.image !== currentImage?.image ||
            _newItem?.loading !== currentImage?.loading ||
            _newItem?.downloadProgress !== currentImage?.downloadProgress
        ) {
            setCurrentImage(imageMap[imageId] ?? {});
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageMap, imageId]);

    useEffect(() => {
        if (Object.values(imageMap).length > 50) {
            let abortList: [string, IPreloadItem][] = [];
            setImageMap(oldMap => {
                let imageList = Object.entries(oldMap);

                abortList = imageList.splice(0, imageList?.length - 50);

                const result = imageList?.reduce(
                    (acc, [key, value]) => ({ ...acc, [key]: value }),
                    {} as Dictionary<IPreloadItem>
                );
                onImagePreloadMapChange?.(getImagePreloadMap(result));
                return result;
            });

            abortList.forEach(([key]) => {
                abortSignallerMap.current[key]?.abort();
                delete abortSignallerMap.current[key];
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageMap]);

    useDidUpdate(() => {
        _keys(imageMap).forEach(item => {
            abortSignallerMap.current[item]?.abort();
            delete abortSignallerMap.current[item];
        });
        setImageMap({});
        onImagePreloadMapChange?.({});
        getImage(imageId, null, null);
        eachOfLimit(preLoadList, 10, (id, index, cb) => getImage(id, index, cb));
    }, [windowLevel]);

    useDidUpdate(() => {
        deleteImage(imageId);
        getImage(imageId, null, null);
    }, [image_slice]);

    useEffect(() => {
        getImage(imageId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [imageId, datasetId, fetch]);

    useEffect(() => {
        const newList = preLoadList.filter(item => !imageMap[item]);
        eachOfLimit(newList, 10, getImage);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [preLoadList]);

    function setState(id: string, newState: Partial<IPreloadItem> = {}) {
        setImageMap(oldList =>
            produce(oldList, draft => {
                const item = draft[id] ?? getInitialPreloadItem(windowLevel, image_slice);
                draft[id] = {
                    ...item,
                    ...newState,
                };
                onImagePreloadMapChange?.(getImagePreloadMap(draft));
            })
        );
    }

    function deleteImage(id: string) {
        setImageMap(_old =>
            produce(_old, draft => {
                if (returnType === 'blob') {
                    URL.revokeObjectURL(draft[id]?.image);
                }
                delete draft[id];
                onImagePreloadMapChange?.(getImagePreloadMap(draft));
            })
        );
    }

    async function getImage(image_id: string, _index?: string | number, cb?: any) {
        try {
            if (
                !image_id ||
                !datasetId ||
                fetch === false ||
                (imageMap[image_id]?.image && _isEqual({ image_slice, windowLevel }, imageMap[image_id]?.params))
            )
                return;

            abortSignallerMap.current[image_id]?.abort();

            abortSignallerMap.current[image_id] = new AbortController();

            setState(image_id);

            const params: any = {
                image_id,
                image_slice,
                dataset_id: datasetId,
                ...getImageBaseParams(status),
                return_handler,
            };

            if (!isWindowLevelExist(windowLevel)) {
                params.ww = windowLevel?.ww;
                params.wc = windowLevel?.wc;
            }

            const response = await PlatformService.Manage.GetImage.get({
                params,
                responseType: 'blob',
                onDownloadProgress: progressEvent => {
                    const totalLength = progressEvent.total;
                    if (!isNullOrUndefined(totalLength)) {
                        if (image_id === imageId) {
                            setState(image_id, { downloadProgress: Math.round((progressEvent.loaded / totalLength) * 100) });
                        }
                    }
                },
                signal: abortSignallerMap.current[image_id]?.signal,
            });

            delete abortSignallerMap.current[image_id];

            if (!response?.data) return;

            const image = returnType === 'blob' ? window.URL.createObjectURL(response.data) : await blobToBase64(response.data);

            setState(image_id, {
                image,
                loading: false,
            });
        } catch (error) {
            console.log(error);
            setState(image_id, {
                loading: false,
            });
            cb?.(error);
        }
    }

    function getImageBaseParams(networkStatus: NetworkStatus) {
        return {
            raw_image: rawImage ?? networkStatus !== 'low',
            width: 300,
            height: 300,
        };
    }

    return currentImage;
}

const getImagePreloadMap = (imageMap: Dictionary<IPreloadItem>) => _compactDict(_mapValues(imageMap, item => item.image));

interface IUsePlatformRawImageArgs {
    imageId: string;
    datasetId: string;
    image_slice?: number;
    windowLevel?: IWindowLevel;
    fetch?: boolean;
    return_handler?: boolean;
    preLoadList?: Array<string>;
    returnType?: 'blob' | 'base64';
    rawImage?: boolean;
    onImagePreloadMapChange?: (imageMap: Dictionary<string>) => void;
}

export interface IPreloadItem {
    image: string;
    loading: boolean;
    downloadProgress: number;
    params?: {
        windowLevel?: IWindowLevel;
        image_slice?: number;
    };
}

function getInitialPreloadItem(windowLevel: IWindowLevel, image_slice: number): IPreloadItem {
    return {
        image: null,
        loading: true,
        downloadProgress: 0,
        params: { windowLevel, image_slice },
    };
}
