import { ColumnType } from 'antd/lib/table';
import cx from 'classnames';
import { IDataset } from 'common/entities';
import { useDatasetTable } from 'common/hooks/useDatasetTable';
import { GDataGrid } from 'components/GDataGrid';
import GSelectionTable from 'components/GDataGrid/GSelectionTable';
import { IDataGridProps } from 'components/GDataGrid/helpers/interface';
import Styles from './Styles.module.scss';

export function DatasetTable({
    selection,
    className,
    extraColumns,
    excludeColumns,
    moveSelectedToTop = true,
    ...props
}: IDatasetTableProps) {
    let { columns } = useDatasetTable();

    extraColumns?.forEach(col => columns.splice(col.order, 0, col.column));
    excludeColumns?.forEach(c => (columns = columns.filter(col => col.key !== c)));

    if (selection) {
        return (
            <GSelectionTable<IDataset>
                columns={columns}
                selectionList={selection.selectedDatasetIds}
                idIndex="datasetId"
                onChangeSelection={selection?.onChangeSelection}
                onAllSelected={selection?.onAllSelected}
                selectionType={selection.type}
                allSelected={selection.allSelected}
                selectionElement={selection.selectionElement}
                className={cx(Styles.DatasetTable, className)}
                moveSelectedToTop={moveSelectedToTop}
                {...props}
            />
        );
    }

    return (
        <GDataGrid<IDataset>
            columns={columns}
            className={cx(Styles.DatasetTable, className)}
            rowKey="datasetId"
            sorting={{ enabled: true }}
            {...props}
        />
    );
}

export interface IDatasetTableProps extends IDataGridProps {
    selection?: IDatasetSelection;
    extraColumns?: Array<IExtraColumn>;
    excludeColumns?: Array<string>;
    moveSelectedToTop?: boolean;
}

export interface IExtraColumn {
    column: ColumnType<IDataset>;
    order?: number;
}
export interface IDatasetSelection {
    type: 'single' | 'multiple';
    selectedDatasetIds?: string[];
    allSelected?: boolean;
    onChangeSelection?: (datasetId: string, checked: boolean) => void;
    onAllSelected?: (allSelected: boolean) => void;
    selectionElement?: (datasetId?: string) => React.ReactNode;
}
