import { getDeviceId, getSessionId } from 'common/Utils';

export function getDefaultHeaders() {
    return {
        Authorization: `Bearer ${sessionStorage.getItem('accessToken')}`,
        activity: 'user',
        'x-session-id': getSessionId(),
        'x-device-id': getDeviceId(),
        'x-project-id': localStorage.getItem('activeProject'),
    };
}
