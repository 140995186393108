import { APIService, _entries, _keys } from "common";
import { setMetricsChartData, setMetricsChartDefaultData, setMetricsChartDefaultParams, setMetricsChartGraphParams, setMetricsExtraData, updateMetricsCommonState } from "pages/Validation/store";
import { call, fork, put } from "redux-saga/effects";
import { ChartKey, IMetricsExtra, MetricsDataType } from "../../entities";
import { GetChartDataFromResponse, setLayoutStateSaga } from "./metricsChartSaga";

export function* setStaticPayloadSaga(batch_job_id: string): any {
    try {

        yield fork(setLayoutStateSaga, "setAll", { isLoading: true });
        const result = yield call(getStaticPayloadSaga, batch_job_id);

        if (result) {
            const { metricsExtraPayload, dataPayload, paramPayload } = result;

            if (_keys(paramPayload).length) {
                yield put(setMetricsChartGraphParams(paramPayload));
                yield put(setMetricsChartDefaultParams(paramPayload));
            }

            yield put(setMetricsExtraData(metricsExtraPayload));
            yield put(setMetricsChartData(dataPayload));
            yield put(setMetricsChartDefaultData(dataPayload));
            yield put(updateMetricsCommonState({ staticPayloadFetched: batch_job_id }));

            yield fork(setLayoutStateSaga, "setAll", { isLoading: false });

        } else {
            yield fork(setLayoutStateSaga, "setAll", { isEmpty: true });
        }
    } catch (error) {
        console.log(error);
        yield fork(setLayoutStateSaga, "setAll", { isEmpty: true });
    } finally {
        yield put(updateMetricsCommonState({ pageLoading: false }));
    }
}

export function* getStaticPayloadSaga(batch_job_id: string): any {
    try {
        const response = yield APIService.Validation.GetStaticPayload.call({ params: { batch_job_id } });

        if (!response?.data) return;
        const dataPayload: Partial<Record<ChartKey, any>> = {};
        const paramPayload: Partial<Record<ChartKey, any>> = {};
        const metricsExtraPayload: IMetricsExtra = {
            classMappings: response.data.class_mappings,
            trainingMetricsExist: response.data.training_metrics_exists,
            metaData: response.data.meta_data
        };

        _entries(MetricsDataType).forEach(([key, graphID]: [ChartKey, MetricsDataType]) => {
            const responseDataItem = response.data[graphID];
            const defaultParams = responseDataItem?.params;
            const graphData = GetChartDataFromResponse[graphID]?.(responseDataItem?.payload?.data) || responseDataItem?.payload?.data;

            if (!graphData) return;
            dataPayload[key] = graphData;
            paramPayload[key] = defaultParams;

        });

        return { metricsExtraPayload, dataPayload, paramPayload };

    } catch (error) {
        console.error(error);
    }
}