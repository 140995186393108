import { Dictionary, PropertyPath } from 'common/entities';
import { Dispatch, SetStateAction, useCallback, useState } from 'react';
import produce from 'immer';
import { _set } from 'common/Utils';

export function useNestedState<T extends object = Dictionary>(firstState: T): IReturnType<T> {
    const [state, setState] = useState<T>(firstState);

    const setNestedState = useCallback((path: PropertyPath, value: any) => {
        setState((prevState: T) => produce(prevState, draft => {
            _set(draft, path, value);
        }));
    }, []);

    return [state, setNestedState, { setState }];
}

type IReturnType<T> = [T, (path: PropertyPath, value: any) => void, { setState: Dispatch<SetStateAction<T>>; }];