import cs from 'classnames';
import { IDataset, getMessages } from 'common';
import { IModel } from 'pages/Models/entities';
import Styles from './Styles.module.scss';

export function getStartValidationMessageContent(model: IModel, dataset: IDataset, description: string) {
    return (
        <div className={Styles.StartValidationModal}>
            <div className={cs(Styles.Header, 'subtitle2-bold')}>{getMessages('000433')}</div>
            <div className="pBody-normal">
                <span className={Styles.Selected}>{dataset?.datasetName} </span>
                <span>{getMessages('000436')}</span>
                <br />
                <span className={Styles.Selected}>{model?.modelName} </span>
                <span>{getMessages('000437')}</span>
                <div className={Styles.ModelSettings}>{description} </div>
            </div>
        </div>
    );
}
