import { Button, TableColumnsType } from 'antd';
import cx from 'classnames';
import { EventBus, IClipboardItem, RoutingHelper, camelCaseToTitleCase, getMessages, useImageClipboardData } from 'common';
import { Notification } from 'components';
import { setIsClipboardOpen } from 'pages/User/store';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { useSet } from 'react-use';
import Styles from './Styles.module.scss';

export default function useGetClipboardData() {
    const dispatch = useDispatch();
    const { data, remove, updateData } = useImageClipboardData();
    const [selectedItems, { toggle }] = useSet<string>();

    function handleModalClose() {
        dispatch(setIsClipboardOpen(false));
    }

    function resetClipboard() {
        updateData([]);
    }

    function onRemoveSelected() {
        remove(Array.from(selectedItems));
        Notification.info({ content: getMessages('001573') });
    }

    return { resetClipboard, onRemoveSelected, data, handleModalClose, selectedItems, toggle };
}

export const columns: TableColumnsType<IClipboardItem> = [
    {
        dataIndex: 'id',
        key: 'id',
        width: 200,
        title: getMessages('001072'),
        render: (value, record, index) => (
            <Button
                className={Styles.OpenPlaygroundBtn}
                type="link"
                onClick={() => {
                    EventBus.emit('dispatch', setIsClipboardOpen(false));
                    const payload = { dataset: record?.datasetId, [record.type]: record?.id };
                    EventBus.emit('openPlayground', payload);
                }}
                id="clipboard-modal-open-playgound-btn"
            >
                <div className={cx('pBody-normal', Styles.BaseCell)}>{value}</div>
            </Button>
        ),
    },
    {
        dataIndex: 'name',
        key: 'name',
        width: 200,
        title: `${getMessages('000702')} / ${getMessages('000081')}`,
    },
    {
        dataIndex: 'type',
        key: 'type',
        title: getMessages('000487'),
        width: 200,
        render: value => camelCaseToTitleCase(value),
    },
    {
        dataIndex: 'datasetName',
        key: 'datasetName',
        title: getMessages('000039'),
        width: 200,
        render: (value, record) => (
            <Link to={RoutingHelper.Dataset({ activeView: 'dataset-details', datasetId: record?.datasetId }).uri}>
                <div className={Styles.BaseCell}>
                    <div>{value}</div>
                    <div className={cx('pBody-normal grey', Styles.CellSummary)}>{record?.datasetId}</div>
                </div>
            </Link>
        ),
    },
];
