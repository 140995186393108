
import { PayloadAction } from "@reduxjs/toolkit";
import { IValidationRun } from "pages/Validation/views/summary/entities";
import { updateValidationListSaga } from "pages/Validation/views/summary/store/saga/ValidationDataSaga";
import { fork } from "redux-saga/effects";

export function* favoriteBSARoundSaga(sagaAction: PayloadAction<IValidationRun>): any {
    try {
        yield fork(updateValidationListSaga, sagaAction.payload);
    } catch (error) {
        console.log(error);
    }
}