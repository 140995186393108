import { _entries } from './_entries';

export function _filterDict<T>(dict: T, predicate: (key: keyof T, value: T[keyof T]) => boolean): Partial<T> {
    return _entries(dict).reduce((acc: Partial<T>, [key, value]) => {
        if (predicate(key, value)) {
            acc[key] = value;
        }
        return acc;
    }, {});
}
