import GButton from 'components/GButton/index';
import GDropdown, { IDropdownMenuItem } from 'components/GDropdown';
import { MenuDashesVertical } from 'components/assets/icons';
import Styles from './Styles.module.scss';

export default function DetailsMenu({ menu, setVisible, visible }: IDetailsMenuProps) {
    return (
        <div
            onClick={e => {
                e.preventDefault();
                e.stopPropagation();
            }}
        >
            <GDropdown
                open={visible}
                onOpenChange={setVisible}
                menuItemList={menu}
                id="dataset-card-details-menu"
                className={Styles.DetailsMenuDropdown}
            >
                <GButton
                    size="middle"
                    type="default"
                    shape="circle"
                >
                    <MenuDashesVertical />
                </GButton>
            </GDropdown>
        </div>
    );
}

export interface IDetailsMenuProps {
    menu?: Array<IDropdownMenuItem>;
    visible?: boolean;
    setVisible?: (visible: boolean) => void;
}
