/* eslint-disable eqeqeq */
import { PropertyName } from "common/entities";
import { _get } from "./_get";

export function _maxBy<T>(arr: Array<T>, predicate: ((o: T, k: number, arr: T[]) => any) | keyof T | Array<PropertyName>): T {
    if (!arr || !predicate) return arr ?? [] as any;
    const cb = typeof predicate === 'function' ? predicate : (o: T) => _get(o, predicate as any);

    return arr.find((o, i, a) => Math.max(...arr.map(cb)) == cb(o, i, a));
}
