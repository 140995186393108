export interface IAnnotationState {
    annotationId: string;
    userId: string;
}

export function AnnotationStateFactory(data?: Partial<IAnnotationState>): IAnnotationState {
    return {
        annotationId: data?.annotationId ?? '',
        userId: data?.userId ?? '',
    };
}
