export interface ICustomSubCohort {
    open: boolean;
    validationId: string;
}

export function CustomSubCohortFactory(data?: Partial<ICustomSubCohort>): ICustomSubCohort {
    return {
        open: data?.open ?? false,
        validationId: data?.validationId ?? ''
    };
}
