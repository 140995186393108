import { IDataset } from 'common';
import GFallback from 'components/GFallback';
import { useState } from 'react';
import { DicomViewerStateProvider } from './DicomViewerState';
import { ImageStateProvider } from './ImageState';
import { AssignmentState } from './ImageState/useSimpleImageList';
import { useTransactions } from './Transactions';

export * from './DicomViewerState';
export * from './ImageState';
export * from './ImageState/useActiveImageDetails';
export * from './Transactions';
export * from './useActiveViewport';
export * from './useViewerImageList';

export function DicomViewerProvider({ dataset, children, studyId, assignmentState }: IDicomViewerProviderProps) {
    const [isLoaded, setIsLoaded] = useState(false);
    return (
        <>
            <DicomViewerStateProvider
                setIsLoaded={setIsLoaded}
                dataset={dataset}
            >
                {isLoaded ? (
                    <ImageStateProvider
                        dataset={dataset}
                        assignmentState={assignmentState}
                    >
                        <DicomViewerStateful studyId={studyId}>{children}</DicomViewerStateful>
                    </ImageStateProvider>
                ) : (
                    <GFallback />
                )}
            </DicomViewerStateProvider>
        </>
    );
}

export function DicomViewerStateful({ children, studyId }: Omit<IDicomViewerProviderProps, 'dataset'>) {
    useTransactions({ studyId });

    return <>{children}</>;
}

export interface IDicomViewerProviderProps {
    dataset: IDataset;
    studyId?: string;
    children?: React.ReactNode;
    assignmentState?: AssignmentState;
}
