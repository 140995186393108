import { Dictionary, IImage } from 'common';
import { IAugmentationProperties, IImageControls } from 'pages/Playground/entities';
import { AugmentationPropertiesFactory } from 'pages/Playground/entities/IAugmentation';
import { ImageControlsFactory } from 'pages/Playground/entities/IPredictionControls';


export interface IRobustValidationState {
    imageMap: Dictionary<IImage>;
    totalDataSize: number;
    selectedImage: string;
    augmentationProperties: IAugmentationProperties;
    imageControls: IImageControls;
    probabilityMap: Dictionary<number>;
}

export function RobustValidationStateFactory(data?: Partial<IRobustValidationState>): IRobustValidationState {
    return {
        imageMap: data?.imageMap ?? {},
        totalDataSize: data?.totalDataSize ?? null,
        selectedImage: data?.selectedImage ?? '',
        augmentationProperties: AugmentationPropertiesFactory(),
        imageControls: ImageControlsFactory(),
        probabilityMap: data?.probabilityMap ?? {}
    };
}