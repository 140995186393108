import React, { useEffect, useState } from 'react';

export function useStepActions({ currentStep, disableNext, onCurrentChange}: IArgs) {
    const [current, setCurrent] = useState(0);
    const [completedSteps, setCompletedSteps] = useState<Array<number>>([]);

    useEffect(() => {
        if ((currentStep || currentStep === 0) && current !== currentStep) setCurrent(currentStep);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep]);

    const next = () => {
        if (disableNext) return;
        setCurrent(current + 1);
        onCurrentChange?.(current + 1);
        setCompletedSteps([...completedSteps, current]);
    };

    const prev = () => {
        setCurrent(current - 1);
        onCurrentChange?.(current - 1);
    };

    const onClickStep = (index: number, disabled: boolean) => {
        if (disabled) return;
        setCurrent(index);
        onCurrentChange?.(index);
    };

    const reset = () => {
        setCurrent(0);
        onCurrentChange?.(0);
        setCompletedSteps([]);
    };

    return { current, completedSteps, next, prev, onClickStep, reset };
}

export interface IArgs {
    currentStep?: number;
    disableNext?: boolean;
    onCurrentChange?: (current: number) => void;
}

export interface IStep {
    title: string;
    content: React.ReactNode;
}
