import { ResponsiveScatterPlotCanvas, ScatterPlotCanvasProps, ScatterPlotMouseHandler, ScatterPlotNodeDynamicSizeSpec } from '@nivo/scatterplot';
import { ChartHelper, Dictionary } from 'common';
import { useRef } from 'react';
import useEventCallback from 'use-event-callback';
import { IBaseChartProps } from '../entities';
import { useSerieLegend } from '../hooks';
import { ISerie } from '../hooks/useSerieLegend';
import { nivoTheme, nivoThemeLight } from '../nivoTheme';

export default function ScatterChart({ chartID, chartOptions, className, data, }: IScatterChartProps) {
    const lightTheme = chartOptions?.lightTheme
    const { renderSerieLegends, selectedSeries } = useSerieLegend({ data, lightTheme });
    const sizeMap = useRef<Dictionary<number>>({});

    const chartData = data?.filter(item => selectedSeries?.includes(item.id));

    const onMouseEnter: ScatterPlotMouseHandler<any> = useEventCallback((node, e) => {
        if (chartOptions?.highlightOnHover) {
            sizeMap.current[node.id] = node.size;
            node.size = node.size * 1.5;
        }
        (e.target as any).style.cursor = 'pointer';
    });

    const onMouseLeave: ScatterPlotMouseHandler<any> = useEventCallback((node, e) => {
        if (chartOptions?.highlightOnHover && sizeMap.current[node.id]) {
            node.size = sizeMap.current[node.id];
        }
        (e.target as any).style.cursor = 'default';
    });

    return (
        <>
            <div id={chartID} className={className} >
                {chartOptions.legendEnabled && renderSerieLegends({ marginLeft: 32 })}

                <ResponsiveScatterPlotCanvas
                    data={chartData}
                    margin={{ top: 20, right: 10, bottom: 20, left: 40 }}
                    xScale={chartOptions?.xScale}
                    xFormat=" >-.1f"
                    yScale={chartOptions?.yScale}
                    yFormat=">-.1f"
                    nodeSize={chartOptions?.nodeSize ?? 10}
                    axisTop={null}
                    axisRight={null}
                    theme={chartOptions?.lightTheme ? nivoThemeLight : nivoTheme}
                    onClick={chartOptions?.onPointClick}
                    colors={chartOptions?.colors}
                    tooltip={chartOptions?.tooltip}
                    onMouseEnter={onMouseEnter}
                    onMouseLeave={onMouseLeave}
                />
            </div>
        </>
    );
}

interface IScatterChartProps extends IBaseChartProps {
    data?: Array<ISerie>;
    getChartHelperRef?: (chartHelperRef: ChartHelper.XYChart) => void;
    chartOptions?: IChartOptions;
}

export interface IChartOptions extends Partial<Omit<ScatterPlotCanvasProps<any>, "width" | "height">> {
    onPointClick?: (point: Dictionary<any>, ev: any) => void;
    legendEnabled?: boolean;
    zoomEnabled?: boolean;
    nodeSize?: number | ScatterPlotNodeDynamicSizeSpec;
    highlightOnHover?: boolean;
    lightTheme?: boolean;
}