import { IWindowLevel } from "common";

export interface IValidationParams {
    imageCount: number;
    batchSize: number;
    numberOfWorker: number;
    windowLevel: IWindowLevel;
    generateMetrics: boolean;
    rowCount?: number;
}

export function ValidationParamsFactory(data?: Partial<IValidationParams>): IValidationParams {
    return {
        imageCount: data?.imageCount ?? null,
        batchSize: data?.batchSize ?? 32,
        numberOfWorker: data?.numberOfWorker ?? 1,
        windowLevel: data?.windowLevel ?? null,
        generateMetrics: data?.generateMetrics ?? true,
        rowCount: data?.rowCount ?? null,
    };
}