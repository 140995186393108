import { getMessages } from 'common';
import { GButton, GModal } from 'components';
import Styles from './Styles.module.scss';
import { useSessionReminder } from './useSessionReminder';
import cx from "classnames";
import { TimeFill80 } from 'components/assets/icons';

export default function SessionReminder() {
    const { modalProps, counter, onClickContinue, onClickCancel } = useSessionReminder();

    return (
        <>
            <GModal {...modalProps}>
                <TimeFill80 className={Styles.Icon} />
                <div className={cx('subtitle2-bold white', Styles.Text)}>{getMessages('000868')} </div>
                <div className={cx('pBody-normal white', Styles.Counter)}>
                    {getMessages('000972')}
                    <span className={cx('pBody-normal primary', Styles.Counter)}> {counter} </span>
                    {getMessages('000973')}.
                </div>

                <div className={Styles.ButtonContainer}>
                    <GButton
                        className={Styles.Button}
                        type="default"
                        onClick={onClickCancel}
                        id="login-session-reminder-cancel"
                    >
                        {getMessages('000238')}{' '}
                    </GButton>
                    <GButton
                        className={Styles.Button}
                        onClick={onClickContinue}
                        id="login-session-reminder-ok"
                    >
                        {getMessages('000141')}{' '}
                    </GButton>
                </div>
            </GModal>
        </>
    );
}
