import { useEventBus, useOpenPlayground, useUpdateSearchParams } from 'common';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDocumentTitle } from './useDocumentTitle';
import { useOAuthRedirect } from './useOAuthRedirect';

export function useRouteBasedSubscribeEvents() {
    const navigate = useNavigate();
    const updateSearchParams = useUpdateSearchParams();
    const openPlayground = useOpenPlayground();

    useDocumentTitle();
    useOAuthRedirect();

    const onNavigate = useCallback(({ path, options }: any) => navigate(path, options), [navigate]);

    useEventBus('navigate', onNavigate);
    useEventBus('updateSearchParams', updateSearchParams);
    useEventBus('openPlayground', openPlayground);
}
