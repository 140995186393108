import ApiClient from './apiClient';
import { call, fork } from 'redux-saga/effects';
import { AxiosRequestConfig } from 'axios';

export class GetMethod extends ApiClient {
    *call(config?: AxiosRequestConfig): any {
        return yield call(this.get, config);
    }

    *fork(config?: AxiosRequestConfig): any {
        return yield fork(this.get, config);
    }
}

export class PostMethod extends ApiClient {
    *call<T = any>(payload?: T, config?: AxiosRequestConfig): any {
        return yield call(this.post, payload, config);
    }

    *fork<T = any>(payload?: T, config?: AxiosRequestConfig): any {
        return yield fork(this.post, payload, config);
    }
}
