import { Dictionary } from "common";

export interface IMetadataFilters {
    open: boolean;
    selectedMetadataFields: Dictionary;
}

export function MetadataFiltersFactory(data?: Partial<IMetadataFilters>): IMetadataFilters {
    return {
        open: data?.open ?? false,
        selectedMetadataFields: data?.selectedMetadataFields ?? {},
    };
}