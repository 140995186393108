import { isStudyDataset } from 'common';
import { useGetSeriesImages } from 'pages/Datasets/store/hooks';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { getViewportSeriesIdSelector, getViewportStateSelector, selectActiveViewport } from '../store';
import { useDataset, useIncludedImageListState, useSimpleImageList } from './ImageState';

export function useViewerImageList(index?: number, viewportStack?: boolean) {
    const dataset = useDataset();
    const activeViewportIndex = useSelector(selectActiveViewport);
    const _index = index ?? activeViewportIndex;
    const seriesId = useSelector(useMemo(() => getViewportSeriesIdSelector(_index), [_index]));
    const viewportState = useSelector(useMemo(() => getViewportStateSelector(index), [index]));
    const [{ enabled, imageList }] = useIncludedImageListState();

    const { seriesImages } = useGetSeriesImages(dataset?.datasetId, seriesId, !isStudyDataset(dataset));

    const simpleImageList = useSimpleImageList();
    let imageIndex = simpleImageList?.findIndex(image => image?.imageId === viewportState?.imageId);
    imageIndex = imageIndex === -1 ? 0 : imageIndex;

    const viewportImageList = viewportStack ? simpleImageList?.slice(imageIndex, imageIndex + 1) : simpleImageList;
    const viewerImageList = isStudyDataset(dataset) ? seriesImages?.data : viewportImageList;

    const enabledImageList = useMemo(
        () => viewerImageList?.filter(image => imageList?.includes(image?.imageId)),
        [imageList, viewerImageList]
    );

    return enabled ? enabledImageList : viewerImageList;
}

export function useViewerImageListLoading(index?: number) {
    const dataset = useDataset();
    const activeIndex = useSelector(selectActiveViewport);

    const _index = index ?? activeIndex;
    const seriesId = useSelector(useMemo(() => getViewportSeriesIdSelector(_index), [_index]));

    return useGetSeriesImages(dataset?.datasetId, seriesId, !isStudyDataset(dataset)).loading;
}
