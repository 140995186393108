import { SegmentedLabeledOption } from 'antd/lib/segmented';
import { getMessages } from 'common/helpers';
import GSegmented from 'components/GSegmented';
import { useEffect } from 'react';
import { useViewState } from './GalleryState';
import Styles from './Styles.module.scss';
import { IGalleryProps } from './interface';

export default function ViewNavigation({ seriesList }: IGalleryProps) {
    const [view, setView] = useViewState();
    const inputs = document.getElementsByTagName('input');

    useEffect(() => {
        for (let input of inputs) {
            input.blur();
        }
    }, [view]);

    if (!seriesList?.length) return null;

    return (
        <div className={Styles?.ViewNavigation}>
            <GSegmented
                options={options}
                onChange={value => setView(value as any)}
                value={view}
                className={Styles.Segmented}
            />
        </div>
    );
}

const options: SegmentedLabeledOption[] = [
    {
        value: 'image',
        label: getMessages('001311'),
    },
    {
        value: 'series',
        label: getMessages('001172'),
    },
];
