import cx from "classnames";
import { Dictionary } from 'common/entities';
import { camelCaseToTitleCase, IsIncludeOrEqual, _entries, _isEmpty } from 'common/Utils';
import GTooltip from 'components/GTooltip';
import Styles from "./Styles.module.scss";


export default function TagList({ tagList }: IProps) {
    return (
        <div className={Styles.TagListContainer}>
            {_entries(tagList)
                .filter(([_, value]) => !_isEmpty(value) && !IsIncludeOrEqual(value, 'N/A'))
                .map(([key, value]) => (
                    <GTooltip
                        key={key}
                        title={renderTagTitle(key, value)}
                        placement="top"
                    >
                        <div className={cx(Styles.InfoTagItem, 'caption2-bold truncate')}>{getValueFromField(value)}</div>
                    </GTooltip>
                ))}
        </div>
    );
}

export const getValueFromField = (field: string | Array<string>) => Array.isArray(field) ? field.join(" ") : field;
export const renderTagTitle = (key: string, value: string | Array<string>) => `${camelCaseToTitleCase(key)}: ${getValueFromField(value)}`;

interface IProps {
    tagList?: Dictionary<string | Array<string>>;
}