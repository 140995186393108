import { _compact } from 'common/Utils';
import { AnnotationTool, IAnnotation } from 'pages/AnnotationCS/entities';
import {
    IAngleMeasurementData,
    IBidirectionalMeasurementData,
    ICobbAngleMeasurementData,
    ILengthMeasurementData,
    IMeasurementData,
    IPolygonMeasurementData,
    IRectangleRoiMeasurementData,
} from './interface';

export class AnnotationAdapter {
    static transformObjectFromCornerstone<T extends AnnotationTool>(
        shape: T,
        measurement: IMeasurementData<any>,
        data: IAnnotation
    ): IAnnotation {
        switch (shape) {
            case 'FreehandRoi':
                return AnnotationAdapter.transformPolygonFromCornerstone(measurement, data);

            case 'CobbAngle':
                return AnnotationAdapter.transformCobbAngleFromCornerstone(measurement, data);

            case 'Angle':
                return AnnotationAdapter.transformAngleFromCornerstone(measurement, data);

            case 'Length':
                return AnnotationAdapter.transformLengthFromCornerstone(measurement, data);

            case 'Bidirectional':
                return AnnotationAdapter.transformBidirectionalFromCornerstone(measurement, data);

            case 'RectangleRoi':
                return AnnotationAdapter.transformRectangleFromCornerstone(measurement, data);

            case 'Brush':
            default:
                return data;
        }
    }

    static transformCobbAngleFromCornerstone(measurement: ICobbAngleMeasurementData, data: IAnnotation): IAnnotation {
        return {
            id: measurement.uuid,
            value: measurement.rAngle,
            unit: measurement.unit,
            points: _compact([
                measurement?.handles?.start,
                measurement?.handles?.end,
                measurement?.handles?.start2,
                measurement?.handles?.end2,
            ]),
            ...data,
        };
    }

    static transformAngleFromCornerstone(measurement: IAngleMeasurementData, data: IAnnotation): IAnnotation {
        return {
            id: measurement.uuid,
            value: measurement.rAngle,
            unit: measurement.unit,
            points: _compact([measurement?.handles?.start, measurement?.handles?.middle, measurement?.handles?.end]),
            ...data,
        };
    }

    static transformLengthFromCornerstone(measurement: ILengthMeasurementData, data: IAnnotation): IAnnotation {
        return {
            id: measurement.uuid,
            value: _roundLengthValue(measurement.length),
            unit: measurement.unit,
            points: _compact([measurement?.handles?.start, measurement?.handles?.end]),
            ...data,
        };
    }

    static transformPolygonFromCornerstone(measurement: IPolygonMeasurementData, data: IAnnotation): IAnnotation {
        return {
            id: measurement.uuid,
            unit: measurement.unit,
            value: measurement.area,
            points: measurement.handles.points.map(({ x, y }) => ({ x, y })),
            ...data,
        };
    }

    static transformBidirectionalFromCornerstone(measurement: IBidirectionalMeasurementData, data: IAnnotation): IAnnotation {
        return {
            id: measurement.uuid,
            value: { L: _roundLengthValue(measurement.longestDiameter), W: _roundLengthValue(measurement.shortestDiameter) },
            unit: measurement.unit,
            points: _compact([
                measurement?.handles?.start,
                measurement?.handles?.end,
                measurement?.handles?.perpendicularStart,
                measurement?.handles?.perpendicularEnd,
            ]),
            ...data,
        };
    }

    static transformRectangleFromCornerstone(measurement: IRectangleRoiMeasurementData, data: IAnnotation): IAnnotation {
        return {
            id: measurement.uuid,
            value: measurement.cachedStats?.area,
            unit: measurement.unit,
            points: _compact([measurement?.handles?.start, measurement?.handles?.end]),
            ...data,
        };
    }

    static transformLabelsFromCornerstone() {}
}
function _roundLengthValue(value: number) {
    return Math.round(value * 100) / 100;
}
