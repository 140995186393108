import { Divider } from 'antd';
import { selectEnvConfiguration } from 'app/store/selectors';
import cx from "classnames";
import { getMessages } from 'common';
import { GButton } from 'components';
import { Google24, Microsoft24 } from 'components/assets/icons';
import { useSelector } from 'react-redux';
import { selectServerNetworkStatus } from 'redux/selectors';
import Styles from './Styles.module.scss';
import { getGoogleUrl } from './getGoogleUrl';
import { getMicrosoftURL } from './getMicrosoftUrl';

export default function OAuth() {
    const serverNetwork = useSelector(selectServerNetworkStatus);
    const { oauth } = useSelector(selectEnvConfiguration);

    async function onClickMicrosoft() {
        try {
            const url = await getMicrosoftURL();
            window.location.href = url;
        } catch (error) {
            console.log(error);
        }
    }

    if (serverNetwork !== 'online' || (!oauth?.google && !oauth?.microsoft)) return null;

    return (
        <>
            <Divider className={cx('pBody-medium', Styles.Divider)}> {getMessages('000678')} </Divider>

            <div className={Styles.OAuthContainer}>
                {oauth?.google ? (
                    <a
                        href={getGoogleUrl()}
                        id="oauth-google-button"
                    >
                        <GButton
                            type="default"
                            size="large"
                            endIcon={<Google24 />}
                        >
                            {getMessages('000016')}{' '}
                        </GButton>
                    </a>
                ) : null}

                {oauth?.microsoft ? (
                    <div
                        onClick={onClickMicrosoft}
                        id="oauth-microsoft-button"
                    >
                        <GButton
                            type="default"
                            size="large"
                            endIcon={<Microsoft24 />}
                        >
                            {getMessages('000879')}{' '}
                        </GButton>
                    </div>
                ) : null}
            </div>
        </>
    );
}