import { INetworkSpeedMeasures } from "app/entities";

const fileSizeInBytes = 5110941;

export function getNetworkSpeed(): Promise<INetworkSpeedMeasures> {
    return new Promise((res, rej) => {
        let startTime: number, endTime: number;
        const download = new Image();

        download.onload = function () {
            endTime = (new Date()).getTime();

            const duration = (endTime - startTime) / 1000;
            const bitsLoaded = fileSizeInBytes * 8;

            const bps = (bitsLoaded / duration).toFixed(2);
            const kbps = (Number(bps) / 1024).toFixed(2);
            const mbps = (Number(kbps) / 1024).toFixed(2);

            const speed = Number(mbps);

            const status = !isNaN(speed) && speed < 5 ? "low" : "online";

            res({ bps, kbps, mbps, status });
        };

        download.onerror = function (err, msg) {
            console.error("Invalid image, or error downloading");
        };

        startTime = (new Date()).getTime();

        const cacheBuster = "?nnn=" + startTime;
        download.src = "/speed-test/TestImage.png" + cacheBuster;
    });
}