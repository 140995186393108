import { createAction } from '@reduxjs/toolkit';
import { IValidationRun } from '../entities';

export const EDIT_VALIDATION_ITEM_TRANSACTION = 'EDIT_VALIDATION_ITEM_TRANSACTION';
export const GET_VALIDATION_LIST_TRANSACTION = 'GET_VALIDATION_LIST_TRANSACTION';
export const RESTART_VALIDATION_TRANSACTION = 'RESTART_VALIDATION_TRANSACTION';
export const DELETE_VALIDATION_TRANSACTION = 'DELETE_VALIDATION_TRANSACTION';
export const UPDATE_VALIDATION_LIST_TRANSACTION = 'UPDATE_VALIDATION_LIST_TRANSACTION';

export const editValidationItemTransaction = createAction<any>(EDIT_VALIDATION_ITEM_TRANSACTION);
export const getValidationListTransaction = createAction(GET_VALIDATION_LIST_TRANSACTION);
export const updateValidationListTransaction = createAction<IValidationRun>(UPDATE_VALIDATION_LIST_TRANSACTION);
