import { getStudySeriesParams, useGetImageListQuery, useGetStudySeriesQuery } from '../DatasetService';

export function useGetStudySeries(dataset_id: string, study_id: string, skip?: boolean) {
    return useGetStudySeriesQuery(getStudySeriesParams(dataset_id, study_id), { skip: skip || !dataset_id || !study_id });
}

export function useGetSeriesImages(dataset_id: string, series_id: string, skip?: boolean) {
    return useGetImageListQuery(
        { dataset_id, seriesId: series_id },
        {
            skip: skip || !dataset_id || !series_id,
            selectFromResult: ({ currentData, isFetching }) => ({ seriesImages: currentData, loading: isFetching }),
        }
    );
}
