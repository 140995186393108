import { _entries, _isEmpty } from 'common/Utils';
import {
    getSearchOptionsWithHighlight,
    useHandleSearch,
} from 'common/components/DashboardView/components/FilterPanel/useHandleSearch';
import { Dictionary, FilterInput, SortingKey } from 'common/entities';
import Fuse from 'fuse.js';
import { IAnnotationTracking, ProjectStatus, TAnnotationStatus } from 'pages/AnnotationCS/entities';

let fuseResult: Fuse.FuseResult<any>[];
let fuseResultIds: Array<string>;

const options = {
    includeScore: true,
    minMatchCharLength: 3,
    includeMatches: true,
    keys: ['title'],
};

export function useFilteredAnnotationList<T extends IAnnotationFilters>({
    filters,
    archived,
    sortBy,
    additionalFiltering,
    annotationList,
    ...args
}: IUseFilteredAnnotationListArgs<T>) {
    const searchOptions = annotationList?.map(item => ({
        value: item.annotationId,
        title: item.annotationName,
        label: <div key={item.annotationId}>{item.annotationName}</div>,
    }));
    const { handleSearch } = useHandleSearch(searchOptions, options);

    let result: any = structuredClone(annotationList);

    _entries(filters).forEach(([key, value]) => {
        if (_isEmpty(value)) return;
        switch (key) {
            case 'search':
                fuseResult = handleSearch(value);
                fuseResultIds = fuseResult?.map(i => {
                    return i.item?.value;
                });

                if (value === '') return;
                result = result
                    ?.filter((d: { annotationId: string }) => fuseResultIds?.includes(d.annotationId))
                    .sort(function (a: IAnnotationTracking, b: IAnnotationTracking) {
                        return fuseResultIds.indexOf(a.annotationId) - fuseResultIds.indexOf(b.annotationId);
                    });

                break;
            case 'projectStatus':
                if (!value) return;
                result = result.filter((item: { projectStatus: ProjectStatus }) => item.projectStatus === value);
                break;
            default:
                break;
        }
    });

    const annotationIds = result?.map((item: { annotationId: any }) => item.annotationId);
    const filteredFuseResult = fuseResult?.filter(d => annotationIds?.includes(d.item.value));
    const searchOptionsWithHighlight = getSearchOptionsWithHighlight(filteredFuseResult);

    return {
        filteredAnnotationList: result,
        searchOptionsWithHighlight,
    };
}

export interface IUseFilteredAnnotationListArgs<T> {
    annotationList?: Array<IAnnotationTracking>;
    filters: Partial<T> | Dictionary;
    sortBy?: SortingKey;
    archived?: boolean;
    additionalFiltering?: (annotationList: Array<IAnnotationTracking>, key: keyof T, value: any) => Array<IAnnotationTracking>;
}

export interface IAnnotationFilters {
    search: string;
    createdAt: number;
    projectStatus?: ProjectStatus;
    modality: FilterInput;
    anatomy: FilterInput;
    problemType: FilterInput;
    sourceId: FilterInput;
    encoding: FilterInput;
    category: FilterInput;
    dicomViewer?: boolean;
    status?: TAnnotationStatus;
}

export type CategoricalAnnotationFilters = 'projectStatus' | 'status';

export function AnnotationsFiltersFactory(data?: Partial<IAnnotationFilters>): IAnnotationFilters {
    return {
        search: data?.search || null,
        modality: data?.modality || null,
        anatomy: data?.anatomy || null,
        problemType: data?.problemType || null,
        sourceId: data?.sourceId || null,
        encoding: data?.encoding || null,
        category: data?.category || null,
        dicomViewer: data?.dicomViewer || false,
        createdAt: data?.createdAt || null,
        projectStatus: data?.projectStatus || null,
        status: data?.status || null,
    };
}
