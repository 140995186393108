import { isStudyDataset } from 'common/entities';
import { useUpdateSearchParams } from 'common/hooks';
import { transformImageResponse } from 'common/services';
import { GetStudyListArgs, useGetImageListQuery, useGetStudyListQuery } from 'pages/Datasets/store/DatasetService';
import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ExplorerLevel, IDataSourceArgs, IDatasetExplorerItem } from '../entities';

export function useStudyDataSource({ dataset, pageSize, search, excludeItems, setSearch, pageMap, reset }: IDataSourceArgs) {
    let [searchParams] = useSearchParams();
    const study = searchParams.get('study');
    const updateSearchParams = useUpdateSearchParams();
    const [studyId, setStudyId] = useState<string>();
    const [seriesId, setSeriesId] = useState<string>();
    const [level, setLevel] = useState<ExplorerLevel>('study');
    const [studySearch, setStudySearch] = useState<string>();

    const skip = studySearch ? 0 : (pageMap['studyPage'] - 1) * pageSize;
    let params: GetStudyListArgs = {
        dataset_id: dataset?.datasetId,
        skip,
        limit: pageSize,
        search: studySearch,
    };

    if (study) {
        params = { ...params, include_list: [study] };
    }

    const {
        studyList,
        seriesList,
        total,
        isFetching: studyListLoading,
        refetch,
    } = useGetStudyListQuery(params, {
        skip: !isStudyDataset(dataset),
        selectFromResult: ({ currentData, isFetching }) => ({
            studyList: (level === 'study' && studySearch
                ? currentData?.data?.filter(s => s.study_id.includes(studySearch) || s.alias.includes(studySearch))
                : currentData?.data
            )?.map(
                (study): IDatasetExplorerItem => ({
                    id: study.study_id,
                    name: study?.description ? `${study?.alias} (${study?.description})` : study?.alias,
                    count: study?.count,
                    type: 'study',
                    thumbnail:
                        dataset?.dataServer === 'pacs'
                            ? study?.example_instances
                                  ?.slice(0, 3)
                                  .map(instance => transformImageResponse(instance, dataset?.datasetId))
                            : study?.example_images?.slice(0, 3)?.map(imageId => ({ imageId, datasetId: dataset.datasetId })),
                })
            ),
            seriesList: currentData?.data
                ?.find(item => item.study_id === studyId)
                ?.series_list?.map(
                    (series): IDatasetExplorerItem => ({
                        id: series.series_id,
                        name: series?.description || series?.series_number?.toString(),
                        count: series?.count,
                        type: 'series',
                        thumbnail:
                            dataset?.dataServer === 'pacs'
                                ? series?.example_instances
                                      ?.slice(0, 3)
                                      .map(instance => transformImageResponse(instance, dataset?.datasetId))
                                : series?.exampleImages?.slice(0, 3)?.map(imageId => ({ imageId, datasetId: dataset.datasetId })),
                    })
                ),
            total: currentData?.total,
            isFetching: isFetching,
        }),
    });

    useEffect(() => {
        if (!isStudyDataset(dataset)) setLevel('image');
        if (study) {
            setStudyId(study);
            setSeriesId(undefined);
            setLevel('series');
        }
    }, [dataset, study]);

    const selectedStudy = studyList?.find(item => item.id === studyId);
    const selectedSeries = seriesList?.find(item => item.id === seriesId);

    const skipImage = search ? 0 : (pageMap['page'] - 1) * pageSize;

    const { studyImageSource, studyImageSourceLoading } = useGetImageListQuery(
        {
            dataset_id: dataset?.datasetId,
            skip: skipImage,
            limit: pageSize,
            studyId,
            seriesId,
            search,
        },
        {
            skip: !isStudyDataset(dataset) || level !== 'image',
            selectFromResult: ({ currentData, isFetching }) => ({
                studyImageSource: {
                    data: currentData?.data?.map(
                        (image): IDatasetExplorerItem => ({
                            ...image,
                            id: image.imageId,
                            name: image.imageName,
                            type: 'image',
                            thumbnail: [image],
                        })
                    ),
                    total: currentData?.total,
                },
                studyImageSourceLoading: isFetching,
            }),
        }
    );

    function getDataSource() {
        switch (level) {
            case 'series':
                return { data: seriesList, total: seriesList?.length };

            case 'image':
                return studyImageSource;

            case 'study':
            default:
                return { data: studyList, total: level === 'study' && studySearch ? total : dataset?.studyCount };
        }
    }

    const studySourceLoading = studyListLoading || studyImageSourceLoading;

    function onClickStudyItem(item: IDatasetExplorerItem) {
        if (item.type === 'study') {
            setStudyId(item.id);
            setSeriesId(undefined);
            setLevel('series');
            updateSearchParams({ study: undefined });
        } else if (item.type === 'series') {
            setSeriesId(item.id);
            setLevel('image');
        }
    }

    function onClickStudyList() {
        setLevel('study');
        setSeriesId(undefined);
        setStudyId(undefined);
        setStudySearch(undefined);
        setSearch(undefined);
        reset();
        updateSearchParams({ study: undefined });
    }

    return {
        studyDataSource: getDataSource(),
        studySourceLoading,
        level,
        onClickStudyItem,
        selectedStudy,
        selectedSeries,
        studyList,
        seriesList,
        onClickStudyList,
        refetchStudySource: refetch,
        setStudySearch,
        studySearch,
    };
}
