import { fetchEventSource } from '@microsoft/fetch-event-source';
import { _isJSON } from 'common/Utils';
import { IDBEventResponse } from 'common/entities';
import { ServiceEnum } from 'common/enum';
import { getDefaultHeaders } from 'common/services/Tools';
import { PacsMigration } from './Migration';

class RetriableError extends Error {}
class FatalError extends Error {}

export class MigrationTrackingServerEvent {
    static readonly base_url = `${ServiceEnum.Api}/Datasets/pacs/track-migrations`;

    static async subscribeEvent({ cb, abortController, onError, pacsId }: Params) {
        const url = `/${MigrationTrackingServerEvent.base_url}/${pacsId}`;
        console.log('subscribeEvent', url);
        fetchEventSource(url, {
            headers: {
                Accept: 'text/event-stream',
                ...getDefaultHeaders(),
            },
            async onopen(response) {
                if (response.ok) {
                    console.log('eventStarted', response);
                } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                    // client-side errors are usually non-retriable:
                    throw new FatalError(response.status.toString());
                } else {
                    throw new RetriableError();
                }
            },
            onmessage(response) {
                console.log('event', response);
                if (!_isJSON(response.data) || response.event === 'join') return;
                const data: MigrationEventData = JSON.parse(response.data);
                cb(data);
            },
            onerror(err) {
                if (err instanceof FatalError) {
                    onError?.(new Error(err.message));
                }
                console.log('error', err);
            },
            signal: abortController?.signal,
            openWhenHidden: true,
        });
    }
}

export type MigrationEventData = IDBEventResponse<any, PacsMigration> | IDBEventResponse<any, PacsMigration>[];

interface Params {
    cb: (data: MigrationEventData) => void;
    onError?: (err?: any) => void;
    abortController?: AbortController;
    pacsId: string;
}
