import { TableColumnsType } from "antd";
import { getMessages } from "common/helpers";
import { _findBy } from "common/Utils";
import { IModel } from "pages/Models/entities";
import { useSelector } from "react-redux";
import { selectVendorList } from "redux/selectors";

export function useModelTable() {
    const sourceList = useSelector(selectVendorList);
    const columns: TableColumnsType<IModel> = [
        {
            dataIndex: "modelName",
            title: getMessages("000480"),
        }, {
            dataIndex: "modality",
            title: getMessages("000021"),
            render: renderBaseField
        }, {
            dataIndex: "problemType",
            title: getMessages("000556"),
        }, {
            dataIndex: "anatomy",
            title: getMessages("000023"),
            render: renderBaseField
        }, {
            dataIndex: "vendorId",
            title: getMessages("000564"),
            render: (value: any) => _findBy(sourceList, "vendor_id", value)?.vendor_name
        }, {
            dataIndex: "createdDate",
            title: getMessages("000769"),
        },
    ].map(item => ({ ...item, key: (item as any).dataIndex }));

    return { columns };
}

export const renderBaseField = (value: any) => Array.isArray(value) ? value.join(", ") : value;
