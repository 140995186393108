import * as am5 from '@amcharts/amcharts5';
import type { ColumnSeries } from '@amcharts/amcharts5/xy';
import { Dictionary } from 'common/entities';
import { ChartHelper } from 'common/helpers';
import _uniqueId from 'lodash/uniqueId';
import { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { IBaseChartProps } from '../entities';
import { IChartOptions, createChart } from './createChart';

export default function BarChart(props: IBarChartProps) {
    const [chartID, setChartID] = useState(_uniqueId(props.chartID));
    const chartHelperRef = useRef<ChartHelper.XYChart<ColumnSeries>>();
    const xAxisTitleRef = useRef<am5.Label>();

    useEffect(() => {
        setChartID(_uniqueId(props.chartID));
    }, [props.chartID, props?.chartOptions?.lightTheme]);

    useEffect(() => {
        if (props.data && chartHelperRef.current) {
            chartHelperRef.current.setData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            const { chartHelper, xAxisTitle } = createChart(chartID, props.chartOptions);
            chartHelperRef.current = chartHelper;
            xAxisTitleRef.current = xAxisTitle;

            if (props.data) {
                chartHelperRef.current.setData(props.data);
            }
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        chartHelperRef.current?.root?.dispose();
        const { chartHelper, xAxisTitle } = createChart(chartID, props.chartOptions);
        chartHelperRef.current = chartHelper;
        xAxisTitleRef.current = xAxisTitle;

        if (props.data) {
            chartHelperRef.current.setData(props.data);
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.chartOptions?.lightTheme]);

    useUpdateEffect(() => {
        xAxisTitleRef.current?.set('text', props.chartOptions.xAxis.title);
    }, [props.chartOptions?.xAxis?.title]);

    useUpdateEffect(() => {
        (chartHelperRef.current?.xAxis as any)
            .get('renderer')
            .labels.template.set('fill', props?.chartOptions?.lightTheme ? am5.color('#000') : am5.color('#fff'));
        (chartHelperRef.current?.yAxis as any)
            .get('renderer')
            .labels.template.set('fill', props?.chartOptions?.lightTheme ? am5.color('#000') : am5.color('#fff'));
    }, [props?.chartOptions?.lightTheme]);

    return (
        <div
            className={props.className}
            style={{ height: props.height, width: props.width }}
            id={chartID}
        />
    );
}

export interface IBarChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    chartOptions?: IChartOptions;
}

export interface IData extends Dictionary {
    name: string;
    value: number;
}
