
import cornerstoneTools from 'cornerstone-tools';
import growCutFromCircle from '../SegmentationHelper/SegmentFromCircle';
const BaseTool = cornerstoneTools.importInternal('base/BaseTool');

export default class SphericalGrowCutSegmentationTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
      const defaultProps = {
        name: 'SphericalGrowCutSegmentation',
        configuration: {
          shouldCleanSegment: false,
        },
        strategies: {
          GROW_CUT: growCutFromCircle,
        },
        defaultStrategy: 'GROW_CUT',
        supportedInteractionTypes: ['Mouse'],
        mixins: ['circleSegmentationMixin'],
      };
      
      super(props, defaultProps);
    }
  }