import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import { ChartHelper } from 'common/helpers';
import { AxesType } from 'common/helpers/ChartHelper/entities/enums';

export function createChart(chartID: string, chartOptions?: IChartOptions) {
    const lightTheme = chartOptions?.lightTheme;
    const chartHelper = new ChartHelper.XYChart();
    chartHelper.createRoot({ chartID, lightTheme, customColor: chartOptions?.customColor });

    chartHelper
        .createChart({
            legendEnabled: chartOptions?.legendEnabled,
            borderRadiusEnabled: chartOptions?.isLegendCircle,
            legendPosition: chartOptions?.legendPosition,
        })
        .createAxes(
            {
                xAxis: {
                    type: AxesType.category,
                    rendererSetting: {
                        minGridDistance: 20,
                        cellStartLocation: 0.1,
                        cellEndLocation: 0.9,
                    },
                    categoryAxisOptions: {
                        categoryField: chartOptions?.xAxis?.fieldName || 'name',
                        // maxDeviation: 0.3,
                        height: chartOptions?.xAxis?.height || 20,
                    },
                },
                yAxis: {
                    type: AxesType.value,
                    rendererSetting: {},
                    valueAxisOptions: {
                        // maxDeviation: 0.3,
                        min: chartOptions?.yAxis?.min,
                        max: chartOptions?.yAxis?.max,
                    },
                },
            },
            (xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>, yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>) => {
                xAxis.get('renderer').labels.template.set('oversizedBehavior', 'wrap');
                xAxis.get('renderer').labels.template.set('maxWidth', 150);

                xAxis.get('renderer').labels.template.setAll({
                    fill: lightTheme ? am5.color('#000') : am5.color('#fff'),
                });

                yAxis.get('renderer').labels.template.setAll({
                    fill: lightTheme ? am5.color('#000') : am5.color('#fff'),
                });

                if (chartOptions?.xAxis?.rotate) {
                    xAxis.get('renderer').labels.template.setAll({
                        rotation: -90,
                        centerY: am5.p50,
                        centerX: am5.p100,
                        paddingRight: 15,
                        text: chartOptions.xAxis?.labelText,
                        textAlign: 'right',
                        fill: am5.color('#fff'),
                    });
                }

                xAxis.get('renderer').grid.template.setAll({
                    fillOpacity: 0,
                    strokeOpacity: 0,
                });
                if (chartOptions?.yAxisGuideLineDisabled) {
                    yAxis.get('renderer').grid.template.setAll({
                        fillOpacity: 0,
                        strokeOpacity: 0,
                    });
                }
                if (chartOptions?.xAxisLabelVisible) {
                    xAxis.get('renderer').labels.template.setAll({
                        visible: false,
                    });
                }
            }
        );

    return chartHelper;
}

export interface IChartOptions {
    valueBulletEnabled?: boolean;
    legendEnabled?: boolean;
    tooltipEnabled?: boolean;
    xAxis?: {
        labelText?: string;
        fieldName?: string;
        rotate?: boolean;
        wrapLabels?: boolean;
        height?: number;
        categoryField?: string;
    };
    yAxis?: {
        fieldName?: string;
        min?: number;
        max?: number;
    };
    series?: {
        fill?: string;
        stroke?: string;
        name?: string;
        label?: string;
    };
    lightTheme?: boolean;
    customColor?: string[];
    isLegendCircle?: boolean;
    legendPosition?: string;
    yAxisGuideLineDisabled?: boolean;
    borderRadius?: { cornerRadiusTL: number; cornerRadiusTR: number };
    barWidth?: number;
    xAxisLabelVisible?: boolean;
    maskBullets?: boolean;
    bulletTextColor?: any;
    showBulletText?: boolean;
}
