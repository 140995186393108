import { _range } from 'common/Utils';
import GLazyList from 'components/GLazyList';
import GDashboardCardSkeletonItem from 'components/GSkeleton/GDashboardCardSkeletonItem';
import React from 'react';
import { IDatasourceProps } from '../../entities';
import Styles from './Styles.module.scss';

export default function DashboardCardView({ loading, datasource, CardComponent, keyField }: IDashboardCardViewProps) {
    return (
        <GLazyList className={Styles.DasboardCardViewContainer} id="dataset-dashboard-card-container">
            {loading
                ? _range(8).map(i => <GDashboardCardSkeletonItem active key={i} />)
                : datasource?.map(i => <CardComponent item={i} key={i[keyField]} />)
            }
        </GLazyList>
    );
}

export interface IDashboardCardViewProps<T = any> extends IDatasourceProps<T> {
    CardComponent: React.FC<{ item: T; }>;
}