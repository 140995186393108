import cx from 'classnames';
import { NothingToSee } from 'components/assets/icons';
import Styles from './Styles.module.scss';

export default function GNothingToSee({ text }: IGNothingToSeeProps) {
    return (
        <div className={Styles.EmptyPage}>
            <div className={Styles.EmptyPageImage}>
                <NothingToSee />
            </div>
            <div className={cx(Styles.EmptyPageTitle, 'white')}>{text ?? 'Nothing to see here yet!'}</div>
        </div>
    );
}

interface IGNothingToSeeProps {
    text?: string;
}
