import { IBaseProps } from 'common';
import GOverLay from 'components/GOverlay';
import { Component } from 'react';

export default class GErrorBoundry extends Component<IBaseProps, { hasError: boolean; }> {
    constructor (props: IBaseProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error: any) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error: any, errorInfo: any) {
        // You can also log the error to an error reporting service
        // logErrorToMyService(error, errorInfo);

        console.log({ error, errorInfo });
    }

    render() {
        if (this.state.hasError) {
            return <GOverLay showErrorOverlay />;
        }

        return this.props.children ?? null;
    }
}
