import cornerstoneTools from 'cornerstone-tools';
import { CornerstoneMouseEvent, CornerstoneToolEvents, IMeasurementData } from '../DicomViewerHelper/interface';
import toolColors from './toolColors';

const getLogger = cornerstoneTools.importInternal('util/getLogger');
const triggerEvent = cornerstoneTools.importInternal('util/triggerEvent');
const getPixelSpacing = cornerstoneTools.importInternal('util/getPixelSpacing');
const throttle = cornerstoneTools.importInternal('util/throttle');
const lineSegDistance = cornerstoneTools.importInternal('util/lineSegDistance');
const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');
// Drawing

const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext');
const draw = cornerstoneTools.importInternal('drawing/draw');
const setShadow = cornerstoneTools.importInternal('drawing/setShadow');
const drawLine = cornerstoneTools.importInternal('drawing/drawLine');
const drawLinkedTextBox = cornerstoneTools.importInternal('drawing/drawLinkedTextBox');
const drawHandles = cornerstoneTools.importInternal('drawing/drawHandles');
const mouseCursors = cornerstoneTools.importInternal('tools/cursors');

const moveHandleNearImagePoint = cornerstoneTools.importInternal('manipulators/moveHandleNearImagePoint');
const moveAnnotation = cornerstoneTools.importInternal('manipulators/moveAnnotation');

const logger = getLogger('tools:annotation:LengthTool');

/**
 * @public
 * @class LengthTool
 * @memberof Tools.Annotation
 * @classdesc Tool for measuring distances.
 * @extends Tools.Base.BaseAnnotationTool
 */
export default class GLengthTool extends BaseAnnotationTool {
    constructor(props: any = {}) {
        const defaultProps = {
            name: 'Length',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            svgCursor: mouseCursors.lengthCursor,
            configuration: {
                drawHandles: true,
                drawHandlesOnHover: false,
                hideHandlesIfMoving: false,
                renderDashed: false,
                digits: 2,
                ...props.configuration,
            },
        };

        super(props, defaultProps);

        this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
    }

    get lock() {
        return cornerstoneTools.getModule('globalConfiguration').configuration.lockAnnotationTools;
    }

    createNewMeasurement(eventData: any) {
        if (!eventData?.currentPoints?.image) {
            logger.error(`required eventData not supplied to tool ${this.name}'s createNewMeasurement`);

            return;
        }

        const { x, y } = eventData.currentPoints.image;

        return {
            visible: true,
            active: true,
            color: undefined as any,
            invalidated: true,
            measurement_info: this.configuration.measurement_info,
            handles: {
                start: {
                    x,
                    y,
                    highlight: true,
                    active: false,
                },
                end: {
                    x,
                    y,
                    highlight: true,
                    active: true,
                },
                textBox: {
                    active: false,
                    hasMoved: false,
                    movesIndependently: false,
                    drawnIndependently: true,
                    allowedOutsideImage: true,
                    hasBoundingBox: true,
                },
            },
        };
    }

    /**
     *
     *
     * @param {*} element
     * @param {*} data
     * @param {*} coords
     * @returns {Boolean}
     */
    pointNearTool(element: any, data: any, coords: any) {
        const hasStartAndEndHandles = data.handles?.start && data.handles?.end;

        if (!hasStartAndEndHandles) {
            logger.warn(`invalid parameters supplied to tool ${this.name}'s pointNearTool`);

            return false;
        }

        if (data.visible === false) {
            return false;
        }

        return lineSegDistance(element, data.handles.start, data.handles.end, coords) < 5;
    }

    handleSelectedCallback(
        evt: CustomEvent<CornerstoneMouseEvent>,
        toolData: IMeasurementData,
        handle: any,
        interactionType = 'mouse'
    ) {
        if (toolData?.visible === false || this.lock) return;

        triggerEvent(evt.detail.element, CornerstoneToolEvents.INTERACTION_STARTED, { toolData });

        moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);
    }

    toolSelectedCallback(evt: CustomEvent<CornerstoneMouseEvent>, toolData: IMeasurementData, interactionType = 'mouse') {
        if (toolData?.visible === false || this.lock) return;

        triggerEvent(evt.detail.element, CornerstoneToolEvents.INTERACTION_STARTED, { toolData });

        moveAnnotation(evt, this, toolData, interactionType);
    }

    updateCachedStats(image: any, element: any, data: any) {
        const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

        // Set rowPixelSpacing and columnPixelSpacing to 1 if they are undefined (or zero)
        const dx = (data.handles.end.x - data.handles.start.x) * (colPixelSpacing || 1);
        const dy = (data.handles.end.y - data.handles.start.y) * (rowPixelSpacing || 1);

        // Calculate the length, and create the text variable with the millimeters or pixels suffix
        const length = Math.sqrt(dx * dx + dy * dy);

        // Store the length inside the tool for outside access
        data.length = length;
        data.invalidated = false;
    }

    renderToolData(evt: any) {
        const eventData = evt.detail;
        const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving, renderDashed, digits } = this.configuration;
        const toolData = cornerstoneTools.getToolState(evt.currentTarget, this.name);

        if (!toolData) {
            return;
        }

        // We have tool data for this element - iterate over each one and draw it
        const context = getNewContext(eventData.canvasContext.canvas);
        const { image, element } = eventData;
        const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

        const lineWidth = cornerstoneTools.toolStyle.getToolWidth();
        const lineDash = cornerstoneTools.getModule('globalConfiguration').configuration.lineDash;

        for (const data of toolData.data) {
            if (data.visible === false) continue;

            draw(context, (context: any) => {
                // Configurable shadow
                setShadow(context, this.configuration);

                const color = toolColors.getColorIfActive(data);

                const lineOptions = { color };

                if (renderDashed) {
                    (lineOptions as any).lineDash = lineDash;
                }

                // Draw the measurement line
                drawLine(context, element, data.handles.start, data.handles.end, lineOptions);

                // Draw the handles
                const handleOptions = {
                    color,
                    handleRadius,
                    drawHandlesIfActive: drawHandlesOnHover,
                    hideHandlesIfMoving,
                };

                if (this.configuration.drawHandles) {
                    drawHandles(context, eventData, data.handles, handleOptions);
                }

                if (!data.handles.textBox.hasMoved) {
                    const coords = {
                        x: Math.max(data.handles.start.x, data.handles.end.x),
                    };

                    // Depending on which handle has the largest x-value,
                    // Set the y-value for the text box
                    if (coords.x === data.handles.start.x) {
                        (coords as any).y = data.handles.start.y;
                    } else {
                        (coords as any).y = data.handles.end.y;
                    }

                    data.handles.textBox.x = coords.x;
                    data.handles.textBox.y = (coords as any).y;
                }

                // Move the textbox slightly to the right and upwards
                // So that it sits beside the length tool handle
                const xOffset = 10;

                // Update textbox stats
                if (data.invalidated === true) {
                    if (data.length) {
                        this.throttledUpdateCachedStats(image, element, data);
                    } else {
                        this.updateCachedStats(image, element, data);
                    }
                }

                const text = textBoxText(data, rowPixelSpacing, colPixelSpacing);

                if (cornerstoneTools.getModule('globalConfiguration').configuration.isVisibleLengthTextBox) {
                    drawLinkedTextBox(
                        context,
                        element,
                        data.handles.textBox,
                        text,
                        data.handles,
                        textBoxAnchorPoints,
                        color,
                        lineWidth,
                        xOffset,
                        true
                    );
                }
            });
        }

        // - SideEffect: Updates annotation 'suffix'
        function textBoxText(annotation: any, rowPixelSpacing: any, colPixelSpacing: any) {
            const measuredValue = _sanitizeMeasuredValue(annotation.length);

            // Measured value is not defined, return empty string
            if (!measuredValue) {
                return '';
            }

            // Set the length text suffix depending on whether or not pixelSpacing is available
            let suffix = 'mm';

            if (!rowPixelSpacing || !colPixelSpacing) {
                suffix = 'pixels';
            }

            annotation.unit = suffix;

            return `${measuredValue.toFixed(digits)} ${suffix}`;
        }

        function textBoxAnchorPoints(handles: any) {
            const midpoint = {
                x: (handles.start.x + handles.end.x) / 2,
                y: (handles.start.y + handles.end.y) / 2,
            };

            return [handles.start, midpoint, handles.end];
        }
    }
}

/**
 * Attempts to sanitize a value by casting as a number; if unable to cast,
 * we return `undefined`
 *
 * @param {*} value
 * @returns a number or undefined
 */
function _sanitizeMeasuredValue(value: any) {
    const parsedValue = Number(value);
    const isNumber = !isNaN(parsedValue);

    return isNumber ? parsedValue : undefined;
}
