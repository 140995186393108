import { fetchEventSource } from '@microsoft/fetch-event-source';
import { NODE_ENV } from 'common/config';
import { IDBEventResponse } from 'common/entities';
import { ServiceEnum } from 'common/enum';
import { END, eventChannel } from 'redux-saga';
import { getDefaultHeaders } from '../Tools/getDefaultHeaders';
import { getUri } from './axiosConfig';

class RetriableError extends Error {}
class FatalError extends Error {}
class ServerEvents {
    serverEvents: Record<string, { emitter: any; ctrl: AbortController }> = {};

    constructor() {
        this.subscribeEvent = this.subscribeEvent.bind(this);
    }

    async subscribeEvent<T = any>(service: ServiceEnum, endPoint: string) {
        const ctrl = new AbortController();

        return eventChannel(emitter => {
            this.serverEvents[endPoint] = { emitter, ctrl };

            fetchEventSource(`${getUri(service, endPoint)}`, {
                headers: {
                    Accept: 'text/event-stream',
                    ...getDefaultHeaders(),
                },
                async onopen(response) {
                    if (response.ok) {
                        console.log('eventStarted', response);
                    } else if (response.status >= 400 && response.status < 500 && response.status !== 429) {
                        // client-side errors are usually non-retriable:
                        throw new FatalError(response.status.toString());
                    } else {
                        throw new RetriableError();
                    }
                },
                onmessage(response) {
                    if (NODE_ENV === 'development') {
                        console.debug('[EventSource]', response);
                    }
                    if (response.data && response.event === 'message') {
                        const data: IDBEventResponse<T> = JSON.parse(response.data);
                        console.log({ service: endPoint, eventMessage: response, data });
                        emitter(data);
                    }
                },
                onerror(err) {
                    if (err instanceof FatalError) {
                        emitter(new Error(err.message));
                        // throw err; // rethrow to stop the operation
                    } else {
                        // do nothing to automatically retry. You can also
                        // return a specific retry interval here.
                    }
                    console.log('error', err);
                },
                onclose() {
                    emitter(END);
                },
                signal: ctrl.signal,
                openWhenHidden: true,
            });

            return () => {
                ctrl.abort();
            };
        });
    }

    unsubscribeEvent(eventId: string) {
        this.serverEvents[eventId]?.emitter(END);
    }
}

export const serverEvents = new ServerEvents();
