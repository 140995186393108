import { PayloadAction } from "@reduxjs/toolkit";
import { WritableDraft } from "immer/dist/internal";
import { IDatasetState } from "pages/Datasets/entities";
import { IMetadataEl } from "pages/Datasets/entities/IMetadata";
import { IImageListState, IMetadataListState, IMetadataManagementState, IMetadataUploadState } from "../entities";
import { IFileUpdloadState } from "../entities/IMetadataUploadState";

function updateMetadataUploadState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IMetadataUploadState>>) {
    Object.assign(state.metadataManagement.uploadState, payload);
}

function updateFileUploadState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IFileUpdloadState>>) {
    Object.assign(state.metadataManagement.uploadState.fileUpload, payload);
}

function updateMetadataImageListState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IImageListState>>) {
    Object.assign(state.metadataManagement.imageListState, payload);
}

function updateMetadataListState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IMetadataListState>>) {
    Object.assign(state.metadataManagement.metadataListState, payload);
}

function updateMetadataManagementState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IMetadataManagementState>>) {
    Object.assign(state.metadataManagement, payload);
}

function updateMetadataEditState(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IMetadataEl>>) {
    state.metadataManagement.editState[payload.image_id] = payload;
}

export const MetadataReducer = {
    updateMetadataUploadState,
    updateMetadataImageListState,
    updateMetadataListState,
    updateMetadataEditState,
    updateFileUploadState,
    updateMetadataManagementState,
};
