export function _entries<T extends Object>(obj?: T): Array<[keyof T, T[keyof T]]> {
    if (!obj || typeof obj !== 'object') return [];
    return Object.entries(obj) as any;
}

export function _values<T extends Object>(obj?: T): Array<T[keyof T]> {
    if (!obj || typeof obj !== 'object') return [];

    return Object.values(obj);
}

export function _keys<T extends Object>(obj?: T): Array<keyof T> {
    if (!obj || typeof obj !== 'object') return [];

    return Object.keys(obj) as any;
}
