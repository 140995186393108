import { APISlice } from "app/store/APISlice";
import { ServiceEnum } from "common";

export const extendedApiSlice = APISlice.injectEndpoints({
    endpoints: builder => ({
        getAppVersion: builder.query<string, undefined>({
            query: () => `${ServiceEnum.Deployment}/platform/version`,
        }),
    })
});

export const { useGetAppVersionQuery } = extendedApiSlice;