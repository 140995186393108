import { IEnvConfiguration } from 'app/entities';
import { updateCommonState } from 'app/store';
import { AxiosResponse } from 'axios';
import { AuthService, _isEmpty, getMessages } from 'common';
import { showMessageModal } from 'components';
import dayjs from 'dayjs';
import { call, fork, put } from 'redux-saga/effects';

export function* GetEnvConfigurationSaga(): any {
    try {
        const response: AxiosResponse<IEnvConfiguration> = yield AuthService.GetEnvConfiguration.call();
        yield put(updateCommonState({ envConfiguration: response.data }));

        yield fork(CheckActivationTokenAlert, response?.data?.activation_token_expires);

        return response?.data;
    } catch (e) {
        console.log(e);
        yield put(updateCommonState({ configFailModalVisible: true }));
    }
}

export function* CheckActivationTokenAlert(token_expires: number): any {
    try {
        const diff_day = dayjs.unix(token_expires).diff(dayjs(), 'day');
        const diff_hour = dayjs.unix(token_expires).diff(dayjs(), 'hour');
        if (!_isEmpty(diff_day) && diff_day < 3) {
            const timeMessage = diff_day ? `${diff_day} ${getMessages('000408')}` : `${diff_hour} ${getMessages('000409')}`;
            yield call(showMessageModal, {
                type: 'info',
                id: 'CheckActivationToken',
                content: getMessages('001156').replace('$', timeMessage),
                cancelButton: {
                    enabled: false,
                },
                okButton: {
                    text: getMessages('000397'),
                    color: 'primary',
                },
            });
        }
    } catch (error) {
        console.log(error);
    }
}
