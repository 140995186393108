import AppSaga from 'app/store/saga/index';
import AuthenticationSaga from 'pages/Authentication/store/saga';
import DatasetSaga from 'pages/Datasets/store/saga';
import ModelsSaga from 'pages/Models/store/saga';
import PlatformSaga from 'pages/Platform/store/saga';
import PlaygroundSaga from 'pages/Playground/store/saga';
import UserSaga from 'pages/User/store/saga';
import ValidationSagas from 'pages/Validation/store/saga';
import { all } from 'redux-saga/effects';

export default function* rootSaga() {
    yield all([
        AppSaga(),
        UserSaga(),
        AuthenticationSaga(),
        ValidationSagas(),
        ModelsSaga(),
        DatasetSaga(),
        PlaygroundSaga(),
        PlatformSaga(),
    ]);
}
