import {
    DashboardCaptionPanel,
    DashboardCardView,
    DashboardListView,
    DashboardTableView,
    IDashboardCaptionPanelProps,
    IFilterPanelProps,
} from './components';
import DashboardMiniCardView from './components/DashboardMiniCardView';
import { IDatasourceProps } from './entities';
import Styles from './Styles.module.scss';

export function DashboardView<T>({
    dashboardCaptionPanelProps,
    datasourceProps,
    CardComponent,
    ListItemComponent,
    MiniCardComponent,
    tableViewProps,
}: IDashboardProps<T>) {
    const dashbordViewMap = {
        card: (
            <DashboardCardView
                {...datasourceProps}
                CardComponent={CardComponent}
            />
        ),
        list: (
            <DashboardListView
                {...datasourceProps}
                ListItemComponent={ListItemComponent}
            />
        ),
        miniCard: (
            <DashboardMiniCardView
                {...datasourceProps}
                MiniCardComponent={MiniCardComponent}
            />
        ),
        table: <DashboardTableView {...tableViewProps} />,
    };
    return (
        <div className={Styles.DashboardContainer}>
            <DashboardCaptionPanel {...dashboardCaptionPanelProps} />
            {dashbordViewMap[dashboardCaptionPanelProps?.activeView]}
        </div>
    );
}

export interface IDashboardProps<T> {
    filterPanelProps?: IFilterPanelProps;
    dashboardCaptionPanelProps: IDashboardCaptionPanelProps;
    datasourceProps: IDatasourceProps<T>;
    CardComponent: React.FC<{ item: T }>;
    MiniCardComponent?: React.FC<{ item: T }>;
    ListItemComponent: React.FC<{ item: T }>;
    tableViewProps?: {
        dataSource: T[];
        columns: any[];
        loading?: boolean;
        keyField: string;
    };
}

export { DashboardCardItem, DashboardListItem } from './components';
export type { ViewType } from './components';
export { BooleanFilterItem, CategoricalFilterItem, SearchItem } from './components/FilterPanel';
