import { IDataset } from 'common';
import constate from 'constate';
import { useState } from 'react';
import { AssignmentState, useSimpleImageListState } from './useSimpleImageList';

export function useImageState({ dataset, assignmentState = {} }: IImageStateParams) {
    const { simpleImageList, simpleImageState } = useSimpleImageListState(dataset, assignmentState);

    const includedImageListState = useState({ enabled: false, imageList: [] as Array<string> });

    return {
        simpleImageList,
        simpleImageState,
        dataset,
        includedImageListState,
    };
}

export const [ImageStateProvider, useSimpleImageList, useSimpleImageState, useDataset, useIncludedImageListState] = constate(
    useImageState,
    value => value.simpleImageList,
    value => value.simpleImageState,
    value => value.dataset, //if playground, dataset is undefined
    value => value.includedImageListState
);

export interface IImageStateParams {
    dataset: IDataset;
    assignmentState?: AssignmentState;
}
