import { PayloadAction } from '@reduxjs/toolkit';
import { _entries, getMessages, getUnixTime, IDatasetResponsePayload, IDataSource, PlatformService, RoutingHelper } from 'common';
import { Notification, showMessageModal } from 'components';
import dayjs from 'dayjs';
import { setThumbnailList, setUploadProgress, setUploadStatus, updateDatasetUploadParams } from 'pages/Datasets/store';
import { getDatasetListTransaction } from 'pages/Datasets/store/actions';
import { put, select } from 'redux-saga/effects';
import { selectDataSourceList } from 'redux/selectors';
import { IUploadDatasetState } from '../../entities';
import { ISubmitDatasetFormArgs } from '../../entities/ISubmitDatasetFormArgs';
import { UploadParamsFactory } from '../../entities/IUploadParams';
import { selectDatasetUploadState } from '../selectors';
import Styles from './Styles.module.scss';

export function* WriteDatasetToDb(sagaAction: PayloadAction<ISubmitDatasetFormArgs>): any {
    try {
        const sourceList = yield select(selectDataSourceList);

        let { payload, form, requestId } = sagaAction.payload;
        const { uploadParams }: IUploadDatasetState = yield select(selectDatasetUploadState);
        const {
            datasetId,
            encoding,
            uploadOptionType,
            zipPath,
            srcCredId,
            srcCloudName,
            sourceType,
            storeLocation,
            destCloudName,
            destCredId,
            dataType,
            videoExt,
            s3Params,
            srcUrl,
            localPath,
            datasetPicture,
            tabularExt,
            selectedSourceId,
        } = uploadParams;

        function getSourceUrl() {
            if (
                uploadOptionType === 'path' &&
                sourceType === 'local' &&
                (storeLocation === 'local' || storeLocation === 'same')
            ) {
                return localPath;
            }
            return sourceType === 'cloud' ? s3Params.URI ?? srcUrl : zipPath;
        }

        payload = getFormPayload(payload, sourceList);
        payload.dataset_picture = datasetPicture || '/assets/model_image/ModelCardImage.png';
        payload.encoding = dataType === 'tabular' ? [tabularExt] : [encoding];
        payload.dataset_id = datasetId;
        payload.source_id = selectedSourceId;
        if (dataType === 'tabular') {
            payload.tabular = true;
        }

        const formData = new FormData();
        const fields = {
            dataset_id: datasetId,
            img_ext: encoding,
            upload_type: uploadOptionType,
            data_type: dataType,
            data_ext: dataType === 'video' ? videoExt : dataType === 'tabular' ? tabularExt : encoding,
            thumbnail_width: '74',
            thumbnail_height: '74',
            src_url: getSourceUrl(),
            src_type: sourceType,
            src_cloud_name: srcCloudName || '',
            src_folder_name: s3Params.folderName || '',
            src_cred_id: srcCredId || '',
            dest_cred_id: destCredId || '',
            dest_cloud_name: destCloudName || '',
            dest_type: storeLocation,
            dataset_card: JSON.stringify(payload),
        };

        for (const [key, value] of _entries(fields)) {
            formData.append(key, value);
        }
        const response = yield PlatformService.Manage.DatasetDbAdd.call(formData, { headers: { 'x-request-id': requestId } });
        if (!response?.data) {
            Notification.error({
                content: 'Something went wrong. Please try again.',
            });
            return;
        }
        form?.resetFields?.();

        yield put(updateDatasetUploadParams(UploadParamsFactory()));
        yield put(setThumbnailList([]));
        yield put(setUploadStatus(null));
        yield put(setUploadProgress(0));

        yield put(getDatasetListTransaction());

        return showMessageModal({
            className: Styles.ModalContainer,
            type: 'info',
            content: getMessages('000588'),
            cancelButton: {
                enabled: false,
            },
            okButton: {
                text: getMessages('000098'),
                navigate: RoutingHelper.Dataset({ activeView: 'DatasetDashboard' }).uri,
            },
        });
    } catch (error) {
        console.error(error);
    }
}

function getFormPayload(data: IDatasetResponsePayload, sourceList: IDataSource[]): IDatasetResponsePayload {
    if (data.dataset_access_options.access === 'private') {
        let sourcename = sourceList.find(item => item.source_id === data.source_id)?.source_name;
        let category = `${sourcename}_${data.dataset_name}_${data.dataset_version}`.replace(/\s/g, '');
        data.category = [category];
    }
    let currentUser: any = sessionStorage.getItem('user');

    currentUser = currentUser ? JSON.parse(currentUser) : {};

    let permission_list = (data as any)?.dataset_access_options?.permission_list || [];
    if (!permission_list.includes(currentUser.email)) {
        permission_list.push(currentUser.email);
    }

    permission_list = permission_list.map((user: string) => ({ user, timestamp: getUnixTime(), type: 'user' }));

    return {
        ...data,
        created_timestamp: dayjs().unix(),
        compatible_annotation_ids: [],
        compatible_metadata_ids: [],
        dataset_access_options: {
            access: data?.dataset_access_options?.access || 'public',
            owner_email: currentUser.email,
            permission_list,
        },
    };
}
