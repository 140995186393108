import { DatasetsFiltersFactory, IDatasetFilters } from "common";
import { DatasetCreationFactory, IDatasetCreation } from "./IDatasetCreation";
import { IDatasetSelection, IDatasetSelectionFactory } from "./IDatasetSelection";
import { IImageFiltering, ImageFilteringFactory, IMetadataFilterField } from "./IImageFiltering";

export interface IDatasetCurationState {
    selectedDatasets: Array<IDatasetSelection>;
    imageFiltering: IImageFiltering;
    datasetFilters: IDatasetFilters;
    datasetCreation: IDatasetCreation;
}

export function DatasetCurationStateFactory(data?: Partial<IDatasetCurationState>): IDatasetCurationState {
    return {
        selectedDatasets: data?.selectedDatasets || [],
        imageFiltering: ImageFilteringFactory(data?.imageFiltering),
        datasetFilters: DatasetsFiltersFactory({ hideNoDefaultMetadata: false }),
        datasetCreation: DatasetCreationFactory(data?.datasetCreation),
    };
}

export interface IViewType {
    key: ViewType,
    icon: JSX.Element;
}

export interface ICurationStepItem {
    key: CurationStepType;
    title: string;
}

export type CurationStepType = "DatasetFilter" | "ImageFilter" | "DatasetCreate";
export type ViewType = 'card' | 'miniCard' | 'list' | 'table';

export type { IDatasetSelection, IMetadataFilterField };

export { IDatasetSelectionFactory };
