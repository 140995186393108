import { _uuid } from 'common/Utils';
import { useEffect, useRef } from 'react';
import { useUpdate } from 'react-use';
import { StorageHelper } from '.';
import { StorageList, StorageType, StorageTypeMap } from './StorageList';

export function useStorageHelper<T extends StorageType>(key: T): StorageHelper<StorageTypeMap[T]> {
    const id = useRef(_uuid());
    const update = useUpdate();
    const storage = StorageList[key];

    useEffect(() => {
        storage.subscribe(id.current, update);

        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => storage.unSubscribe(id.current);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return storage as any;
}
