import { Dictionary } from 'common';
import { MetadataValue } from './IAnalysisResult';

export interface IBlindSpotTracking {
    validationId: string;
    bstId: string;
    totalCombinationSize: number;
    minCohortSize: number;
    maxMetaCombinationPairSize: number;
    totalViableCombination: number;
    totalNonViableCombination: number;
    totalCombinationSizeByPairsize: number;
    uniqueMetadataList: Dictionary<Array<MetadataValue>>;
    valCreatedCohortList: Array<string>;
    status: BlindSpotStatus;
    lowerBound: number;
    slim: boolean;
    favorited?: boolean;
    round_name?: string;
}

export interface IBlindSpotTrackingResponse {
    batch_job_id: string;
    bst_id: string;
    total_combination_size: number;
    min_cohort_size: number;
    max_meta_combination_pair_size: number;
    total_viable_combination: number;
    total_non_viable_combination: number;
    total_combination_size_by_pairsize: number;
    unique_metadata_list: Dictionary<Array<string>>;
    val_created_cohort_list: Array<string>;
    status: BlindSpotStatus;
    lower_bound: number;
    slim: boolean;
    round_name?: string;
    favorited?: boolean;
}

export function getBlindSpotTrackingData(data?: IBlindSpotTrackingResponse): IBlindSpotTracking {
    return {
        validationId: data?.batch_job_id,
        bstId: data?.bst_id,
        totalCombinationSize: data?.total_combination_size,
        minCohortSize: data?.min_cohort_size,
        maxMetaCombinationPairSize: data?.max_meta_combination_pair_size,
        totalViableCombination: data?.total_viable_combination,
        totalNonViableCombination: data?.total_non_viable_combination,
        totalCombinationSizeByPairsize: data?.total_combination_size_by_pairsize,
        uniqueMetadataList: data?.unique_metadata_list,
        valCreatedCohortList: data?.val_created_cohort_list,
        status: data?.status,
        lowerBound: data?.lower_bound,
        slim: data?.slim,
        round_name: data?.round_name,
        favorited: data?.favorited,
    };
}

export enum BlindSpotStatus {
    done = 'DONE',
    inprogress = 'INPROGRESS',
    removed = 'REMOVED',
}
