import { ColumnFilterItem, FilterDropdownProps } from "antd/lib/table/interface";
import { Dictionary } from "common/entities";
import { isNullOrUndefined, _compact, _get, _uniq } from "common/Utils";

export function getFilters<T extends Dictionary<any>,>(dataPath: keyof T | Array<keyof T>, dataSource: Array<T>): IFilterProps {

    let filterList = dataSource?.map(record => _get(record, dataPath as any)).flat();

    return {
        // filterSearch: true,
        filters: _compact(_uniq(filterList).map(value => ({ value, text: (<span className="filter-text">{(dataPath as Array<string>)?.includes("validationStatus") ? renderCompletionTitle(value) : value}</span>) }))),
        onFilter: (value: string, record: Dictionary<any>) => {

            let cellValue = _get(record, dataPath as any);

            if (!isNullOrUndefined(cellValue)) {
                if (Array.isArray(cellValue)) {
                    cellValue = cellValue.join(" ");
                }
                return cellValue?.toLocaleLowerCase?.()?.includes?.(value?.toLocaleLowerCase?.());
            }
            return false;
        }
    };

}

function renderCompletionTitle(value:string){
    switch (value) {
        case "DONE":
            return "Completed"
        case "BATCH_DONE":
            return "Batch Completed"
        case "BATCH_DONE(PARTIAL)":
            return "Batch Partial"
        case "BATCH_FAILED":
            return "Batch Failed"
        case "VAL_FAILED":
            return "Failed"
        case "HALTED":
            return "Stopped"
        case "BATCH_INPROGRESS":
            return "Pending"
        default:
            return "Unknown"
    }
}

export interface IFilterProps {
    filterSearch?: boolean,
    filters: Array<ColumnFilterItem>,
    onFilter: (value: string, record: Dictionary<any>) => boolean;
    filterDropdown?: React.ReactNode | ((props: FilterDropdownProps) => React.ReactNode);
}