import { getMessages } from 'common';
import { LowBandwith, OfflineBandwith } from 'components/assets/icons';
import GTooltip from 'components/GTooltip';
import { useSelector } from 'react-redux';
import { selectNetworkSpeed } from "redux/selectors";

export default function BandwidthAlert() {
    const { status } = useSelector(selectNetworkSpeed);

    if (status === "online") return null;

    const message = status === "offline" ? getMessages("000812") : getMessages("000811");

    return (
        <GTooltip title={message} >
            {status === "offline" ? <OfflineBandwith /> : <LowBandwith />}
        </GTooltip>
    );
}