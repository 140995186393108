import { Switch, SwitchProps } from 'antd';
import Styles from "./Styles.module.scss";

export default function GSwitch({ className, style, ...props }: IGSwitch) {
    return (
        <div className={[Styles.SwitchContainer, className].join(" ")} style={style}>
            <Switch {...props} />
        </div>);
}

interface IGSwitch extends SwitchProps {

}
