import { Tooltip } from 'antd';
import Styles from "./assets//Styles.module.scss";
import cx from "classnames";
import { TooltipPropsWithTitle } from 'antd/lib/tooltip';

export default function GTooltip({ className, title, children, overlayClassName, show = true, ...props }: IGTooltipProps) {
    if (show) {
        return (
            <Tooltip
                title={title}
                className={className}
                color="#161B24"
                overlayInnerStyle={{ color: "#fff" }}
                overlayClassName={cx("pBody-normal", Styles.Tooltip, overlayClassName)}
                {...props}
            >
                {children}
            </Tooltip>
        );
    }

    return <>{children}</>;
}


export type IGTooltipProps = TooltipPropsWithTitle & { className?: string, key?: string | number, placement?: string, overlayClassName?: string, show?: boolean }