import { Image, Skeleton } from 'antd';
import SkeletonImage from "components/assets/placeholder/skeleton.png";
import Styles from "./Styles.module.scss";

export default function GDashboardCardSkeletonItem({ active }: ISkeletonItemProps) {
    return (
        <div className={Styles.DashboardCardSkeletonContainer}>
            <Image src={SkeletonImage} preview={false} />
            <Skeleton paragraph={{ rows: 3 }} active={active} />
        </div>
    );
}

interface ISkeletonItemProps {
    active?: boolean;
}