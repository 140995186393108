import { ServiceEnum } from "common";
import { getUri } from "common/services/Client/axiosConfig";

export function getGoogleUrl() {
    const rootUrl = `https://accounts.google.com/o/oauth2/v2/auth`;
    const redirect_uri = getUri(ServiceEnum.Auth) + "/oauth/google";

    const options = {
        redirect_uri,
        client_id: REACT_APP_GOOGLE_OAUTH_CLIENT_ID,
        access_type: 'offline',
        response_type: 'code',
        prompt: 'consent',
        scope: [
            'https://www.googleapis.com/auth/userinfo.profile',
            'https://www.googleapis.com/auth/userinfo.email',
        ].join(' '),
        state: [window.location.origin, redirect_uri].join(" "),
    };

    const qs = new URLSearchParams(options);

    return `${rootUrl}?${qs.toString()}`;
}

const REACT_APP_GOOGLE_OAUTH_CLIENT_ID = "748204987359-bhjg4ajuh19ksbq40g3f23s33vb9m1sc.apps.googleusercontent.com";
