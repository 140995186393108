import { Tabs, TabsProps } from 'antd';
import cx from "classnames";
import React from 'react';
import Styles from "./Styles.module.scss";

export default function GTabs<T extends string = string>({ type = "card", className, tabList, id, ...props }: IGTabsProps<T>) {
    return (
        <Tabs
            type={type}
            className={cx(Styles.TabContainer, className)}
            id={id}
            items={tabList?.map(({ content, key, title, disabled, closable }) => ({
                key, disabled, children: content,
                label: <span className="pBody-medium">{title}</span>,
                id: key,
                closable
            }))}
            {...props}
        />
    );
}

export interface IGTabsProps<T extends string> extends TabsProps {
    tabList: ITab<T>[];
    onChange?: (key: T) => void;
}

export interface ITab<T = string> {
    title: string | React.ReactNode;
    key: T;
    content: React.ReactNode;
    disabled?: boolean;
    closable?: boolean;
}
