import setPixelDataOfSubVolume from "./setPixelDataOfSubVolume";
import getPointsInSubVolume from "./getPointsInSubVolume";
import getExtentOfSphere from "./getExtentOfSphere";
import getExtentOfBoundingBox from "./getExtentOfBoundingBox";
import getPointsInRectSubVolume from "./getPointsInRectSubVolume";


export default {
  getExtentOfBoundingBox,
  setPixelDataOfSubVolume,
  getPointsInSubVolume,
  getExtentOfSphere,
  getPointsInRectSubVolume
};