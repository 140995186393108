
export function getUploadImageURL({ src }: IPublicImageURLArgs) {
    if (!src) return;
    if (src?.startsWith("http")) return src;
    if (src?.startsWith("/assets")) return src;
    if (src?.startsWith("manage")) return src;
}


interface IPublicImageURLArgs {
    src: string;
}