import { Form } from 'antd';
import { AuthActiveView, getMessages } from 'common';
import { GButton, GForm } from 'components';
import { useCurrentView } from 'pages/Authentication/hooks';
import React from 'react';
import Styles from "./Styles.module.scss";

export default function AuthForm({ children, loading, onSubmit, form }: IProps) {
    const currentView = useCurrentView();

    return (
        <GForm
            name={currentView}
            form={form}
            initialValues={{ remember: true }}
            onFinish={onSubmit}
            layout='vertical'
            labelAlign='left'
            id={`${currentView}-form`}
            className={Styles.AuthForm}
            requiredMark={false}
        >
            {children}

            <Form.Item shouldUpdate>
                {() => (
                    <GButton
                        type="primary"
                        loading={loading}
                        htmlType="submit"
                        disabled={form.getFieldsError().some(({ errors }:{errors:any}) => errors.length)}
                        id={`${currentView}-submit`}
                        className="button-bold"
                        size='large'
                    >
                        {buttonContentMap[currentView]}
                    </GButton>)}
            </Form.Item>
        </GForm>);
}

const buttonContentMap: Record<AuthActiveView, string> = {
    login: getMessages("000121"),
    register: getMessages("000122"),
    "forgot-password": getMessages("000141"),
    "new-password": getMessages("000150"),
    MFA: ""
};

export interface IProps {
    children: React.ReactNode;
    loading?: boolean;
    onSubmit: (args: any) => void;
    form:any;
}