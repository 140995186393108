import { ProblemTypeKeys } from 'common';

export interface IAnnotationUploadState {
    fileUpload?: IFileUploadState;
    problemType?: ProblemTypeKeys;
    annotationName?: string;
    loading?: boolean;
    uploadType?: UploadType;
    uploadModalVisible?: boolean;
}

export interface IFileUploadState {
    file?: string;
    fileName?: string;
    fileData?: any;
}

export function AnnotationUploadStateFactory(data?: Partial<IAnnotationUploadState>): IAnnotationUploadState {
    return {
        fileUpload: data?.fileUpload ?? {},
        problemType: data?.problemType ?? null,
        annotationName: data?.annotationName ?? '',
        loading: data?.loading ?? false,
        uploadType: data?.uploadType ?? null,
        uploadModalVisible: data?.uploadModalVisible ?? false,
    };
}

export type UploadType = 'uploadFromCOCO' | 'uploadFromYOLO' | 'uploadFromCSV' | null;
