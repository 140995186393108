import FullscreenOutlined from '@ant-design/icons/FullscreenOutlined';
import { IImage } from 'common/entities';
import { getDefaultHeaders, getPlatformImageParams, PlatformService } from 'common/services';
import { downloadFileFromAxios, downloadImage } from 'common/Utils';
import { Home } from 'components/assets/icons';
import { fabric } from 'fabric';
import { getMessages } from '../MLHelper';
import { IControlItem, IFabricOverlay } from './entities';

export class ImageViewerHelper {
    OSD: (typeof window)['OpenSeadragon'];
    viewer: OpenSeadragon.Viewer;
    static readonly viewerId = 'openSeaDragonViewer';
    imageSrc: string;
    overlay: IFabricOverlay;
    fabric: typeof fabric;
    mouseTracker: OpenSeadragon.MouseTracker;
    pixelSpacing: number;
    currentImage: OpenSeadragon.TiledImage;

    constructor() {
        this.onCanvasScroll = this.onCanvasScroll.bind(this);
    }

    async init() {
        try {
            const tools = ImageViewerHelper.controlTools.reduce((acc, curr) => ({ ...acc, [curr.id]: curr.id }), {});

            const { default: OpenSeadragon } = await import(/* webpackChunkName: 'openseadragon' */ 'openseadragon');
            const { initOverlay, fabric } = await import(
                /* webpackChunkName: 'openseadragon' */ 'openseadragon-fabricjs-overlay'
            );
            this.OSD = OpenSeadragon;
            initOverlay(OpenSeadragon, fabric);

            this.viewer = OpenSeadragon({
                id: ImageViewerHelper.viewerId,
                loadTilesWithAjax: true,
                showNavigator: true,
                ajaxHeaders: getDefaultHeaders(),
                maxZoomLevel: 10,
                visibilityRatio: 1.0,
                constrainDuringPan: true,
                zoomPerClick: 0,
                ...tools,
            });

            this.mouseTracker = new OpenSeadragon.MouseTracker({
                element: this.viewer.canvas,
            });

            this.viewer.addHandler('canvas-key', function (e) {
                e.preventVerticalPan = true;
            });

            this.overlay = (this.viewer as any).fabricjsOverlay({ scale: 1000 });
            this.fabric = fabric;

            this.viewer.addHandler('canvas-scroll', this.onCanvasScroll);

            return this.viewer;
        } catch (error) {
            console.error(error);
        }
    }

    get fabricCanvas() {
        return this.overlay.fabricCanvas();
    }

    get canvas() {
        return this.overlay.fabricCanvas();
    }

    onCanvasScroll(e: OpenSeadragon.CanvasScrollEvent) {
        if (e.shift) return;
        e.preventDefaultAction = true;
    }

    rotateImage(rotation: number) {
        this.viewer?.viewport?.setRotation(rotation);
    }

    flipImage() {
        this.viewer.viewport.toggleFlip();
    }
    loadImage(imageSrc: string) {
        if (!this.viewer) return;
        // this.viewer?.world.removeAll();
        this.imageSrc = imageSrc;
        if (imageSrc)
            this.viewer?.addSimpleImage({
                url: imageSrc,
                success: (e: any) => {
                    if (this.currentImage) this.viewer.world.removeItem(this.currentImage);
                    this.currentImage = e.item;
                },
                error: e => {
                    if (!this.currentImage) return;
                    this.viewer.world.removeItem(this.currentImage);
                },
            });
    }
    destroy() {
        this.viewer?.destroy();
    }

    async downloadImageFromSrc(image: IImage) {
        if (!image) return;

        downloadImage({ src: this.imageSrc, name: `${image?.imageName ?? image?.imageId ?? 'playground'}.png` });
    }

    async downloadRawImage(image: IImage, datasetId: string, imageSlice?: number, returnHandler: boolean = false) {
        if (!image) return;

        const args = {
            imageId: image.imageId,
            imageSlice,
            datasetId,
            return_handler: returnHandler,
        };

        const response = await PlatformService.Manage.GetImage.get({
            params: getPlatformImageParams(args),
            responseType: 'blob',
        });

        downloadFileFromAxios(response, `${image?.imageName ?? image?.imageId ?? 'playground'}`);
    }

    static readonly controlTools: ReadonlyArray<IControlItem> = [
        {
            id: 'homeButton',
            key: 'goHome',
            title: getMessages('000815'),
            icon: <Home />,
        },
        {
            id: 'fullPageButton',
            key: 'fullScreen',
            title: getMessages('000816'),
            icon: <FullscreenOutlined />,
        },
    ];
}
