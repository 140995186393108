import { createAction } from '@reduxjs/toolkit';

export const GET_DATASET_LIST_TRANSACTION = 'GET_DATASET_LIST_TRANSACTION';
export const GET_SOURCE_LIST_TRANSACTION = 'GET_SOURCE_LIST_TRANSACTION';
export const ARCHIVE_DATASET_TRANSACTION = 'ARCHIVE_DATASET_TRANSACTION';
export const UNARCHIVE_DATASET_TRANSACTION = 'UNARCHIVE_DATASET_TRANSACTION';
export const GET_METADATA_FILTER_FIELDS_TRANSACTION = 'GET_METADATA_FILTER_FIELDS_TRANSACTION';
export const EDIT_DATASET_TRANSACTION = 'EDIT_DATASE_TRANSACTION';
export const CREATE_CURATED_DATASET_TRANSACTION = 'CREATE_CURATED_DATASET_TRANSACTION';

export const getDatasetListTransaction = createAction(GET_DATASET_LIST_TRANSACTION);
export const getSourceListTransaction = createAction(GET_SOURCE_LIST_TRANSACTION);
export const editDatasetFormTransaction = createAction<any>(EDIT_DATASET_TRANSACTION);
export const createCuratedDatasetTransaction = createAction<any>(CREATE_CURATED_DATASET_TRANSACTION);

export const archiveDatasetTransaction = createAction<{ dataset_id: string }>(ARCHIVE_DATASET_TRANSACTION);
export const unArchiveDatasetTransaction = createAction<{ dataset_id: string }>(UNARCHIVE_DATASET_TRANSACTION);

export const getMetadataFilterFieldsTransaction = createAction<any>(GET_METADATA_FILTER_FIELDS_TRANSACTION);
