import GCheckbox from 'components/GCheckbox';
import { GRadio } from 'components/GRadio';
import Styles from "./Styles.module.scss";

export default function SelectionComponent({ selection, checked }: IProps) {

    switch (selection) {
        case "checkbox":
            return (
                <GCheckbox checked={checked} className={Styles.ImageSelection} />
            );

        case "radio":
            return (
                <GRadio checked={checked} className={Styles.ImageSelection} />
            );

        case false:
        default:
            return null;
    }

}

interface IProps {
    selection?: boolean | "checkbox" | "radio";
    checked?: boolean;
}
