import * as am5 from "@amcharts/amcharts5";
import { ChartHelper } from 'common/helpers';
import { SeriesType } from 'common/helpers/ChartHelper/entities/enums';

export function createChart(chartID: string, chartOptions?: IChartOptions) {
    const chartHelper = new ChartHelper.HierarchyChart();

    chartHelper.createRoot({ chartID });

    chartHelper
        .createChart({
            settings: {
                layout: getLayout(chartOptions?.chart?.layout, chartHelper, "horizontal"),
                innerRadius: am5.percent(50),
                width: am5.percent(100),
                height: am5.percent(100),
            },
        })
        .createSeries({
            type: SeriesType.HieararchySeries,
            seriesSettings: {
                downDepth: 3,
                initialDepth: 3,
                valueField: "value",
                categoryField: "name",
                childDataField: "children",
            },
        }, series => {

            if (!chartOptions?.series?.labels?.visible) {

                series.labels.template.setAll({
                    visible: false,
                    text: ""
                });
            } else {

                series.labels.template.setAll({
                    fontSize: 14,
                    oversizedBehavior: "truncate",
                });
            }
        });

    return chartHelper;
}

function getLayout(layout: TLayout, chartHelper: ChartHelper.HierarchyChart, defaultLayout?: TLayout): am5.Layout {
    switch (layout) {
        case "horizontal":
            return chartHelper.root.horizontalLayout;

        case "vertical":
            return chartHelper.root.verticalLayout;
        default:
            return getLayout(defaultLayout, chartHelper);
    }
}

export interface IChartOptions {
    chart?: {
        layout?: TLayout;
    };
    series?: {
        labels?: {
            visible?: boolean;
        };
    };
}

export type TLayout = "vertical" | "horizontal";