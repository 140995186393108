import { AuthService, getMessages } from "common";
import { Notification } from "components";
import { updateValidationMetricsViewState } from "pages/Validation/store";
import { put } from "redux-saga/effects";
import { IViewItem } from "../../entities/IViews";

export function* updateValidationMetricsViewSaga(sagaAction: any) {

    try {
        const response: { data: Array<IViewItem>; } = yield AuthService.UpdateValidationMetricsView.call(sagaAction.payload);

        if (!response?.data) return;

        Notification.success({ content: getMessages("001116") });
        yield put(updateValidationMetricsViewState({ viewList: response?.data }));

    } catch (err) {
        console.log(err);
    }
}