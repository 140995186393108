import type { IWindowLevel } from 'common';
import { _compactDict } from 'common/Utils';
import { Dictionary } from 'common/entities';
import { ServiceEnum } from 'common/enum';
import { getUri } from '../Client/axiosConfig';

export function getPlatformImageURL(args: IPlatformImageURLArgs) {
    let params = getPlatformImageParams(args);

    const urlQuery = new URLSearchParams(params).toString();

    return `${getUri(ServiceEnum.Management, ['image', 'get_image_stream'])}?${urlQuery}`;
}

export function getPlatformImageParams({
    imageId,
    datasetId,
    rawImage = true,
    width = 0,
    height = 0,
    imageSlice = -1,
    windowLevel,
    return_handler = false,
    extraParams = {},
}: IPlatformImageURLArgs): Dictionary {
    return _compactDict({
        image_id: imageId,
        dataset_id: datasetId,
        raw_image: rawImage,
        width,
        height,
        image_slice: imageSlice,
        ww: windowLevel?.ww,
        wc: windowLevel?.wc,
        return_handler,
        ...extraParams,
    });
}

export interface IPlatformImageURLArgs {
    imageId: string;
    datasetId: string;
    rawImage?: boolean;
    width?: number;
    height?: number;
    imageSlice?: number;
    windowLevel?: IWindowLevel;
    return_handler?: boolean;
    extraParams?: Dictionary;
}
