import { Dictionary } from "common/entities";
import { ChartHelper } from "common/helpers";
import { isNullOrUndefined } from "common/Utils";
import _uniqueId from "lodash/uniqueId";
import { useEffect, useMemo, useRef } from "react";
import { IBaseChartProps } from "../entities";
import { createChart, IChartOptions } from "./createChart";

export default function SunburstChart({ data, chartOptions, chartID, ...props }: ISunburstChartProps) {
    const chartHelperRef = useRef<ChartHelper.HierarchyChart>();
    const chartId = useMemo(() => _uniqueId(chartID), [chartID]);

    function setData() {
        if (isNullOrUndefined(data)) {
            data = [];
        }

        if (chartHelperRef.current) {
            chartHelperRef.current.setData(data);
        }
    }

    useEffect(() => {
        setData();
    }, [data]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(chartId, chartOptions);
            setData();
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
    }, []);

    return (
        <>
            <div className={props.className} style={Object.assign({ height: props.height, width: props.width }, props.style)} id={chartId} />
        </>
    );
}

export interface ISunburstChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    height?: number;
    width?: number;
    chartOptions?: IChartOptions;
}