import { PayloadAction } from "@reduxjs/toolkit";
import { TUploadStatus } from "components/GUpload";
import { WritableDraft } from "immer/dist/internal";
import { IDatasetState } from "pages/Datasets/entities";
import { IUploadParams } from "../entities";
import { IS3Params } from "../entities/IUploadParams";

export const DatasetUploadReducer = {
    setUploadLoading(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<boolean>) {
        state.datasetUploadState.uploadLoading = payload;
    },
    updateDatasetUploadParams(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IUploadParams>>) {
        Object.entries(payload ?? {}).forEach(([key, value]) => {
            (state as any).datasetUploadState.uploadParams[key] = value;
        });
    },
    updateS3Params(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Partial<IS3Params>>) {
        Object.entries(payload ?? {}).forEach(([key, value]) => {
            (state as any).datasetUploadState.uploadParams.s3Params[key] = value;
        });
    },
    setThumbnailList(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<Array<string>>) {
        state.datasetUploadState.thumbnailList = payload;
    },
    setUploadStatus(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<TUploadStatus>) {
        state.datasetUploadState.uploadStatus = payload;
    },
    setUploadProgress(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<number>) {
        state.datasetUploadState.uploadProgress = payload;
    },
};