import { PayloadAction } from "@reduxjs/toolkit";
import { APIService, getMessages } from "common";
import { showMessageModal } from "components";
import { IDeploymentTrackingResponse } from "pages/Models/entities";

export function* updatePreEmptiveModelSaga({ payload }: PayloadAction<{ payload: Partial<IDeploymentTrackingResponse>; }>): any {
    try {
        const response = yield APIService.Models.UpdatePreEmptiveOptions.post(payload);
        if (response.data) {
            return showMessageModal({
                type: "success",
                content: getMessages("000680"),
                cancelButton: {
                    enabled: false,
                },
                okButton: {
                    text: getMessages("000661").replace('Dataset', 'Model'),
                },
            });
        }

    } catch (error) {
        console.log(error);
    }
}