import { useGetImageDetailsQuery } from 'app/store/APISlice';
import { IImage } from 'common/entities';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { IViewportDetails } from '../entities';
import { selectActiveViewport, selectActiveViewportState } from '../store';
import { useViewportElementMap } from './DicomViewerState';
import { useDataset } from './ImageState';
import { useViewerImageList } from './useViewerImageList';

export function useActiveViewport() {
    return useSelector(selectActiveViewportState);
}

export function useActiveViewportElement() {
    const activeViewport = useSelector(selectActiveViewport);
    const [viewportElementMap] = useViewportElementMap();
    return viewportElementMap[activeViewport];
}

export function useActiveViewportDetails(): IViewportDetails {
    const activeViewportIndex = useSelector(selectActiveViewport);
    const activeViewport = useActiveViewport();

    const imageList = useViewerImageList();

    return useMemo(
        () => ({ ...activeViewport, viewportIndex: activeViewportIndex, imageList }),
        [activeViewport, activeViewportIndex, imageList]
    );
}

export function useActiveViewportImage(): IImage {
    const activeViewport = useActiveViewportDetails();
    const dataset = useDataset(); //if dataset=playground, dataset is undefined

    return useGetImageDetailsQuery(
        { datasetId: dataset?.datasetId, imageId: activeViewport?.imageId },
        { skip: !dataset?.datasetId || !activeViewport?.imageId }
    )?.currentData;
}
