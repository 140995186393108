import { AxiosResponse } from 'axios';
import { IImage, IImageResponse } from 'common/entities';
import { APIService } from 'common/services/serviceList';
import { transformImages } from './transformImages';

export async function getDatasetImages({
    datasetId,
    skip,
    limit,
    search,
    signal,
    seriesId,
    annotationId,
    extraFields = [],
    excludeImages = [],
    includeImages = [],
    studyId,
}: IGetDatasetImagesArgs): Promise<{ data: Array<IImage>; total: number }> {
    try {
        if (!datasetId) return { data: [], total: 0 };

        let response: AxiosResponse<{ data: Array<IImageResponse>; total: number }> =
            await APIService.Datasets.GetDatasetImages.post(
                { extraFields, excludeImages, includeImages },
                {
                    params: {
                        dataset_id: datasetId,
                        skip,
                        limit,
                        search,
                        seriesId,
                        annotation_id: annotationId,
                        studyId,
                    },
                    signal,
                }
            );
        const data = transformImages(response?.data?.data, datasetId);
        const total = response?.data?.total;

        return { data, total };
    } catch (e) {
        console.log(e);
        return { data: [], total: 0 };
    }
}

interface IGetDatasetImagesArgs {
    datasetId: string;
    skip?: number;
    limit?: number;
    search?: string;
    signal?: AbortSignal;
    seriesId?: string;
    annotationId?: string;
    extraFields?: Array<ExtraFields>;
    excludeImages?: Array<string>;
    includeImages?: Array<string>;
    studyId?: string;
}
export type ExtraFields = 'similar_images_list';
