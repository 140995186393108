import { Image } from 'antd';
import { IDataset, IImage } from 'common/entities';
import { IWindowLevel } from 'common/enum';
import { usePlatformThumbnail } from 'common/hooks/usePlatformThumbnail';
import { GImageSkeletonItem } from 'components/GSkeleton/GImageSkeleton';
import placeholder from 'components/assets/placeholder/placeholder.png';
import { useGetImageThumbnailQuery, useGetPacsAccessQuery } from 'pages/Datasets/views/PacsExplorer/entities/PacsService';
import Styles from './Styles.module.scss';

export default function Thumbnail({
    imageName,
    imageId,
    datasetId,
    windowLevel,
    sliceCount,
    style,
    dataset,
    seriesId,
    instanceId,
    studyId,
    loading: pageLoading,
}: IProps) {
    const { image: platformImage, loading } = usePlatformThumbnail({
        imageId,
        datasetId,
        windowLevel,
        slice: sliceCount ? 0 : -1,
        skip: dataset?.dataServer === 'pacs',
    });
    const { currentData: pacs } = useGetPacsAccessQuery(dataset.pacsId, { skip: dataset?.dataServer !== 'pacs' });
    const { data: pacsImage, isLoading } = useGetImageThumbnailQuery(
        {
            token: pacs?.token,
            baseUrl: pacs?.url,
            SeriesInstanceUID: seriesId,
            StudyInstanceUID: studyId,
            SOPInstanceUID: instanceId,
        },
        { skip: dataset?.dataServer !== 'pacs' || !pacs?.url || !instanceId || !seriesId || !studyId }
    );

    if (pageLoading || loading || isLoading)
        return (
            <GImageSkeletonItem
                className={Styles.ThumbnailSkeleton}
                style={style}
                active
                showTitle={false}
            />
        );

    const image = dataset?.dataServer === 'pacs' ? pacsImage : platformImage;

    return (
        <>
            <Image
                src={image}
                alt={imageName}
                className={Styles.Image}
                fallback={placeholder}
                placeholder={placeholder}
                preview={false}
                style={style}
            />
        </>
    );
}

interface IProps extends IImage {
    windowLevel?: IWindowLevel;
    style?: React.CSSProperties;
    loading?: boolean;
    dataset: IDataset;
}
