import { TableColumnsType } from 'antd';
import { ColumnType } from 'antd/es/table';
import { IStudy } from 'common/entities';
import useStudyTable from 'common/hooks/useStudyTable';
import { GDataGrid, IDataGridProps } from 'components/GDataGrid';
import GSelectionTable from 'components/GDataGrid/GSelectionTable';
import Styles from './Styles.module.scss';

export default function StudyTable({ studyList, extraColumns, excludeColumns, columns, pagination, ...props }: IStudyTable) {
    let { tableColumns } = useStudyTable(studyList);

    extraColumns?.forEach(col => tableColumns.splice(col.order, 0, col.column));
    if (excludeColumns?.length) {
        tableColumns = tableColumns?.filter((column: any) => !excludeColumns.includes(column.dataIndex));
    }

    return (
        <>
            {props?.selectable ? (
                <GSelectionTable
                    {...props}
                    className={Styles.SelectionTableOuter}
                    selectionType={props?.selectionType ?? 'single'}
                    idIndex="study_id"
                    onChangeSelection={props?.onChangeSelection}
                    selectionList={props?.selectionList}
                    selectionDisabledList={props?.selectionDisabledList}
                    columns={columns ?? tableColumns}
                    dataSource={studyList}
                />
            ) : (
                <GDataGrid
                    dataSource={studyList}
                    columns={columns ?? tableColumns}
                    rowKey="study_id"
                    pagination={pagination ?? { hideOnSinglePage: true }}
                    className={Styles.StudyTable}
                    {...props}
                />
            )}
        </>
    );
}

interface IStudyTable extends IDataGridProps<IStudy> {
    studyList: Array<IStudy>;
    extraColumns?: Array<IExtraColumn>;
    excludeColumns?: Array<string>;
    columns?: TableColumnsType;
    selectable?: boolean;
    selectionList?: string[];
    selectionType?: 'single' | 'multiple';
    selectionDisabledList?: string[];
    onChangeSelection?: (studyId: string, checked: boolean) => void;
}

interface IExtraColumn {
    column: ColumnType<IStudy>;
    order?: number;
}
