import { enMessages, MessageKeys, messages } from './messages';
import { serviceMessages } from './serviceMessages';

type ILanguage = 'tr-TR' | 'en-EN';

export class MLHelper {
    static readonly currentLangugage: ILanguage = 'en-EN';
    // static currentLangugage: ILanguage = localStorage.getItem("currentLanguage") as ILanguage || navigator.language as ILanguage;
    static readonly supportedLanguages: ILanguage[];
}

export function getMessages(message: MessageKeys) {
    const localeMessages = messages[MLHelper.currentLangugage];

    return (localeMessages[message] || '') as (typeof enMessages)[MessageKeys];
}

export function getServerMessages(message: string) {
    return serviceMessages[MLHelper.currentLangugage]?.[message] ?? message;
}
export type { MessageKeys };
