import { APIService } from "common";
import { put } from "redux-saga/effects";
import { setVendorList } from "..";

export function* GetVendorListSaga(): any {
    try {
        const response = yield APIService.Models.GetVendors.call();

        if (response?.status === 200 && response?.data) {
            yield put(setVendorList(response.data));
        }
    } catch (error) {
        console.log(error);
    }
}