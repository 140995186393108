export function _mergeBy<TObject = any, TSource = any>(
    target: Array<TObject> = [],
    source: Array<TSource> = [],
    key: keyof TObject & keyof TSource,
    mergeFn: (target: TObject, source: TSource) => TObject & TSource
) {
    if (!target) return structuredClone(source || []);
    return target?.map(targetItem => {
        const sourceItem = source?.find(sourceItem => (sourceItem[key] as any) === targetItem[key]);
        if (sourceItem) return mergeFn(structuredClone(targetItem), structuredClone(sourceItem));

        return targetItem;
    });
}
