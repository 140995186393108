import { CornerstoneMouseEvent, MeasurementEvent } from 'common/helpers/DicomViewerHelper/interface';
import { triggerLabelmapModifiedEvent } from 'common/helpers/SegmentationHelper/lib';
import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';

const { getters, setters } = cornerstoneTools.getModule('segmentation');
const { getDiffBetweenPixelData } = cornerstoneTools.importInternal('util/segmentationUtils');
const draw = cornerstoneTools.importInternal('drawing/draw');
const drawJoinedLines = cornerstoneTools.importInternal('drawing/drawJoinedLines');
const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext');
const segmentationUtils = cornerstoneTools.importInternal('util/segmentationUtils');

/**
 * Render hook: draws the FreehandScissors's outline
 *
 * @param {Object} evt Cornerstone.event#cornerstoneimagerendered > cornerstoneimagerendered event
 * @memberof Tools.ScissorsTool
 * @returns {void}
 */
function renderToolData(this: any, evt: CustomEvent<MeasurementEvent>) {
    const lock = cornerstoneTools.getModule('globalConfiguration').configuration?.lockAnnotationTools;
    if (lock) return;

    const eventData = evt.detail;
    const { element } = eventData;
    try {
        const color = getters.brushColor(element, true);
        const context = getNewContext(eventData.canvasContext.canvas);
        const points = this.handles.points;

        if (points.length < 2) {
            return;
        }

        draw(context, (context: CanvasRenderingContext2D) => {
            for (let j = 0; j < points.length; j++) {
                const lines = [...points[j].lines];

                if (j === points.length - 1) {
                    // If it's still being actively drawn, keep the last line to
                    // The mouse location
                    lines.push(points[0]);
                }
                drawJoinedLines(context, element, points[j], lines, {
                    color,
                });
            }
        });
    } catch (error) {
        console.error('Error on renderToolData:', error);
    }
}

/**
 * Sets the start handle point and claims the eventDispatcher event
 *
 * @private
 * @param {*} evt // mousedown, touchstart, click
 * @returns {void|null}
 */
function _startOutliningRegion(this: any, evt: CustomEvent<CornerstoneMouseEvent>): any {
    const element = evt.detail.element;
    const image = evt.detail.currentPoints.image;
    const points = this.handles.points;

    if (!points.length) {
        console.warn('Something went wrong, empty handles detected.');

        return null;
    }

    points.push({
        x: image.x,
        y: image.y,
        lines: [],
    });

    this.currentHandle += 1;

    cornerstone.updateImage(element);
}

/**
 * This function will update the handles and updateImage to force re-draw
 *
 * @private
 * @method _setHandlesAndUpdate
 * @param {(CornerstoneTools.event#TOUCH_DRAG|CornerstoneTools.event#MOUSE_DRAG|CornerstoneTools.event#MOUSE_MOVE)} evt  Interaction event emitted by an enabledElement
 * @returns {void}
 */
function _setHandlesAndUpdate(this: any, evt: CustomEvent<CornerstoneMouseEvent>) {
    const eventData = evt.detail;
    const element = evt.detail.element;

    this._addPoint(eventData);
    cornerstone.updateImage(element);
}

/**
 * Event handler for MOUSE_UP/TOUCH_END during handle drag event loop.
 *
 * @private
 * @method _applyStrategy
 * @param {(CornerstoneTools.event#MOUSE_UP|CornerstoneTools.event#TOUCH_END)} evt Interaction event emitted by an enabledElement
 * @returns {void}
 */
function _applyStrategy(this: any, evt: CustomEvent) {
    const lock = cornerstoneTools.getModule('globalConfiguration').configuration?.lockAnnotationTools;
    if (lock) return;

    const points = this.handles.points;
    const { element, event } = evt.detail;
    if (event?.button !== 0) return;

    const { labelmap2D, labelmap3D, currentImageIdIndex } = getters.labelmap2D(element);

    const pixelData = labelmap2D.pixelData;
    const previousPixeldata = pixelData.slice();

    const operationData = {
        points,
        pixelData,
        segmentIndex: labelmap3D.activeSegmentIndex,
        segmentationMixinType: `freehandSegmentationMixin`,
    };

    this.applyActiveStrategy(evt, operationData);
    const diff: Array<[number, number, number]> = segmentationUtils.getDiffBetweenPixelData(previousPixeldata, pixelData);
    const operation = {
        imageIdIndex: currentImageIdIndex,
        diff: getDiffBetweenPixelData(previousPixeldata, pixelData),
    };

    setters.pushState(this.element, [operation]);

    // Invalidate the brush tool data so it is redrawn
    setters.updateSegmentsOnLabelmap2D(labelmap2D);
    cornerstone.updateImage(element);

    const segmentIndexList = Array.from(new Set(diff.flatMap(value => value.slice(1, 3)))).filter(value => value > 0);

    triggerLabelmapModifiedEvent(this.element, { imageIdIndex: currentImageIdIndex, segmentIndexList });
    this._resetHandles();
}

/**
 * Sets the start and end handle points to empty objects
 *
 * @private
 * @method _resetHandles
 * @returns {undefined}
 */
function _resetHandles(this: any) {
    this.handles = {
        points: [],
    };

    this.currentHandle = 0;
}

/**
 * Adds a point on mouse click in polygon mode.
 *
 * @private
 * @param {Object} evt - data object associated with an event.
 * @returns {void}
 */
function _addPoint(this: any, evt: any) {
    const points = this.handles.points;

    if (points.length) {
        // Add the line from the current handle to the new handle
        points[this.currentHandle - 1].lines.push({
            x: evt.currentPoints.image.x,
            y: evt.currentPoints.image.y,
            lines: [],
        });
    }

    // Add the new handle
    points.push({
        x: evt.currentPoints.image.x,
        y: evt.currentPoints.image.y,
        lines: [],
    });

    // Increment the current handle value
    this.currentHandle += 1;

    // Force onImageRendered to fire
    cornerstone.updateImage(evt.element);
}

/**
 * @mixin freehandSegmentationMixin - segmentation operations for freehand
 * @memberof Mixins
 */
const freehandSegmentationMixin = {
    postTouchStartCallback: _startOutliningRegion,
    postMouseDownCallback: _startOutliningRegion,
    mouseClickCallback: _startOutliningRegion,
    touchDragCallback: _setHandlesAndUpdate,
    mouseDragCallback: _setHandlesAndUpdate,
    mouseMoveCallback: _setHandlesAndUpdate,
    touchEndCallback: _applyStrategy,
    mouseUpCallback: _applyStrategy,
    initializeMixin: _resetHandles,
    renderToolData,
    _resetHandles,
    _addPoint,
    _applyStrategy,
};

export default freehandSegmentationMixin;
