import { INotification } from 'app/entities';
import { DownloadColor } from 'components/assets/icons';

export default function DownloadJsonReport({ data }: { data: INotification }) {
    async function handleClick() {
        const blob = new Blob([JSON.stringify(data)], {
            type: 'application/json',
        });
        const href = await URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = href;
        link.download = `${data?.id ?? 'file'}.json`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    }

    return (
        <DownloadColor
            style={{ cursor: 'pointer' }}
            onClick={handleClick}
        />
    );
}
