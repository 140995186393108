import GLazyList from "components/GLazyList";
import { IDatasourceProps } from "../../entities";
import Styles from "./Styles.module.scss";

export default function DashboardListView({ datasource, ListItemComponent, keyField }: IDashboardListViewProps) {


    return (
        <>
            <GLazyList className={Styles.DashboardListViewContainer}>
                {datasource?.map((item) => (<ListItemComponent item={item} key={item[keyField]} />))}
            </GLazyList>
        </>
    );
}

export interface IDashboardListViewProps<T = any> extends IDatasourceProps<T> {
    ListItemComponent: React.FC<{ item: T; }>;
}