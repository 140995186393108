export default function getPointsInSubvolume(points, extent) {
    // Deal with the case where the points are an array,
    // Or an object with a start and an end.
    // Get from center point here


    if (Array.isArray(points)) {
      const pointsSubvolume = [];
  
      points.forEach(point => {
        pointsSubvolume.push({
          x: Math.floor(point.x - extent.topLeft.x),
          y: Math.floor(point.y - extent.topLeft.y)
        });
      });
      
      return pointsSubvolume;
    }
    const { start, end } = points;

    let centerX = Math.abs((end.x + start.x) / 2);
    let centerY = Math.abs((end.y + start.y) / 2);

    const startSubVolume = {
      x: Math.floor(centerX - extent.topLeft.x),
      y: Math.floor(centerY - extent.topLeft.y)
    };
    const endSubVolume = {
      x: Math.floor(end.x - extent.topLeft.x) ,
      y: Math.floor(end.y - extent.topLeft.y) +10
    };

    return {
      start: startSubVolume,
      end: endSubVolume
    };
  }