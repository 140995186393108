interface Headers {
  [key: string]: string;
}

export function extractFileNameFromHeaders(headers: Headers | null | undefined): string | null {
  const corefileName = headers?.["content-disposition"];

  if (!corefileName) {
    return null; // or handle the absence of the header accordingly
  }

  const fileName = corefileName.split('filename=')[1].split(';')[0].replace(/^"(.+(?="$))"$/, '$1');
  return fileName;
}
