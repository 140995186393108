import cs from 'classnames';
import { IImage } from 'common/entities';
import { getMessages } from 'common/helpers';
import GAutoComplete from 'components/GAutoComplete';
import GLazyList from 'components/GLazyList';
import GImageSkeleton from 'components/GSkeleton/GImageSkeleton';
import _debounce from 'lodash/debounce';
import usePreventScroll from 'pages/AnnotationCS/views/ToolView/hooks/usePreventScroll';
import { useMemo } from 'react';
import GalleryItem from './GalleryItem';
import Styles from './Styles.module.scss';
import { IGalleryProps } from './interface';
import GNothingToSee from 'components/GNothingToSee';
import { Search20 } from 'components/assets/icons';

export default function ListView({
    imageList,
    className,
    activeImage,
    id,
    style,
    onChangeImage,
    renderCallback,
    onSearch,
    searchValue,
    dataset,
    loading,
}: IGalleryProps) {
    usePreventScroll();

    const options = useMemo(
        () =>
            imageList?.map(image => ({
                key: image.imageId,
                value: image.imageName || image.imageId,
                label: image.imageName || image.imageId,
            })),
        [imageList]
    );

    if (loading && !imageList?.length)
        return (
            <GImageSkeleton
                count={8}
                active
                className={Styles.ImageList}
                itemClassName={Styles.GallerySkeletonItem}
            />
        );

    if (!imageList?.length) return <GNothingToSee />;

    return (
        <div
            className={cs(Styles.GalleryContainer, className)}
            style={style}
        >
            <GAutoComplete
                className={Styles.ImageSearch}
                options={options}
                onSearch={_debounce(onSearch, 1000)}
                onSelect={(value: string, option: any) => {
                    onChangeImage(option.key);
                    onSearch(option.label);
                }}
                onKeyDown={e => e.stopPropagation()}
                placeholder={getMessages('000673')}
                caseInsensitive
                allowClear
                autoClearSearchValue
                id="playground-image-select"
                icon={<Search20 />}
            />

            <GLazyList
                renderCallback={renderCallback}
                id={id}
                className={Styles.ImageList}
            >
                {imageList?.map((item: IImage) =>
                    getImageName(item)?.toLocaleUpperCase().includes(searchValue?.toLocaleUpperCase()) ? (
                        <GalleryItem
                            activeImageId={activeImage?.imageId}
                            key={item.imageId}
                            image={item}
                            dataset={dataset}
                            onChangeImage={onChangeImage}
                        />
                    ) : null
                )}
            </GLazyList>
        </div>
    );
}

function getImageName(image: IImage) {
    return image.imageName || image.imageId;
}
