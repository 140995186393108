import { Input } from 'antd';
import { TextAreaProps } from 'antd/lib/input';
import React from 'react';
import Styles from "./assets/Styles.module.scss";
import cx from "classnames"

export default function GTextArea({ onChange, onInputChange, className, ...props }: IGTextArea) {

  function onChangeValue(e: React.ChangeEvent<HTMLTextAreaElement>) {
    onInputChange?.(e.target?.value, e);
    onChange?.(e);
  }


  return (
    <div className={cx(Styles.TextAreaContainer, className)}>
      <Input.TextArea
        onChange={onChangeValue}
        {...props}
      />
    </div>
  );
}


interface IGTextArea extends TextAreaProps {
  onInputChange?: (value: string, e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  size?:any;
}