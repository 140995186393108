import { getMessages } from 'common/helpers/MLHelper';

export const SamplingStrategies = {
    random: getMessages('001122'),
    sequential: getMessages('001123'),
    ai_assisted: getMessages('001124'),
};

export const LabelingStrategies = {
    single: getMessages('001144'),
    multiple: getMessages('001145'),
};

export const AnnotationModes = {
    simultaneous: getMessages('001148'),
    discrete: getMessages('001149'),
};
