import { NODE_ENV } from 'common/config';
import { H } from 'highlight.run';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/selectors';

export function useHighlight() {
    const user = useSelector(selectCurrentUser);

    useEffect(() => {
        if (!user || NODE_ENV !== 'production') return;
        H.identify(user.email, {
            id: user.userId,
        });
    }, [user]);
}

if (NODE_ENV === 'production') {
    H.init('6gl8jle9', {
        tracingOrigins: true,
        networkRecording: {
            enabled: true,
            recordHeadersAndBody: true,
            urlBlocklist: [
                // insert full or partial urls that you don't want to record here
                // Out of the box, Highlight will not record these URLs (they can be safely removed):
                // 'https://www.googleapis.com/identitytoolkit',
                // 'https://securetoken.googleapis.com',
            ],
        },
    });
}
