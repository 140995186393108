import cs from 'classnames';
import GImageSkeleton from 'components/GSkeleton/GImageSkeleton';
import usePreventScroll from 'pages/AnnotationCS/views/ToolView/hooks/usePreventScroll';
import SerieItem from './SerieItem';
import Styles from './Styles.module.scss';
import { IGalleryProps } from './interface';

export default function SerieView({
    seriesList,
    dataset,
    onChangeSerie,
    className,
    id,
    style,
    loading,
    activeSerie,
}: IGalleryProps) {
    usePreventScroll();

    if (loading)
        return (
            <GImageSkeleton
                count={8}
                active
                className={Styles.ImageList}
                itemClassName={Styles.GallerySkeletonItem}
            />
        );

    return (
        <>
            <div
                className={cs(Styles.GalleryContainer, Styles.SeriesList, className)}
                style={style}
                id={id}
            >
                {seriesList?.map(item => (
                    <SerieItem
                        activeSerie={activeSerie}
                        item={item}
                        key={item.series_id}
                        dataset={dataset}
                        onChangeSerie={onChangeSerie}
                    />
                ))}
            </div>
        </>
    );
}
