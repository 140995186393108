import { PaginationProps } from 'antd';
import { IImage } from 'common/entities';
import { getDatasetImages } from 'common/services';
import { useEffect, useRef, useState } from 'react';
import { useUpdateEffect } from 'react-use';
import { useDatasetData } from 'redux/selectors';

export function useImageList({
    datasetId,
    pageSize = 12,
    excludeImages,
    includeImages,
    fetch = true,
    skipSignal,
    studyId,
}: IArgs) {
    const dataset = useDatasetData(datasetId);
    const [imageList, setImageList] = useState<Array<IImage>>([]);
    const [totalCount, setTotalCount] = useState(0);
    const [skip, setSkip] = useState(0);
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState<string>('');
    const abortController = useRef(new AbortController());

    if (skipSignal)
        skipSignal.onabort = () => {
            setSkip(skipSignal?.reason ?? 0);
        };

    useEffect(() => {
        setImageList([]);
        setTotalCount(0);
        setSkip(0);
        getImageList();
        setSearchText('');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataset?.datasetId, studyId]);

    useUpdateEffect(() => {
        getImageList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [skip]);

    useUpdateEffect(() => {
        setSkip(0);
        getImageList(0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [excludeImages, includeImages]);

    function onSearch(_search: string) {
        setSkip(0);
        getImageList(0, _search);
    }

    async function getImageList(_skip = skip, _search?: string) {
        try {
            if (loading) abortController.current.abort();
            if (!fetch) return;
            setLoading(true);
            abortController.current = new AbortController();
            const { data, total } = await getDatasetImages({
                datasetId: dataset?.datasetId,
                skip: _skip,
                limit: pageSize,
                search: _search ?? searchText?.toString(),
                signal: abortController.current.signal,
                excludeImages,
                includeImages,
                studyId,
            });

            setImageList(data || []);
            setTotalCount(total);
        } catch (error) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    const pagination: PaginationProps = {
        pageSize,
        total: totalCount,
        onChange: page => setSkip((page - 1) * pageSize),
        showSizeChanger: false,
        current: skip / pageSize + 1,
    };

    return { imageList, totalCount, loading, pageSize, pagination, searchText, setSearchText, onSearch };
}

interface IArgs {
    datasetId: string;
    pageSize?: number;
    excludeImages?: Array<string>;
    includeImages?: Array<string>;
    fetch?: boolean;
    skipSignal?: AbortSignal;
    studyId?: string;
}
