import { Tensorflow, PyTorch, Onnx, Scikit, Keras } from 'components/assets/icons';
import React from 'react';
import Styles from "./Styles.module.scss";

export default function FrameworkLogo(props: { framework: string; }) {

    if (!props.framework) return null;

    switch (props.framework.toLocaleLowerCase()) {
        case "tensorflow":
            return <Tensorflow />;

        case "pytorch":
            return <PyTorch />;

        case "onnx":
            return <Onnx />;

        case "scikit":
            return <Scikit />;

        case "keras":
            return <Keras />;

        default:
            return <span className={[Styles.DataFramework, "caption2-normal"].join(" ")}>{props.framework}</span>;

    }
}
