import { DatasetsFiltersFactory, Dictionary, IDatasetFilters } from 'common';

export interface ICommonState {
    selectedSlice: number;
    isUploadLoading: boolean;
    isDrawerVisible: boolean;
    datasetFilters: IPlaygroundDatasetFilters;
    imageSimilarityList: Dictionary<string>;
    explainResult: string;
    isExplainLoading: boolean;
    isSimilarImagesModalVisible: boolean;
    isClosedPredictionEditor?: boolean;
    isVisibleImageGallery?: boolean;
    isInputFocused?: boolean;
    isStudyModalOpen?: boolean;
    isRawModalVisible?: boolean;
    settingsModalOpen?: boolean;
}

export function CommonStateFactory(data?: Partial<ICommonState>): ICommonState {
    return {
        selectedSlice: data?.selectedSlice ?? null,
        isUploadLoading: false,
        isDrawerVisible: data?.isDrawerVisible ?? false,
        datasetFilters: PlaygroundDatasetFiltersFactory(data?.datasetFilters),
        imageSimilarityList: data?.imageSimilarityList ?? {},
        explainResult: data?.explainResult ?? null,
        isExplainLoading: data?.isExplainLoading ?? false,
        isSimilarImagesModalVisible: data?.isSimilarImagesModalVisible ?? false,
        isClosedPredictionEditor: data?.isClosedPredictionEditor ?? true,
        isVisibleImageGallery: data?.isVisibleImageGallery ?? false,
        isInputFocused: data?.isInputFocused ?? false,
        isStudyModalOpen: data?.isStudyModalOpen ?? false,
        isRawModalVisible: data?.isRawModalVisible ?? false,
        settingsModalOpen: data?.settingsModalOpen ?? false,
    };
}

export interface IPlaygroundDatasetFilters extends IDatasetFilters {
    showCompatibles?: boolean;
    showDicoms?: boolean;
}

export function PlaygroundDatasetFiltersFactory(data?: IPlaygroundDatasetFilters): IPlaygroundDatasetFilters {
    return Object.assign({}, DatasetsFiltersFactory(data), {
        showCompatibles: data?.showCompatibles ?? false,
        showDicoms: data?.showDicoms ?? false,
    });
}

export type ImageDetailStatus = 'loading' | 'failed' | 'done';
