import React from 'react';
import { ISearchOption } from './SearchItem';

// Finds `obj[path][to][key]` from `path.to.key`
const resolveAttribute = (obj:ISearchOption, key:string) => key
  .split('.')
  .reduce((prev:ISearchOption, curr:string) => prev?.[curr as keyof ISearchOption], obj);

// Recursively builds JSX output adding `<mark>` tags around matches
const highlight:any = (value:string, indices:number[][] = [], i = 1) => {
  const pair = indices[indices.length - i];
  return !pair ? value : (
    <>
      {highlight(value.substring(0, pair[0]), indices, i+1)}
      <span className='primary'>{value.substring(pair[0], pair[1]+1)}</span>
      {value.substring(pair[1]+1)}
    </>
  );
};

function renderDescription(matches:IMatches) : string {
  return matches?.value?.slice(matches?.indices?.[0]?.[0],matches?.indices?.[0]?.[0]+80)
}

// FuseHighlight component
const FuseHighlight = ({ hit, attribute }:any) => {
  const matches = typeof hit.item === 'string'
    ? hit.matches?.[0]
    : hit.matches?.find((m:IMatches) => m.key === attribute);
  const fallback = typeof hit.item === 'string'
    ? hit.item
    : resolveAttribute(hit.item, attribute);
  return highlight(
    matches?.key === "description" ? 
    (matches?.indices?.[0]?.[0] !== 0 ?  "..."+ renderDescription(matches)+"..." : renderDescription(matches)+"..." || fallback) 
    : (matches?.value || fallback), matches?.indices);
};

export default FuseHighlight;

interface IMatches{
  indices:number[][],
  value:string,
  key:string,
}