import { AuthActiveView, getMessages } from 'common';
import { useCurrentView } from 'pages/Authentication/hooks';
import Styles from './Styles.module.scss';
import LoginRegisterSwitch from '../LoginRegisterSwitch';

export default function AuthCard({ children }: IProps) {
    const currentView = useCurrentView();

    return (
        <div className={Styles.AuthCardContainer}>
            <div className={Styles.AuthCardCaption}>
                <div className="h4-bold white mb-8">{viewMap[currentView]?.caption}</div>
                <div className="subtitle2-normal grey">{viewMap[currentView]?.message}</div>
            </div>
            {(currentView === 'login' || currentView === 'register') && <LoginRegisterSwitch />}
            {children}
        </div>
    );
}

interface IProps {
    children: React.ReactNode;
}

const viewMap: TViewMap = {
    login: {
        caption: getMessages('000128') + ' Gesundai!',
        message: getMessages('000331'),
    },
    register: {
        caption: getMessages('000135'),
        message: getMessages('000335'),
    },
    'forgot-password': {
        caption: getMessages('000139'),
        message: getMessages('000140'),
    },
    'new-password': {
        caption: getMessages('000143'),
        message: getMessages('000144'),
    },
};

type TViewMap = Partial<Record<AuthActiveView, ICardContent>>;

interface ICardContent {
    caption?: string;
    message: string;
}
