import { PayloadAction } from "@reduxjs/toolkit";
import { FormInstance } from "antd";
import { AxiosResponse } from "axios";
import { APIService, getMessages } from "common";
import { showMessageModal } from "components";
import { IModelCardResponsePayload, getModelCardData } from "pages/Models/entities";
import { ModelArtifactPropsFactory } from "pages/Models/views/ModelUpload/entities/IModelArtifactProps";
import { put } from "redux-saga/effects";
import { updateModelArtifactProps, updateModelCommonState, updateModelList } from "..";

export function* editModelFormSaga(sagaAction: PayloadAction<{ payload: IModelCardResponsePayload, form: FormInstance; }>): any {

    try {
        let { payload, form } = sagaAction.payload;

        const response: AxiosResponse<IModelCardResponsePayload> = yield APIService.Models.EditModel.call(payload, { params: { model_id: payload.model_id } });

        if (response?.data) {
            form?.resetFields?.();
            yield put(updateModelList(getModelCardData(response.data)));
            yield put(updateModelCommonState({ isDrawerVisible: false }));
            yield put(updateModelArtifactProps(ModelArtifactPropsFactory()))

            return showMessageModal({
                type: "success",
                content: getMessages("000660").replace('Dataset', 'Model'),
                cancelButton: {
                    enabled: false,
                },
                okButton: {
                    text: getMessages("000661").replace('Dataset', 'Model'),
                },
            });
        }
    } catch (error) {
        console.log(error);
    }
}