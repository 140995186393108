import GDropdown from 'components/GDropdown';
import { UserLine24 } from 'components/assets/icons';
import IconBackground from '../IconBackground';
import Styles from './Styles.module.scss';
import useUserMenu from './useUserMenu';

export default function UserMenu() {
    const { userMenuList, renderUserDropdown, open, handleOpenDropdown } = useUserMenu();

    return (
        <GDropdown
            menuItemList={userMenuList}
            className={Styles.UserDropdown}
            dropdownRender={renderUserDropdown}
            open={open}
            onOpenChange={handleOpenDropdown}
            trigger={['click']}
            overlayClassName={Styles.UserDropdownOverlay}
        >
            <div>
                <IconBackground icon={<UserLine24 />} />
            </div>
        </GDropdown>
    );
}
