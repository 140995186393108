import { Dictionary } from 'common/entities';
import { _entries, _toNumber } from 'common/Utils';
import HorizontalBarChart from 'components/GChartTemplate/HorizontalBarChart';
import { IChartOptions } from 'components/GChartTemplate/HorizontalBarChart/createChart';

export function MetadataImportanceChart({ data, id }: IProps) {

    const chartData = _entries(data)
        .map(([name, value]) => ({ name, value: _toNumber(value.toFixed(3)) }))
        .sort((a, b) => a.value - b.value);

    const height = chartData.length * 50 + 100;

    return (
        <>
            <HorizontalBarChart
                chartID={"metadata-importance-chart" + id}
                data={chartData}
                chartOptions={chartOptions}
                height={height}
                style={{ minHeight: 400 }}
            />
        </>
    );
}

interface IProps {
    data: Dictionary<number>;
    id: string;
}


const chartOptions: IChartOptions = {
    valueBulletEnabled: true,
    dynamicBulletPosition: true,
    yAxis: {
        wrap: true,
        maxWidth: 100,
    }
};