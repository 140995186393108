/* eslint-disable react-hooks/exhaustive-deps */
import { Image } from "antd";
import { isNullOrUndefined } from 'common/Utils';
import { useDidUpdate } from 'common/hooks';
import { Delete, UploadFile } from "components/assets/icons";
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Styles from "./Styles.module.scss";
import UploadProgress from './UploadProgress';

export default function GUpload(props: GUploadProps) {
    const [file, setFile] = useState<File | undefined>();
    const [previewURL, setPreviewURL] = useState("");

    useDidUpdate(() => {
        props.onChange?.(file)
    }, [file])

    const onDrop = useCallback((acceptedFiles: Array<File>) => {
        const droppedFile = acceptedFiles[0];
        setFile(droppedFile);
        props.onDrop?.(acceptedFiles);
    }, []);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop, maxFiles: props.maxFiles || 1, accept: props.accept, disabled: props.disabled
    });

    const style: React.CSSProperties = { cursor: props.disabled ? "no-drop" : "pointer" };


    function onDeleteFile(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
        e.preventDefault();
        props.onDeleteFile?.();
        setPreviewURL("")
        setFile(undefined);
        props.setUploadProgress(undefined);
    }


    function renderPreview() {
        return (
            <div className="image-preview">
                <Image preview={false} src={previewURL} />
                <div onClick={onDeleteFile} className="delete-icon"><Delete /></div>
            </div>
        );
    }

    return (
        <>
            <div  {...getRootProps({ style })} className={[Styles.FileUploadContainer, props.className].join(" ")} id={props?.id}>
                <input {...getInputProps()} id={`${props?.id}-input`}/>
                {props.preview && previewURL ? renderPreview() : renderUploadPlaceholder(props.placeHolder)}
            </div>
            {!!props.uploadProgress && !isNullOrUndefined(props.uploadProgress) ?
                <UploadProgress
                    className={props.className}
                    fileName={file?.name}
                    uploadProgress={props.uploadProgress}
                    uploadStatus={props.uploadStatus}
                    onCancelUpload={props.onCancelUpload}
                    onDeleteFile={props.onDeleteFile}
                /> : null}
        </>
    );
}

function renderUploadPlaceholder(text: string) {
    return (
        <>
            <UploadFile />
            <div className={["pBody-medium", Styles.UploadFileText].join(" ")}>
                {text}
            </div>
        </>
    );
}

export type TUploadStatus = "success" | "exception" | "active" | "canceled";


interface GUploadProps {
    className?: string;
    accept?: string;
    maxFiles?: number;
    disabled?: boolean;
    onDrop?: (acceptedFiles: Array<File>) => void;
    uploadProgress?: number;
    uploadStatus?: TUploadStatus;
    onCancelUpload?: () => void;
    onDeleteFile?: () => void;
    placeHolder?: string;
    preview?: boolean;
    onChange?: (file?: File) => void;
    setUploadProgress?: (uploadProgress: number) => void;
    id?: string;
    crop?: {
        enabled?: boolean,
        maxWidth?: number,
        maxHeight?: number;
    };
}