import React, { useEffect, useRef } from 'react';

import { _values } from 'common/Utils';
import { addEventListener } from 'consolidated-events';
import contains from 'document.contains';
import useEventCallback from 'use-event-callback';

export function OutsideClickHandler({
    disabled = false,
    useCapture = true,
    onOutsideClick,
    display = DISPLAY.BLOCK,
    style,
    ...props
}: Props) {
    const childNodeRef = useRef(null);
    const removeMouseDown = useRef(null);
    const removeMouseUp = useRef(null);

    const onMouseDown = useEventCallback(e => {
        const isDescendantOfRoot = childNodeRef.current && contains(childNodeRef.current, e.target);
        if (!isDescendantOfRoot) {
            if (removeMouseUp.current) {
                removeMouseUp.current();
                removeMouseUp.current = null;
            }
            removeMouseUp.current = addEventListener(document, 'mouseup', onMouseUp, { capture: useCapture });
        }
    });

    const onMouseUp = useEventCallback(e => {
        const isDescendantOfRoot = childNodeRef.current && contains(childNodeRef.current, e.target);
        if (removeMouseUp.current) {
            removeMouseUp.current();
            removeMouseUp.current = null;
        }
        if (!isDescendantOfRoot) {
            onOutsideClick(e);
        }
    });

    const addMouseDownEventListener = useEventCallback(() => {
        removeMouseDown.current = addEventListener(document, 'mousedown', onMouseDown, { capture: useCapture });
    });

    const removeEventListeners = useEventCallback(() => {
        if (removeMouseDown.current) removeMouseDown.current();
        if (removeMouseUp.current) removeMouseUp.current();
    });

    useEffect(() => {
        if (!disabled) {
            addMouseDownEventListener();
        }

        return () => {
            removeEventListeners();
        };
    }, [disabled, addMouseDownEventListener, removeEventListeners]);

    useEffect(() => {
        return () => {
            removeMouseUp.current?.();
        };
    }, []);

    useEffect(() => {
        if (disabled) {
            removeEventListeners();
        } else {
            addMouseDownEventListener();
        }
    }, [disabled, useCapture, addMouseDownEventListener, removeEventListeners]);

    return (
        <div
            ref={childNodeRef}
            style={display !== DISPLAY.BLOCK && _values(DISPLAY).includes(display) ? Object.assign({ display }, style) : style}
            {...props}
        ></div>
    );
}

const DISPLAY = {
    BLOCK: 'block',
    FLEX: 'flex',
    INLINE: 'inline',
    INLINE_BLOCK: 'inline-block',
    CONTENTS: 'contents',
};

interface Props extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
    onOutsideClick: (e: MouseEvent) => void;
    disabled?: boolean;
    useCapture?: boolean;
    display?: React.CSSProperties['display'];
}

export default OutsideClickHandler;
