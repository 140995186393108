import { setURLQuery } from 'app/store';
import { Dictionary, getServerMessages, getUrlQueryFromEntries } from 'common';
import { Notification } from 'components';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSearchParams } from 'react-router-dom';

export function useOAuthRedirect() {
    const dispatch = useDispatch();

    const [searchParams, setSearchParams] = useSearchParams();
    const { token, source, error, message } = OAuthUrlParamList.reduce(
        (acc: Dictionary<string>, key) => ({ ...acc, [key]: searchParams.get(key) }),
        {}
    );

    function checkTokenInUrlQuery() {
        if (error) {
            return Notification.error({ content: getServerMessages(message), options: { toastId: message } });
        }

        if ((source === 'google' || source === 'microsoft') && token) {
            localStorage.setItem('accessToken', token);

            return true;
        }
    }

    useEffect(() => {
        const status = checkTokenInUrlQuery();
        const params = new URLSearchParams(searchParams);

        if (status) {
            params.delete('token');
            params.delete('source');
        }

        setSearchParams(params, { replace: true });
        dispatch(setURLQuery(getUrlQueryFromEntries(params)));

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
}

const OAuthUrlParamList = ['token', 'source', 'error', 'message'];
