import { Dropdown } from 'antd';
import { getMessages } from 'common';
import { GTooltip } from 'components';
import { Vector20 } from 'components/assets/icons';
import { useState } from 'react';
import IconBackground from '../IconBackground';
import SystemUtilsOverlay from './SystemUtilsOverlay';

export default function SystemUtils() {
    const [open, setOpen] = useState(false);

    return (
        <Dropdown
            trigger={['click']}
            open={open}
            onOpenChange={() => setOpen(!open)}
            dropdownRender={() => <SystemUtilsOverlay />}
            placement="bottomLeft"
        >
            <GTooltip title={getMessages('001268')}>
                <div>
                    <IconBackground icon={<Vector20 />} />
                </div>
            </GTooltip>
        </Dropdown>
    );
}
