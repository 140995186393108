import { ColumnType } from 'antd/lib/table';
import cx from "classnames";
import { useValidationTable } from 'common/hooks';
import { GDataGrid, IDataGridProps } from 'components/GDataGrid';
import GSelectionTable from 'components/GDataGrid/GSelectionTable';
import { IValidationRun } from 'pages/Validation/views/summary/entities';
import React from 'react';
import Styles from "./Styles.module.scss";

export function ValidationTable({ selection, className, extraColumns, excludeColumns, ...props }: IValidationTableProps) {
    let { columns } = useValidationTable(props.dataSource);

    extraColumns?.forEach(col => columns.splice(col.order, 0, col.column));
    if (excludeColumns?.length) {
        columns = columns?.filter(column => !excludeColumns.includes((column as any).dataIndex));
    }

    if (selection) return (
        <GSelectionTable
            columns={columns}
            idIndex="validationId"
            className={className}
            moveSelectedToTop={selection?.moveSelectedToTop ?? false}
            {...Object.assign(selection, props as any)}
        />);


    return (
        <>
            <GDataGrid<IValidationRun>
                columns={columns}
                className={cx(Styles.ValidationTable, className)}
                rowKey="validationId"
                sorting={{ enabled: true }}
                {...props}
            />

        </>
    );
}

export interface IValidationTableProps extends IDataGridProps {
    dataSource: Array<IValidationRun>;
    selection?: IValidationSelection;
    extraColumns?: Array<IValidationTableExtraColumn>;
    excludeColumns?: Array<string>;
}

export interface IValidationTableExtraColumn {
    column: ColumnType<IValidationRun>;
    order?: number;
}

export interface IValidationSelection {
    selectionType: "single" | "multiple";
    selectionList?: string[];
    allSelected?: boolean;
    onChangeSelection?: (id: string, checked: boolean) => void;
    onAllSelected?: (allSelected: boolean) => void;
    selectionElement?: (id?: string) => React.ReactNode;
    moveSelectedToTop?: boolean;
}