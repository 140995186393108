import { getMessages } from 'common';
import { GButton, GModal } from 'components';
import { Info } from 'components/assets/icons';
import Styles from "./Styles.module.scss";
import { useDimensionWarning } from './useDimensionWarning';
import cs from 'classnames';

export function DimensionWarning() {

    const { setSupress, modalProps } = useDimensionWarning();

    return (
        <>
            <GModal {...modalProps} >
                <div className={Styles.ModalContentContainer}>
                    <Info />
                    <div>
                        <div className={cs('subtitle2-bold', 'white', Styles.Title)} >
                            {getMessages("001075")}
                        </div>
                        <div className={cs("pBody-normal white", Styles.Body)}>
                            {getMessages("001076")}<br /> {getMessages("001077")}
                        </div>
                    </div>
                    <GButton
                        type='primary'
                        style={{ width: "100%" }}
                        block
                        onClick={() => setSupress(true)}
                    >
                        {getMessages("000447")}!
                    </GButton>
                </div>
            </GModal>
        </>
    );
}
