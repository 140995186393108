import { APIService } from "common";
import { all, put, takeEvery } from "redux-saga/effects";
import { setCredentialList } from "..";
import PlatformMonitoring from "../../views/PlatformMonitoring/store/saga";
import * as actions from '../action';

export default function* rootSaga() {
    yield all([PlatformMonitoring(), takeEvery(actions.GET_CREDENTIALS_LIST_TRANSACTION, getCredentialsListTransaction)]);
}

export function* getCredentialsListTransaction(): any {
    try {
        const response = yield APIService.Platform.CloudCredentials.call();

        if (response?.status === 200 && response?.data) {
            yield put(setCredentialList(response.data));
        }
    } catch (error) {
        console.log(error);
    }

}
