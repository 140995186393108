import { Select, SelectProps } from 'antd';
import cx from 'classnames';
import { _uniqBy } from 'common/Utils';
import { Dictionary } from 'common/entities';
import { ArrowDown } from 'components/assets/icons';
import React, { useEffect, useRef } from 'react';
import useEventCallback from 'use-event-callback';
import NewItemInput from './NewItemInput';
import Styles from './Styles.module.scss';

const { Option } = Select;

export default function GSelect({
    menuItemList,
    onPopupScroll,
    dropdownRender,
    menuItemRender,
    onChange,
    loadMore,
    loading,
    className,
    popupClassName,
    filterOption,
    addNewItem,
    id,
    idPrefix = '',
    preserve = true,
    ...props
}: IGSelectProps) {
    const dropdownRef = useRef<HTMLDivElement>();
    const list = _uniqBy(menuItemList, 'value');

    const handleOnChange = useEventCallback((value: any, option: any) => {
        onChange?.(value, option);
    });

    const handleFilterOption = useEventCallback((input: string, option: any) =>
        option.children?.toLowerCase?.().includes(input?.toLowerCase?.())
    );

    const handleDropdownRender = useEventCallback((menu: React.ReactElement) => (
        <div
            ref={dropdownRef}
            id="dropdown"
        >
            {menu}
            {loading ? <div className={Styles.Loading}>Loading...</div> : null}
        </div>
    ));

    const onScroll = useEventCallback(() => {
        const lastElement = document.getElementById(list?.[list.length - 1]?.value);

        const containerTop = dropdownRef.current?.getBoundingClientRect().top;
        const lastElementTopPos = lastElement?.getBoundingClientRect().top - containerTop;
        const containerHeight = dropdownRef.current?.getBoundingClientRect().height;

        if (lastElementTopPos - 15 < containerHeight) {
            loadMore?.();
        }
    });

    function renderAddNewItem(menu: React.ReactElement) {
        return (
            <div
                ref={dropdownRef}
                id="dropdown"
            >
                {menu}
                <NewItemInput
                    value={props.value}
                    onChange={onChange}
                />
            </div>
        );
    }

    const Options = list?.map((item, i) => (
        <Option
            disabled={item.disabled}
            value={item.value}
            key={item.value}
            id={`${idPrefix}${item.value}`}
        >
            {menuItemRender?.(item) || item.title}
        </Option>
    ));

    useEffect(() => {
        if (preserve || !props.value) return;
        if (Array.isArray(props.value) && props.value?.some(i => !menuItemList?.some(j => j.value === i))) {
            handleOnChange(props.value?.filter(i => menuItemList?.some(j => j.value === i)) || [], null);
        } else if (!menuItemList?.some(i => i.value === props.value)) {
            handleOnChange(null, null);
        }
    }, [props.value, preserve, menuItemList, handleOnChange]);

    return (
        <div
            className={cx(Styles.SelectContainer, className)}
            id={id}
        >
            <Select
                {...props}
                onChange={handleOnChange}
                popupClassName={cx(Styles.SelectMenu, popupClassName)}
                getPopupContainer={trigger => trigger.parentElement}
                filterOption={filterOption || handleFilterOption}
                dropdownRender={addNewItem ? renderAddNewItem : dropdownRender ?? handleDropdownRender}
                loading={loading}
                onPopupScroll={onPopupScroll ?? onScroll}
                id={id}
                suffixIcon={<ArrowDown />}
            >
                {Options}
            </Select>
        </div>
    );
}

export const GSelectStyle = Styles.SelectContainer;
export const GSelectMenuStyle = Styles.SelectMenu;

export interface IGSelectProps extends Partial<SelectProps<any>> {
    menuItemList?: ISelectItem[];
    menuItemRender?: (item: ISelectItem) => React.ReactNode;
    loadMore?: () => void;
    addNewItem?: boolean;
    preserve?: boolean;
    idPrefix?: string;
}

export interface ISelectItem extends Dictionary {
    value: any;
    title: React.ReactNode;
    disabled?: boolean;
}
