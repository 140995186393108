import localforage from 'localforage';
import { useEffect, useRef, useState } from 'react';
import { useUpdate } from 'react-use';

export class DBStorageHelper<T = any> {
    storage: LocalForage;

    onUpdate: (key: string, value: T) => void;
    onRemove: (key: string) => void;

    constructor({ name, version, description, storeName }: LocalForageOptions) {
        this.storage = localforage.createInstance({
            name,
            version,
            description: description ?? name,
            storeName,
        });
    }

    setItem = async (key: string, value: T) => {
        try {
            await this.storage.setItem(key, value);
            this.onUpdate?.(key, value);
        } catch (error) {
            console.error(error);
        }
    };

    getItem = async (key: string): Promise<T> => {
        try {
            return await this.storage.getItem(key);
        } catch (error) {
            console.error(error);
        }
    };

    removeItem = async (key: string) => {
        try {
            await this.storage.removeItem(key);
            this.onRemove?.(key);
        } catch (error) {
            console.error(error);
        }
    };

    subscribe = (evt: MessageEvent<EventContent<T>>) => {
        switch (evt.data.action) {
            case 'update':
                this.onUpdate?.(evt.data.key, evt.data.value);
                break;
            case 'remove':
                this.onRemove?.(evt.data.key);
                break;

            default:
                break;
        }
    };
}

export function useDBStorage<T = any>(options: LocalForageOptions, itemKey?: string) {
    const storageHelper = useRef(new DBStorageHelper<T>(options));
    const [state, setState] = useState<T>();
    const update = useUpdate();

    useEffect(() => {
        storageHelper.current.onUpdate = onChange;
        storageHelper.current.onRemove = onChange;
    }, []);

    useEffect(() => {
        if (itemKey) {
            storageHelper.current.getItem(itemKey).then(setState);
        }
    }, [itemKey]);

    async function onChange(key: string, value?: T) {
        update();
        if (itemKey && key === itemKey) {
            setState(value);
        }
    }

    return [storageHelper.current, state] as const;
}

interface EventContent<T = any> {
    action: 'update' | 'remove';
    key: string;
    value?: T;
}

export * from './storageList';

