import { Dictionary, ProblemType } from "common";
import { TUploadStatus } from "components/GUpload";

export interface IModelArtifactProps {
    customized: boolean;
    selectedProblemType: ProblemType,
    selectedFramework: string,
    selectedArchitecture?: string;
    downloadArtifactLoading: boolean;
    modelUploadProgress: number;
    modelUploadStatus: TUploadStatus;
    modelPicture: string;
    endpointRegistration: string;
    modelURL: string;
    isS3: boolean;
    credId: string;
    vendorModelUrl: string;
    selectedUploadOption: string;
    bundlePath: string;
    monaiResponse:Dictionary<any>;
    monaiBundleName:string; 
    isModelPathLoading:boolean;
    isAddNewVendorModalOpen: boolean;
}


export function ModelArtifactPropsFactory(data?: Partial<IModelArtifactProps>): IModelArtifactProps {
    return {
        customized: data?.customized || true,
        selectedProblemType: data?.selectedProblemType || null,
        selectedFramework: data?.selectedFramework || null,
        selectedArchitecture: data?.selectedArchitecture || null,
        downloadArtifactLoading: data?.downloadArtifactLoading || false,
        modelUploadProgress: data?.modelUploadProgress || null,
        modelUploadStatus: data?.modelUploadStatus || null,
        modelPicture: data?.modelPicture || null,
        endpointRegistration: data?.endpointRegistration || null,
        modelURL: data?.modelURL || null,
        isS3: data?.isS3 || false,
        credId: data?.credId || null,
        vendorModelUrl: data?.vendorModelUrl || null,
        selectedUploadOption: data?.selectedUploadOption || "upload",
        bundlePath: data?.bundlePath || null,
        monaiResponse: data?.monaiResponse || null,
        monaiBundleName: data?.monaiBundleName || null,
        isModelPathLoading: data?.isModelPathLoading || false,
        isAddNewVendorModalOpen: data?.isAddNewVendorModalOpen || false,
        };
}

export interface IModelArtifactItem {
    key: string,
    title: string;
}
