import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { ConfiguredServices, ProblemType, _entries, _values, getServerMessages, isNullOrUndefined } from 'common';
import { Notification } from 'components';
import { IModel } from 'pages/Models/entities';
import { IPredictionResponse } from 'pages/Playground/entities';
import { getAugmentationProperties } from 'pages/Playground/entities/IAugmentation';
import {
    ISinglePredictionRequestPayload,
    getClassControls,
    getClassificationResultFromResponse,
    getObjectDetectionResultFromResponse,
    getSegmentationResultFromResponse,
} from 'pages/Playground/entities/ISinglePrediction';
import { call, put, select } from 'redux-saga/effects';
import { RootState } from 'redux/store';
import {
    setExplainLoading,
    setExplanationResult,
    setPredictionEmpty,
    setPredictionLoading,
    setPredictionRawResult,
    setPredictionResult,
    updatePredictionControls,
} from '..';
import {
    selectAugmentationProperties,
    selectPredictionControls,
    selectRawWindowLevel,
    selectSelectedSlice,
    transformWindowLevel,
} from '../selectors';

export function* startSinglePredictionSaga(
    sagaAction: PayloadAction<{ dataset_id: string; model: IModel; image_id: string }>
): any {
    try {
        yield put(setPredictionLoading(true));

        const { payload, model, augmentationProperties, predictionControls } = yield getPredictionPayload(sagaAction);

        if (!predictionControls.imageControls.useRawImage) {
            const { imageControls, userAugmentation } = getAugmentationProperties(predictionControls, augmentationProperties);

            payload.user_transformations = {
                basic: {
                    imageControls: _entries(imageControls).map(([name, args]) => ({ name, args, probability: 1 })),
                    userAugmentation: _entries(userAugmentation).map(([name, args]) => ({ name, args, probability: 1 })),
                },
                advance: {},
            };
        }
        const { predictionResult, rawResult } = yield call(getSinglePredictionResult, payload, model);

        if (rawResult) {
            yield put(setPredictionRawResult(rawResult));
        }

        if (predictionResult) {
            yield put(setPredictionResult(predictionResult));

            if (model?.problemType !== ProblemType.classification) {
                let classControls = getClassControls(model?.classMappings);
                yield put(updatePredictionControls({ classControls, showResultPicture: true }));
            }
        } else {
            yield put(setPredictionEmpty(true));
        }
    } catch (err) {
        console.error(err);
        Notification.error({ content: getServerMessages('$01003') });
        yield put(setPredictionEmpty(true));
    } finally {
        yield put(setPredictionLoading(false));
    }
}

export function* startExplainPredictionSaga(
    sagaAction: PayloadAction<{ dataset_id: string; model: IModel; image_id: string }>
): any {
    try {
        yield put(setExplainLoading(true));

        const { payload, model, augmentationProperties, predictionControls } = yield getPredictionPayload(sagaAction);

        payload.explain_params = { explanation_method: 'GradCam' };

        if (!predictionControls.imageControls.useRawImage) {
            const { imageControls, userAugmentation } = getAugmentationProperties(predictionControls, augmentationProperties);
            payload.user_transformations = {
                basic: {
                    imageControls: _entries(imageControls).map(([name, args]) => ({ name, args, probability: 1 })),
                    userAugmentation: _entries(userAugmentation).map(([name, args]) => ({ name, args, probability: 1 })),
                },
                advance: {},
            };
        }

        const explainResult = yield ConfiguredServices.PredictionService(model?.deploymentId).call(
            getSinglePredictionRequestPayload(payload),
            { responseType: 'blob' }
        );

        let explainImageURL = explainResult ? window.URL.createObjectURL(explainResult?.data) : null;

        if (explainImageURL && explainResult?.data?.type?.includes('image')) {
            yield put(setExplanationResult(explainImageURL));
        }
    } catch (err) {
        console.error(err);
        Notification.error({ content: getServerMessages('$01003') });
        yield put(setExplanationResult(null));
    } finally {
        yield put(setExplainLoading(false));
    }
}

export function* getPredictionPayload(sagaAction: PayloadAction<{ dataset_id: string; model: IModel; image_id: string }>) {
    try {
        const { image_id, dataset_id, model } = sagaAction.payload;

        const rootState: RootState = yield select();
        const predictionControls = selectPredictionControls(rootState);
        const augmentationProperties = selectAugmentationProperties(rootState);
        const selectedSlice = selectSelectedSlice(rootState);
        const windowing = transformWindowLevel(selectRawWindowLevel(rootState));

        const payload: Partial<ISinglePredictionRequestPayload> = {
            image_id: image_id,
            dataset_id: dataset_id,
            slice: selectedSlice,
        };

        if (windowing && _values(windowing).every(item => !isNullOrUndefined(item))) {
            payload.windowing = windowing;
        }
        return { payload, model, augmentationProperties, predictionControls };
    } catch (error) {
        console.error(error);
    }
}

export async function getSinglePredictionResult(payload: Partial<ISinglePredictionRequestPayload>, model: IModel) {
    try {
        payload = getSinglePredictionRequestPayload(payload);
        const response: AxiosResponse<IPredictionResponse> = await ConfiguredServices.PredictionService(model?.deploymentId).post(
            payload
        );
        if (response?.data?.response) {
            const predictionResult = getPredictionResultData(response.data, model);
            return { predictionResult, rawResult: response?.data?.response };
        }
    } catch (error) {
        console.error(error);
    }

    return { predictionResult: null, rawResult: null };
}

export function getSinglePredictionRequestPayload(payload: Partial<ISinglePredictionRequestPayload>) {
    payload = Object.assign({}, payload, { image_type: 'id', threshold: 0, time_frame: -1 });
    if (!payload.user_transformations) {
        payload.user_transformations = { basic: { userAugmentation: [], imageControls: [] }, advance: {} };
    }
    return payload;
}

function getPredictionResultData(payload: IPredictionResponse, model: IModel) {
    try {
        switch (model?.problemType) {
            case ProblemType.classification:
                return getClassificationResultFromResponse(payload, model);

            case ProblemType.segmentation:
            case ProblemType.instance_segmentation: {
                return getSegmentationResultFromResponse(payload, model);
            }

            case ProblemType.object_detection: {
                return getObjectDetectionResultFromResponse(payload, model);
            }
            default:
                break;
        }
    } catch (error) {
        console.error(error);
    }
}
