import { PayloadAction } from "@reduxjs/toolkit";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { downloadFileFromAxios, getErrorMessage, getMessages, isNullOrUndefined, PlatformService, uploadFile } from 'common';
import { Notification } from 'components';
import { TUploadStatus } from 'components/GUpload';
import { IModelArtifactProps, UploadModelActionPayload } from 'pages/Models/views/ModelUpload/entities';
import { EventChannel } from 'redux-saga';
import { call, put, select, take } from 'redux-saga/effects';
import { RootState } from 'redux/store';
import { updateModelArtifactProps, updateModelFiles } from '..';
import { selectModelArtifactProps } from '../selectors';
import { srcMap } from './submitModelForm';

export function* downloadArtifactsSaga(): any {
    try {
        yield put(updateModelArtifactProps({ downloadArtifactLoading: true }));

        const response: AxiosResponse = yield PlatformService.Manage.GetModelArtifact.call(null, { responseType: 'blob' });

        if (response?.data) {
            downloadFileFromAxios(response, 'model_artifacts.zip');

            Notification.success({ content: getMessages('000351') });
        }
    } catch (error) {
        console.log(error);

        Notification.error({ content: getMessages('000352') });
    } finally {
        yield put(updateModelArtifactProps({ downloadArtifactLoading: false }));
    }
}

export function* uploadModelFilesSaga(sagaAction: PayloadAction<UploadModelActionPayload>): any {
    try {
        const { file, signal,is_training } = sagaAction.payload;

        const modelArtifactProps: IModelArtifactProps = yield select(selectModelArtifactProps);
        let params = {
            framework: modelArtifactProps?.selectedFramework?.toLocaleLowerCase(),
            problem_type: modelArtifactProps?.selectedProblemType,
            model_url:
                modelArtifactProps?.selectedUploadOption === 'externalFramework'
                    ? modelArtifactProps?.monaiBundleName
                    : modelArtifactProps?.modelURL,
            cred_id: modelArtifactProps?.credId,
            src: srcMap[modelArtifactProps?.selectedUploadOption],
            is_training,
        };

        const formData = new FormData();
        const blob = new Blob([''], { type: "text/xml" });

        formData.append("zip_file", file ? file : blob);

        const axiosConfig: AxiosRequestConfig = { params, signal, };

        yield put(updateModelArtifactProps({ modelUploadStatus: "active" }));
        const eventChannel: EventChannel<any> = yield call(uploadFile, PlatformService.Manage.UploadModel, formData, axiosConfig);

        while (true) {
            let event: any = yield take(eventChannel);

            switch (event?.type) {
                case "progressEvent":
                    const progressEvent: ProgressEvent = event.data;
                    const totalLength = progressEvent.total;
                    if (!isNullOrUndefined(totalLength)) {
                        const modelUploadProgress = Math.round((progressEvent.loaded / totalLength) * 100);
                        yield put(updateModelArtifactProps({ modelUploadProgress }));
                    }
                    break;

                case "response": {
                    const axiosResponse: AxiosResponse = event?.data;
                    if (axiosResponse?.data) {
                        const payload = {
                            model_path: axiosResponse.data.model_zip_path,
                            model_id: axiosResponse.data.model_id,
                            dockerhub: false
                        };
                        yield put(updateModelFiles(payload));
                        yield put(updateModelArtifactProps({ modelUploadStatus: "success" }));
                        Notification.success({ content: getMessages("000353") });
                    }
                    break;
                }

                case "error": {
                    const modelUploadStatus: TUploadStatus = yield select((state: RootState) => state.models.modelUpladState.modelArtifactProps.modelUploadStatus);
                    if (modelUploadStatus !== "canceled") {
                        yield put(updateModelArtifactProps({ modelUploadStatus: "exception" }));
                        Notification.error({ content: getErrorMessage(event?.data) });
                    }
                    break;
                }

                default:
                    break;
            }
        }
    } catch (error) {
        console.log(error);

        yield put(updateModelArtifactProps({ modelUploadStatus: "exception" }));

    }
} 
