import * as am5 from '@amcharts/amcharts5';
import { _uniqBy } from 'common/Utils';
import { Dictionary } from 'common/entities';
import { ChartHelper } from 'common/helpers';
import { useEffect, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { IBaseChartProps } from '../entities';
import { IChartOptions, createChart } from './createChart';

export default function MatrixChart({ chartID, data, chartOptions, className, height, width }: IBarChartProps) {
    const chartHelperRef = useRef<ChartHelper.XYChart>();

    function setData() {
        chartHelperRef.current.series?.[0]?.data.setAll(data);

        chartHelperRef.current.xAxis?.data.setAll(_uniqBy(data, chartOptions.xAxis.categoryField));
        chartHelperRef.current.yAxis?.data.setAll(_uniqBy(data, chartOptions.yAxis.categoryField));
    }

    useEffect(() => {
        if (data && chartHelperRef.current) {
            setData();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(chartID, chartOptions);

            if (data) {
                setData();
            }
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        chartHelperRef.current?.root?.dispose();
        chartHelperRef.current = createChart(chartID, chartOptions);

        if (data) {
            setData();
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartOptions?.lightTheme]);

    useUpdateEffect(() => {
        (chartHelperRef.current?.xAxis as any)
            .get('renderer')
            .labels.template.set('fill', chartOptions?.lightTheme ? am5.color('#000') : am5.color('#fff'));
        (chartHelperRef.current?.yAxis as any)
            .get('renderer')
            .labels.template.set('fill', chartOptions?.lightTheme ? am5.color('#000') : am5.color('#fff'));
    }, [chartOptions?.lightTheme]);

    return (
        <>
            <div
                className={className}
                style={{ height: height, width: width }}
                id={chartID}
            />
        </>
    );
}

export interface IBarChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    chartOptions?: IChartOptions;
}
