export class StorageHelper<T = any> extends Map<string, T> {
    constructor(public key: string) {
        super();
    }

    private listeners: Map<string, (key: string, value: T) => void> = new Map();

    onChange(key: string, value: T) {
        this.listeners.forEach(cb => cb(key, value));
    }

    subscribe(key: string, callback: (key: string, value: T) => void) {
        this.listeners.set(key, callback);
    }

    unSubscribe(key: string) {
        this.listeners.delete(key);
    }

    set(key: string, value: T) {
        super.set(key, value);
        this.onChange(key, value);
        return this;
    }

    clear(): void {
        super.clear();
        this.onChange(null, null);
    }

    delete(key: string) {
        const result = super.delete(key);
        this.onChange(key, null);
        return result;
    }
}
