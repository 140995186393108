import constate from 'constate';
import { useCallback, useState } from 'react';
import { ImageViewerHelper } from '..';

export function useImageViewerHelper() {
    const [imageViewer, setImageViewer] = useState<ImageViewerHelper>();

    const initImageViewer = useCallback(() => {
        const _imageViewer = new ImageViewerHelper();
        _imageViewer.init().then(() => setImageViewer(_imageViewer));
    }, []);

    return { initImageViewer, imageViewer };
}

export const [ImageViewerInstanceProvider, useImageViewer, useInitImageViewer] = constate(
    useImageViewerHelper,
    value => value.imageViewer,
    value => value.initImageViewer
);
