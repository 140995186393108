import { createSlice } from '@reduxjs/toolkit';
import { ValidationStateFactory } from '../entities';
import { ExternalModelValidationReducer } from '../views/externalValidation/store';
import { startValidationReducer } from '../views/startValidation/store';
import { ValidationSummaryReducer } from '../views/summary/store';
import { BlindSpotAnalysisReducer } from '../views/validationAnalysis/components/blindSpotAnalysis/store';
import { comparisonReducer } from '../views/validationAnalysis/components/comparison/store';
import { MetricsReducer } from '../views/validationAnalysis/components/metrics/store';
import { ExportValidationSummaryReducer } from '../views/validationAnalysis/components/newSummary/store';
import { exportedMetricsReducer } from './../views/exportedMetrics/store/index';

export const validationSlice = createSlice({
    name: 'validation',
    initialState: ValidationStateFactory(),
    reducers: {
        ...comparisonReducer,
        ...MetricsReducer,
        ...startValidationReducer,
        ...BlindSpotAnalysisReducer,
        ...ValidationSummaryReducer,
        ...ExportValidationSummaryReducer,
        ...ExternalModelValidationReducer,
        ...exportedMetricsReducer,
    },
});

export const {
    setMetricsDataToInitial,
    setMetricsChartData,
    setMetricsChartGraphParams,
    setMetricsChartDefaultParams,
    setMetricsImagePreviewControls,
    setMetricsLayoutState,
    setMetricsExtraData,
    setComparisonData,
    setSelectionModalVisible,
    setComparisonExtraData,
    setComparisonDefaultParams,
    setComparisonGraphParams,
    setComparisonLayoutState,
    setImageMap,
    setTotalSize,
    setImageControls,
    setAugmentationProperties,
    setSelectedImage,
    setProbability,
    updateProbability,
    updateValidationParams,
    updateComparisonControlState,
    setMetadataFiltersModal,
    setMetadataFilters,
    updateValCreatedCohortList,
    updateMetricsCommonState,
    updateBlindSpotAnalysisState,
    setIsEditModalOpen,
    setPlatformSummaryData,
    setSummaryOverlayStatus,
    setValidationRunData,
    updateValidationItem,
    updateValidationMetricsViewState,
    updateHidedChartList,
    removeBlindSpotAnalysis,
    updateValidationSummaryCommonState,
    updateSelectedColumns,
    updateResultModalState,
    setMetricsChartDefaultData,
    updateCommonState,
    setSelectedValidationMetric,
    setBlandAtmanResponse,
    updateExportedMetricsState,
    setSelectedValidations,
    updateBlindSpotTrackingList,
} = validationSlice.actions;

export default validationSlice.reducer;

export * from './ValidationService';
