import { PayloadAction } from "@reduxjs/toolkit";
import { Dictionary, getObjectKeyByValue, isNullOrUndefined, PlatformService, _mapValues } from "common";
import { setMetricsChartData, setMetricsLayoutState, updateMetricsCommonState } from "pages/Validation/store";
import { call, fork, put, select } from "redux-saga/effects";
import { getBasicModelStats, getClassPerformanceData, getDetailedMetricsData, getHighlightedMetricsData, getPerformanceByConfidenceData, IValidationMetricsDataTransactionPaylaod, MetricsDataType, setExplorePredictionsData, setPlotTopLossesItem, setPlotTopMissesItem } from "../../entities";
import { ChartKey, ILayoutState } from "../../entities/Charts/IChartState";
import { setMostConfusedImagesData } from "../../entities/Charts/IMostConfusedImages";
import { selectGraphParams } from "../selectors";

export function* getValidationMetricsDataSaga(sagaAction: PayloadAction<IValidationMetricsDataTransactionPaylaod>): any {
    let { dataType, validationId } = sagaAction.payload;
    const chartName = getObjectKeyByValue(MetricsDataType, dataType) as ChartKey;

    try {
        if (!validationId) return;

        yield fork(setLayoutStateSaga, chartName, { isLoading: true });
        let isValidParams = true;

        const graph_params = yield select(selectGraphParams(chartName));
        const requiredParams = RequiredGraphParams[dataType] || [];
        requiredParams?.forEach(param => {
            if (isNullOrUndefined(graph_params?.[param])) {
                isValidParams = false;
            }
        });

        const requestPayload: IChartDataRequestPayload = {
            graph_id: dataType,
            graph_params: graph_params || {},
            validation_id: validationId,
        };

        if (isValidParams) {
            const chartData = yield call(getChartData, requestPayload);

            if (!chartData) return yield fork(setLayoutStateSaga, chartName, { isEmpty: true });

            yield put(setMetricsChartData({ [chartName]: chartData }));
        }

        yield fork(setLayoutStateSaga, chartName, { isLoading: false });

    } catch (err) {
        console.log(err);
        yield fork(setLayoutStateSaga, chartName, { isEmpty: true });
    } finally {
        yield put(updateMetricsCommonState({ pageLoading: false }));
    }
}

export function* getChartData(requestPayload: IChartDataRequestPayload): any {
    try {
        const response: { data: any; } = yield call(callValidationMetricsRequest, requestPayload);

        if (!response?.data) return null;

        const payloadData = response.data?.payload_dict?.data;

        return GetChartDataFromResponse[requestPayload?.graph_id]?.(payloadData) ?? payloadData;
    } catch (error) {
        console.error(error);
    }
}

export const GetChartDataFromResponse: Partial<Record<MetricsDataType, (response: any) => any>> = {
    [MetricsDataType.highlightedMetrics]: getHighlightedMetricsData,
    [MetricsDataType.basicModelStats]: getBasicModelStats,
    [MetricsDataType.plotTopLosses]: setPlotTopLossesItem,
    [MetricsDataType.performanceByConfidence]: getPerformanceByConfidenceData,
    [MetricsDataType.mostConfusedImages]: setMostConfusedImagesData,
    [MetricsDataType.classPerformance]: getClassPerformanceData,
    [MetricsDataType.explorePredictions]: setExplorePredictionsData,
    [MetricsDataType.plotTopMisses]: setPlotTopMissesItem,
    [MetricsDataType.detailedMetrics]: getDetailedMetricsData,
};

const RequiredGraphParams: Partial<Record<MetricsDataType, Array<string>>> = {
    [MetricsDataType.plotTopLosses]: ["predicted_class"],
    [MetricsDataType.performanceByConfidence]: ["predicted_class", "threshold"],
    [MetricsDataType.mostConfusedImages]: ["predicted_class", "true_class"],
    [MetricsDataType.confidenceDistribution]: ["predicted_class"],
};

function* callValidationMetricsRequest(requestPayload: IChartDataRequestPayload): any {
    try {
        return yield PlatformService.Validation.Plot.call({ ...requestPayload });
    } catch (error) {
        console.log(error);
    }
}

export function* setLayoutStateSaga(key: (ChartKey) | "setAll", layout: Partial<ILayoutState>): any {
    try {
        if (key === "setAll") {
            const payload = _mapValues(MetricsDataType, (() => layout));

            yield put(setMetricsLayoutState(payload));

        } else {
            yield put(setMetricsLayoutState({ [key]: layout }));
        }

    } catch (error) {
        console.log(error);
    }
}

export interface IChartDataRequestPayload {
    graph_id: MetricsDataType,
    graph_params: Dictionary<any>,
    validation_id: string;
}
