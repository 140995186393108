import { Theme } from '@nivo/core';

export const nivoTheme: Theme = {
    background: '#161b24',
    textColor: '#333333',
    fontSize: 11,
    axis: {
        domain: {
            line: {
                stroke: '#666c75',
                strokeWidth: 1,
            },
        },
        legend: {
            text: {
                fontSize: 14,
                fill: '#ffffff',
            },
        },
        ticks: {
            line: {
                stroke: '#666c75',
                strokeWidth: 1,
            },
            text: {
                fontSize: 12,
                fill: '#ffffff',
            },
        },
    },
    grid: {
        line: {
            stroke: '#666c75',
            strokeWidth: 1,
        },
    },
    legends: {
        title: {
            text: {
                fontSize: 10,
                fill: '#ffffff',
            },
        },
        text: {
            fontSize: 12,
            fill: '#ffffff',
        },
        ticks: {
            line: {},
            text: {
                fontSize: 10,
                fill: '#333333',
            },
        },
    },
    annotations: {
        text: {
            fontSize: 13,
            fill: '#333333',
            outlineWidth: 2,
            outlineColor: '#ffffff',
            outlineOpacity: 1,
        },
        link: {
            stroke: '#000000',
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: '#ffffff',
            outlineOpacity: 1,
        },
        outline: {
            stroke: '#000000',
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: '#ffffff',
            outlineOpacity: 1,
        },
        symbol: {
            fill: '#000000',
            outlineWidth: 2,
            outlineColor: '#ffffff',
            outlineOpacity: 1,
        },
    },
    tooltip: {
        container: {
            background: '#ffffff',
            color: '#333333',
            fontSize: 14,
        },
        basic: {},
        chip: {},
        table: {},
        tableCell: {},
        tableCellValue: {},
    },
};

export const nivoThemeLight: Theme = {
    background: '#fff',
    textColor: '#000',
    fontSize: 11,
    axis: {
        domain: {
            line: {
                stroke: '#666c75',
                strokeWidth: 1,
            },
        },
        legend: {
            text: {
                fontSize: 14,
                fill: '#000',
            },
        },
        ticks: {
            line: {
                stroke: '#666c75',
                strokeWidth: 1,
            },
            text: {
                fontSize: 12,
                fill: '#000',
            },
        },
    },
    grid: {
        line: {
            stroke: '#666c75',
            strokeWidth: 1,
        },
    },
    legends: {
        title: {
            text: {
                fontSize: 10,
                fill: '#000',
            },
        },
        text: {
            fontSize: 12,
            fill: '#000',
        },
        ticks: {
            line: {},
            text: {
                fontSize: 10,
                fill: '#000',
            },
        },
    },
    annotations: {
        text: {
            fontSize: 13,
            fill: '#000',
            outlineWidth: 2,
            outlineColor: '#000',
            outlineOpacity: 1,
        },
        link: {
            stroke: '#000000',
            strokeWidth: 1,
            outlineWidth: 2,
            outlineColor: '#000',
            outlineOpacity: 1,
        },
        outline: {
            stroke: '#000000',
            strokeWidth: 2,
            outlineWidth: 2,
            outlineColor: '#000',
            outlineOpacity: 1,
        },
        symbol: {
            fill: '#000000',
            outlineWidth: 2,
            outlineColor: '#000',
            outlineOpacity: 1,
        },
    },
    tooltip: {
        container: {
            background: '#000',
            color: '#000',
            fontSize: 14,
        },
        basic: {},
        chip: {},
        table: {},
        tableCell: {},
        tableCellValue: {},
    },
};

export const colorPalette = [
    '#00FFA7',
    '#24D9D9',
    '#A4BF00',
    '#FFB800',
    '#FF8A00',
    '#EE5843',
    '#F9748C',
    '#FFACBB',
    '#DB78D1',
    '#E7A5FF',
    '#8819CC',
    '#6819CC',
    '#5A00FF',
    '#4968D4',
    '#0085FF',
    '#6597C6',
];
