import { IImage } from 'common';

export interface IViewportState {
    seriesId?: string;
    imageId?: string;
    loading?: boolean;
}

export function ViewportStateFactory(): IViewportState {
    return {
        seriesId: undefined,
        imageId: undefined,
        loading: false,
    };
}

export interface IViewportDetails extends IViewportState {
    imageList: IImage[];
    viewportIndex: number;
}
