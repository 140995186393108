import { Dictionary } from 'common/entities';

export function downloadJSON(data: Dictionary, fileName: string = 'download') {
    if (!data) return;
    const link = document.createElement('a');
    const href = 'data:text/json;charset=utf-8,' + encodeURIComponent(JSON.stringify(data));

    link.setAttribute('download', fileName + '.json');
    link.setAttribute('style', 'display: none');
    link.setAttribute('href', href);

    document.body.appendChild(link);

    link.click();

    document.body.removeChild(link);
}
