import MatomoTracker from '@datapunt/matomo-tracker-js';
import { NODE_ENV } from 'common/config';

export class Matomo extends MatomoTracker {
    constructor(urlBase: string, siteId: number) {
        super({
            // urlBase,
            urlBase: _urlBase,
            siteId,
            // siteId: 2,
            // userId: 'UID76903202', // optional, default value: `undefined`.
            trackerUrl: `${_urlBase}analytics.php`, // optional, default value: `${urlBase}matomo.php`
            srcUrl: `${_urlBase}analytics.js`, // optional, default value: `${urlBase}matomo.js`
            disabled: NODE_ENV === 'development', // optional, false by default. Makes all tracking calls no-ops if set to true.
            heartBeat: {
                // optional, enabled by default
                active: true, // optional, default value: true
                seconds: 10, // optional, default value: `15
            },
            linkTracking: false, // optional, default value: true
            configurations: {
                // optional, default value: {}
                // any valid matomo configuration, all below are optional
                disableCookies: true,
                setSecureCookie: true,
                setRequestMethod: 'POST',
            },
        });

        this.pushInstruction('enableJSErrorTracking', true);

        var maxTimeInSeconds = 60 * 30;
        this.pushInstruction('HeatmapSessionRecording::setMaxCaptureTime', maxTimeInSeconds);

        this.setUser = this.setUser.bind(this);
    }

    public async setUser(email: string) {
        this?.pushInstruction?.('setUserId', email);
    }

    public onPageChange(): void {
        super.trackPageView?.();
        this.pushInstruction('setCustomUrl', window.location.href);
        this.pushInstruction('setDocumentTitle', window.document.title);
    }
}

const _urlBase = window.location.origin + '/analytics/';
