import GFallback from 'components/GFallback';
import { Suspense, lazy } from 'react';
import type { IVolinChartProps } from './ViolinChart';

const ViolinChart = lazy(() => import(/* webpackChunkName: 'Violin_Chart' */ './ViolinChart'));

export default function ViolinChartLazy(props: IVolinChartProps) {
    return (
        <Suspense fallback={<GFallback />}>
            <ViolinChart {...props} />
        </Suspense>
    );
}
