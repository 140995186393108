import { Dictionary, _entries, isNullOrUndefined, _omit } from "common";
import { IImageControls, IPredictionControls } from ".";

export interface IAugmentationProperties {
    brightness: IAugmentationPropertyItem;
    contrast: IAugmentationPropertyItem;
    blur: IAugmentationPropertyItem;
    saturation: IAugmentationPropertyItem;
    sharpen: IAugmentationPropertyItem;
    randomNoise: IAugmentationPropertyItem;
    pixelate: IAugmentationPropertyItem;
    opacity: IAugmentationPropertyItem;
    invert: IAugmentationPropertyItem;
    emboss: IAugmentationPropertyItem;
    removeColor: IAugmentationPropertyItem<any>;
}
export interface IAugmentationPropertyItem<T = any> {
    controlType?: Array<ControlType>;
    enabled: boolean;
    value?: T;
}

export function AugmentationPropertiesFactory(data?: Partial<IAugmentationProperties>): IAugmentationProperties {
    return {
        brightness: AugmentationPropertyItemFactory(data?.brightness),
        contrast: AugmentationPropertyItemFactory(data?.contrast),
        blur: AugmentationPropertyItemFactory(data?.blur),
        saturation: AugmentationPropertyItemFactory(data?.saturation),
        sharpen: BooleanAugmentationItemFactory(data?.sharpen),
        randomNoise: AugmentationPropertyItemFactory(data?.randomNoise),
        pixelate: AugmentationPropertyItemFactory(data?.pixelate),
        opacity: AugmentationPropertyItemFactory(data?.opacity),
        invert: BooleanAugmentationItemFactory(data?.invert),
        emboss: BooleanAugmentationItemFactory(data?.emboss),
        removeColor: AugmentationPropertyItemFactory(data?.removeColor),
    };
}
function AugmentationPropertyItemFactory(data?: Partial<IAugmentationPropertyItem>): IAugmentationPropertyItem {
    return {
        value: data?.value || null,
        enabled: data?.enabled || true,
        controlType: data?.controlType || ["slider"]
    };
}

function BooleanAugmentationItemFactory(data?: Partial<IAugmentationPropertyItem>): IAugmentationPropertyItem {
    return {
        enabled: data?.enabled || false,
        controlType: data?.controlType || ["checkbox"]
    };
}

export type ControlType = "slider" | "checkbox" | "colorPicker";

export function AugmentationIsTouched(data?: IAugmentationProperties): boolean {
    if (!data) return false;
    Object.values(data).some((item) => item.value);
};

export interface IUserTransformationParams {
    basic?: IBasicTransformationParams;
    advance?: Dictionary;
}
export interface IBasicTransformationParams {
    userAugmentation?: Array<ITransformField>;
    imageControls?: Array<ITransformField>;
}

export interface ITransformField {
    name?: string;
    args?: any;
    probability?: number;
}

export function getAugmentationProperties(predictionControls: IPredictionControls, augmentationProperties: IAugmentationProperties) {
    let imageControls: Partial<IImageControls> = _omit(predictionControls.imageControls, ["useRawImage"]);

    imageControls = _entries(imageControls).reduce((result, [key, value]) => {

        if (value) {
            (result as any)[key] = value;
        }

        return result;
    }, {});

    let userAugmentation = _entries(augmentationProperties).reduce((result, [key, props]: [keyof IAugmentationProperties, IAugmentationPropertyItem]) => {
        if (props.enabled) {
            if (!isNullOrUndefined(props.value) && props.value !== 0) {
                (result as any)[key] = props.value;
            } else if (props.controlType?.includes("checkbox")) {
                (result as any)[key] = props.enabled;
            }
        }
        return result;
    }, {});

    return { imageControls, userAugmentation };
}