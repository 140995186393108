import cx from 'classnames';
import { _range } from 'common/Utils';
import { OutsideClickHandler } from 'common/components';
import { memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './Styles.module.scss';
import Viewport from './Viewport';
import { IGDicomViwerProps } from './entities/interfaces';
import { selectGridView, selectIsViewportFocused, updateIsViewportFocused } from './store';

export const GDicomViewer = memo(function GDicomViewer({ className, toolList, ...props }: IGDicomViwerProps) {
    const dispatch = useDispatch();
    const isViewportFocused = useSelector(selectIsViewportFocused);
    const gridView = useSelector(selectGridView);
    const { columns, rows } = gridView || { columns: 1, rows: 1 };

    function handleViewportFocus() {
        if (isViewportFocused) return;
        dispatch(updateIsViewportFocused(true));
    }

    function onClickOutside() {
        if (!isViewportFocused) return;
        dispatch(updateIsViewportFocused(false));
    }

    return (
        <>
            <OutsideClickHandler
                onOutsideClick={onClickOutside}
                className={cx(Styles.GDicomViewer, className)}
                style={{
                    gridTemplateColumns: `repeat(${columns}, minMax(0, 1fr))`,
                    gridTemplateRows: `repeat(${rows}, minMax(0, 1fr))`,
                }}
                onClick={handleViewportFocus}
            >
                {_range(columns * rows).map(i => (
                    <Viewport
                        toolList={toolList}
                        key={i}
                        index={i}
                        {...props}
                    />
                ))}
            </OutsideClickHandler>
        </>
    );
});

export * from './entities/interfaces';
export * from './hooks';

