import { HTMLAttributeAnchorTarget } from "react";
import { Link } from "react-router-dom";
import Styles from "./Styles.module.scss";

export function GTableCell({ caption, prefix, summary, link, target }: IGTableCellProps) {

    const cell = (
        <div className={Styles.BaseCell}>
            {caption}
            <div className={Styles.CellSummary}>{summary}</div>
        </div>
    );

    if (link) return (
        <Link to={link} target={target}>
            {prefix}
            {cell}
        </Link>
    );

    return (
        <>
            {prefix}
            {cell}
        </>
    );
}

export interface IGTableCellProps {
    caption: React.ReactNode;
    summary?: React.ReactNode;
    prefix?: React.ReactNode;
    link?: string;
    target?: HTMLAttributeAnchorTarget;
}