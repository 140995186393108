import { useAddActivityLogMutation } from 'pages/User/store/UserSlice';
import { useEffect } from 'react';

export default function useAddActivityLog({ type, itemId, action = 'view' }: { type: string; itemId: string; action?: string }) {
    const [addActivityLog] = useAddActivityLogMutation();
    // const dispatch = useDispatch();
    useEffect(() => {
        if (itemId) {
            addActivityLog({ type, action, item_id: itemId });
        }
        // if (type === 'dataset') {  **Find another way to do this**
        //     dispatch(getDatasetListTransaction());
        // }
        // if (type === 'model') {
        //     dispatch(getModelListTransaction());
        // }
    }, [itemId]);
}
