import { setMetricsImagePreviewControls } from "pages/Validation/store";
import { put, select } from "redux-saga/effects";
import { RootState } from "redux/store";

export function* changePreviewImageSaga(sagaAction: any): any {

    try {
        const state: RootState = yield select();
        const imagePreviewControl = state.validation.metrics.imagePreviewControls;
        const selectedData = state.validation.metrics.validationMetrics[imagePreviewControl.activeSection]?.data
        let prevImageIndex = selectedData.map(item => item.imageId).indexOf(imagePreviewControl.selectedImageId) + sagaAction.payload;

        if (prevImageIndex < 0) {
            prevImageIndex = selectedData.length - 1;
        }
        if (prevImageIndex === selectedData.length) {
            prevImageIndex = 0;
        }
        yield put(setMetricsImagePreviewControls({ selectedImageId: selectedData[prevImageIndex].imageId }));
    } catch (error) {

    }
}