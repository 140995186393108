import { PayloadAction } from "@reduxjs/toolkit";
import { APIService, getMessages, PostMethod, ServiceEnum } from "common";
import { Notification } from "components";
import { call } from "redux-saga/effects";
import { getModelListTransactionSaga } from "./modelListSaga";
import { getValidationListDataSaga } from 'pages/Validation/views/summary/store/saga/ValidationDataSaga';

export function* DeleteModelSaga(sagaAction: PayloadAction<{ model_id: string; }>): any {
    try {
        const DeleteService = new PostMethod(`model/db/delete/${sagaAction.payload.model_id}`, null, ServiceEnum.Management);
        const response = yield DeleteService.call();
        if (response.data) {
            const res = yield APIService.Validation.DeleteValidationRunsByModelId.call({ model_id: sagaAction.payload.model_id });
            yield call(getValidationListDataSaga);
            yield call(getModelListTransactionSaga);

            Notification.success({ content: getMessages("000451") });

            return res
        }
    } catch (error) {
        console.log(error);

    }
}