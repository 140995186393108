import { GFallback } from 'components';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { selectIsAppInit } from 'redux/selectors';
import CombineProviders from './CombineProviders';
import { useRouteBasedSubscribeEvents } from './hooks';
import { useSubscribeEvents } from './hooks/useSubscribeEvents';

export default function NavigationProvider() {
    const isAppInit = useSelector(selectIsAppInit);

    useRouteBasedSubscribeEvents();
    useSubscribeEvents();

    if (!isAppInit) return <GFallback />;

    return (
        <>
            <Outlet />
            <CombineProviders />
        </>
    );
}
