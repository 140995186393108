import type { ColumnType } from 'antd/lib/table';
import type { SortOrder } from 'antd/lib/table/interface';
import cx from 'classnames';
import { _concat } from 'common/Utils';
import GCheckbox from 'components/GCheckbox';
import { GRadio } from 'components/GRadio';
import { GDataGrid } from '..';
import { IGSelectionTableProps } from '../helpers/interface';
import Styles from './Styles.module.scss';

export default function GSelectionTable<T = any>({
    selectionType = 'multiple',
    idIndex,
    selectionDisabledList,
    onChangeSelection,
    allSelected,
    selectionElement,
    onAllSelected,
    selectionList,
    columns,
    className,
    moveSelectedToTop = true,
    currentSorter,
    rowKey,
    ...props
}: IGSelectionTableProps) {
    function renderSelection(id: string) {
        const checked = selectionList?.includes(id);

        if (selectionElement) {
            return selectionElement(id);
        }

        if (selectionType === 'single') {
            return (
                <GRadio
                    checked={checked}
                    onChange={e => onChangeSelection?.(id, e.target.checked)}
                    disabled={selectionDisabledList?.includes(id)}
                />
            );
        }

        return (
            <GCheckbox
                checked={checked}
                onChange={e => onChangeSelection?.(id, e.target.checked)}
                disabled={selectionDisabledList?.includes(id)}
            />
        );
    }

    let selectionSortOrder: SortOrder;

    if (moveSelectedToTop) {
        selectionSortOrder = currentSorter?.columnKey === 'selection' ? currentSorter?.order : null;
        if (!currentSorter?.order) selectionSortOrder = 'ascend';
    }

    const selectionColumn: ColumnType<any> = {
        dataIndex: idIndex,
        key: 'selection',
        title:
            selectionType === 'multiple' && onAllSelected ? (
                <GCheckbox
                    checked={allSelected}
                    onChange={e => onAllSelected?.(e.target.checked)}
                    indeterminate={Boolean(selectionList?.length) && !allSelected}
                />
            ) : null,
        fixed: true,
        width: 40,
        className: Styles.SelectionColumn,
        render: renderSelection,
        sorter: (a: any, b: any) => Number(selectionList?.includes(b[idIndex])) - Number(selectionList?.includes(a[idIndex])),
        sortOrder: selectionSortOrder,
    };

    const _columns = props?.rowSelection
        ? columns
        : _concat(
              selectionColumn,
              columns?.map(c => ({ ...c, sortOrder: currentSorter?.columnKey === c.key ? currentSorter?.order : null }))
          );

    return (
        <GDataGrid<T>
            columns={_columns}
            className={cx(Styles.SelectionTable, className)}
            rowKey={rowKey || idIndex}
            {...props}
        />
    );
}
