import cornerstoneTools from 'cornerstone-tools';
import { Point } from '../DicomViewerHelper';
import { eraseInsideFreehand, fillInsideFreehand } from './GFreehandScissorsTool';

const BaseTool = cornerstoneTools.importInternal('base/BaseTool');
const cursors = cornerstoneTools.importInternal('tools/cursors');
const segmentationUtils = cornerstoneTools.importInternal('util/segmentationUtils');

export default class GCorrectionScissorsTool extends BaseTool {
    /** @inheritdoc */
    constructor(props = {}) {
        const defaultProps = {
            name: 'CorrectionScissors',
            strategies: {
                CORRECTION: correction,
            },
            cursors: {
                CORRECTION: cursors.freehandFillInsideCursor,
            },
            defaultStrategy: 'CORRECTION',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            svgCursor: cursors.freehandFillInsideCursor,
            mixins: ['polylineSegmentationMixin'],
        };

        super(props, defaultProps);
    }
}

export function correction(evt: any, operationData: any) {
    const { pixelData, segmentIndex, segmentationMixinType } = operationData;

    if (segmentationMixinType !== `freehandSegmentationMixin`) {
        console.error(`correction operation requires freehandSegmentationMixin operationData, recieved ${segmentationMixinType}`);

        return;
    }

    const nodes = snapPointsToGrid(evt, operationData);

    const scissorOperation = checkIfSimpleScissorOperation(nodes, segmentIndex);

    if (scissorOperation.isScissorOperation) {
        if (scissorOperation.operation === 'fillInsideFreehand') {
            console.warn('The line never intersects a segment.');
            fillInsideFreehand(evt, operationData);
        } else if (scissorOperation.operation === 'eraseInsideFreehand') {
            console.warn('The line is only ever inside the segment.');
            eraseInsideFreehand(evt, operationData);
        }

        return;
    }

    // Create binary labelmap with only this segment for calculations of each operation.
    const workingLabelMap = new Uint8Array(pixelData.length);
    const operations = splitLineIntoSeperateOperations(nodes, segmentIndex);

    operations.forEach(operation => {
        performOperation(operation, pixelData, workingLabelMap, segmentIndex, evt);
    });
}

function snapPointsToGrid(evt: any, operationData: any) {
    const { pixelData, points } = operationData;

    const { image } = evt.detail;
    const cols = image.width;
    const rows = image.height;

    const nodes = [];

    for (let i = 0; i < points.length; i++) {
        const point = points[i];

        let x = Math.floor(point.x);
        let y = Math.floor(point.y);

        // Clamp within the confines of the image.
        x = clip(x, 0, cols - 1);
        y = clip(y, 0, rows - 1);

        const lastNode = nodes[nodes.length - 1];

        // Skip double counting of closely drawn freehand points.
        if (lastNode && x === lastNode.x && y === lastNode.y) {
            continue;
        }

        nodes.push({
            x,
            y,
            segment: pixelData[y * cols + x],
        });
    }

    return nodes;
}

function checkIfSimpleScissorOperation(nodes: any, segmentIndex: number) {
    let allInside = true;
    let allOutside = true;

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        if (node.segment === segmentIndex) {
            allOutside = false;
        } else {
            allInside = false;
        }

        if (!allInside && !allOutside) {
            break;
        }
    }

    if (allOutside) {
        return { isScissorOperation: true, operation: 'fillInsideFreehand' };
    } else if (allInside) {
        return { isScissorOperation: true, operation: 'eraseInsideFreehand' };
    }

    return { isScissorOperation: false };
}

function splitLineIntoSeperateOperations(nodes: any, segmentIndex: any) {
    // Check whether the first node is inside a segment of the appropriate label or not.
    let isLabel = nodes[0].segment === segmentIndex;

    const operations = [];

    operations.push({
        additive: !isLabel,
        nodes: [] as any,
    });

    let operationIndex = 0;

    for (let i = 0; i < nodes.length; i++) {
        const node = nodes[i];

        if (isLabel) {
            operations[operationIndex].nodes.push(node);

            if (node.segment !== segmentIndex) {
                // Start a new operation and include the last two nodes.

                operationIndex++;
                isLabel = !isLabel;
                operations.push({
                    additive: true,
                    nodes: [],
                });
                operations[operationIndex].nodes.push(nodes[i - 1]);
                operations[operationIndex].nodes.push(node);
            }
        } else {
            operations[operationIndex].nodes.push(node);

            if (node.segment === segmentIndex) {
                // Start a new operation and add include the last two nodes.
                operationIndex++;
                isLabel = !isLabel;
                operations.push({
                    additive: false,
                    nodes: [],
                });
                operations[operationIndex].nodes.push(nodes[i - 1]);
                operations[operationIndex].nodes.push(node);
            }
        }
    }

    // Trim the first and last entries, as they don't form full operations.

    operations.pop();
    operations.shift();

    return operations;
}

function performOperation(operation: any, pixelData: any, workingLabelMap: any, segmentIndex: number, evt: any) {
    const { width: cols, height: rows } = evt.detail.image;

    const { nodes, additive } = operation;
    const shouldFillOver = additive ? 0 : 1;

    // Local getters to swap from cornerstone vector notation and flattened array indicies.
    const getPixelIndex = (pixelCoord: Point) => pixelCoord.y * cols + pixelCoord.x;
    const getPixelCoordinateFromPixelIndex = (pixelIndex: number) => ({
        x: pixelIndex % cols,
        y: Math.floor(pixelIndex / cols),
    });

    if (additive) {
        console.warn('additive operation...');
    } else {
        console.warn('subtractive operation...');
    }

    const { pixelPath, leftPath, rightPath } = getPixelPaths(nodes);

    // Find extent of region for floodfill (This segment + the drawn loop).
    // This is to reduce the extent of the outwards floodfill, which constitutes 99% of the computation.
    const firstPixelOnPath = pixelPath[0];

    const boundingBox = {
        xMin: firstPixelOnPath.x,
        xMax: firstPixelOnPath.x,
        yMin: firstPixelOnPath.y,
        yMax: firstPixelOnPath.y,
    };

    // ...whilst also initializing the workingLabelmap
    for (let i = 0; i < workingLabelMap.length; i++) {
        if (pixelData[i] === segmentIndex) {
            const pixel = getPixelCoordinateFromPixelIndex(i);

            expandBoundingBox(boundingBox, pixel);
            workingLabelMap[i] = 1;
        } else {
            workingLabelMap[i] = 0;
        }
    }

    // Set workingLabelmap pixelPath to 2 to form a
    // Boundary in the working labelmap to contain the flood fills.
    for (let i = 0; i < pixelPath.length; i++) {
        const pixel = pixelPath[i];

        workingLabelMap[getPixelIndex(pixel)] = 2;
        expandBoundingBox(boundingBox, pixel);
    }

    clipBoundingBox(boundingBox, rows, cols);

    const { xMin, xMax, yMin, yMax } = boundingBox;

    // Define a getter for the fill routine to access the working label map.
    function getter(x: number, y: number) {
        // Check if out of bounds, as the flood filler doesn't know about the dimensions of
        // The data structure. E.g. if cols is 10, (0,1) and (10, 0) would point to the same
        // position in this getter.

        if (x >= xMax || x < xMin || y >= yMax || y < yMin) {
            return;
        }

        return workingLabelMap[y * cols + x];
    }

    let leftArea = 0;
    let rightArea = 0;

    // Traverse the path whilst pouring paint off the left and right sides.
    for (let i = 0; i < leftPath.length; i++) {
        // Left fill
        const leftPixel = leftPath[i];
        const leftValue = workingLabelMap[getPixelIndex(leftPixel)];

        if (leftValue === shouldFillOver && isPointInImage(leftPixel, rows, cols)) {
            leftArea += fillFromPixel(leftPixel, 3, workingLabelMap, getter, cols);
        }

        // Right fill
        const rightPixel = rightPath[i];
        const rightValue = workingLabelMap[getPixelIndex(rightPixel)];

        if (rightValue === shouldFillOver && isPointInImage(rightPixel, rows, cols)) {
            rightArea += fillFromPixel(rightPixel, 4, workingLabelMap, getter, cols);
        }
    }

    if (leftArea === 0 || rightArea === 0) {
        // The areas are connected, therefore the start and end
        // Of the path go through unconnected regions, exit.
        return;
    }

    const replaceValue = additive ? segmentIndex : 0;

    // Fill in smallest area.
    const fillValue = leftArea < rightArea ? 3 : 4;

    for (let i = 0; i < workingLabelMap.length; i++) {
        if (workingLabelMap[i] === fillValue) {
            pixelData[i] = replaceValue;
        }
    }

    if (replaceValue === segmentIndex) {
        // Fill in the path.
        for (let i = 0; i < pixelPath.length; i++) {
            pixelData[getPixelIndex(pixelPath[i])] = segmentIndex;
        }
    } else {
        // Only erase this segment.
        for (let i = 0; i < pixelPath.length; i++) {
            const pixelIndex = getPixelIndex(pixelPath[i]);

            if (pixelData[pixelIndex] === segmentIndex) {
                pixelData[pixelIndex] = 0;
            }
        }
    }
}

function getPixelPaths(nodes: any) {
    const pixelPath: any = [];

    for (let i = 0; i < nodes.length - 1; i++) {
        // Push the node.
        pixelPath.push(nodes[i]);
        // Path towards next node.
        pixelPath.push(...segmentationUtils.getPixelPathBetweenPixels(nodes[i], nodes[i + 1]));
    }

    // Push final node.
    pixelPath.push(nodes[nodes.length - 1]);

    // Get paths on either side.

    const leftPath = [];
    const rightPath = [];

    for (let i = 0; i < pixelPath.length - 1; i++) {
        const { left, right } = getNodesPerpendicularToPathPixel(pixelPath[i], pixelPath[i + 1]);

        leftPath.push(left);
        rightPath.push(right);
    }

    return { pixelPath, leftPath, rightPath };
}

function getNodesPerpendicularToPathPixel(pathPixel: Point, nextPathPixel: Point) {
    const direction = {
        x: nextPathPixel.x - pathPixel.x,
        y: nextPathPixel.y - pathPixel.y,
    };

    // P = pathPixel, n = nextPathPixel, L = left, R = right
    //
    // |n| |R|  | |n| |  |L| |n|
    // | |p| |  |L|p|R|  | |p| |
    // |L| | |  | | | |  | | |R|
    //
    // | |R| |           | |L| |
    // |n|p| |           | |p|n|
    // | |L| |           | |R| |
    //
    // |R| | |  | | | |  | | |L|
    // | |p| |  |R|p|L|  | |p| |
    // |n| |L|  | |n| |  |R| |n|

    if (direction.x === -1 && direction.y === 1) {
        return {
            left: { x: pathPixel.x - 1, y: pathPixel.y - 1 },
            right: { x: pathPixel.x + 1, y: pathPixel.y + 1 },
        };
    } else if (direction.x === 0 && direction.y === 1) {
        return {
            left: { x: pathPixel.x - 1, y: pathPixel.y },
            right: { x: pathPixel.x + 1, y: pathPixel.y },
        };
    } else if (direction.x === 1 && direction.y === 1) {
        return {
            left: { x: pathPixel.x - 1, y: pathPixel.y + 1 },
            right: { x: pathPixel.x + 1, y: pathPixel.y - 1 },
        };
    } else if (direction.x === 1 && direction.y === 0) {
        return {
            left: { x: pathPixel.x, y: pathPixel.y + 1 },
            right: { x: pathPixel.x, y: pathPixel.y - 1 },
        };
    } else if (direction.x === 1 && direction.y === -1) {
        return {
            left: { x: pathPixel.x + 1, y: pathPixel.y + 1 },
            right: { x: pathPixel.x - 1, y: pathPixel.y - 1 },
        };
    } else if (direction.x === 0 && direction.y === -1) {
        return {
            left: { x: pathPixel.x + 1, y: pathPixel.y },
            right: { x: pathPixel.x - 1, y: pathPixel.y },
        };
    } else if (direction.x === -1 && direction.y === -1) {
        return {
            left: { x: pathPixel.x + 1, y: pathPixel.y - 1 },
            right: { x: pathPixel.x - 1, y: pathPixel.y + 1 },
        };
    } else if (direction.x === -1 && direction.y === 0) {
        return {
            left: { x: pathPixel.x, y: pathPixel.y - 1 },
            right: { x: pathPixel.x, y: pathPixel.y + 1 },
        };
    }

    console.error(`Unable to find left and right paths for flood fill `, pathPixel, nextPathPixel, direction);
}

function expandBoundingBox(boundingBox: any, pixel: Point) {
    const { x, y } = pixel;

    if (x < boundingBox.xMin) {
        boundingBox.xMin = x;
    }
    if (x > boundingBox.xMax) {
        boundingBox.xMax = x;
    }
    if (y < boundingBox.yMin) {
        boundingBox.yMin = y;
    }
    if (y > boundingBox.yMax) {
        boundingBox.yMax = y;
    }
}

function clipBoundingBox(boundingBox: any, rows: number, cols: number) {
    // Add a 2px border to stop the floodfill starting out of bounds and exploading.
    const border = 2;

    boundingBox.xMax = Math.min(boundingBox.xMax + border, cols);
    boundingBox.xMin = Math.max(boundingBox.xMin - border, 0);
    boundingBox.yMax = Math.min(boundingBox.yMax + border, rows);
    boundingBox.yMin = Math.max(boundingBox.yMin - border, 0);
}

function fillFromPixel(pixel: Point, fillValue: number, workingLabelMap: any, getter: any, cols: number) {
    const result = segmentationUtils.floodFill(getter, [pixel.x, pixel.y]);

    const flooded = result.flooded;

    for (let p = 0; p < flooded.length; p++) {
        const floodedI = flooded[p];

        workingLabelMap[floodedI[1] * cols + floodedI[0]] = fillValue;
    }

    return flooded.length;
}

export function clip(val: number, low: number, high: number) {
    return Math.min(Math.max(low, val), high);
}

export function isPointInImage({ x, y }: Point, rows: number, cols: number) {
    return x < cols && x >= 0 && y < rows && y >= 0;
}
