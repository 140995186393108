import cs from 'classnames';
import React from 'react';
import './Styles.scss';

export default function GGrid({ children, className, id }: IProps) {
    return (
        <div
            className={cs('g-grid-container', className)}
            id={id}
        >
            {children}
        </div>
    );
}

type IProps = React.PropsWithChildren<{
    id?: string;
    className?: string;
}>;
