/* eslint-disable eqeqeq */
import dcmjs from 'dcmjs';

export class DicomWebHelper {
    static getAttribute(element: any, defaultValue?: any) {
        if (!element) {
            return defaultValue;
        }
        // Value is not present if the attribute has a zero length value
        if (!element.Value) {
            return defaultValue;
        }
        // Sanity check to make sure we have at least one entry in the array.
        if (!element.Value.length) {
            return defaultValue;
        }

        return this.convertToInt(element.Value);
    }

    static convertToInt(input: any) {
        function padFour(input: any) {
            const l = input.length;

            if (l == 0) return '0000';
            if (l == 1) return '000' + input;
            if (l == 2) return '00' + input;
            if (l == 3) return '0' + input;

            return input;
        }

        let output = '';

        for (let i = 0; i < input.length; i++) {
            for (let j = 0; j < input[i].length; j++) {
                output += padFour(input[i].charCodeAt(j).toString(16));
            }
        }

        return parseInt(output, 16);
    }

    static getModalities(Modality: any, ModalitiesInStudy: any) {
        if (!Modality && !ModalitiesInStudy) {
            return {};
        }

        const modalities = structuredClone(Modality) || {
            vr: 'CS',
            Value: [],
        };

        // Rare case, depending on the DICOM server we are using, but sometimes,
        // modalities.Value is undefined or null.
        modalities.Value = modalities.Value || [];

        if (ModalitiesInStudy) {
            if (modalities.vr && modalities.vr === ModalitiesInStudy.vr) {
                ModalitiesInStudy.Value?.forEach?.((value: any) => {
                    if (!modalities.Value.includes(value)) {
                        modalities.Value.push(value);
                    }
                });
            } else {
                return structuredClone(ModalitiesInStudy);
            }
        }

        return modalities.Value;
    }

    static getName(element: any, defaultValue?: any) {
        if (!element) {
            return defaultValue;
        }
        // Value is not present if the attribute has a zero length value
        if (!element.Value) {
            return defaultValue;
        }
        // Sanity check to make sure we have at least one entry in the array.
        if (!element.Value.length) {
            return defaultValue;
        }
        // Return the Alphabetic component group
        if (element.Value[0].Alphabetic) {
            return element.Value[0].Alphabetic;
        }
        // Orthanc does not return PN properly so this is a temporary workaround
        return element.Value[0];
    }

    static getNumber(element: any, defaultValue?: any) {
        if (!element) {
            return defaultValue;
        }
        // Value is not present if the attribute has a zero length value
        if (!element.Value) {
            return defaultValue;
        }
        // Sanity check to make sure we have at least one entry in the array.
        if (!element.Value.length) {
            return defaultValue;
        }

        return parseFloat(element.Value[0]);
    }

    static getString(element: any, defaultValue?: any) {
        if (!element) {
            return defaultValue;
        }
        // Value is not present if the attribute has a zero length value
        if (!element.Value) {
            return defaultValue;
        }
        // Sanity check to make sure we have at least one entry in the array.
        if (!element.Value.length) {
            return defaultValue;
        }
        // Join the array together separated by backslash
        // NOTE: Orthanc does not correctly split values into an array so the join is a no-op
        return element.Value.join('\\');
    }

    static humanizeDicomData(data: any) {
        return dcmjs.data.DicomMetaDictionary.naturalizeDataset(data);
    }

    static getNameFromTag(tag: string): string {
        return dcmjs.data.DicomMetaDictionary.dictionary[dcmjs.data.DicomMetaDictionary.punctuateTag(tag)]?.name;
    }

    static getTagNumberFromName(name: string): string {
        const tag = dcmjs.data.DicomMetaDictionary.nameMap[name]?.tag;
        if (!tag) return '';
        return dcmjs.data.DicomMetaDictionary.unpunctuateTag(tag);
    }

    static getTagItemFromName(name: string) {
        const tagItem = dcmjs.data.DicomMetaDictionary.nameMap[name];
        if (!tagItem) return null;
        return {
            ...tagItem,
            tagNumber: dcmjs.data.DicomMetaDictionary.unpunctuateTag(tagItem.tag),
        };
    }
}
