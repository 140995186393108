/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useLayoutEffect, useRef } from "react";
import { ChartHelper } from "common/helpers";
import { isNullOrUndefined } from "common/Utils";
import { createChart } from "./createChart";
import { IBaseChartProps } from "../entities";

export default function GaugeChart(props: IGaugeChartProps) {
    const CHART_ID = props.chartID;
    let data = props.data;
    const chartHelperRef = useRef<ChartHelper.GaugeChart>();

    useEffect(() => {

        if (isNullOrUndefined(data)) {
            data = 0;
        }

        if (chartHelperRef.current) {
            chartHelperRef.current.updateData(data);
        }

    }, [data]);

    useLayoutEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(CHART_ID)
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
    }, []);

    return (
        <>
            <div style={{ height: props.height || "100%", width: props.width || "95%" }} id={CHART_ID} />
        </>
    );
}



export interface IGaugeChartProps extends IBaseChartProps {
    data?: number;

}