import { cornerstoneTools } from "../../CornerstoneInitHelper/CornerstoneHelper"

const segmentationModule = cornerstoneTools.getModule("segmentation");

export default function updateSegmentsOnActiveLabelmap(eventData, extent) {
  const { getters, setters } = segmentationModule;
  const { image, element } = eventData;
  const { labelmap3D } = getters.labelmap2D(element);
  const { width, height } = image;

  let imageIdIndex = extent.currentImageIdIndex;
  const labelmap2D = getters.labelmap2DByImageIdIndex(
    labelmap3D,
    imageIdIndex,
    height,
    width
  );

  setters.updateSegmentsOnLabelmap2D(labelmap2D);
}