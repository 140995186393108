import { IWindowLevel, isWindowLevelExist } from 'common/enum';
import { IUsePlatformThumbnailArgs, useGetPlatformRawImageQuery } from 'pages/Datasets/store/DatasetService';

export function usePlatformThumbnail({
    imageId,
    datasetId,
    windowLevel,
    width = 200,
    height = 120,
    slice = -1,
    skip = false,
}: IProps) {
    const args: IUsePlatformThumbnailArgs = {
        image_id: imageId,
        dataset_id: datasetId,
        width,
        height,
        image_slice: slice,
        raw_image: false,
    };
    if (isWindowLevelExist(windowLevel)) {
        args.ww = windowLevel.ww;
        args.wc = windowLevel.wc;
    }
    const { data, isFetching } = useGetPlatformRawImageQuery(args, { skip: !imageId || !datasetId || skip });

    return { image: data as any, loading: isFetching };
}

interface IProps {
    imageId: string;
    datasetId: string;
    windowLevel?: IWindowLevel;
    width?: number;
    height?: number;
    slice?: number;
    skip?: boolean;
}
