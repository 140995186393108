import { AxiosResponse } from "axios";
import { AuthService, getMessages } from "common";
import { showMessageModal } from "components";

export function* registerNewUserTransactionSaga(sagaAction: any): any {
    try {
        const payload = sagaAction.payload;

        payload.origin = window.location.origin;

        const response: AxiosResponse<boolean> = yield AuthService.Register.call(payload);

        if (response?.data) {

            showMessageModal({ content: getMessages("000231"), }).then(() => {
                setTimeout(() => {
                    window.location.reload();
                }, 3000);
            });
        }
    }
    catch (e) {
    }
}