import { IModelDeployment, ModelDeployment } from "./ModelDeployment";
import { IPlatformAlert, IPlatformHealth, PlatformHealthFactory } from "./PlatformHealth";

export interface IPlatformMonitoring {
    platformHealth: IPlatformHealth;
    modelDeployment: ModelDeployment;
    modalVisible: boolean;
    selectedModels: Array<any>;
    platformAlert: IPlatformAlert;
    platformAlertModalVisible: boolean;
}


export function PlatformMonitoringFactory(data?: Partial<IPlatformMonitoring>): IPlatformMonitoring {
    return {
        platformHealth: PlatformHealthFactory(data?.platformHealth),
        modelDeployment: data?.modelDeployment || [],
        modalVisible: data?.modalVisible ?? false,
        selectedModels: data?.selectedModels || [],
        platformAlert: data?.platformAlert || null,
        platformAlertModalVisible: data?.platformAlertModalVisible || false,
    };
}

export type { IModelDeployment, IPlatformHealth, ModelDeployment };