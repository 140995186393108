import { getMessages } from 'common/helpers/MLHelper';
import GButton from 'components/GButton';
import { Reset } from 'components/assets/icons';
import Styles from './Styles.module.scss';

export function ApproveButtonGroup({ onApprove, onCancel, approve, cancel, reset, onReset }: IProps) {
    return (
        <div className={Styles.ButtonGroup}>
            {Boolean(onReset) && (
                <GButton
                    type="default"
                    className={Styles.ResetButton}
                    onClick={onReset}
                    {...reset}
                    endIcon={<Reset />}
                >
                    {reset?.text || getMessages('000329')}
                </GButton>
            )}
            <GButton
                type="default"
                onClick={onCancel}
                {...cancel}
            >
                {cancel?.text || getMessages('000238')}
            </GButton>
            <GButton
                type="primary"
                onClick={onApprove}
                {...approve}
            >
                {approve?.text || getMessages('000169')}
            </GButton>
        </div>
    );
}

interface IProps {
    approve?: IButtonProps;
    cancel?: IButtonProps;
    reset?: IButtonProps;
    onCancel?: () => void;
    onApprove?: () => void;
    onReset?: () => void;
}

interface IButtonProps {
    disabled?: boolean;
    loading?: boolean;
    text?: string;
}
