import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { DashboardStateFactory } from '../entities/IDashboard';

export const DashboardSlice = createSlice({
    name: 'DicomViewer',
    initialState: DashboardStateFactory(),
    reducers: {
        setDatasetStatsModalVisible(state, action: PayloadAction<boolean>) {
            state.isStatsModalVisible = action.payload;
        },
        setSelectedId(state, action: PayloadAction<string>) {
            state.selectedId = action.payload;
        },
    },
});

export const { setDatasetStatsModalVisible, setSelectedId } = DashboardSlice.actions;

export default DashboardSlice.reducer;
