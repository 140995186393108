import { Checkbox, CheckboxProps } from 'antd';
import Styles from "./Styles.module.scss";
import cx from "classnames"
import { RefObject } from 'react';

export default function GCheckbox({ className, id,  checkboxRef, ...props }: IGCheckbox) {
    return (
        <>
            <Checkbox
                ref={checkboxRef}
                className={cx(Styles.CheckboxComponent, className)}
                id={id}
                {...props}
            />
        </>
    );
}

interface IGCheckbox extends CheckboxProps {
    checkboxRef?: RefObject<HTMLInputElement>;
}
