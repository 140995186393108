export enum UserAuditEvents {
    Login = 'Logged In',
    Logout = 'Logged Out',
    Register = 'Registered',
    ResetPassword = 'Reset Password',
    ChangePassword = 'Changed Password',
    ChangeRole = 'Changed Role',
    ChangeStatus = 'Changed Status',
    UpdateProfileInfo = 'Updated Profile Information',
    ChangeAvatar = 'Changed Avatar',
    Change2FA = 'Changed Two-Factor Authentication',
}

export enum ValidationAuditEvents {
    Start = 'Started Validation Process',
    Stop = 'Stopped Validation Process',
    Analytics = 'Viewed Validation Analytics',
    Archive = 'Archived Validation',
    Unarchive = 'Unarchived Validation',
    Delete = 'Deleted Validation',
    ChangeInfo = 'Changed Validation Information',
    RemoveBlindSpotAnalysis = 'Removed Sub-Cohort Analysis',
    ExportPredictions = 'Exported Validation Predictions',
}

export enum AnnotationAuditEvents {
    ApplyPreAnnotation = 'Applied Pre-Annotation',
    RemovePreAnnotation = 'Removed Pre-Annotation',
    CreateAnnotation = 'Created Annotation',
    EditAnnotation = 'Edited Annotation',
    RemoveAnnotation = 'Removed Annotation',
    CompleteProject = 'Completed Project',
    UpdateAnnotationStatus = 'Updated Annotation Status',
    SaveAnnotation = 'Saved Annotation',
}


export enum Annotation3DAuditEvents {
    UpdateComment = 'Updated Annotation Comment',
    UpdateLabelStatus = 'Updated Label Status',
    UpdateLabelReviewStatus = 'Updated Label Review Status',
    SaveAnnotation = 'Saved Annotation File',
    RemoveAnnotation = 'Removed Annotation',
    SetAsPrimary = 'Set Annotation As Primary',
    UpdateReviewStatus = 'Updated Annotation Review Status',
    SaveImageEvaluation = 'Save Image Evaluation',
    RemoveImageEvaluation = 'Remove Image Evaluation'
}

export enum DatasetAuditEvents {
    AddNewSource = 'Added New Source',
    ArchiveDataset = 'Archived Dataset',
    UnArchiveDataset = 'Unarchived Dataset',
    ChangeAccessType = 'Changed Access Type',
    ChangeDownloadPermission = 'Changed Dataset Download Permission',
    CreateCuratedDataset = 'Created Curated Dataset',
    DeleteCuratedDataset = 'Deleted Curated Dataset',
    EditDataset = 'Edited Dataset',
    RemoveUserPermission = 'Removed User Permission',
    UpdateDataset = 'Update Dataset',
}

export enum ModelAuditEvents {
    AddNewVendor = 'Added New Vendor',
    ArchiveModel = 'Archived Model',
    UnArchiveModel = 'Unarchived Model',
    AddModelImage = 'Added Model Image',
    EditModel = 'Edited Model',
    UpdatePreEmptiveOptions = 'Updated Pre-Emptive Options',
    ChangeAccessType = 'Changed Access Type',
    RemoveUserPermission = 'Removed User Permission',
    ChangeModelDownloadPermission = 'Changed Model Download Permission',
    ChangeModelSourceFileModifyPermission = 'Changed Model Source File Modify Permission',
    UpdateExperimentalMode = 'Updated Experimental Mode',
}

export enum MetadataAuditEvents {
    CreateMetadata = 'Created Metadata',
    EditMetadata = 'Edited Metadata',
    RemoveMetadata = 'Removed Metadata',
    SetDefaultMetadata = 'Set Default Metadata',
    UpdateEntities = 'Updated Entities',
}

export enum PlatformAuditEvents {
    AddNewCredential = 'Added New Credential',
    AddVerifiedUser = 'Added Verified User',
    RemoveVerifiedUser = 'Removed Verified User',
    CreateNewUser = 'Created New User',
    DeleteCredentialKey = 'Deleted Credential Key',
}

export enum ProjectAuditEvents {
    CreateProject = 'Created Project',
    EditProject = 'Edited Project',
    DeleteProject = 'Deleted Project',
}
export enum TrainingAuditEvents {
    DeleteTrainJob = 'Deleted Train Job',
    UpdateNameAndAccessOptions = 'Updated Name and Access Options',
}

export type AuditEventType =
    | UserAuditEvents
    | ValidationAuditEvents
    | AnnotationAuditEvents
    | Annotation3DAuditEvents
    | DatasetAuditEvents
    | ModelAuditEvents
    | MetadataAuditEvents
    | PlatformAuditEvents
    | ProjectAuditEvents
    | TrainingAuditEvents;

export enum AuditCategory {
    Model = 'model',
    Dataset = 'dataset',
    Validation = 'validation',
    Annotation = 'annotation',
    Annotation3D = 'annotation3d',
    Deployment = 'deployment',
    Training = 'training',
    User = 'user',
    Platform = 'platform',
    Metadata = 'metadata',
    Project = 'x-project-id',
}
