import { all, takeEvery } from "redux-saga/effects";
import * as actions from "../actions";
import { favoriteBSARoundSaga } from "./favoriteBSARoundSaga";
import { favoriteValidationItemSaga } from './favoriteValidationItemSaga';

export default function* rootSaga() {
    yield all([
        takeEvery(actions.FAVORITE_VALIDATION_TRANSACTION, favoriteValidationItemSaga),
        takeEvery(actions.FAVORITE_BSA_ROUND_TRANSACTION, favoriteBSARoundSaga),
    ]);
}


