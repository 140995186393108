import { AxiosResponse } from "axios";
import { AuthService, getMessages } from "common";
import { showMessageModal } from "components";

export function* ForgotPasswordSaga(sagaAction: any): any {
    try {
        const payload = { ...sagaAction.payload, origin: window.location.origin };
        const response: AxiosResponse<boolean> = yield AuthService.ForgotPassword.call(payload);

        if (response.data) {

            showMessageModal({ content: getMessages("000396"), id: "forgor-password-modal" });
        }

    } catch (error) {
        console.log(error);
    }

}