import * as am5 from '@amcharts/amcharts5';
import type { ColumnSeries } from '@amcharts/amcharts5/xy';
import { Dictionary } from 'common/entities';
import { ChartHelper } from 'common/helpers';
import _uniqueId from 'lodash/uniqueId';
import { useEffect, useRef, useState } from 'react';
import { IBaseChartProps } from '../entities';
import { IChartOptions, createChart } from './createChart';

export default function BoxPlot(props: IBoxPlotChartProps) {
    const [chartID, setChartID] = useState(_uniqueId(props.chartID));
    const chartHelperRef = useRef<ChartHelper.XYChart<ColumnSeries>>();
    const xAxisTitleRef = useRef<am5.Label>();

    useEffect(() => {
        setChartID(_uniqueId(props.chartID));
    }, [props.chartID, props?.chartOptions?.lightTheme]);

    useEffect(() => {
        if (props.data && chartHelperRef.current) {
            chartHelperRef.current.setData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            const { chartHelper, xAxisTitle } = createChart(chartID, props.chartOptions);
            chartHelperRef.current = chartHelper;
            xAxisTitleRef.current = xAxisTitle;

            if (props.data) {
                chartHelperRef.current.setData(props.data);
            }
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div
            className={props.className}
            style={{ height: props.height, width: props.width }}
            id={chartID}
        />
    );
}

export interface IBoxPlotChartProps extends IBaseChartProps {
    data?: Array<Dictionary<any>>;
    chartOptions?: IChartOptions;
}

export interface IData extends Dictionary {
    name: string;
    value: number;
}
