import cornerstone from 'cornerstone-core';
import cornerstoneTools from 'cornerstone-tools';
import { CornerstoneMouseEvent, CornerstoneToolEvents, IMeasurementData } from '../DicomViewerHelper/interface';
import EVENTS from './event';
import toolColors from './toolColors';

const getPixelSpacing = cornerstoneTools.importInternal('util/getPixelSpacing');
const throttle = cornerstoneTools.importInternal('util/throttle');
const lineSegDistance = cornerstoneTools.importInternal('util/lineSegDistance');
const BaseAnnotationTool = cornerstoneTools.importInternal('base/BaseAnnotationTool');
// Drawing

const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext');
const draw = cornerstoneTools.importInternal('drawing/draw');
const setShadow = cornerstoneTools.importInternal('drawing/setShadow');

const drawJoinedLines = cornerstoneTools.importInternal('drawing/drawJoinedLines');
const drawLinkedTextBox = cornerstoneTools.importInternal('drawing/drawLinkedTextBox');
const drawHandles = cornerstoneTools.importInternal('drawing/drawHandles');
const moveNewHandle = cornerstoneTools.importInternal('manipulators/moveHandle');
const textBoxWidth = cornerstoneTools.importInternal('drawing/textBoxWidth');
const roundToDecimal = cornerstoneTools.importInternal('util/roundToDecimal');
const triggerEvent = cornerstoneTools.importInternal('util/triggerEvent');
const mouseCursors = cornerstoneTools.importInternal('tools/cursors');

const moveHandleNearImagePoint = cornerstoneTools.importInternal('manipulators/moveHandleNearImagePoint');
const moveAnnotation = cornerstoneTools.importInternal('manipulators/moveAnnotation');

/**
 * @public
 * @class AngleTool
 * @memberof Tools.Annotation
 * @classdesc Create and position an angle by placing three consecutive points.
 * @extends Tools.Base.BaseAnnotationTool
 * @hideconstructor
 *
 * @param {ToolConfiguration} [props={}]
 */
export default class GAngleTool extends BaseAnnotationTool {
    constructor(props = {}) {
        const defaultProps = {
            name: 'Angle',
            supportedInteractionTypes: ['Mouse', 'Touch'],
            svgCursor: mouseCursors.angleCursor,
            configuration: {
                drawHandles: true,
                drawHandlesOnHover: false,
                hideHandlesIfMoving: false,
                renderDashed: false,
            },
        };

        super(props, defaultProps);

        this.preventNewMeasurement = false;

        this.throttledUpdateCachedStats = throttle(this.updateCachedStats, 110);
    }

    createNewMeasurement(eventData: any) {
        // Create the measurement data for this tool with the end handle activated
        return {
            visible: true,
            active: true,
            color: undefined as any,
            invalidated: true,
            measurement_info: this.configuration.measurement_info,
            handles: {
                start: {
                    x: eventData.currentPoints.image.x,
                    y: eventData.currentPoints.image.y,
                    highlight: true,
                    active: false,
                },
                middle: {
                    x: eventData.currentPoints.image.x,
                    y: eventData.currentPoints.image.y,
                    highlight: true,
                    active: true,
                },
                end: {
                    x: eventData.currentPoints.image.x,
                    y: eventData.currentPoints.image.y,
                    highlight: true,
                    active: false,
                },
                textBox: {
                    active: false,
                    hasMoved: false,
                    movesIndependently: false,
                    drawnIndependently: true,
                    allowedOutsideImage: true,
                    hasBoundingBox: true,
                },
            },
        };
    }

    pointNearTool(element: any, data: any, coords: any) {
        if (data.visible === false) {
            return false;
        }

        return (
            lineSegDistance(element, data.handles.start, data.handles.middle, coords) < 25 ||
            lineSegDistance(element, data.handles.middle, data.handles.end, coords) < 25
        );
    }

    updateCachedStats(image: any, element: any, data: any) {
        const sideA = getSide(image, data.handles.middle, data.handles.start);
        const sideB = getSide(image, data.handles.end, data.handles.middle);
        const sideC = getSide(image, data.handles.end, data.handles.start);

        const sideALength = length(sideA);
        const sideBLength = length(sideB);
        const sideCLength = length(sideC);

        // Cosine law
        let angle = Math.acos(
            (Math.pow(sideALength, 2) + Math.pow(sideBLength, 2) - Math.pow(sideCLength, 2)) / (2 * sideALength * sideBLength)
        );

        angle *= 180 / Math.PI;

        data.rAngle = roundToDecimal(angle, 2);
        data.invalidated = false;
    }

    renderToolData(evt: any) {
        const eventData = evt.detail;
        const enabledElement = eventData.enabledElement;
        const { handleRadius, drawHandlesOnHover, hideHandlesIfMoving, renderDashed } = this.configuration;
        // If we have no toolData for this element, return immediately as there is nothing to do
        const toolData = cornerstoneTools.getToolState(evt.currentTarget, this.name);
        const lineDash = cornerstoneTools.getModule('globalConfiguration').configuration.lineDash;

        if (!toolData) {
            return;
        }

        // We have tool data for this element - iterate over each one and draw it
        const context = getNewContext(eventData.canvasContext.canvas);
        const { image, element } = eventData;
        const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

        const lineWidth = cornerstoneTools.toolStyle.getToolWidth();

        for (let i = 0; i < toolData.data.length; i++) {
            const data = toolData.data[i];

            if (data.visible === false) {
                continue;
            }

            draw(context, (context: any) => {
                setShadow(context, this.configuration);

                // Differentiate the color of activation tool
                const color = toolColors.getColorIfActive(data);

                const handleStartCanvas = cornerstone.pixelToCanvas(eventData.element, data.handles.start);
                const handleMiddleCanvas = cornerstone.pixelToCanvas(eventData.element, data.handles.middle);

                const lineOptions = { color };

                if (renderDashed) {
                    (lineOptions as any).lineDash = lineDash;
                }

                drawJoinedLines(
                    context,
                    eventData.element,
                    data.handles.start,
                    [data.handles.middle, data.handles.end],
                    lineOptions
                );

                // Draw the handles
                const handleOptions = {
                    color,
                    handleRadius,
                    drawHandlesIfActive: drawHandlesOnHover,
                    hideHandlesIfMoving,
                };

                if (this.configuration.drawHandles) {
                    drawHandles(context, eventData, data.handles, handleOptions);
                }

                // Update textbox stats
                if (data.invalidated === true) {
                    if (data.rAngle) {
                        this.throttledUpdateCachedStats(image, element, data);
                    } else {
                        this.updateCachedStats(image, element, data);
                    }
                }

                if (data.rAngle) {
                    const text = textBoxText(data, rowPixelSpacing, colPixelSpacing);

                    const distance = 15;

                    let textCoords;

                    if (!data.handles.textBox.hasMoved) {
                        textCoords = {
                            x: handleMiddleCanvas.x,
                            y: handleMiddleCanvas.y,
                        };

                        const padding = 5;
                        const textWidth = textBoxWidth(context, text, padding);

                        if (handleMiddleCanvas.x < handleStartCanvas.x) {
                            textCoords.x -= distance + textWidth + 10;
                        } else {
                            textCoords.x += distance;
                        }

                        const transform = cornerstone.internal.getTransform(enabledElement);

                        transform.invert();

                        const coords = transform.transformPoint(textCoords.x, textCoords.y);

                        data.handles.textBox.x = coords.x;
                        data.handles.textBox.y = coords.y;
                    }

                    if (cornerstoneTools.getModule('globalConfiguration').configuration.isVisibleAngleTextBox) {
                        drawLinkedTextBox(
                            context,
                            eventData.element,
                            data.handles.textBox,
                            text,
                            data.handles,
                            textBoxAnchorPoints,
                            color,
                            lineWidth,
                            0,
                            true
                        );
                    }
                }
            });
        }

        function textBoxText(data: any, rowPixelSpacing: any, colPixelSpacing: any) {
            const suffix = !rowPixelSpacing || !colPixelSpacing ? ' (isotropic)' : '';
            const str = '00B0'; // Degrees symbol

            return data.rAngle.toString() + String.fromCharCode(parseInt(str, 16)) + suffix;
        }

        function textBoxAnchorPoints(handles: any) {
            return [handles.start, handles.middle, handles.end];
        }
    }

    handleSelectedCallback(
        evt: CustomEvent<CornerstoneMouseEvent>,
        toolData: IMeasurementData,
        handle: any,
        interactionType = 'mouse'
    ) {
        if (toolData?.visible === false) return;

        triggerEvent(evt.detail.element, CornerstoneToolEvents.INTERACTION_STARTED, { toolData });

        moveHandleNearImagePoint(evt, this, toolData, handle, interactionType);
    }

    toolSelectedCallback(evt: CustomEvent<CornerstoneMouseEvent>, toolData: IMeasurementData, interactionType = 'mouse') {
        if (toolData?.visible === false) return;

        triggerEvent(evt.detail.element, CornerstoneToolEvents.INTERACTION_STARTED, { toolData });

        moveAnnotation(evt, this, toolData, interactionType);
    }

    addNewMeasurement(evt: any, interactionType: any) {
        if (this.preventNewMeasurement) {
            return;
        }

        this.preventNewMeasurement = true;
        evt.preventDefault();
        evt.stopPropagation();

        const eventData = evt.detail;
        const measurementData = this.createNewMeasurement(eventData);
        const element = evt.detail.element;

        // Associate this data with this imageId so we can render it and manipulate it
        cornerstoneTools.addToolState(element, this.name, measurementData);
        cornerstone.updateImage(element);

        // Step 1, create start and second middle.
        moveNewHandle(
            eventData,
            this.name,
            measurementData,
            measurementData.handles.middle,
            this.options,
            interactionType,
            (success: any) => {
                measurementData.active = false;

                if (!success) {
                    cornerstoneTools.toolState.removeToolState(element, this.name, measurementData);

                    this.preventNewMeasurement = false;

                    return;
                }

                measurementData.handles.end.active = true;

                cornerstone.updateImage(element);

                // Step 2, create end.
                moveNewHandle(
                    eventData,
                    this.name,
                    measurementData,
                    measurementData.handles.end,
                    this.options,
                    interactionType,
                    (success: any) => {
                        if (success) {
                            measurementData.active = false;
                            cornerstone.updateImage(element);
                        } else {
                            cornerstoneTools.toolState.removeToolState(element, this.name, measurementData);
                        }

                        this.preventNewMeasurement = false;
                        cornerstone.updateImage(element);

                        const modifiedEventData = {
                            toolName: this.name,
                            toolType: this.name, // Deprecation notice: toolType will be replaced by toolName
                            element,
                            measurementData,
                        };

                        triggerEvent(element, EVENTS.MEASUREMENT_COMPLETED, modifiedEventData);
                    }
                );
            }
        );
    }
}

function length(vector: any) {
    return Math.sqrt(Math.pow(vector.x, 2) + Math.pow(vector.y, 2));
}

function getSide(image: any, handleEnd: any, handleStart: any) {
    const { rowPixelSpacing, colPixelSpacing } = getPixelSpacing(image);

    return {
        x: (handleEnd.x - handleStart.x) * (colPixelSpacing || 1),
        y: (handleEnd.y - handleStart.y) * (rowPixelSpacing || 1),
    };
}
