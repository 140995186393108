import { Dictionary, TClassMapping } from "common";

export interface IMetricsExtra {
    metaData: Dictionary<IMetaData>;
    trainingMetricsExist: boolean;
    classMappings: TClassMapping;
}

export function MetricsExtraDataFactory(data?: IMetricsExtra): IMetricsExtra {
    return {
        metaData: data?.metaData || {},
        trainingMetricsExist: data?.trainingMetricsExist || false,
        classMappings: data?.classMappings || {}
    };
}

export interface IMetaData {
    key?: string;
    type: MetaDataType,
    values?: any;
}

export enum MetaDataType {
    categorical = "categorical",
    continuous = "continuous"
}