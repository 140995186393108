export const endpoints = {
  "/validation-api/plot": "Service: Validation, Api: Validation Plot",
  "/validation-api/generate": "Service: Validation, Api: Validation Generate",
  "/validation-api/blind_spot_analysis": "Service: Validation, Api: Blind Spot Analysis",
  "/validation-api/blind_spot_combinations":"Service: Validation, Api: Blind Spot Combinations",
  "/platform-api/batch/prediction": "Service: Platform, Api: Batch prediction",
  "/platform-api/study/prediction": "Service: Platform, Api: Study Prediction",
  "/platform-api/study/result": "Service: Platform, Api: Study Result",
  "/manage/image/store/playground": "Service: Manage, Api: Playground Image Upload",
  "/manage/image/get_image_stream": "Service: Manage, Api: Get Image",
  "/manage/image/get_image_meta": "Service: Manage, Api: Get Dicom Details",
  "/manage/dataset/upload": "Service: Manage, Api: Dataset Upload",
  "/manage/dataset/metadata/template": "Service: Manage, Api: Download Metadata",
  "/manage/dataset/add": "Service: Manage, Api: Dataset Add",
  "/manage/dataset/get_images_meta": "Service: Manage, Api: Get Metadata",
  "/manage/metadata/get_entities": "Service: Manage, Api: Get Metadata Entities",
  "/manage/model/template/zip": "Service: Manage, Api: Download Model Artifacts",
  "/manage/model/upload/delete": "Service: Manage, Api: Model Delete",
  "/manage/model/upload/zip": "Service: Manage, Api: Model Upload",
  "/manage/model/db/add": "Service: Manage, Api: Model Add",
  "/manage/model/upload/file": "Service: Manage, Api: Model File Upload",
  "/deployment_manage/platform/live_services":"Service: Deployment, Api: Get Platform Live Services",
  "/deployment_manage/platform/system_utilization":"Service: Deployment, Api: System Utilization",
  "/deployment_manage/models/deploy": "Service: Deployment, Api: Model Deploy",
  "/deployment_manage/models/stop/all": "Service: Deployment, Api: Model Stop All",
  "/deployment_manage/models/stop/ids": "Service: Deployment, Api: Model Stop Ids",
  "/mlutility/dataset_analysis/find_similar_images":"Service: MLUtility, Api: Find Similar Images",
  "/mlutility/similarity/find_similar_datasets":"Service: MLUtility, Api: Find Similar Datasets",
};

export const endpointsWithId = {
   "/manage/dataset/delete/": "Service: Manage, Api: Delete Dataset",
   "/manage/model/db/delete/": "Service: Manage, Api: Delete Model",
   "/manage/model/get/file/": "Service: Manage, Api: Get Model File",
   "/deployment_manage/models/stop/": "Service: Deployment, Api: Model Stop",
   "/deployment_manage/models/system_utilization/": "Service: Deployment, Api: System Utilization",
   "/platform-api/deployment/get_workers/": "Service: Platform, Api: Get Workers",
   "/platform-api/prediction/": "Service: Platform, Api: Single Prediction",
   "/mlutility/dataset_analysis/run_dataset_analysis/":"Service: MLUtility, Api: Run Dataset Analysis",
};
