import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { IPlatformCommonState, PlatformStateFactory } from "../entities";
import { ICredentials } from "../entities/Credentials";
import { IUserManagement } from "../entities/UserManagement";
import { IPlatformHealth, ModelDeployment } from "../views/PlatformMonitoring/entities";
import { IPlatformAlert } from '../views/PlatformMonitoring/entities/PlatformHealth';

export const PlatformSlice = createSlice({
    name: 'Platform',
    initialState: PlatformStateFactory(),
    reducers: {
        setPlatformHealth(state, action: PayloadAction<IPlatformHealth>) {
            state.platformMonitoring.platformHealth = action.payload;
        },
        setModelDeployment(state, action: PayloadAction<ModelDeployment>) {
            state.platformMonitoring.modelDeployment = action.payload;
        },
        setPlatformAlert(state, action: PayloadAction<IPlatformAlert>) {
            state.platformMonitoring.platformAlert = action.payload;
        },
        setPlatformAlertModalVisible(state, action: PayloadAction<boolean>) {
            state.platformMonitoring.platformAlertModalVisible = action.payload;
        },
        setStopModelsModalVisible(state, action: PayloadAction<boolean>) {
            state.platformMonitoring.modalVisible = action.payload;
        },
        setSelectedModels(state, action: PayloadAction<any>) {
            state.platformMonitoring.selectedModels = action.payload;
        },
        setCredentialList(state, { payload }: PayloadAction<Array<ICredentials>>) {
            state.credentialList = payload;
        },
        setCredFormVisible(state, { payload }: PayloadAction<boolean>) {
            state.addCredentialVisible = payload;
        },
        updateUserManagementState(state, { payload }: PayloadAction<Partial<IUserManagement>>) {
            Object.assign(state.userManagement, payload);
        },
        updateCommonState(state, { payload }: PayloadAction<Partial<IPlatformCommonState>>) {
            Object.assign(state.common, payload);
        },
        setUploadState(state, { payload }: PayloadAction<any>) {
            state.uploadState = payload;
        },
    },
});

export const {
    setPlatformHealth,
    setModelDeployment,
    setCredentialList,
    updateUserManagementState,
    setStopModelsModalVisible,
    setSelectedModels,
    setCredFormVisible,
    updateCommonState,
    setUploadState,
    setPlatformAlert,
    setPlatformAlertModalVisible,
} = PlatformSlice.actions;

export default PlatformSlice.reducer;