import { getMessages } from 'common';
import { IFilterPanelProps } from 'common/components/DashboardView/components';
import {
    getSearchOptionsWithHighlight,
    useHandleSearch,
} from 'common/components/DashboardView/components/FilterPanel/useHandleSearch';
import Fuse from 'fuse.js';

import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { updateProjectFilters } from '../store';
import { useGetProjectListQuery } from '../store/ProjectService';
import { selectProjectFilter } from '../store/selectors';

export function useProjectFilter(): IFilterPanelProps {
    const projectFilters = useSelector(selectProjectFilter);
    const { data = [] } = useGetProjectListQuery(undefined);
    const { activeView } = useParams();

    let projectList;

    activeView === 'project-archived'
        ? (projectList = data?.filter(item => item?.isArchived))
        : (projectList = data?.filter(item => !item?.isArchived));
    const dispatch = useDispatch();
    let fuseResult: Fuse.FuseResult<any>[];

    const searchOptions = data?.map((item, index) => ({
        value: item.projectId,
        title: item.name,
        label: item.name,
    }));

    const options = {
        includeScore: true,
        minMatchCharLength: 3,
        includeMatches: true,
        keys: ['problemType', 'title', 'description', 'tags', 'modality'],
    };
    const { handleSearch } = useHandleSearch(searchOptions, options);

    fuseResult = handleSearch(projectFilters?.search ?? '');

    const annotationIds = projectList?.map(item => item.projectId);

    const filteredFuseResult = fuseResult?.filter(d => annotationIds?.includes(d.item.value));

    const searchOptionsWithHighlight = getSearchOptionsWithHighlight(filteredFuseResult);

    return {
        searchValue: projectFilters?.search,
        searchOptions: projectList?.map(item => ({
            value: item.projectId,
            title: item.name,
            label: item.name,
        })),
        searchOptionsWithHighlight: searchOptionsWithHighlight,
        booleanFilterList: [],
        onChangeFilter: (key, value) => dispatch(updateProjectFilters({ [key]: value })),
        title: getMessages('001237'),
        categoricalFilterList: [],
    };
}
