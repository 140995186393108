import Styles from "../GCheckbox/Styles.module.scss";
import cx from 'classnames';
import { CheckboxGroupProps } from 'antd/es/checkbox';
import { Checkbox } from 'antd';

export default function GCheckboxGroup({ className, colCount, style, gap = 6, ...props }: IGCheckboxGroupProps) {

    const groupClassname = cx(Styles.CheckboxComponent, Styles.CheckboxGroup, className);

    const groupStyle: React.CSSProperties = Object.assign({
        gridTemplateColumns: `repeat(${colCount}, minmax(0, 1fr))`,
        gap
    }, style);

    return (
        <Checkbox.Group
            className={groupClassname}
            style={groupStyle}
            {...props}
        />
    );
}

interface IGCheckboxGroupProps extends CheckboxGroupProps {
    colCount?: number;
    gap?: number;
    id?: string;
}