import * as am5 from '@amcharts/amcharts5';
import { _compact } from 'common/Utils';
import { Dictionary } from 'common/entities';
import { ChartHelper } from 'common/helpers';
import computeHistogram from 'compute-histogram';
import { useEffect, useRef } from 'react';
import { useUpdateEffect } from 'react-use';
import { IBaseChartProps } from '../entities';
import { IChartOptions, createChart } from './createChart';

export default function HistogramChart(props: IHistogramProps) {
    const CHART_ID = props.chartID;
    const matrixValue = props.matrixValue ?? 10;
    const chartHelperRef = useRef<ChartHelper.XYChart>();
    const xAxisTitleRef = useRef<am5.Label>();

    const chartData = getChartData(props.data, matrixValue);

    useEffect(() => {
        if (props.data && chartHelperRef.current) {
            chartHelperRef.current.setData(chartData);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.data]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            const { chartHelper, xAxisTitle } = createChart(CHART_ID, props.chartOptions);
            chartHelperRef.current = chartHelper;
            xAxisTitleRef.current = xAxisTitle;

            if (props.data) {
                chartHelperRef.current.setData(chartData);
            }
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useUpdateEffect(() => {
        xAxisTitleRef.current?.set('text', props.chartOptions.xAxis.title);
    }, [props.chartOptions?.xAxis?.title]);

    return (
        <div
            className={props.className}
            style={{ height: props.height, width: props.width }}
            id={CHART_ID}
        />
    );
}

function getChartData(data?: Array<number>, matrixValue?: number) {
    if (!data) return;
    const matrix: Array<[number, number]> = computeHistogram(_compact(data), matrixValue);
    return matrix.map(([x, y]) => ({ name: x / matrixValue, value: y }));
}

export interface IHistogramProps extends IBaseChartProps {
    data?: Array<number>;
    chartOptions?: IChartOptions;
    matrixValue?: number;
}

export interface IData extends Dictionary {
    name: string;
    value: number;
}
