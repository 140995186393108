
/*
 * Groups the elements of an array based on the given function and returns the count of elements in each group.",
 * Use `Array.prototype.map()` to map the values of an array to a function or property name.",
 * Use `Array.prototype.reduce()` to create an object, where the keys are produced from the mapped results.",
 * @param arr {T[]} here <T = any>",
 * @param fn fn: Func<T> | string",
*/

import { Dictionary, PredicateType } from "common/entities";

export function _countBy<T>(arr: T[], fn?: PredicateType<T>): Dictionary<number> {
    if (!arr) return {};

    if (!fn) {
        return arr?.reduce((acc, curr) => {
            acc[curr] = (acc[curr] || 0) + 1;
            return acc;
        }, {} as any) ?? [];
    }
    const mapper = typeof fn === "function" ? fn : (val: any) => val[fn];
    return arr?.reduce((acc, val) => {
        const value = mapper(val);
        acc[value] = (acc[value] || 0) + 1;
        return acc;
    }, {} as any) ?? [];
};