import { DeploymentStatus } from "common";

export interface IDeploymentTracking {
    time?: string,
    deploymentId: string;
    status?: DeploymentStatus;
    reason?: string;
    modelId?: string;
    preEmptiveOptions?: IPreEmptiveOptions;
    target: string;
    userId?: string;
}

export interface IDeploymentTrackingResponse {
    timestamp: string;
    deployment_id: string;
    status: DeploymentStatus;
    reason: string;
    model_id: string;
    pre_emptive_options: IPreEmptiveOptions;
    target: "remote" | "local";
    user_id: string;
}

export function GetDeploymentTrackingData(data?: IDeploymentTrackingResponse): IDeploymentTracking {
    return {
        time: data?.timestamp,
        deploymentId: data?.deployment_id,
        status: data?.status,
        reason: data?.reason,
        modelId: data?.model_id,
        preEmptiveOptions: data?.pre_emptive_options,
        target: data?.target,
        userId:data?.user_id
    };
}

export interface IPreEmptiveOptions {
    allowed_idle_time: number,
    enabled: boolean,
}

export interface IRemotePlatformStatusResponse {
    status: "healthy" | "error";
    timestamp: number;
  }