import { AxiosRequestConfig, CancelTokenSource } from "axios";
import { PostMethod } from "common/services";
import { END, eventChannel } from "redux-saga";


export function uploadFile(service: PostMethod, payload?: any, config?: AxiosRequestConfig, source?: CancelTokenSource) {
    return eventChannel(emitter => {

        config.onUploadProgress = (progressEvent) => {
            emitter({ type: "progressEvent", data: progressEvent });
        };

        service.post(payload, config).then(response => {
            emitter({ type: "response", data: response });

            emitter(END);
        }).catch(error => {
            emitter({ type: "error", data: error });
        });

        return () => {
            source?.cancel?.();
        };
    });
}