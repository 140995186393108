import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStateFactory, IAppCommonState, INotification } from "app/entities";
import { Dictionary } from "common";

export const AppSlice = createSlice({
    name: "App",
    initialState: AppStateFactory(),
    reducers: {
        updateCommonState(state, { payload }: PayloadAction<Partial<IAppCommonState>>) {
            Object.assign(state.common, payload);
        },
        setURLQuery(state, { payload }: PayloadAction<Dictionary>) {
            state.routing.urlQuery = payload;
        },
        setNotifications(state, { payload }: PayloadAction<Array<INotification>>) {
            state.notifications = payload;
        },
        updateNotifications(state, { payload }: PayloadAction<Array<INotification>>) {
            payload.forEach(n => {
                if (state.notifications.some(item => item.id === n.id)) {
                    state.notifications = state.notifications.map(item => item.id === n.id ? n : item);
                } else {
                    state.notifications.unshift(n);
                }
            });
        },
        removeNotifications(state, { payload }: PayloadAction<string>) {
            state.notifications = state.notifications.filter(item => item.id !== payload);
        },
        setRoutingSource(state, { payload }: PayloadAction<string>) {
            state.routing.source = payload;
        },
        setReportedErrorLogs(state, { payload }: PayloadAction<Array<INotification>>) {
            state.errorLogs = payload;
        },
    }
});

export const { updateCommonState, setURLQuery, updateNotifications, setNotifications, removeNotifications, setRoutingSource, setReportedErrorLogs } = AppSlice.actions;

export default AppSlice.reducer;