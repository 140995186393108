import cornerstone from 'cornerstone-core';
import cornerstoneMath from 'cornerstone-math';
import * as cornerstoneNiftiImageLoader from 'cornerstone-nifti-image-loader';
import cornerstoneTools from 'cornerstone-tools';
import cornerstoneWADOImageLoader from 'cornerstone-wado-image-loader';
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
import dicomParser from 'dicom-parser'; // Importing from dist breaks instance reference of dicomParser.DataSet class
import Hammer from 'hammerjs';

export {
    Hammer,
    cornerstone,
    cornerstoneMath,
    cornerstoneNiftiImageLoader,
    cornerstoneTools,
    cornerstoneWADOImageLoader,
    cornerstoneWebImageLoader,
    dicomParser,
};


export const defaultMeasurementColor = 'rgb(255, 255, 0)';
export const activeMeasurementColor = 'rgb(0, 255, 0)';
