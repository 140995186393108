import { IModelTrainingConfig, ModelTrainingStatus } from './IModelTraining';

export interface IModelTrainingParams {
    // datasetId: string;
    // annotationId: string;
    configId: string;
    config: Dictionary<any>;
    // backbone: string;
    model: string;
}

export interface IModelTraining {
    dataset_id: string;
    annotation_id: string;
    status: ModelTrainingStatus;
    config: IModelTrainingConfig;
    train_job_id: string;
    logs: Dictionary<ITrainingLogs>;
    name: string;
    updated: number;
    model_id: string;
    finished_timestamp: number;
    created_timestamp: number;
    favorited?: boolean;
    accessOptions?: any;
    dataset_name: string;
    annotation_name: string;
}

export interface ITrainingLogs {
    loss_train: string;
    metrics: IMetrics;
}

export interface IMetrics {
    train: Dictionary<any>;
    validation: Dictionary<any>;
}

export interface IModelTrainingConfigParams {
    epochs: number;
    loss: string;
    image_size?: Array<number>;
    batch_size: number;
    validation_split: number;
    pre_dense_layer?: number;
    metrics: Array<string>;
    height?: number;
    width?: number;
    encoder?: string;
    lr?: number;
    checkpoint_save_mode?: string;
    model_basename?: string;
    backbone_basename?: string;
    model?: string;
}

export function ModelTrainingParamsFactory(data?: Partial<IModelTrainingParams>): IModelTrainingParams {
    return {
        // datasetId: data?.datasetId ?? null,
        // annotationId: data?.annotationId ?? null,
        configId: data?.configId ?? null,
        config: data?.config ?? {},
        // backbone: data?.backbone ?? null,
        model: data?.model ?? null,
    };
}
