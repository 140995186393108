import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { IDatasetState } from 'pages/Datasets/entities';
import { AnnotationViewMode, IAnnotationModalState, IImageListState } from '../entities';

function updateAnnotationImageListState(
    state: WritableDraft<IDatasetState>,
    { payload }: PayloadAction<Partial<IImageListState>>
) {
    Object.assign(state.annotationManagement.imageListState, payload);
}

function updateAnnotationViewMode(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<AnnotationViewMode>) {
    state.annotationManagement.viewMode = payload;
}

function updateAnnotationPreviewModal(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<IAnnotationModalState>) {
    state.annotationManagement.previewModal = payload;
}

function updateSelectedAnnotationId(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<string>) {
    state.annotationManagement.selectedAnnotationId = payload;
}

function updateSelectedLabelingConfiguration(state: WritableDraft<IDatasetState>, { payload }: PayloadAction<string>) {
    state.annotationManagement.selectedLabelingConfiguration = payload;
}

export const AnnotationReducer = {
    updateAnnotationImageListState,
    updateAnnotationViewMode,
    updateAnnotationPreviewModal,
    updateSelectedAnnotationId,
    updateSelectedLabelingConfiguration,
};
