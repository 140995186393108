import cx from 'classnames';
import Styles from './Style.module.scss';

export default function DashboardCountMenu({ title, countMenu }: IDashboardCountMenuProps) {
    return (
        <div className={Styles.TitleWrapper}>
            <div className="subtitle2-bold white">{title}</div>
            <div className={Styles.CountWrapper}>
                {countMenu?.map((item: ICountMenu) => (
                    <span
                        key={item.title}
                        className={['subtitle2-medium grey', Styles.Title].join(' ')}
                    >
                        {item.title}: <span className={cx(Styles.Value, 'subtitle2-medium primary')}>{item.value}</span>
                    </span>
                ))}
            </div>
        </div>
    );
}

export interface IDashboardCountMenuProps {
    title?: React.ReactNode;
    countMenu?: ICountMenu[];
}

interface ICountMenu {
    title: string;
    value: number;
}
