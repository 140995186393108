import { PayloadAction } from '@reduxjs/toolkit';
import { _uuid, APIService, DeploymentStatus, getMessages, GetMethod, PlatformService, ServiceEnum } from 'common';
import { Notification } from 'components';
import { IModel } from 'pages/Models/entities';
import { GetDeploymentTrackingData, IDeploymentTrackingResponse } from 'pages/Models/entities/IDeploymentTracking';
import { put, select } from 'redux-saga/effects';
import { findModel, selectDeploymentList } from 'redux/selectors';
import { RootState } from 'redux/store';
import { setDeploymentTracking, setModalStopLoading, updateModelDeploymentStatus } from '..';

export function* DeployModelSaga(sagaAction: PayloadAction<Partial<IDeploymentTrackingResponse>>): any {
    try {
        const { model_id, deployment_id, pre_emptive_options, target } = sagaAction.payload;
        const model: IModel = yield select((state: RootState) => findModel(state.models.modelList, model_id));
        const params: any = { model_id, pre_emptive_options, target };
        const request_id = _uuid();
        if (model.deploymentStatus === DeploymentStatus.STOP && deployment_id) {
            params.deploy_id = deployment_id;
        }

        const response = yield PlatformService.Deployment.DeployModel.call(params, {
            headers: { 'x-request-id': request_id },
        });

        if (!response.data) return;
        const deploymentId = response.data.deploy_id || deployment_id;

        yield put(
            updateModelDeploymentStatus({ modelId: model_id, deploymentStatus: DeploymentStatus.INPROGRESS, deploymentId })
        );

        Notification.success({ content: getMessages('000394'), options: { showTime: true } });
    } catch (error) {
        console.log(error);
    }
}

export function* StopModelSaga(sagaAction: PayloadAction<Partial<IModel>>): any {
    const { deploymentId, modelId } = sagaAction.payload;

    try {
        yield put(setModalStopLoading({ [modelId]: true }));

        yield put(updateModelDeploymentStatus({ deploymentId, deploymentStatus: DeploymentStatus.INPROGRESS }));
        const request_id = _uuid();

        const StopModelService = new GetMethod(`models/stop/${deploymentId}`, null, ServiceEnum.Deployment);

        const response = yield StopModelService.call({
            headers: { 'x-request-id': request_id },
        });

        if (!response.data) return;
        yield put(updateModelDeploymentStatus({ deploymentId, deploymentStatus: DeploymentStatus.STOP }));

        Notification.success({ content: getMessages('000395'), options: { showTime: true } });
    } catch (error) {
        console.log(error);
    } finally {
        yield put(setModalStopLoading({ [modelId]: false }));
    }
}

export function* GetDeploymentTrackingSaga(): any {
    try {
        const response = yield APIService.Models.GetDeploymentTracking.call();

        if (response?.data) {
            const payload = response.data.map(GetDeploymentTrackingData);
            yield put(setDeploymentTracking(payload));
        }
    } catch (error) {
        console.log(error);
    }
}

export function* UpdateDeploymentTrackingSaga(
    deploymentResponse: IDeploymentTrackingResponse | Array<IDeploymentTrackingResponse>
): any {
    try {
        if (!deploymentResponse) return;
        if (!Array.isArray(deploymentResponse)) deploymentResponse = [deploymentResponse];
        const deploymentList = yield select(selectDeploymentList);

        for (let responseItem of deploymentResponse) {
            const payload = GetDeploymentTrackingData(responseItem);

            const newState = deploymentList.map((item: any) => (item.deploymentId === payload.deploymentId ? payload : item));

            yield put(setDeploymentTracking(newState));
        }
    } catch (error) {
        console.log(error);
    }
}
