import cx from 'classnames';
import { getMessages, isNullOrUndefined } from 'common';
import { GButton, Notification, showMessageModal } from 'components';
import { AddLine18 } from 'components/assets/icons';
import { getDatasetListTransaction } from 'pages/Datasets/store/actions';
import { getModelListTransaction } from 'pages/Models/store/actions';
import { setActiveKey } from 'pages/Project';
import { selectActiveProjectId, setActiveProjectId } from 'pages/Project/store';
import { useGetProjectListQuery } from 'pages/Project/store/ProjectService';
import { getValidationListTransaction } from 'pages/Validation/views/summary/store/actions';
import { useDispatch, useSelector } from 'react-redux';
import { selectCurrentUser } from 'redux/selectors';
import Styles from './Styles.module.scss';

export function useProjectSelection() {
    const activeProjectId = useSelector(selectActiveProjectId);
    const dispatch = useDispatch();
    const user = useSelector(selectCurrentUser);
    const { data = [] } = useGetProjectListQuery(undefined, { skip: !user?.userId });
    const menuItemList = data?.filter(item => !item.isArchived).map(item => ({ title: item.name, value: item.projectId }));

    function onChange(value: string | undefined) {
        showMessageModal({
            type: 'info',
            content: getMessages('001254'),
            cancelButton: {
                enabled: true,
            },
            okButton: {
                text: getMessages('000397'),
                color: 'primary',
            },
        }).then(() => changeProject(value));
    }

    function changeProject(value: string) {
        localStorage.setItem('activeProject', value);
        dispatch(setActiveProjectId(value));
        dispatch(getModelListTransaction());
        dispatch(getDatasetListTransaction());
        dispatch(getValidationListTransaction());

        if (isNullOrUndefined(value)) {
            localStorage.removeItem('activeProject');
            Notification.success({ content: getMessages('001245') });
            return;
        }

        Notification.success({ content: getMessages('001253') });
    }
    function renderProjectDropdown(Content: React.ReactElement) {
        return (
            <div className={Styles.ProjectDropdown}>
                {Content}
                <GButton
                    endIcon={<AddLine18 />}
                    size="small"
                    onClick={() => setActiveKey('project-create')}
                    id="navbar-project-selection"
                    className={Styles.CreateProjectButton}
                >
                    <span className="button-bold">{getMessages('000833')}</span>
                </GButton>
            </div>
        );
    }

    const renderProjectOption = (item: ISelectItem) => {
        const isActive = item.value === activeProjectId;
        return (
            <div className={Styles.SelectItem}>
                <div className={cx(Styles.Circle, { [Styles.Active]: isActive, [Styles.InActive]: !isActive })} />
                <div className="pBody-normal truncate">{item.title} </div>
            </div>
        );
    };

    return { menuItemList, onChange, activeProjectId, renderProjectOption, renderProjectDropdown };
}

interface ISelectItem {
    title: string;
    value: string;
}
