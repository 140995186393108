import { DeploymentStatus } from "common/enum";
import { RoutingHelper, ValidationActiveView } from "common/helpers";
import { useCallback } from "react";
import { useSelector } from "react-redux";
import { findModel, selectModelList } from "redux/selectors";

export function useOpenStartValidation() {
    const modelList = useSelector(selectModelList);

    function openStartValidation({ model, activeView="start-new", ...params }: IOpenStartValidationArgs) {
        const selectedModel = findModel(modelList, model);

        if (selectedModel?.deploymentStatus === DeploymentStatus.UP) {
            (params as IOpenStartValidationArgs).model = model;
        }

        RoutingHelper.Validation({ activeView:activeView, params }).navigate();
    }

    return useCallback(openStartValidation, [modelList],);

}

interface IOpenStartValidationArgs {
    model?: string,
    dataset?: string,
    activeView?: ValidationActiveView,
}