import { Dictionary } from "@reduxjs/toolkit";
import { IValidationPlotResponse } from "..";
import { formatValue } from "./payload";

export interface IPlotTopLossesResponsePayload extends IValidationPlotResponse {
    payload_dict: {
        type: "image";
        data: Array<IPlotTopLossesResponseItem>;
    };
}

export interface IPlotTopLossesResponseItem {
    image_id: string;
    image_name: string;
    rank: number;
    img_url: string;
    "Ground Truth": string;
    Prediction: string;
    Loss: number;
    Confidence: number;
    "Meta Data": Dictionary<string>;
}

export type PlotTopLossesData = Array<IPlotTopLossesItem>;

export interface IPlotTopLossesItem {
    imageId: string;
    imageName: string;
    rank: number;
    imgURL: string;
    groundTruth: string;
    prediction: string;
    loss: number;
    confidence: number;
    metaData: Dictionary<string>;
}

export function setPlotTopLossesItem(data: Array<IPlotTopLossesResponseItem>): PlotTopLossesData {
    return data?.map(item => ({
        imageId: item.image_id,
        imageName: item.image_name,
        rank: item.rank,
        imgURL: item.img_url,
        groundTruth: item["Ground Truth"],
        prediction: item.Prediction,
        loss: formatValue(item.Loss),
        confidence: formatValue(item.Confidence),
        metaData: item["Meta Data"]
    })).sort((a, b) => b.rank - a.rank);
}