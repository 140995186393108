import * as am5 from "@amcharts/amcharts5";
import * as am5radar from "@amcharts/amcharts5/radar";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark"
import { IChartHelperArgs, ICreateChartArgs, IGenerateAxesArgs } from "./entities/entities";

export class GaugeChart {
    root: am5.Root;
    chart: am5radar.RadarChart;
    data: number = 0;
    defaultTheme: am5.Theme;
    xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>;
    axisDataItem: am5.DataItem<am5xy.IValueAxisDataItem>;
    axisRange0: am5.DataItem<am5xy.IValueAxisDataItem>;
    axisRange1: am5.DataItem<am5xy.IValueAxisDataItem>;

    createRoot(args: IChartHelperArgs) {

        this.root = am5.Root.new(args.chartID);

        this.defaultTheme = am5.Theme.new(this.root);

        this.defaultTheme.rule("AxisLabel").setAll(this.defaultAxisLabelSettings);

        this.root.setThemes([
            am5themes_Animated.new(this.root),
            am5themes_Dark.new(this.root),
            this.defaultTheme
        ]);
    }

    createChart({ settings }: ICreateChartArgs<am5radar.IRadarChartSettings>, CB?: (chart?: am5radar.RadarChart) => void) {
        this.chart = this.root.container.children.push(am5radar.RadarChart.new(this.root, {
            panX: false,
            panY: false,
            startAngle: 180,
            endAngle: 360,
        }));

        this.chart.getNumberFormatter().set("numberFormat", "#'%'");

        CB?.(this.chart);

        return this;
    }

    createAxes({ xAxis }: IGenerateAxesArgs, CB?: (xAxis: am5xy.ValueAxis<am5xy.AxisRenderer>) => void) {
        var axisRenderer = am5radar.AxisRendererCircular.new(this.root, {
            innerRadius: -20,
            minGridDistance: 30,
        });

        axisRenderer.grid.template.setAll({
            stroke: this.root.interfaceColors.get("background"),
            visible: true,
            strokeOpacity: 0.8,
        });

        axisRenderer.labels.template.setAll({
            fontSize: 12,
        });

        this.xAxis = this.chart.xAxes.push(
            am5xy.ValueAxis.new(this.root, {
                maxDeviation: 0,
                min: 0,
                max: 100,
                strictMinMax: true,
                renderer: axisRenderer,
            })
        );

        this.createHand();

        CB?.(this.xAxis);

    }

    createHand() {
        const axisDataItem = this.xAxis.makeDataItem({});

        const clockHand = am5radar.ClockHand.new(this.root, {
            pinRadius: 25,
            radius: am5.percent(100),
            innerRadius: 25,
            bottomWidth: 0,
            topWidth: 0,
        });

        clockHand.pin.setAll({
            fillOpacity: 0,
            strokeOpacity: 0.5,
            stroke: am5.color("#C5CDD9"),
            strokeWidth: 1,
            strokeDasharray: [2, 2]
        });
        clockHand.hand.setAll({
            fillOpacity: 0,
            strokeOpacity: 0.5,
            stroke: am5.color("#C5CDD9"),
            strokeWidth: 0.5
        });

        var bullet = axisDataItem.set(
            "bullet",
            am5xy.AxisBullet.new(this.root, {
                sprite: clockHand
            })
        );

        this.xAxis.createAxisRange(axisDataItem);

        var label = this.chart.radarContainer.children.push(
            am5.Label.new(this.root, {
                centerX: am5.percent(50),
                textAlign: "center",
                centerY: am5.percent(50),
                fontSize: 14,
                fontWeight: "bold",
            })
        );

        axisDataItem.set("value", 0);

        bullet.get("sprite").on("rotation", function () {
            var value = axisDataItem.get("value");
            label.set("text", Math.round(value).toString() + "%");
        });

        this.chart.bulletsContainer.set("mask", undefined);

        axisDataItem.get("label").setAll({
            fontSize: 12,
        });

        this.axisRange0 = this.xAxis.createAxisRange(
            this.xAxis.makeDataItem({
                above: true,
                value: 0,
                endValue: 0
            })
        );

        this.axisRange0.get("axisFill").setAll({
            visible: true,
            fill: am5.color("#34C5F3"),
        });

        this.axisRange0.get("label").setAll({
            forceHidden: true,
        });

        this.axisRange1 = this.xAxis.createAxisRange(
            this.xAxis.makeDataItem({
                above: true,
                value: 0,
                endValue: 100
            })
        );

        this.axisRange1.get("axisFill").setAll({
            visible: true,
            fill: am5.color("#515761"),
        });

        this.axisRange1.get("label").setAll({
            forceHidden: true
        });

        this.axisDataItem = axisDataItem;
    }

    updateData(value: number) {
        this.axisDataItem.animate({
            key: "value",
            to: value,
            duration: 500,
            easing: am5.ease.out(am5.ease.cubic)
        });

        this.axisRange0.animate({
            key: "endValue",
            to: value,
            duration: 500,
            easing: am5.ease.out(am5.ease.cubic)
        });

        this.axisRange1.animate({
            key: "value",
            to: value,
            duration: 500,
            easing: am5.ease.out(am5.ease.cubic)
        });
    }

    defaultAxisLabelSettings: Partial<am5xy.IAxisLabelSettings> = {
        fill: am5.color("#fff"),
        fontSize: 12,
        fontFamily: "Noto Sans",
        fontWeight: "400",
    };
}