import { URLSearchValues, useUpdateSearchParams } from 'common';
import { _isEmpty } from 'common/Utils';
import { IImage, IImageResponse, isStudyDataset } from 'common/entities';
import { selectActiveViewport, selectViewportStateMap, updateViewportStateMap } from 'components/GDicomViewer/store';
import { DatasetService } from 'pages/Datasets/store/DatasetService';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/reduxHooks';
import { IChangeImageArgs, IViewportState } from '../../entities/';
import { useDataset, useSimpleImageList } from '../ImageState';
import { useChangeSeriesState } from './useChangeSeriesState';

export function useChangeImage() {
    const activeViewportIndex = useSelector(selectActiveViewport);
    const dataset = useDataset();
    const dispatch = useAppDispatch();
    const simpleImageList = useSimpleImageList();
    const viewportStateMap = useSelector(selectViewportStateMap);
    const changeSeriesState = useChangeSeriesState();
    const updateSearchParams = useUpdateSearchParams();

    async function changeImageState({ imageId, imageIndex, seriesId, viewportIndex = activeViewportIndex }: IChangeImageArgs) {
        if (!imageId && _isEmpty(imageIndex) && !seriesId) return;
        const updatePayload: IViewportState = { imageId };
        const _newParams: Dictionary = {};
        let imageList: IImage[] = [];

        if (seriesId) {
            changeSeriesState({ seriesId, imageId, viewportIndex });
            return;
        }

        if (imageId && _isEmpty(imageIndex)) {
            _newParams[URLSearchValues.IMAGE] = imageId;
        } else if (!_isEmpty(imageIndex) && !imageId) {
            if (isStudyDataset(dataset)) {
                const _seriesId = seriesId || viewportStateMap[viewportIndex]?.seriesId;
                if (!_seriesId) return;
                const result = dispatch(
                    DatasetService.endpoints.getImageList.initiate({
                        dataset_id: dataset?.datasetId,
                        seriesId: seriesId || viewportStateMap[viewportIndex]?.seriesId,
                    })
                );
                const { data } = await result;

                imageList = data?.data;
                result.unsubscribe?.();
            } else {
                imageList = simpleImageList;
            }
            const _nextImage = imageList?.[imageIndex]?.imageId;
            updatePayload.imageId = _nextImage;
            _newParams[URLSearchValues.IMAGE] = _nextImage;
        }

        if (!_isEmpty(_newParams) && viewportIndex === activeViewportIndex) {
            updateSearchParams(_newParams);
        }

        dispatch(updateViewportStateMap({ [viewportIndex]: updatePayload }));
    }

    return changeImageState;
}

export interface ImageIndexResponse {
    imageIndex: number;
    details: IImageResponse;
}
