import { INotification } from 'app/entities';
import { removeNotifications, setNotifications } from 'app/store';
import {
    selectActivityNotifications,
    selectAllNotifications,
    selectReportedNotifications,
    selectSystemNotifications,
    selectUnreadNotifications,
} from 'app/store/selectors';
import { APIService, UserNotificationsView } from 'common';
import { useDispatch, useSelector } from 'react-redux';
import { MarkAsReadService } from './MarkAsReadService';

export function useNotifications(type: UserNotificationsView = 'all') {
    const dispatch = useDispatch();
    const notifications = useSelector(selectAllNotifications);
    const systemNotifications = useSelector(selectSystemNotifications);
    const activityNotifications = useSelector(selectActivityNotifications);
    const reportedNotifications = useSelector(selectReportedNotifications);
    const unReadNotifications = useSelector(selectUnreadNotifications);

    const typeNotifications: Record<UserNotificationsView, Array<INotification>> = {
        all: notifications,
        activities: activityNotifications,
        systemMessages: systemNotifications,
        reportedMessages: reportedNotifications,
    };

    function markAllAsRead() {
        MarkAsReadService(unReadNotifications.map(n => n.id));
    }

    function clearNotifications() {
        dispatch(setNotifications(reportedNotifications));
        APIService.Notifications.RemoveNotifications.post({ idList: notifications.map(n => n.id) });
    }

    function removeNotification(id: string) {
        dispatch(removeNotifications(id));
        APIService.Notifications.RemoveNotifications.post({ idList: [id] });
    }

    return {
        notifications,
        markAllAsRead,
        clearNotifications,
        removeNotification,
        filteredNotifications: typeNotifications[type],
    };
}
