import { AnnotationTool, MeasurementUnit } from 'pages/AnnotationCS/entities';

export interface IMeasurementData<T = any> {
    uuid: string;
    active?: boolean;
    complete?: boolean;
    visible?: boolean;
    area?: number;
    canComplete?: boolean;
    color?: string;
    highlight?: boolean;
    invalidated?: boolean;
    unit?: MeasurementUnit;
    handles: T;
    type: AnnotationTool;
    imageId?: string;
    measurement_info?: Dictionary;
    isGuide?: boolean;
    label?: number | string;
    imageUrl?: string;
}

export interface IPolygonMeasurementData extends IMeasurementData<IPolygonHandles> {
    meanStdDev?: IMeanStdDev;
    meanStdDevSUV?: IMeanStdDev;
    polyBoundingBox?: IBoundingBox;
}

export interface ILengthMeasurementData extends IMeasurementData<ILineHandles> {
    length?: number;
}

export interface ICobbAngleMeasurementData extends IMeasurementData<ICobbAngleHandles> {
    rAngle?: number;
}

export interface IAngleMeasurementData extends IMeasurementData<IAngleHandles> {
    rAngle?: number;
}

export interface IBidirectionalMeasurementData extends IMeasurementData<IBidirectionalHandles> {
    longestDiameter?: number;
    shortestDiameter?: number;
}

export interface IRectangleRoiMeasurementData extends IMeasurementData<IRectangleHandles> {
    cachedStats?: {
        area?: number;
        mean?: number;
        stdDev?: number;
        meanStdDevSUV?: number;
        min?: number;
        max?: number;
        count?: number;
        perimeter?: number;
        variance?: number;
    };
}

export interface IRectangleHandles {
    start: IPoint;
    end: IPoint;
    textBox?: ITextBoxData;
    initialRotation?: number;
}

export interface IBidirectionalHandles {
    start: IPoint;
    end: IPoint;
    textBox: ITextBoxData;
    perpendicularStart: IPoint;
    perpendicularEnd: IPoint;
}

export interface IPolygonHandles {
    invalidHandlePlacement?: boolean;
    points: IFreeHandleData[];
    textBox: ITextBoxData;
}

export interface ITextHandles {
    end: IPoint;
}

export interface ILineHandles {
    start: IPoint;
    end: IPoint;
    textBox: ITextBoxData;
}

export interface IAngleHandles {
    start: IPoint;
    middle: IPoint;
    end: IPoint;
    textBox: ITextBoxData;
}

export interface ICobbAngleHandles {
    start: IPoint;
    start2: IPoint;
    end: IPoint;
    end2: IPoint;
    textBox: ITextBoxData;
}

export interface IPoint {
    x: number;
    y: number;
    highlight?: boolean;
    active?: boolean;
    moving?: boolean;
    clickType?: number;
}

export interface IFreeHandleData extends IPoint {
    lines?: IPoint[];
}

export interface ITextBoxData {
    active?: boolean;
    hasMoved?: boolean;
    movesIndependently?: boolean;
    drawnIndependently?: boolean;
    allowedOutsideImage?: boolean;
    hasBoundingBox?: boolean;
    x?: number;
    y?: number;
    boundingBox?: IBoundingBox;
}

export interface IBoundingBox {
    width: number;
    height: number;
    left: number;
    top: number;
}

export interface IMeanStdDev {
    mean: number;
    stdDev: number;
    count: number;
    variance: number;
}

export function CornerstonePointFactory(point: Partial<IPoint> = {}): IPoint {
    return {
        x: point.x,
        y: point.y,
        highlight: true,
        active: false,
        ...point,
    };
}

export function CornerstoneTextboxFactory() {
    return {
        active: false,
        hasMoved: false,
        movesIndependently: false,
        drawnIndependently: true,
        allowedOutsideImage: true,
        hasBoundingBox: true,
    };
}

export interface ToolDataMap {
    FreehandRoi: IPolygonMeasurementData;
    Length: ILengthMeasurementData;
    Angle: IAngleMeasurementData;
    CobbAngle: ICobbAngleMeasurementData;
    Brush: any;
    Bidirectional: any;
    RectangleRoi: any;
    SAMPointPrediction: any;
    SAMRectPrediction: any;
    BrushEraser: any;
    FreehandScissors: any;
    CorrectionScissors: any;
    AssistedSliceSelectionCross: any;
    SphericalGrowCutSegmentation: any;
}

export type ToolState = Dictionary<Dictionary<{ data: IMeasurementData<any>[] }>>;
