import { updateCommonState } from 'app/store';
import { selectIsAppVersionModalOpen } from 'app/store/selectors';
import cs from 'classnames';
import { getMessages } from 'common';
import { GModal } from 'components';
import { IGModalProps } from 'components/GModal';
import { useGetAppVersionQuery } from 'pages/Main/store/MainService';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './Styles.module.scss';

export default function AppVersionModalContainer() {
    const open = useSelector(selectIsAppVersionModalOpen);
    if (!open) return <></>;

    return <AppVersionModal />;
}

function AppVersionModal() {
    const open = useSelector(selectIsAppVersionModalOpen);
    const dispatch = useDispatch();
    const { data = '' } = useGetAppVersionQuery(undefined);

    const modalProps: IGModalProps = {
        closable: true,
        open: open,
        setVisible: () => dispatch(updateCommonState({ isAppVersionModalOpen: !open })),
        className: Styles.ModalContainer,
        footer: null,
        title: getMessages('000503'),
    };

    return (
        <>
            <GModal {...modalProps}>
                <div className={cs('pBody-medium white', Styles.VersionContainer)}>
                    {data && (
                        <div className={cs('pBody-medium white', Styles.PlatformVersion)}>
                            <div>Platform Version</div>
                            <div>v{data}</div>
                        </div>
                    )}
                    <div className={cs('pBody-medium white', Styles.PlatformVersion)}>
                        <div>UI Version</div>
                        <div>{process.env.REACT_APP_VERSION}</div>
                    </div>
                </div>
            </GModal>
        </>
    );
}
