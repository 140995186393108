import cx from 'classnames';
import { getMessages } from 'common';
import { GModal } from 'components';
import { CloseX } from 'components/assets/icons';
import { IGModalProps } from 'components/GModal';
import { useCurrentView } from 'pages/Authentication/hooks';
import { useCallback, useEffect, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import content from './content.md';
import Styles from './Styles.module.scss';

export default function TermOfUse() {
    const [open, setOpen] = useState(false);
    const [terms, setTerms] = useState<string>();
    const currentView = useCurrentView();

    useEffect(() => {
        if (!open || terms) return;
        fetch(content)
            .then(response => response.text())
            .then(text => {
                setTerms(text);
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    const handleOpenTermOfUse = useCallback(() => setOpen(true), []);

    const modalProps: IGModalProps = {
        title: getMessages('000809'),
        footer: null,
        open,
        setVisible: setOpen,
        closeIcon: <CloseX />,
        width: 770,
        destroyOnClose: true,
        className: Styles.PreviewModalContainer,
    };

    return (
        <>
            <div className={cx('pBody-normal text-center', Styles.TermOfUse)}>
                By {currentView === 'login' ? 'signing in' : 'creating an account'}, you accept the{' '}
                <span
                    className="pointer secondary caption-bold"
                    onClick={handleOpenTermOfUse}
                    id="login-terms-of-use"
                >
                    Gesund.ai Terms of Use and Sale.{' '}
                </span>
            </div>
            <GModal {...modalProps}>
                <ReactMarkdown className={cx('pBody-normal', 'white', Styles.MDContainer)}>{terms}</ReactMarkdown>
            </GModal>
        </>
    );
}
