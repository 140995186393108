import { selectRecentNotifications } from 'app/store/selectors';
import { useSelector } from 'react-redux';
import NotificationItem from './NotificationItem';

export default function RecentNotifications() {
    const recentNotifications = useSelector(selectRecentNotifications);

    return (
        <>
            {recentNotifications.map(notification => (
                <NotificationItem
                    item={notification}
                    key={notification._id}
                />
            ))}
        </>
    );
}
