import { ChartHelper } from 'common/helpers';
import { AxesType, SeriesType } from 'common/helpers/ChartHelper/entities/enums';
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";

export function createChart(chartID: string, chartOptions?: IChartOptions) {
    const { lightTheme } = chartOptions
    const chartHelper = new ChartHelper.XYChart();

    chartHelper.createRoot({ chartID });

    chartHelper
        .createChart({

        })
        .createAxes({
            xAxis: {
                type: AxesType.category,
                rendererSetting: {
                    visible: false,
                    minGridDistance: 20,
                    inversed: true,
                },
                categoryAxisOptions: {
                    maxDeviation: 0.3,
                    categoryField: chartOptions?.xAxis?.categoryField,
                }
            },
            yAxis: {
                type: AxesType.category, rendererSetting: {
                    visible: false,
                    minGridDistance: 20,
                    inversed: true,
                },
                categoryAxisOptions: {
                    maxDeviation: 0.3,
                    categoryField: chartOptions?.yAxis?.categoryField,
                },
            }
        }, (xAxis: am5xy.CategoryAxis<am5xy.AxisRenderer>, yAxis: am5xy.ValueAxis<am5xy.AxisRenderer>) => {

            yAxis.get("renderer").grid.template.setAll({
                visible: false,
            });

            xAxis.get("renderer").grid.template.setAll({
                visible: false,
            });

            xAxis.get("renderer").labels.template.setAll({
                fill: am5.color("#fff")
            });

            yAxis.get("renderer").labels.template.setAll({
                fill: am5.color("#fff")
            });

            xAxis.children.push(
                am5.Label.new(chartHelper.root, {
                    text: chartOptions?.xAxis?.title,
                    x: am5.p50,
                    centerX: am5.p50,
                    fill: lightTheme ? am5.color("#000") : am5.color("#fff"),
                })
            );

            yAxis.children.unshift(
                am5.Label.new(chartHelper.root, {
                    rotation: -90,
                    text: chartOptions?.yAxis?.title,
                    y: am5.p50,
                    centerX: am5.p50,
                    fill: lightTheme ? am5.color("#000") : am5.color("#fff"),
                })
            );
        });

    chartHelper.createSeries({
        type: SeriesType.ColumnSeries,
        seriesSettings: {
            calculateAggregates: true,
            clustered: false,
            categoryXField: chartOptions?.xAxis?.categoryField,
            categoryYField: chartOptions?.yAxis?.categoryField,
            valueField: "value",
            name: chartID,
        },
    }, (series: am5xy.ColumnSeries) => {

        series.columns.template.setAll({
            tooltipText: "",
            strokeOpacity: 0,
            strokeWidth: 0,
            width: am5.percent(100),
            height: am5.percent(100),
            templateField: "columnSettings"
        });

        const heatLegend = chartHelper.chart.rightAxesContainer.children.push(am5.HeatLegend.new(chartHelper.root, {
            orientation: "vertical",
            endColor: am5.color("#B5FFEA"),
            startColor: am5.color("#B033C3"),
        }));

        series.events.on("datavalidated", function () {
            heatLegend.set("startValue", series.getPrivate("valueHigh"));
            heatLegend.set("endValue", series.getPrivate("valueLow"));
        });
        series.columns.template.events.on("pointerover", function (event) {
            var di = event.target.dataItem;
            if (di) {
                heatLegend.showValue(di.get("value" as any, 0));
            }
        });
        series.set("heatRules", [{
            target: series.columns.template,
            min: am5.color("#B5FFEA"),
            max: am5.color("#B033C3"),
            dataField: "value",
            key: "fill"
        }]);

        heatLegend.startLabel.setAll({
            fontSize: 12,
            fill: lightTheme ? am5.color("#000") : am5.color("#fff"),
        });

        heatLegend.endLabel.setAll({
            fontSize: 12,
            fill: lightTheme ? am5.color("#000") : am5.color("#fff"),
        });

        if (chartOptions?.valueBulletEnabled) {
            series.bullets.push(function () {
                return am5.Bullet.new(chartHelper.root, {
                    // locationY: 1,
                    sprite: am5.Label.new(chartHelper.root, {
                        text: "{value}",
                        fill: am5.color("#000"),
                        centerX: am5.p50,
                        centerY: am5.p50,
                        populateText: true,
                        fontFamily: "Noto Sans",
                        fontSize: "16px",
                        fontWeight: "bold",
                    })
                });
            });
        }
    });

    return chartHelper;
}

export interface IChartOptions {
    valueBulletEnabled?: boolean;
    xAxis?: {
        categoryField?: string;
        title?: string;
    },
    yAxis?: {
        categoryField?: string;
        title?: string;
    };
    lightTheme?: boolean;
}