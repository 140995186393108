import { Image } from 'antd';
import cx from 'classnames';
import { Dictionary } from 'common/entities';
import { useCoverImage } from 'common/hooks/useCoverImage';
import { _isEmpty } from 'common/Utils';
import ModelCardImage from 'components/assets/images/ModelCardImage.png';
import React from 'react';
import { Link } from 'react-router-dom';
import DetailsMenu, { IDetailsMenuProps } from '../DetailsMenu';
import TagList from '../TagList';
import StatsTags, { IStats } from '../TagList/StatsTags';
import Styles from './Styles.module.scss';
import FrameworkLogo from 'pages/Models/components/FrameworkLogo';

export default function DashboardListItem({
    caption,
    description,
    imageSrc,
    link,
    datasetId,
    detailsMenuProps,
    id,
    statsList,
    extraTagField,
    navCb,
    framework,
    sourceName,
    infoTagList,
}: IProps) {
    const { image } = useCoverImage({ src: imageSrc, datasetId });

    return (
        <>
            <Link
                to={link}
                id={id}
                className={Styles.ListItemContainer}
                onClick={() => navCb(id)}
            >
                <div className={Styles.ImageWrapper}>
                    <Image
                        preview={false}
                        className={Styles.image}
                        src={image}
                        alt="datasets"
                        fallback={ModelCardImage}
                        placeholder={ModelCardImage}
                    />
                </div>

                <div className={Styles.Details}>
                    <div className={Styles.DetailsHeader}>
                        {caption}
                        {!_isEmpty(detailsMenuProps?.menu) && (
                            <div className={Styles.DetailsMenu}>
                                <DetailsMenu {...detailsMenuProps} />
                            </div>
                        )}
                    </div>
                    <div className={Styles.ModelSource}>
                        {sourceName}
                        <FrameworkLogo framework={framework} />
                    </div>

                    <div className={cx(Styles.Description, 'pBody-normal')}>{description}</div>

                    <div className={Styles.TagRow}>
                        <div className={Styles.TagRowLeftSection}>
                            {extraTagField ? extraTagField : null}
                            {infoTagList ? <TagList tagList={infoTagList} /> : null}
                        </div>
                        <div className={Styles.TagRowRightSection}>
                            <StatsTags
                                statsList={statsList}
                                className={Styles.StatsTags}
                            />
                        </div>
                    </div>
                </div>
            </Link>
        </>
    );
}

interface IProps<T = any> {
    link: string;
    id?: string;
    imageSrc: string;
    datasetId?: string;
    accessStatus?: 'public' | 'private';
    item: T;
    detailsMenuProps?: IDetailsMenuProps;
    caption: React.ReactNode;
    description: React.ReactNode;
    extraTagField?: React.ReactNode;
    infoTagList?: Dictionary<string | Array<string>>;
    tagList?: Array<string | Array<string>>;
    statsList?: Array<IStats>;
    navCb?: (id: string) => void;
    framework?: string;
    sourceName?: React.ReactNode;
}
