import { call } from '@redux-saga/core/effects';
import { AuthService, getMessages } from 'common';
import { Notification } from 'components';
import { userLoginActionSaga } from './LoginActionSaga';

export function* userNewPasswordTransactionSaga(sagaAction: any): any {
    try {
        const payload = { ...sagaAction.payload, origin: window.location.origin };

        const { data } = yield AuthService.NewPassword.call(payload);

        Notification.success({ content: getMessages('000230') });

        yield call(userLoginActionSaga, data, true);
    } catch (e) {
        console.log(e);
    }
}
