import { getMessages } from 'common';
import { Notification } from 'components';
import { ModelTrainingStatus } from 'pages/Models/entities/IModelTraining';
import { IModelTraining } from 'pages/Models/entities/IModelTrainingParams';
import { put } from 'redux-saga/effects';
import { extendedApiSlice } from '../ModelService';

export function* UpdateModelTrainingSaga(data: IModelTraining | Array<IModelTraining>): any {
    try {
        if (!data) return;
        yield put(
            extendedApiSlice.util.updateQueryData('getModelTrainingList', undefined, (draft = []) => {
                if (!Array.isArray(data)) data = [data];
                for (const item of data) {
                    const updatedTrainJob = draft?.find(v => v.train_job_id === item.train_job_id);

                    if (!updatedTrainJob) {
                        Notification.success({
                            content: getMessages('001475').replace('$', item.name),
                            options: { toastId: item.train_job_id },
                        });
                        draft = [item, ...draft];
                    } else {
                        draft = draft.map(v => (v.train_job_id === item.train_job_id ? item : v));
                    }

                    if (updatedTrainJob?.status !== ModelTrainingStatus.done && item.status === ModelTrainingStatus.done) {
                        Notification.success({
                            content: getMessages('001474').replace('$', item.name),
                            options: { toastId: item.train_job_id },
                        });
                    }
                }

                return draft;
            }) as any
        );
    } catch (error) {
        console.log(error);
    }
}
