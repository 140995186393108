import { GalleryStateProvider, useViewState } from './GalleryState';
import ListView from './ListView';
import SerieView from './SerieView';
import ViewNavigation from './ViewNavigation';
import { IGalleryProps } from './interface';

export function GalleryStateless(props: IGalleryProps) {
    const [view] = useViewState();

    switch (view) {
        case 'series':
            return <SerieView {...props} />;

        case 'image':
        default:
            return <ListView {...props} />;
    }
}

export function Gallery(props: IGalleryProps) {
    return (
        <GalleryStateProvider defaultView={props.seriesList?.length ? 'series' : 'image'}>
            <ViewNavigation {...props} />
            <GalleryStateless {...props} />
        </GalleryStateProvider>
    );
}
