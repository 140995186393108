import { _findBy } from "common";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { IProject } from "../entities";
import { useGetProjectListQuery } from "../store/ProjectService";

export function useSelectSelectedProject(): IProject {
    const { projectId } = useParams();
    const { data = [] } = useGetProjectListQuery(undefined);
    return useMemo(() => _findBy(data, "projectId", projectId), [data, projectId]);
}