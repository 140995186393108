import { ImageViewerHelper } from 'common/helpers/ImageViewerHelper';
import { useImageViewer, useInitImageViewer } from 'common/helpers/ImageViewerHelper/hooks';
import { useEffect, WheelEventHandler } from 'react';
import Styles from './Styles.module.scss';

export function GImageViewer({ image, onWheel }: IGImageEditorProps) {
    const imageViewer = useImageViewer();
    const initImageViewer = useInitImageViewer();

    useEffect(() => {
        initImageViewer();

        return () => {
            imageViewer?.destroy();
        };
    }, []);

    useEffect(() => {
        imageViewer?.loadImage?.(image);
    }, [image, imageViewer]);

    return (
        <div
            className={Styles.ImageViewerContainer}
            onWheel={onWheel}
        >
            <div
                className={Styles.ImageViewer}
                id={ImageViewerHelper.viewerId}
            />
        </div>
    );
}

interface IGImageEditorProps {
    image?: string;
    onWheel?: WheelEventHandler<HTMLDivElement>;
}
