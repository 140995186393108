import { ChartHelper } from 'common/helpers';
import { isNullOrUndefined } from 'common/Utils';
import { SeriesType } from 'common/helpers/ChartHelper/entities/enums';
import * as am5 from "@amcharts/amcharts5";

export function createChart(chartID: string, chartOptions?: IChartOptions) {
    const { lightTheme } = chartOptions
    const chartHelper = new ChartHelper.PieChart();

    chartHelper.createRoot({ chartID, lightTheme });

    chartHelper
        .createChart({
            settings: {
                layout: getLayout(chartOptions?.chart?.layout, chartHelper, "horizontal"),
                innerRadius: am5.percent(50),
            },
        })
        .createSeries({
            type: SeriesType.PieSeries,
            seriesSettings: {
                valueField: "value",
                categoryField: "name",
                alignLabels: false,
                legendValueText: "{value}",
            },
            legend: {
                enabled: true,
                legendSettings: {
                    layout: getLayout(chartOptions?.legend?.layout, chartHelper, "vertical"),
                    centerY: am5.percent(50),
                    centerX: am5.percent(50),
                    y: isNullOrUndefined(chartOptions?.legend?.y) ? am5.percent(50) : am5.percent(chartOptions?.legend?.y),
                    x: isNullOrUndefined(chartOptions?.legend?.x) ? undefined : am5.percent(chartOptions?.legend?.x),
                }
            },
        }, series => {

            if (!chartOptions?.series?.labels?.visible) {
                series.ticks.template.setAll({
                    forceHidden: true
                });

                series.labels.template.setAll({
                    visible: false,
                    text: ""
                });
            } else {
                series.ticks.template.setAll({
                    stroke: am5.color("#ffffff"),
                });

                series.labels.template.setAll({
                    textType: "circular",
                    centerX: 0,
                    centerY: 0,
                    maxWidth: 100,
                    oversizedBehavior: "wrap",
                });
            }

            if (chartOptions?.centerLabel?.enabled) {

                chartHelper.centerLabel = chartHelper.chart.seriesContainer.children.push(
                    am5.Label.new(chartHelper.root, {
                        fill: chartOptions?.lightTheme ? am5.color('#000') : am5.color('#fff'),
                        textAlign: 'center',
                        centerY: am5.p50,
                        centerX: am5.p50,
                        text: ``,
                    })
                );
            }

        });

    return chartHelper;
}

function getLayout(layout: TLayout, chartHelper: ChartHelper.PieChart, defaultLayout?: TLayout): am5.Layout {
    switch (layout) {
        case "grid":
            return chartHelper.root.gridLayout;

        case "horizontal":
            return chartHelper.root.horizontalLayout;

        case "vertical":
            return chartHelper.root.verticalLayout;
        default:
            return getLayout(defaultLayout, chartHelper);
    }
}

export interface IChartOptions {
    centerLabel?: {
        enabled: boolean,
        text?: string;
        title?: string;
    };
    chart?: {
        layout?: TLayout;
    };
    legend?: {
        layout?: TLayout;
        x?: number;
        y?: number;
    };
    series?: {
        labels?: {
            visible?: boolean;
        };
    };
    lightTheme?: boolean;
}

export type TLayout = "vertical" | "horizontal" | "grid";