import { INotification } from 'app/entities';
import { AxiosResponse } from 'axios';
import { APIService } from 'common/services/serviceList';
import { put } from 'redux-saga/effects';
import { setNotifications, setReportedErrorLogs, updateNotifications } from '..';

export function* getInitialNotifications(): any {
    try {
        const response: AxiosResponse<{ notifications: Array<INotification> }> =
            yield APIService.Notifications.GetUserNotifications.call();

        if (!response?.data) return;

        yield put(setNotifications(response.data.notifications.reverse()));
    } catch (error) {
        console.error(error);
    }
}

export function* getReportedErrorLogs(): any {
    try {
        const response: AxiosResponse<{ notifications: Array<INotification> }> =
            yield APIService.Notifications.GetReportedErrorLogs.call();

        if (!response?.data) return;

        yield put(setReportedErrorLogs(response.data.notifications));
    } catch (error) {
        console.error(error);
    }
}

export function* updateNotificationsSaga(payload: INotification | Array<INotification>): any {
    try {
        if (!payload) return;
        if (!Array.isArray(payload)) payload = [payload];

        for (const payloadItem of payload) {
            payloadItem.id = payloadItem?._id;
        }

        yield put(updateNotifications(payload));
    } catch (error) {
        console.error(error);
    }
}
