import { Dictionary, ProblemType } from "common";

export interface IViewState {
    viewList: Dictionary<Dictionary<Array<IViewItem>>>;
    viewModalVisible: boolean;
    viewEditable: boolean;
    hidedChartList: Array<string>;
}

export interface IViewItem {
    name: string,
    index: number,
    visible?: boolean,
    editable?: boolean,
}

export function ViewStateFactory(data?: Partial<IViewState>): any {
    return {
        viewList: data?.viewList ?? defaultViewList,
        viewModalVisible: data?.viewModalVisible ?? false,
        viewEditable: data?.viewEditable ?? false,
        hidedChartList: data?.hidedChartList ?? []
    };
}

export const defaultViewList: Dictionary<Dictionary<Array<IViewItem>>> = {
    [ProblemType.classification]: {
        validationResult: [
            {
                name: 'pdfHeader',
                index: -2,
                visible: true,
                editable: false,
            },
            {
                name: 'pdfDetails',
                index: -1,
                visible: true,
                editable: false,
            },
            {
                name: 'highlightedMetrics',
                index: 0,
                visible: true,
                editable: false,
            },
            {
                name: 'basicModelStats',
                index: 1,
                visible: true,
                editable: true,
            },
            {
                name: 'plotTopLosses',
                index: 2,
                visible: true,
                editable: true,
            },
            {
                name: 'confusionMatrix',
                index: 3,
                visible: true,
                editable: true,
            },
            {
                name: 'predictionGTDistribution',
                index: 4,
                visible: true,
                editable: true,
            },
            {
                name: 'rocCurve',
                index: 5,
                visible: true,
                editable: true,
            },
            {
                name: 'precisionRecallCurve',
                index: 6,
                visible: true,
                editable: true,
            },
            {
                name: 'performanceByConfidence',
                index: 7,
                visible: true,
                editable: true,
            },
            {
                name: 'confidenceDistribution',
                index: 8,
                visible: true,
                editable: true,
            },
            {
                name: 'classPerformance',
                index: 9,
                visible: true,
                editable: true,
            },
            {
                name: 'trainingVsValidationPerformance',
                index: 10,
                visible: true,
                editable: true,
            },
            {
                name: 'populationDistribution',
                index: 11,
                visible: true,
                editable: true,
            },
            {
                name: 'mostConfusedClasses',
                index: 12,
                visible: true,
                editable: true,
            },
            {
                name: 'liftChart',
                index: 13,
                visible: true,
                editable: true,
            },
            {
                name: 'violinChart',
                index: 14,
                visible: true,
                editable: true,
            },
            {
                name: 'footer',
                index: 15,
                visible: true,
                editable: true,
            },
        ],
        batchPrediction: [
            {
                name: 'pdfHeader',
                index: -2,
                visible: true,
                editable: false,
            },
            {
                name: 'pdfDetails',
                index: -1,
                visible: true,
                editable: false,
            },
            {
                name: 'confidenceDistributionGTless',
                index: 0,
                visible: true,
                editable: true,
            },
            {
                name: 'explorePredictions',
                index: 1,
                visible: true,
                editable: true,
            },
            {
                name: 'predictionDistribution',
                index: 2,
                visible: true,
                editable: true,
            },
            {
                name: 'footer',
                index: 3,
                visible: true,
                editable: true,
            },
        ],
    },
    [ProblemType.segmentation]: {
        validationResult: [
            {
                name: 'pdfHeader',
                index: -2,
                visible: true,
                editable: false,
            },
            {
                name: 'pdfDetails',
                index: -1,
                visible: true,
                editable: false,
            },
            {
                name: 'highlightedMetrics',
                index: 0,
                visible: true,
                editable: false,
            },
            {
                name: 'basicModelStats',
                index: 1,
                visible: true,
                editable: true,
            },
            {
                name: 'plotTopMisses',
                index: 2,
                visible: true,
                editable: true,
            },
            {
                name: 'detailedMetrics',
                index: 3,
                visible: true,
                editable: true,
            },
            {
                name: 'intersectionDistribution',
                index: 4,
                visible: true,
                editable: true,
            },
            {
                name: 'populationDistribution',
                index: 5,
                visible: true,
                editable: true,
            },
            {
                name: 'violinChart',
                index: 6,
                visible: true,
                editable: true,
            },
            {
                name: 'footer',
                index: 7,
                visible: true,
                editable: true,
            },
        ],
    },

    [ProblemType.object_detection]: {
        validationResult: [
            {
                name: 'pdfHeader',
                index: -2,
                visible: true,
                editable: false,
            },
            {
                name: 'pdfDetails',
                index: -1,
                visible: true,
                editable: false,
            },
            {
                name: 'highlightedMetrics',
                index: 0,
                visible: true,
                editable: false,
            },
            {
                name: 'basicModelStats',
                index: 1,
                visible: true,
                editable: true,
            },
            {
                name: 'plotTopMisses',
                index: 2,
                visible: true,
                editable: true,
            },
            {
                name: 'performanceByIoUThreshold',
                index: 3,
                visible: true,
                editable: true,
            },
            {
                name: 'confidenceDistribution',
                index: 4,
                visible: true,
                editable: true,
            },
            {
                name: 'predictionDistribution',
                index: 5,
                visible: true,
                editable: true,
            },
            {
                name: 'objectCounts',
                index: 6,
                visible: true,
                editable: true,
            },
            {
                name: 'populationDistribution',
                index: 7,
                visible: true,
                editable: true,
            },
            {
                name: 'trainingVsValidationPerformance',
                index: 8,
                visible: true,
                editable: true,
            },
            {
                name: 'violinChart',
                index: 9,
                visible: true,
                editable: true,
            },
            {
                name: 'footer',
                index: 10,
                visible: true,
                editable: true,
            },
        ],
    },
    [ProblemType.instance_segmentation]: {
        validationResult: [
            {
                name: 'pdfHeader',
                index: -2,
                visible: true,
                editable: false,
            },
            {
                name: 'pdfDetails',
                index: -1,
                visible: true,
                editable: false,
            },
            {
                name: 'highlightedMetrics',
                index: 0,
                visible: true,
                editable: false,
            },
            {
                name: 'basicModelStats',
                index: 1,
                visible: true,
                editable: true,
            },
            {
                name: 'plotTopMisses',
                index: 2,
                visible: true,
                editable: true,
            },
            {
                name: 'performanceByIoUThreshold',
                index: 3,
                visible: true,
                editable: true,
            },
            {
                name: 'confidenceDistribution',
                index: 4,
                visible: true,
                editable: true,
            },
            {
                name: 'predictionDistribution',
                index: 5,
                visible: true,
                editable: true,
            },
            {
                name: 'objectCounts',
                index: 6,
                visible: true,
                editable: true,
            },
            {
                name: 'populationDistribution',
                index: 7,
                visible: true,
                editable: true,
            },
            {
                name: 'trainingVsValidationPerformance',
                index: 8,
                visible: true,
                editable: true,
            },
            {
                name: 'violinChart',
                index: 9,
                visible: true,
                editable: true,
            },
            {
                name: 'footer',
                index: 10,
                visible: true,
                editable: true,
            },
        ],
    },
    [ProblemType.semantic_segmentation]: {
        validationResult: [
            {
                name: 'pdfHeader',
                index: -2,
                visible: true,
                editable: false,
            },
            {
                name: 'pdfDetails',
                index: -1,
                visible: true,
                editable: false,
            },
            {
                name: 'highlightedMetrics',
                index: 0,
                visible: true,
                editable: false,
            },
            {
                name: 'basicModelStats',
                index: 1,
                visible: true,
                editable: true,
            },
            {
                name: 'plotTopMisses',
                index: 2,
                visible: true,
                editable: true,
            },
            {
                name: 'detailedMetrics',
                index: 3,
                visible: true,
                editable: true,
            },
            {
                name: 'intersectionDistribution',
                index: 4,
                visible: true,
                editable: true,
            },
            {
                name: 'populationDistribution',
                index: 5,
                visible: true,
                editable: true,
            },
            {
                name: 'violinChart',
                index: 6,
                visible: true,
                editable: true,
            },
            {
                name: 'footer',
                index: 7,
                visible: true,
                editable: true,
            },
        ],
    },
};