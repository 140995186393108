import { createSlice, Dictionary, PayloadAction } from '@reduxjs/toolkit';
import { _entries } from 'common';
import { IDeploymentModalState, IModel, IModelCommonState, ModelStateFactory } from '../entities';
import { IDeploymentTracking } from '../entities/IDeploymentTracking';
import { IModelFiltering } from '../entities/IModelFiltering';
import { IModelTrainingConfigParams } from '../entities/IModelTrainingParams';
import { IVendor } from '../entities/IVendor';
import { IModelArtifactProps, IModelFiles } from '../views/ModelUpload/entities';
import { IEditTrainJobModalStatus } from '../entities/IModelTraining';
import { IPredictionExplorerState } from '../entities/IPredictionExplorer';

export const ModelSlice = createSlice({
    name: 'Model',
    initialState: ModelStateFactory(),
    reducers: {
        setModelList(state, action: PayloadAction<Array<IModel>>) {
            state.modelList = action.payload;
        },
        updateModelList(state, { payload }: PayloadAction<IModel>) {
            state.modelList = state.modelList.map(model => (model.modelId === payload.modelId ? payload : model));
        },
        updateModelArtifactProps(state, action: PayloadAction<Partial<IModelArtifactProps>>) {
            Object.assign(state.modelUpladState.modelArtifactProps, action.payload);
        },
        updateModelFiles(state, { payload }: PayloadAction<Partial<IModelFiles>>) {
            state.modelUpladState.modelFiles.model_id = payload?.model_id;
            state.modelUpladState.modelFiles.model_path = payload?.model_path;
            state.modelUpladState.modelFiles.dockerhub = payload?.dockerhub;
        },
        updateModel(state, { payload }: PayloadAction<IModel>) {
            state.modelList = state.modelList.map(model => (model.modelId === payload.modelId ? payload : model));
        },
        setVendorList(state, { payload }: PayloadAction<Array<IVendor>>) {
            state.vendorList = payload;
        },
        updateModelFilters(state, { payload }: PayloadAction<Partial<IModelFiltering>>) {
            Object.assign(state.common.modelFilters, payload);
        },
        updateModelDeploymentStatus(state, { payload }: PayloadAction<Partial<IModel>>) {
            state.modelList = state.modelList.map(model =>
                model.modelId === payload.modelId
                    ? { ...model, deploymentStatus: payload.deploymentStatus, deploymentId: payload.deploymentId }
                    : model
            );
        },
        setDeploymentTracking(state, { payload }: PayloadAction<Array<IDeploymentTracking>>) {
            state.deploymentList = payload;
        },
        setArchivedModels(state, { payload }: PayloadAction<Array<IModel>>) {
            state.archivedModelList = payload;
        },
        setModalStopLoading(state, { payload }: PayloadAction<Dictionary<boolean>>) {
            Object.assign(state.common.modelStopLoading, payload);
        },
        setDeploymentModalState(state, { payload }: PayloadAction<Partial<IDeploymentModalState>>) {
            Object.assign(state.common.deploymentModalState, payload);
        },
        updateModelCommonState(state, { payload }: PayloadAction<Partial<IModelCommonState>>) {
            Object.assign(state.common, payload);
        },
        updateModelOutputExample(state, { payload }: PayloadAction<Dictionary<any>>) {
            Object.assign(state.common.modelOutputExampleSchema, payload);
        },
        updateModelTrainingState(state, { payload }: PayloadAction<Dictionary<any>>) {
            Object.assign(state.modelTrainingState, payload);
        },
        updateModelTrainingConfigId(state, { payload }: PayloadAction<any>) {
            state.modelTrainingState.modelTrainingParams.configId = payload;
        },
        // updateModelTrainingBackbone(state, { payload }: PayloadAction<any>) {
        //     state.modelTrainingState.modelTrainingParams.backbone = payload;
        // },
        updateModelTrainingParams(state, { payload }: PayloadAction<any>) {
            Object.assign(state.modelTrainingState.modelTrainingParams, payload);
        },
        resetModelTrainingConfigState(state, { payload }: PayloadAction<any>) {
            state.modelTrainingState.modelTrainingParams.config = payload;
        },
        updateModelTrainingConfig(state, { payload }: PayloadAction<Dictionary<any>>) {
            _entries(payload).forEach(([key, value]: [keyof IModelTrainingConfigParams, never]) => {
                state.modelTrainingState.modelTrainingParams.config[key] = value;
            });
        },
        setIsEditModalOpen(state, { payload }: PayloadAction<IEditTrainJobModalStatus>) {
            state.modelTrainingState.isEditModalOpen = payload;
        },
        updatePredictionExplorerState(state, { payload }: PayloadAction<Partial<IPredictionExplorerState>>) {
            Object.assign(state.predictionExplorerState, payload);
        },
    },
});

export const {
    setModelList,
    updateModelList,
    updateModelArtifactProps,
    updateModelFiles,
    setVendorList,
    updateModelFilters,
    updateModelDeploymentStatus,
    setDeploymentTracking,
    setArchivedModels,
    setModalStopLoading,
    setDeploymentModalState,
    updateModelCommonState,
    updateModelOutputExample,
    updateModel,
    updateModelTrainingConfig,
    updateModelTrainingState,
    updateModelTrainingConfigId,
    resetModelTrainingConfigState,
    setIsEditModalOpen,
    updatePredictionExplorerState,
    // updateModelTrainingBackbone,
    updateModelTrainingParams,
} = ModelSlice.actions;

export default ModelSlice.reducer;
