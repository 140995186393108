import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider as ReduxProvider } from 'react-redux';
import store from 'redux/store';
import RouterProvider from './RouterProvider';
import { MatomoProvider } from './hooks/useMatomo';

export default function AppProvider() {
    return (
        <>
            <ReduxProvider store={store}>
                <DndProvider backend={HTML5Backend}>
                    <MatomoProvider>
                        <RouterProvider />
                    </MatomoProvider>
                </DndProvider>
            </ReduxProvider>
        </>
    );
}
