import { URLSearchValues, useUpdateSearchParams } from 'common';
import { selectActiveViewport, updateAllViewportState, updateViewportStateMap } from 'components/GDicomViewer/store';
import { DatasetService } from 'pages/Datasets/store/DatasetService';
import { useSelector } from 'react-redux';
import { useAppDispatch } from 'redux/reduxHooks';
import { useViewportElementMap } from '../DicomViewerState';
import { useDataset } from '../ImageState';

export function useChangeSeriesState() {
    const activeViewportIndex = useSelector(selectActiveViewport);
    const dataset = useDataset();
    const dispatch = useAppDispatch();
    const [viewportElementMap] = useViewportElementMap();
    const updateSearchParams = useUpdateSearchParams();

    return async function changeSeriesState({
        changeAllViewports,
        imageId,
        seriesId,
        viewportIndex = activeViewportIndex,
    }: Args) {
        if (!seriesId) return;
        const viewportElement = viewportElementMap[viewportIndex];
        if (viewportElement) viewportElement.dataset.seriesId = seriesId;

        if (changeAllViewports) {
            dispatch(updateAllViewportState({ loading: true }));
        } else {
            dispatch(updateViewportStateMap({ [viewportIndex]: { loading: true } }));
        }

        const result = dispatch(
            DatasetService.endpoints.getImageList.initiate({
                dataset_id: dataset?.datasetId,
                seriesId,
            })
        );
        const { data } = await result;

        if (!imageId) {
            imageId = data?.data?.[Math.floor(data?.data?.length / 2)]?.imageId;
        }
        result.unsubscribe?.();

        if (viewportIndex === activeViewportIndex) {
            updateSearchParams({
                [URLSearchValues.SERIES]: seriesId,
                [URLSearchValues.IMAGE]: imageId,
            });
        }

        const updatePayload = {
            seriesId,
            imageId,
            loading: false,
        };
        if (changeAllViewports) {
            dispatch(updateAllViewportState(updatePayload));
        } else {
            dispatch(updateViewportStateMap({ [viewportIndex]: updatePayload }));
        }
    };
}

interface Args {
    seriesId: string;
    imageId?: string;
    changeAllViewports?: boolean;
    viewportIndex?: number;
}
