import { _uuid } from 'common/Utils';
import { AssignmentRole } from 'pages/AnnotationCS/entities/IAnnotationTracking';
import { IToolState, PreAnnotationState } from './interfaces';

export function ToolStateFactory(data?: Partial<IToolState>): IToolState {
    return {
        isSaveStudyModalOpen: data?.isSaveStudyModalOpen ?? false,
        isStatusInfoModalOpen: data?.isStatusInfoModalOpen ?? false,
        studySessionId: data?.studySessionId || _uuid(),
        studySessionStartTime: data?.studySessionStartTime || null,
        isStudyTableModalOpen: data?.isStudyTableModalOpen ?? false,
        currentRole: data?.currentRole || AssignmentRole.Annotator,
        currentReader: data?.currentReader || '',
        isAnnotationChanged: data?.isAnnotationChanged || false,
        activeShapeType: data?.activeShapeType || null,
        preAnnotationState: data?.preAnnotationState || PreAnnotationStateFactory(),
    };
}

export function PreAnnotationStateFactory(): PreAnnotationState {
    return {
        active: false,
        modelId: null,
        modalOpen: false,
        labelSelection: {},
        preserve: false,
        editModeAlways: false,
        editMode: false,
    };
}
