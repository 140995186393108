import { PayloadAction } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { AuthService, getMessages } from 'common';
import { Notification } from 'components';
import { ValidationAPISlice } from 'pages/Validation/store';
import { IValidationRun } from 'pages/Validation/views/summary/entities';
import { updateValidationListSaga } from 'pages/Validation/views/summary/store/saga/ValidationDataSaga';
import { fork, put, select } from 'redux-saga/effects';
import { selectValidationData } from 'redux/selectors';

export function* favoriteValidationItemSaga(sagaAction: PayloadAction<any>): any {
    const { batch_job_id, unfavorite } = sagaAction.payload;

    try {
        const { data }: AxiosResponse<IValidationRun> = yield AuthService.FavoriteValidation.call({ batch_job_id, unfavorite });
        if (data) {
            const validation: IValidationRun = yield select(selectValidationData(batch_job_id));

            yield fork(updateValidationListSaga, { ...validation, favorited: !unfavorite });
        } else {
            yield put(ValidationAPISlice.endpoints.getStudyValidations.initiate(undefined, { forceRefetch: true }) as any);
        }
        Notification.success({ content: getMessages(unfavorite ? '001392' : '001395'), options: { toastId: batch_job_id } });
    } catch (error) {
        console.log(error);
    }
}
