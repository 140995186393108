export interface IImagePreviewControls {
    selectedImageId: string,
    previewVisible: boolean;
    activeSection: "plotTopLosses" | "mostConfusedImages" | "explorePredictions",
    navigationEnabled: boolean;
}

export function IIMagePreviewControlsFactory(data?: IImagePreviewControls): IImagePreviewControls {
    return {
        selectedImageId: data?.selectedImageId || null,
        previewVisible: data?.previewVisible || false,
        activeSection: null,
        navigationEnabled: data?.navigationEnabled || true
    };

}
