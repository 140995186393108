import { _values } from 'common';
import { IDataset, isStudyDataset } from 'common/entities';
import { useInfiniteScrollingImageMap } from 'common/hooks';
import { useGetAssignedImagesQuery } from 'pages/AnnotationCS/store/AnnotationService';

export function useSimpleImageListState(dataset: IDataset, { annotation_id, reader_id, enabled }: AssignmentState) {
    const simpleImageState = useInfiniteScrollingImageMap({
        datasetId: dataset?.datasetId,
        fetch: !isStudyDataset(dataset) && !enabled,
    });

    const { currentData: assignmentList } = useGetAssignedImagesQuery({
        annotation_id,
        reader_id,
        dataset_id: dataset?.datasetId,
    });

    const simpleImageList = enabled ? assignmentList : _values(simpleImageState.imageMap);

    return { simpleImageState, simpleImageList };
}

export interface AssignmentState {
    annotation_id?: string;
    reader_id?: string;
    enabled?: boolean;
}
