import { PayloadAction } from '@reduxjs/toolkit';
import { _entries } from 'common';
import { IDBEventResponse } from 'common/entities/interfaces';
import { ServiceEnum } from 'common/enum/ServiceEnum';
import { EventBus } from 'common/helpers';
import { serverEvents } from 'common/services/Client/eventServices';
import _groupBy from 'lodash/groupBy';
import { AnnotationServiceSlice } from 'pages/AnnotationCS/store/AnnotationService';
import { updateDatasetSaga } from 'pages/Datasets/store/saga/datasetListSaga';
import { UpdateDeploymentTrackingSaga } from 'pages/Models/store/saga/modelDeploymentSaga';
import { updateDeployedModelsSaga } from 'pages/Models/store/saga/modelListSaga';
import { UpdateModelTrainingSaga } from 'pages/Models/store/saga/updateModelTrainingSaga';
import { subscribePlatformHealthService, unsubscribePlatformHealthService } from 'pages/Platform/store/action';
import { UpdateStudyValidationsSaga } from 'pages/Validation/services';
import { updateValidationListSaga } from 'pages/Validation/views/summary/store/saga/ValidationDataSaga';
import { UpdateBlindSpotTrackingSaga } from 'pages/Validation/views/validationAnalysis/components/blindSpotAnalysis/store/saga';
import { EventChannel } from 'redux-saga';
import { all, call, fork, put, take, takeEvery } from 'redux-saga/effects';
import * as actions from '../actions';
import { GetInitialDataSaga } from './GetInitialDataSaga';
import { getReportedErrorLogs, updateNotificationsSaga } from './NotificationSaga';

export default function* rootSaga() {
    yield all([
        takeEvery(actions.SUBSCRIBE_DB, subscribeDB),
        takeEvery(actions.UNSUBSCRIBE_DB, unsubscribeDB),
        takeEvery(actions.GET_REPORTED_LOGS, getReportedErrorLogs),
    ]);
}

function* subscribeDB({ payload = true }: PayloadAction<boolean>): any {
    try {
        if (payload) yield fork(GetInitialDataSaga);

        yield put(subscribePlatformHealthService());

        const eventChannel: EventChannel<any> = yield call(serverEvents.subscribeEvent, ServiceEnum.Api, 'events/main-db');

        while (true) {
            let data: IDBEventResponse<TCollection> | Array<IDBEventResponse<TCollection>> = yield take(eventChannel);

            if (Array.isArray(data)) {
                for (let [coll, dataList] of _entries(_groupBy(data, item => item.source.coll))) {
                    const payloadList = dataList.map(p => p.payload);
                    yield fork(dispatchEventData, payloadList, coll as TCollection);
                }
            } else {
                yield fork(dispatchEventData, data?.payload, data?.source?.coll);
            }
        }
    } catch (error: any) {
        switch (error?.message) {
            case '401':
            case 401: {
                EventBus.emit('forceLogout');
                break;
            }
            default:
                break;
        }
        console.log({ error });
    }
}

function* dispatchEventData(payload: any, coll: TCollection) {
    try {
        switch (coll) {
            case 'BatchJobTracking':
                yield fork(updateValidationListSaga, payload);
                break;
            case 'DeploymentTracking':
                yield fork(updateDeployedModelsSaga, payload);
                yield fork(UpdateDeploymentTrackingSaga, payload);
                break;

            case 'BlindSpotTracking':
                yield fork(UpdateBlindSpotTrackingSaga, payload);
                break;

            case 'Notifications':
                yield fork(updateNotificationsSaga, payload);
                break;

            case 'Dataset':
                yield fork(updateDatasetSaga, payload);
                break;

            case 'StudyJobTracking':
                yield fork(UpdateStudyValidationsSaga, payload);
                break;

            case 'TrainJobTracking':
                yield fork(UpdateModelTrainingSaga, payload);
                break;

            case 'AnnotationTracking':
                yield put(AnnotationServiceSlice.util.invalidateTags(['AnnotationList']));
                break;
        }
    } catch (error: any) {
        console.error(error);
    }
}

function* unsubscribeDB(): any {
    yield serverEvents.unsubscribeEvent('main-db');
    yield put(unsubscribePlatformHealthService());
}

type TCollection =
    | 'Notifications'
    | 'BatchJobTracking'
    | 'StudyJobTracking'
    | 'DeploymentTracking'
    | 'BlindSpotTracking'
    | 'Dataset'
    | 'TrainJobTracking'
    | 'AnnotationTracking';
