import { getMessages } from 'common';
import { Notification } from 'components';
import { put } from 'redux-saga/effects';
import { ValidationAPISlice } from '../store';
import { IValidationRun } from '../views/summary/entities';
import { ValidationStatus } from '../views/summary/entities/IValidation';

export function* UpdateStudyValidationsSaga(data: IValidationRun | Array<IValidationRun>): any {
    try {
        if (!data) return;

        yield put(
            ValidationAPISlice.util.updateQueryData('getStudyValidations', undefined, (draft = []) => {
                if (!Array.isArray(data)) data = [data];
                for (const dataItem of data) {
                    const updatedValidation = draft?.find(v => v.validationId === dataItem.validationId);

                    if (!updatedValidation) {
                        Notification.success({
                            content: getMessages('000313').replace('$', dataItem.name),
                            options: { toastId: dataItem.validationId },
                        });
                        draft = [dataItem, ...draft];
                    } else if (dataItem.archived) {
                        draft = draft.filter(v => v.validationId !== updatedValidation.validationId);
                    } else {
                        draft = draft.map(v => (v.validationId === dataItem.validationId ? dataItem : v));
                    }

                    if (
                        updatedValidation?.validationStatus !== ValidationStatus.done &&
                        dataItem.validationStatus === ValidationStatus.done
                    ) {
                        Notification.success({
                            content: getMessages('000312').replace('$', dataItem.name),
                            options: { toastId: dataItem.validationId },
                        });
                    }
                }

                return draft;
            }) as any
        );
    } catch (error) {
        console.log(error);
    }
}
