import { useCallback } from 'react';
import { useEvent } from 'react-use';

export default function usePreventScroll() {
    const onkeydown = useCallback((e: KeyboardEvent) => {
        if (['ArrowUp', 'ArrowDown', 'ArrowLeft', 'ArrowRight'].includes(e.key)) {
            e.preventDefault();
            e.stopPropagation();
        }
    }, []);

    useEvent('keydown', onkeydown);
}
