import { Dictionary } from "common/entities";

export function getUrlQueryFromEntries(searchParams: URLSearchParams) {
    const it = searchParams.entries();
    const dict: Dictionary<string> = {};

    let result = it.next();
    while (!result.done) {
        if (result.value) {
            dict[result.value[0]] = result.value[1];
        }
        result = it.next();
    }

    return dict;
}