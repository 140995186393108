import { Badge } from 'antd';
import React from 'react';
import Styles from './Styles.module.scss';

export default function GBadge({ count, children }: GBadgeProps) {
    if (typeof count === 'number') count = count > 9 ? '+9' : count;

    return (
        <Badge
            count={count}
            className={Styles.badgeMain}
        >
            {children}
        </Badge>
    );
}

interface GBadgeProps {
    count?: React.ReactNode;
    children?: React.ReactNode;
}
