import { _range } from 'common/Utils';
import GLazyList from 'components/GLazyList';
import GDashboardCardSkeletonItem from 'components/GSkeleton/GDashboardCardSkeletonItem';
import React from 'react';
import { IDatasourceProps } from '../../entities';
import Styles from './Styles.module.scss';

export default function DashboardMiniCardView({ loading, datasource, MiniCardComponent, keyField }: IDashboardMiniCardViewProps) {
    return (
        <GLazyList
            className={Styles.DashboardMiniCardViewContainer}
            id="dataset-dashboard-card-container"
        >
            {loading
                ? _range(8).map(i => (
                      <GDashboardCardSkeletonItem
                          active
                          key={i}
                      />
                  ))
                : datasource?.map(i => (
                      <MiniCardComponent
                          item={i}
                          key={i[keyField]}
                      />
                  ))}
        </GLazyList>
    );
}

export interface IDashboardMiniCardViewProps<T = any> extends IDatasourceProps<T> {
    MiniCardComponent: React.FC<{ item: T }>;
}
