import { IModel } from 'pages/Models/entities/IModel';
import { IValidationRun } from 'pages/Validation/views/summary/entities';
import { findModel } from 'redux/selectors';
import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { selectCompletedValidationlist } from '../../metrics/store/selectors';
import { ComparisonChartKey, IComparisonMetrics } from '../entities';

export const selectComparisonExtra = (state: RootState) => state.validation.comparison.comparisonExtra;
export const selectIsDataFetched = (state: RootState) => state.validation.comparison.control.isDataFetched;
export const selectIsDataLoading = (state: RootState) => state.validation.comparison.control.isDataLoading;
export const selectSelectionModalVisible = (state: RootState) => state.validation.comparison.control.selectionModalVisible;
export const selectShowCompatibleValidations = (state: RootState) =>
    state.validation.comparison.control.showCompatibleValidations;

export function compatibleValidationsSelector(validation: IValidationRun) {
    return createSelector(
        selectCompletedValidationlist,
        selectShowCompatibleValidations,
        (completedValidations, showCompatibleValidations) => {
            if (!validation) return [];
            return completedValidations?.filter(v => {
                if (!v.generateMetrics) return false;
                if (showCompatibleValidations && v?.datasetId !== validation?.datasetId) return false;
                if (v?.problemType !== validation?.problemType) return false;

                return true;
            });
        }
    );
}

export function selectComparisonData<T extends ComparisonChartKey>(key: T): (state: RootState) => IComparisonMetrics[T]['data'] {
    return (state: any) => state.validation.comparison.comparisonMetrics[key]?.data;
}
export function selectComparisonLayout(key: ComparisonChartKey) {
    return (state: RootState) => state.validation.comparison.comparisonMetrics[key]?.layoutState;
}

export function selectComparisonGraphParams<T extends ComparisonChartKey>(
    key: T
): (state: RootState) => IComparisonMetrics[T]['graphParams'] {
    return (state: RootState) => state.validation.comparison.comparisonMetrics[key]?.graphParams;
}

export function selectComparisonDefaultParams<T extends ComparisonChartKey>(
    key: T
): (state: RootState) => IComparisonMetrics[T]['graphParams'] {
    return (state: RootState) => state.validation.comparison.comparisonMetrics[key]?.defaultParams;
}

export function isCompatible(validation: IValidationRun, selectedValidation: IValidationRun, modelList: Array<IModel>) {
    const getModelProblemType = (id: string) => findModel(modelList, id)?.problemType;

    return (
        getModelProblemType(validation?.modelId) === getModelProblemType(selectedValidation?.modelId) &&
        validation.datasetId === selectedValidation?.datasetId &&
        validation.generateMetrics
    );
}
