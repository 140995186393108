export interface IVisualComparisonState {
    study_id: string;
    viewportState: Array<IViewportState>;
    labelFilterMap: Dictionary;
    selectedLabel: string;
    isLabelModalOpen: boolean;
    stackScrollSync: boolean;
}

export function VisualComparisonStateFactory(data?: Partial<IVisualComparisonState>): IVisualComparisonState {
    return {
        study_id: data?.study_id ?? '',
        viewportState: data?.viewportState ?? [],
        labelFilterMap: data?.labelFilterMap ?? {},
        selectedLabel: data?.selectedLabel ?? '',
        isLabelModalOpen: data?.isLabelModalOpen ?? false,
        stackScrollSync: data?.stackScrollSync ?? true,
    };
}

export interface IViewportState {
    user_id: string;
    series: string;
    imageIdIndex: number;
    dicomTags: Dictionary;
}

export function ViewportStateFactory(data?: Partial<IViewportState>): IViewportState {
    return {
        user_id: data?.user_id ?? '',
        series: data?.series ?? '',
        imageIdIndex: data?.imageIdIndex ?? 0,
        dicomTags: data?.dicomTags ?? {},
    };
}
