import { Dictionary } from 'common/entities';
import { useSearchParams } from 'react-router-dom';
import useEventCallback from 'use-event-callback';
import { _entries, isNullOrUndefined } from '../Utils';

export function useUpdateSearchParams() {
    const [, setSearchParams] = useSearchParams();

    function updateSearchParams(newParams: Dictionary) {
        if (!newParams) return;

        setSearchParams(
            _prev => {
                _entries(newParams).forEach(([key, value]) => {
                    if (isNullOrUndefined(value)) {
                        _prev.delete(key);
                    } else {
                        _prev.set(key, value);
                    }
                });
                return _prev;
            },
            { replace: true }
        );
    }

    return useEventCallback(updateSearchParams);
}
