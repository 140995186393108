import { PayloadAction } from '@reduxjs/toolkit';
import cs from 'classnames';
import { isNullOrUndefined } from 'common/Utils/LangUtils/isNullOrUndefined';
import { getMessages } from 'common/helpers/MLHelper';
import GButton from 'components/GButton';
import GModal from 'components/GModal';
import { Info, Success, Warning80 } from 'components/assets/icons';
import React from 'react';
import { create } from 'react-modal-promise';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Styles from './Styles.module.scss';

function GMessageModal({
    isOpen,
    onResolve,
    onReject,
    type,
    content,
    subContent,
    okButton,
    cancelButton,
    id,
    className,
    icon,
}: IGMessageModalProps) {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    function onModalReject() {
        onReject();

        if (cancelButton?.action) {
            dispatch(cancelButton.action);
        }
    }

    function onModalResolve() {
        onResolve();

        if (okButton?.action) {
            dispatch(okButton.action);
        }

        if (okButton?.navigate) {
            navigate(okButton.navigate);
        }
    }

    const messageProperties = GetMessageProperties(type, icon);

    return (
        <>
            <GModal
                centered
                open={isOpen}
                setVisible={onModalReject}
                footer={null}
                width="30%"
                maskStyle={{ backdropFilter: 'blur(20px)' }}
                className={cs(Styles.GMessageModal, className)}
            >
                <div className={Styles.MessageModalContent}>
                    {messageProperties?.icon}
                    <div style={{ width: '100%' }}>
                        <div className={cs(Styles[type], 'flex-center')}>{messageProperties?.title}</div>
                        <div className="pBody-normal">{content}</div>
                        <div className="pBody-small">{subContent}</div>
                    </div>

                    <div className={Styles.ButtonGroup}>
                        {cancelButton?.enabled ? (
                            <GButton
                                block
                                type="default"
                                className={`button-bold ${Styles.BlockButton}`}
                                onClick={onModalReject}
                                id={`${id}-cancel`}
                            >
                                ️{cancelButton?.text || getMessages('000238')}
                            </GButton>
                        ) : null}
                        {isNullOrUndefined(okButton?.enabled) || okButton?.enabled ? (
                            <GButton
                                block
                                type={ButtonColorMap[okButton?.color] || 'primary'}
                                className={`button-bold ${Styles.BlockButton}`}
                                onClick={onModalResolve}
                                id={`${id}-ok`}
                            >
                                ️{okButton?.text || getMessages('000397')}
                            </GButton>
                        ) : null}
                    </div>
                </div>
            </GModal>
        </>
    );
}

const ButtonColorMap = {
    danger: 'ghost',
    default: 'default',
    primary: 'primary',
} as const;

function GetMessageProperties(messageType: TMessageType, icon: React.ReactNode) {
    switch (messageType) {
        case 'info':
            return {
                icon: <Info />,
                title: null,
            };

        case 'warning':
            return {
                icon: <Warning80 />,
                title: getMessages('000298'),
            };
        case 'error':
            return {
                icon: <Warning80 />,
                title: null,
            };

        case 'custom':
            return {
                icon: icon,
            };

        case 'success':
        default:
            return {
                icon: <Success />,
                title: null,
            };
    }
}

const showMessageModal = create<IGMessageModalProps>(GMessageModal);

export default showMessageModal;

interface IGMessageModalProps {
    isOpen: any;
    onResolve: any;
    onReject: any;
    instanceId: string;
    open: any;
    close: any;
    id?: string;
    className?: any;
    icon?: React.ReactNode;
    headerTitle?: string;

    type?: TMessageType;
    content?: React.ReactNode;
    subContent?: React.ReactNode;
    cancelButton?: {
        enabled: boolean;
        text?: string;
        action?: PayloadAction<any>;
        color?: 'danger' | 'primary' | 'default';
    };
    okButton?: {
        enabled?: boolean;
        text?: string;
        action?: PayloadAction<any>;
        color?: 'danger' | 'primary' | 'default';
        navigate?: string;
    };
}

export type TMessageType = 'success' | 'warning' | 'error' | 'info' | 'custom';
