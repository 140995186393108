import { IAugmentationProperties } from "pages/Playground/entities";
import { IAugmentationPropertyItem } from "pages/Playground/entities/IAugmentation";
import { _capitalize, _entries } from "common/Utils";

export async function getFilters(augmentationProperties: Partial<IAugmentationProperties>, fabric?: any) {
    if (!augmentationProperties) return;
    const result: Array<fabric.IBaseFilter> = [];

    await Promise.all(_entries(augmentationProperties).map(async ([key, prop]: [AugmentationPropertyKeys, IAugmentationPropertyItem]) => {
        if (prop.enabled && (prop.value || prop.controlType.includes("checkbox"))) {
            const fabricFilterKey = getFabricImageFilterKey(key);
            if (fabricFilterKey) {
                const filter = await getFabricFilter(fabricFilterKey, prop.value, fabric);
                result.push(filter);
            }
        }
    }));
    return result;
}

async function getFabricFilter(filter: IFilterProps, value: any, fabric: any) {
    // const { fabric } = await import(/* webpackChunkName: 'fabricjs' */ "fabric");

    let filterOptions: any;

    if (filter.option) {
        filterOptions = filter.option?.reduce((result, option) => ({
            ...result,
            [option]: getFilterValue(filter.key, value, option, filter.kernelType)
        }), {});
    }

    return fabric.Image.filters[filter.key] ? new fabric.Image.filters[filter.key](filterOptions) : null;
}

export function getFilterValue(filterKey: AllFiltersKeys, value: any, option: string, kernelType?: KernelType) {
    switch (filterKey) {
        case "Pixelate":
            return value * 10 || null;

        case "Noise":
            return value * 250;

        case "Convolute":
            switch (kernelType) {
                case "sharpen":
                    return [0, -1, 0, -1, 5, -1, 0, -1, 0];

                case "emboss":
                    return [1, 1, 1, 1, 0.7, -1, -1, -1, -1];
            }
            break;

        case "RemoveColor" as any:
            if (option === "distance") {
                return value.range;
            } else {
                return value.color;
            }
        default:
            return value;
    }
}

export function getFabricImageFilterKey(filterName: AugmentationPropertyKeys): IFilterProps {

    switch (filterName) {
        case "pixelate":
            return { key: "Pixelate", option: ["blocksize"] };

        case "randomNoise":
            return { key: "Noise", option: ["noise"] };

        case "invert":
            return { key: "Invert" };

        case "sharpen":
            return { key: "Convolute", option: ["matrix"], kernelType: "sharpen" };

        case "emboss":
            return { key: "Convolute", option: ["matrix"], kernelType: "emboss" };

        case "removeColor":
            return { key: "RemoveColor" as any, option: ["distance", "color"] };

        default: {
            return { key: _capitalize(filterName) as any, option: [filterName] };
        }
    }
}

interface IFilterProps {
    key: AllFiltersKeys,
    option?: Array<string>;
    kernelType?: KernelType;
}
export type KernelType = "sharpen" | "emboss";

export type AugmentationPropertyKeys = keyof IAugmentationProperties;
export type AllFiltersKeys = keyof fabric.IAllFilters;