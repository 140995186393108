import Styles from "./Styles.module.scss";
import cx from "classnames";
import { useList } from "react-use";
import { useEffect } from "react";
import { Dictionary } from "common";

export function useSerieLegend(props: ISerieLegendProps) {
    const { data, lightTheme } = props
    const [selectedSeries, { set, push, filter }] = useList(data?.map(item => item.id));

    useEffect(() => {
        set(data?.map(item => item.id));
    }, [data, set]);

    function onClick(id: string | number) {
        if (selectedSeries.includes(id)) {
            filter(item => item !== id);
        } else {
            push(id);
        }
    }

    function renderSerieLegends(containerStyles: React.CSSProperties = {}) {
        return (
            <div className={lightTheme ? Styles.LegendContainerLight : Styles.LegendContainer} style={containerStyles}>
                {data?.map(item => (
                    <div
                        key={item.id}
                        className={cx(Styles.LegendItem, { [Styles.Disabled]: !selectedSeries?.includes(item.id) })}
                        onClick={() => onClick(item.id)}
                    >
                        <div className={Styles.LegendBox} style={{ background: item.color }} />
                        <div className='pBody-medium'>{item.id} </div>
                    </div>))}
            </div>);
    }

    return { renderSerieLegends, selectedSeries };
}


export interface ISerie<T = any> extends Dictionary {
    id: string | number;
    color?: string;
    data: Array<T>;
}

export interface ISerieLegendProps {
    data: Array<ISerie>;
    lightTheme?: boolean;
}