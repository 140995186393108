import { _uuid, getSessionId, getUserId } from 'common/Utils';
import { NODE_ENV } from 'common/config';
import { getDefaultHeaders } from 'common/services/Tools/getDefaultHeaders';
import { useEffect } from 'react';
import {
    AnnotationAuditEvents,
    AuditActionPayload,
    DatasetAuditEvents,
    MetadataAuditEvents,
    ModelAuditEvents,
    PlatformAuditEvents,
    ProjectAuditEvents,
    TrainingAuditEvents,
    UserAuditEvents,
    ValidationAuditEvents,
} from './entities';

export class AuditHelper {
    static trackSession = (logout?: boolean) => {
        window.navigator.sendBeacon(
            '/api/audit/UpdateUserSession',
            JSON.stringify({ customHeaders: getDefaultHeaders(), logout })
        );
    };


    static LogAuditEvent = ({ level = 'info', request_id, ...payload }: AuditActionPayload) => {
        const headers = {
            type: 'application/json',
        };
        const blob = new Blob([JSON.stringify({
            ...payload,
            level,
            user_id: getUserId(),
            session_id: getSessionId(),
            request_id: request_id || _uuid(),
            customHeaders: getDefaultHeaders(),
        })], headers);

        return window.navigator.sendBeacon(
            '/deployment_manage/logging/log_web_app', blob
        );
    };

    static AuditEvents = {
        User: UserAuditEvents,
        Validation: ValidationAuditEvents,
        Annotation: AnnotationAuditEvents,
        Dataset: DatasetAuditEvents,
        Model: ModelAuditEvents,
        Metadata: MetadataAuditEvents,
        Platform: PlatformAuditEvents,
        Project: ProjectAuditEvents,
        Training: TrainingAuditEvents,
    };
}

export function useTrackSession() {
    useEffect(() => {
        if (NODE_ENV === 'development' || !sessionStorage.getItem('accessToken')) return;
        const onVisibilityChange = () => AuditHelper.trackSession();
        document.addEventListener('visibilitychange', onVisibilityChange);
        AuditHelper.trackSession();

        return () => {
            document.removeEventListener('visibilitychange', onVisibilityChange);
        };
    }, []);
}

export * from './entities';

