import { IDataset, IDataSource } from 'common';
import { ProblemType, ProblemTypeKeys } from 'common/enum';
import { _filterBy, _findBy } from 'common/Utils';
import { IModel } from 'pages/Models/entities';
import { IDeploymentTracking } from 'pages/Models/entities/IDeploymentTracking';
import { IVendor } from 'pages/Models/entities/IVendor';
import { IValidationRun } from 'pages/Validation/views/summary/entities';
import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RootState } from './store';
import { IAnnotationTracking } from 'pages/AnnotationCS/entities';

export const selectModelList = (state: RootState) => state.models.modelList;
export const selectDeploymentList = (state: RootState) => state.models.deploymentList;
export const selectArchivedModelList = (state: RootState) => state.models.archivedModelList;
export const selectDatasetList = (state: RootState) => state.datasets.datasetList;
export const selectArchivedDatasetList = (state: RootState) => state.datasets.archivedDatasetList;
export const selectDataSourceList = (state: RootState) => state.datasets.sourceList;
export const selectValidationList = (state: RootState) => state.validation.summary?.validationRunList;
export const selectValidationListLoading = (state: RootState) => state.validation.summary?.overlayStatus?.isLoading;
export const selectVendorList = (state: RootState) => state.models.vendorList;
export const selectAnnotationList = (state: RootState) => state.annotation;
export const selectAnnotationSourceList = (state: RootState) => state.annotation;

export const selectUserRole = (state: RootState) => state.auth.user?.role;
export const selectCurrentUser = (state: RootState) => state.auth.user;

export const selectCredList = (state: RootState) => state.platform.credentialList;
export const selectNetworkSpeed = (state: RootState) => state.app.common.networkSpeed;
export const selectServerNetworkStatus = (state: RootState) => state.app.common.serverNetworkStatus;
export const selectUrlQuery = (state: RootState) => state.app.routing.urlQuery;
export const selectAccessToken = (state: RootState) => state.auth.token;
export const selectIsAppInit = (state: RootState) => state.auth.isAppInit;

export const selectBlindSpotAnalysisTrackingList = (state: RootState) =>
    state.validation.blindSpotAnalysis.blindSpotAnalysisTrackingList;

export const findModel = (list: Array<IModel>, id: string) => list?.find(m => m.modelId === id);
export const findDataset = (list: Array<IDataset>, id: string) => list?.find(d => d.datasetId === id);

export const selectCurrentValidationList = createSelector(selectValidationList, validationList =>
    validationList?.filter(validation => !validation.archived)
);

export const selectArchivedValidationList = createSelector(selectValidationList, validationList =>
    validationList?.filter(validation => validation.archived)
);

export const selectAllModels = createSelector<any, Array<IModel>>(
    selectModelList,
    selectArchivedModelList,
    (modelList: Array<IModel>, archivedModellist: Array<IModel>) =>
        modelList && archivedModellist ? [...modelList, ...archivedModellist] : []
);

export const selectAllDatasets = createSelector<any, Array<IDataset>>(
    selectDatasetList,
    selectArchivedDatasetList,
    (datasetList: Array<IDataset>, archivedDatasetList: Array<IDataset>) =>
        [datasetList, archivedDatasetList].flat().filter(item => item)
);

export const selectAllAnnotations = createSelector<any, Array<IAnnotationTracking>>(
    selectAnnotationList,
    (datasetList: Array<IAnnotationTracking>) => [datasetList].flat().filter(item => item)
);

export function selectModelData(modelId: string) {
    return createSelector<any, IModel>(selectAllModels, (modelList: Array<IModel>) => findModel(modelList, modelId));
}

export function selectDatasetData(datasetId: string) {
    return createSelector<any, IDataset>(selectAllDatasets, (datasetList: Array<IDataset>) =>
        findDataset(datasetList, datasetId)
    );
}

export function selectValidationData(validationId: string) {
    return createSelector<any, IValidationRun>(selectValidationList, (validationList: Array<IValidationRun>) =>
        validationList?.find(validation => validation.validationId === validationId)
    );
}

export function selectDatasourceData(datasourceId: string) {
    return createSelector<any, IDataSource>(selectDataSourceList, (sourceList: Array<IDataSource>) =>
        sourceList?.find(s => s.source_id === datasourceId)
    );
}
export function selectModelSourceData(id: string) {
    return createSelector<any, IVendor>(selectVendorList, (sourceList: Array<IVendor>) =>
        sourceList?.find(s => s.vendor_id === id)
    );
}

export function selectDeploymentData(id: string) {
    return createSelector<any, IDeploymentTracking>(selectDeploymentList, (deploymentList: Array<IDeploymentTracking>) =>
        _findBy(deploymentList, 'deploymentId', id)
    );
}

export function useModelData(modelId: string) {
    const modelSelector = useMemo(() => selectModelData(modelId), [modelId]);
    return useSelector(modelSelector);
}

export function useDatasetData(datasetId: string) {
    const datasetSelector = useMemo(() => selectDatasetData(datasetId), [datasetId]);
    return useSelector(datasetSelector);
}

export function useValidationData(validationId: string) {
    const validationSelector = useMemo(() => selectValidationData(validationId), [validationId]);
    return useSelector(validationSelector);
}

export function useDeploymentData(id: string) {
    const deploymentSelector = useMemo(() => selectDeploymentData(id), [id]);
    return useSelector(deploymentSelector);
}

export function useDatasetSource(datasetId: string) {
    const dataset = useDatasetData(datasetId);
    return useSelector(useMemo(() => selectDatasourceData(dataset?.sourceId), [dataset?.sourceId]));
}

export function useCurrentUser() {
    return useSelector(selectCurrentUser);
}

export function getModelListWithProblemTypeSelector(problemType: ProblemTypeKeys) {
    return createSelector<any, Array<IModel>>(selectAllModels, (modelList: Array<IModel>) =>
        _filterBy(modelList, 'problemType', ProblemType[problemType])
    );
}
