export interface IDashboard {
    isStatsModalVisible: boolean;
    selectedId: string;
}

export function DashboardStateFactory(data?: Partial<IDashboard>): IDashboard {
    return {
        isStatsModalVisible: data?.isStatsModalVisible ?? false,
        selectedId: data?.selectedId ?? null,
    };
}
