import { PayloadAction } from '@reduxjs/toolkit';
import { WritableDraft } from 'immer/dist/internal';
import { IValidationState } from 'pages/Validation/entities';
import { IExportedMetricsState } from '../entities';

function updateExportedMetricsState(
    state: WritableDraft<IValidationState>,
    { payload }: PayloadAction<Partial<IExportedMetricsState>>
) {
    Object.assign(state.exportedMetrics, payload);
}

export const exportedMetricsReducer = {
    updateExportedMetricsState,
};
