import Notification from "components/GNotification";
import { getMessages } from "../helpers/MLHelper";

export enum ImageType {
    IMAGE = 'image',
    DICOM = 'dicom',
    NIFTI = 'nifti',
    APPLICATON = 'application',
}

export function dataImageHandler(file: File, onLoad: (image: any, fileType: ImageType) => void, allowedFileType: ImageType) {
    if (file) {
        const fileType = getFileType(file);
        const reader = new FileReader();

        reader.onload = function (this, ev) {
            if (this.readyState === this.DONE) {
                const result = (this.result as string).replace(/PNG/, '');
                onLoad(result, fileType);
            }
        };

        if (fileType === allowedFileType) {
            readFile(reader, file, fileType);
        } else {
            Notification.warning({ content: getMessages('000305') });
        }
    }
}

function readFile(reader: FileReader, file: File, fileType: ImageType) {
    switch (fileType) {
        case ImageType.IMAGE:
            // reader.readAsDataURL(file);
            reader.readAsBinaryString(file);
            break;

        case ImageType.DICOM:
            reader.readAsArrayBuffer(file);
            break;
        default:
            break;
    }
}

export function getFileType(file: File): ImageType | null {
    if (file) {
        let fileType: Array<string> = [];
        if (file.type !== '') {
            fileType = file.type?.split('/');
        } else {
            fileType = ['', file.name?.split('.')[1]];
        }

        switch (fileType[0]) {
            case 'image':
                return ImageType.IMAGE;
            default:
                return getFileSubTypeFromName(fileType[1]);
        }
    }
    return null;
}

function getFileSubTypeFromName(subType: string) {
    switch (subType) {
        case 'DCM':
            return ImageType.DICOM;

        default:
            return ImageType.APPLICATON;
    }
}

