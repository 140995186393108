import { GDataGrid } from 'components/GDataGrid';
import { IDataGridProps } from 'components/GDataGrid/helpers/interface';
import Styles from './Styles.module.scss';

export default function DashboardTableView({ ...props }: IDashboardTableView) {

    return (
        <GDataGrid<any>
            className={Styles.TableView}
            sorting={{ enabled: true }}
            {...props}
        />
    );
}

interface IDashboardTableView extends IDataGridProps<any> {}