import { Dictionary, IImage } from 'common';
import { IMetadataEl } from 'pages/Datasets/entities/IMetadata';
import { IMetadataUploadState, MetadataStragety, MetadataUploadStateFactory } from './IMetadataUploadState';

export interface IMetadataManagementState {
    uploadState?: IMetadataUploadState;
    editState?: MetadataEditState;
    metadataListState: IMetadataListState;
    mode: MetadataManagementMode;
    viewMode: MetadataViewMode;
    previewModal: IMetadataModalState;
    editModal: IMetadataModalState;
    imageListState: IImageListState;
    metadataImportanceModal: IMetadataModalState;
}

export interface IMetadataModalState {
    visibility: boolean;
    selectedImage?: IImage;
}

export interface IImageListState {
    imageList: Array<IImage>;
    skip: number;
    totalCount: number;
    loading: boolean;
    pageSize: number;
    imageSearch?: string;
}

export interface IMetadataListState {
    selectedMetadataId: string;
    metadataDict: Dictionary<Partial<IMetadataEl>>;
    loading: boolean;
}

export type MetadataManagementMode = 'show' | 'upload' | 'edit';

export type MetadataViewMode = 'list' | 'grid';

export type MetadataEditState = Dictionary<Partial<IMetadataEl>>;

export function MetadataManagementStateFactory(data?: Partial<IMetadataManagementState>): IMetadataManagementState {
    return {
        uploadState: MetadataUploadStateFactory(data?.uploadState),
        mode: data?.mode ?? 'show',
        metadataListState: MetadataListStateFactory(data?.metadataListState),
        viewMode: data?.viewMode ?? 'grid',
        editState: data?.editState ?? {},
        previewModal: ModalStateFactory(data?.previewModal),
        editModal: ModalStateFactory(data?.editModal),
        imageListState: ImageListStateFactory(data?.imageListState),
        metadataImportanceModal: ModalStateFactory(data?.metadataImportanceModal),
    };
}

export function MetadataListStateFactory(data?: Partial<IMetadataListState>): IMetadataListState {
    return {
        selectedMetadataId: data?.selectedMetadataId ?? null,
        metadataDict: data?.metadataDict ?? {},
        loading: data?.loading ?? false,
    };
}

export function ImageListStateFactory(data?: Partial<IImageListState>): IImageListState {
    return {
        imageList: data?.imageList ?? [],
        skip: data?.skip ?? 0,
        totalCount: data?.totalCount ?? 0,
        loading: data?.loading ?? false,
        pageSize: data?.pageSize ?? 12,
        imageSearch: data?.imageSearch ?? '',
    };
}

export function ModalStateFactory(data?: Partial<IMetadataModalState>): IMetadataModalState {
    return {
        visibility: data?.visibility ?? false,
        selectedImage: data?.selectedImage ?? null,
    };
}

export type { IMetadataUploadState, MetadataStragety };
