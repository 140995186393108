import * as am5 from '@amcharts/amcharts5';
import * as am5hierarchy from '@amcharts/amcharts5/hierarchy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import am5themes_Dark from '@amcharts/amcharts5/themes/Dark';

import { IChartHelperArgs, ICreateChartArgs, ICreateSeriesArgs } from './entities/entities';

export class HierarchyChart {
    root: am5.Root;
    chart: am5hierarchy.Sunburst;
    data: Array<any> = [];
    defaultTheme: am5.Theme;
    series: am5hierarchy.Sunburst;
    container?: am5.Container;

    createRoot(args: IChartHelperArgs) {
        this.root = am5.Root.new(args.chartID);

        this.defaultTheme = am5.Theme.new(this.root);
        this.defaultTheme.rule('ColorSet').set(
            'colors',
            this.colorPalette.map(color => am5.color(color))
        );

        this.root.setThemes([am5themes_Animated.new(this.root), am5themes_Dark.new(this.root), this.defaultTheme]);
    }

    createChart({ settings = {} }: ICreateChartArgs<am5hierarchy.ISunburstSettings>, CB?: (container?: am5.Container) => void) {
        this.container = this.root.container.children.push(
            am5.Container.new(this.root, {
                width: am5.percent(100),
                height: am5.percent(100),
                ...settings,
            })
        );

        CB?.(this.container);

        return this;
    }

    createSeries(
        { seriesSettings }: ICreateSeriesArgs<am5hierarchy.ISunburstSettings>,
        CB?: (series: am5hierarchy.Sunburst) => void
    ) {
        this.series = this.container.children.push(
            am5hierarchy.Sunburst.new(this.root, {
                ...seriesSettings,
            })
        );

        this.series.nodes.template.set('tooltipText', '{category}: [bold]{sum}[/]');
        CB?.(this.series);
        return this;
    }

    setData(data: any) {
        this.data = data;
        this.series?.appear();
        this.series?.data?.setAll(this.data);
    }

    colorPalette = [
        '#00FFA7',
        '#24D9D9',
        '#A4BF00',
        '#FFB800',
        '#FF8A00',
        '#EE5843',
        '#F9748C',
        '#FFACBB',
        '#DB78D1',
        '#E7A5FF',
        '#8819CC',
        '#6819CC',
        '#5A00FF',
        '#4968D4',
        '#0085FF',
        '#6597C6',
    ];
}
