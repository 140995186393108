export const TAG_DICT = {
    '(0000,0000)': 'CommandGroupLength',
    '(0000,0001)': 'CommandLengthToEnd',
    '(0000,0002)': 'AffectedSOPClassUID',
    '(0000,0003)': 'RequestedSOPClassUID',
    '(0000,0010)': 'CommandRecognitionCode',
    '(0000,0100)': 'CommandField',
    '(0000,0110)': 'MessageID',
    '(0000,0120)': 'MessageIDBeingRespondedTo',
    '(0000,0200)': 'Initiator',
    '(0000,0300)': 'Receiver',
    '(0000,0400)': 'FindLocation',
    '(0000,0600)': 'MoveDestination',
    '(0000,0700)': 'Priority',
    '(0000,0800)': 'CommandDataSetType',
    '(0000,0850)': 'NumberOfMatches',
    '(0000,0860)': 'ResponseSequenceNumber',
    '(0000,0900)': 'Status',
    '(0000,0901)': 'OffendingElement',
    '(0000,0902)': 'ErrorComment',
    '(0000,0903)': 'ErrorID',
    '(0000,1000)': 'AffectedSOPInstanceUID',
    '(0000,1001)': 'RequestedSOPInstanceUID',
    '(0000,1002)': 'EventTypeID',
    '(0000,1005)': 'AttributeIdentifierList',
    '(0000,1008)': 'ActionTypeID',
    '(0000,1020)': 'NumberOfRemainingSuboperations',
    '(0000,1021)': 'NumberOfCompletedSuboperations',
    '(0000,1022)': 'NumberOfFailedSuboperations',
    '(0000,1023)': 'NumberOfWarningSuboperations',
    '(0000,1030)': 'MoveOriginatorApplicationEntityTitle',
    '(0000,1031)': 'MoveOriginatorMessageID',
    '(0000,4000)': 'DialogReceiver',
    '(0000,4010)': 'TerminalType',
    '(0000,5010)': 'MessageSetID',
    '(0000,5020)': 'EndMessageID',
    '(0000,5110)': 'DisplayFormat',
    '(0000,5120)': 'PagePositionID',
    '(0000,5130)': 'TextFormatID',
    '(0000,5140)': 'NormalReverse',
    '(0000,5150)': 'AddGrayScale',
    '(0000,5160)': 'Borders',
    '(0000,5170)': 'Copies',
    '(0000,5180)': 'CommandMagnificationType',
    '(0000,5190)': 'Erase',
    '(0000,51A0)': 'Print',
    '(0000,51B0)': 'Overlays',
    '(0002,0000)': 'FileMetaInformationGroupLength',
    '(0002,0001)': 'FileMetaInformationVersion',
    '(0002,0002)': 'MediaStorageSOPClassUID',
    '(0002,0003)': 'MediaStorageSOPInstanceUID',
    '(0002,0010)': 'TransferSyntaxUID',
    '(0002,0012)': 'ImplementationClassUID',
    '(0002,0013)': 'ImplementationVersionName',
    '(0002,0016)': 'SourceApplicationEntityTitle',
    '(0002,0017)': 'SendingApplicationEntityTitle',
    '(0002,0018)': 'ReceivingApplicationEntityTitle',
    '(0002,0026)': 'SourcePresentationAddress',
    '(0002,0027)': 'SendingPresentationAddress',
    '(0002,0028)': 'ReceivingPresentationAddress',
    '(0002,0031)': 'RTVMetaInformationVersion',
    '(0002,0032)': 'RTVCommunicationSOPClassUID',
    '(0002,0033)': 'RTVCommunicationSOPInstanceUID',
    '(0002,0035)': 'RTVSourceIdentifier',
    '(0002,0036)': 'RTVFlowIdentifier',
    '(0002,0037)': 'RTVFlowRTPSamplingRate',
    '(0002,0038)': 'RTVFlowActualFrameDuration',
    '(0002,0100)': 'PrivateInformationCreatorUID',
    '(0002,0102)': 'PrivateInformation',
    '(0004,1130)': 'FileSetID',
    '(0004,1141)': 'FileSetDescriptorFileID',
    '(0004,1142)': 'SpecificCharacterSetOfFileSetDescriptorFile',
    '(0004,1200)': 'OffsetOfTheFirstDirectoryRecordOfTheRootDirectoryEntity',
    '(0004,1202)': 'OffsetOfTheLastDirectoryRecordOfTheRootDirectoryEntity',
    '(0004,1212)': 'FileSetConsistencyFlag',
    '(0004,1220)': 'DirectoryRecordSequence',
    '(0004,1400)': 'OffsetOfTheNextDirectoryRecord',
    '(0004,1410)': 'RecordInUseFlag',
    '(0004,1420)': 'OffsetOfReferencedLowerLevelDirectoryEntity',
    '(0004,1430)': 'DirectoryRecordType',
    '(0004,1432)': 'PrivateRecordUID',
    '(0004,1500)': 'ReferencedFileID',
    '(0004,1504)': 'MRDRDirectoryRecordOffset',
    '(0004,1510)': 'ReferencedSOPClassUIDInFile',
    '(0004,1511)': 'ReferencedSOPInstanceUIDInFile',
    '(0004,1512)': 'ReferencedTransferSyntaxUIDInFile',
    '(0004,151A)': 'ReferencedRelatedGeneralSOPClassUIDInFile',
    '(0004,1600)': 'NumberOfReferences',
    '(0006,0001)': 'CurrentFrameFunctionalGroupsSequence',
    '(0008,0001)': 'LengthToEnd',
    '(0008,0005)': 'SpecificCharacterSet',
    '(0008,0006)': 'LanguageCodeSequence',
    '(0008,0008)': 'ImageType',
    '(0008,0010)': 'RecognitionCode',
    '(0008,0012)': 'InstanceCreationDate',
    '(0008,0013)': 'InstanceCreationTime',
    '(0008,0014)': 'InstanceCreatorUID',
    '(0008,0015)': 'InstanceCoercionDateTime',
    '(0008,0016)': 'SOPClassUID',
    '(0008,0018)': 'SOPInstanceUID',
    '(0008,001A)': 'RelatedGeneralSOPClassUID',
    '(0008,001B)': 'OriginalSpecializedSOPClassUID',
    '(0008,0020)': 'StudyDate',
    '(0008,0021)': 'SeriesDate',
    '(0008,0022)': 'AcquisitionDate',
    '(0008,0023)': 'ContentDate',
    '(0008,0024)': 'OverlayDate',
    '(0008,0025)': 'CurveDate',
    '(0008,002A)': 'AcquisitionDateTime',
    '(0008,0030)': 'StudyTime',
    '(0008,0031)': 'SeriesTime',
    '(0008,0032)': 'AcquisitionTime',
    '(0008,0033)': 'ContentTime',
    '(0008,0034)': 'OverlayTime',
    '(0008,0035)': 'CurveTime',
    '(0008,0040)': 'DataSetType',
    '(0008,0041)': 'DataSetSubtype',
    '(0008,0042)': 'NuclearMedicineSeriesType',
    '(0008,0050)': 'AccessionNumber',
    '(0008,0051)': 'IssuerOfAccessionNumberSequence',
    '(0008,0052)': 'QueryRetrieveLevel',
    '(0008,0053)': 'QueryRetrieveView',
    '(0008,0054)': 'RetrieveAETitle',
    '(0008,0055)': 'StationAETitle',
    '(0008,0056)': 'InstanceAvailability',
    '(0008,0058)': 'FailedSOPInstanceUIDList',
    '(0008,0060)': 'Modality',
    '(0008,0061)': 'ModalitiesInStudy',
    '(0008,0062)': 'SOPClassesInStudy',
    '(0008,0063)': 'AnatomicRegionsInStudyCodeSequence',
    '(0008,0064)': 'ConversionType',
    '(0008,0068)': 'PresentationIntentType',
    '(0008,0070)': 'Manufacturer',
    '(0008,0080)': 'InstitutionName',
    '(0008,0081)': 'InstitutionAddress',
    '(0008,0082)': 'InstitutionCodeSequence',
    '(0008,0090)': 'ReferringPhysicianName',
    '(0008,0092)': 'ReferringPhysicianAddress',
    '(0008,0094)': 'ReferringPhysicianTelephoneNumbers',
    '(0008,0096)': 'ReferringPhysicianIdentificationSequence',
    '(0008,009C)': 'ConsultingPhysicianName',
    '(0008,009D)': 'ConsultingPhysicianIdentificationSequence',
    '(0008,0100)': 'CodeValue',
    '(0008,0101)': 'ExtendedCodeValue',
    '(0008,0102)': 'CodingSchemeDesignator',
    '(0008,0103)': 'CodingSchemeVersion',
    '(0008,0104)': 'CodeMeaning',
    '(0008,0105)': 'MappingResource',
    '(0008,0106)': 'ContextGroupVersion',
    '(0008,0107)': 'ContextGroupLocalVersion',
    '(0008,0108)': 'ExtendedCodeMeaning',
    '(0008,0109)': 'CodingSchemeResourcesSequence',
    '(0008,010A)': 'CodingSchemeURLType',
    '(0008,010B)': 'ContextGroupExtensionFlag',
    '(0008,010C)': 'CodingSchemeUID',
    '(0008,010D)': 'ContextGroupExtensionCreatorUID',
    '(0008,010E)': 'CodingSchemeURL',
    '(0008,010F)': 'ContextIdentifier',
    '(0008,0110)': 'CodingSchemeIdentificationSequence',
    '(0008,0112)': 'CodingSchemeRegistry',
    '(0008,0114)': 'CodingSchemeExternalID',
    '(0008,0115)': 'CodingSchemeName',
    '(0008,0116)': 'CodingSchemeResponsibleOrganization',
    '(0008,0117)': 'ContextUID',
    '(0008,0118)': 'MappingResourceUID',
    '(0008,0119)': 'LongCodeValue',
    '(0008,0120)': 'URNCodeValue',
    '(0008,0121)': 'EquivalentCodeSequence',
    '(0008,0122)': 'MappingResourceName',
    '(0008,0123)': 'ContextGroupIdentificationSequence',
    '(0008,0124)': 'MappingResourceIdentificationSequence',
    '(0008,0201)': 'TimezoneOffsetFromUTC',
    '(0008,0220)': 'ResponsibleGroupCodeSequence',
    '(0008,0221)': 'EquipmentModality',
    '(0008,0222)': 'ManufacturerRelatedModelGroup',
    '(0008,0300)': 'PrivateDataElementCharacteristicsSequence',
    '(0008,0301)': 'PrivateGroupReference',
    '(0008,0302)': 'PrivateCreatorReference',
    '(0008,0303)': 'BlockIdentifyingInformationStatus',
    '(0008,0304)': 'NonidentifyingPrivateElements',
    '(0008,0305)': 'DeidentificationActionSequence',
    '(0008,0306)': 'IdentifyingPrivateElements',
    '(0008,0307)': 'DeidentificationAction',
    '(0008,0308)': 'PrivateDataElement',
    '(0008,0309)': 'PrivateDataElementValueMultiplicity',
    '(0008,030A)': 'PrivateDataElementValueRepresentation',
    '(0008,030B)': 'PrivateDataElementNumberOfItems',
    '(0008,030C)': 'PrivateDataElementName',
    '(0008,030D)': 'PrivateDataElementKeyword',
    '(0008,030E)': 'PrivateDataElementDescription',
    '(0008,030F)': 'PrivateDataElementEncoding',
    '(0008,0310)': 'PrivateDataElementDefinitionSequence',
    '(0008,1000)': 'NetworkID',
    '(0008,1010)': 'StationName',
    '(0008,1030)': 'StudyDescription',
    '(0008,1032)': 'ProcedureCodeSequence',
    '(0008,103E)': 'SeriesDescription',
    '(0008,103F)': 'SeriesDescriptionCodeSequence',
    '(0008,1040)': 'InstitutionalDepartmentName',
    '(0008,1041)': 'InstitutionalDepartmentTypeCodeSequence',
    '(0008,1048)': 'PhysiciansOfRecord',
    '(0008,1049)': 'PhysiciansOfRecordIdentificationSequence',
    '(0008,1050)': 'PerformingPhysicianName',
    '(0008,1052)': 'PerformingPhysicianIdentificationSequence',
    '(0008,1060)': 'NameOfPhysiciansReadingStudy',
    '(0008,1062)': 'PhysiciansReadingStudyIdentificationSequence',
    '(0008,1070)': 'OperatorsName',
    '(0008,1072)': 'OperatorIdentificationSequence',
    '(0008,1080)': 'AdmittingDiagnosesDescription',
    '(0008,1084)': 'AdmittingDiagnosesCodeSequence',
    '(0008,1090)': 'ManufacturerModelName',
    '(0008,1100)': 'ReferencedResultsSequence',
    '(0008,1110)': 'ReferencedStudySequence',
    '(0008,1111)': 'ReferencedPerformedProcedureStepSequence',
    '(0008,1115)': 'ReferencedSeriesSequence',
    '(0008,1120)': 'ReferencedPatientSequence',
    '(0008,1125)': 'ReferencedVisitSequence',
    '(0008,1130)': 'ReferencedOverlaySequence',
    '(0008,1134)': 'ReferencedStereometricInstanceSequence',
    '(0008,113A)': 'ReferencedWaveformSequence',
    '(0008,1140)': 'ReferencedImageSequence',
    '(0008,1145)': 'ReferencedCurveSequence',
    '(0008,114A)': 'ReferencedInstanceSequence',
    '(0008,114B)': 'ReferencedRealWorldValueMappingInstanceSequence',
    '(0008,1150)': 'ReferencedSOPClassUID',
    '(0008,1155)': 'ReferencedSOPInstanceUID',
    '(0008,1156)': 'DefinitionSourceSequence',
    '(0008,115A)': 'SOPClassesSupported',
    '(0008,1160)': 'ReferencedFrameNumber',
    '(0008,1161)': 'SimpleFrameList',
    '(0008,1162)': 'CalculatedFrameList',
    '(0008,1163)': 'TimeRange',
    '(0008,1164)': 'FrameExtractionSequence',
    '(0008,1167)': 'MultiFrameSourceSOPInstanceUID',
    '(0008,1190)': 'RetrieveURL',
    '(0008,1195)': 'TransactionUID',
    '(0008,1196)': 'WarningReason',
    '(0008,1197)': 'FailureReason',
    '(0008,1198)': 'FailedSOPSequence',
    '(0008,1199)': 'ReferencedSOPSequence',
    '(0008,119A)': 'OtherFailuresSequence',
    '(0008,1200)': 'StudiesContainingOtherReferencedInstancesSequence',
    '(0008,1250)': 'RelatedSeriesSequence',
    '(0008,2110)': 'LossyImageCompressionRetired',
    '(0008,2111)': 'DerivationDescription',
    '(0008,2112)': 'SourceImageSequence',
    '(0008,2120)': 'StageName',
    '(0008,2122)': 'StageNumber',
    '(0008,2124)': 'NumberOfStages',
    '(0008,2127)': 'ViewName',
    '(0008,2128)': 'ViewNumber',
    '(0008,2129)': 'NumberOfEventTimers',
    '(0008,212A)': 'NumberOfViewsInStage',
    '(0008,2130)': 'EventElapsedTimes',
    '(0008,2132)': 'EventTimerNames',
    '(0008,2133)': 'EventTimerSequence',
    '(0008,2134)': 'EventTimeOffset',
    '(0008,2135)': 'EventCodeSequence',
    '(0008,2142)': 'StartTrim',
    '(0008,2143)': 'StopTrim',
    '(0008,2144)': 'RecommendedDisplayFrameRate',
    '(0008,2200)': 'TransducerPosition',
    '(0008,2204)': 'TransducerOrientation',
    '(0008,2208)': 'AnatomicStructure',
    '(0008,2218)': 'AnatomicRegionSequence',
    '(0008,2220)': 'AnatomicRegionModifierSequence',
    '(0008,2228)': 'PrimaryAnatomicStructureSequence',
    '(0008,2229)': 'AnatomicStructureSpaceOrRegionSequence',
    '(0008,2230)': 'PrimaryAnatomicStructureModifierSequence',
    '(0008,2240)': 'TransducerPositionSequence',
    '(0008,2242)': 'TransducerPositionModifierSequence',
    '(0008,2244)': 'TransducerOrientationSequence',
    '(0008,2246)': 'TransducerOrientationModifierSequence',
    '(0008,2251)': 'AnatomicStructureSpaceOrRegionCodeSequenceTrial',
    '(0008,2253)': 'AnatomicPortalOfEntranceCodeSequenceTrial',
    '(0008,2255)': 'AnatomicApproachDirectionCodeSequenceTrial',
    '(0008,2256)': 'AnatomicPerspectiveDescriptionTrial',
    '(0008,2257)': 'AnatomicPerspectiveCodeSequenceTrial',
    '(0008,2258)': 'AnatomicLocationOfExaminingInstrumentDescriptionTrial',
    '(0008,2259)': 'AnatomicLocationOfExaminingInstrumentCodeSequenceTrial',
    '(0008,225A)': 'AnatomicStructureSpaceOrRegionModifierCodeSequenceTrial',
    '(0008,225C)': 'OnAxisBackgroundAnatomicStructureCodeSequenceTrial',
    '(0008,3001)': 'AlternateRepresentationSequence',
    '(0008,3002)': 'AvailableTransferSyntaxUID',
    '(0008,3010)': 'IrradiationEventUID',
    '(0008,3011)': 'SourceIrradiationEventSequence',
    '(0008,3012)': 'RadiopharmaceuticalAdministrationEventUID',
    '(0008,4000)': 'IdentifyingComments',
    '(0008,9007)': 'FrameType',
    '(0008,9092)': 'ReferencedImageEvidenceSequence',
    '(0008,9121)': 'ReferencedRawDataSequence',
    '(0008,9123)': 'CreatorVersionUID',
    '(0008,9124)': 'DerivationImageSequence',
    '(0008,9154)': 'SourceImageEvidenceSequence',
    '(0008,9205)': 'PixelPresentation',
    '(0008,9206)': 'VolumetricProperties',
    '(0008,9207)': 'VolumeBasedCalculationTechnique',
    '(0008,9208)': 'ComplexImageComponent',
    '(0008,9209)': 'AcquisitionContrast',
    '(0008,9215)': 'DerivationCodeSequence',
    '(0008,9237)': 'ReferencedPresentationStateSequence',
    '(0008,9410)': 'ReferencedOtherPlaneSequence',
    '(0008,9458)': 'FrameDisplaySequence',
    '(0008,9459)': 'RecommendedDisplayFrameRateInFloat',
    '(0008,9460)': 'SkipFrameRangeFlag',
    '(0010,0010)': 'PatientName',
    '(0010,0020)': 'PatientID',
    '(0010,0021)': 'IssuerOfPatientID',
    '(0010,0022)': 'TypeOfPatientID',
    '(0010,0024)': 'IssuerOfPatientIDQualifiersSequence',
    '(0010,0026)': 'SourcePatientGroupIdentificationSequence',
    '(0010,0027)': 'GroupOfPatientsIdentificationSequence',
    '(0010,0028)': 'SubjectRelativePositionInImage',
    '(0010,0030)': 'PatientBirthDate',
    '(0010,0032)': 'PatientBirthTime',
    '(0010,0033)': 'PatientBirthDateInAlternativeCalendar',
    '(0010,0034)': 'PatientDeathDateInAlternativeCalendar',
    '(0010,0035)': 'PatientAlternativeCalendar',
    '(0010,0040)': 'PatientSex',
    '(0010,0050)': 'PatientInsurancePlanCodeSequence',
    '(0010,0101)': 'PatientPrimaryLanguageCodeSequence',
    '(0010,0102)': 'PatientPrimaryLanguageModifierCodeSequence',
    '(0010,0200)': 'QualityControlSubject',
    '(0010,0201)': 'QualityControlSubjectTypeCodeSequence',
    '(0010,0212)': 'StrainDescription',
    '(0010,0213)': 'StrainNomenclature',
    '(0010,0214)': 'StrainStockNumber',
    '(0010,0215)': 'StrainSourceRegistryCodeSequence',
    '(0010,0216)': 'StrainStockSequence',
    '(0010,0217)': 'StrainSource',
    '(0010,0218)': 'StrainAdditionalInformation',
    '(0010,0219)': 'StrainCodeSequence',
    '(0010,0221)': 'GeneticModificationsSequence',
    '(0010,0222)': 'GeneticModificationsDescription',
    '(0010,0223)': 'GeneticModificationsNomenclature',
    '(0010,0229)': 'GeneticModificationsCodeSequence',
    '(0010,1000)': 'OtherPatientIDs',
    '(0010,1001)': 'OtherPatientNames',
    '(0010,1002)': 'OtherPatientIDsSequence',
    '(0010,1005)': 'PatientBirthName',
    '(0010,1010)': 'PatientAge',
    '(0010,1020)': 'PatientSize',
    '(0010,1021)': 'PatientSizeCodeSequence',
    '(0010,1022)': 'PatientBodyMassIndex',
    '(0010,1023)': 'MeasuredAPDimension',
    '(0010,1024)': 'MeasuredLateralDimension',
    '(0010,1030)': 'PatientWeight',
    '(0010,1040)': 'PatientAddress',
    '(0010,1050)': 'InsurancePlanIdentification',
    '(0010,1060)': 'PatientMotherBirthName',
    '(0010,1080)': 'MilitaryRank',
    '(0010,1081)': 'BranchOfService',
    '(0010,1090)': 'MedicalRecordLocator',
    '(0010,1100)': 'ReferencedPatientPhotoSequence',
    '(0010,2000)': 'MedicalAlerts',
    '(0010,2110)': 'Allergies',
    '(0010,2150)': 'CountryOfResidence',
    '(0010,2152)': 'RegionOfResidence',
    '(0010,2154)': 'PatientTelephoneNumbers',
    '(0010,2155)': 'PatientTelecomInformation',
    '(0010,2160)': 'EthnicGroup',
    '(0010,2180)': 'Occupation',
    '(0010,21A0)': 'SmokingStatus',
    '(0010,21B0)': 'AdditionalPatientHistory',
    '(0010,21C0)': 'PregnancyStatus',
    '(0010,21D0)': 'LastMenstrualDate',
    '(0010,21F0)': 'PatientReligiousPreference',
    '(0010,2201)': 'PatientSpeciesDescription',
    '(0010,2202)': 'PatientSpeciesCodeSequence',
    '(0010,2203)': 'PatientSexNeutered',
    '(0010,2210)': 'AnatomicalOrientationType',
    '(0010,2292)': 'PatientBreedDescription',
    '(0010,2293)': 'PatientBreedCodeSequence',
    '(0010,2294)': 'BreedRegistrationSequence',
    '(0010,2295)': 'BreedRegistrationNumber',
    '(0010,2296)': 'BreedRegistryCodeSequence',
    '(0010,2297)': 'ResponsiblePerson',
    '(0010,2298)': 'ResponsiblePersonRole',
    '(0010,2299)': 'ResponsibleOrganization',
    '(0010,4000)': 'PatientComments',
    '(0010,9431)': 'ExaminedBodyThickness',
    '(0012,0010)': 'ClinicalTrialSponsorName',
    '(0012,0020)': 'ClinicalTrialProtocolID',
    '(0012,0021)': 'ClinicalTrialProtocolName',
    '(0012,0030)': 'ClinicalTrialSiteID',
    '(0012,0031)': 'ClinicalTrialSiteName',
    '(0012,0040)': 'ClinicalTrialSubjectID',
    '(0012,0042)': 'ClinicalTrialSubjectReadingID',
    '(0012,0050)': 'ClinicalTrialTimePointID',
    '(0012,0051)': 'ClinicalTrialTimePointDescription',
    '(0012,0052)': 'LongitudinalTemporalOffsetFromEvent',
    '(0012,0053)': 'LongitudinalTemporalEventType',
    '(0012,0060)': 'ClinicalTrialCoordinatingCenterName',
    '(0012,0062)': 'PatientIdentityRemoved',
    '(0012,0063)': 'DeidentificationMethod',
    '(0012,0064)': 'DeidentificationMethodCodeSequence',
    '(0012,0071)': 'ClinicalTrialSeriesID',
    '(0012,0072)': 'ClinicalTrialSeriesDescription',
    '(0012,0081)': 'ClinicalTrialProtocolEthicsCommitteeName',
    '(0012,0082)': 'ClinicalTrialProtocolEthicsCommitteeApprovalNumber',
    '(0012,0083)': 'ConsentForClinicalTrialUseSequence',
    '(0012,0084)': 'DistributionType',
    '(0012,0085)': 'ConsentForDistributionFlag',
    '(0012,0086)': 'EthicsCommitteeApprovalEffectivenessStartDate',
    '(0012,0087)': 'EthicsCommitteeApprovalEffectivenessEndDate',
    '(0014,0023)': 'CADFileFormat',
    '(0014,0024)': 'ComponentReferenceSystem',
    '(0014,0025)': 'ComponentManufacturingProcedure',
    '(0014,0028)': 'ComponentManufacturer',
    '(0014,0030)': 'MaterialThickness',
    '(0014,0032)': 'MaterialPipeDiameter',
    '(0014,0034)': 'MaterialIsolationDiameter',
    '(0014,0042)': 'MaterialGrade',
    '(0014,0044)': 'MaterialPropertiesDescription',
    '(0014,0045)': 'MaterialPropertiesFileFormatRetired',
    '(0014,0046)': 'MaterialNotes',
    '(0014,0050)': 'ComponentShape',
    '(0014,0052)': 'CurvatureType',
    '(0014,0054)': 'OuterDiameter',
    '(0014,0056)': 'InnerDiameter',
    '(0014,0100)': 'ComponentWelderIDs',
    '(0014,0101)': 'SecondaryApprovalStatus',
    '(0014,0102)': 'SecondaryReviewDate',
    '(0014,0103)': 'SecondaryReviewTime',
    '(0014,0104)': 'SecondaryReviewerName',
    '(0014,0105)': 'RepairID',
    '(0014,0106)': 'MultipleComponentApprovalSequence',
    '(0014,0107)': 'OtherApprovalStatus',
    '(0014,0108)': 'OtherSecondaryApprovalStatus',
    '(0014,1010)': 'ActualEnvironmentalConditions',
    '(0014,1020)': 'ExpiryDate',
    '(0014,1040)': 'EnvironmentalConditions',
    '(0014,2002)': 'EvaluatorSequence',
    '(0014,2004)': 'EvaluatorNumber',
    '(0014,2006)': 'EvaluatorName',
    '(0014,2008)': 'EvaluationAttempt',
    '(0014,2012)': 'IndicationSequence',
    '(0014,2014)': 'IndicationNumber',
    '(0014,2016)': 'IndicationLabel',
    '(0014,2018)': 'IndicationDescription',
    '(0014,201A)': 'IndicationType',
    '(0014,201C)': 'IndicationDisposition',
    '(0014,201E)': 'IndicationROISequence',
    '(0014,2030)': 'IndicationPhysicalPropertySequence',
    '(0014,2032)': 'PropertyLabel',
    '(0014,2202)': 'CoordinateSystemNumberOfAxes',
    '(0014,2204)': 'CoordinateSystemAxesSequence',
    '(0014,2206)': 'CoordinateSystemAxisDescription',
    '(0014,2208)': 'CoordinateSystemDataSetMapping',
    '(0014,220A)': 'CoordinateSystemAxisNumber',
    '(0014,220C)': 'CoordinateSystemAxisType',
    '(0014,220E)': 'CoordinateSystemAxisUnits',
    '(0014,2210)': 'CoordinateSystemAxisValues',
    '(0014,2220)': 'CoordinateSystemTransformSequence',
    '(0014,2222)': 'TransformDescription',
    '(0014,2224)': 'TransformNumberOfAxes',
    '(0014,2226)': 'TransformOrderOfAxes',
    '(0014,2228)': 'TransformedAxisUnits',
    '(0014,222A)': 'CoordinateSystemTransformRotationAndScaleMatrix',
    '(0014,222C)': 'CoordinateSystemTransformTranslationMatrix',
    '(0014,3011)': 'InternalDetectorFrameTime',
    '(0014,3012)': 'NumberOfFramesIntegrated',
    '(0014,3020)': 'DetectorTemperatureSequence',
    '(0014,3022)': 'SensorName',
    '(0014,3024)': 'HorizontalOffsetOfSensor',
    '(0014,3026)': 'VerticalOffsetOfSensor',
    '(0014,3028)': 'SensorTemperature',
    '(0014,3040)': 'DarkCurrentSequence',
    '(0014,3050)': 'DarkCurrentCounts',
    '(0014,3060)': 'GainCorrectionReferenceSequence',
    '(0014,3070)': 'AirCounts',
    '(0014,3071)': 'KVUsedInGainCalibration',
    '(0014,3072)': 'MAUsedInGainCalibration',
    '(0014,3073)': 'NumberOfFramesUsedForIntegration',
    '(0014,3074)': 'FilterMaterialUsedInGainCalibration',
    '(0014,3075)': 'FilterThicknessUsedInGainCalibration',
    '(0014,3076)': 'DateOfGainCalibration',
    '(0014,3077)': 'TimeOfGainCalibration',
    '(0014,3080)': 'BadPixelImage',
    '(0014,3099)': 'CalibrationNotes',
    '(0014,3100)': 'LinearityCorrectionTechnique',
    '(0014,3101)': 'BeamHardeningCorrectionTechnique',
    '(0014,4002)': 'PulserEquipmentSequence',
    '(0014,4004)': 'PulserType',
    '(0014,4006)': 'PulserNotes',
    '(0014,4008)': 'ReceiverEquipmentSequence',
    '(0014,400A)': 'AmplifierType',
    '(0014,400C)': 'ReceiverNotes',
    '(0014,400E)': 'PreAmplifierEquipmentSequence',
    '(0014,400F)': 'PreAmplifierNotes',
    '(0014,4010)': 'TransmitTransducerSequence',
    '(0014,4011)': 'ReceiveTransducerSequence',
    '(0014,4012)': 'NumberOfElements',
    '(0014,4013)': 'ElementShape',
    '(0014,4014)': 'ElementDimensionA',
    '(0014,4015)': 'ElementDimensionB',
    '(0014,4016)': 'ElementPitchA',
    '(0014,4017)': 'MeasuredBeamDimensionA',
    '(0014,4018)': 'MeasuredBeamDimensionB',
    '(0014,4019)': 'LocationOfMeasuredBeamDiameter',
    '(0014,401A)': 'NominalFrequency',
    '(0014,401B)': 'MeasuredCenterFrequency',
    '(0014,401C)': 'MeasuredBandwidth',
    '(0014,401D)': 'ElementPitchB',
    '(0014,4020)': 'PulserSettingsSequence',
    '(0014,4022)': 'PulseWidth',
    '(0014,4024)': 'ExcitationFrequency',
    '(0014,4026)': 'ModulationType',
    '(0014,4028)': 'Damping',
    '(0014,4030)': 'ReceiverSettingsSequence',
    '(0014,4031)': 'AcquiredSoundpathLength',
    '(0014,4032)': 'AcquisitionCompressionType',
    '(0014,4033)': 'AcquisitionSampleSize',
    '(0014,4034)': 'RectifierSmoothing',
    '(0014,4035)': 'DACSequence',
    '(0014,4036)': 'DACType',
    '(0014,4038)': 'DACGainPoints',
    '(0014,403A)': 'DACTimePoints',
    '(0014,403C)': 'DACAmplitude',
    '(0014,4040)': 'PreAmplifierSettingsSequence',
    '(0014,4050)': 'TransmitTransducerSettingsSequence',
    '(0014,4051)': 'ReceiveTransducerSettingsSequence',
    '(0014,4052)': 'IncidentAngle',
    '(0014,4054)': 'CouplingTechnique',
    '(0014,4056)': 'CouplingMedium',
    '(0014,4057)': 'CouplingVelocity',
    '(0014,4058)': 'ProbeCenterLocationX',
    '(0014,4059)': 'ProbeCenterLocationZ',
    '(0014,405A)': 'SoundPathLength',
    '(0014,405C)': 'DelayLawIdentifier',
    '(0014,4060)': 'GateSettingsSequence',
    '(0014,4062)': 'GateThreshold',
    '(0014,4064)': 'VelocityOfSound',
    '(0014,4070)': 'CalibrationSettingsSequence',
    '(0014,4072)': 'CalibrationProcedure',
    '(0014,4074)': 'ProcedureVersion',
    '(0014,4076)': 'ProcedureCreationDate',
    '(0014,4078)': 'ProcedureExpirationDate',
    '(0014,407A)': 'ProcedureLastModifiedDate',
    '(0014,407C)': 'CalibrationTime',
    '(0014,407E)': 'CalibrationDate',
    '(0014,4080)': 'ProbeDriveEquipmentSequence',
    '(0014,4081)': 'DriveType',
    '(0014,4082)': 'ProbeDriveNotes',
    '(0014,4083)': 'DriveProbeSequence',
    '(0014,4084)': 'ProbeInductance',
    '(0014,4085)': 'ProbeResistance',
    '(0014,4086)': 'ReceiveProbeSequence',
    '(0014,4087)': 'ProbeDriveSettingsSequence',
    '(0014,4088)': 'BridgeResistors',
    '(0014,4089)': 'ProbeOrientationAngle',
    '(0014,408B)': 'UserSelectedGainY',
    '(0014,408C)': 'UserSelectedPhase',
    '(0014,408D)': 'UserSelectedOffsetX',
    '(0014,408E)': 'UserSelectedOffsetY',
    '(0014,4091)': 'ChannelSettingsSequence',
    '(0014,4092)': 'ChannelThreshold',
    '(0014,409A)': 'ScannerSettingsSequence',
    '(0014,409B)': 'ScanProcedure',
    '(0014,409C)': 'TranslationRateX',
    '(0014,409D)': 'TranslationRateY',
    '(0014,409F)': 'ChannelOverlap',
    '(0014,40A0)': 'ImageQualityIndicatorType',
    '(0014,40A1)': 'ImageQualityIndicatorMaterial',
    '(0014,40A2)': 'ImageQualityIndicatorSize',
    '(0014,5002)': 'LINACEnergy',
    '(0014,5004)': 'LINACOutput',
    '(0014,5100)': 'ActiveAperture',
    '(0014,5101)': 'TotalAperture',
    '(0014,5102)': 'ApertureElevation',
    '(0014,5103)': 'MainLobeAngle',
    '(0014,5104)': 'MainRoofAngle',
    '(0014,5105)': 'ConnectorType',
    '(0014,5106)': 'WedgeModelNumber',
    '(0014,5107)': 'WedgeAngleFloat',
    '(0014,5108)': 'WedgeRoofAngle',
    '(0014,5109)': 'WedgeElement1Position',
    '(0014,510A)': 'WedgeMaterialVelocity',
    '(0014,510B)': 'WedgeMaterial',
    '(0014,510C)': 'WedgeOffsetZ',
    '(0014,510D)': 'WedgeOriginOffsetX',
    '(0014,510E)': 'WedgeTimeDelay',
    '(0014,510F)': 'WedgeName',
    '(0014,5110)': 'WedgeManufacturerName',
    '(0014,5111)': 'WedgeDescription',
    '(0014,5112)': 'NominalBeamAngle',
    '(0014,5113)': 'WedgeOffsetX',
    '(0014,5114)': 'WedgeOffsetY',
    '(0014,5115)': 'WedgeTotalLength',
    '(0014,5116)': 'WedgeInContactLength',
    '(0014,5117)': 'WedgeFrontGap',
    '(0014,5118)': 'WedgeTotalHeight',
    '(0014,5119)': 'WedgeFrontHeight',
    '(0014,511A)': 'WedgeRearHeight',
    '(0014,511B)': 'WedgeTotalWidth',
    '(0014,511C)': 'WedgeInContactWidth',
    '(0014,511D)': 'WedgeChamferHeight',
    '(0014,511E)': 'WedgeCurve',
    '(0014,511F)': 'RadiusAlongWedge',
    '(0016,0001)': 'WhitePoint',
    '(0016,0002)': 'PrimaryChromaticities',
    '(0016,0003)': 'BatteryLevel',
    '(0016,0004)': 'ExposureTimeInSeconds',
    '(0016,0005)': 'FNumber',
    '(0016,0006)': 'OECFRows',
    '(0016,0007)': 'OECFColumns',
    '(0016,0008)': 'OECFColumnNames',
    '(0016,0009)': 'OECFValues',
    '(0016,000A)': 'SpatialFrequencyResponseRows',
    '(0016,000B)': 'SpatialFrequencyResponseColumns',
    '(0016,000C)': 'SpatialFrequencyResponseColumnNames',
    '(0016,000D)': 'SpatialFrequencyResponseValues',
    '(0016,000E)': 'ColorFilterArrayPatternRows',
    '(0016,000F)': 'ColorFilterArrayPatternColumns',
    '(0016,0010)': 'ColorFilterArrayPatternValues',
    '(0016,0011)': 'FlashFiringStatus',
    '(0016,0012)': 'FlashReturnStatus',
    '(0016,0013)': 'FlashMode',
    '(0016,0014)': 'FlashFunctionPresent',
    '(0016,0015)': 'FlashRedEyeMode',
    '(0016,0016)': 'ExposureProgram',
    '(0016,0017)': 'SpectralSensitivity',
    '(0016,0018)': 'PhotographicSensitivity',
    '(0016,0019)': 'SelfTimerMode',
    '(0016,001A)': 'SensitivityType',
    '(0016,001B)': 'StandardOutputSensitivity',
    '(0016,001C)': 'RecommendedExposureIndex',
    '(0016,001D)': 'ISOSpeed',
    '(0016,001E)': 'ISOSpeedLatitudeyyy',
    '(0016,001F)': 'ISOSpeedLatitudezzz',
    '(0016,0020)': 'EXIFVersion',
    '(0016,0021)': 'ShutterSpeedValue',
    '(0016,0022)': 'ApertureValue',
    '(0016,0023)': 'BrightnessValue',
    '(0016,0024)': 'ExposureBiasValue',
    '(0016,0025)': 'MaxApertureValue',
    '(0016,0026)': 'SubjectDistance',
    '(0016,0027)': 'MeteringMode',
    '(0016,0028)': 'LightSource',
    '(0016,0029)': 'FocalLength',
    '(0016,002A)': 'SubjectArea',
    '(0016,002B)': 'MakerNote',
    '(0016,0030)': 'Temperature',
    '(0016,0031)': 'Humidity',
    '(0016,0032)': 'Pressure',
    '(0016,0033)': 'WaterDepth',
    '(0016,0034)': 'Acceleration',
    '(0016,0035)': 'CameraElevationAngle',
    '(0016,0036)': 'FlashEnergy',
    '(0016,0037)': 'SubjectLocation',
    '(0016,0038)': 'PhotographicExposureIndex',
    '(0016,0039)': 'SensingMethod',
    '(0016,003A)': 'FileSource',
    '(0016,003B)': 'SceneType',
    '(0016,0041)': 'CustomRendered',
    '(0016,0042)': 'ExposureMode',
    '(0016,0043)': 'WhiteBalance',
    '(0016,0044)': 'DigitalZoomRatio',
    '(0016,0045)': 'FocalLengthIn35mmFilm',
    '(0016,0046)': 'SceneCaptureType',
    '(0016,0047)': 'GainControl',
    '(0016,0048)': 'Contrast',
    '(0016,0049)': 'Saturation',
    '(0016,004A)': 'Sharpness',
    '(0016,004B)': 'DeviceSettingDescription',
    '(0016,004C)': 'SubjectDistanceRange',
    '(0016,004D)': 'CameraOwnerName',
    '(0016,004E)': 'LensSpecification',
    '(0016,004F)': 'LensMake',
    '(0016,0050)': 'LensModel',
    '(0016,0051)': 'LensSerialNumber',
    '(0016,0061)': 'InteroperabilityIndex',
    '(0016,0062)': 'InteroperabilityVersion',
    '(0016,0070)': 'GPSVersionID',
    '(0016,0071)': 'GPSLatitudeRef',
    '(0016,0072)': 'GPSLatitude',
    '(0016,0073)': 'GPSLongitudeRef',
    '(0016,0074)': 'GPSLongitude',
    '(0016,0075)': 'GPSAltitudeRef',
    '(0016,0076)': 'GPSAltitude',
    '(0016,0077)': 'GPSTimeStamp',
    '(0016,0078)': 'GPSSatellites',
    '(0016,0079)': 'GPSStatus',
    '(0016,007A)': 'GPSMeasureMode',
    '(0016,007B)': 'GPSDOP',
    '(0016,007C)': 'GPSSpeedRef',
    '(0016,007D)': 'GPSSpeed',
    '(0016,007E)': 'GPSTrackRef',
    '(0016,007F)': 'GPSTrack',
    '(0016,0080)': 'GPSImgDirectionRef',
    '(0016,0081)': 'GPSImgDirection',
    '(0016,0082)': 'GPSMapDatum',
    '(0016,0083)': 'GPSDestLatitudeRef',
    '(0016,0084)': 'GPSDestLatitude',
    '(0016,0085)': 'GPSDestLongitudeRef',
    '(0016,0086)': 'GPSDestLongitude',
    '(0016,0087)': 'GPSDestBearingRef',
    '(0016,0088)': 'GPSDestBearing',
    '(0016,0089)': 'GPSDestDistanceRef',
    '(0016,008A)': 'GPSDestDistance',
    '(0016,008B)': 'GPSProcessingMethod',
    '(0016,008C)': 'GPSAreaInformation',
    '(0016,008D)': 'GPSDateStamp',
    '(0016,008E)': 'GPSDifferential',
    '(0016,1001)': 'LightSourcePolarization',
    '(0016,1002)': 'EmitterColorTemperature',
    '(0016,1003)': 'ContactMethod',
    '(0016,1004)': 'ImmersionMedia',
    '(0016,1005)': 'OpticalMagnificationFactor',
    '(0018,0010)': 'ContrastBolusAgent',
    '(0018,0012)': 'ContrastBolusAgentSequence',
    '(0018,0013)': 'ContrastBolusT1Relaxivity',
    '(0018,0014)': 'ContrastBolusAdministrationRouteSequence',
    '(0018,0015)': 'BodyPartExamined',
    '(0018,0020)': 'ScanningSequence',
    '(0018,0021)': 'SequenceVariant',
    '(0018,0022)': 'ScanOptions',
    '(0018,0023)': 'MRAcquisitionType',
    '(0018,0024)': 'SequenceName',
    '(0018,0025)': 'AngioFlag',
    '(0018,0026)': 'InterventionDrugInformationSequence',
    '(0018,0027)': 'InterventionDrugStopTime',
    '(0018,0028)': 'InterventionDrugDose',
    '(0018,0029)': 'InterventionDrugCodeSequence',
    '(0018,002A)': 'AdditionalDrugSequence',
    '(0018,0030)': 'Radionuclide',
    '(0018,0031)': 'Radiopharmaceutical',
    '(0018,0032)': 'EnergyWindowCenterline',
    '(0018,0033)': 'EnergyWindowTotalWidth',
    '(0018,0034)': 'InterventionDrugName',
    '(0018,0035)': 'InterventionDrugStartTime',
    '(0018,0036)': 'InterventionSequence',
    '(0018,0037)': 'TherapyType',
    '(0018,0038)': 'InterventionStatus',
    '(0018,0039)': 'TherapyDescription',
    '(0018,003A)': 'InterventionDescription',
    '(0018,0040)': 'CineRate',
    '(0018,0042)': 'InitialCineRunState',
    '(0018,0050)': 'SliceThickness',
    '(0018,0060)': 'KVP',
    '(0018,0070)': 'CountsAccumulated',
    '(0018,0071)': 'AcquisitionTerminationCondition',
    '(0018,0072)': 'EffectiveDuration',
    '(0018,0073)': 'AcquisitionStartCondition',
    '(0018,0074)': 'AcquisitionStartConditionData',
    '(0018,0075)': 'AcquisitionTerminationConditionData',
    '(0018,0080)': 'RepetitionTime',
    '(0018,0081)': 'EchoTime',
    '(0018,0082)': 'InversionTime',
    '(0018,0083)': 'NumberOfAverages',
    '(0018,0084)': 'ImagingFrequency',
    '(0018,0085)': 'ImagedNucleus',
    '(0018,0086)': 'EchoNumbers',
    '(0018,0087)': 'MagneticFieldStrength',
    '(0018,0088)': 'SpacingBetweenSlices',
    '(0018,0089)': 'NumberOfPhaseEncodingSteps',
    '(0018,0090)': 'DataCollectionDiameter',
    '(0018,0091)': 'EchoTrainLength',
    '(0018,0093)': 'PercentSampling',
    '(0018,0094)': 'PercentPhaseFieldOfView',
    '(0018,0095)': 'PixelBandwidth',
    '(0018,1000)': 'DeviceSerialNumber',
    '(0018,1002)': 'DeviceUID',
    '(0018,1003)': 'DeviceID',
    '(0018,1004)': 'PlateID',
    '(0018,1005)': 'GeneratorID',
    '(0018,1006)': 'GridID',
    '(0018,1007)': 'CassetteID',
    '(0018,1008)': 'GantryID',
    '(0018,1009)': 'UniqueDeviceIdentifier',
    '(0018,100A)': 'UDISequence',
    '(0018,100B)': 'ManufacturerDeviceClassUID',
    '(0018,1010)': 'SecondaryCaptureDeviceID',
    '(0018,1011)': 'HardcopyCreationDeviceID',
    '(0018,1012)': 'DateOfSecondaryCapture',
    '(0018,1014)': 'TimeOfSecondaryCapture',
    '(0018,1016)': 'SecondaryCaptureDeviceManufacturer',
    '(0018,1017)': 'HardcopyDeviceManufacturer',
    '(0018,1018)': 'SecondaryCaptureDeviceManufacturerModelName',
    '(0018,1019)': 'SecondaryCaptureDeviceSoftwareVersions',
    '(0018,101A)': 'HardcopyDeviceSoftwareVersion',
    '(0018,101B)': 'HardcopyDeviceManufacturerModelName',
    '(0018,1020)': 'SoftwareVersions',
    '(0018,1022)': 'VideoImageFormatAcquired',
    '(0018,1023)': 'DigitalImageFormatAcquired',
    '(0018,1030)': 'ProtocolName',
    '(0018,1040)': 'ContrastBolusRoute',
    '(0018,1041)': 'ContrastBolusVolume',
    '(0018,1042)': 'ContrastBolusStartTime',
    '(0018,1043)': 'ContrastBolusStopTime',
    '(0018,1044)': 'ContrastBolusTotalDose',
    '(0018,1045)': 'SyringeCounts',
    '(0018,1046)': 'ContrastFlowRate',
    '(0018,1047)': 'ContrastFlowDuration',
    '(0018,1048)': 'ContrastBolusIngredient',
    '(0018,1049)': 'ContrastBolusIngredientConcentration',
    '(0018,1050)': 'SpatialResolution',
    '(0018,1060)': 'TriggerTime',
    '(0018,1061)': 'TriggerSourceOrType',
    '(0018,1062)': 'NominalInterval',
    '(0018,1063)': 'FrameTime',
    '(0018,1064)': 'CardiacFramingType',
    '(0018,1065)': 'FrameTimeVector',
    '(0018,1066)': 'FrameDelay',
    '(0018,1067)': 'ImageTriggerDelay',
    '(0018,1068)': 'MultiplexGroupTimeOffset',
    '(0018,1069)': 'TriggerTimeOffset',
    '(0018,106A)': 'SynchronizationTrigger',
    '(0018,106C)': 'SynchronizationChannel',
    '(0018,106E)': 'TriggerSamplePosition',
    '(0018,1070)': 'RadiopharmaceuticalRoute',
    '(0018,1071)': 'RadiopharmaceuticalVolume',
    '(0018,1072)': 'RadiopharmaceuticalStartTime',
    '(0018,1073)': 'RadiopharmaceuticalStopTime',
    '(0018,1074)': 'RadionuclideTotalDose',
    '(0018,1075)': 'RadionuclideHalfLife',
    '(0018,1076)': 'RadionuclidePositronFraction',
    '(0018,1077)': 'RadiopharmaceuticalSpecificActivity',
    '(0018,1078)': 'RadiopharmaceuticalStartDateTime',
    '(0018,1079)': 'RadiopharmaceuticalStopDateTime',
    '(0018,1080)': 'BeatRejectionFlag',
    '(0018,1081)': 'LowRRValue',
    '(0018,1082)': 'HighRRValue',
    '(0018,1083)': 'IntervalsAcquired',
    '(0018,1084)': 'IntervalsRejected',
    '(0018,1085)': 'PVCRejection',
    '(0018,1086)': 'SkipBeats',
    '(0018,1088)': 'HeartRate',
    '(0018,1090)': 'CardiacNumberOfImages',
    '(0018,1094)': 'TriggerWindow',
    '(0018,1100)': 'ReconstructionDiameter',
    '(0018,1110)': 'DistanceSourceToDetector',
    '(0018,1111)': 'DistanceSourceToPatient',
    '(0018,1114)': 'EstimatedRadiographicMagnificationFactor',
    '(0018,1120)': 'GantryDetectorTilt',
    '(0018,1121)': 'GantryDetectorSlew',
    '(0018,1130)': 'TableHeight',
    '(0018,1131)': 'TableTraverse',
    '(0018,1134)': 'TableMotion',
    '(0018,1135)': 'TableVerticalIncrement',
    '(0018,1136)': 'TableLateralIncrement',
    '(0018,1137)': 'TableLongitudinalIncrement',
    '(0018,1138)': 'TableAngle',
    '(0018,113A)': 'TableType',
    '(0018,1140)': 'RotationDirection',
    '(0018,1141)': 'AngularPosition',
    '(0018,1142)': 'RadialPosition',
    '(0018,1143)': 'ScanArc',
    '(0018,1144)': 'AngularStep',
    '(0018,1145)': 'CenterOfRotationOffset',
    '(0018,1146)': 'RotationOffset',
    '(0018,1147)': 'FieldOfViewShape',
    '(0018,1149)': 'FieldOfViewDimensions',
    '(0018,1150)': 'ExposureTime',
    '(0018,1151)': 'XRayTubeCurrent',
    '(0018,1152)': 'Exposure',
    '(0018,1153)': 'ExposureInuAs',
    '(0018,1154)': 'AveragePulseWidth',
    '(0018,1155)': 'RadiationSetting',
    '(0018,1156)': 'RectificationType',
    '(0018,115A)': 'RadiationMode',
    '(0018,115E)': 'ImageAndFluoroscopyAreaDoseProduct',
    '(0018,1160)': 'FilterType',
    '(0018,1161)': 'TypeOfFilters',
    '(0018,1162)': 'IntensifierSize',
    '(0018,1164)': 'ImagerPixelSpacing',
    '(0018,1166)': 'Grid',
    '(0018,1170)': 'GeneratorPower',
    '(0018,1180)': 'CollimatorGridName',
    '(0018,1181)': 'CollimatorType',
    '(0018,1182)': 'FocalDistance',
    '(0018,1183)': 'XFocusCenter',
    '(0018,1184)': 'YFocusCenter',
    '(0018,1190)': 'FocalSpots',
    '(0018,1191)': 'AnodeTargetMaterial',
    '(0018,11A0)': 'BodyPartThickness',
    '(0018,11A2)': 'CompressionForce',
    '(0018,11A3)': 'CompressionPressure',
    '(0018,11A4)': 'PaddleDescription',
    '(0018,11A5)': 'CompressionContactArea',
    '(0018,11B0)': 'AcquisitionMode',
    '(0018,11B1)': 'DoseModeName',
    '(0018,11B2)': 'AcquiredSubtractionMaskFlag',
    '(0018,11B3)': 'FluoroscopyPersistenceFlag',
    '(0018,11B4)': 'FluoroscopyLastImageHoldPersistenceFlag',
    '(0018,11B5)': 'UpperLimitNumberOfPersistentFluoroscopyFrames',
    '(0018,11B6)': 'ContrastBolusAutoInjectionTriggerFlag',
    '(0018,11B7)': 'ContrastBolusInjectionDelay',
    '(0018,11B8)': 'XAAcquisitionPhaseDetailsSequence',
    '(0018,11B9)': 'XAAcquisitionFrameRate',
    '(0018,11BA)': 'XAPlaneDetailsSequence',
    '(0018,11BB)': 'AcquisitionFieldOfViewLabel',
    '(0018,11BC)': 'XRayFilterDetailsSequence',
    '(0018,11BD)': 'XAAcquisitionDuration',
    '(0018,11BE)': 'ReconstructionPipelineType',
    '(0018,11BF)': 'ImageFilterDetailsSequence',
    '(0018,11C0)': 'AppliedMaskSubtractionFlag',
    '(0018,11C1)': 'RequestedSeriesDescriptionCodeSequence',
    '(0018,1200)': 'DateOfLastCalibration',
    '(0018,1201)': 'TimeOfLastCalibration',
    '(0018,1202)': 'DateTimeOfLastCalibration',
    '(0018,1203)': 'CalibrationDateTime',
    '(0018,1210)': 'ConvolutionKernel',
    '(0018,1240)': 'UpperLowerPixelValues',
    '(0018,1242)': 'ActualFrameDuration',
    '(0018,1243)': 'CountRate',
    '(0018,1244)': 'PreferredPlaybackSequencing',
    '(0018,1250)': 'ReceiveCoilName',
    '(0018,1251)': 'TransmitCoilName',
    '(0018,1260)': 'PlateType',
    '(0018,1261)': 'PhosphorType',
    '(0018,1271)': 'WaterEquivalentDiameter',
    '(0018,1272)': 'WaterEquivalentDiameterCalculationMethodCodeSequence',
    '(0018,1300)': 'ScanVelocity',
    '(0018,1301)': 'WholeBodyTechnique',
    '(0018,1302)': 'ScanLength',
    '(0018,1310)': 'AcquisitionMatrix',
    '(0018,1312)': 'InPlanePhaseEncodingDirection',
    '(0018,1314)': 'FlipAngle',
    '(0018,1315)': 'VariableFlipAngleFlag',
    '(0018,1316)': 'SAR',
    '(0018,1318)': 'dBdt',
    '(0018,1320)': 'B1rms',
    '(0018,1400)': 'AcquisitionDeviceProcessingDescription',
    '(0018,1401)': 'AcquisitionDeviceProcessingCode',
    '(0018,1402)': 'CassetteOrientation',
    '(0018,1403)': 'CassetteSize',
    '(0018,1404)': 'ExposuresOnPlate',
    '(0018,1405)': 'RelativeXRayExposure',
    '(0018,1411)': 'ExposureIndex',
    '(0018,1412)': 'TargetExposureIndex',
    '(0018,1413)': 'DeviationIndex',
    '(0018,1450)': 'ColumnAngulation',
    '(0018,1460)': 'TomoLayerHeight',
    '(0018,1470)': 'TomoAngle',
    '(0018,1480)': 'TomoTime',
    '(0018,1490)': 'TomoType',
    '(0018,1491)': 'TomoClass',
    '(0018,1495)': 'NumberOfTomosynthesisSourceImages',
    '(0018,1500)': 'PositionerMotion',
    '(0018,1508)': 'PositionerType',
    '(0018,1510)': 'PositionerPrimaryAngle',
    '(0018,1511)': 'PositionerSecondaryAngle',
    '(0018,1520)': 'PositionerPrimaryAngleIncrement',
    '(0018,1521)': 'PositionerSecondaryAngleIncrement',
    '(0018,1530)': 'DetectorPrimaryAngle',
    '(0018,1531)': 'DetectorSecondaryAngle',
    '(0018,1600)': 'ShutterShape',
    '(0018,1602)': 'ShutterLeftVerticalEdge',
    '(0018,1604)': 'ShutterRightVerticalEdge',
    '(0018,1606)': 'ShutterUpperHorizontalEdge',
    '(0018,1608)': 'ShutterLowerHorizontalEdge',
    '(0018,1610)': 'CenterOfCircularShutter',
    '(0018,1612)': 'RadiusOfCircularShutter',
    '(0018,1620)': 'VerticesOfThePolygonalShutter',
    '(0018,1622)': 'ShutterPresentationValue',
    '(0018,1623)': 'ShutterOverlayGroup',
    '(0018,1624)': 'ShutterPresentationColorCIELabValue',
    '(0018,1630)': 'OutlineShapeType',
    '(0018,1631)': 'OutlineLeftVerticalEdge',
    '(0018,1632)': 'OutlineRightVerticalEdge',
    '(0018,1633)': 'OutlineUpperHorizontalEdge',
    '(0018,1634)': 'OutlineLowerHorizontalEdge',
    '(0018,1635)': 'CenterOfCircularOutline',
    '(0018,1636)': 'DiameterOfCircularOutline',
    '(0018,1637)': 'NumberOfPolygonalVertices',
    '(0018,1638)': 'VerticesOfThePolygonalOutline',
    '(0018,1700)': 'CollimatorShape',
    '(0018,1702)': 'CollimatorLeftVerticalEdge',
    '(0018,1704)': 'CollimatorRightVerticalEdge',
    '(0018,1706)': 'CollimatorUpperHorizontalEdge',
    '(0018,1708)': 'CollimatorLowerHorizontalEdge',
    '(0018,1710)': 'CenterOfCircularCollimator',
    '(0018,1712)': 'RadiusOfCircularCollimator',
    '(0018,1720)': 'VerticesOfThePolygonalCollimator',
    '(0018,1800)': 'AcquisitionTimeSynchronized',
    '(0018,1801)': 'TimeSource',
    '(0018,1802)': 'TimeDistributionProtocol',
    '(0018,1803)': 'NTPSourceAddress',
    '(0018,2001)': 'PageNumberVector',
    '(0018,2002)': 'FrameLabelVector',
    '(0018,2003)': 'FramePrimaryAngleVector',
    '(0018,2004)': 'FrameSecondaryAngleVector',
    '(0018,2005)': 'SliceLocationVector',
    '(0018,2006)': 'DisplayWindowLabelVector',
    '(0018,2010)': 'NominalScannedPixelSpacing',
    '(0018,2020)': 'DigitizingDeviceTransportDirection',
    '(0018,2030)': 'RotationOfScannedFilm',
    '(0018,2041)': 'BiopsyTargetSequence',
    '(0018,2042)': 'TargetUID',
    '(0018,2043)': 'LocalizingCursorPosition',
    '(0018,2044)': 'CalculatedTargetPosition',
    '(0018,2045)': 'TargetLabel',
    '(0018,2046)': 'DisplayedZValue',
    '(0018,3100)': 'IVUSAcquisition',
    '(0018,3101)': 'IVUSPullbackRate',
    '(0018,3102)': 'IVUSGatedRate',
    '(0018,3103)': 'IVUSPullbackStartFrameNumber',
    '(0018,3104)': 'IVUSPullbackStopFrameNumber',
    '(0018,3105)': 'LesionNumber',
    '(0018,4000)': 'AcquisitionComments',
    '(0018,5000)': 'OutputPower',
    '(0018,5010)': 'TransducerData',
    '(0018,5011)': 'TransducerIdentificationSequence',
    '(0018,5012)': 'FocusDepth',
    '(0018,5020)': 'ProcessingFunction',
    '(0018,5021)': 'PostprocessingFunction',
    '(0018,5022)': 'MechanicalIndex',
    '(0018,5024)': 'BoneThermalIndex',
    '(0018,5026)': 'CranialThermalIndex',
    '(0018,5027)': 'SoftTissueThermalIndex',
    '(0018,5028)': 'SoftTissueFocusThermalIndex',
    '(0018,5029)': 'SoftTissueSurfaceThermalIndex',
    '(0018,5030)': 'DynamicRange',
    '(0018,5040)': 'TotalGain',
    '(0018,5050)': 'DepthOfScanField',
    '(0018,5100)': 'PatientPosition',
    '(0018,5101)': 'ViewPosition',
    '(0018,5104)': 'ProjectionEponymousNameCodeSequence',
    '(0018,5210)': 'ImageTransformationMatrix',
    '(0018,5212)': 'ImageTranslationVector',
    '(0018,6000)': 'Sensitivity',
    '(0018,6011)': 'SequenceOfUltrasoundRegions',
    '(0018,6012)': 'RegionSpatialFormat',
    '(0018,6014)': 'RegionDataType',
    '(0018,6016)': 'RegionFlags',
    '(0018,6018)': 'RegionLocationMinX0',
    '(0018,601A)': 'RegionLocationMinY0',
    '(0018,601C)': 'RegionLocationMaxX1',
    '(0018,601E)': 'RegionLocationMaxY1',
    '(0018,6020)': 'ReferencePixelX0',
    '(0018,6022)': 'ReferencePixelY0',
    '(0018,6024)': 'PhysicalUnitsXDirection',
    '(0018,6026)': 'PhysicalUnitsYDirection',
    '(0018,6028)': 'ReferencePixelPhysicalValueX',
    '(0018,602A)': 'ReferencePixelPhysicalValueY',
    '(0018,602C)': 'PhysicalDeltaX',
    '(0018,602E)': 'PhysicalDeltaY',
    '(0018,6030)': 'TransducerFrequency',
    '(0018,6031)': 'TransducerType',
    '(0018,6032)': 'PulseRepetitionFrequency',
    '(0018,6034)': 'DopplerCorrectionAngle',
    '(0018,6036)': 'SteeringAngle',
    '(0018,6038)': 'DopplerSampleVolumeXPositionRetired',
    '(0018,6039)': 'DopplerSampleVolumeXPosition',
    '(0018,603A)': 'DopplerSampleVolumeYPositionRetired',
    '(0018,603B)': 'DopplerSampleVolumeYPosition',
    '(0018,603C)': 'TMLinePositionX0Retired',
    '(0018,603D)': 'TMLinePositionX0',
    '(0018,603E)': 'TMLinePositionY0Retired',
    '(0018,603F)': 'TMLinePositionY0',
    '(0018,6040)': 'TMLinePositionX1Retired',
    '(0018,6041)': 'TMLinePositionX1',
    '(0018,6042)': 'TMLinePositionY1Retired',
    '(0018,6043)': 'TMLinePositionY1',
    '(0018,6044)': 'PixelComponentOrganization',
    '(0018,6046)': 'PixelComponentMask',
    '(0018,6048)': 'PixelComponentRangeStart',
    '(0018,604A)': 'PixelComponentRangeStop',
    '(0018,604C)': 'PixelComponentPhysicalUnits',
    '(0018,604E)': 'PixelComponentDataType',
    '(0018,6050)': 'NumberOfTableBreakPoints',
    '(0018,6052)': 'TableOfXBreakPoints',
    '(0018,6054)': 'TableOfYBreakPoints',
    '(0018,6056)': 'NumberOfTableEntries',
    '(0018,6058)': 'TableOfPixelValues',
    '(0018,605A)': 'TableOfParameterValues',
    '(0018,6060)': 'RWaveTimeVector',
    '(0018,6070)': 'ActiveImageAreaOverlayGroup',
    '(0018,7000)': 'DetectorConditionsNominalFlag',
    '(0018,7001)': 'DetectorTemperature',
    '(0018,7004)': 'DetectorType',
    '(0018,7005)': 'DetectorConfiguration',
    '(0018,7006)': 'DetectorDescription',
    '(0018,7008)': 'DetectorMode',
    '(0018,700A)': 'DetectorID',
    '(0018,700C)': 'DateOfLastDetectorCalibration',
    '(0018,700E)': 'TimeOfLastDetectorCalibration',
    '(0018,7010)': 'ExposuresOnDetectorSinceLastCalibration',
    '(0018,7011)': 'ExposuresOnDetectorSinceManufactured',
    '(0018,7012)': 'DetectorTimeSinceLastExposure',
    '(0018,7014)': 'DetectorActiveTime',
    '(0018,7016)': 'DetectorActivationOffsetFromExposure',
    '(0018,701A)': 'DetectorBinning',
    '(0018,7020)': 'DetectorElementPhysicalSize',
    '(0018,7022)': 'DetectorElementSpacing',
    '(0018,7024)': 'DetectorActiveShape',
    '(0018,7026)': 'DetectorActiveDimensions',
    '(0018,7028)': 'DetectorActiveOrigin',
    '(0018,702A)': 'DetectorManufacturerName',
    '(0018,702B)': 'DetectorManufacturerModelName',
    '(0018,7030)': 'FieldOfViewOrigin',
    '(0018,7032)': 'FieldOfViewRotation',
    '(0018,7034)': 'FieldOfViewHorizontalFlip',
    '(0018,7036)': 'PixelDataAreaOriginRelativeToFOV',
    '(0018,7038)': 'PixelDataAreaRotationAngleRelativeToFOV',
    '(0018,7040)': 'GridAbsorbingMaterial',
    '(0018,7041)': 'GridSpacingMaterial',
    '(0018,7042)': 'GridThickness',
    '(0018,7044)': 'GridPitch',
    '(0018,7046)': 'GridAspectRatio',
    '(0018,7048)': 'GridPeriod',
    '(0018,704C)': 'GridFocalDistance',
    '(0018,7050)': 'FilterMaterial',
    '(0018,7052)': 'FilterThicknessMinimum',
    '(0018,7054)': 'FilterThicknessMaximum',
    '(0018,7056)': 'FilterBeamPathLengthMinimum',
    '(0018,7058)': 'FilterBeamPathLengthMaximum',
    '(0018,7060)': 'ExposureControlMode',
    '(0018,7062)': 'ExposureControlModeDescription',
    '(0018,7064)': 'ExposureStatus',
    '(0018,7065)': 'PhototimerSetting',
    '(0018,8150)': 'ExposureTimeInuS',
    '(0018,8151)': 'XRayTubeCurrentInuA',
    '(0018,9004)': 'ContentQualification',
    '(0018,9005)': 'PulseSequenceName',
    '(0018,9006)': 'MRImagingModifierSequence',
    '(0018,9008)': 'EchoPulseSequence',
    '(0018,9009)': 'InversionRecovery',
    '(0018,9010)': 'FlowCompensation',
    '(0018,9011)': 'MultipleSpinEcho',
    '(0018,9012)': 'MultiPlanarExcitation',
    '(0018,9014)': 'PhaseContrast',
    '(0018,9015)': 'TimeOfFlightContrast',
    '(0018,9016)': 'Spoiling',
    '(0018,9017)': 'SteadyStatePulseSequence',
    '(0018,9018)': 'EchoPlanarPulseSequence',
    '(0018,9019)': 'TagAngleFirstAxis',
    '(0018,9020)': 'MagnetizationTransfer',
    '(0018,9021)': 'T2Preparation',
    '(0018,9022)': 'BloodSignalNulling',
    '(0018,9024)': 'SaturationRecovery',
    '(0018,9025)': 'SpectrallySelectedSuppression',
    '(0018,9026)': 'SpectrallySelectedExcitation',
    '(0018,9027)': 'SpatialPresaturation',
    '(0018,9028)': 'Tagging',
    '(0018,9029)': 'OversamplingPhase',
    '(0018,9030)': 'TagSpacingFirstDimension',
    '(0018,9032)': 'GeometryOfKSpaceTraversal',
    '(0018,9033)': 'SegmentedKSpaceTraversal',
    '(0018,9034)': 'RectilinearPhaseEncodeReordering',
    '(0018,9035)': 'TagThickness',
    '(0018,9036)': 'PartialFourierDirection',
    '(0018,9037)': 'CardiacSynchronizationTechnique',
    '(0018,9041)': 'ReceiveCoilManufacturerName',
    '(0018,9042)': 'MRReceiveCoilSequence',
    '(0018,9043)': 'ReceiveCoilType',
    '(0018,9044)': 'QuadratureReceiveCoil',
    '(0018,9045)': 'MultiCoilDefinitionSequence',
    '(0018,9046)': 'MultiCoilConfiguration',
    '(0018,9047)': 'MultiCoilElementName',
    '(0018,9048)': 'MultiCoilElementUsed',
    '(0018,9049)': 'MRTransmitCoilSequence',
    '(0018,9050)': 'TransmitCoilManufacturerName',
    '(0018,9051)': 'TransmitCoilType',
    '(0018,9052)': 'SpectralWidth',
    '(0018,9053)': 'ChemicalShiftReference',
    '(0018,9054)': 'VolumeLocalizationTechnique',
    '(0018,9058)': 'MRAcquisitionFrequencyEncodingSteps',
    '(0018,9059)': 'Decoupling',
    '(0018,9060)': 'DecoupledNucleus',
    '(0018,9061)': 'DecouplingFrequency',
    '(0018,9062)': 'DecouplingMethod',
    '(0018,9063)': 'DecouplingChemicalShiftReference',
    '(0018,9064)': 'KSpaceFiltering',
    '(0018,9065)': 'TimeDomainFiltering',
    '(0018,9066)': 'NumberOfZeroFills',
    '(0018,9067)': 'BaselineCorrection',
    '(0018,9069)': 'ParallelReductionFactorInPlane',
    '(0018,9070)': 'CardiacRRIntervalSpecified',
    '(0018,9073)': 'AcquisitionDuration',
    '(0018,9074)': 'FrameAcquisitionDateTime',
    '(0018,9075)': 'DiffusionDirectionality',
    '(0018,9076)': 'DiffusionGradientDirectionSequence',
    '(0018,9077)': 'ParallelAcquisition',
    '(0018,9078)': 'ParallelAcquisitionTechnique',
    '(0018,9079)': 'InversionTimes',
    '(0018,9080)': 'MetaboliteMapDescription',
    '(0018,9081)': 'PartialFourier',
    '(0018,9082)': 'EffectiveEchoTime',
    '(0018,9083)': 'MetaboliteMapCodeSequence',
    '(0018,9084)': 'ChemicalShiftSequence',
    '(0018,9085)': 'CardiacSignalSource',
    '(0018,9087)': 'DiffusionBValue',
    '(0018,9089)': 'DiffusionGradientOrientation',
    '(0018,9090)': 'VelocityEncodingDirection',
    '(0018,9091)': 'VelocityEncodingMinimumValue',
    '(0018,9092)': 'VelocityEncodingAcquisitionSequence',
    '(0018,9093)': 'NumberOfKSpaceTrajectories',
    '(0018,9094)': 'CoverageOfKSpace',
    '(0018,9095)': 'SpectroscopyAcquisitionPhaseRows',
    '(0018,9096)': 'ParallelReductionFactorInPlaneRetired',
    '(0018,9098)': 'TransmitterFrequency',
    '(0018,9100)': 'ResonantNucleus',
    '(0018,9101)': 'FrequencyCorrection',
    '(0018,9103)': 'MRSpectroscopyFOVGeometrySequence',
    '(0018,9104)': 'SlabThickness',
    '(0018,9105)': 'SlabOrientation',
    '(0018,9106)': 'MidSlabPosition',
    '(0018,9107)': 'MRSpatialSaturationSequence',
    '(0018,9112)': 'MRTimingAndRelatedParametersSequence',
    '(0018,9114)': 'MREchoSequence',
    '(0018,9115)': 'MRModifierSequence',
    '(0018,9117)': 'MRDiffusionSequence',
    '(0018,9118)': 'CardiacSynchronizationSequence',
    '(0018,9119)': 'MRAveragesSequence',
    '(0018,9125)': 'MRFOVGeometrySequence',
    '(0018,9126)': 'VolumeLocalizationSequence',
    '(0018,9127)': 'SpectroscopyAcquisitionDataColumns',
    '(0018,9147)': 'DiffusionAnisotropyType',
    '(0018,9151)': 'FrameReferenceDateTime',
    '(0018,9152)': 'MRMetaboliteMapSequence',
    '(0018,9155)': 'ParallelReductionFactorOutOfPlane',
    '(0018,9159)': 'SpectroscopyAcquisitionOutOfPlanePhaseSteps',
    '(0018,9166)': 'BulkMotionStatus',
    '(0018,9168)': 'ParallelReductionFactorSecondInPlane',
    '(0018,9169)': 'CardiacBeatRejectionTechnique',
    '(0018,9170)': 'RespiratoryMotionCompensationTechnique',
    '(0018,9171)': 'RespiratorySignalSource',
    '(0018,9172)': 'BulkMotionCompensationTechnique',
    '(0018,9173)': 'BulkMotionSignalSource',
    '(0018,9174)': 'ApplicableSafetyStandardAgency',
    '(0018,9175)': 'ApplicableSafetyStandardDescription',
    '(0018,9176)': 'OperatingModeSequence',
    '(0018,9177)': 'OperatingModeType',
    '(0018,9178)': 'OperatingMode',
    '(0018,9179)': 'SpecificAbsorptionRateDefinition',
    '(0018,9180)': 'GradientOutputType',
    '(0018,9181)': 'SpecificAbsorptionRateValue',
    '(0018,9182)': 'GradientOutput',
    '(0018,9183)': 'FlowCompensationDirection',
    '(0018,9184)': 'TaggingDelay',
    '(0018,9185)': 'RespiratoryMotionCompensationTechniqueDescription',
    '(0018,9186)': 'RespiratorySignalSourceID',
    '(0018,9195)': 'ChemicalShiftMinimumIntegrationLimitInHz',
    '(0018,9196)': 'ChemicalShiftMaximumIntegrationLimitInHz',
    '(0018,9197)': 'MRVelocityEncodingSequence',
    '(0018,9198)': 'FirstOrderPhaseCorrection',
    '(0018,9199)': 'WaterReferencedPhaseCorrection',
    '(0018,9200)': 'MRSpectroscopyAcquisitionType',
    '(0018,9214)': 'RespiratoryCyclePosition',
    '(0018,9217)': 'VelocityEncodingMaximumValue',
    '(0018,9218)': 'TagSpacingSecondDimension',
    '(0018,9219)': 'TagAngleSecondAxis',
    '(0018,9220)': 'FrameAcquisitionDuration',
    '(0018,9226)': 'MRImageFrameTypeSequence',
    '(0018,9227)': 'MRSpectroscopyFrameTypeSequence',
    '(0018,9231)': 'MRAcquisitionPhaseEncodingStepsInPlane',
    '(0018,9232)': 'MRAcquisitionPhaseEncodingStepsOutOfPlane',
    '(0018,9234)': 'SpectroscopyAcquisitionPhaseColumns',
    '(0018,9236)': 'CardiacCyclePosition',
    '(0018,9239)': 'SpecificAbsorptionRateSequence',
    '(0018,9240)': 'RFEchoTrainLength',
    '(0018,9241)': 'GradientEchoTrainLength',
    '(0018,9250)': 'ArterialSpinLabelingContrast',
    '(0018,9251)': 'MRArterialSpinLabelingSequence',
    '(0018,9252)': 'ASLTechniqueDescription',
    '(0018,9253)': 'ASLSlabNumber',
    '(0018,9254)': 'ASLSlabThickness',
    '(0018,9255)': 'ASLSlabOrientation',
    '(0018,9256)': 'ASLMidSlabPosition',
    '(0018,9257)': 'ASLContext',
    '(0018,9258)': 'ASLPulseTrainDuration',
    '(0018,9259)': 'ASLCrusherFlag',
    '(0018,925A)': 'ASLCrusherFlowLimit',
    '(0018,925B)': 'ASLCrusherDescription',
    '(0018,925C)': 'ASLBolusCutoffFlag',
    '(0018,925D)': 'ASLBolusCutoffTimingSequence',
    '(0018,925E)': 'ASLBolusCutoffTechnique',
    '(0018,925F)': 'ASLBolusCutoffDelayTime',
    '(0018,9260)': 'ASLSlabSequence',
    '(0018,9295)': 'ChemicalShiftMinimumIntegrationLimitInppm',
    '(0018,9296)': 'ChemicalShiftMaximumIntegrationLimitInppm',
    '(0018,9297)': 'WaterReferenceAcquisition',
    '(0018,9298)': 'EchoPeakPosition',
    '(0018,9301)': 'CTAcquisitionTypeSequence',
    '(0018,9302)': 'AcquisitionType',
    '(0018,9303)': 'TubeAngle',
    '(0018,9304)': 'CTAcquisitionDetailsSequence',
    '(0018,9305)': 'RevolutionTime',
    '(0018,9306)': 'SingleCollimationWidth',
    '(0018,9307)': 'TotalCollimationWidth',
    '(0018,9308)': 'CTTableDynamicsSequence',
    '(0018,9309)': 'TableSpeed',
    '(0018,9310)': 'TableFeedPerRotation',
    '(0018,9311)': 'SpiralPitchFactor',
    '(0018,9312)': 'CTGeometrySequence',
    '(0018,9313)': 'DataCollectionCenterPatient',
    '(0018,9314)': 'CTReconstructionSequence',
    '(0018,9315)': 'ReconstructionAlgorithm',
    '(0018,9316)': 'ConvolutionKernelGroup',
    '(0018,9317)': 'ReconstructionFieldOfView',
    '(0018,9318)': 'ReconstructionTargetCenterPatient',
    '(0018,9319)': 'ReconstructionAngle',
    '(0018,9320)': 'ImageFilter',
    '(0018,9321)': 'CTExposureSequence',
    '(0018,9322)': 'ReconstructionPixelSpacing',
    '(0018,9323)': 'ExposureModulationType',
    '(0018,9324)': 'EstimatedDoseSaving',
    '(0018,9325)': 'CTXRayDetailsSequence',
    '(0018,9326)': 'CTPositionSequence',
    '(0018,9327)': 'TablePosition',
    '(0018,9328)': 'ExposureTimeInms',
    '(0018,9329)': 'CTImageFrameTypeSequence',
    '(0018,9330)': 'XRayTubeCurrentInmA',
    '(0018,9332)': 'ExposureInmAs',
    '(0018,9333)': 'ConstantVolumeFlag',
    '(0018,9334)': 'FluoroscopyFlag',
    '(0018,9335)': 'DistanceSourceToDataCollectionCenter',
    '(0018,9337)': 'ContrastBolusAgentNumber',
    '(0018,9338)': 'ContrastBolusIngredientCodeSequence',
    '(0018,9340)': 'ContrastAdministrationProfileSequence',
    '(0018,9341)': 'ContrastBolusUsageSequence',
    '(0018,9342)': 'ContrastBolusAgentAdministered',
    '(0018,9343)': 'ContrastBolusAgentDetected',
    '(0018,9344)': 'ContrastBolusAgentPhase',
    '(0018,9345)': 'CTDIvol',
    '(0018,9346)': 'CTDIPhantomTypeCodeSequence',
    '(0018,9351)': 'CalciumScoringMassFactorPatient',
    '(0018,9352)': 'CalciumScoringMassFactorDevice',
    '(0018,9353)': 'EnergyWeightingFactor',
    '(0018,9360)': 'CTAdditionalXRaySourceSequence',
    '(0018,9361)': 'MultienergyCTAcquisition',
    '(0018,9362)': 'MultienergyCTAcquisitionSequence',
    '(0018,9363)': 'MultienergyCTProcessingSequence',
    '(0018,9364)': 'MultienergyCTCharacteristicsSequence',
    '(0018,9365)': 'MultienergyCTXRaySourceSequence',
    '(0018,9366)': 'XRaySourceIndex',
    '(0018,9367)': 'XRaySourceID',
    '(0018,9368)': 'MultienergySourceTechnique',
    '(0018,9369)': 'SourceStartDateTime',
    '(0018,936A)': 'SourceEndDateTime',
    '(0018,936B)': 'SwitchingPhaseNumber',
    '(0018,936C)': 'SwitchingPhaseNominalDuration',
    '(0018,936D)': 'SwitchingPhaseTransitionDuration',
    '(0018,936E)': 'EffectiveBinEnergy',
    '(0018,936F)': 'MultienergyCTXRayDetectorSequence',
    '(0018,9370)': 'XRayDetectorIndex',
    '(0018,9371)': 'XRayDetectorID',
    '(0018,9372)': 'MultienergyDetectorType',
    '(0018,9373)': 'XRayDetectorLabel',
    '(0018,9374)': 'NominalMaxEnergy',
    '(0018,9375)': 'NominalMinEnergy',
    '(0018,9376)': 'ReferencedXRayDetectorIndex',
    '(0018,9377)': 'ReferencedXRaySourceIndex',
    '(0018,9378)': 'ReferencedPathIndex',
    '(0018,9379)': 'MultienergyCTPathSequence',
    '(0018,937A)': 'MultienergyCTPathIndex',
    '(0018,937B)': 'MultienergyAcquisitionDescription',
    '(0018,937C)': 'MonoenergeticEnergyEquivalent',
    '(0018,937D)': 'MaterialCodeSequence',
    '(0018,937E)': 'DecompositionMethod',
    '(0018,937F)': 'DecompositionDescription',
    '(0018,9380)': 'DecompositionAlgorithmIdentificationSequence',
    '(0018,9381)': 'DecompositionMaterialSequence',
    '(0018,9382)': 'MaterialAttenuationSequence',
    '(0018,9383)': 'PhotonEnergy',
    '(0018,9384)': 'XRayMassAttenuationCoefficient',
    '(0018,9401)': 'ProjectionPixelCalibrationSequence',
    '(0018,9402)': 'DistanceSourceToIsocenter',
    '(0018,9403)': 'DistanceObjectToTableTop',
    '(0018,9404)': 'ObjectPixelSpacingInCenterOfBeam',
    '(0018,9405)': 'PositionerPositionSequence',
    '(0018,9406)': 'TablePositionSequence',
    '(0018,9407)': 'CollimatorShapeSequence',
    '(0018,9410)': 'PlanesInAcquisition',
    '(0018,9412)': 'XAXRFFrameCharacteristicsSequence',
    '(0018,9417)': 'FrameAcquisitionSequence',
    '(0018,9420)': 'XRayReceptorType',
    '(0018,9423)': 'AcquisitionProtocolName',
    '(0018,9424)': 'AcquisitionProtocolDescription',
    '(0018,9425)': 'ContrastBolusIngredientOpaque',
    '(0018,9426)': 'DistanceReceptorPlaneToDetectorHousing',
    '(0018,9427)': 'IntensifierActiveShape',
    '(0018,9428)': 'IntensifierActiveDimensions',
    '(0018,9429)': 'PhysicalDetectorSize',
    '(0018,9430)': 'PositionOfIsocenterProjection',
    '(0018,9432)': 'FieldOfViewSequence',
    '(0018,9433)': 'FieldOfViewDescription',
    '(0018,9434)': 'ExposureControlSensingRegionsSequence',
    '(0018,9435)': 'ExposureControlSensingRegionShape',
    '(0018,9436)': 'ExposureControlSensingRegionLeftVerticalEdge',
    '(0018,9437)': 'ExposureControlSensingRegionRightVerticalEdge',
    '(0018,9438)': 'ExposureControlSensingRegionUpperHorizontalEdge',
    '(0018,9439)': 'ExposureControlSensingRegionLowerHorizontalEdge',
    '(0018,9440)': 'CenterOfCircularExposureControlSensingRegion',
    '(0018,9441)': 'RadiusOfCircularExposureControlSensingRegion',
    '(0018,9442)': 'VerticesOfThePolygonalExposureControlSensingRegion',
    '(0018,9447)': 'ColumnAngulationPatient',
    '(0018,9449)': 'BeamAngle',
    '(0018,9451)': 'FrameDetectorParametersSequence',
    '(0018,9452)': 'CalculatedAnatomyThickness',
    '(0018,9455)': 'CalibrationSequence',
    '(0018,9456)': 'ObjectThicknessSequence',
    '(0018,9457)': 'PlaneIdentification',
    '(0018,9461)': 'FieldOfViewDimensionsInFloat',
    '(0018,9462)': 'IsocenterReferenceSystemSequence',
    '(0018,9463)': 'PositionerIsocenterPrimaryAngle',
    '(0018,9464)': 'PositionerIsocenterSecondaryAngle',
    '(0018,9465)': 'PositionerIsocenterDetectorRotationAngle',
    '(0018,9466)': 'TableXPositionToIsocenter',
    '(0018,9467)': 'TableYPositionToIsocenter',
    '(0018,9468)': 'TableZPositionToIsocenter',
    '(0018,9469)': 'TableHorizontalRotationAngle',
    '(0018,9470)': 'TableHeadTiltAngle',
    '(0018,9471)': 'TableCradleTiltAngle',
    '(0018,9472)': 'FrameDisplayShutterSequence',
    '(0018,9473)': 'AcquiredImageAreaDoseProduct',
    '(0018,9474)': 'CArmPositionerTabletopRelationship',
    '(0018,9476)': 'XRayGeometrySequence',
    '(0018,9477)': 'IrradiationEventIdentificationSequence',
    '(0018,9504)': 'XRay3DFrameTypeSequence',
    '(0018,9506)': 'ContributingSourcesSequence',
    '(0018,9507)': 'XRay3DAcquisitionSequence',
    '(0018,9508)': 'PrimaryPositionerScanArc',
    '(0018,9509)': 'SecondaryPositionerScanArc',
    '(0018,9510)': 'PrimaryPositionerScanStartAngle',
    '(0018,9511)': 'SecondaryPositionerScanStartAngle',
    '(0018,9514)': 'PrimaryPositionerIncrement',
    '(0018,9515)': 'SecondaryPositionerIncrement',
    '(0018,9516)': 'StartAcquisitionDateTime',
    '(0018,9517)': 'EndAcquisitionDateTime',
    '(0018,9518)': 'PrimaryPositionerIncrementSign',
    '(0018,9519)': 'SecondaryPositionerIncrementSign',
    '(0018,9524)': 'ApplicationName',
    '(0018,9525)': 'ApplicationVersion',
    '(0018,9526)': 'ApplicationManufacturer',
    '(0018,9527)': 'AlgorithmType',
    '(0018,9528)': 'AlgorithmDescription',
    '(0018,9530)': 'XRay3DReconstructionSequence',
    '(0018,9531)': 'ReconstructionDescription',
    '(0018,9538)': 'PerProjectionAcquisitionSequence',
    '(0018,9541)': 'DetectorPositionSequence',
    '(0018,9542)': 'XRayAcquisitionDoseSequence',
    '(0018,9543)': 'XRaySourceIsocenterPrimaryAngle',
    '(0018,9544)': 'XRaySourceIsocenterSecondaryAngle',
    '(0018,9545)': 'BreastSupportIsocenterPrimaryAngle',
    '(0018,9546)': 'BreastSupportIsocenterSecondaryAngle',
    '(0018,9547)': 'BreastSupportXPositionToIsocenter',
    '(0018,9548)': 'BreastSupportYPositionToIsocenter',
    '(0018,9549)': 'BreastSupportZPositionToIsocenter',
    '(0018,9550)': 'DetectorIsocenterPrimaryAngle',
    '(0018,9551)': 'DetectorIsocenterSecondaryAngle',
    '(0018,9552)': 'DetectorXPositionToIsocenter',
    '(0018,9553)': 'DetectorYPositionToIsocenter',
    '(0018,9554)': 'DetectorZPositionToIsocenter',
    '(0018,9555)': 'XRayGridSequence',
    '(0018,9556)': 'XRayFilterSequence',
    '(0018,9557)': 'DetectorActiveAreaTLHCPosition',
    '(0018,9558)': 'DetectorActiveAreaOrientation',
    '(0018,9559)': 'PositionerPrimaryAngleDirection',
    '(0018,9601)': 'DiffusionBMatrixSequence',
    '(0018,9602)': 'DiffusionBValueXX',
    '(0018,9603)': 'DiffusionBValueXY',
    '(0018,9604)': 'DiffusionBValueXZ',
    '(0018,9605)': 'DiffusionBValueYY',
    '(0018,9606)': 'DiffusionBValueYZ',
    '(0018,9607)': 'DiffusionBValueZZ',
    '(0018,9621)': 'FunctionalMRSequence',
    '(0018,9622)': 'FunctionalSettlingPhaseFramesPresent',
    '(0018,9623)': 'FunctionalSyncPulse',
    '(0018,9624)': 'SettlingPhaseFrame',
    '(0018,9701)': 'DecayCorrectionDateTime',
    '(0018,9715)': 'StartDensityThreshold',
    '(0018,9716)': 'StartRelativeDensityDifferenceThreshold',
    '(0018,9717)': 'StartCardiacTriggerCountThreshold',
    '(0018,9718)': 'StartRespiratoryTriggerCountThreshold',
    '(0018,9719)': 'TerminationCountsThreshold',
    '(0018,9720)': 'TerminationDensityThreshold',
    '(0018,9721)': 'TerminationRelativeDensityThreshold',
    '(0018,9722)': 'TerminationTimeThreshold',
    '(0018,9723)': 'TerminationCardiacTriggerCountThreshold',
    '(0018,9724)': 'TerminationRespiratoryTriggerCountThreshold',
    '(0018,9725)': 'DetectorGeometry',
    '(0018,9726)': 'TransverseDetectorSeparation',
    '(0018,9727)': 'AxialDetectorDimension',
    '(0018,9729)': 'RadiopharmaceuticalAgentNumber',
    '(0018,9732)': 'PETFrameAcquisitionSequence',
    '(0018,9733)': 'PETDetectorMotionDetailsSequence',
    '(0018,9734)': 'PETTableDynamicsSequence',
    '(0018,9735)': 'PETPositionSequence',
    '(0018,9736)': 'PETFrameCorrectionFactorsSequence',
    '(0018,9737)': 'RadiopharmaceuticalUsageSequence',
    '(0018,9738)': 'AttenuationCorrectionSource',
    '(0018,9739)': 'NumberOfIterations',
    '(0018,9740)': 'NumberOfSubsets',
    '(0018,9749)': 'PETReconstructionSequence',
    '(0018,9751)': 'PETFrameTypeSequence',
    '(0018,9755)': 'TimeOfFlightInformationUsed',
    '(0018,9756)': 'ReconstructionType',
    '(0018,9758)': 'DecayCorrected',
    '(0018,9759)': 'AttenuationCorrected',
    '(0018,9760)': 'ScatterCorrected',
    '(0018,9761)': 'DeadTimeCorrected',
    '(0018,9762)': 'GantryMotionCorrected',
    '(0018,9763)': 'PatientMotionCorrected',
    '(0018,9764)': 'CountLossNormalizationCorrected',
    '(0018,9765)': 'RandomsCorrected',
    '(0018,9766)': 'NonUniformRadialSamplingCorrected',
    '(0018,9767)': 'SensitivityCalibrated',
    '(0018,9768)': 'DetectorNormalizationCorrection',
    '(0018,9769)': 'IterativeReconstructionMethod',
    '(0018,9770)': 'AttenuationCorrectionTemporalRelationship',
    '(0018,9771)': 'PatientPhysiologicalStateSequence',
    '(0018,9772)': 'PatientPhysiologicalStateCodeSequence',
    '(0018,9801)': 'DepthsOfFocus',
    '(0018,9803)': 'ExcludedIntervalsSequence',
    '(0018,9804)': 'ExclusionStartDateTime',
    '(0018,9805)': 'ExclusionDuration',
    '(0018,9806)': 'USImageDescriptionSequence',
    '(0018,9807)': 'ImageDataTypeSequence',
    '(0018,9808)': 'DataType',
    '(0018,9809)': 'TransducerScanPatternCodeSequence',
    '(0018,980B)': 'AliasedDataType',
    '(0018,980C)': 'PositionMeasuringDeviceUsed',
    '(0018,980D)': 'TransducerGeometryCodeSequence',
    '(0018,980E)': 'TransducerBeamSteeringCodeSequence',
    '(0018,980F)': 'TransducerApplicationCodeSequence',
    '(0018,9810)': 'ZeroVelocityPixelValue',
    '(0018,9900)': 'ReferenceLocationLabel',
    '(0018,9901)': 'ReferenceLocationDescription',
    '(0018,9902)': 'ReferenceBasisCodeSequence',
    '(0018,9903)': 'ReferenceGeometryCodeSequence',
    '(0018,9904)': 'OffsetDistance',
    '(0018,9905)': 'OffsetDirection',
    '(0018,9906)': 'PotentialScheduledProtocolCodeSequence',
    '(0018,9907)': 'PotentialRequestedProcedureCodeSequence',
    '(0018,9908)': 'PotentialReasonsForProcedure',
    '(0018,9909)': 'PotentialReasonsForProcedureCodeSequence',
    '(0018,990A)': 'PotentialDiagnosticTasks',
    '(0018,990B)': 'ContraindicationsCodeSequence',
    '(0018,990C)': 'ReferencedDefinedProtocolSequence',
    '(0018,990D)': 'ReferencedPerformedProtocolSequence',
    '(0018,990E)': 'PredecessorProtocolSequence',
    '(0018,990F)': 'ProtocolPlanningInformation',
    '(0018,9910)': 'ProtocolDesignRationale',
    '(0018,9911)': 'PatientSpecificationSequence',
    '(0018,9912)': 'ModelSpecificationSequence',
    '(0018,9913)': 'ParametersSpecificationSequence',
    '(0018,9914)': 'InstructionSequence',
    '(0018,9915)': 'InstructionIndex',
    '(0018,9916)': 'InstructionText',
    '(0018,9917)': 'InstructionDescription',
    '(0018,9918)': 'InstructionPerformedFlag',
    '(0018,9919)': 'InstructionPerformedDateTime',
    '(0018,991A)': 'InstructionPerformanceComment',
    '(0018,991B)': 'PatientPositioningInstructionSequence',
    '(0018,991C)': 'PositioningMethodCodeSequence',
    '(0018,991D)': 'PositioningLandmarkSequence',
    '(0018,991E)': 'TargetFrameOfReferenceUID',
    '(0018,991F)': 'AcquisitionProtocolElementSpecificationSequence',
    '(0018,9920)': 'AcquisitionProtocolElementSequence',
    '(0018,9921)': 'ProtocolElementNumber',
    '(0018,9922)': 'ProtocolElementName',
    '(0018,9923)': 'ProtocolElementCharacteristicsSummary',
    '(0018,9924)': 'ProtocolElementPurpose',
    '(0018,9930)': 'AcquisitionMotion',
    '(0018,9931)': 'AcquisitionStartLocationSequence',
    '(0018,9932)': 'AcquisitionEndLocationSequence',
    '(0018,9933)': 'ReconstructionProtocolElementSpecificationSequence',
    '(0018,9934)': 'ReconstructionProtocolElementSequence',
    '(0018,9935)': 'StorageProtocolElementSpecificationSequence',
    '(0018,9936)': 'StorageProtocolElementSequence',
    '(0018,9937)': 'RequestedSeriesDescription',
    '(0018,9938)': 'SourceAcquisitionProtocolElementNumber',
    '(0018,9939)': 'SourceAcquisitionBeamNumber',
    '(0018,993A)': 'SourceReconstructionProtocolElementNumber',
    '(0018,993B)': 'ReconstructionStartLocationSequence',
    '(0018,993C)': 'ReconstructionEndLocationSequence',
    '(0018,993D)': 'ReconstructionAlgorithmSequence',
    '(0018,993E)': 'ReconstructionTargetCenterLocationSequence',
    '(0018,9941)': 'ImageFilterDescription',
    '(0018,9942)': 'CTDIvolNotificationTrigger',
    '(0018,9943)': 'DLPNotificationTrigger',
    '(0018,9944)': 'AutoKVPSelectionType',
    '(0018,9945)': 'AutoKVPUpperBound',
    '(0018,9946)': 'AutoKVPLowerBound',
    '(0018,9947)': 'ProtocolDefinedPatientPosition',
    '(0018,A001)': 'ContributingEquipmentSequence',
    '(0018,A002)': 'ContributionDateTime',
    '(0018,A003)': 'ContributionDescription',
    '(0020,000D)': 'StudyInstanceUID',
    '(0020,000E)': 'SeriesInstanceUID',
    '(0020,0010)': 'StudyID',
    '(0020,0011)': 'SeriesNumber',
    '(0020,0012)': 'AcquisitionNumber',
    '(0020,0013)': 'InstanceNumber',
    '(0020,0014)': 'IsotopeNumber',
    '(0020,0015)': 'PhaseNumber',
    '(0020,0016)': 'IntervalNumber',
    '(0020,0017)': 'TimeSlotNumber',
    '(0020,0018)': 'AngleNumber',
    '(0020,0019)': 'ItemNumber',
    '(0020,0020)': 'PatientOrientation',
    '(0020,0022)': 'OverlayNumber',
    '(0020,0024)': 'CurveNumber',
    '(0020,0026)': 'LUTNumber',
    '(0020,0030)': 'ImagePosition',
    '(0020,0032)': 'ImagePositionPatient',
    '(0020,0035)': 'ImageOrientation',
    '(0020,0037)': 'ImageOrientationPatient',
    '(0020,0050)': 'Location',
    '(0020,0052)': 'FrameOfReferenceUID',
    '(0020,0060)': 'Laterality',
    '(0020,0062)': 'ImageLaterality',
    '(0020,0070)': 'ImageGeometryType',
    '(0020,0080)': 'MaskingImage',
    '(0020,00AA)': 'ReportNumber',
    '(0020,0100)': 'TemporalPositionIdentifier',
    '(0020,0105)': 'NumberOfTemporalPositions',
    '(0020,0110)': 'TemporalResolution',
    '(0020,0200)': 'SynchronizationFrameOfReferenceUID',
    '(0020,0242)': 'SOPInstanceUIDOfConcatenationSource',
    '(0020,1000)': 'SeriesInStudy',
    '(0020,1001)': 'AcquisitionsInSeries',
    '(0020,1002)': 'ImagesInAcquisition',
    '(0020,1003)': 'ImagesInSeries',
    '(0020,1004)': 'AcquisitionsInStudy',
    '(0020,1005)': 'ImagesInStudy',
    '(0020,1020)': 'Reference',
    '(0020,103F)': 'TargetPositionReferenceIndicator',
    '(0020,1040)': 'PositionReferenceIndicator',
    '(0020,1041)': 'SliceLocation',
    '(0020,1070)': 'OtherStudyNumbers',
    '(0020,1200)': 'NumberOfPatientRelatedStudies',
    '(0020,1202)': 'NumberOfPatientRelatedSeries',
    '(0020,1204)': 'NumberOfPatientRelatedInstances',
    '(0020,1206)': 'NumberOfStudyRelatedSeries',
    '(0020,1208)': 'NumberOfStudyRelatedInstances',
    '(0020,1209)': 'NumberOfSeriesRelatedInstances',
    '(0020,31xx)': 'SourceImageIDs',
    '(0020,3401)': 'ModifyingDeviceID',
    '(0020,3402)': 'ModifiedImageID',
    '(0020,3403)': 'ModifiedImageDate',
    '(0020,3404)': 'ModifyingDeviceManufacturer',
    '(0020,3405)': 'ModifiedImageTime',
    '(0020,3406)': 'ModifiedImageDescription',
    '(0020,4000)': 'ImageComments',
    '(0020,5000)': 'OriginalImageIdentification',
    '(0020,5002)': 'OriginalImageIdentificationNomenclature',
    '(0020,9056)': 'StackID',
    '(0020,9057)': 'InStackPositionNumber',
    '(0020,9071)': 'FrameAnatomySequence',
    '(0020,9072)': 'FrameLaterality',
    '(0020,9111)': 'FrameContentSequence',
    '(0020,9113)': 'PlanePositionSequence',
    '(0020,9116)': 'PlaneOrientationSequence',
    '(0020,9128)': 'TemporalPositionIndex',
    '(0020,9153)': 'NominalCardiacTriggerDelayTime',
    '(0020,9154)': 'NominalCardiacTriggerTimePriorToRPeak',
    '(0020,9155)': 'ActualCardiacTriggerTimePriorToRPeak',
    '(0020,9156)': 'FrameAcquisitionNumber',
    '(0020,9157)': 'DimensionIndexValues',
    '(0020,9158)': 'FrameComments',
    '(0020,9161)': 'ConcatenationUID',
    '(0020,9162)': 'InConcatenationNumber',
    '(0020,9163)': 'InConcatenationTotalNumber',
    '(0020,9164)': 'DimensionOrganizationUID',
    '(0020,9165)': 'DimensionIndexPointer',
    '(0020,9167)': 'FunctionalGroupPointer',
    '(0020,9170)': 'UnassignedSharedConvertedAttributesSequence',
    '(0020,9171)': 'UnassignedPerFrameConvertedAttributesSequence',
    '(0020,9172)': 'ConversionSourceAttributesSequence',
    '(0020,9213)': 'DimensionIndexPrivateCreator',
    '(0020,9221)': 'DimensionOrganizationSequence',
    '(0020,9222)': 'DimensionIndexSequence',
    '(0020,9228)': 'ConcatenationFrameOffsetNumber',
    '(0020,9238)': 'FunctionalGroupPrivateCreator',
    '(0020,9241)': 'NominalPercentageOfCardiacPhase',
    '(0020,9245)': 'NominalPercentageOfRespiratoryPhase',
    '(0020,9246)': 'StartingRespiratoryAmplitude',
    '(0020,9247)': 'StartingRespiratoryPhase',
    '(0020,9248)': 'EndingRespiratoryAmplitude',
    '(0020,9249)': 'EndingRespiratoryPhase',
    '(0020,9250)': 'RespiratoryTriggerType',
    '(0020,9251)': 'RRIntervalTimeNominal',
    '(0020,9252)': 'ActualCardiacTriggerDelayTime',
    '(0020,9253)': 'RespiratorySynchronizationSequence',
    '(0020,9254)': 'RespiratoryIntervalTime',
    '(0020,9255)': 'NominalRespiratoryTriggerDelayTime',
    '(0020,9256)': 'RespiratoryTriggerDelayThreshold',
    '(0020,9257)': 'ActualRespiratoryTriggerDelayTime',
    '(0020,9301)': 'ImagePositionVolume',
    '(0020,9302)': 'ImageOrientationVolume',
    '(0020,9307)': 'UltrasoundAcquisitionGeometry',
    '(0020,9308)': 'ApexPosition',
    '(0020,9309)': 'VolumeToTransducerMappingMatrix',
    '(0020,930A)': 'VolumeToTableMappingMatrix',
    '(0020,930B)': 'VolumeToTransducerRelationship',
    '(0020,930C)': 'PatientFrameOfReferenceSource',
    '(0020,930D)': 'TemporalPositionTimeOffset',
    '(0020,930E)': 'PlanePositionVolumeSequence',
    '(0020,930F)': 'PlaneOrientationVolumeSequence',
    '(0020,9310)': 'TemporalPositionSequence',
    '(0020,9311)': 'DimensionOrganizationType',
    '(0020,9312)': 'VolumeFrameOfReferenceUID',
    '(0020,9313)': 'TableFrameOfReferenceUID',
    '(0020,9421)': 'DimensionDescriptionLabel',
    '(0020,9450)': 'PatientOrientationInFrameSequence',
    '(0020,9453)': 'FrameLabel',
    '(0020,9518)': 'AcquisitionIndex',
    '(0020,9529)': 'ContributingSOPInstancesReferenceSequence',
    '(0020,9536)': 'ReconstructionIndex',
    '(0022,0001)': 'LightPathFilterPassThroughWavelength',
    '(0022,0002)': 'LightPathFilterPassBand',
    '(0022,0003)': 'ImagePathFilterPassThroughWavelength',
    '(0022,0004)': 'ImagePathFilterPassBand',
    '(0022,0005)': 'PatientEyeMovementCommanded',
    '(0022,0006)': 'PatientEyeMovementCommandCodeSequence',
    '(0022,0007)': 'SphericalLensPower',
    '(0022,0008)': 'CylinderLensPower',
    '(0022,0009)': 'CylinderAxis',
    '(0022,000A)': 'EmmetropicMagnification',
    '(0022,000B)': 'IntraOcularPressure',
    '(0022,000C)': 'HorizontalFieldOfView',
    '(0022,000D)': 'PupilDilated',
    '(0022,000E)': 'DegreeOfDilation',
    '(0022,0010)': 'StereoBaselineAngle',
    '(0022,0011)': 'StereoBaselineDisplacement',
    '(0022,0012)': 'StereoHorizontalPixelOffset',
    '(0022,0013)': 'StereoVerticalPixelOffset',
    '(0022,0014)': 'StereoRotation',
    '(0022,0015)': 'AcquisitionDeviceTypeCodeSequence',
    '(0022,0016)': 'IlluminationTypeCodeSequence',
    '(0022,0017)': 'LightPathFilterTypeStackCodeSequence',
    '(0022,0018)': 'ImagePathFilterTypeStackCodeSequence',
    '(0022,0019)': 'LensesCodeSequence',
    '(0022,001A)': 'ChannelDescriptionCodeSequence',
    '(0022,001B)': 'RefractiveStateSequence',
    '(0022,001C)': 'MydriaticAgentCodeSequence',
    '(0022,001D)': 'RelativeImagePositionCodeSequence',
    '(0022,001E)': 'CameraAngleOfView',
    '(0022,0020)': 'StereoPairsSequence',
    '(0022,0021)': 'LeftImageSequence',
    '(0022,0022)': 'RightImageSequence',
    '(0022,0028)': 'StereoPairsPresent',
    '(0022,0030)': 'AxialLengthOfTheEye',
    '(0022,0031)': 'OphthalmicFrameLocationSequence',
    '(0022,0032)': 'ReferenceCoordinates',
    '(0022,0035)': 'DepthSpatialResolution',
    '(0022,0036)': 'MaximumDepthDistortion',
    '(0022,0037)': 'AlongScanSpatialResolution',
    '(0022,0038)': 'MaximumAlongScanDistortion',
    '(0022,0039)': 'OphthalmicImageOrientation',
    '(0022,0041)': 'DepthOfTransverseImage',
    '(0022,0042)': 'MydriaticAgentConcentrationUnitsSequence',
    '(0022,0048)': 'AcrossScanSpatialResolution',
    '(0022,0049)': 'MaximumAcrossScanDistortion',
    '(0022,004E)': 'MydriaticAgentConcentration',
    '(0022,0055)': 'IlluminationWaveLength',
    '(0022,0056)': 'IlluminationPower',
    '(0022,0057)': 'IlluminationBandwidth',
    '(0022,0058)': 'MydriaticAgentSequence',
    '(0022,1007)': 'OphthalmicAxialMeasurementsRightEyeSequence',
    '(0022,1008)': 'OphthalmicAxialMeasurementsLeftEyeSequence',
    '(0022,1009)': 'OphthalmicAxialMeasurementsDeviceType',
    '(0022,1010)': 'OphthalmicAxialLengthMeasurementsType',
    '(0022,1012)': 'OphthalmicAxialLengthSequence',
    '(0022,1019)': 'OphthalmicAxialLength',
    '(0022,1024)': 'LensStatusCodeSequence',
    '(0022,1025)': 'VitreousStatusCodeSequence',
    '(0022,1028)': 'IOLFormulaCodeSequence',
    '(0022,1029)': 'IOLFormulaDetail',
    '(0022,1033)': 'KeratometerIndex',
    '(0022,1035)': 'SourceOfOphthalmicAxialLengthCodeSequence',
    '(0022,1036)': 'SourceOfCornealSizeDataCodeSequence',
    '(0022,1037)': 'TargetRefraction',
    '(0022,1039)': 'RefractiveProcedureOccurred',
    '(0022,1040)': 'RefractiveSurgeryTypeCodeSequence',
    '(0022,1044)': 'OphthalmicUltrasoundMethodCodeSequence',
    '(0022,1045)': 'SurgicallyInducedAstigmatismSequence',
    '(0022,1046)': 'TypeOfOpticalCorrection',
    '(0022,1047)': 'ToricIOLPowerSequence',
    '(0022,1048)': 'PredictedToricErrorSequence',
    '(0022,1049)': 'PreSelectedForImplantation',
    '(0022,104A)': 'ToricIOLPowerForExactEmmetropiaSequence',
    '(0022,104B)': 'ToricIOLPowerForExactTargetRefractionSequence',
    '(0022,1050)': 'OphthalmicAxialLengthMeasurementsSequence',
    '(0022,1053)': 'IOLPower',
    '(0022,1054)': 'PredictedRefractiveError',
    '(0022,1059)': 'OphthalmicAxialLengthVelocity',
    '(0022,1065)': 'LensStatusDescription',
    '(0022,1066)': 'VitreousStatusDescription',
    '(0022,1090)': 'IOLPowerSequence',
    '(0022,1092)': 'LensConstantSequence',
    '(0022,1093)': 'IOLManufacturer',
    '(0022,1094)': 'LensConstantDescription',
    '(0022,1095)': 'ImplantName',
    '(0022,1096)': 'KeratometryMeasurementTypeCodeSequence',
    '(0022,1097)': 'ImplantPartNumber',
    '(0022,1100)': 'ReferencedOphthalmicAxialMeasurementsSequence',
    '(0022,1101)': 'OphthalmicAxialLengthMeasurementsSegmentNameCodeSequence',
    '(0022,1103)': 'RefractiveErrorBeforeRefractiveSurgeryCodeSequence',
    '(0022,1121)': 'IOLPowerForExactEmmetropia',
    '(0022,1122)': 'IOLPowerForExactTargetRefraction',
    '(0022,1125)': 'AnteriorChamberDepthDefinitionCodeSequence',
    '(0022,1127)': 'LensThicknessSequence',
    '(0022,1128)': 'AnteriorChamberDepthSequence',
    '(0022,112A)': 'CalculationCommentSequence',
    '(0022,112B)': 'CalculationCommentType',
    '(0022,112C)': 'CalculationComment',
    '(0022,1130)': 'LensThickness',
    '(0022,1131)': 'AnteriorChamberDepth',
    '(0022,1132)': 'SourceOfLensThicknessDataCodeSequence',
    '(0022,1133)': 'SourceOfAnteriorChamberDepthDataCodeSequence',
    '(0022,1134)': 'SourceOfRefractiveMeasurementsSequence',
    '(0022,1135)': 'SourceOfRefractiveMeasurementsCodeSequence',
    '(0022,1140)': 'OphthalmicAxialLengthMeasurementModified',
    '(0022,1150)': 'OphthalmicAxialLengthDataSourceCodeSequence',
    '(0022,1153)': 'OphthalmicAxialLengthAcquisitionMethodCodeSequence',
    '(0022,1155)': 'SignalToNoiseRatio',
    '(0022,1159)': 'OphthalmicAxialLengthDataSourceDescription',
    '(0022,1210)': 'OphthalmicAxialLengthMeasurementsTotalLengthSequence',
    '(0022,1211)': 'OphthalmicAxialLengthMeasurementsSegmentalLengthSequence',
    '(0022,1212)': 'OphthalmicAxialLengthMeasurementsLengthSummationSequence',
    '(0022,1220)': 'UltrasoundOphthalmicAxialLengthMeasurementsSequence',
    '(0022,1225)': 'OpticalOphthalmicAxialLengthMeasurementsSequence',
    '(0022,1230)': 'UltrasoundSelectedOphthalmicAxialLengthSequence',
    '(0022,1250)': 'OphthalmicAxialLengthSelectionMethodCodeSequence',
    '(0022,1255)': 'OpticalSelectedOphthalmicAxialLengthSequence',
    '(0022,1257)': 'SelectedSegmentalOphthalmicAxialLengthSequence',
    '(0022,1260)': 'SelectedTotalOphthalmicAxialLengthSequence',
    '(0022,1262)': 'OphthalmicAxialLengthQualityMetricSequence',
    '(0022,1265)': 'OphthalmicAxialLengthQualityMetricTypeCodeSequence',
    '(0022,1273)': 'OphthalmicAxialLengthQualityMetricTypeDescription',
    '(0022,1300)': 'IntraocularLensCalculationsRightEyeSequence',
    '(0022,1310)': 'IntraocularLensCalculationsLeftEyeSequence',
    '(0022,1330)': 'ReferencedOphthalmicAxialLengthMeasurementQCImageSequence',
    '(0022,1415)': 'OphthalmicMappingDeviceType',
    '(0022,1420)': 'AcquisitionMethodCodeSequence',
    '(0022,1423)': 'AcquisitionMethodAlgorithmSequence',
    '(0022,1436)': 'OphthalmicThicknessMapTypeCodeSequence',
    '(0022,1443)': 'OphthalmicThicknessMappingNormalsSequence',
    '(0022,1445)': 'RetinalThicknessDefinitionCodeSequence',
    '(0022,1450)': 'PixelValueMappingToCodedConceptSequence',
    '(0022,1452)': 'MappedPixelValue',
    '(0022,1454)': 'PixelValueMappingExplanation',
    '(0022,1458)': 'OphthalmicThicknessMapQualityThresholdSequence',
    '(0022,1460)': 'OphthalmicThicknessMapThresholdQualityRating',
    '(0022,1463)': 'AnatomicStructureReferencePoint',
    '(0022,1465)': 'RegistrationToLocalizerSequence',
    '(0022,1466)': 'RegisteredLocalizerUnits',
    '(0022,1467)': 'RegisteredLocalizerTopLeftHandCorner',
    '(0022,1468)': 'RegisteredLocalizerBottomRightHandCorner',
    '(0022,1470)': 'OphthalmicThicknessMapQualityRatingSequence',
    '(0022,1472)': 'RelevantOPTAttributesSequence',
    '(0022,1512)': 'TransformationMethodCodeSequence',
    '(0022,1513)': 'TransformationAlgorithmSequence',
    '(0022,1515)': 'OphthalmicAxialLengthMethod',
    '(0022,1517)': 'OphthalmicFOV',
    '(0022,1518)': 'TwoDimensionalToThreeDimensionalMapSequence',
    '(0022,1525)': 'WideFieldOphthalmicPhotographyQualityRatingSequence',
    '(0022,1526)': 'WideFieldOphthalmicPhotographyQualityThresholdSequence',
    '(0022,1527)': 'WideFieldOphthalmicPhotographyThresholdQualityRating',
    '(0022,1528)': 'XCoordinatesCenterPixelViewAngle',
    '(0022,1529)': 'YCoordinatesCenterPixelViewAngle',
    '(0022,1530)': 'NumberOfMapPoints',
    '(0022,1531)': 'TwoDimensionalToThreeDimensionalMapData',
    '(0022,1612)': 'DerivationAlgorithmSequence',
    '(0022,1615)': 'OphthalmicImageTypeCodeSequence',
    '(0022,1616)': 'OphthalmicImageTypeDescription',
    '(0022,1618)': 'ScanPatternTypeCodeSequence',
    '(0022,1620)': 'ReferencedSurfaceMeshIdentificationSequence',
    '(0022,1622)': 'OphthalmicVolumetricPropertiesFlag',
    '(0022,1624)': 'OphthalmicAnatomicReferencePointXCoordinate',
    '(0022,1626)': 'OphthalmicAnatomicReferencePointYCoordinate',
    '(0022,1628)': 'OphthalmicEnFaceImageQualityRatingSequence',
    '(0022,1630)': 'QualityThreshold',
    '(0022,1640)': 'OCTBscanAnalysisAcquisitionParametersSequence',
    '(0022,1642)': 'NumberOfBscansPerFrame',
    '(0022,1643)': 'BscanSlabThickness',
    '(0022,1644)': 'DistanceBetweenBscanSlabs',
    '(0022,1645)': 'BscanCycleTime',
    '(0022,1646)': 'BscanCycleTimeVector',
    '(0022,1649)': 'AscanRate',
    '(0022,1650)': 'BscanRate',
    '(0022,1658)': 'SurfaceMeshZPixelOffset',
    '(0024,0010)': 'VisualFieldHorizontalExtent',
    '(0024,0011)': 'VisualFieldVerticalExtent',
    '(0024,0012)': 'VisualFieldShape',
    '(0024,0016)': 'ScreeningTestModeCodeSequence',
    '(0024,0018)': 'MaximumStimulusLuminance',
    '(0024,0020)': 'BackgroundLuminance',
    '(0024,0021)': 'StimulusColorCodeSequence',
    '(0024,0024)': 'BackgroundIlluminationColorCodeSequence',
    '(0024,0025)': 'StimulusArea',
    '(0024,0028)': 'StimulusPresentationTime',
    '(0024,0032)': 'FixationSequence',
    '(0024,0033)': 'FixationMonitoringCodeSequence',
    '(0024,0034)': 'VisualFieldCatchTrialSequence',
    '(0024,0035)': 'FixationCheckedQuantity',
    '(0024,0036)': 'PatientNotProperlyFixatedQuantity',
    '(0024,0037)': 'PresentedVisualStimuliDataFlag',
    '(0024,0038)': 'NumberOfVisualStimuli',
    '(0024,0039)': 'ExcessiveFixationLossesDataFlag',
    '(0024,0040)': 'ExcessiveFixationLosses',
    '(0024,0042)': 'StimuliRetestingQuantity',
    '(0024,0044)': 'CommentsOnPatientPerformanceOfVisualField',
    '(0024,0045)': 'FalseNegativesEstimateFlag',
    '(0024,0046)': 'FalseNegativesEstimate',
    '(0024,0048)': 'NegativeCatchTrialsQuantity',
    '(0024,0050)': 'FalseNegativesQuantity',
    '(0024,0051)': 'ExcessiveFalseNegativesDataFlag',
    '(0024,0052)': 'ExcessiveFalseNegatives',
    '(0024,0053)': 'FalsePositivesEstimateFlag',
    '(0024,0054)': 'FalsePositivesEstimate',
    '(0024,0055)': 'CatchTrialsDataFlag',
    '(0024,0056)': 'PositiveCatchTrialsQuantity',
    '(0024,0057)': 'TestPointNormalsDataFlag',
    '(0024,0058)': 'TestPointNormalsSequence',
    '(0024,0059)': 'GlobalDeviationProbabilityNormalsFlag',
    '(0024,0060)': 'FalsePositivesQuantity',
    '(0024,0061)': 'ExcessiveFalsePositivesDataFlag',
    '(0024,0062)': 'ExcessiveFalsePositives',
    '(0024,0063)': 'VisualFieldTestNormalsFlag',
    '(0024,0064)': 'ResultsNormalsSequence',
    '(0024,0065)': 'AgeCorrectedSensitivityDeviationAlgorithmSequence',
    '(0024,0066)': 'GlobalDeviationFromNormal',
    '(0024,0067)': 'GeneralizedDefectSensitivityDeviationAlgorithmSequence',
    '(0024,0068)': 'LocalizedDeviationFromNormal',
    '(0024,0069)': 'PatientReliabilityIndicator',
    '(0024,0070)': 'VisualFieldMeanSensitivity',
    '(0024,0071)': 'GlobalDeviationProbability',
    '(0024,0072)': 'LocalDeviationProbabilityNormalsFlag',
    '(0024,0073)': 'LocalizedDeviationProbability',
    '(0024,0074)': 'ShortTermFluctuationCalculated',
    '(0024,0075)': 'ShortTermFluctuation',
    '(0024,0076)': 'ShortTermFluctuationProbabilityCalculated',
    '(0024,0077)': 'ShortTermFluctuationProbability',
    '(0024,0078)': 'CorrectedLocalizedDeviationFromNormalCalculated',
    '(0024,0079)': 'CorrectedLocalizedDeviationFromNormal',
    '(0024,0080)': 'CorrectedLocalizedDeviationFromNormalProbabilityCalculated',
    '(0024,0081)': 'CorrectedLocalizedDeviationFromNormalProbability',
    '(0024,0083)': 'GlobalDeviationProbabilitySequence',
    '(0024,0085)': 'LocalizedDeviationProbabilitySequence',
    '(0024,0086)': 'FovealSensitivityMeasured',
    '(0024,0087)': 'FovealSensitivity',
    '(0024,0088)': 'VisualFieldTestDuration',
    '(0024,0089)': 'VisualFieldTestPointSequence',
    '(0024,0090)': 'VisualFieldTestPointXCoordinate',
    '(0024,0091)': 'VisualFieldTestPointYCoordinate',
    '(0024,0092)': 'AgeCorrectedSensitivityDeviationValue',
    '(0024,0093)': 'StimulusResults',
    '(0024,0094)': 'SensitivityValue',
    '(0024,0095)': 'RetestStimulusSeen',
    '(0024,0096)': 'RetestSensitivityValue',
    '(0024,0097)': 'VisualFieldTestPointNormalsSequence',
    '(0024,0098)': 'QuantifiedDefect',
    '(0024,0100)': 'AgeCorrectedSensitivityDeviationProbabilityValue',
    '(0024,0102)': 'GeneralizedDefectCorrectedSensitivityDeviationFlag',
    '(0024,0103)': 'GeneralizedDefectCorrectedSensitivityDeviationValue',
    '(0024,0104)': 'GeneralizedDefectCorrectedSensitivityDeviationProbabilityValue',
    '(0024,0105)': 'MinimumSensitivityValue',
    '(0024,0106)': 'BlindSpotLocalized',
    '(0024,0107)': 'BlindSpotXCoordinate',
    '(0024,0108)': 'BlindSpotYCoordinate',
    '(0024,0110)': 'VisualAcuityMeasurementSequence',
    '(0024,0112)': 'RefractiveParametersUsedOnPatientSequence',
    '(0024,0113)': 'MeasurementLaterality',
    '(0024,0114)': 'OphthalmicPatientClinicalInformationLeftEyeSequence',
    '(0024,0115)': 'OphthalmicPatientClinicalInformationRightEyeSequence',
    '(0024,0117)': 'FovealPointNormativeDataFlag',
    '(0024,0118)': 'FovealPointProbabilityValue',
    '(0024,0120)': 'ScreeningBaselineMeasured',
    '(0024,0122)': 'ScreeningBaselineMeasuredSequence',
    '(0024,0124)': 'ScreeningBaselineType',
    '(0024,0126)': 'ScreeningBaselineValue',
    '(0024,0202)': 'AlgorithmSource',
    '(0024,0306)': 'DataSetName',
    '(0024,0307)': 'DataSetVersion',
    '(0024,0308)': 'DataSetSource',
    '(0024,0309)': 'DataSetDescription',
    '(0024,0317)': 'VisualFieldTestReliabilityGlobalIndexSequence',
    '(0024,0320)': 'VisualFieldGlobalResultsIndexSequence',
    '(0024,0325)': 'DataObservationSequence',
    '(0024,0338)': 'IndexNormalsFlag',
    '(0024,0341)': 'IndexProbability',
    '(0024,0344)': 'IndexProbabilitySequence',
    '(0028,0002)': 'SamplesPerPixel',
    '(0028,0003)': 'SamplesPerPixelUsed',
    '(0028,0004)': 'PhotometricInterpretation',
    '(0028,0005)': 'ImageDimensions',
    '(0028,0006)': 'PlanarConfiguration',
    '(0028,0008)': 'NumberOfFrames',
    '(0028,0009)': 'FrameIncrementPointer',
    '(0028,000A)': 'FrameDimensionPointer',
    '(0028,0010)': 'Rows',
    '(0028,0011)': 'Columns',
    '(0028,0012)': 'Planes',
    '(0028,0014)': 'UltrasoundColorDataPresent',
    '(0028,0030)': 'PixelSpacing',
    '(0028,0031)': 'ZoomFactor',
    '(0028,0032)': 'ZoomCenter',
    '(0028,0034)': 'PixelAspectRatio',
    '(0028,0040)': 'ImageFormat',
    '(0028,0050)': 'ManipulatedImage',
    '(0028,0051)': 'CorrectedImage',
    '(0028,005F)': 'CompressionRecognitionCode',
    '(0028,0060)': 'CompressionCode',
    '(0028,0061)': 'CompressionOriginator',
    '(0028,0062)': 'CompressionLabel',
    '(0028,0063)': 'CompressionDescription',
    '(0028,0065)': 'CompressionSequence',
    '(0028,0066)': 'CompressionStepPointers',
    '(0028,0068)': 'RepeatInterval',
    '(0028,0069)': 'BitsGrouped',
    '(0028,0070)': 'PerimeterTable',
    '(0028,0071)': 'PerimeterValue',
    '(0028,0080)': 'PredictorRows',
    '(0028,0081)': 'PredictorColumns',
    '(0028,0082)': 'PredictorConstants',
    '(0028,0090)': 'BlockedPixels',
    '(0028,0091)': 'BlockRows',
    '(0028,0092)': 'BlockColumns',
    '(0028,0093)': 'RowOverlap',
    '(0028,0094)': 'ColumnOverlap',
    '(0028,0100)': 'BitsAllocated',
    '(0028,0101)': 'BitsStored',
    '(0028,0102)': 'HighBit',
    '(0028,0103)': 'PixelRepresentation',
    '(0028,0104)': 'SmallestValidPixelValue',
    '(0028,0105)': 'LargestValidPixelValue',
    '(0028,0106)': 'SmallestImagePixelValue',
    '(0028,0107)': 'LargestImagePixelValue',
    '(0028,0108)': 'SmallestPixelValueInSeries',
    '(0028,0109)': 'LargestPixelValueInSeries',
    '(0028,0110)': 'SmallestImagePixelValueInPlane',
    '(0028,0111)': 'LargestImagePixelValueInPlane',
    '(0028,0120)': 'PixelPaddingValue',
    '(0028,0121)': 'PixelPaddingRangeLimit',
    '(0028,0122)': 'FloatPixelPaddingValue',
    '(0028,0123)': 'DoubleFloatPixelPaddingValue',
    '(0028,0124)': 'FloatPixelPaddingRangeLimit',
    '(0028,0125)': 'DoubleFloatPixelPaddingRangeLimit',
    '(0028,0200)': 'ImageLocation',
    '(0028,0300)': 'QualityControlImage',
    '(0028,0301)': 'BurnedInAnnotation',
    '(0028,0302)': 'RecognizableVisualFeatures',
    '(0028,0303)': 'LongitudinalTemporalInformationModified',
    '(0028,0304)': 'ReferencedColorPaletteInstanceUID',
    '(0028,0400)': 'TransformLabel',
    '(0028,0401)': 'TransformVersionNumber',
    '(0028,0402)': 'NumberOfTransformSteps',
    '(0028,0403)': 'SequenceOfCompressedData',
    '(0028,0404)': 'DetailsOfCoefficients',
    '(0028,04x0)': 'RowsForNthOrderCoefficients',
    '(0028,04x1)': 'ColumnsForNthOrderCoefficients',
    '(0028,04x2)': 'CoefficientCoding',
    '(0028,04x3)': 'CoefficientCodingPointers',
    '(0028,0700)': 'DCTLabel',
    '(0028,0701)': 'DataBlockDescription',
    '(0028,0702)': 'DataBlock',
    '(0028,0710)': 'NormalizationFactorFormat',
    '(0028,0720)': 'ZonalMapNumberFormat',
    '(0028,0721)': 'ZonalMapLocation',
    '(0028,0722)': 'ZonalMapFormat',
    '(0028,0730)': 'AdaptiveMapFormat',
    '(0028,0740)': 'CodeNumberFormat',
    '(0028,08x0)': 'CodeLabel',
    '(0028,08x2)': 'NumberOfTables',
    '(0028,08x3)': 'CodeTableLocation',
    '(0028,08x4)': 'BitsForCodeWord',
    '(0028,08x8)': 'ImageDataLocation',
    '(0028,0A02)': 'PixelSpacingCalibrationType',
    '(0028,0A04)': 'PixelSpacingCalibrationDescription',
    '(0028,1040)': 'PixelIntensityRelationship',
    '(0028,1041)': 'PixelIntensityRelationshipSign',
    '(0028,1050)': 'WindowCenter',
    '(0028,1051)': 'WindowWidth',
    '(0028,1052)': 'RescaleIntercept',
    '(0028,1053)': 'RescaleSlope',
    '(0028,1054)': 'RescaleType',
    '(0028,1055)': 'WindowCenterWidthExplanation',
    '(0028,1056)': 'VOILUTFunction',
    '(0028,1080)': 'GrayScale',
    '(0028,1090)': 'RecommendedViewingMode',
    '(0028,1100)': 'GrayLookupTableDescriptor',
    '(0028,1101)': 'RedPaletteColorLookupTableDescriptor',
    '(0028,1102)': 'GreenPaletteColorLookupTableDescriptor',
    '(0028,1103)': 'BluePaletteColorLookupTableDescriptor',
    '(0028,1104)': 'AlphaPaletteColorLookupTableDescriptor',
    '(0028,1111)': 'LargeRedPaletteColorLookupTableDescriptor',
    '(0028,1112)': 'LargeGreenPaletteColorLookupTableDescriptor',
    '(0028,1113)': 'LargeBluePaletteColorLookupTableDescriptor',
    '(0028,1199)': 'PaletteColorLookupTableUID',
    '(0028,1200)': 'GrayLookupTableData',
    '(0028,1201)': 'RedPaletteColorLookupTableData',
    '(0028,1202)': 'GreenPaletteColorLookupTableData',
    '(0028,1203)': 'BluePaletteColorLookupTableData',
    '(0028,1204)': 'AlphaPaletteColorLookupTableData',
    '(0028,1211)': 'LargeRedPaletteColorLookupTableData',
    '(0028,1212)': 'LargeGreenPaletteColorLookupTableData',
    '(0028,1213)': 'LargeBluePaletteColorLookupTableData',
    '(0028,1214)': 'LargePaletteColorLookupTableUID',
    '(0028,1221)': 'SegmentedRedPaletteColorLookupTableData',
    '(0028,1222)': 'SegmentedGreenPaletteColorLookupTableData',
    '(0028,1223)': 'SegmentedBluePaletteColorLookupTableData',
    '(0028,1224)': 'SegmentedAlphaPaletteColorLookupTableData',
    '(0028,1230)': 'StoredValueColorRangeSequence',
    '(0028,1231)': 'MinimumStoredValueMapped',
    '(0028,1232)': 'MaximumStoredValueMapped',
    '(0028,1300)': 'BreastImplantPresent',
    '(0028,1350)': 'PartialView',
    '(0028,1351)': 'PartialViewDescription',
    '(0028,1352)': 'PartialViewCodeSequence',
    '(0028,135A)': 'SpatialLocationsPreserved',
    '(0028,1401)': 'DataFrameAssignmentSequence',
    '(0028,1402)': 'DataPathAssignment',
    '(0028,1403)': 'BitsMappedToColorLookupTable',
    '(0028,1404)': 'BlendingLUT1Sequence',
    '(0028,1405)': 'BlendingLUT1TransferFunction',
    '(0028,1406)': 'BlendingWeightConstant',
    '(0028,1407)': 'BlendingLookupTableDescriptor',
    '(0028,1408)': 'BlendingLookupTableData',
    '(0028,140B)': 'EnhancedPaletteColorLookupTableSequence',
    '(0028,140C)': 'BlendingLUT2Sequence',
    '(0028,140D)': 'BlendingLUT2TransferFunction',
    '(0028,140E)': 'DataPathID',
    '(0028,140F)': 'RGBLUTTransferFunction',
    '(0028,1410)': 'AlphaLUTTransferFunction',
    '(0028,2000)': 'ICCProfile',
    '(0028,2002)': 'ColorSpace',
    '(0028,2110)': 'LossyImageCompression',
    '(0028,2112)': 'LossyImageCompressionRatio',
    '(0028,2114)': 'LossyImageCompressionMethod',
    '(0028,3000)': 'ModalityLUTSequence',
    '(0028,3002)': 'LUTDescriptor',
    '(0028,3003)': 'LUTExplanation',
    '(0028,3004)': 'ModalityLUTType',
    '(0028,3006)': 'LUTData',
    '(0028,3010)': 'VOILUTSequence',
    '(0028,3110)': 'SoftcopyVOILUTSequence',
    '(0028,4000)': 'ImagePresentationComments',
    '(0028,5000)': 'BiPlaneAcquisitionSequence',
    '(0028,6010)': 'RepresentativeFrameNumber',
    '(0028,6020)': 'FrameNumbersOfInterest',
    '(0028,6022)': 'FrameOfInterestDescription',
    '(0028,6023)': 'FrameOfInterestType',
    '(0028,6030)': 'MaskPointers',
    '(0028,6040)': 'RWavePointer',
    '(0028,6100)': 'MaskSubtractionSequence',
    '(0028,6101)': 'MaskOperation',
    '(0028,6102)': 'ApplicableFrameRange',
    '(0028,6110)': 'MaskFrameNumbers',
    '(0028,6112)': 'ContrastFrameAveraging',
    '(0028,6114)': 'MaskSubPixelShift',
    '(0028,6120)': 'TIDOffset',
    '(0028,6190)': 'MaskOperationExplanation',
    '(0028,7000)': 'EquipmentAdministratorSequence',
    '(0028,7001)': 'NumberOfDisplaySubsystems',
    '(0028,7002)': 'CurrentConfigurationID',
    '(0028,7003)': 'DisplaySubsystemID',
    '(0028,7004)': 'DisplaySubsystemName',
    '(0028,7005)': 'DisplaySubsystemDescription',
    '(0028,7006)': 'SystemStatus',
    '(0028,7007)': 'SystemStatusComment',
    '(0028,7008)': 'TargetLuminanceCharacteristicsSequence',
    '(0028,7009)': 'LuminanceCharacteristicsID',
    '(0028,700A)': 'DisplaySubsystemConfigurationSequence',
    '(0028,700B)': 'ConfigurationID',
    '(0028,700C)': 'ConfigurationName',
    '(0028,700D)': 'ConfigurationDescription',
    '(0028,700E)': 'ReferencedTargetLuminanceCharacteristicsID',
    '(0028,700F)': 'QAResultsSequence',
    '(0028,7010)': 'DisplaySubsystemQAResultsSequence',
    '(0028,7011)': 'ConfigurationQAResultsSequence',
    '(0028,7012)': 'MeasurementEquipmentSequence',
    '(0028,7013)': 'MeasurementFunctions',
    '(0028,7014)': 'MeasurementEquipmentType',
    '(0028,7015)': 'VisualEvaluationResultSequence',
    '(0028,7016)': 'DisplayCalibrationResultSequence',
    '(0028,7017)': 'DDLValue',
    '(0028,7018)': 'CIExyWhitePoint',
    '(0028,7019)': 'DisplayFunctionType',
    '(0028,701A)': 'GammaValue',
    '(0028,701B)': 'NumberOfLuminancePoints',
    '(0028,701C)': 'LuminanceResponseSequence',
    '(0028,701D)': 'TargetMinimumLuminance',
    '(0028,701E)': 'TargetMaximumLuminance',
    '(0028,701F)': 'LuminanceValue',
    '(0028,7020)': 'LuminanceResponseDescription',
    '(0028,7021)': 'WhitePointFlag',
    '(0028,7022)': 'DisplayDeviceTypeCodeSequence',
    '(0028,7023)': 'DisplaySubsystemSequence',
    '(0028,7024)': 'LuminanceResultSequence',
    '(0028,7025)': 'AmbientLightValueSource',
    '(0028,7026)': 'MeasuredCharacteristics',
    '(0028,7027)': 'LuminanceUniformityResultSequence',
    '(0028,7028)': 'VisualEvaluationTestSequence',
    '(0028,7029)': 'TestResult',
    '(0028,702A)': 'TestResultComment',
    '(0028,702B)': 'TestImageValidation',
    '(0028,702C)': 'TestPatternCodeSequence',
    '(0028,702D)': 'MeasurementPatternCodeSequence',
    '(0028,702E)': 'VisualEvaluationMethodCodeSequence',
    '(0028,7FE0)': 'PixelDataProviderURL',
    '(0028,9001)': 'DataPointRows',
    '(0028,9002)': 'DataPointColumns',
    '(0028,9003)': 'SignalDomainColumns',
    '(0028,9099)': 'LargestMonochromePixelValue',
    '(0028,9108)': 'DataRepresentation',
    '(0028,9110)': 'PixelMeasuresSequence',
    '(0028,9132)': 'FrameVOILUTSequence',
    '(0028,9145)': 'PixelValueTransformationSequence',
    '(0028,9235)': 'SignalDomainRows',
    '(0028,9411)': 'DisplayFilterPercentage',
    '(0028,9415)': 'FramePixelShiftSequence',
    '(0028,9416)': 'SubtractionItemID',
    '(0028,9422)': 'PixelIntensityRelationshipLUTSequence',
    '(0028,9443)': 'FramePixelDataPropertiesSequence',
    '(0028,9444)': 'GeometricalProperties',
    '(0028,9445)': 'GeometricMaximumDistortion',
    '(0028,9446)': 'ImageProcessingApplied',
    '(0028,9454)': 'MaskSelectionMode',
    '(0028,9474)': 'LUTFunction',
    '(0028,9478)': 'MaskVisibilityPercentage',
    '(0028,9501)': 'PixelShiftSequence',
    '(0028,9502)': 'RegionPixelShiftSequence',
    '(0028,9503)': 'VerticesOfTheRegion',
    '(0028,9505)': 'MultiFramePresentationSequence',
    '(0028,9506)': 'PixelShiftFrameRange',
    '(0028,9507)': 'LUTFrameRange',
    '(0028,9520)': 'ImageToEquipmentMappingMatrix',
    '(0028,9537)': 'EquipmentCoordinateSystemIdentification',
    '(0032,000A)': 'StudyStatusID',
    '(0032,000C)': 'StudyPriorityID',
    '(0032,0012)': 'StudyIDIssuer',
    '(0032,0032)': 'StudyVerifiedDate',
    '(0032,0033)': 'StudyVerifiedTime',
    '(0032,0034)': 'StudyReadDate',
    '(0032,0035)': 'StudyReadTime',
    '(0032,1000)': 'ScheduledStudyStartDate',
    '(0032,1001)': 'ScheduledStudyStartTime',
    '(0032,1010)': 'ScheduledStudyStopDate',
    '(0032,1011)': 'ScheduledStudyStopTime',
    '(0032,1020)': 'ScheduledStudyLocation',
    '(0032,1021)': 'ScheduledStudyLocationAETitle',
    '(0032,1030)': 'ReasonForStudy',
    '(0032,1031)': 'RequestingPhysicianIdentificationSequence',
    '(0032,1032)': 'RequestingPhysician',
    '(0032,1033)': 'RequestingService',
    '(0032,1034)': 'RequestingServiceCodeSequence',
    '(0032,1040)': 'StudyArrivalDate',
    '(0032,1041)': 'StudyArrivalTime',
    '(0032,1050)': 'StudyCompletionDate',
    '(0032,1051)': 'StudyCompletionTime',
    '(0032,1055)': 'StudyComponentStatusID',
    '(0032,1060)': 'RequestedProcedureDescription',
    '(0032,1064)': 'RequestedProcedureCodeSequence',
    '(0032,1065)': 'RequestedLateralityCodeSequence',
    '(0032,1066)': 'ReasonForVisit',
    '(0032,1067)': 'ReasonForVisitCodeSequence',
    '(0032,1070)': 'RequestedContrastAgent',
    '(0032,4000)': 'StudyComments',
    '(0034,0001)': 'FlowIdentifierSequence',
    '(0034,0002)': 'FlowIdentifier',
    '(0034,0003)': 'FlowTransferSyntaxUID',
    '(0034,0004)': 'FlowRTPSamplingRate',
    '(0034,0005)': 'SourceIdentifier',
    '(0034,0007)': 'FrameOriginTimestamp',
    '(0034,0008)': 'IncludesImagingSubject',
    '(0034,0009)': 'FrameUsefulnessGroupSequence',
    '(0034,000A)': 'RealTimeBulkDataFlowSequence',
    '(0034,000B)': 'CameraPositionGroupSequence',
    '(0034,000C)': 'IncludesInformation',
    '(0034,000D)': 'TimeOfFrameGroupSequence',
    '(0038,0004)': 'ReferencedPatientAliasSequence',
    '(0038,0008)': 'VisitStatusID',
    '(0038,0010)': 'AdmissionID',
    '(0038,0011)': 'IssuerOfAdmissionID',
    '(0038,0014)': 'IssuerOfAdmissionIDSequence',
    '(0038,0016)': 'RouteOfAdmissions',
    '(0038,001A)': 'ScheduledAdmissionDate',
    '(0038,001B)': 'ScheduledAdmissionTime',
    '(0038,001C)': 'ScheduledDischargeDate',
    '(0038,001D)': 'ScheduledDischargeTime',
    '(0038,001E)': 'ScheduledPatientInstitutionResidence',
    '(0038,0020)': 'AdmittingDate',
    '(0038,0021)': 'AdmittingTime',
    '(0038,0030)': 'DischargeDate',
    '(0038,0032)': 'DischargeTime',
    '(0038,0040)': 'DischargeDiagnosisDescription',
    '(0038,0044)': 'DischargeDiagnosisCodeSequence',
    '(0038,0050)': 'SpecialNeeds',
    '(0038,0060)': 'ServiceEpisodeID',
    '(0038,0061)': 'IssuerOfServiceEpisodeID',
    '(0038,0062)': 'ServiceEpisodeDescription',
    '(0038,0064)': 'IssuerOfServiceEpisodeIDSequence',
    '(0038,0100)': 'PertinentDocumentsSequence',
    '(0038,0101)': 'PertinentResourcesSequence',
    '(0038,0102)': 'ResourceDescription',
    '(0038,0300)': 'CurrentPatientLocation',
    '(0038,0400)': 'PatientInstitutionResidence',
    '(0038,0500)': 'PatientState',
    '(0038,0502)': 'PatientClinicalTrialParticipationSequence',
    '(0038,4000)': 'VisitComments',
    '(003A,0004)': 'WaveformOriginality',
    '(003A,0005)': 'NumberOfWaveformChannels',
    '(003A,0010)': 'NumberOfWaveformSamples',
    '(003A,001A)': 'SamplingFrequency',
    '(003A,0020)': 'MultiplexGroupLabel',
    '(003A,0200)': 'ChannelDefinitionSequence',
    '(003A,0202)': 'WaveformChannelNumber',
    '(003A,0203)': 'ChannelLabel',
    '(003A,0205)': 'ChannelStatus',
    '(003A,0208)': 'ChannelSourceSequence',
    '(003A,0209)': 'ChannelSourceModifiersSequence',
    '(003A,020A)': 'SourceWaveformSequence',
    '(003A,020C)': 'ChannelDerivationDescription',
    '(003A,0210)': 'ChannelSensitivity',
    '(003A,0211)': 'ChannelSensitivityUnitsSequence',
    '(003A,0212)': 'ChannelSensitivityCorrectionFactor',
    '(003A,0213)': 'ChannelBaseline',
    '(003A,0214)': 'ChannelTimeSkew',
    '(003A,0215)': 'ChannelSampleSkew',
    '(003A,0218)': 'ChannelOffset',
    '(003A,021A)': 'WaveformBitsStored',
    '(003A,0220)': 'FilterLowFrequency',
    '(003A,0221)': 'FilterHighFrequency',
    '(003A,0222)': 'NotchFilterFrequency',
    '(003A,0223)': 'NotchFilterBandwidth',
    '(003A,0230)': 'WaveformDataDisplayScale',
    '(003A,0231)': 'WaveformDisplayBackgroundCIELabValue',
    '(003A,0240)': 'WaveformPresentationGroupSequence',
    '(003A,0241)': 'PresentationGroupNumber',
    '(003A,0242)': 'ChannelDisplaySequence',
    '(003A,0244)': 'ChannelRecommendedDisplayCIELabValue',
    '(003A,0245)': 'ChannelPosition',
    '(003A,0246)': 'DisplayShadingFlag',
    '(003A,0247)': 'FractionalChannelDisplayScale',
    '(003A,0248)': 'AbsoluteChannelDisplayScale',
    '(003A,0300)': 'MultiplexedAudioChannelsDescriptionCodeSequence',
    '(003A,0301)': 'ChannelIdentificationCode',
    '(003A,0302)': 'ChannelMode',
    '(003A,0310)': 'MultiplexGroupUID',
    '(003A,0311)': 'PowerlineFrequency',
    '(003A,0312)': 'ChannelImpedanceSequence',
    '(003A,0313)': 'ImpedanceValue',
    '(003A,0314)': 'ImpedanceMeasurementDateTime',
    '(003A,0315)': 'ImpedanceMeasurementFrequency',
    '(003A,0316)': 'ImpedanceMeasurementCurrentType',
    '(0040,0001)': 'ScheduledStationAETitle',
    '(0040,0002)': 'ScheduledProcedureStepStartDate',
    '(0040,0003)': 'ScheduledProcedureStepStartTime',
    '(0040,0004)': 'ScheduledProcedureStepEndDate',
    '(0040,0005)': 'ScheduledProcedureStepEndTime',
    '(0040,0006)': 'ScheduledPerformingPhysicianName',
    '(0040,0007)': 'ScheduledProcedureStepDescription',
    '(0040,0008)': 'ScheduledProtocolCodeSequence',
    '(0040,0009)': 'ScheduledProcedureStepID',
    '(0040,000A)': 'StageCodeSequence',
    '(0040,000B)': 'ScheduledPerformingPhysicianIdentificationSequence',
    '(0040,0010)': 'ScheduledStationName',
    '(0040,0011)': 'ScheduledProcedureStepLocation',
    '(0040,0012)': 'PreMedication',
    '(0040,0020)': 'ScheduledProcedureStepStatus',
    '(0040,0026)': 'OrderPlacerIdentifierSequence',
    '(0040,0027)': 'OrderFillerIdentifierSequence',
    '(0040,0031)': 'LocalNamespaceEntityID',
    '(0040,0032)': 'UniversalEntityID',
    '(0040,0033)': 'UniversalEntityIDType',
    '(0040,0035)': 'IdentifierTypeCode',
    '(0040,0036)': 'AssigningFacilitySequence',
    '(0040,0039)': 'AssigningJurisdictionCodeSequence',
    '(0040,003A)': 'AssigningAgencyOrDepartmentCodeSequence',
    '(0040,0100)': 'ScheduledProcedureStepSequence',
    '(0040,0220)': 'ReferencedNonImageCompositeSOPInstanceSequence',
    '(0040,0241)': 'PerformedStationAETitle',
    '(0040,0242)': 'PerformedStationName',
    '(0040,0243)': 'PerformedLocation',
    '(0040,0244)': 'PerformedProcedureStepStartDate',
    '(0040,0245)': 'PerformedProcedureStepStartTime',
    '(0040,0250)': 'PerformedProcedureStepEndDate',
    '(0040,0251)': 'PerformedProcedureStepEndTime',
    '(0040,0252)': 'PerformedProcedureStepStatus',
    '(0040,0253)': 'PerformedProcedureStepID',
    '(0040,0254)': 'PerformedProcedureStepDescription',
    '(0040,0255)': 'PerformedProcedureTypeDescription',
    '(0040,0260)': 'PerformedProtocolCodeSequence',
    '(0040,0261)': 'PerformedProtocolType',
    '(0040,0270)': 'ScheduledStepAttributesSequence',
    '(0040,0275)': 'RequestAttributesSequence',
    '(0040,0280)': 'CommentsOnThePerformedProcedureStep',
    '(0040,0281)': 'PerformedProcedureStepDiscontinuationReasonCodeSequence',
    '(0040,0293)': 'QuantitySequence',
    '(0040,0294)': 'Quantity',
    '(0040,0295)': 'MeasuringUnitsSequence',
    '(0040,0296)': 'BillingItemSequence',
    '(0040,0300)': 'TotalTimeOfFluoroscopy',
    '(0040,0301)': 'TotalNumberOfExposures',
    '(0040,0302)': 'EntranceDose',
    '(0040,0303)': 'ExposedArea',
    '(0040,0306)': 'DistanceSourceToEntrance',
    '(0040,0307)': 'DistanceSourceToSupport',
    '(0040,030E)': 'ExposureDoseSequence',
    '(0040,0310)': 'CommentsOnRadiationDose',
    '(0040,0312)': 'XRayOutput',
    '(0040,0314)': 'HalfValueLayer',
    '(0040,0316)': 'OrganDose',
    '(0040,0318)': 'OrganExposed',
    '(0040,0320)': 'BillingProcedureStepSequence',
    '(0040,0321)': 'FilmConsumptionSequence',
    '(0040,0324)': 'BillingSuppliesAndDevicesSequence',
    '(0040,0330)': 'ReferencedProcedureStepSequence',
    '(0040,0340)': 'PerformedSeriesSequence',
    '(0040,0400)': 'CommentsOnTheScheduledProcedureStep',
    '(0040,0440)': 'ProtocolContextSequence',
    '(0040,0441)': 'ContentItemModifierSequence',
    '(0040,0500)': 'ScheduledSpecimenSequence',
    '(0040,050A)': 'SpecimenAccessionNumber',
    '(0040,0512)': 'ContainerIdentifier',
    '(0040,0513)': 'IssuerOfTheContainerIdentifierSequence',
    '(0040,0515)': 'AlternateContainerIdentifierSequence',
    '(0040,0518)': 'ContainerTypeCodeSequence',
    '(0040,051A)': 'ContainerDescription',
    '(0040,0520)': 'ContainerComponentSequence',
    '(0040,0550)': 'SpecimenSequence',
    '(0040,0551)': 'SpecimenIdentifier',
    '(0040,0552)': 'SpecimenDescriptionSequenceTrial',
    '(0040,0553)': 'SpecimenDescriptionTrial',
    '(0040,0554)': 'SpecimenUID',
    '(0040,0555)': 'AcquisitionContextSequence',
    '(0040,0556)': 'AcquisitionContextDescription',
    '(0040,0560)': 'SpecimenDescriptionSequence',
    '(0040,0562)': 'IssuerOfTheSpecimenIdentifierSequence',
    '(0040,059A)': 'SpecimenTypeCodeSequence',
    '(0040,0600)': 'SpecimenShortDescription',
    '(0040,0602)': 'SpecimenDetailedDescription',
    '(0040,0610)': 'SpecimenPreparationSequence',
    '(0040,0612)': 'SpecimenPreparationStepContentItemSequence',
    '(0040,0620)': 'SpecimenLocalizationContentItemSequence',
    '(0040,06FA)': 'SlideIdentifier',
    '(0040,0710)': 'WholeSlideMicroscopyImageFrameTypeSequence',
    '(0040,071A)': 'ImageCenterPointCoordinatesSequence',
    '(0040,072A)': 'XOffsetInSlideCoordinateSystem',
    '(0040,073A)': 'YOffsetInSlideCoordinateSystem',
    '(0040,074A)': 'ZOffsetInSlideCoordinateSystem',
    '(0040,08D8)': 'PixelSpacingSequence',
    '(0040,08DA)': 'CoordinateSystemAxisCodeSequence',
    '(0040,08EA)': 'MeasurementUnitsCodeSequence',
    '(0040,09F8)': 'VitalStainCodeSequenceTrial',
    '(0040,1001)': 'RequestedProcedureID',
    '(0040,1002)': 'ReasonForTheRequestedProcedure',
    '(0040,1003)': 'RequestedProcedurePriority',
    '(0040,1004)': 'PatientTransportArrangements',
    '(0040,1005)': 'RequestedProcedureLocation',
    '(0040,1006)': 'PlacerOrderNumberProcedure',
    '(0040,1007)': 'FillerOrderNumberProcedure',
    '(0040,1008)': 'ConfidentialityCode',
    '(0040,1009)': 'ReportingPriority',
    '(0040,100A)': 'ReasonForRequestedProcedureCodeSequence',
    '(0040,1010)': 'NamesOfIntendedRecipientsOfResults',
    '(0040,1011)': 'IntendedRecipientsOfResultsIdentificationSequence',
    '(0040,1012)': 'ReasonForPerformedProcedureCodeSequence',
    '(0040,1060)': 'RequestedProcedureDescriptionTrial',
    '(0040,1101)': 'PersonIdentificationCodeSequence',
    '(0040,1102)': 'PersonAddress',
    '(0040,1103)': 'PersonTelephoneNumbers',
    '(0040,1104)': 'PersonTelecomInformation',
    '(0040,1400)': 'RequestedProcedureComments',
    '(0040,2001)': 'ReasonForTheImagingServiceRequest',
    '(0040,2004)': 'IssueDateOfImagingServiceRequest',
    '(0040,2005)': 'IssueTimeOfImagingServiceRequest',
    '(0040,2006)': 'PlacerOrderNumberImagingServiceRequestRetired',
    '(0040,2007)': 'FillerOrderNumberImagingServiceRequestRetired',
    '(0040,2008)': 'OrderEnteredBy',
    '(0040,2009)': 'OrderEntererLocation',
    '(0040,2010)': 'OrderCallbackPhoneNumber',
    '(0040,2011)': 'OrderCallbackTelecomInformation',
    '(0040,2016)': 'PlacerOrderNumberImagingServiceRequest',
    '(0040,2017)': 'FillerOrderNumberImagingServiceRequest',
    '(0040,2400)': 'ImagingServiceRequestComments',
    '(0040,3001)': 'ConfidentialityConstraintOnPatientDataDescription',
    '(0040,4001)': 'GeneralPurposeScheduledProcedureStepStatus',
    '(0040,4002)': 'GeneralPurposePerformedProcedureStepStatus',
    '(0040,4003)': 'GeneralPurposeScheduledProcedureStepPriority',
    '(0040,4004)': 'ScheduledProcessingApplicationsCodeSequence',
    '(0040,4005)': 'ScheduledProcedureStepStartDateTime',
    '(0040,4006)': 'MultipleCopiesFlag',
    '(0040,4007)': 'PerformedProcessingApplicationsCodeSequence',
    '(0040,4008)': 'ScheduledProcedureStepExpirationDateTime',
    '(0040,4009)': 'HumanPerformerCodeSequence',
    '(0040,4010)': 'ScheduledProcedureStepModificationDateTime',
    '(0040,4011)': 'ExpectedCompletionDateTime',
    '(0040,4015)': 'ResultingGeneralPurposePerformedProcedureStepsSequence',
    '(0040,4016)': 'ReferencedGeneralPurposeScheduledProcedureStepSequence',
    '(0040,4018)': 'ScheduledWorkitemCodeSequence',
    '(0040,4019)': 'PerformedWorkitemCodeSequence',
    '(0040,4020)': 'InputAvailabilityFlag',
    '(0040,4021)': 'InputInformationSequence',
    '(0040,4022)': 'RelevantInformationSequence',
    '(0040,4023)': 'ReferencedGeneralPurposeScheduledProcedureStepTransactionUID',
    '(0040,4025)': 'ScheduledStationNameCodeSequence',
    '(0040,4026)': 'ScheduledStationClassCodeSequence',
    '(0040,4027)': 'ScheduledStationGeographicLocationCodeSequence',
    '(0040,4028)': 'PerformedStationNameCodeSequence',
    '(0040,4029)': 'PerformedStationClassCodeSequence',
    '(0040,4030)': 'PerformedStationGeographicLocationCodeSequence',
    '(0040,4031)': 'RequestedSubsequentWorkitemCodeSequence',
    '(0040,4032)': 'NonDICOMOutputCodeSequence',
    '(0040,4033)': 'OutputInformationSequence',
    '(0040,4034)': 'ScheduledHumanPerformersSequence',
    '(0040,4035)': 'ActualHumanPerformersSequence',
    '(0040,4036)': 'HumanPerformerOrganization',
    '(0040,4037)': 'HumanPerformerName',
    '(0040,4040)': 'RawDataHandling',
    '(0040,4041)': 'InputReadinessState',
    '(0040,4050)': 'PerformedProcedureStepStartDateTime',
    '(0040,4051)': 'PerformedProcedureStepEndDateTime',
    '(0040,4052)': 'ProcedureStepCancellationDateTime',
    '(0040,4070)': 'OutputDestinationSequence',
    '(0040,4071)': 'DICOMStorageSequence',
    '(0040,4072)': 'STOWRSStorageSequence',
    '(0040,4073)': 'StorageURL',
    '(0040,4074)': 'XDSStorageSequence',
    '(0040,8302)': 'EntranceDoseInmGy',
    '(0040,8303)': 'EntranceDoseDerivation',
    '(0040,9092)': 'ParametricMapFrameTypeSequence',
    '(0040,9094)': 'ReferencedImageRealWorldValueMappingSequence',
    '(0040,9096)': 'RealWorldValueMappingSequence',
    '(0040,9098)': 'PixelValueMappingCodeSequence',
    '(0040,9210)': 'LUTLabel',
    '(0040,9211)': 'RealWorldValueLastValueMapped',
    '(0040,9212)': 'RealWorldValueLUTData',
    '(0040,9213)': 'DoubleFloatRealWorldValueLastValueMapped',
    '(0040,9214)': 'DoubleFloatRealWorldValueFirstValueMapped',
    '(0040,9216)': 'RealWorldValueFirstValueMapped',
    '(0040,9220)': 'QuantityDefinitionSequence',
    '(0040,9224)': 'RealWorldValueIntercept',
    '(0040,9225)': 'RealWorldValueSlope',
    '(0040,A007)': 'FindingsFlagTrial',
    '(0040,A010)': 'RelationshipType',
    '(0040,A020)': 'FindingsSequenceTrial',
    '(0040,A021)': 'FindingsGroupUIDTrial',
    '(0040,A022)': 'ReferencedFindingsGroupUIDTrial',
    '(0040,A023)': 'FindingsGroupRecordingDateTrial',
    '(0040,A024)': 'FindingsGroupRecordingTimeTrial',
    '(0040,A026)': 'FindingsSourceCategoryCodeSequenceTrial',
    '(0040,A027)': 'VerifyingOrganization',
    '(0040,A028)': 'DocumentingOrganizationIdentifierCodeSequenceTrial',
    '(0040,A030)': 'VerificationDateTime',
    '(0040,A032)': 'ObservationDateTime',
    '(0040,A033)': 'ObservationStartDateTime',
    '(0040,A040)': 'ValueType',
    '(0040,A043)': 'ConceptNameCodeSequence',
    '(0040,A047)': 'MeasurementPrecisionDescriptionTrial',
    '(0040,A050)': 'ContinuityOfContent',
    '(0040,A057)': 'UrgencyOrPriorityAlertsTrial',
    '(0040,A060)': 'SequencingIndicatorTrial',
    '(0040,A066)': 'DocumentIdentifierCodeSequenceTrial',
    '(0040,A067)': 'DocumentAuthorTrial',
    '(0040,A068)': 'DocumentAuthorIdentifierCodeSequenceTrial',
    '(0040,A070)': 'IdentifierCodeSequenceTrial',
    '(0040,A073)': 'VerifyingObserverSequence',
    '(0040,A074)': 'ObjectBinaryIdentifierTrial',
    '(0040,A075)': 'VerifyingObserverName',
    '(0040,A076)': 'DocumentingObserverIdentifierCodeSequenceTrial',
    '(0040,A078)': 'AuthorObserverSequence',
    '(0040,A07A)': 'ParticipantSequence',
    '(0040,A07C)': 'CustodialOrganizationSequence',
    '(0040,A080)': 'ParticipationType',
    '(0040,A082)': 'ParticipationDateTime',
    '(0040,A084)': 'ObserverType',
    '(0040,A085)': 'ProcedureIdentifierCodeSequenceTrial',
    '(0040,A088)': 'VerifyingObserverIdentificationCodeSequence',
    '(0040,A089)': 'ObjectDirectoryBinaryIdentifierTrial',
    '(0040,A090)': 'EquivalentCDADocumentSequence',
    '(0040,A0B0)': 'ReferencedWaveformChannels',
    '(0040,A110)': 'DateOfDocumentOrVerbalTransactionTrial',
    '(0040,A112)': 'TimeOfDocumentCreationOrVerbalTransactionTrial',
    '(0040,A120)': 'DateTime',
    '(0040,A121)': 'Date',
    '(0040,A122)': 'Time',
    '(0040,A123)': 'PersonName',
    '(0040,A124)': 'UID',
    '(0040,A125)': 'ReportStatusIDTrial',
    '(0040,A130)': 'TemporalRangeType',
    '(0040,A132)': 'ReferencedSamplePositions',
    '(0040,A136)': 'ReferencedFrameNumbers',
    '(0040,A138)': 'ReferencedTimeOffsets',
    '(0040,A13A)': 'ReferencedDateTime',
    '(0040,A160)': 'TextValue',
    '(0040,A161)': 'FloatingPointValue',
    '(0040,A162)': 'RationalNumeratorValue',
    '(0040,A163)': 'RationalDenominatorValue',
    '(0040,A167)': 'ObservationCategoryCodeSequenceTrial',
    '(0040,A168)': 'ConceptCodeSequence',
    '(0040,A16A)': 'BibliographicCitationTrial',
    '(0040,A170)': 'PurposeOfReferenceCodeSequence',
    '(0040,A171)': 'ObservationUID',
    '(0040,A172)': 'ReferencedObservationUIDTrial',
    '(0040,A173)': 'ReferencedObservationClassTrial',
    '(0040,A174)': 'ReferencedObjectObservationClassTrial',
    '(0040,A180)': 'AnnotationGroupNumber',
    '(0040,A192)': 'ObservationDateTrial',
    '(0040,A193)': 'ObservationTimeTrial',
    '(0040,A194)': 'MeasurementAutomationTrial',
    '(0040,A195)': 'ModifierCodeSequence',
    '(0040,A224)': 'IdentificationDescriptionTrial',
    '(0040,A290)': 'CoordinatesSetGeometricTypeTrial',
    '(0040,A296)': 'AlgorithmCodeSequenceTrial',
    '(0040,A297)': 'AlgorithmDescriptionTrial',
    '(0040,A29A)': 'PixelCoordinatesSetTrial',
    '(0040,A300)': 'MeasuredValueSequence',
    '(0040,A301)': 'NumericValueQualifierCodeSequence',
    '(0040,A307)': 'CurrentObserverTrial',
    '(0040,A30A)': 'NumericValue',
    '(0040,A313)': 'ReferencedAccessionSequenceTrial',
    '(0040,A33A)': 'ReportStatusCommentTrial',
    '(0040,A340)': 'ProcedureContextSequenceTrial',
    '(0040,A352)': 'VerbalSourceTrial',
    '(0040,A353)': 'AddressTrial',
    '(0040,A354)': 'TelephoneNumberTrial',
    '(0040,A358)': 'VerbalSourceIdentifierCodeSequenceTrial',
    '(0040,A360)': 'PredecessorDocumentsSequence',
    '(0040,A370)': 'ReferencedRequestSequence',
    '(0040,A372)': 'PerformedProcedureCodeSequence',
    '(0040,A375)': 'CurrentRequestedProcedureEvidenceSequence',
    '(0040,A380)': 'ReportDetailSequenceTrial',
    '(0040,A385)': 'PertinentOtherEvidenceSequence',
    '(0040,A390)': 'HL7StructuredDocumentReferenceSequence',
    '(0040,A402)': 'ObservationSubjectUIDTrial',
    '(0040,A403)': 'ObservationSubjectClassTrial',
    '(0040,A404)': 'ObservationSubjectTypeCodeSequenceTrial',
    '(0040,A491)': 'CompletionFlag',
    '(0040,A492)': 'CompletionFlagDescription',
    '(0040,A493)': 'VerificationFlag',
    '(0040,A494)': 'ArchiveRequested',
    '(0040,A496)': 'PreliminaryFlag',
    '(0040,A504)': 'ContentTemplateSequence',
    '(0040,A525)': 'IdenticalDocumentsSequence',
    '(0040,A600)': 'ObservationSubjectContextFlagTrial',
    '(0040,A601)': 'ObserverContextFlagTrial',
    '(0040,A603)': 'ProcedureContextFlagTrial',
    '(0040,A730)': 'ContentSequence',
    '(0040,A731)': 'RelationshipSequenceTrial',
    '(0040,A732)': 'RelationshipTypeCodeSequenceTrial',
    '(0040,A744)': 'LanguageCodeSequenceTrial',
    '(0040,A801)': 'TabulatedValuesSequence',
    '(0040,A802)': 'NumberOfTableRows',
    '(0040,A803)': 'NumberOfTableColumns',
    '(0040,A804)': 'TableRowNumber',
    '(0040,A805)': 'TableColumnNumber',
    '(0040,A806)': 'TableRowDefinitionSequence',
    '(0040,A807)': 'TableColumnDefinitionSequence',
    '(0040,A808)': 'CellValuesSequence',
    '(0040,A992)': 'UniformResourceLocatorTrial',
    '(0040,B020)': 'WaveformAnnotationSequence',
    '(0040,DB00)': 'TemplateIdentifier',
    '(0040,DB06)': 'TemplateVersion',
    '(0040,DB07)': 'TemplateLocalVersion',
    '(0040,DB0B)': 'TemplateExtensionFlag',
    '(0040,DB0C)': 'TemplateExtensionOrganizationUID',
    '(0040,DB0D)': 'TemplateExtensionCreatorUID',
    '(0040,DB73)': 'ReferencedContentItemIdentifier',
    '(0040,E001)': 'HL7InstanceIdentifier',
    '(0040,E004)': 'HL7DocumentEffectiveTime',
    '(0040,E006)': 'HL7DocumentTypeCodeSequence',
    '(0040,E008)': 'DocumentClassCodeSequence',
    '(0040,E010)': 'RetrieveURI',
    '(0040,E011)': 'RetrieveLocationUID',
    '(0040,E020)': 'TypeOfInstances',
    '(0040,E021)': 'DICOMRetrievalSequence',
    '(0040,E022)': 'DICOMMediaRetrievalSequence',
    '(0040,E023)': 'WADORetrievalSequence',
    '(0040,E024)': 'XDSRetrievalSequence',
    '(0040,E025)': 'WADORSRetrievalSequence',
    '(0040,E030)': 'RepositoryUniqueID',
    '(0040,E031)': 'HomeCommunityID',
    '(0042,0010)': 'DocumentTitle',
    '(0042,0011)': 'EncapsulatedDocument',
    '(0042,0012)': 'MIMETypeOfEncapsulatedDocument',
    '(0042,0013)': 'SourceInstanceSequence',
    '(0042,0014)': 'ListOfMIMETypes',
    '(0042,0015)': 'EncapsulatedDocumentLength',
    '(0044,0001)': 'ProductPackageIdentifier',
    '(0044,0002)': 'SubstanceAdministrationApproval',
    '(0044,0003)': 'ApprovalStatusFurtherDescription',
    '(0044,0004)': 'ApprovalStatusDateTime',
    '(0044,0007)': 'ProductTypeCodeSequence',
    '(0044,0008)': 'ProductName',
    '(0044,0009)': 'ProductDescription',
    '(0044,000A)': 'ProductLotIdentifier',
    '(0044,000B)': 'ProductExpirationDateTime',
    '(0044,0010)': 'SubstanceAdministrationDateTime',
    '(0044,0011)': 'SubstanceAdministrationNotes',
    '(0044,0012)': 'SubstanceAdministrationDeviceID',
    '(0044,0013)': 'ProductParameterSequence',
    '(0044,0019)': 'SubstanceAdministrationParameterSequence',
    '(0044,0100)': 'ApprovalSequence',
    '(0044,0101)': 'AssertionCodeSequence',
    '(0044,0102)': 'AssertionUID',
    '(0044,0103)': 'AsserterIdentificationSequence',
    '(0044,0104)': 'AssertionDateTime',
    '(0044,0105)': 'AssertionExpirationDateTime',
    '(0044,0106)': 'AssertionComments',
    '(0044,0107)': 'RelatedAssertionSequence',
    '(0044,0108)': 'ReferencedAssertionUID',
    '(0044,0109)': 'ApprovalSubjectSequence',
    '(0044,010A)': 'OrganizationalRoleCodeSequence',
    '(0046,0012)': 'LensDescription',
    '(0046,0014)': 'RightLensSequence',
    '(0046,0015)': 'LeftLensSequence',
    '(0046,0016)': 'UnspecifiedLateralityLensSequence',
    '(0046,0018)': 'CylinderSequence',
    '(0046,0028)': 'PrismSequence',
    '(0046,0030)': 'HorizontalPrismPower',
    '(0046,0032)': 'HorizontalPrismBase',
    '(0046,0034)': 'VerticalPrismPower',
    '(0046,0036)': 'VerticalPrismBase',
    '(0046,0038)': 'LensSegmentType',
    '(0046,0040)': 'OpticalTransmittance',
    '(0046,0042)': 'ChannelWidth',
    '(0046,0044)': 'PupilSize',
    '(0046,0046)': 'CornealSize',
    '(0046,0047)': 'CornealSizeSequence',
    '(0046,0050)': 'AutorefractionRightEyeSequence',
    '(0046,0052)': 'AutorefractionLeftEyeSequence',
    '(0046,0060)': 'DistancePupillaryDistance',
    '(0046,0062)': 'NearPupillaryDistance',
    '(0046,0063)': 'IntermediatePupillaryDistance',
    '(0046,0064)': 'OtherPupillaryDistance',
    '(0046,0070)': 'KeratometryRightEyeSequence',
    '(0046,0071)': 'KeratometryLeftEyeSequence',
    '(0046,0074)': 'SteepKeratometricAxisSequence',
    '(0046,0075)': 'RadiusOfCurvature',
    '(0046,0076)': 'KeratometricPower',
    '(0046,0077)': 'KeratometricAxis',
    '(0046,0080)': 'FlatKeratometricAxisSequence',
    '(0046,0092)': 'BackgroundColor',
    '(0046,0094)': 'Optotype',
    '(0046,0095)': 'OptotypePresentation',
    '(0046,0097)': 'SubjectiveRefractionRightEyeSequence',
    '(0046,0098)': 'SubjectiveRefractionLeftEyeSequence',
    '(0046,0100)': 'AddNearSequence',
    '(0046,0101)': 'AddIntermediateSequence',
    '(0046,0102)': 'AddOtherSequence',
    '(0046,0104)': 'AddPower',
    '(0046,0106)': 'ViewingDistance',
    '(0046,0110)': 'CorneaMeasurementsSequence',
    '(0046,0111)': 'SourceOfCorneaMeasurementDataCodeSequence',
    '(0046,0112)': 'SteepCornealAxisSequence',
    '(0046,0113)': 'FlatCornealAxisSequence',
    '(0046,0114)': 'CornealPower',
    '(0046,0115)': 'CornealAxis',
    '(0046,0116)': 'CorneaMeasurementMethodCodeSequence',
    '(0046,0117)': 'RefractiveIndexOfCornea',
    '(0046,0118)': 'RefractiveIndexOfAqueousHumor',
    '(0046,0121)': 'VisualAcuityTypeCodeSequence',
    '(0046,0122)': 'VisualAcuityRightEyeSequence',
    '(0046,0123)': 'VisualAcuityLeftEyeSequence',
    '(0046,0124)': 'VisualAcuityBothEyesOpenSequence',
    '(0046,0125)': 'ViewingDistanceType',
    '(0046,0135)': 'VisualAcuityModifiers',
    '(0046,0137)': 'DecimalVisualAcuity',
    '(0046,0139)': 'OptotypeDetailedDefinition',
    '(0046,0145)': 'ReferencedRefractiveMeasurementsSequence',
    '(0046,0146)': 'SpherePower',
    '(0046,0147)': 'CylinderPower',
    '(0046,0201)': 'CornealTopographySurface',
    '(0046,0202)': 'CornealVertexLocation',
    '(0046,0203)': 'PupilCentroidXCoordinate',
    '(0046,0204)': 'PupilCentroidYCoordinate',
    '(0046,0205)': 'EquivalentPupilRadius',
    '(0046,0207)': 'CornealTopographyMapTypeCodeSequence',
    '(0046,0208)': 'VerticesOfTheOutlineOfPupil',
    '(0046,0210)': 'CornealTopographyMappingNormalsSequence',
    '(0046,0211)': 'MaximumCornealCurvatureSequence',
    '(0046,0212)': 'MaximumCornealCurvature',
    '(0046,0213)': 'MaximumCornealCurvatureLocation',
    '(0046,0215)': 'MinimumKeratometricSequence',
    '(0046,0218)': 'SimulatedKeratometricCylinderSequence',
    '(0046,0220)': 'AverageCornealPower',
    '(0046,0224)': 'CornealISValue',
    '(0046,0227)': 'AnalyzedArea',
    '(0046,0230)': 'SurfaceRegularityIndex',
    '(0046,0232)': 'SurfaceAsymmetryIndex',
    '(0046,0234)': 'CornealEccentricityIndex',
    '(0046,0236)': 'KeratoconusPredictionIndex',
    '(0046,0238)': 'DecimalPotentialVisualAcuity',
    '(0046,0242)': 'CornealTopographyMapQualityEvaluation',
    '(0046,0244)': 'SourceImageCornealProcessedDataSequence',
    '(0046,0247)': 'CornealPointLocation',
    '(0046,0248)': 'CornealPointEstimated',
    '(0046,0249)': 'AxialPower',
    '(0046,0250)': 'TangentialPower',
    '(0046,0251)': 'RefractivePower',
    '(0046,0252)': 'RelativeElevation',
    '(0046,0253)': 'CornealWavefront',
    '(0048,0001)': 'ImagedVolumeWidth',
    '(0048,0002)': 'ImagedVolumeHeight',
    '(0048,0003)': 'ImagedVolumeDepth',
    '(0048,0006)': 'TotalPixelMatrixColumns',
    '(0048,0007)': 'TotalPixelMatrixRows',
    '(0048,0008)': 'TotalPixelMatrixOriginSequence',
    '(0048,0010)': 'SpecimenLabelInImage',
    '(0048,0011)': 'FocusMethod',
    '(0048,0012)': 'ExtendedDepthOfField',
    '(0048,0013)': 'NumberOfFocalPlanes',
    '(0048,0014)': 'DistanceBetweenFocalPlanes',
    '(0048,0015)': 'RecommendedAbsentPixelCIELabValue',
    '(0048,0100)': 'IlluminatorTypeCodeSequence',
    '(0048,0102)': 'ImageOrientationSlide',
    '(0048,0105)': 'OpticalPathSequence',
    '(0048,0106)': 'OpticalPathIdentifier',
    '(0048,0107)': 'OpticalPathDescription',
    '(0048,0108)': 'IlluminationColorCodeSequence',
    '(0048,0110)': 'SpecimenReferenceSequence',
    '(0048,0111)': 'CondenserLensPower',
    '(0048,0112)': 'ObjectiveLensPower',
    '(0048,0113)': 'ObjectiveLensNumericalAperture',
    '(0048,0120)': 'PaletteColorLookupTableSequence',
    '(0048,0200)': 'ReferencedImageNavigationSequence',
    '(0048,0201)': 'TopLeftHandCornerOfLocalizerArea',
    '(0048,0202)': 'BottomRightHandCornerOfLocalizerArea',
    '(0048,0207)': 'OpticalPathIdentificationSequence',
    '(0048,021A)': 'PlanePositionSlideSequence',
    '(0048,021E)': 'ColumnPositionInTotalImagePixelMatrix',
    '(0048,021F)': 'RowPositionInTotalImagePixelMatrix',
    '(0048,0301)': 'PixelOriginInterpretation',
    '(0048,0302)': 'NumberOfOpticalPaths',
    '(0048,0303)': 'TotalPixelMatrixFocalPlanes',
    '(0050,0004)': 'CalibrationImage',
    '(0050,0010)': 'DeviceSequence',
    '(0050,0012)': 'ContainerComponentTypeCodeSequence',
    '(0050,0013)': 'ContainerComponentThickness',
    '(0050,0014)': 'DeviceLength',
    '(0050,0015)': 'ContainerComponentWidth',
    '(0050,0016)': 'DeviceDiameter',
    '(0050,0017)': 'DeviceDiameterUnits',
    '(0050,0018)': 'DeviceVolume',
    '(0050,0019)': 'InterMarkerDistance',
    '(0050,001A)': 'ContainerComponentMaterial',
    '(0050,001B)': 'ContainerComponentID',
    '(0050,001C)': 'ContainerComponentLength',
    '(0050,001D)': 'ContainerComponentDiameter',
    '(0050,001E)': 'ContainerComponentDescription',
    '(0050,0020)': 'DeviceDescription',
    '(0050,0021)': 'LongDeviceDescription',
    '(0052,0001)': 'ContrastBolusIngredientPercentByVolume',
    '(0052,0002)': 'OCTFocalDistance',
    '(0052,0003)': 'BeamSpotSize',
    '(0052,0004)': 'EffectiveRefractiveIndex',
    '(0052,0006)': 'OCTAcquisitionDomain',
    '(0052,0007)': 'OCTOpticalCenterWavelength',
    '(0052,0008)': 'AxialResolution',
    '(0052,0009)': 'RangingDepth',
    '(0052,0011)': 'ALineRate',
    '(0052,0012)': 'ALinesPerFrame',
    '(0052,0013)': 'CatheterRotationalRate',
    '(0052,0014)': 'ALinePixelSpacing',
    '(0052,0016)': 'ModeOfPercutaneousAccessSequence',
    '(0052,0025)': 'IntravascularOCTFrameTypeSequence',
    '(0052,0026)': 'OCTZOffsetApplied',
    '(0052,0027)': 'IntravascularFrameContentSequence',
    '(0052,0028)': 'IntravascularLongitudinalDistance',
    '(0052,0029)': 'IntravascularOCTFrameContentSequence',
    '(0052,0030)': 'OCTZOffsetCorrection',
    '(0052,0031)': 'CatheterDirectionOfRotation',
    '(0052,0033)': 'SeamLineLocation',
    '(0052,0034)': 'FirstALineLocation',
    '(0052,0036)': 'SeamLineIndex',
    '(0052,0038)': 'NumberOfPaddedALines',
    '(0052,0039)': 'InterpolationType',
    '(0052,003A)': 'RefractiveIndexApplied',
    '(0054,0010)': 'EnergyWindowVector',
    '(0054,0011)': 'NumberOfEnergyWindows',
    '(0054,0012)': 'EnergyWindowInformationSequence',
    '(0054,0013)': 'EnergyWindowRangeSequence',
    '(0054,0014)': 'EnergyWindowLowerLimit',
    '(0054,0015)': 'EnergyWindowUpperLimit',
    '(0054,0016)': 'RadiopharmaceuticalInformationSequence',
    '(0054,0017)': 'ResidualSyringeCounts',
    '(0054,0018)': 'EnergyWindowName',
    '(0054,0020)': 'DetectorVector',
    '(0054,0021)': 'NumberOfDetectors',
    '(0054,0022)': 'DetectorInformationSequence',
    '(0054,0030)': 'PhaseVector',
    '(0054,0031)': 'NumberOfPhases',
    '(0054,0032)': 'PhaseInformationSequence',
    '(0054,0033)': 'NumberOfFramesInPhase',
    '(0054,0036)': 'PhaseDelay',
    '(0054,0038)': 'PauseBetweenFrames',
    '(0054,0039)': 'PhaseDescription',
    '(0054,0050)': 'RotationVector',
    '(0054,0051)': 'NumberOfRotations',
    '(0054,0052)': 'RotationInformationSequence',
    '(0054,0053)': 'NumberOfFramesInRotation',
    '(0054,0060)': 'RRIntervalVector',
    '(0054,0061)': 'NumberOfRRIntervals',
    '(0054,0062)': 'GatedInformationSequence',
    '(0054,0063)': 'DataInformationSequence',
    '(0054,0070)': 'TimeSlotVector',
    '(0054,0071)': 'NumberOfTimeSlots',
    '(0054,0072)': 'TimeSlotInformationSequence',
    '(0054,0073)': 'TimeSlotTime',
    '(0054,0080)': 'SliceVector',
    '(0054,0081)': 'NumberOfSlices',
    '(0054,0090)': 'AngularViewVector',
    '(0054,0100)': 'TimeSliceVector',
    '(0054,0101)': 'NumberOfTimeSlices',
    '(0054,0200)': 'StartAngle',
    '(0054,0202)': 'TypeOfDetectorMotion',
    '(0054,0210)': 'TriggerVector',
    '(0054,0211)': 'NumberOfTriggersInPhase',
    '(0054,0220)': 'ViewCodeSequence',
    '(0054,0222)': 'ViewModifierCodeSequence',
    '(0054,0300)': 'RadionuclideCodeSequence',
    '(0054,0302)': 'AdministrationRouteCodeSequence',
    '(0054,0304)': 'RadiopharmaceuticalCodeSequence',
    '(0054,0306)': 'CalibrationDataSequence',
    '(0054,0308)': 'EnergyWindowNumber',
    '(0054,0400)': 'ImageID',
    '(0054,0410)': 'PatientOrientationCodeSequence',
    '(0054,0412)': 'PatientOrientationModifierCodeSequence',
    '(0054,0414)': 'PatientGantryRelationshipCodeSequence',
    '(0054,0500)': 'SliceProgressionDirection',
    '(0054,0501)': 'ScanProgressionDirection',
    '(0054,1000)': 'SeriesType',
    '(0054,1001)': 'Units',
    '(0054,1002)': 'CountsSource',
    '(0054,1004)': 'ReprojectionMethod',
    '(0054,1006)': 'SUVType',
    '(0054,1100)': 'RandomsCorrectionMethod',
    '(0054,1101)': 'AttenuationCorrectionMethod',
    '(0054,1102)': 'DecayCorrection',
    '(0054,1103)': 'ReconstructionMethod',
    '(0054,1104)': 'DetectorLinesOfResponseUsed',
    '(0054,1105)': 'ScatterCorrectionMethod',
    '(0054,1200)': 'AxialAcceptance',
    '(0054,1201)': 'AxialMash',
    '(0054,1202)': 'TransverseMash',
    '(0054,1203)': 'DetectorElementSize',
    '(0054,1210)': 'CoincidenceWindowWidth',
    '(0054,1220)': 'SecondaryCountsType',
    '(0054,1300)': 'FrameReferenceTime',
    '(0054,1310)': 'PrimaryPromptsCountsAccumulated',
    '(0054,1311)': 'SecondaryCountsAccumulated',
    '(0054,1320)': 'SliceSensitivityFactor',
    '(0054,1321)': 'DecayFactor',
    '(0054,1322)': 'DoseCalibrationFactor',
    '(0054,1323)': 'ScatterFractionFactor',
    '(0054,1324)': 'DeadTimeFactor',
    '(0054,1330)': 'ImageIndex',
    '(0054,1400)': 'CountsIncluded',
    '(0054,1401)': 'DeadTimeCorrectionFlag',
    '(0060,3000)': 'HistogramSequence',
    '(0060,3002)': 'HistogramNumberOfBins',
    '(0060,3004)': 'HistogramFirstBinValue',
    '(0060,3006)': 'HistogramLastBinValue',
    '(0060,3008)': 'HistogramBinWidth',
    '(0060,3010)': 'HistogramExplanation',
    '(0060,3020)': 'HistogramData',
    '(0062,0001)': 'SegmentationType',
    '(0062,0002)': 'SegmentSequence',
    '(0062,0003)': 'SegmentedPropertyCategoryCodeSequence',
    '(0062,0004)': 'SegmentNumber',
    '(0062,0005)': 'SegmentLabel',
    '(0062,0006)': 'SegmentDescription',
    '(0062,0007)': 'SegmentationAlgorithmIdentificationSequence',
    '(0062,0008)': 'SegmentAlgorithmType',
    '(0062,0009)': 'SegmentAlgorithmName',
    '(0062,000A)': 'SegmentIdentificationSequence',
    '(0062,000B)': 'ReferencedSegmentNumber',
    '(0062,000C)': 'RecommendedDisplayGrayscaleValue',
    '(0062,000D)': 'RecommendedDisplayCIELabValue',
    '(0062,000E)': 'MaximumFractionalValue',
    '(0062,000F)': 'SegmentedPropertyTypeCodeSequence',
    '(0062,0010)': 'SegmentationFractionalType',
    '(0062,0011)': 'SegmentedPropertyTypeModifierCodeSequence',
    '(0062,0012)': 'UsedSegmentsSequence',
    '(0062,0013)': 'SegmentsOverlap',
    '(0062,0020)': 'TrackingID',
    '(0062,0021)': 'TrackingUID',
    '(0064,0002)': 'DeformableRegistrationSequence',
    '(0064,0003)': 'SourceFrameOfReferenceUID',
    '(0064,0005)': 'DeformableRegistrationGridSequence',
    '(0064,0007)': 'GridDimensions',
    '(0064,0008)': 'GridResolution',
    '(0064,0009)': 'VectorGridData',
    '(0064,000F)': 'PreDeformationMatrixRegistrationSequence',
    '(0064,0010)': 'PostDeformationMatrixRegistrationSequence',
    '(0066,0001)': 'NumberOfSurfaces',
    '(0066,0002)': 'SurfaceSequence',
    '(0066,0003)': 'SurfaceNumber',
    '(0066,0004)': 'SurfaceComments',
    '(0066,0009)': 'SurfaceProcessing',
    '(0066,000A)': 'SurfaceProcessingRatio',
    '(0066,000B)': 'SurfaceProcessingDescription',
    '(0066,000C)': 'RecommendedPresentationOpacity',
    '(0066,000D)': 'RecommendedPresentationType',
    '(0066,000E)': 'FiniteVolume',
    '(0066,0010)': 'Manifold',
    '(0066,0011)': 'SurfacePointsSequence',
    '(0066,0012)': 'SurfacePointsNormalsSequence',
    '(0066,0013)': 'SurfaceMeshPrimitivesSequence',
    '(0066,0015)': 'NumberOfSurfacePoints',
    '(0066,0016)': 'PointCoordinatesData',
    '(0066,0017)': 'PointPositionAccuracy',
    '(0066,0018)': 'MeanPointDistance',
    '(0066,0019)': 'MaximumPointDistance',
    '(0066,001A)': 'PointsBoundingBoxCoordinates',
    '(0066,001B)': 'AxisOfRotation',
    '(0066,001C)': 'CenterOfRotation',
    '(0066,001E)': 'NumberOfVectors',
    '(0066,001F)': 'VectorDimensionality',
    '(0066,0020)': 'VectorAccuracy',
    '(0066,0021)': 'VectorCoordinateData',
    '(0066,0022)': 'DoublePointCoordinatesData',
    '(0066,0023)': 'TrianglePointIndexList',
    '(0066,0024)': 'EdgePointIndexList',
    '(0066,0025)': 'VertexPointIndexList',
    '(0066,0026)': 'TriangleStripSequence',
    '(0066,0027)': 'TriangleFanSequence',
    '(0066,0028)': 'LineSequence',
    '(0066,0029)': 'PrimitivePointIndexList',
    '(0066,002A)': 'SurfaceCount',
    '(0066,002B)': 'ReferencedSurfaceSequence',
    '(0066,002C)': 'ReferencedSurfaceNumber',
    '(0066,002D)': 'SegmentSurfaceGenerationAlgorithmIdentificationSequence',
    '(0066,002E)': 'SegmentSurfaceSourceInstanceSequence',
    '(0066,002F)': 'AlgorithmFamilyCodeSequence',
    '(0066,0030)': 'AlgorithmNameCodeSequence',
    '(0066,0031)': 'AlgorithmVersion',
    '(0066,0032)': 'AlgorithmParameters',
    '(0066,0034)': 'FacetSequence',
    '(0066,0035)': 'SurfaceProcessingAlgorithmIdentificationSequence',
    '(0066,0036)': 'AlgorithmName',
    '(0066,0037)': 'RecommendedPointRadius',
    '(0066,0038)': 'RecommendedLineThickness',
    '(0066,0040)': 'LongPrimitivePointIndexList',
    '(0066,0041)': 'LongTrianglePointIndexList',
    '(0066,0042)': 'LongEdgePointIndexList',
    '(0066,0043)': 'LongVertexPointIndexList',
    '(0066,0101)': 'TrackSetSequence',
    '(0066,0102)': 'TrackSequence',
    '(0066,0103)': 'RecommendedDisplayCIELabValueList',
    '(0066,0104)': 'TrackingAlgorithmIdentificationSequence',
    '(0066,0105)': 'TrackSetNumber',
    '(0066,0106)': 'TrackSetLabel',
    '(0066,0107)': 'TrackSetDescription',
    '(0066,0108)': 'TrackSetAnatomicalTypeCodeSequence',
    '(0066,0121)': 'MeasurementsSequence',
    '(0066,0124)': 'TrackSetStatisticsSequence',
    '(0066,0125)': 'FloatingPointValues',
    '(0066,0129)': 'TrackPointIndexList',
    '(0066,0130)': 'TrackStatisticsSequence',
    '(0066,0132)': 'MeasurementValuesSequence',
    '(0066,0133)': 'DiffusionAcquisitionCodeSequence',
    '(0066,0134)': 'DiffusionModelCodeSequence',
    '(0068,6210)': 'ImplantSize',
    '(0068,6221)': 'ImplantTemplateVersion',
    '(0068,6222)': 'ReplacedImplantTemplateSequence',
    '(0068,6223)': 'ImplantType',
    '(0068,6224)': 'DerivationImplantTemplateSequence',
    '(0068,6225)': 'OriginalImplantTemplateSequence',
    '(0068,6226)': 'EffectiveDateTime',
    '(0068,6230)': 'ImplantTargetAnatomySequence',
    '(0068,6260)': 'InformationFromManufacturerSequence',
    '(0068,6265)': 'NotificationFromManufacturerSequence',
    '(0068,6270)': 'InformationIssueDateTime',
    '(0068,6280)': 'InformationSummary',
    '(0068,62A0)': 'ImplantRegulatoryDisapprovalCodeSequence',
    '(0068,62A5)': 'OverallTemplateSpatialTolerance',
    '(0068,62C0)': 'HPGLDocumentSequence',
    '(0068,62D0)': 'HPGLDocumentID',
    '(0068,62D5)': 'HPGLDocumentLabel',
    '(0068,62E0)': 'ViewOrientationCodeSequence',
    '(0068,62F0)': 'ViewOrientationModifierCodeSequence',
    '(0068,62F2)': 'HPGLDocumentScaling',
    '(0068,6300)': 'HPGLDocument',
    '(0068,6310)': 'HPGLContourPenNumber',
    '(0068,6320)': 'HPGLPenSequence',
    '(0068,6330)': 'HPGLPenNumber',
    '(0068,6340)': 'HPGLPenLabel',
    '(0068,6345)': 'HPGLPenDescription',
    '(0068,6346)': 'RecommendedRotationPoint',
    '(0068,6347)': 'BoundingRectangle',
    '(0068,6350)': 'ImplantTemplate3DModelSurfaceNumber',
    '(0068,6360)': 'SurfaceModelDescriptionSequence',
    '(0068,6380)': 'SurfaceModelLabel',
    '(0068,6390)': 'SurfaceModelScalingFactor',
    '(0068,63A0)': 'MaterialsCodeSequence',
    '(0068,63A4)': 'CoatingMaterialsCodeSequence',
    '(0068,63A8)': 'ImplantTypeCodeSequence',
    '(0068,63AC)': 'FixationMethodCodeSequence',
    '(0068,63B0)': 'MatingFeatureSetsSequence',
    '(0068,63C0)': 'MatingFeatureSetID',
    '(0068,63D0)': 'MatingFeatureSetLabel',
    '(0068,63E0)': 'MatingFeatureSequence',
    '(0068,63F0)': 'MatingFeatureID',
    '(0068,6400)': 'MatingFeatureDegreeOfFreedomSequence',
    '(0068,6410)': 'DegreeOfFreedomID',
    '(0068,6420)': 'DegreeOfFreedomType',
    '(0068,6430)': 'TwoDMatingFeatureCoordinatesSequence',
    '(0068,6440)': 'ReferencedHPGLDocumentID',
    '(0068,6450)': 'TwoDMatingPoint',
    '(0068,6460)': 'TwoDMatingAxes',
    '(0068,6470)': 'TwoDDegreeOfFreedomSequence',
    '(0068,6490)': 'ThreeDDegreeOfFreedomAxis',
    '(0068,64A0)': 'RangeOfFreedom',
    '(0068,64C0)': 'ThreeDMatingPoint',
    '(0068,64D0)': 'ThreeDMatingAxes',
    '(0068,64F0)': 'TwoDDegreeOfFreedomAxis',
    '(0068,6500)': 'PlanningLandmarkPointSequence',
    '(0068,6510)': 'PlanningLandmarkLineSequence',
    '(0068,6520)': 'PlanningLandmarkPlaneSequence',
    '(0068,6530)': 'PlanningLandmarkID',
    '(0068,6540)': 'PlanningLandmarkDescription',
    '(0068,6545)': 'PlanningLandmarkIdentificationCodeSequence',
    '(0068,6550)': 'TwoDPointCoordinatesSequence',
    '(0068,6560)': 'TwoDPointCoordinates',
    '(0068,6590)': 'ThreeDPointCoordinates',
    '(0068,65A0)': 'TwoDLineCoordinatesSequence',
    '(0068,65B0)': 'TwoDLineCoordinates',
    '(0068,65D0)': 'ThreeDLineCoordinates',
    '(0068,65E0)': 'TwoDPlaneCoordinatesSequence',
    '(0068,65F0)': 'TwoDPlaneIntersection',
    '(0068,6610)': 'ThreeDPlaneOrigin',
    '(0068,6620)': 'ThreeDPlaneNormal',
    '(0068,7001)': 'ModelModification',
    '(0068,7002)': 'ModelMirroring',
    '(0068,7003)': 'ModelUsageCodeSequence',
    '(0068,7004)': 'ModelGroupUID',
    '(0068,7005)': 'RelativeURIReferenceWithinEncapsulatedDocument',
    '(006A,0001)': 'AnnotationCoordinateType',
    '(006A,0002)': 'AnnotationGroupSequence',
    '(006A,0003)': 'AnnotationGroupUID',
    '(006A,0005)': 'AnnotationGroupLabel',
    '(006A,0006)': 'AnnotationGroupDescription',
    '(006A,0007)': 'AnnotationGroupGenerationType',
    '(006A,0008)': 'AnnotationGroupAlgorithmIdentificationSequence',
    '(006A,0009)': 'AnnotationPropertyCategoryCodeSequence',
    '(006A,000A)': 'AnnotationPropertyTypeCodeSequence',
    '(006A,000B)': 'AnnotationPropertyTypeModifierCodeSequence',
    '(006A,000C)': 'NumberOfAnnotations',
    '(006A,000D)': 'AnnotationAppliesToAllOpticalPaths',
    '(006A,000E)': 'ReferencedOpticalPathIdentifier',
    '(006A,000F)': 'AnnotationAppliesToAllZPlanes',
    '(006A,0010)': 'CommonZCoordinateValue',
    '(006A,0011)': 'AnnotationIndexList',
    '(0070,0001)': 'GraphicAnnotationSequence',
    '(0070,0002)': 'GraphicLayer',
    '(0070,0003)': 'BoundingBoxAnnotationUnits',
    '(0070,0004)': 'AnchorPointAnnotationUnits',
    '(0070,0005)': 'GraphicAnnotationUnits',
    '(0070,0006)': 'UnformattedTextValue',
    '(0070,0008)': 'TextObjectSequence',
    '(0070,0009)': 'GraphicObjectSequence',
    '(0070,0010)': 'BoundingBoxTopLeftHandCorner',
    '(0070,0011)': 'BoundingBoxBottomRightHandCorner',
    '(0070,0012)': 'BoundingBoxTextHorizontalJustification',
    '(0070,0014)': 'AnchorPoint',
    '(0070,0015)': 'AnchorPointVisibility',
    '(0070,0020)': 'GraphicDimensions',
    '(0070,0021)': 'NumberOfGraphicPoints',
    '(0070,0022)': 'GraphicData',
    '(0070,0023)': 'GraphicType',
    '(0070,0024)': 'GraphicFilled',
    '(0070,0040)': 'ImageRotationRetired',
    '(0070,0041)': 'ImageHorizontalFlip',
    '(0070,0042)': 'ImageRotation',
    '(0070,0050)': 'DisplayedAreaTopLeftHandCornerTrial',
    '(0070,0051)': 'DisplayedAreaBottomRightHandCornerTrial',
    '(0070,0052)': 'DisplayedAreaTopLeftHandCorner',
    '(0070,0053)': 'DisplayedAreaBottomRightHandCorner',
    '(0070,005A)': 'DisplayedAreaSelectionSequence',
    '(0070,0060)': 'GraphicLayerSequence',
    '(0070,0062)': 'GraphicLayerOrder',
    '(0070,0066)': 'GraphicLayerRecommendedDisplayGrayscaleValue',
    '(0070,0067)': 'GraphicLayerRecommendedDisplayRGBValue',
    '(0070,0068)': 'GraphicLayerDescription',
    '(0070,0080)': 'ContentLabel',
    '(0070,0081)': 'ContentDescription',
    '(0070,0082)': 'PresentationCreationDate',
    '(0070,0083)': 'PresentationCreationTime',
    '(0070,0084)': 'ContentCreatorName',
    '(0070,0086)': 'ContentCreatorIdentificationCodeSequence',
    '(0070,0087)': 'AlternateContentDescriptionSequence',
    '(0070,0100)': 'PresentationSizeMode',
    '(0070,0101)': 'PresentationPixelSpacing',
    '(0070,0102)': 'PresentationPixelAspectRatio',
    '(0070,0103)': 'PresentationPixelMagnificationRatio',
    '(0070,0207)': 'GraphicGroupLabel',
    '(0070,0208)': 'GraphicGroupDescription',
    '(0070,0209)': 'CompoundGraphicSequence',
    '(0070,0226)': 'CompoundGraphicInstanceID',
    '(0070,0227)': 'FontName',
    '(0070,0228)': 'FontNameType',
    '(0070,0229)': 'CSSFontName',
    '(0070,0230)': 'RotationAngle',
    '(0070,0231)': 'TextStyleSequence',
    '(0070,0232)': 'LineStyleSequence',
    '(0070,0233)': 'FillStyleSequence',
    '(0070,0234)': 'GraphicGroupSequence',
    '(0070,0241)': 'TextColorCIELabValue',
    '(0070,0242)': 'HorizontalAlignment',
    '(0070,0243)': 'VerticalAlignment',
    '(0070,0244)': 'ShadowStyle',
    '(0070,0245)': 'ShadowOffsetX',
    '(0070,0246)': 'ShadowOffsetY',
    '(0070,0247)': 'ShadowColorCIELabValue',
    '(0070,0248)': 'Underlined',
    '(0070,0249)': 'Bold',
    '(0070,0250)': 'Italic',
    '(0070,0251)': 'PatternOnColorCIELabValue',
    '(0070,0252)': 'PatternOffColorCIELabValue',
    '(0070,0253)': 'LineThickness',
    '(0070,0254)': 'LineDashingStyle',
    '(0070,0255)': 'LinePattern',
    '(0070,0256)': 'FillPattern',
    '(0070,0257)': 'FillMode',
    '(0070,0258)': 'ShadowOpacity',
    '(0070,0261)': 'GapLength',
    '(0070,0262)': 'DiameterOfVisibility',
    '(0070,0273)': 'RotationPoint',
    '(0070,0274)': 'TickAlignment',
    '(0070,0278)': 'ShowTickLabel',
    '(0070,0279)': 'TickLabelAlignment',
    '(0070,0282)': 'CompoundGraphicUnits',
    '(0070,0284)': 'PatternOnOpacity',
    '(0070,0285)': 'PatternOffOpacity',
    '(0070,0287)': 'MajorTicksSequence',
    '(0070,0288)': 'TickPosition',
    '(0070,0289)': 'TickLabel',
    '(0070,0294)': 'CompoundGraphicType',
    '(0070,0295)': 'GraphicGroupID',
    '(0070,0306)': 'ShapeType',
    '(0070,0308)': 'RegistrationSequence',
    '(0070,0309)': 'MatrixRegistrationSequence',
    '(0070,030A)': 'MatrixSequence',
    '(0070,030B)': 'FrameOfReferenceToDisplayedCoordinateSystemTransformationMatrix',
    '(0070,030C)': 'FrameOfReferenceTransformationMatrixType',
    '(0070,030D)': 'RegistrationTypeCodeSequence',
    '(0070,030F)': 'FiducialDescription',
    '(0070,0310)': 'FiducialIdentifier',
    '(0070,0311)': 'FiducialIdentifierCodeSequence',
    '(0070,0312)': 'ContourUncertaintyRadius',
    '(0070,0314)': 'UsedFiducialsSequence',
    '(0070,0318)': 'GraphicCoordinatesDataSequence',
    '(0070,031A)': 'FiducialUID',
    '(0070,031B)': 'ReferencedFiducialUID',
    '(0070,031C)': 'FiducialSetSequence',
    '(0070,031E)': 'FiducialSequence',
    '(0070,031F)': 'FiducialsPropertyCategoryCodeSequence',
    '(0070,0401)': 'GraphicLayerRecommendedDisplayCIELabValue',
    '(0070,0402)': 'BlendingSequence',
    '(0070,0403)': 'RelativeOpacity',
    '(0070,0404)': 'ReferencedSpatialRegistrationSequence',
    '(0070,0405)': 'BlendingPosition',
    '(0070,1101)': 'PresentationDisplayCollectionUID',
    '(0070,1102)': 'PresentationSequenceCollectionUID',
    '(0070,1103)': 'PresentationSequencePositionIndex',
    '(0070,1104)': 'RenderedImageReferenceSequence',
    '(0070,1201)': 'VolumetricPresentationStateInputSequence',
    '(0070,1202)': 'PresentationInputType',
    '(0070,1203)': 'InputSequencePositionIndex',
    '(0070,1204)': 'Crop',
    '(0070,1205)': 'CroppingSpecificationIndex',
    '(0070,1206)': 'CompositingMethod',
    '(0070,1207)': 'VolumetricPresentationInputNumber',
    '(0070,1208)': 'ImageVolumeGeometry',
    '(0070,1209)': 'VolumetricPresentationInputSetUID',
    '(0070,120A)': 'VolumetricPresentationInputSetSequence',
    '(0070,120B)': 'GlobalCrop',
    '(0070,120C)': 'GlobalCroppingSpecificationIndex',
    '(0070,120D)': 'RenderingMethod',
    '(0070,1301)': 'VolumeCroppingSequence',
    '(0070,1302)': 'VolumeCroppingMethod',
    '(0070,1303)': 'BoundingBoxCrop',
    '(0070,1304)': 'ObliqueCroppingPlaneSequence',
    '(0070,1305)': 'Plane',
    '(0070,1306)': 'PlaneNormal',
    '(0070,1309)': 'CroppingSpecificationNumber',
    '(0070,1501)': 'MultiPlanarReconstructionStyle',
    '(0070,1502)': 'MPRThicknessType',
    '(0070,1503)': 'MPRSlabThickness',
    '(0070,1505)': 'MPRTopLeftHandCorner',
    '(0070,1507)': 'MPRViewWidthDirection',
    '(0070,1508)': 'MPRViewWidth',
    '(0070,150C)': 'NumberOfVolumetricCurvePoints',
    '(0070,150D)': 'VolumetricCurvePoints',
    '(0070,1511)': 'MPRViewHeightDirection',
    '(0070,1512)': 'MPRViewHeight',
    '(0070,1602)': 'RenderProjection',
    '(0070,1603)': 'ViewpointPosition',
    '(0070,1604)': 'ViewpointLookAtPoint',
    '(0070,1605)': 'ViewpointUpDirection',
    '(0070,1606)': 'RenderFieldOfView',
    '(0070,1607)': 'SamplingStepSize',
    '(0070,1701)': 'ShadingStyle',
    '(0070,1702)': 'AmbientReflectionIntensity',
    '(0070,1703)': 'LightDirection',
    '(0070,1704)': 'DiffuseReflectionIntensity',
    '(0070,1705)': 'SpecularReflectionIntensity',
    '(0070,1706)': 'Shininess',
    '(0070,1801)': 'PresentationStateClassificationComponentSequence',
    '(0070,1802)': 'ComponentType',
    '(0070,1803)': 'ComponentInputSequence',
    '(0070,1804)': 'VolumetricPresentationInputIndex',
    '(0070,1805)': 'PresentationStateCompositorComponentSequence',
    '(0070,1806)': 'WeightingTransferFunctionSequence',
    '(0070,1807)': 'WeightingLookupTableDescriptor',
    '(0070,1808)': 'WeightingLookupTableData',
    '(0070,1901)': 'VolumetricAnnotationSequence',
    '(0070,1903)': 'ReferencedStructuredContextSequence',
    '(0070,1904)': 'ReferencedContentItem',
    '(0070,1905)': 'VolumetricPresentationInputAnnotationSequence',
    '(0070,1907)': 'AnnotationClipping',
    '(0070,1A01)': 'PresentationAnimationStyle',
    '(0070,1A03)': 'RecommendedAnimationRate',
    '(0070,1A04)': 'AnimationCurveSequence',
    '(0070,1A05)': 'AnimationStepSize',
    '(0070,1A06)': 'SwivelRange',
    '(0070,1A07)': 'VolumetricCurveUpDirections',
    '(0070,1A08)': 'VolumeStreamSequence',
    '(0070,1A09)': 'RGBATransferFunctionDescription',
    '(0070,1B01)': 'AdvancedBlendingSequence',
    '(0070,1B02)': 'BlendingInputNumber',
    '(0070,1B03)': 'BlendingDisplayInputSequence',
    '(0070,1B04)': 'BlendingDisplaySequence',
    '(0070,1B06)': 'BlendingMode',
    '(0070,1B07)': 'TimeSeriesBlending',
    '(0070,1B08)': 'GeometryForDisplay',
    '(0070,1B11)': 'ThresholdSequence',
    '(0070,1B12)': 'ThresholdValueSequence',
    '(0070,1B13)': 'ThresholdType',
    '(0070,1B14)': 'ThresholdValue',
    '(0072,0002)': 'HangingProtocolName',
    '(0072,0004)': 'HangingProtocolDescription',
    '(0072,0006)': 'HangingProtocolLevel',
    '(0072,0008)': 'HangingProtocolCreator',
    '(0072,000A)': 'HangingProtocolCreationDateTime',
    '(0072,000C)': 'HangingProtocolDefinitionSequence',
    '(0072,000E)': 'HangingProtocolUserIdentificationCodeSequence',
    '(0072,0010)': 'HangingProtocolUserGroupName',
    '(0072,0012)': 'SourceHangingProtocolSequence',
    '(0072,0014)': 'NumberOfPriorsReferenced',
    '(0072,0020)': 'ImageSetsSequence',
    '(0072,0022)': 'ImageSetSelectorSequence',
    '(0072,0024)': 'ImageSetSelectorUsageFlag',
    '(0072,0026)': 'SelectorAttribute',
    '(0072,0028)': 'SelectorValueNumber',
    '(0072,0030)': 'TimeBasedImageSetsSequence',
    '(0072,0032)': 'ImageSetNumber',
    '(0072,0034)': 'ImageSetSelectorCategory',
    '(0072,0038)': 'RelativeTime',
    '(0072,003A)': 'RelativeTimeUnits',
    '(0072,003C)': 'AbstractPriorValue',
    '(0072,003E)': 'AbstractPriorCodeSequence',
    '(0072,0040)': 'ImageSetLabel',
    '(0072,0050)': 'SelectorAttributeVR',
    '(0072,0052)': 'SelectorSequencePointer',
    '(0072,0054)': 'SelectorSequencePointerPrivateCreator',
    '(0072,0056)': 'SelectorAttributePrivateCreator',
    '(0072,005E)': 'SelectorAEValue',
    '(0072,005F)': 'SelectorASValue',
    '(0072,0060)': 'SelectorATValue',
    '(0072,0061)': 'SelectorDAValue',
    '(0072,0062)': 'SelectorCSValue',
    '(0072,0063)': 'SelectorDTValue',
    '(0072,0064)': 'SelectorISValue',
    '(0072,0065)': 'SelectorOBValue',
    '(0072,0066)': 'SelectorLOValue',
    '(0072,0067)': 'SelectorOFValue',
    '(0072,0068)': 'SelectorLTValue',
    '(0072,0069)': 'SelectorOWValue',
    '(0072,006A)': 'SelectorPNValue',
    '(0072,006B)': 'SelectorTMValue',
    '(0072,006C)': 'SelectorSHValue',
    '(0072,006D)': 'SelectorUNValue',
    '(0072,006E)': 'SelectorSTValue',
    '(0072,006F)': 'SelectorUCValue',
    '(0072,0070)': 'SelectorUTValue',
    '(0072,0071)': 'SelectorURValue',
    '(0072,0072)': 'SelectorDSValue',
    '(0072,0073)': 'SelectorODValue',
    '(0072,0074)': 'SelectorFDValue',
    '(0072,0075)': 'SelectorOLValue',
    '(0072,0076)': 'SelectorFLValue',
    '(0072,0078)': 'SelectorULValue',
    '(0072,007A)': 'SelectorUSValue',
    '(0072,007C)': 'SelectorSLValue',
    '(0072,007E)': 'SelectorSSValue',
    '(0072,007F)': 'SelectorUIValue',
    '(0072,0080)': 'SelectorCodeSequenceValue',
    '(0072,0081)': 'SelectorOVValue',
    '(0072,0082)': 'SelectorSVValue',
    '(0072,0083)': 'SelectorUVValue',
    '(0072,0100)': 'NumberOfScreens',
    '(0072,0102)': 'NominalScreenDefinitionSequence',
    '(0072,0104)': 'NumberOfVerticalPixels',
    '(0072,0106)': 'NumberOfHorizontalPixels',
    '(0072,0108)': 'DisplayEnvironmentSpatialPosition',
    '(0072,010A)': 'ScreenMinimumGrayscaleBitDepth',
    '(0072,010C)': 'ScreenMinimumColorBitDepth',
    '(0072,010E)': 'ApplicationMaximumRepaintTime',
    '(0072,0200)': 'DisplaySetsSequence',
    '(0072,0202)': 'DisplaySetNumber',
    '(0072,0203)': 'DisplaySetLabel',
    '(0072,0204)': 'DisplaySetPresentationGroup',
    '(0072,0206)': 'DisplaySetPresentationGroupDescription',
    '(0072,0208)': 'PartialDataDisplayHandling',
    '(0072,0210)': 'SynchronizedScrollingSequence',
    '(0072,0212)': 'DisplaySetScrollingGroup',
    '(0072,0214)': 'NavigationIndicatorSequence',
    '(0072,0216)': 'NavigationDisplaySet',
    '(0072,0218)': 'ReferenceDisplaySets',
    '(0072,0300)': 'ImageBoxesSequence',
    '(0072,0302)': 'ImageBoxNumber',
    '(0072,0304)': 'ImageBoxLayoutType',
    '(0072,0306)': 'ImageBoxTileHorizontalDimension',
    '(0072,0308)': 'ImageBoxTileVerticalDimension',
    '(0072,0310)': 'ImageBoxScrollDirection',
    '(0072,0312)': 'ImageBoxSmallScrollType',
    '(0072,0314)': 'ImageBoxSmallScrollAmount',
    '(0072,0316)': 'ImageBoxLargeScrollType',
    '(0072,0318)': 'ImageBoxLargeScrollAmount',
    '(0072,0320)': 'ImageBoxOverlapPriority',
    '(0072,0330)': 'CineRelativeToRealTime',
    '(0072,0400)': 'FilterOperationsSequence',
    '(0072,0402)': 'FilterByCategory',
    '(0072,0404)': 'FilterByAttributePresence',
    '(0072,0406)': 'FilterByOperator',
    '(0072,0420)': 'StructuredDisplayBackgroundCIELabValue',
    '(0072,0421)': 'EmptyImageBoxCIELabValue',
    '(0072,0422)': 'StructuredDisplayImageBoxSequence',
    '(0072,0424)': 'StructuredDisplayTextBoxSequence',
    '(0072,0427)': 'ReferencedFirstFrameSequence',
    '(0072,0430)': 'ImageBoxSynchronizationSequence',
    '(0072,0432)': 'SynchronizedImageBoxList',
    '(0072,0434)': 'TypeOfSynchronization',
    '(0072,0500)': 'BlendingOperationType',
    '(0072,0510)': 'ReformattingOperationType',
    '(0072,0512)': 'ReformattingThickness',
    '(0072,0514)': 'ReformattingInterval',
    '(0072,0516)': 'ReformattingOperationInitialViewDirection',
    '(0072,0520)': 'ThreeDRenderingType',
    '(0072,0600)': 'SortingOperationsSequence',
    '(0072,0602)': 'SortByCategory',
    '(0072,0604)': 'SortingDirection',
    '(0072,0700)': 'DisplaySetPatientOrientation',
    '(0072,0702)': 'VOIType',
    '(0072,0704)': 'PseudoColorType',
    '(0072,0705)': 'PseudoColorPaletteInstanceReferenceSequence',
    '(0072,0706)': 'ShowGrayscaleInverted',
    '(0072,0710)': 'ShowImageTrueSizeFlag',
    '(0072,0712)': 'ShowGraphicAnnotationFlag',
    '(0072,0714)': 'ShowPatientDemographicsFlag',
    '(0072,0716)': 'ShowAcquisitionTechniquesFlag',
    '(0072,0717)': 'DisplaySetHorizontalJustification',
    '(0072,0718)': 'DisplaySetVerticalJustification',
    '(0074,0120)': 'ContinuationStartMeterset',
    '(0074,0121)': 'ContinuationEndMeterset',
    '(0074,1000)': 'ProcedureStepState',
    '(0074,1002)': 'ProcedureStepProgressInformationSequence',
    '(0074,1004)': 'ProcedureStepProgress',
    '(0074,1006)': 'ProcedureStepProgressDescription',
    '(0074,1007)': 'ProcedureStepProgressParametersSequence',
    '(0074,1008)': 'ProcedureStepCommunicationsURISequence',
    '(0074,100A)': 'ContactURI',
    '(0074,100C)': 'ContactDisplayName',
    '(0074,100E)': 'ProcedureStepDiscontinuationReasonCodeSequence',
    '(0074,1020)': 'BeamTaskSequence',
    '(0074,1022)': 'BeamTaskType',
    '(0074,1024)': 'BeamOrderIndexTrial',
    '(0074,1025)': 'AutosequenceFlag',
    '(0074,1026)': 'TableTopVerticalAdjustedPosition',
    '(0074,1027)': 'TableTopLongitudinalAdjustedPosition',
    '(0074,1028)': 'TableTopLateralAdjustedPosition',
    '(0074,102A)': 'PatientSupportAdjustedAngle',
    '(0074,102B)': 'TableTopEccentricAdjustedAngle',
    '(0074,102C)': 'TableTopPitchAdjustedAngle',
    '(0074,102D)': 'TableTopRollAdjustedAngle',
    '(0074,1030)': 'DeliveryVerificationImageSequence',
    '(0074,1032)': 'VerificationImageTiming',
    '(0074,1034)': 'DoubleExposureFlag',
    '(0074,1036)': 'DoubleExposureOrdering',
    '(0074,1038)': 'DoubleExposureMetersetTrial',
    '(0074,103A)': 'DoubleExposureFieldDeltaTrial',
    '(0074,1040)': 'RelatedReferenceRTImageSequence',
    '(0074,1042)': 'GeneralMachineVerificationSequence',
    '(0074,1044)': 'ConventionalMachineVerificationSequence',
    '(0074,1046)': 'IonMachineVerificationSequence',
    '(0074,1048)': 'FailedAttributesSequence',
    '(0074,104A)': 'OverriddenAttributesSequence',
    '(0074,104C)': 'ConventionalControlPointVerificationSequence',
    '(0074,104E)': 'IonControlPointVerificationSequence',
    '(0074,1050)': 'AttributeOccurrenceSequence',
    '(0074,1052)': 'AttributeOccurrencePointer',
    '(0074,1054)': 'AttributeItemSelector',
    '(0074,1056)': 'AttributeOccurrencePrivateCreator',
    '(0074,1057)': 'SelectorSequencePointerItems',
    '(0074,1200)': 'ScheduledProcedureStepPriority',
    '(0074,1202)': 'WorklistLabel',
    '(0074,1204)': 'ProcedureStepLabel',
    '(0074,1210)': 'ScheduledProcessingParametersSequence',
    '(0074,1212)': 'PerformedProcessingParametersSequence',
    '(0074,1216)': 'UnifiedProcedureStepPerformedProcedureSequence',
    '(0074,1220)': 'RelatedProcedureStepSequence',
    '(0074,1222)': 'ProcedureStepRelationshipType',
    '(0074,1224)': 'ReplacedProcedureStepSequence',
    '(0074,1230)': 'DeletionLock',
    '(0074,1234)': 'ReceivingAE',
    '(0074,1236)': 'RequestingAE',
    '(0074,1238)': 'ReasonForCancellation',
    '(0074,1242)': 'SCPStatus',
    '(0074,1244)': 'SubscriptionListStatus',
    '(0074,1246)': 'UnifiedProcedureStepListStatus',
    '(0074,1324)': 'BeamOrderIndex',
    '(0074,1338)': 'DoubleExposureMeterset',
    '(0074,133A)': 'DoubleExposureFieldDelta',
    '(0074,1401)': 'BrachyTaskSequence',
    '(0074,1402)': 'ContinuationStartTotalReferenceAirKerma',
    '(0074,1403)': 'ContinuationEndTotalReferenceAirKerma',
    '(0074,1404)': 'ContinuationPulseNumber',
    '(0074,1405)': 'ChannelDeliveryOrderSequence',
    '(0074,1406)': 'ReferencedChannelNumber',
    '(0074,1407)': 'StartCumulativeTimeWeight',
    '(0074,1408)': 'EndCumulativeTimeWeight',
    '(0074,1409)': 'OmittedChannelSequence',
    '(0074,140A)': 'ReasonForChannelOmission',
    '(0074,140B)': 'ReasonForChannelOmissionDescription',
    '(0074,140C)': 'ChannelDeliveryOrderIndex',
    '(0074,140D)': 'ChannelDeliveryContinuationSequence',
    '(0074,140E)': 'OmittedApplicationSetupSequence',
    '(0076,0001)': 'ImplantAssemblyTemplateName',
    '(0076,0003)': 'ImplantAssemblyTemplateIssuer',
    '(0076,0006)': 'ImplantAssemblyTemplateVersion',
    '(0076,0008)': 'ReplacedImplantAssemblyTemplateSequence',
    '(0076,000A)': 'ImplantAssemblyTemplateType',
    '(0076,000C)': 'OriginalImplantAssemblyTemplateSequence',
    '(0076,000E)': 'DerivationImplantAssemblyTemplateSequence',
    '(0076,0010)': 'ImplantAssemblyTemplateTargetAnatomySequence',
    '(0076,0020)': 'ProcedureTypeCodeSequence',
    '(0076,0030)': 'SurgicalTechnique',
    '(0076,0032)': 'ComponentTypesSequence',
    '(0076,0034)': 'ComponentTypeCodeSequence',
    '(0076,0036)': 'ExclusiveComponentType',
    '(0076,0038)': 'MandatoryComponentType',
    '(0076,0040)': 'ComponentSequence',
    '(0076,0055)': 'ComponentID',
    '(0076,0060)': 'ComponentAssemblySequence',
    '(0076,0070)': 'Component1ReferencedID',
    '(0076,0080)': 'Component1ReferencedMatingFeatureSetID',
    '(0076,0090)': 'Component1ReferencedMatingFeatureID',
    '(0076,00A0)': 'Component2ReferencedID',
    '(0076,00B0)': 'Component2ReferencedMatingFeatureSetID',
    '(0076,00C0)': 'Component2ReferencedMatingFeatureID',
    '(0078,0001)': 'ImplantTemplateGroupName',
    '(0078,0010)': 'ImplantTemplateGroupDescription',
    '(0078,0020)': 'ImplantTemplateGroupIssuer',
    '(0078,0024)': 'ImplantTemplateGroupVersion',
    '(0078,0026)': 'ReplacedImplantTemplateGroupSequence',
    '(0078,0028)': 'ImplantTemplateGroupTargetAnatomySequence',
    '(0078,002A)': 'ImplantTemplateGroupMembersSequence',
    '(0078,002E)': 'ImplantTemplateGroupMemberID',
    '(0078,0050)': 'ThreeDImplantTemplateGroupMemberMatchingPoint',
    '(0078,0060)': 'ThreeDImplantTemplateGroupMemberMatchingAxes',
    '(0078,0070)': 'ImplantTemplateGroupMemberMatching2DCoordinatesSequence',
    '(0078,0090)': 'TwoDImplantTemplateGroupMemberMatchingPoint',
    '(0078,00A0)': 'TwoDImplantTemplateGroupMemberMatchingAxes',
    '(0078,00B0)': 'ImplantTemplateGroupVariationDimensionSequence',
    '(0078,00B2)': 'ImplantTemplateGroupVariationDimensionName',
    '(0078,00B4)': 'ImplantTemplateGroupVariationDimensionRankSequence',
    '(0078,00B6)': 'ReferencedImplantTemplateGroupMemberID',
    '(0078,00B8)': 'ImplantTemplateGroupVariationDimensionRank',
    '(0080,0001)': 'SurfaceScanAcquisitionTypeCodeSequence',
    '(0080,0002)': 'SurfaceScanModeCodeSequence',
    '(0080,0003)': 'RegistrationMethodCodeSequence',
    '(0080,0004)': 'ShotDurationTime',
    '(0080,0005)': 'ShotOffsetTime',
    '(0080,0006)': 'SurfacePointPresentationValueData',
    '(0080,0007)': 'SurfacePointColorCIELabValueData',
    '(0080,0008)': 'UVMappingSequence',
    '(0080,0009)': 'TextureLabel',
    '(0080,0010)': 'UValueData',
    '(0080,0011)': 'VValueData',
    '(0080,0012)': 'ReferencedTextureSequence',
    '(0080,0013)': 'ReferencedSurfaceDataSequence',
    '(0082,0001)': 'AssessmentSummary',
    '(0082,0003)': 'AssessmentSummaryDescription',
    '(0082,0004)': 'AssessedSOPInstanceSequence',
    '(0082,0005)': 'ReferencedComparisonSOPInstanceSequence',
    '(0082,0006)': 'NumberOfAssessmentObservations',
    '(0082,0007)': 'AssessmentObservationsSequence',
    '(0082,0008)': 'ObservationSignificance',
    '(0082,000A)': 'ObservationDescription',
    '(0082,000C)': 'StructuredConstraintObservationSequence',
    '(0082,0010)': 'AssessedAttributeValueSequence',
    '(0082,0016)': 'AssessmentSetID',
    '(0082,0017)': 'AssessmentRequesterSequence',
    '(0082,0018)': 'SelectorAttributeName',
    '(0082,0019)': 'SelectorAttributeKeyword',
    '(0082,0021)': 'AssessmentTypeCodeSequence',
    '(0082,0022)': 'ObservationBasisCodeSequence',
    '(0082,0023)': 'AssessmentLabel',
    '(0082,0032)': 'ConstraintType',
    '(0082,0033)': 'SpecificationSelectionGuidance',
    '(0082,0034)': 'ConstraintValueSequence',
    '(0082,0035)': 'RecommendedDefaultValueSequence',
    '(0082,0036)': 'ConstraintViolationSignificance',
    '(0082,0037)': 'ConstraintViolationCondition',
    '(0082,0038)': 'ModifiableConstraintFlag',
    '(0088,0130)': 'StorageMediaFileSetID',
    '(0088,0140)': 'StorageMediaFileSetUID',
    '(0088,0200)': 'IconImageSequence',
    '(0088,0904)': 'TopicTitle',
    '(0088,0906)': 'TopicSubject',
    '(0088,0910)': 'TopicAuthor',
    '(0088,0912)': 'TopicKeywords',
    '(0100,0410)': 'SOPInstanceStatus',
    '(0100,0420)': 'SOPAuthorizationDateTime',
    '(0100,0424)': 'SOPAuthorizationComment',
    '(0100,0426)': 'AuthorizationEquipmentCertificationNumber',
    '(0400,0005)': 'MACIDNumber',
    '(0400,0010)': 'MACCalculationTransferSyntaxUID',
    '(0400,0015)': 'MACAlgorithm',
    '(0400,0020)': 'DataElementsSigned',
    '(0400,0100)': 'DigitalSignatureUID',
    '(0400,0105)': 'DigitalSignatureDateTime',
    '(0400,0110)': 'CertificateType',
    '(0400,0115)': 'CertificateOfSigner',
    '(0400,0120)': 'Signature',
    '(0400,0305)': 'CertifiedTimestampType',
    '(0400,0310)': 'CertifiedTimestamp',
    '(0400,0401)': 'DigitalSignaturePurposeCodeSequence',
    '(0400,0402)': 'ReferencedDigitalSignatureSequence',
    '(0400,0403)': 'ReferencedSOPInstanceMACSequence',
    '(0400,0404)': 'MAC',
    '(0400,0500)': 'EncryptedAttributesSequence',
    '(0400,0510)': 'EncryptedContentTransferSyntaxUID',
    '(0400,0520)': 'EncryptedContent',
    '(0400,0550)': 'ModifiedAttributesSequence',
    '(0400,0551)': 'NonconformingModifiedAttributesSequence',
    '(0400,0552)': 'NonconformingDataElementValue',
    '(0400,0561)': 'OriginalAttributesSequence',
    '(0400,0562)': 'AttributeModificationDateTime',
    '(0400,0563)': 'ModifyingSystem',
    '(0400,0564)': 'SourceOfPreviousValues',
    '(0400,0565)': 'ReasonForTheAttributeModification',
    '(0400,0600)': 'InstanceOriginStatus',
    '(1000,xxx0)': 'EscapeTriplet',
    '(1000,xxx1)': 'RunLengthTriplet',
    '(1000,xxx2)': 'HuffmanTableSize',
    '(1000,xxx3)': 'HuffmanTableTriplet',
    '(1000,xxx4)': 'ShiftTableSize',
    '(1000,xxx5)': 'ShiftTableTriplet',
    '(1010,xxxx)': 'ZonalMap',
    '(2000,0010)': 'NumberOfCopies',
    '(2000,001E)': 'PrinterConfigurationSequence',
    '(2000,0020)': 'PrintPriority',
    '(2000,0030)': 'MediumType',
    '(2000,0040)': 'FilmDestination',
    '(2000,0050)': 'FilmSessionLabel',
    '(2000,0060)': 'MemoryAllocation',
    '(2000,0061)': 'MaximumMemoryAllocation',
    '(2000,0062)': 'ColorImagePrintingFlag',
    '(2000,0063)': 'CollationFlag',
    '(2000,0065)': 'AnnotationFlag',
    '(2000,0067)': 'ImageOverlayFlag',
    '(2000,0069)': 'PresentationLUTFlag',
    '(2000,006A)': 'ImageBoxPresentationLUTFlag',
    '(2000,00A0)': 'MemoryBitDepth',
    '(2000,00A1)': 'PrintingBitDepth',
    '(2000,00A2)': 'MediaInstalledSequence',
    '(2000,00A4)': 'OtherMediaAvailableSequence',
    '(2000,00A8)': 'SupportedImageDisplayFormatsSequence',
    '(2000,0500)': 'ReferencedFilmBoxSequence',
    '(2000,0510)': 'ReferencedStoredPrintSequence',
    '(2010,0010)': 'ImageDisplayFormat',
    '(2010,0030)': 'AnnotationDisplayFormatID',
    '(2010,0040)': 'FilmOrientation',
    '(2010,0050)': 'FilmSizeID',
    '(2010,0052)': 'PrinterResolutionID',
    '(2010,0054)': 'DefaultPrinterResolutionID',
    '(2010,0060)': 'MagnificationType',
    '(2010,0080)': 'SmoothingType',
    '(2010,00A6)': 'DefaultMagnificationType',
    '(2010,00A7)': 'OtherMagnificationTypesAvailable',
    '(2010,00A8)': 'DefaultSmoothingType',
    '(2010,00A9)': 'OtherSmoothingTypesAvailable',
    '(2010,0100)': 'BorderDensity',
    '(2010,0110)': 'EmptyImageDensity',
    '(2010,0120)': 'MinDensity',
    '(2010,0130)': 'MaxDensity',
    '(2010,0140)': 'Trim',
    '(2010,0150)': 'ConfigurationInformation',
    '(2010,0152)': 'ConfigurationInformationDescription',
    '(2010,0154)': 'MaximumCollatedFilms',
    '(2010,015E)': 'Illumination',
    '(2010,0160)': 'ReflectedAmbientLight',
    '(2010,0376)': 'PrinterPixelSpacing',
    '(2010,0500)': 'ReferencedFilmSessionSequence',
    '(2010,0510)': 'ReferencedImageBoxSequence',
    '(2010,0520)': 'ReferencedBasicAnnotationBoxSequence',
    '(2020,0010)': 'ImageBoxPosition',
    '(2020,0020)': 'Polarity',
    '(2020,0030)': 'RequestedImageSize',
    '(2020,0040)': 'RequestedDecimateCropBehavior',
    '(2020,0050)': 'RequestedResolutionID',
    '(2020,00A0)': 'RequestedImageSizeFlag',
    '(2020,00A2)': 'DecimateCropResult',
    '(2020,0110)': 'BasicGrayscaleImageSequence',
    '(2020,0111)': 'BasicColorImageSequence',
    '(2020,0130)': 'ReferencedImageOverlayBoxSequence',
    '(2020,0140)': 'ReferencedVOILUTBoxSequence',
    '(2030,0010)': 'AnnotationPosition',
    '(2030,0020)': 'TextString',
    '(2040,0010)': 'ReferencedOverlayPlaneSequence',
    '(2040,0011)': 'ReferencedOverlayPlaneGroups',
    '(2040,0020)': 'OverlayPixelDataSequence',
    '(2040,0060)': 'OverlayMagnificationType',
    '(2040,0070)': 'OverlaySmoothingType',
    '(2040,0072)': 'OverlayOrImageMagnification',
    '(2040,0074)': 'MagnifyToNumberOfColumns',
    '(2040,0080)': 'OverlayForegroundDensity',
    '(2040,0082)': 'OverlayBackgroundDensity',
    '(2040,0090)': 'OverlayMode',
    '(2040,0100)': 'ThresholdDensity',
    '(2040,0500)': 'ReferencedImageBoxSequenceRetired',
    '(2050,0010)': 'PresentationLUTSequence',
    '(2050,0020)': 'PresentationLUTShape',
    '(2050,0500)': 'ReferencedPresentationLUTSequence',
    '(2100,0010)': 'PrintJobID',
    '(2100,0020)': 'ExecutionStatus',
    '(2100,0030)': 'ExecutionStatusInfo',
    '(2100,0040)': 'CreationDate',
    '(2100,0050)': 'CreationTime',
    '(2100,0070)': 'Originator',
    '(2100,0140)': 'DestinationAE',
    '(2100,0160)': 'OwnerID',
    '(2100,0170)': 'NumberOfFilms',
    '(2100,0500)': 'ReferencedPrintJobSequencePullStoredPrint',
    '(2110,0010)': 'PrinterStatus',
    '(2110,0020)': 'PrinterStatusInfo',
    '(2110,0030)': 'PrinterName',
    '(2110,0099)': 'PrintQueueID',
    '(2120,0010)': 'QueueStatus',
    '(2120,0050)': 'PrintJobDescriptionSequence',
    '(2120,0070)': 'ReferencedPrintJobSequence',
    '(2130,0010)': 'PrintManagementCapabilitiesSequence',
    '(2130,0015)': 'PrinterCharacteristicsSequence',
    '(2130,0030)': 'FilmBoxContentSequence',
    '(2130,0040)': 'ImageBoxContentSequence',
    '(2130,0050)': 'AnnotationContentSequence',
    '(2130,0060)': 'ImageOverlayBoxContentSequence',
    '(2130,0080)': 'PresentationLUTContentSequence',
    '(2130,00A0)': 'ProposedStudySequence',
    '(2130,00C0)': 'OriginalImageSequence',
    '(2200,0001)': 'LabelUsingInformationExtractedFromInstances',
    '(2200,0002)': 'LabelText',
    '(2200,0003)': 'LabelStyleSelection',
    '(2200,0004)': 'MediaDisposition',
    '(2200,0005)': 'BarcodeValue',
    '(2200,0006)': 'BarcodeSymbology',
    '(2200,0007)': 'AllowMediaSplitting',
    '(2200,0008)': 'IncludeNonDICOMObjects',
    '(2200,0009)': 'IncludeDisplayApplication',
    '(2200,000A)': 'PreserveCompositeInstancesAfterMediaCreation',
    '(2200,000B)': 'TotalNumberOfPiecesOfMediaCreated',
    '(2200,000C)': 'RequestedMediaApplicationProfile',
    '(2200,000D)': 'ReferencedStorageMediaSequence',
    '(2200,000E)': 'FailureAttributes',
    '(2200,000F)': 'AllowLossyCompression',
    '(2200,0020)': 'RequestPriority',
    '(3002,0002)': 'RTImageLabel',
    '(3002,0003)': 'RTImageName',
    '(3002,0004)': 'RTImageDescription',
    '(3002,000A)': 'ReportedValuesOrigin',
    '(3002,000C)': 'RTImagePlane',
    '(3002,000D)': 'XRayImageReceptorTranslation',
    '(3002,000E)': 'XRayImageReceptorAngle',
    '(3002,0010)': 'RTImageOrientation',
    '(3002,0011)': 'ImagePlanePixelSpacing',
    '(3002,0012)': 'RTImagePosition',
    '(3002,0020)': 'RadiationMachineName',
    '(3002,0022)': 'RadiationMachineSAD',
    '(3002,0024)': 'RadiationMachineSSD',
    '(3002,0026)': 'RTImageSID',
    '(3002,0028)': 'SourceToReferenceObjectDistance',
    '(3002,0029)': 'FractionNumber',
    '(3002,0030)': 'ExposureSequence',
    '(3002,0032)': 'MetersetExposure',
    '(3002,0034)': 'DiaphragmPosition',
    '(3002,0040)': 'FluenceMapSequence',
    '(3002,0041)': 'FluenceDataSource',
    '(3002,0042)': 'FluenceDataScale',
    '(3002,0050)': 'PrimaryFluenceModeSequence',
    '(3002,0051)': 'FluenceMode',
    '(3002,0052)': 'FluenceModeID',
    '(3004,0001)': 'DVHType',
    '(3004,0002)': 'DoseUnits',
    '(3004,0004)': 'DoseType',
    '(3004,0005)': 'SpatialTransformOfDose',
    '(3004,0006)': 'DoseComment',
    '(3004,0008)': 'NormalizationPoint',
    '(3004,000A)': 'DoseSummationType',
    '(3004,000C)': 'GridFrameOffsetVector',
    '(3004,000E)': 'DoseGridScaling',
    '(3004,0010)': 'RTDoseROISequence',
    '(3004,0012)': 'DoseValue',
    '(3004,0014)': 'TissueHeterogeneityCorrection',
    '(3004,0040)': 'DVHNormalizationPoint',
    '(3004,0042)': 'DVHNormalizationDoseValue',
    '(3004,0050)': 'DVHSequence',
    '(3004,0052)': 'DVHDoseScaling',
    '(3004,0054)': 'DVHVolumeUnits',
    '(3004,0056)': 'DVHNumberOfBins',
    '(3004,0058)': 'DVHData',
    '(3004,0060)': 'DVHReferencedROISequence',
    '(3004,0062)': 'DVHROIContributionType',
    '(3004,0070)': 'DVHMinimumDose',
    '(3004,0072)': 'DVHMaximumDose',
    '(3004,0074)': 'DVHMeanDose',
    '(3006,0002)': 'StructureSetLabel',
    '(3006,0004)': 'StructureSetName',
    '(3006,0006)': 'StructureSetDescription',
    '(3006,0008)': 'StructureSetDate',
    '(3006,0009)': 'StructureSetTime',
    '(3006,0010)': 'ReferencedFrameOfReferenceSequence',
    '(3006,0012)': 'RTReferencedStudySequence',
    '(3006,0014)': 'RTReferencedSeriesSequence',
    '(3006,0016)': 'ContourImageSequence',
    '(3006,0018)': 'PredecessorStructureSetSequence',
    '(3006,0020)': 'StructureSetROISequence',
    '(3006,0022)': 'ROINumber',
    '(3006,0024)': 'ReferencedFrameOfReferenceUID',
    '(3006,0026)': 'ROIName',
    '(3006,0028)': 'ROIDescription',
    '(3006,002A)': 'ROIDisplayColor',
    '(3006,002C)': 'ROIVolume',
    '(3006,0030)': 'RTRelatedROISequence',
    '(3006,0033)': 'RTROIRelationship',
    '(3006,0036)': 'ROIGenerationAlgorithm',
    '(3006,0037)': 'ROIDerivationAlgorithmIdentificationSequence',
    '(3006,0038)': 'ROIGenerationDescription',
    '(3006,0039)': 'ROIContourSequence',
    '(3006,0040)': 'ContourSequence',
    '(3006,0042)': 'ContourGeometricType',
    '(3006,0044)': 'ContourSlabThickness',
    '(3006,0045)': 'ContourOffsetVector',
    '(3006,0046)': 'NumberOfContourPoints',
    '(3006,0048)': 'ContourNumber',
    '(3006,0049)': 'AttachedContours',
    '(3006,004A)': 'SourcePixelPlanesCharacteristicsSequence',
    '(3006,0050)': 'ContourData',
    '(3006,0080)': 'RTROIObservationsSequence',
    '(3006,0082)': 'ObservationNumber',
    '(3006,0084)': 'ReferencedROINumber',
    '(3006,0085)': 'ROIObservationLabel',
    '(3006,0086)': 'RTROIIdentificationCodeSequence',
    '(3006,0088)': 'ROIObservationDescription',
    '(3006,00A0)': 'RelatedRTROIObservationsSequence',
    '(3006,00A4)': 'RTROIInterpretedType',
    '(3006,00A6)': 'ROIInterpreter',
    '(3006,00B0)': 'ROIPhysicalPropertiesSequence',
    '(3006,00B2)': 'ROIPhysicalProperty',
    '(3006,00B4)': 'ROIPhysicalPropertyValue',
    '(3006,00B6)': 'ROIElementalCompositionSequence',
    '(3006,00B7)': 'ROIElementalCompositionAtomicNumber',
    '(3006,00B8)': 'ROIElementalCompositionAtomicMassFraction',
    '(3006,00B9)': 'AdditionalRTROIIdentificationCodeSequence',
    '(3006,00C0)': 'FrameOfReferenceRelationshipSequence',
    '(3006,00C2)': 'RelatedFrameOfReferenceUID',
    '(3006,00C4)': 'FrameOfReferenceTransformationType',
    '(3006,00C6)': 'FrameOfReferenceTransformationMatrix',
    '(3006,00C8)': 'FrameOfReferenceTransformationComment',
    '(3006,00C9)': 'PatientLocationCoordinatesSequence',
    '(3006,00CA)': 'PatientLocationCoordinatesCodeSequence',
    '(3006,00CB)': 'PatientSupportPositionSequence',
    '(3008,0010)': 'MeasuredDoseReferenceSequence',
    '(3008,0012)': 'MeasuredDoseDescription',
    '(3008,0014)': 'MeasuredDoseType',
    '(3008,0016)': 'MeasuredDoseValue',
    '(3008,0020)': 'TreatmentSessionBeamSequence',
    '(3008,0021)': 'TreatmentSessionIonBeamSequence',
    '(3008,0022)': 'CurrentFractionNumber',
    '(3008,0024)': 'TreatmentControlPointDate',
    '(3008,0025)': 'TreatmentControlPointTime',
    '(3008,002A)': 'TreatmentTerminationStatus',
    '(3008,002B)': 'TreatmentTerminationCode',
    '(3008,002C)': 'TreatmentVerificationStatus',
    '(3008,0030)': 'ReferencedTreatmentRecordSequence',
    '(3008,0032)': 'SpecifiedPrimaryMeterset',
    '(3008,0033)': 'SpecifiedSecondaryMeterset',
    '(3008,0036)': 'DeliveredPrimaryMeterset',
    '(3008,0037)': 'DeliveredSecondaryMeterset',
    '(3008,003A)': 'SpecifiedTreatmentTime',
    '(3008,003B)': 'DeliveredTreatmentTime',
    '(3008,0040)': 'ControlPointDeliverySequence',
    '(3008,0041)': 'IonControlPointDeliverySequence',
    '(3008,0042)': 'SpecifiedMeterset',
    '(3008,0044)': 'DeliveredMeterset',
    '(3008,0045)': 'MetersetRateSet',
    '(3008,0046)': 'MetersetRateDelivered',
    '(3008,0047)': 'ScanSpotMetersetsDelivered',
    '(3008,0048)': 'DoseRateDelivered',
    '(3008,0050)': 'TreatmentSummaryCalculatedDoseReferenceSequence',
    '(3008,0052)': 'CumulativeDoseToDoseReference',
    '(3008,0054)': 'FirstTreatmentDate',
    '(3008,0056)': 'MostRecentTreatmentDate',
    '(3008,005A)': 'NumberOfFractionsDelivered',
    '(3008,0060)': 'OverrideSequence',
    '(3008,0061)': 'ParameterSequencePointer',
    '(3008,0062)': 'OverrideParameterPointer',
    '(3008,0063)': 'ParameterItemIndex',
    '(3008,0064)': 'MeasuredDoseReferenceNumber',
    '(3008,0065)': 'ParameterPointer',
    '(3008,0066)': 'OverrideReason',
    '(3008,0067)': 'ParameterValueNumber',
    '(3008,0068)': 'CorrectedParameterSequence',
    '(3008,006A)': 'CorrectionValue',
    '(3008,0070)': 'CalculatedDoseReferenceSequence',
    '(3008,0072)': 'CalculatedDoseReferenceNumber',
    '(3008,0074)': 'CalculatedDoseReferenceDescription',
    '(3008,0076)': 'CalculatedDoseReferenceDoseValue',
    '(3008,0078)': 'StartMeterset',
    '(3008,007A)': 'EndMeterset',
    '(3008,0080)': 'ReferencedMeasuredDoseReferenceSequence',
    '(3008,0082)': 'ReferencedMeasuredDoseReferenceNumber',
    '(3008,0090)': 'ReferencedCalculatedDoseReferenceSequence',
    '(3008,0092)': 'ReferencedCalculatedDoseReferenceNumber',
    '(3008,00A0)': 'BeamLimitingDeviceLeafPairsSequence',
    '(3008,00B0)': 'RecordedWedgeSequence',
    '(3008,00C0)': 'RecordedCompensatorSequence',
    '(3008,00D0)': 'RecordedBlockSequence',
    '(3008,00D1)': 'RecordedBlockSlabSequence',
    '(3008,00E0)': 'TreatmentSummaryMeasuredDoseReferenceSequence',
    '(3008,00F0)': 'RecordedSnoutSequence',
    '(3008,00F2)': 'RecordedRangeShifterSequence',
    '(3008,00F4)': 'RecordedLateralSpreadingDeviceSequence',
    '(3008,00F6)': 'RecordedRangeModulatorSequence',
    '(3008,0100)': 'RecordedSourceSequence',
    '(3008,0105)': 'SourceSerialNumber',
    '(3008,0110)': 'TreatmentSessionApplicationSetupSequence',
    '(3008,0116)': 'ApplicationSetupCheck',
    '(3008,0120)': 'RecordedBrachyAccessoryDeviceSequence',
    '(3008,0122)': 'ReferencedBrachyAccessoryDeviceNumber',
    '(3008,0130)': 'RecordedChannelSequence',
    '(3008,0132)': 'SpecifiedChannelTotalTime',
    '(3008,0134)': 'DeliveredChannelTotalTime',
    '(3008,0136)': 'SpecifiedNumberOfPulses',
    '(3008,0138)': 'DeliveredNumberOfPulses',
    '(3008,013A)': 'SpecifiedPulseRepetitionInterval',
    '(3008,013C)': 'DeliveredPulseRepetitionInterval',
    '(3008,0140)': 'RecordedSourceApplicatorSequence',
    '(3008,0142)': 'ReferencedSourceApplicatorNumber',
    '(3008,0150)': 'RecordedChannelShieldSequence',
    '(3008,0152)': 'ReferencedChannelShieldNumber',
    '(3008,0160)': 'BrachyControlPointDeliveredSequence',
    '(3008,0162)': 'SafePositionExitDate',
    '(3008,0164)': 'SafePositionExitTime',
    '(3008,0166)': 'SafePositionReturnDate',
    '(3008,0168)': 'SafePositionReturnTime',
    '(3008,0171)': 'PulseSpecificBrachyControlPointDeliveredSequence',
    '(3008,0172)': 'PulseNumber',
    '(3008,0173)': 'BrachyPulseControlPointDeliveredSequence',
    '(3008,0200)': 'CurrentTreatmentStatus',
    '(3008,0202)': 'TreatmentStatusComment',
    '(3008,0220)': 'FractionGroupSummarySequence',
    '(3008,0223)': 'ReferencedFractionNumber',
    '(3008,0224)': 'FractionGroupType',
    '(3008,0230)': 'BeamStopperPosition',
    '(3008,0240)': 'FractionStatusSummarySequence',
    '(3008,0250)': 'TreatmentDate',
    '(3008,0251)': 'TreatmentTime',
    '(300A,0002)': 'RTPlanLabel',
    '(300A,0003)': 'RTPlanName',
    '(300A,0004)': 'RTPlanDescription',
    '(300A,0006)': 'RTPlanDate',
    '(300A,0007)': 'RTPlanTime',
    '(300A,0009)': 'TreatmentProtocols',
    '(300A,000A)': 'PlanIntent',
    '(300A,000B)': 'TreatmentSites',
    '(300A,000C)': 'RTPlanGeometry',
    '(300A,000E)': 'PrescriptionDescription',
    '(300A,0010)': 'DoseReferenceSequence',
    '(300A,0012)': 'DoseReferenceNumber',
    '(300A,0013)': 'DoseReferenceUID',
    '(300A,0014)': 'DoseReferenceStructureType',
    '(300A,0015)': 'NominalBeamEnergyUnit',
    '(300A,0016)': 'DoseReferenceDescription',
    '(300A,0018)': 'DoseReferencePointCoordinates',
    '(300A,001A)': 'NominalPriorDose',
    '(300A,0020)': 'DoseReferenceType',
    '(300A,0021)': 'ConstraintWeight',
    '(300A,0022)': 'DeliveryWarningDose',
    '(300A,0023)': 'DeliveryMaximumDose',
    '(300A,0025)': 'TargetMinimumDose',
    '(300A,0026)': 'TargetPrescriptionDose',
    '(300A,0027)': 'TargetMaximumDose',
    '(300A,0028)': 'TargetUnderdoseVolumeFraction',
    '(300A,002A)': 'OrganAtRiskFullVolumeDose',
    '(300A,002B)': 'OrganAtRiskLimitDose',
    '(300A,002C)': 'OrganAtRiskMaximumDose',
    '(300A,002D)': 'OrganAtRiskOverdoseVolumeFraction',
    '(300A,0040)': 'ToleranceTableSequence',
    '(300A,0042)': 'ToleranceTableNumber',
    '(300A,0043)': 'ToleranceTableLabel',
    '(300A,0044)': 'GantryAngleTolerance',
    '(300A,0046)': 'BeamLimitingDeviceAngleTolerance',
    '(300A,0048)': 'BeamLimitingDeviceToleranceSequence',
    '(300A,004A)': 'BeamLimitingDevicePositionTolerance',
    '(300A,004B)': 'SnoutPositionTolerance',
    '(300A,004C)': 'PatientSupportAngleTolerance',
    '(300A,004E)': 'TableTopEccentricAngleTolerance',
    '(300A,004F)': 'TableTopPitchAngleTolerance',
    '(300A,0050)': 'TableTopRollAngleTolerance',
    '(300A,0051)': 'TableTopVerticalPositionTolerance',
    '(300A,0052)': 'TableTopLongitudinalPositionTolerance',
    '(300A,0053)': 'TableTopLateralPositionTolerance',
    '(300A,0055)': 'RTPlanRelationship',
    '(300A,0070)': 'FractionGroupSequence',
    '(300A,0071)': 'FractionGroupNumber',
    '(300A,0072)': 'FractionGroupDescription',
    '(300A,0078)': 'NumberOfFractionsPlanned',
    '(300A,0079)': 'NumberOfFractionPatternDigitsPerDay',
    '(300A,007A)': 'RepeatFractionCycleLength',
    '(300A,007B)': 'FractionPattern',
    '(300A,0080)': 'NumberOfBeams',
    '(300A,0082)': 'BeamDoseSpecificationPoint',
    '(300A,0083)': 'ReferencedDoseReferenceUID',
    '(300A,0084)': 'BeamDose',
    '(300A,0086)': 'BeamMeterset',
    '(300A,0088)': 'BeamDosePointDepth',
    '(300A,0089)': 'BeamDosePointEquivalentDepth',
    '(300A,008A)': 'BeamDosePointSSD',
    '(300A,008B)': 'BeamDoseMeaning',
    '(300A,008C)': 'BeamDoseVerificationControlPointSequence',
    '(300A,008D)': 'AverageBeamDosePointDepth',
    '(300A,008E)': 'AverageBeamDosePointEquivalentDepth',
    '(300A,008F)': 'AverageBeamDosePointSSD',
    '(300A,0090)': 'BeamDoseType',
    '(300A,0091)': 'AlternateBeamDose',
    '(300A,0092)': 'AlternateBeamDoseType',
    '(300A,0093)': 'DepthValueAveragingFlag',
    '(300A,0094)': 'BeamDosePointSourceToExternalContourDistance',
    '(300A,00A0)': 'NumberOfBrachyApplicationSetups',
    '(300A,00A2)': 'BrachyApplicationSetupDoseSpecificationPoint',
    '(300A,00A4)': 'BrachyApplicationSetupDose',
    '(300A,00B0)': 'BeamSequence',
    '(300A,00B2)': 'TreatmentMachineName',
    '(300A,00B3)': 'PrimaryDosimeterUnit',
    '(300A,00B4)': 'SourceAxisDistance',
    '(300A,00B6)': 'BeamLimitingDeviceSequence',
    '(300A,00B8)': 'RTBeamLimitingDeviceType',
    '(300A,00BA)': 'SourceToBeamLimitingDeviceDistance',
    '(300A,00BB)': 'IsocenterToBeamLimitingDeviceDistance',
    '(300A,00BC)': 'NumberOfLeafJawPairs',
    '(300A,00BE)': 'LeafPositionBoundaries',
    '(300A,00C0)': 'BeamNumber',
    '(300A,00C2)': 'BeamName',
    '(300A,00C3)': 'BeamDescription',
    '(300A,00C4)': 'BeamType',
    '(300A,00C5)': 'BeamDeliveryDurationLimit',
    '(300A,00C6)': 'RadiationType',
    '(300A,00C7)': 'HighDoseTechniqueType',
    '(300A,00C8)': 'ReferenceImageNumber',
    '(300A,00CA)': 'PlannedVerificationImageSequence',
    '(300A,00CC)': 'ImagingDeviceSpecificAcquisitionParameters',
    '(300A,00CE)': 'TreatmentDeliveryType',
    '(300A,00D0)': 'NumberOfWedges',
    '(300A,00D1)': 'WedgeSequence',
    '(300A,00D2)': 'WedgeNumber',
    '(300A,00D3)': 'WedgeType',
    '(300A,00D4)': 'WedgeID',
    '(300A,00D5)': 'WedgeAngle',
    '(300A,00D6)': 'WedgeFactor',
    '(300A,00D7)': 'TotalWedgeTrayWaterEquivalentThickness',
    '(300A,00D8)': 'WedgeOrientation',
    '(300A,00D9)': 'IsocenterToWedgeTrayDistance',
    '(300A,00DA)': 'SourceToWedgeTrayDistance',
    '(300A,00DB)': 'WedgeThinEdgePosition',
    '(300A,00DC)': 'BolusID',
    '(300A,00DD)': 'BolusDescription',
    '(300A,00DE)': 'EffectiveWedgeAngle',
    '(300A,00E0)': 'NumberOfCompensators',
    '(300A,00E1)': 'MaterialID',
    '(300A,00E2)': 'TotalCompensatorTrayFactor',
    '(300A,00E3)': 'CompensatorSequence',
    '(300A,00E4)': 'CompensatorNumber',
    '(300A,00E5)': 'CompensatorID',
    '(300A,00E6)': 'SourceToCompensatorTrayDistance',
    '(300A,00E7)': 'CompensatorRows',
    '(300A,00E8)': 'CompensatorColumns',
    '(300A,00E9)': 'CompensatorPixelSpacing',
    '(300A,00EA)': 'CompensatorPosition',
    '(300A,00EB)': 'CompensatorTransmissionData',
    '(300A,00EC)': 'CompensatorThicknessData',
    '(300A,00ED)': 'NumberOfBoli',
    '(300A,00EE)': 'CompensatorType',
    '(300A,00EF)': 'CompensatorTrayID',
    '(300A,00F0)': 'NumberOfBlocks',
    '(300A,00F2)': 'TotalBlockTrayFactor',
    '(300A,00F3)': 'TotalBlockTrayWaterEquivalentThickness',
    '(300A,00F4)': 'BlockSequence',
    '(300A,00F5)': 'BlockTrayID',
    '(300A,00F6)': 'SourceToBlockTrayDistance',
    '(300A,00F7)': 'IsocenterToBlockTrayDistance',
    '(300A,00F8)': 'BlockType',
    '(300A,00F9)': 'AccessoryCode',
    '(300A,00FA)': 'BlockDivergence',
    '(300A,00FB)': 'BlockMountingPosition',
    '(300A,00FC)': 'BlockNumber',
    '(300A,00FE)': 'BlockName',
    '(300A,0100)': 'BlockThickness',
    '(300A,0102)': 'BlockTransmission',
    '(300A,0104)': 'BlockNumberOfPoints',
    '(300A,0106)': 'BlockData',
    '(300A,0107)': 'ApplicatorSequence',
    '(300A,0108)': 'ApplicatorID',
    '(300A,0109)': 'ApplicatorType',
    '(300A,010A)': 'ApplicatorDescription',
    '(300A,010C)': 'CumulativeDoseReferenceCoefficient',
    '(300A,010E)': 'FinalCumulativeMetersetWeight',
    '(300A,0110)': 'NumberOfControlPoints',
    '(300A,0111)': 'ControlPointSequence',
    '(300A,0112)': 'ControlPointIndex',
    '(300A,0114)': 'NominalBeamEnergy',
    '(300A,0115)': 'DoseRateSet',
    '(300A,0116)': 'WedgePositionSequence',
    '(300A,0118)': 'WedgePosition',
    '(300A,011A)': 'BeamLimitingDevicePositionSequence',
    '(300A,011C)': 'LeafJawPositions',
    '(300A,011E)': 'GantryAngle',
    '(300A,011F)': 'GantryRotationDirection',
    '(300A,0120)': 'BeamLimitingDeviceAngle',
    '(300A,0121)': 'BeamLimitingDeviceRotationDirection',
    '(300A,0122)': 'PatientSupportAngle',
    '(300A,0123)': 'PatientSupportRotationDirection',
    '(300A,0124)': 'TableTopEccentricAxisDistance',
    '(300A,0125)': 'TableTopEccentricAngle',
    '(300A,0126)': 'TableTopEccentricRotationDirection',
    '(300A,0128)': 'TableTopVerticalPosition',
    '(300A,0129)': 'TableTopLongitudinalPosition',
    '(300A,012A)': 'TableTopLateralPosition',
    '(300A,012C)': 'IsocenterPosition',
    '(300A,012E)': 'SurfaceEntryPoint',
    '(300A,0130)': 'SourceToSurfaceDistance',
    '(300A,0131)': 'AverageBeamDosePointSourceToExternalContourDistance',
    '(300A,0132)': 'SourceToExternalContourDistance',
    '(300A,0133)': 'ExternalContourEntryPoint',
    '(300A,0134)': 'CumulativeMetersetWeight',
    '(300A,0140)': 'TableTopPitchAngle',
    '(300A,0142)': 'TableTopPitchRotationDirection',
    '(300A,0144)': 'TableTopRollAngle',
    '(300A,0146)': 'TableTopRollRotationDirection',
    '(300A,0148)': 'HeadFixationAngle',
    '(300A,014A)': 'GantryPitchAngle',
    '(300A,014C)': 'GantryPitchRotationDirection',
    '(300A,014E)': 'GantryPitchAngleTolerance',
    '(300A,0150)': 'FixationEye',
    '(300A,0151)': 'ChairHeadFramePosition',
    '(300A,0152)': 'HeadFixationAngleTolerance',
    '(300A,0153)': 'ChairHeadFramePositionTolerance',
    '(300A,0154)': 'FixationLightAzimuthalAngleTolerance',
    '(300A,0155)': 'FixationLightPolarAngleTolerance',
    '(300A,0180)': 'PatientSetupSequence',
    '(300A,0182)': 'PatientSetupNumber',
    '(300A,0183)': 'PatientSetupLabel',
    '(300A,0184)': 'PatientAdditionalPosition',
    '(300A,0190)': 'FixationDeviceSequence',
    '(300A,0192)': 'FixationDeviceType',
    '(300A,0194)': 'FixationDeviceLabel',
    '(300A,0196)': 'FixationDeviceDescription',
    '(300A,0198)': 'FixationDevicePosition',
    '(300A,0199)': 'FixationDevicePitchAngle',
    '(300A,019A)': 'FixationDeviceRollAngle',
    '(300A,01A0)': 'ShieldingDeviceSequence',
    '(300A,01A2)': 'ShieldingDeviceType',
    '(300A,01A4)': 'ShieldingDeviceLabel',
    '(300A,01A6)': 'ShieldingDeviceDescription',
    '(300A,01A8)': 'ShieldingDevicePosition',
    '(300A,01B0)': 'SetupTechnique',
    '(300A,01B2)': 'SetupTechniqueDescription',
    '(300A,01B4)': 'SetupDeviceSequence',
    '(300A,01B6)': 'SetupDeviceType',
    '(300A,01B8)': 'SetupDeviceLabel',
    '(300A,01BA)': 'SetupDeviceDescription',
    '(300A,01BC)': 'SetupDeviceParameter',
    '(300A,01D0)': 'SetupReferenceDescription',
    '(300A,01D2)': 'TableTopVerticalSetupDisplacement',
    '(300A,01D4)': 'TableTopLongitudinalSetupDisplacement',
    '(300A,01D6)': 'TableTopLateralSetupDisplacement',
    '(300A,0200)': 'BrachyTreatmentTechnique',
    '(300A,0202)': 'BrachyTreatmentType',
    '(300A,0206)': 'TreatmentMachineSequence',
    '(300A,0210)': 'SourceSequence',
    '(300A,0212)': 'SourceNumber',
    '(300A,0214)': 'SourceType',
    '(300A,0216)': 'SourceManufacturer',
    '(300A,0218)': 'ActiveSourceDiameter',
    '(300A,021A)': 'ActiveSourceLength',
    '(300A,021B)': 'SourceModelID',
    '(300A,021C)': 'SourceDescription',
    '(300A,0222)': 'SourceEncapsulationNominalThickness',
    '(300A,0224)': 'SourceEncapsulationNominalTransmission',
    '(300A,0226)': 'SourceIsotopeName',
    '(300A,0228)': 'SourceIsotopeHalfLife',
    '(300A,0229)': 'SourceStrengthUnits',
    '(300A,022A)': 'ReferenceAirKermaRate',
    '(300A,022B)': 'SourceStrength',
    '(300A,022C)': 'SourceStrengthReferenceDate',
    '(300A,022E)': 'SourceStrengthReferenceTime',
    '(300A,0230)': 'ApplicationSetupSequence',
    '(300A,0232)': 'ApplicationSetupType',
    '(300A,0234)': 'ApplicationSetupNumber',
    '(300A,0236)': 'ApplicationSetupName',
    '(300A,0238)': 'ApplicationSetupManufacturer',
    '(300A,0240)': 'TemplateNumber',
    '(300A,0242)': 'TemplateType',
    '(300A,0244)': 'TemplateName',
    '(300A,0250)': 'TotalReferenceAirKerma',
    '(300A,0260)': 'BrachyAccessoryDeviceSequence',
    '(300A,0262)': 'BrachyAccessoryDeviceNumber',
    '(300A,0263)': 'BrachyAccessoryDeviceID',
    '(300A,0264)': 'BrachyAccessoryDeviceType',
    '(300A,0266)': 'BrachyAccessoryDeviceName',
    '(300A,026A)': 'BrachyAccessoryDeviceNominalThickness',
    '(300A,026C)': 'BrachyAccessoryDeviceNominalTransmission',
    '(300A,0271)': 'ChannelEffectiveLength',
    '(300A,0272)': 'ChannelInnerLength',
    '(300A,0273)': 'AfterloaderChannelID',
    '(300A,0274)': 'SourceApplicatorTipLength',
    '(300A,0280)': 'ChannelSequence',
    '(300A,0282)': 'ChannelNumber',
    '(300A,0284)': 'ChannelLength',
    '(300A,0286)': 'ChannelTotalTime',
    '(300A,0288)': 'SourceMovementType',
    '(300A,028A)': 'NumberOfPulses',
    '(300A,028C)': 'PulseRepetitionInterval',
    '(300A,0290)': 'SourceApplicatorNumber',
    '(300A,0291)': 'SourceApplicatorID',
    '(300A,0292)': 'SourceApplicatorType',
    '(300A,0294)': 'SourceApplicatorName',
    '(300A,0296)': 'SourceApplicatorLength',
    '(300A,0298)': 'SourceApplicatorManufacturer',
    '(300A,029C)': 'SourceApplicatorWallNominalThickness',
    '(300A,029E)': 'SourceApplicatorWallNominalTransmission',
    '(300A,02A0)': 'SourceApplicatorStepSize',
    '(300A,02A1)': 'ApplicatorShapeReferencedROINumber',
    '(300A,02A2)': 'TransferTubeNumber',
    '(300A,02A4)': 'TransferTubeLength',
    '(300A,02B0)': 'ChannelShieldSequence',
    '(300A,02B2)': 'ChannelShieldNumber',
    '(300A,02B3)': 'ChannelShieldID',
    '(300A,02B4)': 'ChannelShieldName',
    '(300A,02B8)': 'ChannelShieldNominalThickness',
    '(300A,02BA)': 'ChannelShieldNominalTransmission',
    '(300A,02C8)': 'FinalCumulativeTimeWeight',
    '(300A,02D0)': 'BrachyControlPointSequence',
    '(300A,02D2)': 'ControlPointRelativePosition',
    '(300A,02D4)': 'ControlPoint3DPosition',
    '(300A,02D6)': 'CumulativeTimeWeight',
    '(300A,02E0)': 'CompensatorDivergence',
    '(300A,02E1)': 'CompensatorMountingPosition',
    '(300A,02E2)': 'SourceToCompensatorDistance',
    '(300A,02E3)': 'TotalCompensatorTrayWaterEquivalentThickness',
    '(300A,02E4)': 'IsocenterToCompensatorTrayDistance',
    '(300A,02E5)': 'CompensatorColumnOffset',
    '(300A,02E6)': 'IsocenterToCompensatorDistances',
    '(300A,02E7)': 'CompensatorRelativeStoppingPowerRatio',
    '(300A,02E8)': 'CompensatorMillingToolDiameter',
    '(300A,02EA)': 'IonRangeCompensatorSequence',
    '(300A,02EB)': 'CompensatorDescription',
    '(300A,0302)': 'RadiationMassNumber',
    '(300A,0304)': 'RadiationAtomicNumber',
    '(300A,0306)': 'RadiationChargeState',
    '(300A,0308)': 'ScanMode',
    '(300A,0309)': 'ModulatedScanModeType',
    '(300A,030A)': 'VirtualSourceAxisDistances',
    '(300A,030C)': 'SnoutSequence',
    '(300A,030D)': 'SnoutPosition',
    '(300A,030F)': 'SnoutID',
    '(300A,0312)': 'NumberOfRangeShifters',
    '(300A,0314)': 'RangeShifterSequence',
    '(300A,0316)': 'RangeShifterNumber',
    '(300A,0318)': 'RangeShifterID',
    '(300A,0320)': 'RangeShifterType',
    '(300A,0322)': 'RangeShifterDescription',
    '(300A,0330)': 'NumberOfLateralSpreadingDevices',
    '(300A,0332)': 'LateralSpreadingDeviceSequence',
    '(300A,0334)': 'LateralSpreadingDeviceNumber',
    '(300A,0336)': 'LateralSpreadingDeviceID',
    '(300A,0338)': 'LateralSpreadingDeviceType',
    '(300A,033A)': 'LateralSpreadingDeviceDescription',
    '(300A,033C)': 'LateralSpreadingDeviceWaterEquivalentThickness',
    '(300A,0340)': 'NumberOfRangeModulators',
    '(300A,0342)': 'RangeModulatorSequence',
    '(300A,0344)': 'RangeModulatorNumber',
    '(300A,0346)': 'RangeModulatorID',
    '(300A,0348)': 'RangeModulatorType',
    '(300A,034A)': 'RangeModulatorDescription',
    '(300A,034C)': 'BeamCurrentModulationID',
    '(300A,0350)': 'PatientSupportType',
    '(300A,0352)': 'PatientSupportID',
    '(300A,0354)': 'PatientSupportAccessoryCode',
    '(300A,0355)': 'TrayAccessoryCode',
    '(300A,0356)': 'FixationLightAzimuthalAngle',
    '(300A,0358)': 'FixationLightPolarAngle',
    '(300A,035A)': 'MetersetRate',
    '(300A,0360)': 'RangeShifterSettingsSequence',
    '(300A,0362)': 'RangeShifterSetting',
    '(300A,0364)': 'IsocenterToRangeShifterDistance',
    '(300A,0366)': 'RangeShifterWaterEquivalentThickness',
    '(300A,0370)': 'LateralSpreadingDeviceSettingsSequence',
    '(300A,0372)': 'LateralSpreadingDeviceSetting',
    '(300A,0374)': 'IsocenterToLateralSpreadingDeviceDistance',
    '(300A,0380)': 'RangeModulatorSettingsSequence',
    '(300A,0382)': 'RangeModulatorGatingStartValue',
    '(300A,0384)': 'RangeModulatorGatingStopValue',
    '(300A,0386)': 'RangeModulatorGatingStartWaterEquivalentThickness',
    '(300A,0388)': 'RangeModulatorGatingStopWaterEquivalentThickness',
    '(300A,038A)': 'IsocenterToRangeModulatorDistance',
    '(300A,038F)': 'ScanSpotTimeOffset',
    '(300A,0390)': 'ScanSpotTuneID',
    '(300A,0391)': 'ScanSpotPrescribedIndices',
    '(300A,0392)': 'NumberOfScanSpotPositions',
    '(300A,0393)': 'ScanSpotReordered',
    '(300A,0394)': 'ScanSpotPositionMap',
    '(300A,0395)': 'ScanSpotReorderingAllowed',
    '(300A,0396)': 'ScanSpotMetersetWeights',
    '(300A,0398)': 'ScanningSpotSize',
    '(300A,0399)': 'ScanSpotSizesDelivered',
    '(300A,039A)': 'NumberOfPaintings',
    '(300A,03A0)': 'IonToleranceTableSequence',
    '(300A,03A2)': 'IonBeamSequence',
    '(300A,03A4)': 'IonBeamLimitingDeviceSequence',
    '(300A,03A6)': 'IonBlockSequence',
    '(300A,03A8)': 'IonControlPointSequence',
    '(300A,03AA)': 'IonWedgeSequence',
    '(300A,03AC)': 'IonWedgePositionSequence',
    '(300A,0401)': 'ReferencedSetupImageSequence',
    '(300A,0402)': 'SetupImageComment',
    '(300A,0410)': 'MotionSynchronizationSequence',
    '(300A,0412)': 'ControlPointOrientation',
    '(300A,0420)': 'GeneralAccessorySequence',
    '(300A,0421)': 'GeneralAccessoryID',
    '(300A,0422)': 'GeneralAccessoryDescription',
    '(300A,0423)': 'GeneralAccessoryType',
    '(300A,0424)': 'GeneralAccessoryNumber',
    '(300A,0425)': 'SourceToGeneralAccessoryDistance',
    '(300A,0426)': 'IsocenterToGeneralAccessoryDistance',
    '(300A,0431)': 'ApplicatorGeometrySequence',
    '(300A,0432)': 'ApplicatorApertureShape',
    '(300A,0433)': 'ApplicatorOpening',
    '(300A,0434)': 'ApplicatorOpeningX',
    '(300A,0435)': 'ApplicatorOpeningY',
    '(300A,0436)': 'SourceToApplicatorMountingPositionDistance',
    '(300A,0440)': 'NumberOfBlockSlabItems',
    '(300A,0441)': 'BlockSlabSequence',
    '(300A,0442)': 'BlockSlabThickness',
    '(300A,0443)': 'BlockSlabNumber',
    '(300A,0450)': 'DeviceMotionControlSequence',
    '(300A,0451)': 'DeviceMotionExecutionMode',
    '(300A,0452)': 'DeviceMotionObservationMode',
    '(300A,0453)': 'DeviceMotionParameterCodeSequence',
    '(300A,0501)': 'DistalDepthFraction',
    '(300A,0502)': 'DistalDepth',
    '(300A,0503)': 'NominalRangeModulationFractions',
    '(300A,0504)': 'NominalRangeModulatedRegionDepths',
    '(300A,0505)': 'DepthDoseParametersSequence',
    '(300A,0506)': 'DeliveredDepthDoseParametersSequence',
    '(300A,0507)': 'DeliveredDistalDepthFraction',
    '(300A,0508)': 'DeliveredDistalDepth',
    '(300A,0509)': 'DeliveredNominalRangeModulationFractions',
    '(300A,0510)': 'DeliveredNominalRangeModulatedRegionDepths',
    '(300A,0511)': 'DeliveredReferenceDoseDefinition',
    '(300A,0512)': 'ReferenceDoseDefinition',
    '(300A,0600)': 'RTControlPointIndex',
    '(300A,0601)': 'RadiationGenerationModeIndex',
    '(300A,0602)': 'ReferencedDefinedDeviceIndex',
    '(300A,0603)': 'RadiationDoseIdentificationIndex',
    '(300A,0604)': 'NumberOfRTControlPoints',
    '(300A,0605)': 'ReferencedRadiationGenerationModeIndex',
    '(300A,0606)': 'TreatmentPositionIndex',
    '(300A,0607)': 'ReferencedDeviceIndex',
    '(300A,0608)': 'TreatmentPositionGroupLabel',
    '(300A,0609)': 'TreatmentPositionGroupUID',
    '(300A,060A)': 'TreatmentPositionGroupSequence',
    '(300A,060B)': 'ReferencedTreatmentPositionIndex',
    '(300A,060C)': 'ReferencedRadiationDoseIdentificationIndex',
    '(300A,060D)': 'RTAccessoryHolderWaterEquivalentThickness',
    '(300A,060E)': 'ReferencedRTAccessoryHolderDeviceIndex',
    '(300A,060F)': 'RTAccessoryHolderSlotExistenceFlag',
    '(300A,0610)': 'RTAccessoryHolderSlotSequence',
    '(300A,0611)': 'RTAccessoryHolderSlotID',
    '(300A,0612)': 'RTAccessoryHolderSlotDistance',
    '(300A,0613)': 'RTAccessorySlotDistance',
    '(300A,0614)': 'RTAccessoryHolderDefinitionSequence',
    '(300A,0615)': 'RTAccessoryDeviceSlotID',
    '(300A,0616)': 'RTRadiationSequence',
    '(300A,0617)': 'RadiationDoseSequence',
    '(300A,0618)': 'RadiationDoseIdentificationSequence',
    '(300A,0619)': 'RadiationDoseIdentificationLabel',
    '(300A,061A)': 'ReferenceDoseType',
    '(300A,061B)': 'PrimaryDoseValueIndicator',
    '(300A,061C)': 'DoseValuesSequence',
    '(300A,061D)': 'DoseValuePurpose',
    '(300A,061E)': 'ReferenceDosePointCoordinates',
    '(300A,061F)': 'RadiationDoseValuesParametersSequence',
    '(300A,0620)': 'MetersetToDoseMappingSequence',
    '(300A,0621)': 'ExpectedInVivoMeasurementValuesSequence',
    '(300A,0622)': 'ExpectedInVivoMeasurementValueIndex',
    '(300A,0623)': 'RadiationDoseInVivoMeasurementLabel',
    '(300A,0624)': 'RadiationDoseCentralAxisDisplacement',
    '(300A,0625)': 'RadiationDoseValue',
    '(300A,0626)': 'RadiationDoseSourceToSkinDistance',
    '(300A,0627)': 'RadiationDoseMeasurementPointCoordinates',
    '(300A,0628)': 'RadiationDoseSourceToExternalContourDistance',
    '(300A,0629)': 'RTToleranceSetSequence',
    '(300A,062A)': 'RTToleranceSetLabel',
    '(300A,062B)': 'AttributeToleranceValuesSequence',
    '(300A,062C)': 'ToleranceValue',
    '(300A,062D)': 'PatientSupportPositionToleranceSequence',
    '(300A,062E)': 'TreatmentTimeLimit',
    '(300A,062F)': 'CArmPhotonElectronControlPointSequence',
    '(300A,0630)': 'ReferencedRTRadiationSequence',
    '(300A,0631)': 'ReferencedRTInstanceSequence',
    '(300A,0632)': 'ReferencedRTPatientSetupSequence',
    '(300A,0634)': 'SourceToPatientSurfaceDistance',
    '(300A,0635)': 'TreatmentMachineSpecialModeCodeSequence',
    '(300A,0636)': 'IntendedNumberOfFractions',
    '(300A,0637)': 'RTRadiationSetIntent',
    '(300A,0638)': 'RTRadiationPhysicalAndGeometricContentDetailFlag',
    '(300A,0639)': 'RTRecordFlag',
    '(300A,063A)': 'TreatmentDeviceIdentificationSequence',
    '(300A,063B)': 'ReferencedRTPhysicianIntentSequence',
    '(300A,063C)': 'CumulativeMeterset',
    '(300A,063D)': 'DeliveryRate',
    '(300A,063E)': 'DeliveryRateUnitSequence',
    '(300A,063F)': 'TreatmentPositionSequence',
    '(300A,0640)': 'RadiationSourceAxisDistance',
    '(300A,0641)': 'NumberOfRTBeamLimitingDevices',
    '(300A,0642)': 'RTBeamLimitingDeviceProximalDistance',
    '(300A,0643)': 'RTBeamLimitingDeviceDistalDistance',
    '(300A,0644)': 'ParallelRTBeamDelimiterDeviceOrientationLabelCodeSequence',
    '(300A,0645)': 'BeamModifierOrientationAngle',
    '(300A,0646)': 'FixedRTBeamDelimiterDeviceSequence',
    '(300A,0647)': 'ParallelRTBeamDelimiterDeviceSequence',
    '(300A,0648)': 'NumberOfParallelRTBeamDelimiters',
    '(300A,0649)': 'ParallelRTBeamDelimiterBoundaries',
    '(300A,064A)': 'ParallelRTBeamDelimiterPositions',
    '(300A,064B)': 'RTBeamLimitingDeviceOffset',
    '(300A,064C)': 'RTBeamDelimiterGeometrySequence',
    '(300A,064D)': 'RTBeamLimitingDeviceDefinitionSequence',
    '(300A,064E)': 'ParallelRTBeamDelimiterOpeningMode',
    '(300A,064F)': 'ParallelRTBeamDelimiterLeafMountingSide',
    '(300A,0650)': 'PatientSetupUID',
    '(300A,0651)': 'WedgeDefinitionSequence',
    '(300A,0652)': 'RadiationBeamWedgeAngle',
    '(300A,0653)': 'RadiationBeamWedgeThinEdgeDistance',
    '(300A,0654)': 'RadiationBeamEffectiveWedgeAngle',
    '(300A,0655)': 'NumberOfWedgePositions',
    '(300A,0656)': 'RTBeamLimitingDeviceOpeningSequence',
    '(300A,0657)': 'NumberOfRTBeamLimitingDeviceOpenings',
    '(300A,0658)': 'RadiationDosimeterUnitSequence',
    '(300A,0659)': 'RTDeviceDistanceReferenceLocationCodeSequence',
    '(300A,065A)': 'RadiationDeviceConfigurationAndCommissioningKeySequence',
    '(300A,065B)': 'PatientSupportPositionParameterSequence',
    '(300A,065C)': 'PatientSupportPositionSpecificationMethod',
    '(300A,065D)': 'PatientSupportPositionDeviceParameterSequence',
    '(300A,065E)': 'DeviceOrderIndex',
    '(300A,065F)': 'PatientSupportPositionParameterOrderIndex',
    '(300A,0660)': 'PatientSupportPositionDeviceToleranceSequence',
    '(300A,0661)': 'PatientSupportPositionToleranceOrderIndex',
    '(300A,0662)': 'CompensatorDefinitionSequence',
    '(300A,0663)': 'CompensatorMapOrientation',
    '(300A,0664)': 'CompensatorProximalThicknessMap',
    '(300A,0665)': 'CompensatorDistalThicknessMap',
    '(300A,0666)': 'CompensatorBasePlaneOffset',
    '(300A,0667)': 'CompensatorShapeFabricationCodeSequence',
    '(300A,0668)': 'CompensatorShapeSequence',
    '(300A,0669)': 'RadiationBeamCompensatorMillingToolDiameter',
    '(300A,066A)': 'BlockDefinitionSequence',
    '(300A,066B)': 'BlockEdgeData',
    '(300A,066C)': 'BlockOrientation',
    '(300A,066D)': 'RadiationBeamBlockThickness',
    '(300A,066E)': 'RadiationBeamBlockSlabThickness',
    '(300A,066F)': 'BlockEdgeDataSequence',
    '(300A,0670)': 'NumberOfRTAccessoryHolders',
    '(300A,0671)': 'GeneralAccessoryDefinitionSequence',
    '(300A,0672)': 'NumberOfGeneralAccessories',
    '(300A,0673)': 'BolusDefinitionSequence',
    '(300A,0674)': 'NumberOfBoluses',
    '(300A,0675)': 'EquipmentFrameOfReferenceUID',
    '(300A,0676)': 'EquipmentFrameOfReferenceDescription',
    '(300A,0677)': 'EquipmentReferencePointCoordinatesSequence',
    '(300A,0678)': 'EquipmentReferencePointCodeSequence',
    '(300A,0679)': 'RTBeamLimitingDeviceAngle',
    '(300A,067A)': 'SourceRollAngle',
    '(300A,067B)': 'RadiationGenerationModeSequence',
    '(300A,067C)': 'RadiationGenerationModeLabel',
    '(300A,067D)': 'RadiationGenerationModeDescription',
    '(300A,067E)': 'RadiationGenerationModeMachineCodeSequence',
    '(300A,067F)': 'RadiationTypeCodeSequence',
    '(300A,0680)': 'NominalEnergy',
    '(300A,0681)': 'MinimumNominalEnergy',
    '(300A,0682)': 'MaximumNominalEnergy',
    '(300A,0683)': 'RadiationFluenceModifierCodeSequence',
    '(300A,0684)': 'EnergyUnitCodeSequence',
    '(300A,0685)': 'NumberOfRadiationGenerationModes',
    '(300A,0686)': 'PatientSupportDevicesSequence',
    '(300A,0687)': 'NumberOfPatientSupportDevices',
    '(300A,0688)': 'RTBeamModifierDefinitionDistance',
    '(300A,0689)': 'BeamAreaLimitSequence',
    '(300A,068A)': 'ReferencedRTPrescriptionSequence',
    '(300A,0700)': 'TreatmentSessionUID',
    '(300A,0701)': 'RTRadiationUsage',
    '(300A,0702)': 'ReferencedRTRadiationSetSequence',
    '(300A,0703)': 'ReferencedRTRadiationRecordSequence',
    '(300A,0704)': 'RTRadiationSetDeliveryNumber',
    '(300A,0705)': 'ClinicalFractionNumber',
    '(300A,0706)': 'RTTreatmentFractionCompletionStatus',
    '(300A,0707)': 'RTRadiationSetUsage',
    '(300A,0708)': 'TreatmentDeliveryContinuationFlag',
    '(300A,0709)': 'TreatmentRecordContentOrigin',
    '(300A,0714)': 'RTTreatmentTerminationStatus',
    '(300A,0715)': 'RTTreatmentTerminationReasonCodeSequence',
    '(300A,0716)': 'MachineSpecificTreatmentTerminationCodeSequence',
    '(300A,0722)': 'RTRadiationSalvageRecordControlPointSequence',
    '(300A,0723)': 'StartingMetersetValueKnownFlag',
    '(300A,0730)': 'TreatmentTerminationDescription',
    '(300A,0731)': 'TreatmentToleranceViolationSequence',
    '(300A,0732)': 'TreatmentToleranceViolationCategory',
    '(300A,0733)': 'TreatmentToleranceViolationAttributeSequence',
    '(300A,0734)': 'TreatmentToleranceViolationDescription',
    '(300A,0735)': 'TreatmentToleranceViolationIdentification',
    '(300A,0736)': 'TreatmentToleranceViolationDateTime',
    '(300A,073A)': 'RecordedRTControlPointDateTime',
    '(300A,073B)': 'ReferencedRadiationRTControlPointIndex',
    '(300A,073E)': 'AlternateValueSequence',
    '(300A,073F)': 'ConfirmationSequence',
    '(300A,0740)': 'InterlockSequence',
    '(300A,0741)': 'InterlockDateTime',
    '(300A,0742)': 'InterlockDescription',
    '(300A,0743)': 'InterlockOriginatingDeviceSequence',
    '(300A,0744)': 'InterlockCodeSequence',
    '(300A,0745)': 'InterlockResolutionCodeSequence',
    '(300A,0746)': 'InterlockResolutionUserSequence',
    '(300A,0760)': 'OverrideDateTime',
    '(300A,0761)': 'TreatmentToleranceViolationTypeCodeSequence',
    '(300A,0762)': 'TreatmentToleranceViolationCauseCodeSequence',
    '(300A,0772)': 'MeasuredMetersetToDoseMappingSequence',
    '(300A,0773)': 'ReferencedExpectedInVivoMeasurementValueIndex',
    '(300A,0774)': 'DoseMeasurementDeviceCodeSequence',
    '(300A,0780)': 'AdditionalParameterRecordingInstanceSequence',
    '(300A,0783)': 'InterlockOriginDescription',
    '(300A,0784)': 'RTPatientPositionScopeSequence',
    '(300A,0785)': 'ReferencedTreatmentPositionGroupUID',
    '(300A,0786)': 'RadiationOrderIndex',
    '(300A,0787)': 'OmittedRadiationSequence',
    '(300A,0788)': 'ReasonForOmissionCodeSequence',
    '(300A,0789)': 'RTDeliveryStartPatientPositionSequence',
    '(300A,078A)': 'RTTreatmentPreparationPatientPositionSequence',
    '(300A,078B)': 'ReferencedRTTreatmentPreparationSequence',
    '(300A,078C)': 'ReferencedPatientSetupPhotoSequence',
    '(300A,078D)': 'PatientTreatmentPreparationMethodCodeSequence',
    '(300A,078E)': 'PatientTreatmentPreparationProcedureParameterDescription',
    '(300A,078F)': 'PatientTreatmentPreparationDeviceSequence',
    '(300A,0790)': 'PatientTreatmentPreparationProcedureSequence',
    '(300A,0791)': 'PatientTreatmentPreparationProcedureCodeSequence',
    '(300A,0792)': 'PatientTreatmentPreparationMethodDescription',
    '(300A,0793)': 'PatientTreatmentPreparationProcedureParameterSequence',
    '(300A,0794)': 'PatientSetupPhotoDescription',
    '(300A,0795)': 'PatientTreatmentPreparationProcedureIndex',
    '(300A,0796)': 'ReferencedPatientSetupProcedureIndex',
    '(300A,0797)': 'RTRadiationTaskSequence',
    '(300A,0798)': 'RTPatientPositionDisplacementSequence',
    '(300A,0799)': 'RTPatientPositionSequence',
    '(300A,079A)': 'DisplacementReferenceLabel',
    '(300A,079B)': 'DisplacementMatrix',
    '(300A,079C)': 'PatientSupportDisplacementSequence',
    '(300A,079D)': 'DisplacementReferenceLocationCodeSequence',
    '(300A,079E)': 'RTRadiationSetDeliveryUsage',
    '(300C,0002)': 'ReferencedRTPlanSequence',
    '(300C,0004)': 'ReferencedBeamSequence',
    '(300C,0006)': 'ReferencedBeamNumber',
    '(300C,0007)': 'ReferencedReferenceImageNumber',
    '(300C,0008)': 'StartCumulativeMetersetWeight',
    '(300C,0009)': 'EndCumulativeMetersetWeight',
    '(300C,000A)': 'ReferencedBrachyApplicationSetupSequence',
    '(300C,000C)': 'ReferencedBrachyApplicationSetupNumber',
    '(300C,000E)': 'ReferencedSourceNumber',
    '(300C,0020)': 'ReferencedFractionGroupSequence',
    '(300C,0022)': 'ReferencedFractionGroupNumber',
    '(300C,0040)': 'ReferencedVerificationImageSequence',
    '(300C,0042)': 'ReferencedReferenceImageSequence',
    '(300C,0050)': 'ReferencedDoseReferenceSequence',
    '(300C,0051)': 'ReferencedDoseReferenceNumber',
    '(300C,0055)': 'BrachyReferencedDoseReferenceSequence',
    '(300C,0060)': 'ReferencedStructureSetSequence',
    '(300C,006A)': 'ReferencedPatientSetupNumber',
    '(300C,0080)': 'ReferencedDoseSequence',
    '(300C,00A0)': 'ReferencedToleranceTableNumber',
    '(300C,00B0)': 'ReferencedBolusSequence',
    '(300C,00C0)': 'ReferencedWedgeNumber',
    '(300C,00D0)': 'ReferencedCompensatorNumber',
    '(300C,00E0)': 'ReferencedBlockNumber',
    '(300C,00F0)': 'ReferencedControlPointIndex',
    '(300C,00F2)': 'ReferencedControlPointSequence',
    '(300C,00F4)': 'ReferencedStartControlPointIndex',
    '(300C,00F6)': 'ReferencedStopControlPointIndex',
    '(300C,0100)': 'ReferencedRangeShifterNumber',
    '(300C,0102)': 'ReferencedLateralSpreadingDeviceNumber',
    '(300C,0104)': 'ReferencedRangeModulatorNumber',
    '(300C,0111)': 'OmittedBeamTaskSequence',
    '(300C,0112)': 'ReasonForOmission',
    '(300C,0113)': 'ReasonForOmissionDescription',
    '(300C,0114)': 'PrescriptionOverviewSequence',
    '(300C,0115)': 'TotalPrescriptionDose',
    '(300C,0116)': 'PlanOverviewSequence',
    '(300C,0117)': 'PlanOverviewIndex',
    '(300C,0118)': 'ReferencedPlanOverviewIndex',
    '(300C,0119)': 'NumberOfFractionsIncluded',
    '(300C,0120)': 'DoseCalibrationConditionsSequence',
    '(300C,0121)': 'AbsorbedDoseToMetersetRatio',
    '(300C,0122)': 'DelineatedRadiationFieldSize',
    '(300C,0123)': 'DoseCalibrationConditionsVerifiedFlag',
    '(300C,0124)': 'CalibrationReferencePointDepth',
    '(300C,0125)': 'GatingBeamHoldTransitionSequence',
    '(300C,0126)': 'BeamHoldTransition',
    '(300C,0127)': 'BeamHoldTransitionDateTime',
    '(300C,0128)': 'BeamHoldOriginatingDeviceSequence',
    '(300E,0002)': 'ApprovalStatus',
    '(300E,0004)': 'ReviewDate',
    '(300E,0005)': 'ReviewTime',
    '(300E,0008)': 'ReviewerName',
    '(3010,0001)': 'RadiobiologicalDoseEffectSequence',
    '(3010,0002)': 'RadiobiologicalDoseEffectFlag',
    '(3010,0003)': 'EffectiveDoseCalculationMethodCategoryCodeSequence',
    '(3010,0004)': 'EffectiveDoseCalculationMethodCodeSequence',
    '(3010,0005)': 'EffectiveDoseCalculationMethodDescription',
    '(3010,0006)': 'ConceptualVolumeUID',
    '(3010,0007)': 'OriginatingSOPInstanceReferenceSequence',
    '(3010,0008)': 'ConceptualVolumeConstituentSequence',
    '(3010,0009)': 'EquivalentConceptualVolumeInstanceReferenceSequence',
    '(3010,000A)': 'EquivalentConceptualVolumesSequence',
    '(3010,000B)': 'ReferencedConceptualVolumeUID',
    '(3010,000C)': 'ConceptualVolumeCombinationExpression',
    '(3010,000D)': 'ConceptualVolumeConstituentIndex',
    '(3010,000E)': 'ConceptualVolumeCombinationFlag',
    '(3010,000F)': 'ConceptualVolumeCombinationDescription',
    '(3010,0010)': 'ConceptualVolumeSegmentationDefinedFlag',
    '(3010,0011)': 'ConceptualVolumeSegmentationReferenceSequence',
    '(3010,0012)': 'ConceptualVolumeConstituentSegmentationReferenceSequence',
    '(3010,0013)': 'ConstituentConceptualVolumeUID',
    '(3010,0014)': 'DerivationConceptualVolumeSequence',
    '(3010,0015)': 'SourceConceptualVolumeUID',
    '(3010,0016)': 'ConceptualVolumeDerivationAlgorithmSequence',
    '(3010,0017)': 'ConceptualVolumeDescription',
    '(3010,0018)': 'SourceConceptualVolumeSequence',
    '(3010,0019)': 'AuthorIdentificationSequence',
    '(3010,001A)': 'ManufacturerModelVersion',
    '(3010,001B)': 'DeviceAlternateIdentifier',
    '(3010,001C)': 'DeviceAlternateIdentifierType',
    '(3010,001D)': 'DeviceAlternateIdentifierFormat',
    '(3010,001E)': 'SegmentationCreationTemplateLabel',
    '(3010,001F)': 'SegmentationTemplateUID',
    '(3010,0020)': 'ReferencedSegmentReferenceIndex',
    '(3010,0021)': 'SegmentReferenceSequence',
    '(3010,0022)': 'SegmentReferenceIndex',
    '(3010,0023)': 'DirectSegmentReferenceSequence',
    '(3010,0024)': 'CombinationSegmentReferenceSequence',
    '(3010,0025)': 'ConceptualVolumeSequence',
    '(3010,0026)': 'SegmentedRTAccessoryDeviceSequence',
    '(3010,0027)': 'SegmentCharacteristicsSequence',
    '(3010,0028)': 'RelatedSegmentCharacteristicsSequence',
    '(3010,0029)': 'SegmentCharacteristicsPrecedence',
    '(3010,002A)': 'RTSegmentAnnotationSequence',
    '(3010,002B)': 'SegmentAnnotationCategoryCodeSequence',
    '(3010,002C)': 'SegmentAnnotationTypeCodeSequence',
    '(3010,002D)': 'DeviceLabel',
    '(3010,002E)': 'DeviceTypeCodeSequence',
    '(3010,002F)': 'SegmentAnnotationTypeModifierCodeSequence',
    '(3010,0030)': 'PatientEquipmentRelationshipCodeSequence',
    '(3010,0031)': 'ReferencedFiducialsUID',
    '(3010,0032)': 'PatientTreatmentOrientationSequence',
    '(3010,0033)': 'UserContentLabel',
    '(3010,0034)': 'UserContentLongLabel',
    '(3010,0035)': 'EntityLabel',
    '(3010,0036)': 'EntityName',
    '(3010,0037)': 'EntityDescription',
    '(3010,0038)': 'EntityLongLabel',
    '(3010,0039)': 'DeviceIndex',
    '(3010,003A)': 'RTTreatmentPhaseIndex',
    '(3010,003B)': 'RTTreatmentPhaseUID',
    '(3010,003C)': 'RTPrescriptionIndex',
    '(3010,003D)': 'RTSegmentAnnotationIndex',
    '(3010,003E)': 'BasisRTTreatmentPhaseIndex',
    '(3010,003F)': 'RelatedRTTreatmentPhaseIndex',
    '(3010,0040)': 'ReferencedRTTreatmentPhaseIndex',
    '(3010,0041)': 'ReferencedRTPrescriptionIndex',
    '(3010,0042)': 'ReferencedParentRTPrescriptionIndex',
    '(3010,0043)': 'ManufacturerDeviceIdentifier',
    '(3010,0044)': 'InstanceLevelReferencedPerformedProcedureStepSequence',
    '(3010,0045)': 'RTTreatmentPhaseIntentPresenceFlag',
    '(3010,0046)': 'RadiotherapyTreatmentType',
    '(3010,0047)': 'TeletherapyRadiationType',
    '(3010,0048)': 'BrachytherapySourceType',
    '(3010,0049)': 'ReferencedRTTreatmentPhaseSequence',
    '(3010,004A)': 'ReferencedDirectSegmentInstanceSequence',
    '(3010,004B)': 'IntendedRTTreatmentPhaseSequence',
    '(3010,004C)': 'IntendedPhaseStartDate',
    '(3010,004D)': 'IntendedPhaseEndDate',
    '(3010,004E)': 'RTTreatmentPhaseIntervalSequence',
    '(3010,004F)': 'TemporalRelationshipIntervalAnchor',
    '(3010,0050)': 'MinimumNumberOfIntervalDays',
    '(3010,0051)': 'MaximumNumberOfIntervalDays',
    '(3010,0052)': 'PertinentSOPClassesInStudy',
    '(3010,0053)': 'PertinentSOPClassesInSeries',
    '(3010,0054)': 'RTPrescriptionLabel',
    '(3010,0055)': 'RTPhysicianIntentPredecessorSequence',
    '(3010,0056)': 'RTTreatmentApproachLabel',
    '(3010,0057)': 'RTPhysicianIntentSequence',
    '(3010,0058)': 'RTPhysicianIntentIndex',
    '(3010,0059)': 'RTTreatmentIntentType',
    '(3010,005A)': 'RTPhysicianIntentNarrative',
    '(3010,005B)': 'RTProtocolCodeSequence',
    '(3010,005C)': 'ReasonForSuperseding',
    '(3010,005D)': 'RTDiagnosisCodeSequence',
    '(3010,005E)': 'ReferencedRTPhysicianIntentIndex',
    '(3010,005F)': 'RTPhysicianIntentInputInstanceSequence',
    '(3010,0060)': 'RTAnatomicPrescriptionSequence',
    '(3010,0061)': 'PriorTreatmentDoseDescription',
    '(3010,0062)': 'PriorTreatmentReferenceSequence',
    '(3010,0063)': 'DosimetricObjectiveEvaluationScope',
    '(3010,0064)': 'TherapeuticRoleCategoryCodeSequence',
    '(3010,0065)': 'TherapeuticRoleTypeCodeSequence',
    '(3010,0066)': 'ConceptualVolumeOptimizationPrecedence',
    '(3010,0067)': 'ConceptualVolumeCategoryCodeSequence',
    '(3010,0068)': 'ConceptualVolumeBlockingConstraint',
    '(3010,0069)': 'ConceptualVolumeTypeCodeSequence',
    '(3010,006A)': 'ConceptualVolumeTypeModifierCodeSequence',
    '(3010,006B)': 'RTPrescriptionSequence',
    '(3010,006C)': 'DosimetricObjectiveSequence',
    '(3010,006D)': 'DosimetricObjectiveTypeCodeSequence',
    '(3010,006E)': 'DosimetricObjectiveUID',
    '(3010,006F)': 'ReferencedDosimetricObjectiveUID',
    '(3010,0070)': 'DosimetricObjectiveParameterSequence',
    '(3010,0071)': 'ReferencedDosimetricObjectivesSequence',
    '(3010,0073)': 'AbsoluteDosimetricObjectiveFlag',
    '(3010,0074)': 'DosimetricObjectiveWeight',
    '(3010,0075)': 'DosimetricObjectivePurpose',
    '(3010,0076)': 'PlanningInputInformationSequence',
    '(3010,0077)': 'TreatmentSite',
    '(3010,0078)': 'TreatmentSiteCodeSequence',
    '(3010,0079)': 'FractionPatternSequence',
    '(3010,007A)': 'TreatmentTechniqueNotes',
    '(3010,007B)': 'PrescriptionNotes',
    '(3010,007C)': 'NumberOfIntervalFractions',
    '(3010,007D)': 'NumberOfFractions',
    '(3010,007E)': 'IntendedDeliveryDuration',
    '(3010,007F)': 'FractionationNotes',
    '(3010,0080)': 'RTTreatmentTechniqueCodeSequence',
    '(3010,0081)': 'PrescriptionNotesSequence',
    '(3010,0082)': 'FractionBasedRelationshipSequence',
    '(3010,0083)': 'FractionBasedRelationshipIntervalAnchor',
    '(3010,0084)': 'MinimumHoursBetweenFractions',
    '(3010,0085)': 'IntendedFractionStartTime',
    '(3010,0086)': 'IntendedStartDayOfWeek',
    '(3010,0087)': 'WeekdayFractionPatternSequence',
    '(3010,0088)': 'DeliveryTimeStructureCodeSequence',
    '(3010,0089)': 'TreatmentSiteModifierCodeSequence',
    '(3010,0090)': 'RoboticBaseLocationIndicator',
    '(3010,0091)': 'RoboticPathNodeSetCodeSequence',
    '(3010,0092)': 'RoboticNodeIdentifier',
    '(3010,0093)': 'RTTreatmentSourceCoordinates',
    '(3010,0094)': 'RadiationSourceCoordinateSystemYawAngle',
    '(3010,0095)': 'RadiationSourceCoordinateSystemRollAngle',
    '(3010,0096)': 'RadiationSourceCoordinateSystemPitchAngle',
    '(3010,0097)': 'RoboticPathControlPointSequence',
    '(3010,0098)': 'TomotherapeuticControlPointSequence',
    '(3010,0099)': 'TomotherapeuticLeafOpenDurations',
    '(3010,009A)': 'TomotherapeuticLeafInitialClosedDurations',
    '(4000,0010)': 'Arbitrary',
    '(4000,4000)': 'TextComments',
    '(4008,0040)': 'ResultsID',
    '(4008,0042)': 'ResultsIDIssuer',
    '(4008,0050)': 'ReferencedInterpretationSequence',
    '(4008,00FF)': 'ReportProductionStatusTrial',
    '(4008,0100)': 'InterpretationRecordedDate',
    '(4008,0101)': 'InterpretationRecordedTime',
    '(4008,0102)': 'InterpretationRecorder',
    '(4008,0103)': 'ReferenceToRecordedSound',
    '(4008,0108)': 'InterpretationTranscriptionDate',
    '(4008,0109)': 'InterpretationTranscriptionTime',
    '(4008,010A)': 'InterpretationTranscriber',
    '(4008,010B)': 'InterpretationText',
    '(4008,010C)': 'InterpretationAuthor',
    '(4008,0111)': 'InterpretationApproverSequence',
    '(4008,0112)': 'InterpretationApprovalDate',
    '(4008,0113)': 'InterpretationApprovalTime',
    '(4008,0114)': 'PhysicianApprovingInterpretation',
    '(4008,0115)': 'InterpretationDiagnosisDescription',
    '(4008,0117)': 'InterpretationDiagnosisCodeSequence',
    '(4008,0118)': 'ResultsDistributionListSequence',
    '(4008,0119)': 'DistributionName',
    '(4008,011A)': 'DistributionAddress',
    '(4008,0200)': 'InterpretationID',
    '(4008,0202)': 'InterpretationIDIssuer',
    '(4008,0210)': 'InterpretationTypeID',
    '(4008,0212)': 'InterpretationStatusID',
    '(4008,0300)': 'Impressions',
    '(4008,4000)': 'ResultsComments',
    '(4010,0001)': 'LowEnergyDetectors',
    '(4010,0002)': 'HighEnergyDetectors',
    '(4010,0004)': 'DetectorGeometrySequence',
    '(4010,1001)': 'ThreatROIVoxelSequence',
    '(4010,1004)': 'ThreatROIBase',
    '(4010,1005)': 'ThreatROIExtents',
    '(4010,1006)': 'ThreatROIBitmap',
    '(4010,1007)': 'RouteSegmentID',
    '(4010,1008)': 'GantryType',
    '(4010,1009)': 'OOIOwnerType',
    '(4010,100A)': 'RouteSegmentSequence',
    '(4010,1010)': 'PotentialThreatObjectID',
    '(4010,1011)': 'ThreatSequence',
    '(4010,1012)': 'ThreatCategory',
    '(4010,1013)': 'ThreatCategoryDescription',
    '(4010,1014)': 'ATDAbilityAssessment',
    '(4010,1015)': 'ATDAssessmentFlag',
    '(4010,1016)': 'ATDAssessmentProbability',
    '(4010,1017)': 'Mass',
    '(4010,1018)': 'Density',
    '(4010,1019)': 'ZEffective',
    '(4010,101A)': 'BoardingPassID',
    '(4010,101B)': 'CenterOfMass',
    '(4010,101C)': 'CenterOfPTO',
    '(4010,101D)': 'BoundingPolygon',
    '(4010,101E)': 'RouteSegmentStartLocationID',
    '(4010,101F)': 'RouteSegmentEndLocationID',
    '(4010,1020)': 'RouteSegmentLocationIDType',
    '(4010,1021)': 'AbortReason',
    '(4010,1023)': 'VolumeOfPTO',
    '(4010,1024)': 'AbortFlag',
    '(4010,1025)': 'RouteSegmentStartTime',
    '(4010,1026)': 'RouteSegmentEndTime',
    '(4010,1027)': 'TDRType',
    '(4010,1028)': 'InternationalRouteSegment',
    '(4010,1029)': 'ThreatDetectionAlgorithmAndVersion',
    '(4010,102A)': 'AssignedLocation',
    '(4010,102B)': 'AlarmDecisionTime',
    '(4010,1031)': 'AlarmDecision',
    '(4010,1033)': 'NumberOfTotalObjects',
    '(4010,1034)': 'NumberOfAlarmObjects',
    '(4010,1037)': 'PTORepresentationSequence',
    '(4010,1038)': 'ATDAssessmentSequence',
    '(4010,1039)': 'TIPType',
    '(4010,103A)': 'DICOSVersion',
    '(4010,1041)': 'OOIOwnerCreationTime',
    '(4010,1042)': 'OOIType',
    '(4010,1043)': 'OOISize',
    '(4010,1044)': 'AcquisitionStatus',
    '(4010,1045)': 'BasisMaterialsCodeSequence',
    '(4010,1046)': 'PhantomType',
    '(4010,1047)': 'OOIOwnerSequence',
    '(4010,1048)': 'ScanType',
    '(4010,1051)': 'ItineraryID',
    '(4010,1052)': 'ItineraryIDType',
    '(4010,1053)': 'ItineraryIDAssigningAuthority',
    '(4010,1054)': 'RouteID',
    '(4010,1055)': 'RouteIDAssigningAuthority',
    '(4010,1056)': 'InboundArrivalType',
    '(4010,1058)': 'CarrierID',
    '(4010,1059)': 'CarrierIDAssigningAuthority',
    '(4010,1060)': 'SourceOrientation',
    '(4010,1061)': 'SourcePosition',
    '(4010,1062)': 'BeltHeight',
    '(4010,1064)': 'AlgorithmRoutingCodeSequence',
    '(4010,1067)': 'TransportClassification',
    '(4010,1068)': 'OOITypeDescriptor',
    '(4010,1069)': 'TotalProcessingTime',
    '(4010,106C)': 'DetectorCalibrationData',
    '(4010,106D)': 'AdditionalScreeningPerformed',
    '(4010,106E)': 'AdditionalInspectionSelectionCriteria',
    '(4010,106F)': 'AdditionalInspectionMethodSequence',
    '(4010,1070)': 'AITDeviceType',
    '(4010,1071)': 'QRMeasurementsSequence',
    '(4010,1072)': 'TargetMaterialSequence',
    '(4010,1073)': 'SNRThreshold',
    '(4010,1075)': 'ImageScaleRepresentation',
    '(4010,1076)': 'ReferencedPTOSequence',
    '(4010,1077)': 'ReferencedTDRInstanceSequence',
    '(4010,1078)': 'PTOLocationDescription',
    '(4010,1079)': 'AnomalyLocatorIndicatorSequence',
    '(4010,107A)': 'AnomalyLocatorIndicator',
    '(4010,107B)': 'PTORegionSequence',
    '(4010,107C)': 'InspectionSelectionCriteria',
    '(4010,107D)': 'SecondaryInspectionMethodSequence',
    '(4010,107E)': 'PRCSToRCSOrientation',
    '(4FFE,0001)': 'MACParametersSequence',
    '(50xx,0005)': 'CurveDimensions',
    '(50xx,0010)': 'NumberOfPoints',
    '(50xx,0020)': 'TypeOfData',
    '(50xx,0022)': 'CurveDescription',
    '(50xx,0030)': 'AxisUnits',
    '(50xx,0040)': 'AxisLabels',
    '(50xx,0103)': 'DataValueRepresentation',
    '(50xx,0104)': 'MinimumCoordinateValue',
    '(50xx,0105)': 'MaximumCoordinateValue',
    '(50xx,0106)': 'CurveRange',
    '(50xx,0110)': 'CurveDataDescriptor',
    '(50xx,0112)': 'CoordinateStartValue',
    '(50xx,0114)': 'CoordinateStepValue',
    '(50xx,1001)': 'CurveActivationLayer',
    '(50xx,2000)': 'AudioType',
    '(50xx,2002)': 'AudioSampleFormat',
    '(50xx,2004)': 'NumberOfChannels',
    '(50xx,2006)': 'NumberOfSamples',
    '(50xx,2008)': 'SampleRate',
    '(50xx,200A)': 'TotalTime',
    '(50xx,200C)': 'AudioSampleData',
    '(50xx,200E)': 'AudioComments',
    '(50xx,2500)': 'CurveLabel',
    '(50xx,2600)': 'CurveReferencedOverlaySequence',
    '(50xx,2610)': 'CurveReferencedOverlayGroup',
    '(50xx,3000)': 'CurveData',
    '(5200,9229)': 'SharedFunctionalGroupsSequence',
    '(5200,9230)': 'PerFrameFunctionalGroupsSequence',
    '(5400,0100)': 'WaveformSequence',
    '(5400,0110)': 'ChannelMinimumValue',
    '(5400,0112)': 'ChannelMaximumValue',
    '(5400,1004)': 'WaveformBitsAllocated',
    '(5400,1006)': 'WaveformSampleInterpretation',
    '(5400,100A)': 'WaveformPaddingValue',
    '(5400,1010)': 'WaveformData',
    '(5600,0010)': 'FirstOrderPhaseCorrectionAngle',
    '(5600,0020)': 'SpectroscopyData',
    '(60xx,0010)': 'OverlayRows',
    '(60xx,0011)': 'OverlayColumns',
    '(60xx,0012)': 'OverlayPlanes',
    '(60xx,0015)': 'NumberOfFramesInOverlay',
    '(60xx,0022)': 'OverlayDescription',
    '(60xx,0040)': 'OverlayType',
    '(60xx,0045)': 'OverlaySubtype',
    '(60xx,0050)': 'OverlayOrigin',
    '(60xx,0051)': 'ImageFrameOrigin',
    '(60xx,0052)': 'OverlayPlaneOrigin',
    '(60xx,0060)': 'OverlayCompressionCode',
    '(60xx,0061)': 'OverlayCompressionOriginator',
    '(60xx,0062)': 'OverlayCompressionLabel',
    '(60xx,0063)': 'OverlayCompressionDescription',
    '(60xx,0066)': 'OverlayCompressionStepPointers',
    '(60xx,0068)': 'OverlayRepeatInterval',
    '(60xx,0069)': 'OverlayBitsGrouped',
    '(60xx,0100)': 'OverlayBitsAllocated',
    '(60xx,0102)': 'OverlayBitPosition',
    '(60xx,0110)': 'OverlayFormat',
    '(60xx,0200)': 'OverlayLocation',
    '(60xx,0800)': 'OverlayCodeLabel',
    '(60xx,0802)': 'OverlayNumberOfTables',
    '(60xx,0803)': 'OverlayCodeTableLocation',
    '(60xx,0804)': 'OverlayBitsForCodeWord',
    '(60xx,1001)': 'OverlayActivationLayer',
    '(60xx,1100)': 'OverlayDescriptorGray',
    '(60xx,1101)': 'OverlayDescriptorRed',
    '(60xx,1102)': 'OverlayDescriptorGreen',
    '(60xx,1103)': 'OverlayDescriptorBlue',
    '(60xx,1200)': 'OverlaysGray',
    '(60xx,1201)': 'OverlaysRed',
    '(60xx,1202)': 'OverlaysGreen',
    '(60xx,1203)': 'OverlaysBlue',
    '(60xx,1301)': 'ROIArea',
    '(60xx,1302)': 'ROIMean',
    '(60xx,1303)': 'ROIStandardDeviation',
    '(60xx,1500)': 'OverlayLabel',
    '(60xx,3000)': 'OverlayData',
    '(60xx,4000)': 'OverlayComments',
    '(7FE0,0001)': 'ExtendedOffsetTable',
    '(7FE0,0002)': 'ExtendedOffsetTableLengths',
    '(7FE0,0008)': 'FloatPixelData',
    '(7FE0,0009)': 'DoubleFloatPixelData',
    '(7FE0,0010)': 'PixelData',
    '(7FE0,0020)': 'CoefficientsSDVN',
    '(7FE0,0030)': 'CoefficientsSDHN',
    '(7FE0,0040)': 'CoefficientsSDDN',
    '(7Fxx,0010)': 'VariablePixelData',
    '(7Fxx,0011)': 'VariableNextDataGroup',
    '(7Fxx,0020)': 'VariableCoefficientsSDVN',
    '(7Fxx,0030)': 'VariableCoefficientsSDHN',
    '(7Fxx,0040)': 'VariableCoefficientsSDDN',
    '(FFFA,FFFA)': 'DigitalSignaturesSequence',
    '(FFFC,FFFC)': 'DataSetTrailingPadding',
    '(FFFE,E000)': 'Item',
    '(FFFE,E00D)': 'ItemDelimitationItem',
    '(FFFE,E0DD)': 'SequenceDelimitationItem',
} as const;
