import {
    ConfidenceDistributionData, ConfidenceDistributionGTlessData, ExplorePredictionsData, IBasicModelStatsData, IClassPerformanceData, IConfusionMatrixData, IDatasetClassDistributionData, ILiftChartData, IMostConfusedClassesData, IMostConfusedImagesGraphParams, IPerformanceByConfidenceData, IPerformanceByConfidenceGraphParams, IPerformanceByIoUThresholdData, IPerformanceByIoUThresholdGraphParams, IPopulationDistributionData,
    IPrecisionRecallCurveData, IROCCurveData, MostConfusedImagesData, ObjectCountsData, PlotTopLossesData, PlotTopMissesData, PredictionDistributionData, PredictionGTDistributionData, SoftmaxHistogramData, THighlightedMetrics, TrainingVsValidationPerformanceData, IIntersectionDistributionData, IDetailedMetricsData, IViolinChartData
} from "..";

export interface IChartState {
    highlightedMetrics: IMetricsBaseData<THighlightedMetrics>;
    confusionMatrix: IMetricsBaseData<IConfusionMatrixData>;
    basicModelStats: IMetricsBaseData<IBasicModelStatsData>;
    plotTopLosses: IMetricsBaseData<PlotTopLossesData>;
    performanceByConfidence: IMetricsBaseData<IPerformanceByConfidenceData, IPerformanceByConfidenceGraphParams>;
    trainingVsValidationPerformance: IMetricsBaseData<TrainingVsValidationPerformanceData>;
    datasetClassDistribution: IMetricsBaseData<IDatasetClassDistributionData>;
    populationDistribution: IMetricsBaseData<IPopulationDistributionData>;
    mostConfusedClasses: IMetricsBaseData<Array<IMostConfusedClassesData>>;
    mostConfusedImages: IMetricsBaseData<MostConfusedImagesData, IMostConfusedImagesGraphParams>;
    classPerformance: IMetricsBaseData<IClassPerformanceData>;
    confidenceDistribution: IMetricsBaseData<ConfidenceDistributionData>;
    rocCurve: IMetricsBaseData<IROCCurveData>;
    predictionDistribution: IMetricsBaseData<PredictionDistributionData>;
    explorePredictions: IMetricsBaseData<ExplorePredictionsData>,
    precisionRecallCurve: IMetricsBaseData<IPrecisionRecallCurveData>,
    softmaxHistogram: IMetricsBaseData<SoftmaxHistogramData>,
    confidenceDistributionGTless: IMetricsBaseData<ConfidenceDistributionGTlessData>,
    plotTopMisses: IMetricsBaseData<PlotTopMissesData>,
    performanceByIoUThreshold: IMetricsBaseData<IPerformanceByIoUThresholdData, IPerformanceByIoUThresholdGraphParams>,
    objectCounts: IMetricsBaseData<ObjectCountsData>,
    predictionGTDistribution: IMetricsBaseData<PredictionGTDistributionData>,
    liftChart: IMetricsBaseData<ILiftChartData>,
    detailedMetrics: IMetricsBaseData<IDetailedMetricsData>,
    intersectionDistribution: IMetricsBaseData<IIntersectionDistributionData>,
    violinChart: IMetricsBaseData<IViolinChartData>,
}

export function ChartStateFactory(data?: Partial<IChartState>): IChartState {
    return {
        highlightedMetrics: MetricsBaseDataFactory(data?.highlightedMetrics?.data),
        confusionMatrix: MetricsBaseDataFactory(data?.confusionMatrix?.data),
        basicModelStats: MetricsBaseDataFactory(data?.basicModelStats?.data, { Validation: [], Training: [] }),
        plotTopLosses: MetricsBaseDataFactory(data?.plotTopLosses?.data, []),
        performanceByConfidence: MetricsBaseDataFactory(data?.performanceByConfidence?.data),
        trainingVsValidationPerformance: MetricsBaseDataFactory(data?.trainingVsValidationPerformance, {}),
        datasetClassDistribution: MetricsBaseDataFactory(data?.datasetClassDistribution, { Validation: {}, Training: {} }),
        populationDistribution: MetricsBaseDataFactory(data?.populationDistribution, { Validation: {}, Training: {} }),
        mostConfusedClasses: MetricsBaseDataFactory(data?.mostConfusedClasses, []),
        mostConfusedImages: MetricsBaseDataFactory(data?.mostConfusedImages, []),
        classPerformance: MetricsBaseDataFactory(data?.classPerformance, {}),
        confidenceDistribution: MetricsBaseDataFactory(data?.confidenceDistribution, {}),
        rocCurve: MetricsBaseDataFactory(data?.rocCurve, []),
        predictionDistribution: MetricsBaseDataFactory(data?.predictionDistribution, []),
        explorePredictions: MetricsBaseDataFactory(data?.explorePredictions, []),
        precisionRecallCurve: MetricsBaseDataFactory(data?.precisionRecallCurve, {}),
        softmaxHistogram: MetricsBaseDataFactory(data?.softmaxHistogram, []),
        confidenceDistributionGTless: MetricsBaseDataFactory(data?.confidenceDistributionGTless, {}),
        plotTopMisses: MetricsBaseDataFactory(data?.plotTopMisses, []),
        performanceByIoUThreshold: MetricsBaseDataFactory(data?.performanceByIoUThreshold, {}),
        objectCounts: MetricsBaseDataFactory(data?.objectCounts, {}),
        predictionGTDistribution: MetricsBaseDataFactory(data?.predictionGTDistribution, {}),
        liftChart: MetricsBaseDataFactory(data?.liftChart, {}),
        detailedMetrics: MetricsBaseDataFactory(data?.detailedMetrics, {}),
        intersectionDistribution: MetricsBaseDataFactory(data?.intersectionDistribution, {}),
        violinChart: MetricsBaseDataFactory(data?.violinChart, {}),
    };
}

export interface IMetricsBaseData<T = any, P = any> {
    layoutState: ILayoutState;
    graphParams: P,
    defaultParams?: P,
    data: T;
    defaultData: T;
}

export function MetricsBaseDataFactory(data: any, defaultData?: any): IMetricsBaseData {
    return {
        layoutState: {
            isLoading: false,
            isEmpty: false
        },
        graphParams: {},
        defaultParams: {},
        data: data ?? defaultData,
        defaultData: data ?? defaultData
    };
}

export interface ILayoutState {
    isLoading: boolean;
    isEmpty: boolean;
}

export interface IValidationPlotResponse {
    graph_id: string;
    response_timestamp: string;
    payload_dict: IPayloadDict;
}

export interface IPayloadDict {
    type: string;
    data: any;
}

// export type ChartKey = keyof IChartState;

export enum ChartKey {
    HighlightedMetrics = 'highlightedMetrics',
    ConfusionMatrix = 'confusionMatrix',
    BasicModelStats = 'basicModelStats',
    PlotTopLosses = 'plotTopLosses',
    PerformanceByConfidence = 'performanceByConfidence',
    TrainingVsValidationPerformance = 'trainingVsValidationPerformance',
    DatasetClassDistribution = 'datasetClassDistribution',
    PopulationDistribution = 'populationDistribution',
    MostConfusedClasses = 'mostConfusedClasses',
    MostConfusedImages = 'mostConfusedImages',
    ClassPerformance = 'classPerformance',
    ConfidenceDistribution = 'confidenceDistribution',
    RocCurve = 'rocCurve',
    PredictionDistribution = 'predictionDistribution',
    ExplorePredictions = 'explorePredictions',
    PrecisionRecallCurve = 'precisionRecallCurve',
    SoftmaxHistogram = 'softmaxHistogram',
    ConfidenceDistributionGTless = 'confidenceDistributionGTless',
    PlotTopMisses = 'plotTopMisses',
    PerformanceByIoUThreshold = 'performanceByIoUThreshold',
    ObjectCounts = 'objectCounts',
    PredictionGTDistribution = 'predictionGTDistribution',
    LiftChart = 'liftChart',
    DetailedMetrics = 'detailedMetrics',
    IntersectionDistribution = 'intersectionDistribution',
    ViolinChart = 'violinChart',
}