import React from 'react';

const isWDYR = localStorage.getItem('wdyr');
if (process.env.NODE_ENV === 'development') {
    if (isWDYR) {
        const whyDidYouRender = require('@welldone-software/why-did-you-render');
        whyDidYouRender(React, { trackAllPureComponents: true, });
    } else {
        console.log('WDYR is disabled. RUN localStorage.setItem("wdyr", "true") to enable');
    }
}