import { Dictionary, IModelFilters, ModelFiltersFactory, SortingKey } from 'common';
import { JSONSchema3or4 } from 'to-json-schema';
import { IUploadModelState, UploadModelStateFactory } from '../views/ModelUpload/entities';
import { IDeploymentTracking, IDeploymentTrackingResponse } from './IDeploymentTracking';
import { IContainerLog, ISystemUtils, ISystemUtilsResponse, LogLevel } from './ILogs';
import { IModel, IModelTrainSpecs } from './IModel';
import { IModelTrainingPageState, ModelTrainingPageStateFactory } from './IModelTraining';
import { IPredictionExplorerState, PredictionExplorerStateFactory } from './IPredictionExplorer';
import { IVendor } from './IVendor';
import { getModelCardData, IModelCardResponsePayload } from './payload';

export interface IModelState {
    modelList: Array<IModel>;
    archivedModelList: Array<IModel>;
    deploymentList: Array<IDeploymentTracking>;
    vendorList: Array<IVendor>;
    modelUpladState: IUploadModelState;
    common: IModelCommonState;
    modelTrainingState: IModelTrainingPageState;
    predictionExplorerState: IPredictionExplorerState;
}

export function ModelStateFactory(data?: IModelState): IModelState {
    return {
        modelList: data?.modelList || [],
        archivedModelList: data?.archivedModelList || [],
        deploymentList: data?.deploymentList || [],
        vendorList: data?.vendorList || [],
        modelUpladState: UploadModelStateFactory(data?.modelUpladState),
        common: ModelCommonStateFactory(data?.common),
        modelTrainingState: ModelTrainingPageStateFactory(data?.modelTrainingState),
        predictionExplorerState: PredictionExplorerStateFactory(data?.predictionExplorerState),
    };
}

export interface IModelCommonState {
    modelFilters: IModelFilters;
    modelStopLoading: Dictionary<boolean>;
    modelSorting: SortingKey;
    isDrawerVisible: boolean;
    deploymentModalState: IDeploymentModalState;
    modelListLoading: boolean;
    modelOutputExampleSchema: Dictionary<JSONSchema3or4>;
}

export function ModelCommonStateFactory(data?: Partial<IModelCommonState>): IModelCommonState {
    return {
        modelFilters: ModelFiltersFactory(data?.modelFilters),
        modelSorting: data?.modelSorting || 'lastActivity',
        modelStopLoading: {},
        isDrawerVisible: data?.isDrawerVisible ?? false,
        deploymentModalState: DeploymentModalStateFactory(data?.deploymentModalState),
        modelListLoading: data?.modelListLoading ?? false,
        modelOutputExampleSchema: data?.modelOutputExampleSchema ?? {},
    };
}

export interface IDeploymentModalState {
    visible: boolean;
    modelId: string;
}
export function DeploymentModalStateFactory(data?: Partial<IDeploymentModalState>): IDeploymentModalState {
    return {
        visible: data?.visible || false,
        modelId: data?.modelId || null,
    };
}

/*Model upload with zip path*/
export interface IModelPathReq {
    framework: string;
    problem_type: string;
    path: string;
}

export interface IModelPathRes {
        model_id: string;
        model_zip_path: string;
    
}

export { getModelCardData };
export type {
    IContainerLog, IDeploymentTrackingResponse, IModel, IModelCardResponsePayload, IModelTrainSpecs, ISystemUtils,
    ISystemUtilsResponse, LogLevel
};

