import { all, takeEvery } from "redux-saga/effects";
import * as actions from "../actions";
import { getValidationListDataSaga, updateValidationListTransactionSaga } from './ValidationDataSaga';
import { editValidationItemSaga } from './editValidationItemSaga';

export default function* rootSaga() {
    yield all([
        takeEvery(actions.EDIT_VALIDATION_ITEM_TRANSACTION, editValidationItemSaga),
        takeEvery(actions.GET_VALIDATION_LIST_TRANSACTION, getValidationListDataSaga),
        takeEvery(actions.UPDATE_VALIDATION_LIST_TRANSACTION, updateValidationListTransactionSaga),
    ]);
}
 