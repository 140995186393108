export enum ServiceEnum {
    Validation = 'validation-api',
    Api = 'api',
    Prediction = 'platform-api',
    Management = 'manage',
    Auth = 'authentication',
    Public = 'public',
    Deployment = 'deployment_manage',
    MLUtility = 'mlutility',
    PacsService = 'pacs-server',
    Genomics = 'genomics',
    None = '',
}
