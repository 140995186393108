import { AxiosResponse } from "axios";
import { DeploymentStatus, PlatformService, serverEvents, ServiceEnum } from "common";
import { GetDeploymentTrackingSaga } from "pages/Models/store/saga/modelDeploymentSaga";
import { EventChannel } from "redux-saga";
import { all, call, fork, put, take, takeEvery } from "redux-saga/effects";
import { setModelDeployment, setPlatformAlert, setPlatformAlertModalVisible, setPlatformHealth } from "../../../../store";
import * as actions from "../../../../store/action";
import { ModelDeployment } from "../../entities";
import { IPlatformAlert, IPlatformHealth, PlatformHealthFactory, PlatformServices } from "../../entities/PlatformHealth";

export default function* rootSaga() {
    yield all([
        takeEvery(actions.SUBSCRIBE_PLATFORM_HEALTH_TRANSACTION, subscribePlatformHealthService),
        takeEvery(actions.UNSUBSCRIBE_PLATFORM_HEALTH_TRANSACTION, unsubscribePlatformHealthService),
    ]);
}

function* subscribePlatformHealthService(): any {
    try {
        yield call(GetDeploymentTrackingSaga);
        const serviceResponse: AxiosResponse<{ services: Array<string>; alert: IPlatformAlert }> = yield PlatformService.Deployment.GetLiveServices.call({ headers: { activity: "system" } });

        if (serviceResponse?.data) {
            yield fork(setPlatformHealthSaga, serviceResponse.data.services);
        }

        if (localStorage.getItem("print") === "true") return;

        const eventChannel: EventChannel<any> = yield call(serverEvents.subscribeEvent, ServiceEnum.Deployment, "platform/health_stream");

        while (true) {

            let response = yield take(eventChannel);

            yield fork(setPlatformHealthSaga, response?.live_services);
            yield fork(setPlatformHealthAlertSaga, response?.alert);
        }
    } catch (error: any) {

        console.log({ error });
    }
}

function* setPlatformHealthAlertSaga(alert: IPlatformAlert) {
    if (alert?.deployment_id?.length > 0) {

        yield put(setPlatformAlert(alert));
        yield put(setPlatformAlertModalVisible(true));
    }
}

function* setPlatformHealthSaga(services?: Array<string>) {
    try {
        const clientServiceList = ["Web-Server", "Web-Client", "gesund-web-app-development_certbot_1",];
        const serviceList = Object.keys(PlatformServices);
        const modelDeployment: ModelDeployment = [];

        const platformHealth: Partial<IPlatformHealth> = {};

        services?.forEach((service: keyof IPlatformHealth) => {
            if (serviceList.includes?.(service)) {
                platformHealth[service] = DeploymentStatus.UP;
            } else if (!clientServiceList.includes?.(service)) {
                modelDeployment.push(service);
            }
        });

        yield put(setPlatformHealth(PlatformHealthFactory(platformHealth)));

        yield put(setModelDeployment(modelDeployment));

    } catch (error) {
        console.log({ error });
    }

}

export function* unsubscribePlatformHealthService(): any {
    yield serverEvents.unsubscribeEvent("platform/health_stream");
}