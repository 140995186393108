import { Layout } from 'antd';
import { LogoLarge } from 'components/assets/icons';
import Styles from './Styles.module.scss';
import {
    AppVersion,
    BandwidthAlert,
    FullScreenView,
    LinkPanel,
    NotificationPanel,
    ProjectSelection,
    SystemUtils,
    UserMenu,
} from './components';
import ClipboardModal from './components/ClipboardModal';
import NotificationDetailsModal from './components/NotificationPanel/components/NotificationDetailsModal';
import AppVersionModalContainer from './components/AppVersionModal';

export function TopMenu() {
    return (
        <div className={Styles.TopbarWrapper}>
            <div className={Styles.TopbarImage}></div>
            <div className={Styles.TopbarContainer}>
                <Layout.Header>
                    <div className={Styles.TopbarLeftContainer}>
                        <a
                            href="https://gesund.ai/"
                            target="_blank"
                            rel="noreferrer"
                            className={Styles.MainLogoWrapper}
                        >
                            <LogoLarge
                                className={Styles.MainLogo}
                                width={'150px'}
                                height={'40px'}
                            />
                        </a>
                        <AppVersion />
                        <LinkPanel />
                    </div>
                    <div className={Styles.TopbarRightContainer}>
                        <BandwidthAlert />
                        <ProjectSelection />
                        <FullScreenView />
                        <SystemUtils />
                        <NotificationPanel />
                        <UserMenu />
                    </div>
                </Layout.Header>
                <NotificationDetailsModal />
                <ClipboardModal />
                <AppVersionModalContainer />
            </div>
        </div>
    );
}
