import { _uuid, Dictionary, getMessages, getUnixTime, PlatformService, RoutingHelper } from 'common';
import { showMessageModal } from 'components';
import { IModelCardResponsePayload } from 'pages/Models/entities';
import { IModelFiles, ModelFilesFactory } from 'pages/Models/views/ModelUpload/entities';
import { IModelArtifactProps, ModelArtifactPropsFactory } from 'pages/Models/views/ModelUpload/entities/IModelArtifactProps';
import { put, select } from 'redux-saga/effects';
import { updateModelArtifactProps, updateModelFiles } from '..';
import { getModelListTransaction } from '../actions';
import { selectModelArtifactProps, selectModelFiles } from '../selectors';

export function* submitModelFormSaga(sagaAction: any): any {
    try {
        const {
            endpointRegistration,
            modelURL,
            vendorModelUrl,
            selectedProblemType,
            selectedFramework,
            selectedArchitecture,
            modelPicture,
            selectedUploadOption,
            bundlePath,
            monaiResponse,
        }: IModelArtifactProps = yield select(selectModelArtifactProps);
        const { model_id, model_path, dockerhub }: IModelFiles = yield select(selectModelFiles);
        let { payload, form } = sagaAction.payload;

        const modelPathMap: any = {
            upload: model_path,
            import: model_path,
            importFromS3URL: model_path,
            endpoint: endpointRegistration,
            externalRegistration: vendorModelUrl,
            externalFramework: bundlePath ?? model_path, //model_path for local zip upload & bundlePath for monai url upload
        };

        payload = getFormPayload(payload);
        payload.model_id = model_id ?? 'sampleModelId';
        payload.model_picture = modelPicture;
        payload.mode = modeMap[selectedUploadOption];
        payload.model_url = vendorModelUrl || modelURL || 'monai'; //for vendor_serving
        payload.model_architecture = selectedArchitecture ?? '';

        if (selectedUploadOption === 'externalFramework') {
            payload.namespace = payload.model_name?.split(' ')?.join('_')?.toLowerCase();
        }

        payload.model_framework = selectedFramework ?? 'pytorch'; //pytorch for monai

        if (selectedProblemType || selectedUploadOption === 'externalFramework') {
            payload.problem_type = selectedProblemType ?? monaiResponse?.network_data_format?.outputs.pred?.format;
        }
        if (!payload.model_picture) {
            payload.model_picture = '/assets/model_image/ModelCardImage.png';
        }

        const params = {
            model_path: modelPathMap[selectedUploadOption],
            dockerhub: dockerhub,
            src: srcMap[selectedUploadOption],
        };

        const request_id = _uuid();
        const response = yield PlatformService.Manage.CreateModel.call(payload, {
            params,
            headers: { 'x-request-id': request_id },
        });

        if (!response.data) return;
        form?.resetFields?.();

        yield put(updateModelFiles(ModelFilesFactory()));
        yield put(updateModelArtifactProps(ModelArtifactPropsFactory()));

        yield put(getModelListTransaction());

        showMessageModal({
            type: 'info',
            content: getMessages('000346'),
            okButton: {
                text: getMessages('000097'),
                navigate: RoutingHelper.Model({ activeView: 'ModelDashboard' }).uri,
            },
        });
    } catch (error: any) {
        console.log(error);
    }
}

function getFormPayload(data?: IModelCardResponsePayload) {
    let currentUser: any = sessionStorage.getItem('user');
    currentUser = currentUser ? JSON.parse(currentUser) : {};
    let permission_list = (data as any)?.model_access_options?.permission_list
        ? [...(data as any)?.model_access_options?.permission_list]
        : [];
    permission_list?.push(currentUser?.email);
    permission_list = permission_list.map((user: string) => ({ user, timestamp: getUnixTime(), type: 'user' }));

    return {
        ...data,

        model_access_options: {
            access: data?.model_access_options?.access || 'public',
            owner_email: currentUser.email,
            permission_list,
        },
        class_mappings: { ...data?.class_mappings },
        model_train_specs: {
            validation_metrics: data.model_train_specs?.validation_metrics,
            training_dataset_stats: data.model_train_specs?.training_dataset_stats?.reduce(
                (result, categoryItem) => ({
                    ...result,
                    [categoryItem.category]: (categoryItem.value as Array<Dictionary<any>>)?.reduce(
                        (result, item) => ({
                            ...result,
                            [item.title]: item.value,
                        }),
                        {}
                    ),
                }),
                {}
            ),
        },
    };
}

export const srcMap: any = {
    upload: 'local',
    import: 'vendor',
    importFromS3URL: 'vendor',
    endpoint: 'vendor',
    externalRegistration: 'vendor',
    externalFramework: 'monai',
};

export const modeMap: any = {
    upload: 'local_model',
    import: 'local_model',
    importFromS3URL: 'local_model',
    endpoint: 'external_model',
    externalRegistration: 'vendor_serving',
    externalFramework: 'monai',
};
