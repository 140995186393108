import { all, takeEvery, takeLatest } from "redux-saga/effects";

import * as actions from "../actions";
import { DeleteModelSaga } from "./deleteModelSaga";
import { ModelArchiveSaga, ModelUnArchiveSaga } from "./modelArchiveSaga";
import { DeployModelSaga, StopModelSaga } from "./modelDeploymentSaga";
import { getModelListTransactionSaga } from "./modelListSaga";
import { downloadArtifactsSaga, uploadModelFilesSaga } from "./modelUploadSaga";
import { submitModelFormSaga } from "./submitModelForm";
import { GetVendorListSaga } from "./vendorListSaga";
import { editModelFormSaga } from "./editModelFormSaga";
import { updatePreEmptiveModelSaga } from "./updatePreEmptiveModelSaga";
import { startModelTrainingSaga } from "./startModelTraining";
import { setModelModeSaga } from './setModelMode';
import { submitTrainingModelFormSaga } from "./submitTrainingModelForm";

export default function* rootSaga() {
    yield all([
        takeEvery(actions.GET_MODEL_LIST_TRANSACTION, getModelListTransactionSaga),
        takeEvery(actions.DOWNLOAD_ARTIFACTS_ACTION, downloadArtifactsSaga),
        takeEvery(actions.UPLOAD_MODEL_FILES_TRANSACTION, uploadModelFilesSaga),
        takeEvery(actions.GET_VENDOR_LIST_TRANSACTION, GetVendorListSaga),
        takeEvery(actions.SUBMIT_MODEL_FORM_TRANSACTION, submitModelFormSaga),
        takeEvery(actions.EDIT_MODEL_FORM_TRANSACTION, editModelFormSaga),
        takeEvery(actions.UPDATE_PREEMPTIVE_MODEL_TRANSACTION, updatePreEmptiveModelSaga),
        takeLatest(actions.DEPLOY_MODEL_TRANSACTION, DeployModelSaga),
        takeLatest(actions.STOP_MODEL_TRANSACTION, StopModelSaga),
        takeLatest(actions.ARCHIVE_MODEL_TRANSACTION, ModelArchiveSaga),
        takeLatest(actions.UNARCHIVE_MODEL_TRANSACTION, ModelUnArchiveSaga),
        takeLatest(actions.DELETE_MODEL_TRANSACTION, DeleteModelSaga),
        takeLatest(actions.START_MODEL_TRAINING_ACTION, startModelTrainingSaga),
        takeLatest(actions.SET_MODEL_MODE_ACTION, setModelModeSaga),
        takeLatest(actions.SUBMIT_MODEL_TRAINING_FORM_TRANSACTION, submitTrainingModelFormSaga),
    ]);
}
