import { IDataset } from 'common/entities';
import { DicomViewerHelper } from 'common/helpers';
import { useEffect, useState } from 'react';

export function useDicomViewerHelperInstance(setIsLoaded: React.Dispatch<React.SetStateAction<boolean>>, dataset: IDataset) {
    const [dicomViewerHelper, setDicomViewerHelper] = useState<DicomViewerHelper>();

    useEffect(() => {
        const dicomViewerHelperInstance = new DicomViewerHelper();
        dicomViewerHelperInstance.dataset = dataset;
        setDicomViewerHelper(dicomViewerHelperInstance);
        setIsLoaded?.(true);

        return () => {
            dicomViewerHelperInstance?.destroy();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (!dicomViewerHelper) return;
        dicomViewerHelper.dataset = dataset;
    }, [dataset, dicomViewerHelper]);

    return { dicomViewerHelper };
}
