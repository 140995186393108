import { Skeleton } from 'antd';
import cs from 'classnames';
import { IBaseProps } from 'common';
import { _range } from 'common/Utils';
import Styles from './Styles.module.scss';

export default function GImageSkeleton({ active, count, className, itemClassName }: IGImageSkeletonProps) {
    return (
        <div className={className}>
            {_range(count).map(i => (
                <GImageSkeletonItem
                    key={i}
                    active={active}
                    className={itemClassName}
                />
            ))}
        </div>
    );
}

export function GImageSkeletonItem({ active, className, style, showTitle = true }: ISkeletonItemProps) {
    return (
        <div
            className={cs(Styles.SkeletonItem, className)}
            style={style}
        >
            <Skeleton.Image
                active={active}
                className={Styles.ImageSkeleton}
            />

            {showTitle && (
                <Skeleton
                    paragraph={false}
                    active={active}
                />
            )}
        </div>
    );
}

interface IGImageSkeletonProps extends IBaseProps {
    count: number;
    active?: boolean;
    itemClassName?: string;
}

interface ISkeletonItemProps {
    active?: boolean;
    className?: string;
    style?: React.CSSProperties;
    showTitle?: boolean;
}
