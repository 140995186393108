/* eslint-disable eqeqeq */
import { useEffect } from 'react';
import { CustomEventType, EventListItem, EventPayloadMap } from './interface';

export class EventBus {
    static eventList: Array<EventListItem> = [];
    static on<T extends CustomEventType>(event: T, callback: (payload: EventPayloadMap[T]) => void, id?: string) {
        const cb = (e: CustomEvent<EventPayloadMap[T]>) => callback(e.detail);
        document.addEventListener(event, cb);
        EventBus.eventList.push({ type: event, cb, originalCb: callback, id });
    }
    static off<T extends CustomEventType>(event: T, callback: (payload: EventPayloadMap[T]) => void, id?: string) {
        const cb = EventBus.eventList?.find(item => item.type == event && (item?.id == id || item.originalCb == callback))?.cb;
        document.removeEventListener(event, cb);
        EventBus.eventList = EventBus.eventList.filter(item => item.type == event && item.originalCb == callback);
    }
    static emit<T extends CustomEventType>(event: T, payload?: EventPayloadMap[T]) {
        document.dispatchEvent(new CustomEvent(event, { detail: payload }));
    }
}

export function useEventBus<T extends CustomEventType>(
    event: T,
    eventListener: (payload: EventPayloadMap[T]) => void,
    id?: string
) {
    useEffect(() => {
        EventBus.on(event, eventListener, id);

        return () => {
            EventBus.off(event, eventListener, id);
        };
    }, [event, eventListener, id]);
}

export * from './interface';
