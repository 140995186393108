import { AutoComplete, AutoCompleteProps } from 'antd';
import cx from 'classnames';
import { Dictionary } from 'common/entities';
import { Search24 } from 'components/assets/icons';
import Styles from './Styles.module.scss';

export default function GAutoComplete({
    className,
    filterOption,
    caseInsensitive,
    id,
    popupClassName,
    icon,
    ...props
}: GAutoCompleteProps) {
    function filterOperation(inputValue: string, option: Dictionary<string>) {
        return option?.value?.toLocaleUpperCase().includes(inputValue?.toLocaleUpperCase());
    }

    return (
        <div
            className={cx(Styles.AutoCompleteWrapper, className)}
            id={id}
        >
            <div className={Styles.searchBox}>
                <AutoComplete
                    {...props}
                    filterOption={caseInsensitive ? filterOperation : undefined}
                    popupClassName={cx(Styles.DropdownWrapper, popupClassName)}
                />
            </div>
            <div className={Styles.searchIcon}>{icon ? icon : <Search24 />}</div>
        </div>
    );
}

export interface GAutoCompleteProps extends AutoCompleteProps {
    caseInsensitive?: boolean;
    id?: string;
    icon?: React.ReactNode;
}
