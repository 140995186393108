import { PropertyName } from "common/entities";
import { _get } from "./_get";
import { _keys } from "./_keys";

export function _mapValues<T>(obj: T, predicate: (o: T[keyof T], k: keyof T, obj: T) => any | keyof T | Array<PropertyName>): Record<keyof T, any> {
    if (!obj || !predicate) return obj ?? {} as any;

    const cb = typeof predicate === 'function' ? predicate : (o: any) => _get(o, predicate);

    return _keys(obj).reduce((acc: Record<keyof T, any>, k) => {
        acc[k] = cb(obj[k], k, obj);
        return acc;
    }, {} as Record<keyof T, any>);
}