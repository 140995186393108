import { List, ListProps } from 'antd';
import cx from 'classnames';
import Styles from './Styles.module.scss';

export default function GList<T = any>({ className, ...props }: GListProps<T>) {
    return (
        <>
            <List
                className={cx(Styles.GList, className)}
                {...props}
            />
        </>
    );
}

export interface GListProps<T> extends ListProps<T> {}
