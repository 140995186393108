import { all } from "redux-saga/effects";
import SummarySaga from "../views/summary/store/saga";
import MetricsSaga from "../views/validationAnalysis/components/metrics/store/saga";
import BatchStartSaga from "../views/startValidation/store/saga"
import ComparisonSaga from "../views/validationAnalysis/components/comparison/store/saga"
import ExternalValidationSaga from "../views/externalValidation/store/saga"

export default function* rootSaga() {
    yield all([
        SummarySaga(),
        MetricsSaga(),
        BatchStartSaga(),
        ComparisonSaga(),
        ExternalValidationSaga(),
    ]);
}