/* eslint-disable react-hooks/exhaustive-deps */
import { ChartHelper } from 'common/helpers';
import { Dictionary } from 'common/entities';
import React, { useEffect, useRef } from 'react';
import { IBaseChartProps } from '../entities';
import { createChart, IChartOptions } from './createChart';

export default function StepLineChart(props: IStepLineChartProps) {
    const chartHelperRef = useRef<ChartHelper.XYChart>();

    useEffect(() => {
        if (props.data && chartHelperRef.current) {
            chartHelperRef.current.setData(props.data);
        }
    }, [props.data]);

    useEffect(() => {
        if (!chartHelperRef.current) {
            chartHelperRef.current = createChart(props.chartID, props.chartOptions);

            if (props.data) {
                chartHelperRef.current.setData(props.data);
            }
        }

        return () => {
            chartHelperRef.current?.root?.dispose();
        };
    }, []);


    return (
        <>
            <div id={props.chartID} className={props.className} />
        </>
    );
}


interface IStepLineChartProps extends IBaseChartProps {
    data?: Array<Dictionary>;
    getChartHelperRef?: (chartHelperRef: ChartHelper.XYChart) => void;
    chartOptions?: IChartOptions;
}
