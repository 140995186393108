import { Dictionary } from "common";
import { IMeasurementForm, Team } from 'pages/AnnotationCS/entities';
import { IAccessOptions } from "pages/Project/entities";

export interface IStudyJob {
    studyId: string;
    status: StudyJobStatus;
    name: string;
    studySize: number;
    predictions: string[];
}


export enum StudyJobStatus {
    done = 'STUDY_DONE',
    inprogress = 'STUDY_INPROGRESS',
    failed = 'STUDY_FAILED',
}

export interface IStudyValidationRunRequestPayload {
    "deployment_id": string,
    "study_id": string | string[],
    "table_names": string | string[],
    "dataset_id": string;
}

export interface IExternalValidationTrackingResponse {
    accessOptions: IAccessOptions;
    createdTimestamp: string | number;
    finishedTimestamp: string | number;
    datasetId: string;
    datasetName: string;
    modelId: string;
    modelName: string;
    name: string;
    sampleSize: number;
    study_job_id: string;
    userId: string;
    validationId: string;
    measurementList: Array<IMeasurementForm>;
    team: Team;
}

export interface IExternalModelValidationState {
    resultModal: IResultModalState;
    selectedValidation: IExternalValidationTrackingResponse;
    blandAtmanResponse: any;
}


export interface IResultModalState {
    open: boolean;
    validationId: string;
    studyId: string;
}

export type IExternalValidationResponse = Dictionary<IExternalValidation>;

export interface IExternalValidation {
    icc?: Array<IICCValues>,
    bland_altman_analysis?: Array<Dictionary<IBlandAtmanValues>>;
    dice_coefficient?: Array<IDice>;
}
export interface IDiceCoefficientResponse {
    dice: Array<IDice>;
}
export interface IDice {
    dice: number,
    measurement: string,
    object: string,
}

export interface IICCValues {
    "VB": string,
    "MH(mm)": number,
    "AH(mm)": string,
    "PH(mm)": number,
    "PAR": number,
    "Angle[0]": number,
    "APR": number,
    "SBH(mm)": number,
    "SBR": number,
    "MPR": number,
}
export interface IBlandAtmanResponse {
    measurement: string,
    object: string,
    result: Dictionary<IBlandAtmanValues>;
}
export interface IBlandAtmanPlatformResponse {
    data: {
        blandaltman: IBlandAtmanResponse;
    };
}
export interface IBlandAtmanValues {
    measurement: string,
    mean: {
        value: number,
        instance: number;
    }[],
    diff: {
        value: number,
        instance: number;
    }[],
    bias: number,
    std_dev: number,
    bounds: {
        upper_loa: number,
        lower_loa: number,
    };
}

export interface IDualComparisonRequest {
    metric_name: string,
    reader_combo: string,
    validation_id: string,
    measurement_combination: Array<string>,
}