import cornerstoneTools from 'cornerstone-tools';
import freehandSegmentationMixin from './freehandSegmentationMixin';

const { getters } = cornerstoneTools.getModule('segmentation');
const draw = cornerstoneTools.importInternal('drawing/draw');
const drawJoinedLines = cornerstoneTools.importInternal('drawing/drawJoinedLines');
const getNewContext = cornerstoneTools.importInternal('drawing/getNewContext');
/**
 * Override for `freehandSegmentationMixin`'s `renderToolData` method to render a polyline instead
 * of a freehand region with the first and last point connected. Apply after the `freehandSegmentationMixin`.
 *
 * @override
 * @param {Object} evt The cornerstone render event.
 * @returns {null}
 */
function renderToolData(this: any, evt: CustomEvent) {
    const lock = cornerstoneTools.getModule('globalConfiguration').configuration?.lockAnnotationTools;
    if (lock) return;
    
    const eventData = evt.detail;
    const { element } = eventData;
    const color = getters.brushColor(element, true);
    const context = getNewContext(eventData.canvasContext.canvas);
    const handles = this.handles;

    draw(context, (context: CanvasRenderingContext2D) => {
        const isNotTheFirstHandle = handles.points.length > 1;

        if (isNotTheFirstHandle) {
            for (let j = 0; j < handles.points.length; j++) {
                const lines = [...handles.points[j].lines];

                drawJoinedLines(context, element, this.handles.points[j], lines, {
                    color,
                });
            }
        }
    });
}

const polylineSegmentationMixin = Object.assign({}, freehandSegmentationMixin, {
    renderToolData,
});

/**
 * @mixin freehandPolylineRenderOverride - segmentation operations for corrections Polyline
 * @memberof Mixins
 */
export default polylineSegmentationMixin;
