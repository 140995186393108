import { TClassMapping } from 'common';
import { IBlindSpotAnalysisResult } from './IAnalysisResult';
import { IBlindSpotTracking } from './IBlindSpotTracking';
import { CustomSubCohortFactory, ICustomSubCohort } from './ICustomSubCohort';
import { IMetadataFilters, MetadataFiltersFactory } from './IMetadataFilters';

export interface IBlindSpotAnalysisState {
    analysisResult: Array<IBlindSpotAnalysisResult>;
    missedSubcohorts: Array<IBlindSpotAnalysisResult>;
    analysisTracking: IBlindSpotTracking;
    metadataFilters: IMetadataFilters;
    resultLoading: boolean;
    selectedAnalysisList: Array<string>;
    baseValidation: IBlindSpotAnalysisResult;
    customSubCohort: ICustomSubCohort;
    blindSpotAnalysisTrackingList: Array<IBlindSpotTracking>;
    generateBlindSpotAnalysisVisible: boolean;
    selectedClass: 'Average' | number;
    classMappings: TClassMapping;
}

export function BlindSpotAnalysisStateFactory(data?: Partial<IBlindSpotAnalysisState>): IBlindSpotAnalysisState {
    return {
        analysisResult: data?.analysisResult ?? [],
        missedSubcohorts: data?.analysisResult ?? [],
        analysisTracking: data?.analysisTracking,
        metadataFilters: MetadataFiltersFactory(data?.metadataFilters),
        resultLoading: data?.resultLoading ?? false,
        selectedAnalysisList: data?.selectedAnalysisList ?? [],
        baseValidation: data?.baseValidation,
        customSubCohort: CustomSubCohortFactory(data?.customSubCohort),
        blindSpotAnalysisTrackingList: data?.blindSpotAnalysisTrackingList ?? [],
        generateBlindSpotAnalysisVisible: data?.generateBlindSpotAnalysisVisible ?? false,
        selectedClass: data?.selectedClass ?? 'Average',
        classMappings: data?.classMappings ?? {},
    };
}

export * from './IAnalysisMetadata';
export * from './IAnalysisResult';
export * from './IBlindSpotTracking';
export * from './ICustomSubCohort';
export * from './IMetadataFilters';
