export default function usePagination(
    setPageSize: (pageSize: number) => void,
    pageMap: Dictionary<number>,
    pageSize: number,
    set: (key: string, value: number) => void,
    total: number,
    level: 'study' | 'series' | 'image'
) {
    const keyMap = {
        study: 'studyPage',
        series: 'seriesPage',
        image: 'page',
    };

    const key = keyMap[level] || 'page';
    const current = pageMap[keyMap[level] || 'page'];

    let paginationConfig = {
        current: current,
        pageSize,
        total: total,
        onChange: (_page: number, _pageSize: number) => {
            if (_page !== pageMap[key]) {
                set(key, _page);
            }

            if (_pageSize !== pageSize) {
                setPageSize(_pageSize);
            }
        },
        pageSizeOptions: [12, 24, 36, 48, 60, 72, 84, 96, 108, 120, 132, 144, 156, 168, 180],
        showSizeChanger: true,
        showTotal(total: any, range: any) {
            return `${range[0]}-${range[1]} of ${total} items`;
        },
    };
    return paginationConfig;
}
