import { Upload } from 'antd';
import type { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { AuthService, _values } from 'common';
import { useState } from 'react';
import Styles from './Styles.module.scss';

function UploadImage({ filePathRef }: any) {
    const [fileList, setFileList] = useState<Dictionary<UploadFile>>({});
    let accrementor = 0;

    async function onImageChanged(file: File) {
        try {
            if (!file) return;
            const formdata = new FormData();
            formdata.append('error_images', file);
            const response = await AuthService.AddFeedbackImage.post(formdata);

            if (response?.data) {
                filePathRef.current = { ...filePathRef.current, [(file as any)?.uid]: response?.data };
            }
        } catch (error) {
            console.log(error);
        }
    }


    function onBeforeUpload(file: File) {
        accrementor++
        if(accrementor > 5) return false
        onImageChanged?.(file);
        return false;
    }
    async function onRemove(e: any) {
        const response = await AuthService.RemoveFeedbackImage.post({ filePath: filePathRef.current[e?.uid] });
        delete filePathRef.current?.[e?.uid];
    }

    const onChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
        let updatedFileList: Dictionary<UploadFile> = {};
        newFileList.slice(0,5).forEach(i => {
            updatedFileList[i?.uid] = i;
        });
        setFileList(updatedFileList);
    };

    const onPreview = async (file: UploadFile) => {
        if(accrementor > 5) return
        let src = file.url as string;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj as RcFile);
                reader.onload = () => resolve(reader.result as string);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    return (
        <Upload
            className={Styles.UploadImage}
            listType="picture-card"
            fileList={_values(fileList)}
            onChange={onChange}
            beforeUpload={onBeforeUpload}
            onPreview={onPreview}
            onRemove={onRemove}
            multiple
            accept="image/*"
        >
            {_values(fileList).length < 5 && '+ Upload'}
        </Upload>
    );
}

export default UploadImage;
