import { IValidationRun } from "./IValidation";

interface ISummaryPageData {
    validationRunList: Array<IValidationRun>;
    overlayStatus: IOverlayStatus;
    isEditModalOpen: IModalStatus;
    selectedValidations: Array<string>;
}

interface IOverlayStatus {
    isLoading: boolean;
    showNoDataOverlay: boolean
}

interface IModalStatus {
    isOpen: boolean;
    validationId: string
}

interface IValidationRunsPayload {
    validationRuns?: Array<IValidationRun>;
}

interface ICommonState {
    isValRestartLoading?: boolean;
    restartedValidationId?: string;
    isEditBSARoundNameModalOpen?: boolean;
    EditedBSARoundId?: string;
}

export const ValidationRunsFactory = (data: IValidationRunsPayload): Array<IValidationRun> => {
    return data?.validationRuns || [];
};

export const SummaryPageDataFactory = (data?: Partial<ISummaryPageData>): ISummaryPageData => {
    return {
        validationRunList: data?.validationRunList || [],
        overlayStatus: {
            isLoading: false,
            showNoDataOverlay: false
        },
        isEditModalOpen: data?.isEditModalOpen || {
            isOpen: false,
            validationId: ""
        },
        selectedValidations: data?.selectedValidations || [],
    };
};

export type { ISummaryPageData, IValidationRun, IValidationRunsPayload, IOverlayStatus, IModalStatus, ICommonState };
