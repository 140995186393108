import cs from 'classnames';
import GErrorBoundry from 'components/GErrorBoundry';
import GLoading from 'components/GLoading';
import GOverLay from 'components/GOverlay';
import React from 'react';
import Styles from './assets/Style.module.scss';

export default function GCard({
    children,
    className,
    caption,
    loading,
    showNoDataOverlay,
    captionTag,
    cardHeader,
    id,
    containerRef,
    style,
    ...props
}: ICardProps) {
    function renderCardHeader() {
        return (
            <div className="g-card-header">
                <div className={cs('card-caption', 'subtitle2-bold')}>
                    <span className="title">{caption}</span>
                    {captionTag}
                </div>
                {cardHeader}
            </div>
        );
    }

    return (
        <div
            className={cs(Styles.CardContainer, className)}
            id={id}
            ref={containerRef}
            style={style}
            data-handler-id={props['data-handler-id']}
        >
            {captionTag || caption || cardHeader ? renderCardHeader() : null}

            <div className="g-card-content">
                <GErrorBoundry>
                    <GLoading
                        loading={loading}
                        style={{ height: '100%' }}
                    >
                        {children}
                    </GLoading>
                </GErrorBoundry>
            </div>

            {!loading ? <GOverLay showNoDataOverlay={showNoDataOverlay} /> : null}
        </div>
    );
}

interface ICardProps {
    children?: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    caption?: React.ReactNode;
    loading?: boolean;
    showNoDataOverlay?: boolean;
    captionTag?: React.ReactNode;
    cardHeader?: React.ReactNode;
    id?: string;
    containerRef?: React.RefObject<HTMLDivElement>;
    'data-handler-id'?: any;
}
