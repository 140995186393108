import { ChartHelper } from 'common/helpers';
import { AxesType } from 'common/helpers/ChartHelper/entities/enums';

export function createChart(chartID: string) {
    const chartHelper = new ChartHelper.GaugeChart();


    chartHelper.createRoot({ chartID });

    chartHelper
        .createChart({})
        .createAxes({
            xAxis: {
                type: AxesType.gaplessDate,
                categoryField: "time",
                rendererSetting: {}
            },
            yAxis: {
                type: AxesType.value, rendererSetting: {},
                valueAxisOptions: {
                    min: 0, max: 100, numberFormat: "#'%'"
                },
            }
        });

    return chartHelper;
}