import { IDataset } from 'common';
import { IMetadataField } from 'pages/Datasets/entities';
import { findDataset, selectDatasetList } from 'redux/selectors';
import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { IDatasetSelection } from '../entities';

export const selectSelectedDatasets = (state: RootState) => state.datasets.datasetCurationState.selectedDatasets;

export const selectMetadataFilterFields = (state: RootState) =>
    state.datasets.datasetCurationState.imageFiltering.metadataFilterFields.filter(f => f?.valueList?.length);
export const selectSelectedFilters = (state: RootState) =>
    state.datasets.datasetCurationState.imageFiltering.selectedMetadataFilters;
export const selectSelectedQueries = (state: RootState) => state.datasets.datasetCurationState.imageFiltering.selectedQueries;
export const selectCurateDatasetLoading = (state: RootState) =>
    state.datasets.datasetCurationState.datasetCreation.curateDatasetLoading;
export const selectMetadataFiltersLoading = (state: RootState) =>
    state.datasets.datasetCurationState.imageFiltering.metadataFiltersLoading;
export const selectDatasetPicture = (state: RootState) => state.datasets.datasetCurationState.datasetCreation.datasetPicture;
export const selectDatasetFilters = (state: RootState) => state.datasets.datasetCurationState.datasetFilters;
export const selectImageFiltersCalculated = (state: RootState) =>
    state.datasets.datasetCurationState.imageFiltering.imageFiltersCalculated;
export const selectQueryApplied = (state: RootState) => state.datasets.datasetCurationState.imageFiltering.queryApplied;
export const selectSelectedMetadataFiltersLoading = (state: RootState) =>
    state.datasets.datasetCurationState.imageFiltering.selectedMetadataFiltersLoading;

export const selectSelectedQueryList = createSelector<any, Array<Array<IMetadataField>>>(
    selectMetadataFilterFields,
    selectSelectedQueries,
    (metadataFields: Array<IMetadataField>, selectedQueries: Array<Array<string>>) =>
        selectedQueries?.map(q => q?.map(f => metadataFields?.find(item => item.meta_name === f)))
);

export const selectSelectedDatasetList = createSelector<any, Array<IDataset>>(
    selectSelectedDatasets,
    selectDatasetList,
    (selectedDatasets: Array<IDatasetSelection>, datasetList: Array<IDataset>) =>
        selectedDatasets?.map(d => findDataset(datasetList, d.datasetId))
);

export const selectCuratedDatasetList = createSelector<any, Array<IDataset>>(
    selectDatasetList,
    selectSelectedDatasets,
    (datasetList: Array<IDataset>, selectedDatasets: Array<IDatasetSelection>) =>
        selectedDatasets.filter(d => d.imageFilterSelection).map(d => findDataset(datasetList, d.datasetId))
);

export function selectDatasetSelectionItem(id: string) {
    return createSelector<any, IDatasetSelection>(selectSelectedDatasets, (selectedDatasets: Array<IDatasetSelection>) =>
        selectedDatasets?.find(d => d.datasetId === id)
    );
}
