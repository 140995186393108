import { IModelArtifactProps, ModelArtifactPropsFactory, } from "./IModelArtifactProps";
import { IModelFiles, ModelFilesFactory } from "./IModelFiles";

export interface IUploadModelState {
    modelArtifactProps: IModelArtifactProps;
    modelFiles: IModelFiles;
}

export function UploadModelStateFactory(data?: Partial<IUploadModelState>): IUploadModelState {
    return {
        modelArtifactProps: ModelArtifactPropsFactory(data?.modelArtifactProps),
        modelFiles: ModelFilesFactory(data?.modelFiles)
    };
}

export type { IModelArtifactProps, IModelFiles, };

export { ModelFilesFactory };

export interface UploadModelActionPayload {
    file?: File,
    signal: AbortSignal;
    is_training?:Boolean;
}
