import { Dictionary, ProblemTypeKeys } from 'common';
import { IPoint } from 'pages/AnnotationCS/entities';
import { TOutputSchema } from 'pages/Models/entities/IModelOutputSchema';

export interface IPredictionResult {
    isLoading: boolean;
    isEmpty: boolean;
    classification?: IClassificationResult;
    maskList?: IMask[];
    timeTaken: number;
}

export interface IConfidenceScore {
    name: string;
    value: number;
}

export function PredictionResultFactory(data?: Partial<IPredictionResult>): IPredictionResult {
    return {
        isLoading: data?.isLoading || false,
        isEmpty: data?.isEmpty || true,
        classification: ClassificationResultFactory(data?.classification) || {},
        maskList: data?.maskList || [],
        timeTaken: data?.timeTaken || null,
    };
}

export interface IPredictionResponse<T = any> {
    response: T;
    status: number;
    time_taken: number;
}

export interface IClassificationResultResponse extends Dictionary {
    confidence?: number;
    logits?: Array<number>;
    prediction_class?: number;
}

export interface ISegmentationResultResponse extends Dictionary {
    masks?: Array<IMask>;
    rles?: Array<IMask>;
}

export interface IClassificationResult {
    confidenceScore?: Array<IConfidenceScore>;
    result?: IConfidenceScore;
}

export function ClassificationResultFactory(data?: Partial<IClassificationResult>): IClassificationResult {
    return {
        confidenceScore: data?.confidenceScore || [],
        result: data?.result || { name: null, value: null },
    };
}

export interface IObjectDetectionResultResponse extends Dictionary {
    objects: Array<IObjectDetectionResultItem>;
}

export interface IObjectDetectionResultItem extends Dictionary {
    box: IBoundingBox;
    prediction_class: number;
    confidence: number;
}
export interface IMask {
    mask?: Array<IPoint>;
    class?: number;
    label?: string;
    rle?: string;
    prediction_class?: number;
    confidence?: number;
    isGt?: boolean;
}

const segmentationDefaultResultFields = {
    masks: {
        value: 'masks',
        children: {
            prediction_class: { value: 'prediction_class' },
            confidence: { value: 'confidence' },
            mask: { value: 'mask' },
            rle: { value: 'rle' },
        },
    },
};

const objectDetectionDefaultResultFields = {
    objects: {
        value: 'objects',
        children: {
            prediction_class: { value: 'prediction_class' },
            confidence: { value: 'confidence' },
            box: { value: 'box' },
        },
    },
};

const segmentationFieldList = ['masks', 'mask', 'prediction_class', 'confidence', 'rle'];
export const fieldList: Record<ProblemTypeKeys, string[]> = {
    classification: ['prediction_class', 'confidence', 'logits', 'status'],
    segmentation: segmentationFieldList,
    instance_segmentation: segmentationFieldList,
    semantic_segmentation: segmentationFieldList,
    object_detection: ['objects', 'prediction_class', 'confidence', 'box'],
};

export const defaultResultFields: Record<ProblemTypeKeys, TOutputSchema> = {
    classification: fieldList?.classification.reduce((acc, curr) => ({ ...acc, [curr]: { value: curr } }), {}),
    segmentation: segmentationDefaultResultFields,
    instance_segmentation: segmentationDefaultResultFields,
    semantic_segmentation: segmentationDefaultResultFields,
    object_detection: objectDetectionDefaultResultFields,
};

const segmentationPathMap = {
    masks: ['masks', 'value'],
    mask: ['masks', 'children', 'mask', 'value'],
    rle: ['masks', 'children', 'rle', 'value'],
    prediction_class: ['masks', 'children', 'prediction_class', 'value'],
    confidence: ['masks', 'children', 'confidence', 'value'],
};

const objectDetectionPathMap = {
    objects: ['objects', 'value'],
    prediction_class: ['objects', 'children', 'prediction_class', 'value'],
    confidence: ['objects', 'children', 'confidence', 'value'],
    box: ['objects', 'children', 'box', 'value'],
};

export const pathMap: Record<ProblemTypeKeys, Dictionary<Array<string>>> = {
    classification: {
        prediction_class: ['prediction_class', 'value'],
        confidence: ['confidence', 'value'],
        logits: ['logits', 'value'],
        status: ['status', 'value'],
    },
    segmentation: segmentationPathMap,
    instance_segmentation: segmentationPathMap,
    semantic_segmentation: segmentationPathMap,
    object_detection: objectDetectionPathMap,
};

type SegmentationFields = 'masks' | 'mask' | 'prediction_class' | 'confidence' | 'rle';
export interface IPredictionResponseFields {
    classification: 'prediction_class' | 'confidence' | 'logits' | 'status';
    segmentation: SegmentationFields;
    instance_segmentation: SegmentationFields;
    semantic_segmentation: SegmentationFields;
    object_detection: 'objects' | 'prediction_class' | 'confidence' | 'box';
}

export type IRawResponse = {
    status: number;
    prediction?: Array<any>;
} & (IObjectDetectionResultResponse | ISegmentationResultResponse | IClassificationResultResponse);

export interface IBoundingBox {
    x1: number;
    y1: number;
    x2: number;
    y2: number;
}
