import { Dictionary, IImage } from 'common';
import { IMetadataEl, IMetadataField } from 'pages/Datasets/entities/IMetadata';

export interface IMetadataUploadState {
    metadata_id?: string;
    fileUpload?: IFileUpdloadState;
    metadata_fields?: Array<IMetadataField>;
    metadata_name?: string;
    imageMap?: Dictionary<IImage>;
    loading?: boolean;
    activeStrategy?: MetadataStragety;
    newMetadataDict?: Dictionary<IMetadataEl>;
}

export interface IFileUpdloadState {
    file?: string;
    fileName?: string;
    fileData?: Dictionary<IMetadataEl>;
    columnList?: Array<string>;
}

export function MetadataUploadStateFactory(data?: Partial<IMetadataUploadState>): IMetadataUploadState {
    return {
        fileUpload: data?.fileUpload ?? {},
        metadata_fields: data?.metadata_fields ?? [],
        metadata_name: data?.metadata_name ?? '',
        imageMap: data?.imageMap ?? {},
        loading: data?.loading ?? false,
        activeStrategy: data?.activeStrategy ?? null,
        newMetadataDict: data?.newMetadataDict ?? {},
    };
}

export type MetadataStragety = 'cloneFromMetadata' | 'uploadFromFile' | 'getFromDicom' | 'generateFromReport';
