import { Dictionary } from 'common/entities';
import { _entries, _isEmpty } from 'common/Utils';

export const WindowLevel = {
    Default: { wc: null, ww: null } as { wc: null; ww: null },
    Bone: { wc: 500, ww: 2000 },
    Lung: { wc: -600, ww: 1600 },
    Abdomen: { wc: 40, ww: 400 },
    Brain: { wc: 30, ww: 70 },
    'Soft Tissue': { wc: 50, ww: 350 },
    Liver: { wc: 60, ww: 160 },
    Mediastinum: { wc: 50, ww: 500 },
    Stroke: { wc: 30, ww: 30 },
    CTA: { wc: 170, ww: 600 },
} as const;

export type WindowLevelKey = keyof typeof WindowLevel;

export function findWindowLevel(windowLevel?: IWindowLevel) {
    if (!windowLevel) return;
    let result: WindowLevelKey;
    _entries(WindowLevel).forEach(([key, value]) => {
        if (value.ww === windowLevel.ww && value.wc === windowLevel.wc) {
            result = key;
        }
    });
    return result;
}

export interface IWindowLevel {
    ww: number;
    wc: number;
}

export const windowLevelList = _entries(WindowLevel).map(([key, { ww, wc }]) => ({
    title: key === 'Default' ? `${key}` : `${key} (W: ${ww}, L: ${wc})`,
    value: key,
}));

export const isWindowLevelExist = (windowLevel?: IWindowLevel) => !_isEmpty(windowLevel?.ww) && !_isEmpty(windowLevel?.wc);

export function getWindowLevelFromMetadata(metadata?: Dictionary) {
    const windowing = { ww: metadata?.['Window Width'], wc: metadata?.['Window Center'] };
    return isWindowLevelExist(windowing) ? windowing : undefined;
}

export function checkWindowLevel(windowLevel: IWindowLevel) {
    const { ww, wc } = windowLevel;
    const w = parseInt(ww as any, 10);
    const l = parseInt(wc as any, 10);

    if (!isNaN(w) && !isNaN(l) && w >= -32768 && w <= 32768 && l >= -32768 && l <= 32768) {
        return true;
    } else {
        return false;
    }
}

export function isWindowLevelsEqual(a: IWindowLevel, b: IWindowLevel) {
    if (!a || !b) return false;
    return a.ww === b.ww && a.wc === b.wc;
}
