import { IBoundingBox, IFreeHandleData } from "./interface";

export function getPolygonBoundingBox(points: IFreeHandleData[] = []): IBoundingBox {
    const bounds = points.reduce(
        (acc, p) => ({
            left: Math.min(acc.left, p.x),
            right: Math.max(acc.right, p.x),
            bottom: Math.min(acc.bottom, p.y),
            top: Math.max(acc.top, p.y),
        }),
        {
            left: points[0].x,
            right: points[0].x,
            bottom: points[0].y,
            top: points[0].x,
        }
    );

    return {
        left: bounds.left,
        top: bounds.bottom,
        width: Math.abs(bounds.right - bounds.left),
        height: Math.abs(bounds.top - bounds.bottom),
    };
}
