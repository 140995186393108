import { Dictionary, ProblemType } from 'common';
import { IModel } from 'pages/Models/entities';
import { findModel, selectModelList, selectValidationList } from 'redux/selectors';
import { RootState } from 'redux/store';
import { createSelector } from 'reselect';
import { IValidationRun } from '../../../../summary/entities';
import { ValidationStatus } from '../../../../summary/entities/IValidation';
import { ComparisonChartKey } from '../../comparison/entities';
import { ChartKey, IChartState } from '../entities';
import { ValidationType } from '../entities/ICommon';
import { IViewItem, defaultViewList } from './../entities/IViews';

export const selectImagePreviewControls = (state: RootState) => state.validation.metrics.imagePreviewControls;
export const selectMetricsExtra = (state: RootState) => state.validation.metrics.metricsExtra;
export const selectTrainingMetricsExist = (state: RootState) => state.validation.metrics.metricsExtra.trainingMetricsExist;
export const selectPageLoading = (state: RootState) => state.validation.metrics.common.pageLoading;
export const selectStaticPayloadFetched = (state: RootState) => state.validation.metrics.common.staticPayloadFetched;
export const selectExportPDFVisible = (state: RootState) => state.validation.metrics.common.exportPdfVisible;
export const selectExportPDFLoading = (state: RootState) => state.validation.metrics.common.pdfLoading;
export const selectViewList = (state: RootState) => state.validation.metrics.views.viewList;
export const selectMetricsViewModalVisible = (state: RootState) => state.validation.metrics.views.viewModalVisible;
export const selectMetricsViewEditable = (state: RootState) => state.validation.metrics.views.viewEditable;
export const selectHidedChartList = (state: RootState) => state.validation.metrics.views.hidedChartList;
export const selectExportTablesVisible = (state: RootState) => state.validation.metrics.common.exportTablesVisible;
export const selectExportTablesPDFLoading = (state: RootState) => state.validation.metrics.common.pdfTablesLoading;
export const selectExportFilterEnabled = (state: RootState) => state.validation.metrics.common.exportFilterEnabled;
export const selectLightThemeDict = (state: RootState) => state.validation.metrics.common.lightThemeDict;

export function selectChartData<T extends ChartKey>(key: T): (state: RootState) => IChartState[T]['data'] {
    return (state: RootState) => state.validation.metrics.validationMetrics[key].data;
}

export function selectLightTheme(key: ChartKey | ComparisonChartKey) {
    return (state: RootState) => state.validation.metrics.common.lightThemeDict[key];
}

export function selectChartDefaultData<T extends ChartKey>(key: T): (state: RootState) => IChartState[T]['data'] {
    return (state: RootState) => state.validation.metrics.validationMetrics[key].defaultData;
}

export function selectChartLayout(key: ChartKey) {
    return (state: RootState) => state.validation.metrics.validationMetrics[key].layoutState;
}

export function selectGraphParams<T extends ChartKey>(key: T): (state: RootState) => IChartState[T]['graphParams'] {
    return (state: RootState) => state.validation.metrics.validationMetrics[key].graphParams;
}

export function selectDefaultParams<T extends ChartKey>(key: T): (state: RootState) => IChartState[T]['graphParams'] {
    return (state: RootState) => state.validation.metrics.validationMetrics[key].defaultParams;
}

export const selectCompletedValidationlist = createSelector<any, Array<IValidationRun>>(
    selectValidationList,
    selectModelList,
    (validationList: Array<IValidationRun>, modelList: Array<IModel>) =>
        validationList
            .filter(v => v.validationStatus === ValidationStatus.done)
            .map(item => ({ ...item, modelStatus: findModel(modelList, item.modelId)?.deploymentStatus }))
);

export function getViewListByProblemTypeSelector(
    problemType: ProblemType,
    validationType: ValidationType
): (state: RootState) => Array<IViewItem> {
    return createSelector(
        selectViewList,
        (viewList: Dictionary<Dictionary<Array<IViewItem>>>) =>
            viewList[problemType]?.[validationType] ?? defaultViewList[problemType]?.[validationType] ?? []
    );
}

export function getViewItemSelector(key: ChartKey, problemType: ProblemType, validationType: ValidationType) {
    return createSelector<any, IViewItem>(
        getViewListByProblemTypeSelector(problemType, validationType),
        (viewList: Array<IViewItem>) => viewList.find(item => item.name === key)
    );
}
