import { AuthService, getMessages } from 'common';
import { put } from 'redux-saga/effects';
import { updateActivationStatus, updateActivationToken } from '..';
import { Notification } from 'components';

export function* ActivationTokenSaga(sagaAction: any): any {
    try {
        const payload = { ...sagaAction.payload, origin: window.location.origin };
        const response = yield AuthService.SetActivationToken.call(payload);

        if (response?.data) {
            yield put(updateActivationToken(response?.data?.value));
            Notification.success({ content: getMessages('001538') });
        }
    } catch (e: any) {
        console.log(e);
        if (e?.data?.message === '$00014') {
            yield put(updateActivationToken(''));
            yield put(updateActivationStatus('expired'));
        }
    }
}
