import { Breadcrumb } from 'antd';
import { camelCaseToTitleCase } from 'common/Utils';
import { IDataset, isStudyDataset } from 'common/entities';
import { IDatasetExplorerItem } from '../DatasetExplorer';
import Styles from './Styles.module.scss';

export function ExplorerBreadcrumb({
    level,
    onClickItem,
    onClickStudyList,
    selectedSeries,
    selectedStudy,
    seriesList,
    studyList,
    dataset,
}: IProps) {
    if (!isStudyDataset(dataset))
        return (
            <Breadcrumb className={Styles.BreadcrumbContainer}>
                <Breadcrumb.Item>Image List</Breadcrumb.Item>
            </Breadcrumb>
        );

    return (
        <>
            <Breadcrumb className={Styles.BreadcrumbContainer}>
                {level !== 'study' && (
                    <Breadcrumb.Item
                        className={Styles.BreadcrumbLink}
                        onClick={onClickStudyList}
                    >
                        Study List
                    </Breadcrumb.Item>
                )}
                <Breadcrumb.Item
                    className={Styles.BreadcrumbLink}
                    onClick={() => onClickItem(selectedStudy)}
                    menu={{
                        items: studyList?.map(item => ({
                            label: item.name,
                            key: item.id,
                        })),
                        onClick: ({ key }) => {
                            const study = studyList?.find(item => item.id === key);
                            if (study) onClickItem(study);
                        },
                        className: Styles.BreadcrumbMenu,
                    }}
                >
                    {selectedStudy?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item
                    menu={{
                        items: seriesList?.map(item => ({
                            label: item.name,
                            key: item.id,
                        })),
                        onClick: ({ key }) => {
                            const series = seriesList?.find(item => item.id === key);
                            if (series) onClickItem(series);
                        },
                        className: Styles.BreadcrumbMenu,
                    }}
                    className={Styles.BreadcrumbLink}
                >
                    {selectedSeries?.name}
                </Breadcrumb.Item>
                <Breadcrumb.Item>{camelCaseToTitleCase(level)} List</Breadcrumb.Item>
            </Breadcrumb>
        </>
    );
}

interface IProps {
    level: string;
    onClickStudyList: () => void;
    onClickItem: (item: IDatasetExplorerItem) => void;
    selectedStudy: IDatasetExplorerItem;
    selectedSeries: IDatasetExplorerItem;
    studyList: IDatasetExplorerItem[];
    seriesList: IDatasetExplorerItem[];
    dataset: IDataset;
}
