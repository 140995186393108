import { APISlice } from 'app/store/APISlice';
import { Dictionary, ServiceEnum } from 'common';
import { getProjectData, IProject, IProjectResponse, transformDataForRequest } from '../entities/IProject';

export const extendedApiSlice = APISlice.injectEndpoints({
    endpoints: builder => ({
        getProjectList: builder.query<Array<IProject>, undefined>({
            query: () => `${ServiceEnum.Api}/Project/GetProjectList`,
            transformResponse(baseQueryReturnValue: Array<IProjectResponse>, meta, arg) {
                return baseQueryReturnValue?.map(getProjectData) || [];
            },
            providesTags: ['ProjectList'],
        }),

        getProject: builder.query<Array<IProject>, string | undefined>({
            query: () => `${ServiceEnum.Api}/Project/getProject`,
            transformResponse(baseQueryReturnValue: Array<IProjectResponse>, meta, arg) {
                return baseQueryReturnValue?.map(getProjectData) || [];
            },
            providesTags: ['ProjectList'],
        }),

        createProject: builder.mutation<IProject, IProjectResponse>({
            query: (project) => ({
                url: `${ServiceEnum.Api}/Project/CreateProject`,
                method: 'POST',
                body: project,
            }),
            transformResponse(baseQueryReturnValue: IProjectResponse, meta, arg) {
                return getProjectData(baseQueryReturnValue);
            },
            invalidatesTags: ['ProjectList'],
        }),

        editProject: builder.mutation<IProject, Dictionary & Partial<IProject>>({
            query: (project) => ({
                url: `${ServiceEnum.Api}/Project/EditProject`,
                method: 'POST',
                body: transformDataForRequest(project),
            }),
            transformResponse(baseQueryReturnValue: IProjectResponse, meta, arg) {
                return getProjectData(baseQueryReturnValue);
            },
            invalidatesTags: (result, error, project) => {
                return [
                    { type: 'ValidationList', id: result?.projectId },
                    { type: 'ModelList', id: result?.projectId },
                    { type: 'DatasetList', id: result?.projectId },
                    { type: "AnnotationList", id: result?.projectId },
                    'ProjectList',
                    "AnnotationList",
                ];
            }
        }),

        removeItem: builder.mutation<IProject, any>({
            query: (project) => {
              const itemIdKey =
                project.type === 'model' ? 'model_id' :
                project.type === 'dataset' ? 'dataset_id' :
                project.type === 'annotation' ? 'annotation_id' : null;
          
              if (!itemIdKey) {
                throw new Error(`Unsupported project type: ${project.type}`);
              }
          
              return {
                url: `${ServiceEnum.Api}/Project/removeItem`,
                method: 'POST',
                body: {
                  project_id: project.project_id,
                  [itemIdKey]: project.item_id,
                  type: project.type
                },
              };
            },
            transformResponse(baseQueryReturnValue: IProjectResponse, meta, arg) {
              return getProjectData(baseQueryReturnValue);
            },
            invalidatesTags: (result, error, project) => {
              const itemTypeMapping = {
                model: 'ModelList',
                dataset: 'DatasetList',
                annotation: 'AnnotationList'
              };
              const itemType = itemTypeMapping[project.type as keyof typeof itemTypeMapping] || null;
              if (!itemType) {
                throw new Error(`Unsupported project type: ${project.type}`);
              }
          
              return [
                { type: 'ValidationList', id: result?.projectId },
                { type: 'AnnotationList', id: result?.projectId },
                { type: itemType as "ModelList", id: result?.projectId },
                'ProjectList'
              ];
            }
          }),
          

        deleteProject: builder.mutation<IProject, string>({
            query: (project_id) => ({
                url: `${ServiceEnum.Api}/Project/DeleteProject`,
                method: 'POST',
                body: { project_id },
            }),
            invalidatesTags: ['ProjectList'],
        }),

        toggleArchiveProject: builder.mutation<IProject, { project_id: string; action: 'archive' | 'unarchive' }>({
            query: ({ project_id, action }) => ({
                url: `${ServiceEnum.Api}/Project/ToggleArchiveProject`,
                method: 'POST',
                body: { project_id, action },
            }),
            invalidatesTags: ['ProjectList'],
        }),

    })
});

export const { useGetProjectListQuery, useCreateProjectMutation, useGetProjectQuery, useEditProjectMutation,
    useDeleteProjectMutation, useToggleArchiveProjectMutation, useRemoveItemMutation} = extendedApiSlice;
