import { TableColumnsType } from 'antd';
import { getMessages, RoutingHelper } from 'common/helpers';
import { isNullOrUndefined, sortString, _entries, _isEmpty } from 'common/Utils';
import { Chart } from 'components/assets/icons';
import { getFilters, GTableCell } from 'components/GDataGrid';
import GTooltip from 'components/GTooltip';
import dayjs from 'dayjs';
import { IValidationRun } from 'pages/Validation/views/summary/entities';
import { ValidationStatus } from 'pages/Validation/views/summary/entities/IValidation';
import CompletionTag from './CompletionTag';
import Styles from './Styles.module.scss';

export function useValidationTable(validationList: Array<IValidationRun> = []) {
    const columns: TableColumnsType<IValidationRun> = [
        {
            dataIndex: 'generateMetrics',
            title: (
                <div className={Styles.generateMetricsContainer}>
                    <GTooltip title={getMessages('000865')}>
                        <Chart />
                    </GTooltip>
                </div>
            ),
            width: 40,
            align: 'center',
            fixed: true,
            render: (value: boolean) =>
                value ? (
                    <div className={Styles.generateMetricsContainer}>
                        <GTooltip title={getMessages('000865')}>
                            <Chart />
                        </GTooltip>
                    </div>
                ) : null,
        },
        {
            dataIndex: 'validationId',
            title: getMessages('000002'),
            render: (value: string, record) => (
                <GTableCell
                    caption={record?.name}
                    summary={<GTooltip title={value}>{value?.slice(0, 15)}...</GTooltip>}
                    link={
                        record.validationStatus === ValidationStatus.done
                            ? RoutingHelper.Validation({ activeView: 'analysis', validationId: record?.validationId }).uri
                            : null
                    }
                />
            ),
            width: 170,
            fixed: true,
            sorter: (a: IValidationRun, b: IValidationRun) => sortString(a.validationId, b.validationId),
            ...getFilters('name', validationList),
        },
        {
            dataIndex: 'modelName',
            title: getMessages('000018'),
            render: (value: string, record, index) => (
                <GTableCell
                    caption={record?.modelName}
                    summary={record?.modelSummary}
                    link={RoutingHelper.Model({ activeView: 'model-details', modelId: record.modelId }).uri}
                />
            ),
            width: 200,
            sorter: (a: IValidationRun, b: IValidationRun) => sortString(a.modelName, b.modelName),
            ...getFilters(['modelName'], validationList),
        },
        {
            dataIndex: 'problemType',
            title: getMessages('000019'),
            width: 160,
            sorter: (a: IValidationRun, b: IValidationRun) => sortString(a.problemType, b.problemType),
            ...getFilters(['problemType'], validationList),
        },
        {
            dataIndex: 'completion',
            title: getMessages('001027'),
            render: (value, record) => (
                <CompletionTag
                    status={record.validationStatus}
                    progress={record.progress}
                />
            ),
            width: 160,
            sorter: (a: IValidationRun, b: IValidationRun) => a.progress - b.progress,
            ...getFilters(['validationStatus'], validationList),
        },
        {
            dataIndex: 'modality',
            title: getMessages('000021'),
            width: 150,
            sorter: (a: IValidationRun, b: IValidationRun) => sortString(a.modality, b.modality),
            ...getFilters(['modality'], validationList),
        },
        {
            dataIndex: 'dataset',
            title: getMessages('000022'),
            render: (value: string, record, index) => (
                <GTableCell
                    caption={record?.datasetName}
                    summary={
                        <>
                            <div>{`Sample: ${record.sampleSize}`}</div>
                            <div>{`Source: ${record.hospitalName}`}</div>
                        </>
                    }
                    link={RoutingHelper.Dataset({ activeView: 'dataset-details', datasetId: record?.datasetId }).uri}
                />
            ),
            width: 200,
            sorter: (a: IValidationRun, b: IValidationRun) => sortString(a.datasetName, b.datasetName),
            ...getFilters(['datasetName'], validationList),
        },
        {
            dataIndex: 'userEmail',
            title: getMessages('000494'),
            width: 150,
            sorter: (a: IValidationRun, b: IValidationRun) => sortString(a.userEmail, b.userEmail),
            ...getFilters(['userEmail'], validationList),
        },
        {
            dataIndex: 'anatomy',
            title: getMessages('000023'),
            width: 135,
            sorter: (a: IValidationRun, b: IValidationRun) => sortString(a.anatomy, b.anatomy),
            ...getFilters(['anatomy'], validationList),
        },
        {
            dataIndex: 'createdTimestamp',
            title: getMessages('001490'),
            width: 120,
            render: (value: number) =>
                value ? (
                    <GTableCell
                        caption={dayjs.unix(value)?.format('MM/DD/YYYY')}
                        summary={dayjs.unix(value)?.format('hh:mm:ss A')}
                    />
                ) : (
                    <span>-</span>
                ),
            sorter: (a: IValidationRun, b: IValidationRun) => a?.createdTimestamp - b?.createdTimestamp,
        },
        {
            dataIndex: 'finishedTimestamp',
            title: getMessages('000405'),
            width: 120,
            render: (value: number) =>
                value ? (
                    <GTableCell
                        caption={dayjs.unix(value)?.format('MM/DD/YYYY')}
                        summary={dayjs.unix(value)?.format('hh:mm:ss A')}
                    />
                ) : (
                    <span>-</span>
                ),
            sorter: (a: IValidationRun, b: IValidationRun) => a?.finishedTimestamp - b?.finishedTimestamp,
        },
        {
            dataIndex: 'tags',
            title: getMessages('000025'),
            width: 200,
            render: value => value?.join?.(', '),
            ...getFilters(['tags'], validationList),
        },
        {
            dataIndex: 'metrics',
            title: getMessages('000026'),
            width: 130,
            render: (value, record) =>
                _isEmpty(value) ? (
                    <span>N/A</span>
                ) : (
                    <>
                        {_entries(value).map(([key, _value]) =>
                            typeof _value == 'number' ? (
                                <div>
                                    {key}: {formatValue(_value)}
                                </div>
                            ) : (
                                <span>N/A</span>
                            )
                        )}
                    </>
                ),
        },
        {
            dataIndex: 'sampleCounts',
            title: (
                <>
                    <div>
                        <div> {getMessages('000445')}</div>
                        <div style={{ fontSize: '1.1rem', fontWeight: 'normal' }}>
                            {getMessages('000446')}/{getMessages('000447')}/{getMessages('000448')}{' '}
                        </div>
                    </div>
                </>
            ),
            width: 150,
            render: value => (
                <div>
                    {value?.total} / {value?.done} / {value?.failed}
                </div>
            ),
        },
    ];

    return { columns };
}

export const formatValue = (value: number) => {
    const result = !isNullOrUndefined(value) && !isNaN(Number(value)) ? Number(Number(value)?.toFixed?.(4)) : 'N/A';

    return isNaN(result as any) ? ('N/A' as any) : result;
};
