import { isNullOrUndefined } from "./isNullOrUndefined";

interface IOmit {
    <T extends object, K extends [...(keyof T)[] | (keyof T)[][]]>
        (obj: T, ...keys: K): {
            [K2 in Exclude<keyof T, K[number]>]: T[K2]
        };
}

export const _omit: IOmit = (obj, ...keys) => {
    if (typeof obj !== "object" && isNullOrUndefined(obj)) return {} as any;
    const ret = {} as {
        [K in keyof typeof obj]: (typeof obj)[K]
    };
    let key: keyof typeof obj;
    for (key in obj) {
        if (!(keys.flat().includes(key))) {
            ret[key] = obj[key];
        }
    }
    return ret;
};