import { Segmented, SegmentedProps } from 'antd';
import cs from 'classnames';
import Styles from './Styles.module.scss';

export default function GSegmented({ className, ...props }: IGSegmentedProps) {
    return (
        <>
            <Segmented
                className={cs(Styles.GSegmentedContainer, className)}
                {...props}
            />
        </>
    );
}

export interface IGSegmentedProps extends Omit<SegmentedProps, 'ref'> {}
