import { Layout } from 'antd';
import { useMatomo } from 'app/hooks';
import { RoutingHelper } from 'common';
import GFallback from 'components/GFallback';
import { Suspense, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Outlet, ScrollRestoration, useLocation } from 'react-router-dom';
import { selectAccessToken, selectIsAppInit } from 'redux/selectors';
import Styles from './Styles.module.scss';
import { DimensionWarning, TopMenu } from './components';

export default function GLayout() {
    const token = useSelector(selectAccessToken);
    const isAppInit = useSelector(selectIsAppInit);
    let location = useLocation();
    const matomo = useMatomo();

    useEffect(() => {
        if (isAppInit && !token) {
            RoutingHelper.Authentication({ activeView: 'login' }).navigate({ state: { from: location } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAppInit, token]);

    useEffect(() => {
        matomo?.onPageChange();
    }, [location?.pathname, matomo]);

    if (!isAppInit || !token) return <GFallback allScreen />;

    return (
        <>
            <ScrollRestoration />
            <Layout className={Styles.LayoutContainer}>
                <TopMenu />
                <Suspense fallback={<GFallback />}>
                    <Layout.Content>
                        <Outlet />
                    </Layout.Content>
                </Suspense>

                <DimensionWarning />
            </Layout>
        </>
    );
}
