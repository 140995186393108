import { getDatasetListTransactionSaga } from "pages/Datasets/store/saga/datasetListSaga";
import { GetSourceListSaga } from "pages/Datasets/store/saga/sourceListSaga";
import { getModelListTransactionSaga } from "pages/Models/store/saga/modelListSaga";
import { GetVendorListSaga } from "pages/Models/store/saga/vendorListSaga";
import { getCredentialsListTransaction } from "pages/Platform/store/saga";
import { ValidationAPISlice } from 'pages/Validation/store';
import { getValidationListDataSaga } from 'pages/Validation/views/summary/store/saga/ValidationDataSaga';
import { getValidationMetricsViewSaga } from 'pages/Validation/views/validationAnalysis/components/metrics/store/saga/getValidationMetricsViewSaga';
import { fork, put } from 'redux-saga/effects';
import { getInitialNotifications, getReportedErrorLogs } from './NotificationSaga';

export function* GetInitialDataSaga(): any {
    try {
        yield fork(GetVendorListSaga);
        yield fork(GetSourceListSaga);
        yield fork(getModelListTransactionSaga);
        yield fork(getDatasetListTransactionSaga);
        yield fork(getCredentialsListTransaction);
        yield fork(getInitialNotifications);
        yield fork(getReportedErrorLogs);
        yield fork(getValidationMetricsViewSaga);
        yield fork(getValidationListDataSaga);
        yield put(ValidationAPISlice.endpoints.getStudyValidations.initiate(undefined) as any);
    } catch (error) {
        console.log(error);
    }
}